import { RootState } from 'common/redux'
import { FC, useCallback, useEffect } from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { RoomContainer } from 'room/common/presentation/room-container'
import { useAuthenticatedBeeldayClient } from 'connectivity/beelday-hooks'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { getLobbyAddress } from 'interaction-scheme/model/interaction-scheme'
import RoomHeader from 'room/common/presentation/room-header'
import { MiscPanel } from 'room/misc'
import {
	allStreams,
	clearVcrRoomAddress,
	selectPublishBitRate,
	setPublishBitrate,
	setVcrRoomAddress,
} from 'video-conference-media/redux'
import { PublishBitrates } from 'common/publish-bitrates'
import Menu from './menu'
import background from './background.png'
import QuickChange from './quick-change'
import {
	setCurrentPublicChatRooms,
	visiblePublicChatRooms,
} from 'room/lobby/redux'
import roomsPresentations from 'room/lobby/rooms-presentation'
import Translate from 'intl/translate'
import { RoomId, RoomType } from 'common/types'
import EqualVideosContainer from 'video-conference-media/presentation/equal-videos-container'
import { roomAddress } from 'interaction-scheme/model/address'
import { use100vh } from 'react-div-100vh'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding-top: 50px;
	padding-left: 75px;
	padding-right: 30px;
	padding-bottom: 30px;
	position: relative;
	width: 100%;
`

const RoomBackground = styled.img`
	left: 0;
	position: absolute;
	top: 0;
	height: 100%;
	width: 100%;
`

const PublicChatRoom: FC = () => {
	const containerHeight = use100vh()
	const dispatch = useDispatch()
	const beeldayClient = useAuthenticatedBeeldayClient()
	const address = useAssertedJoinedRoomAddress()
	const publishBitrate = useSelector(selectPublishBitRate)
	const latestInteractionSchemeEvent = useSelector(
		(state: RootState) => state.eventSource.latestInteractionSchemeEvent
	)
	const sceneVideoUsers = useSelector(allStreams)
	const userCount = sceneVideoUsers.length

	const availeblePublicChatRooms = useSelector(visiblePublicChatRooms)
	const currentRoom = availeblePublicChatRooms.find(
		r => r.id === address.roomId
	)
	const otherRooms = availeblePublicChatRooms.filter(
		r => r.id !== currentRoom?.id
	)

	useEffect(() => {
		dispatch(setVcrRoomAddress(address))

		return () => {
			dispatch(clearVcrRoomAddress())
		}
	}, [dispatch, address])

	useEffect(() => {
		const lobbyRoomId = getLobbyAddress(latestInteractionSchemeEvent)
		if (lobbyRoomId) {
			const lobbyAddress = roomAddress(address, RoomType.Lobby, lobbyRoomId)
			beeldayClient.getPublicChatRooms(lobbyAddress).then(rooms => {
				dispatch(setCurrentPublicChatRooms(rooms))
			})
		}
	}, [dispatch, beeldayClient, address, latestInteractionSchemeEvent])

	useEffect(() => {
		let bitrate = PublishBitrates.low

		if (userCount <= 1) {
			bitrate = PublishBitrates.low
		} else if (userCount <= 4) {
			bitrate = PublishBitrates.high
		} else if (userCount <= 8) {
			bitrate = PublishBitrates.medium
		}

		if (bitrate !== publishBitrate) {
			dispatch(setPublishBitrate(bitrate))
		}
	}, [dispatch, publishBitrate, userCount])

	const changePublicRoom = useCallback(
		(roomId: RoomId) => {
			beeldayClient.transfer(address, roomId)
		},
		[beeldayClient, address]
	)

	return (
		<RoomContainer
			style={{
				height: `${containerHeight}px`,
			}}
		>
			<RoomHeader>
				{currentRoom?.type && (
					<Translate>{roomsPresentations[currentRoom.type].name}</Translate>
				)}
			</RoomHeader>
			<Container>
				<RoomBackground src={background} />
				<EqualVideosContainer videos={sceneVideoUsers} />
				<QuickChange rooms={otherRooms} onPublicRoomChange={changePublicRoom} />
			</Container>
			<Menu />
			<MiscPanel />
		</RoomContainer>
	)
}

export default PublicChatRoom
