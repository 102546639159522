import { colors, icons, intl } from '@beelday/common'
import styled from '@emotion/styled'
import { FC, forwardRef, useEffect, useRef } from 'react'
import { Slide, SlideId } from './slide-models'
import { useSlides } from './use-slides'

type Props = {
	slides: Slide[]
	selected?: SlideId | null
	onSelect: (slide: Slide) => unknown
	onClose: () => unknown
	className?: string
}
export const SlideSwitcher: FC<Props> = ({
	slides,
	selected,
	onSelect,
	onClose,
	className,
}) => {
	const activeRef = useRef<HTMLDivElement>(null)
	const switcherRef = useRef<HTMLDivElement>(null)
	useEffect(() => {
		if (switcherRef.current && activeRef.current) {
			switcherRef.current.scrollLeft = activeRef.current.offsetLeft
		}
	}, [selected])

	const scrollRight = () => {
		const { current: switcher } = switcherRef
		if (switcher) {
			const offset = Math.max(switcher.offsetWidth / 2, SLIDE_ITEM_WIDTH)
			if (switcher.scrollLeft + offset <= switcher.scrollWidth) {
				switcher.scrollLeft += offset
			} else {
				switcher.scrollLeft = switcher.scrollWidth
			}
		}
	}
	const scrollLeft = () => {
		const { current: switcher } = switcherRef
		if (switcher) {
			const offset = Math.max(switcher.offsetWidth / 2, SLIDE_ITEM_WIDTH)
			if (switcher.scrollLeft - offset >= 0) {
				switcher.scrollLeft -= offset
			} else {
				switcher.scrollLeft = 0
			}
		}
	}

	return (
		<Container className={className}>
			<Title>
				<intl.Translate>slides.switcher.title</intl.Translate>
			</Title>
			<Switcher ref={switcherRef}>
				{slides.map(slide => (
					<SlideItem
						ref={selected === slide.id ? activeRef : undefined}
						key={slide.id}
						slide={slide}
						selected={selected === slide.id}
						onClick={() => onSelect(slide)}
					/>
				))}
			</Switcher>
			<ScrollButtonContainer position="l" onClick={scrollLeft}>
				<button type="button">
					<icons.ArrowLeft />
				</button>
			</ScrollButtonContainer>
			<ScrollButtonContainer position="r" onClick={scrollRight}>
				<button type="button">
					<icons.ArrowRight />
				</button>
			</ScrollButtonContainer>
			<CloseIcon onClick={onClose}>
				<icons.CancelIcon />
			</CloseIcon>
		</Container>
	)
}

const Container = styled.div`
	background-color: ${colors.white};
	box-shadow: 0 5px 15px 0 rgba(19, 16, 13, 0.1);
	border-radius: 10px;
	border: 1px solid ${colors.borderGray50};
`
const Title = styled.div`
	font-size: 14px;
	text-align: center;
	padding: 14px 30px 0px 30px;
	position: relative;
`
const CloseIcon = styled.div`
	color: ${colors.indigoBlue};
	z-index: 2;
	position: absolute;
	right: 9px;
	top: 0px;
	padding: 10px;
	box-sizing: content-box;
	width: 10px;
	cursor: pointer;
	transition: transform 0.2s ease-in-out;
	&:hover {
		transform: scale(1.4);
	}
`
const Switcher = styled.div`
	display: flex;
	flex-basis: 70px;
	gap: 10px;
	overflow-x: auto;
	padding: 8px 20px 20px 20px;
	scroll-behavior: smooth;
	scroll-snap-type: x mandatory;
	-ms-overflow-style: none;
	scrollbar-width: none;
	::-webkit-scrollbar {
		display: none;
	}
`

const SLIDE_ITEM_WIDTH = 125
const SlideThumb = styled.div`
	scroll-snap-align: start;
	scroll-margin: 0;
	padding: 5px;
	border-radius: 2px;
	cursor: pointer;
	transition: 0.2s ease-in-out;

	:first-of-type {
		scroll-margin-left: 20px;
	}
	:last-of-type {
		scroll-margin-right: 20px;
	}

	:hover {
		transform: scale(1.1);
	}

	&.active {
		scroll-margin: 5px;
		background-color: ${colors.indigoBlue10};

		:first-of-type {
			scroll-margin-left: 20px;
		}
		:last-of-type {
			scroll-margin-right: 20px;
		}
	}

	img {
		background-color: ${colors.white};
		aspect-ratio: 16/9;
		object-fit: cover;
		width: ${SLIDE_ITEM_WIDTH}px;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		border-radius: 2px;
		color: ${colors.gray};
		font-size: 12px;
		border: 2px solid ${colors.borderGray};
		transition: border-color 0.2s ease-in-out;
	}

	&.active img {
		border-color: ${colors.indigoBlue};
	}
`

const SlideItem = forwardRef<
	HTMLDivElement,
	{
		slide: Slide
		onClick: () => unknown
		selected?: boolean
	}
>(({ slide, onClick, selected }, ref) => {
	const slides = useSlides()
	const src = slides.getThumbnailUrl(slide)
	return (
		<SlideThumb
			className={selected ? 'active' : ''}
			onClick={onClick}
			title={slide.name}
			ref={ref}
		>
			<img src={src} alt={slide.name} />
		</SlideThumb>
	)
})
SlideItem.displayName = 'SlideItem'

const ScrollButtonContainer = styled.div<{ position: 'l' | 'r' }>`
	padding: 10px;
	height: 100%;
	display: flex;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: ${({ position }) => (position === 'l' ? 0 : 'unset')};
	right: ${({ position }) => (position === 'r' ? 0 : 'unset')};
	z-index: 1;
	opacity: 0;
	background: white;
	border-radius: ${({ position }) =>
		position === 'r' ? '0 10px 10px 0' : '10px 0 0 10px'};
	background: ${({ position }) =>
		position === 'r'
			? 'linear-gradient( 90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 10%, rgba(255, 255, 255, 0.8) 70%)'
			: 'linear-gradient( -90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 10%, rgba(255, 255, 255, 0.8) 70%)'};
	transition: opacity 0.2s ease-in-out;

	:hover {
		opacity: 1;
	}

	button {
		all: unset;
		user-select: none;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: ${colors.white};
		width: 35px;
		height: 35px;
		border-radius: 50%;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
		color: ${colors.indigoBlue};
	}
`
