import { Config } from 'common/config'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { useJuno } from 'vcr/juno/juno-provider'

import {
	selectLocalScreenShareStream,
	setLocalScreenShareStream,
} from 'video-conference-media/redux'
import useScreenShareAPI from './screen-share-api'
import {
	selectScreenShareStateInGroup,
	selectUserGroup,
} from 'room/group-room/redux'
import { useAssertedWorkflowUser } from 'room/common/use-workflow-user'
import { ScreenShareInGroupState } from 'common/types'

type UseScreenShareInGroup = () => {
	meScreenSharing: boolean
	userGroupScreenShareState: ScreenShareInGroupState | undefined
	start: () => Promise<void>
	stop: () => Promise<void>
	forceStart: (userId: string) => Promise<void>
	forceStop: (userId: string) => Promise<void>
}

const useScreenShareInGroup: UseScreenShareInGroup = () => {
	const dispatch = useDispatch()
	const localScreenShareStream = useSelector(selectLocalScreenShareStream)
	const address = useAssertedJoinedRoomAddress()
	const api = useScreenShareAPI(Config.beeldayBackendUrl)
	const { juno } = useJuno()

	const userGroupScreenShareState = useSelector(selectScreenShareStateInGroup)

	const userGroup = useSelector(selectUserGroup)

	const me = useAssertedWorkflowUser()

	const meScreenSharing = me.id === userGroupScreenShareState?.userId

	const stop = useCallback(async () => {
		await api.stopSharing(address)
		await juno?.stopScreenShare()
		if (localScreenShareStream) {
			localScreenShareStream.getTracks().forEach(track => track.stop())
		}

		dispatch(setLocalScreenShareStream())
	}, [api, address, juno, localScreenShareStream, dispatch])

	const forceStart = useCallback(
		async (userId: string) => {
			await api.stopSharing(address, userId)

			const stream = await navigator.mediaDevices.getDisplayMedia()
			await api.startSharing(address)
			await juno?.startScreenShare(stream)

			dispatch(setLocalScreenShareStream(stream))
		},
		[api, address, juno, dispatch]
	)

	const forceStop = useCallback(
		async (userId: string) => {
			if (userId !== me.id) {
				await api.stopSharing(address, userId)
			}
		},
		[me.id, api, address]
	)

	const start = useCallback(async () => {
		const stream = await navigator.mediaDevices.getDisplayMedia()
		await api.startSharing(address)
		await juno?.startScreenShare(stream)

		dispatch(setLocalScreenShareStream(stream))
	}, [dispatch, juno, api, address])

	useEffect(() => {
		const bothInSameGroup =
			userGroup?.users.filter(
				u => u.id === userGroupScreenShareState?.userId || u.id === me.id
			).length === 2
				? true
				: false

		if (bothInSameGroup) {
			if (!meScreenSharing) {
				stop()
			}
		} else if (!userGroupScreenShareState?.active) {
			stop()
		}
	}, [
		me.id,
		meScreenSharing,
		stop,
		userGroup?.users,
		userGroupScreenShareState?.active,
		userGroupScreenShareState?.userId,
	])

	useEffect(() => {
		if (meScreenSharing) {
			const screenSharingTrack = localScreenShareStream?.getVideoTracks()[0]
			screenSharingTrack?.addEventListener('ended', stop)
			return () => {
				screenSharingTrack?.removeEventListener('ended', stop)
			}
		}
	}, [localScreenShareStream, meScreenSharing, stop])

	useEffect(() => {
		if (meScreenSharing) {
			window.addEventListener('beforeunload', stop)
			return () => {
				window.removeEventListener('beforeunload', stop)
			}
		}
	}, [meScreenSharing, stop])

	return {
		meScreenSharing,
		userGroupScreenShareState: userGroupScreenShareState,
		start,
		stop,
		forceStart,
		forceStop,
	}
}

export default useScreenShareInGroup
