import { useUserApi, Avatar as UserApiAvatar } from '../user-api'
import { Relative } from '../ui'
import { Avatar } from '../icons'
import { PropsOf } from '@emotion/react'
import styled from '@emotion/styled'

type Shape = 'CIRCLE' | 'SQUARE'

const Image = styled.img<{ shape: Shape }>`
	border-radius: ${props => (props.shape === 'SQUARE' ? '10px' : '50%')};
	height: 100%;
	object-fit: cover;
	width: 100%;
`

const PlaceholderContainer = styled.div<{ shape: Shape }>`
	background-color: #abb8c3;
	border-radius: ${props => (props.shape === 'SQUARE' ? '10px' : '50%')};
	display: flex;
	height: 100%;
	width: 100%;
`

type Props = PropsOf<typeof Relative> & {
	avatar?: UserApiAvatar
	border?: string
	shape?: Shape
	size: string
	userApiUrl: string
}

export const ProfileAvatar: React.FC<Props> = ({
	shape = 'CIRCLE',
	size,
	border,
	avatar,
	children,
	style,
	userApiUrl,
}) => {
	const { getAvatarUrl } = useUserApi(userApiUrl)
	const url = avatar?.fileId && getAvatarUrl(avatar.fileId)
	return (
		<Relative
			style={{
				border: border,
				borderRadius: '50%',
				display: 'flex',
				height: size,
				width: size,
				minWidth: size,
				...style,
			}}
		>
			{url ? (
				<Image shape={shape} src={url} />
			) : (
				<PlaceholderContainer shape={shape}>
					<Avatar color={avatar?.color} square={shape === 'SQUARE'} />
				</PlaceholderContainer>
			)}
			{children}
		</Relative>
	)
}
