const MicrophoneIcon: React.FC = () => (
	<svg
		data-name="Group 25545"
		xmlns="http://www.w3.org/2000/svg"
		width="16.666"
		height="24"
		viewBox="0 0 16.666 24"
	>
		<path
			data-name="Path 11787"
			d="M8.333 14.4a3.57 3.57 0 0 0 3.56-3.6l.007-7.2a3.571 3.571 0 1 0-7.143 0v7.2a3.58 3.58 0 0 0 3.571 3.6m-6.31-3.6a6.2 6.2 0 0 0 6.31 6.12 6.2 6.2 0 0 0 6.31-6.12h2.024a8.338 8.338 0 0 1-7.143 8.064V21.6H11.9V24H4.762v-2.4h2.38v-2.736A8.361 8.361 0 0 1 0 10.991V10.8z"
		/>
	</svg>
)

export default MicrophoneIcon
