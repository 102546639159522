import styled from '@emotion/styled'
import colors from '../colors'
import Checked from '../images/checked.svgx'
import Invalid from '../images/failed.svgx'

const Blank = styled.div`
	border: 1px solid ${colors.lightGray};
	border-radius: 50%;
	height: 20px;
	width: 20px;
`

type Props = {
	size: string
	state: 'VALID' | 'INVALID' | 'EMPTY'
	color?: string
}

const Checkmark = ({ size, state, color }: Props): JSX.Element => {
	if (state === 'VALID') {
		return (
			<Checked
				style={{
					height: size,
					width: size,
					color: color || colors.greenishCyan,
				}}
			/>
		)
	} else if (state === 'INVALID') {
		return (
			<Invalid
				style={{ height: size, width: size, color: color || colors.pinkRed }}
			/>
		)
	} else {
		return (
			<Blank style={{ height: size, width: size, backgroundColor: color }} />
		)
	}
}

export default Checkmark
