export const BoardletIcon = (): JSX.Element => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="21"
		height="20"
		viewBox="0 0 21 20"
	>
		<g id="Group_51327" data-name="Group 51327" transform="translate(0.5 0.5)">
			<path
				id="Path_12429"
				data-name="Path 12429"
				d="M8.107,3.815h-7A1.11,1.11,0,0,1,0,2.7v-1.6A1.109,1.109,0,0,1,1.11,0h7A1.109,1.109,0,0,1,9.22,1.109v1.6A1.109,1.109,0,0,1,8.11,3.816"
				transform="translate(0 0)"
				fill="currentColor"
				stroke="rgba(0,0,0,0)"
				strokeWidth="1"
				fillRule="evenodd"
			/>
			<path
				id="Path_12430"
				data-name="Path 12430"
				d="M18.887,3.815h-7a1.109,1.109,0,0,1-1.11-1.109v-1.6A1.109,1.109,0,0,1,11.887,0h7A1.109,1.109,0,0,1,20,1.109v1.6a1.109,1.109,0,0,1-1.11,1.109"
				transform="translate(0 0)"
				fill="currentColor"
				stroke="rgba(0,0,0,0)"
				strokeWidth="1"
				fillRule="evenodd"
			/>
			<path
				id="Path_12431"
				data-name="Path 12431"
				d="M5.528,8.5l1.648,2.2H2.037L3.324,9.059l.913,1.092Z"
				transform="translate(0 -0.01)"
				fill="currentColor"
				stroke="rgba(0,0,0,0)"
				strokeWidth="1"
				fillRule="evenodd"
			/>
			<path
				id="Path_12432"
				data-name="Path 12432"
				d="M8.111,4.93h-7A1.105,1.105,0,0,0,0,6.03H0V17.9a1.114,1.114,0,0,0,1.111,1.11h7a1.106,1.106,0,0,0,1.1-1.11V6.031a1.1,1.1,0,0,0-1.1-1.1m-.2,5.738a1.647,1.647,0,0,1-1.644,1.65H2.954a1.653,1.653,0,0,1-1.648-1.655V7.779A1.645,1.645,0,0,1,2.95,6.133H6.259a1.639,1.639,0,0,1,1.648,1.63v.016Z"
				transform="translate(0 -0.006)"
				fill="currentColor"
				stroke="rgba(0,0,0,0)"
				strokeWidth="1"
				fillRule="evenodd"
			/>
			<path
				id="Path_12433"
				data-name="Path 12433"
				d="M18.889,4.92h-7a1.114,1.114,0,0,0-1.111,1.11V17.89A1.107,1.107,0,0,0,11.883,19h7.006A1.107,1.107,0,0,0,20,17.9V6.03a1.114,1.114,0,0,0-1.111-1.11m-3.251,7.4H12.824a.727.727,0,0,1-.731-.722V11.59a.734.734,0,0,1,.729-.74h2.816a.742.742,0,0,1,.741.74.735.735,0,0,1-.739.73h0m2.315-2.065h-5.13a.727.727,0,0,1-.731-.722V9.526a.734.734,0,0,1,.729-.74h5.132a.742.742,0,0,1,.741.74.735.735,0,0,1-.739.73h0m0-2.062h-5.13a.726.726,0,0,1-.731-.722V7.464a.734.734,0,0,1,.729-.74h5.132a.742.742,0,0,1,.741.74.735.735,0,0,1-.739.73h0"
				transform="translate(0 -0.006)"
				fill="currentColor"
				stroke="rgba(0,0,0,0)"
				strokeWidth="1"
				fillRule="evenodd"
			/>
		</g>
	</svg>
)
