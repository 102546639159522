import { colors, UserId } from '@beelday/common'
import styled from '@emotion/styled'
import ProfileAvatar from 'users/profile-avatar'
import { useProfileFor } from 'users/redux'

const ProfileAvatarOverlay = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding: 5%;
	background-color: ${colors.black};

	div {
		width: 100%;
		height: 100%;
	}
	img {
		height: 100%;
		width: auto;
	}

	svg {
		width: 100%;
		height: 100%;
	}
`

type Props = {
	userId: UserId
}

export const VideoStreamAvatarOverlay = ({ userId }: Props): JSX.Element => {
	const userProfile = useProfileFor(userId)

	return (
		<ProfileAvatarOverlay>
			<ProfileAvatar profile={userProfile} />
		</ProfileAvatarOverlay>
	)
}
