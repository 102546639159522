export const Time: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="100%"
		height="100%"
		viewBox="0 0 20 20"
	>
		<g id="Group_4462" data-name="Group 4462" transform="translate(-246 -246)">
			<path
				id="Path_8902"
				data-name="Path 8902"
				d="M256,246a10,10,0,1,0,10,10A10,10,0,0,0,256,246Zm0,18.182A8.182,8.182,0,1,1,264.182,256,8.182,8.182,0,0,1,256,264.182Z"
				fill="currentColor"
			/>
			<path
				id="Path_8903"
				data-name="Path 8903"
				d="M256.909,255.624v-5.079a.909.909,0,0,0-1.818,0V256a.912.912,0,0,0,.266.643l2.728,2.727a.909.909,0,0,0,1.285-1.285Z"
				fill="currentColor"
			/>
		</g>
	</svg>
)
