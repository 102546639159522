import { PropsOf } from '@emotion/react'
import styled from '@emotion/styled'
import {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useLayoutEffect,
	useRef,
	useState,
} from 'react'
import colors from '../colors'
import { Relative } from './containers'

const TextArea = styled.textarea`
	background-color: transparent;
	border: none;
	display: block;
	flex: 1;
	font-family: Ubuntu;
	font-size: 12px;
	padding: 0px;
	width: 100%;
	resize: none;
	min-height: 12px;

	::placeholder {
		color: ${colors.gray};
	}

	:focus {
		outline: none;
	}
`

type Props = PropsOf<typeof TextArea> & {
	value: string
	maxHeight: number
}

export type ExpandableTextAreaRef = {
	focusAtEnd: () => void
}

export const ExpandableTextArea = forwardRef<ExpandableTextAreaRef, Props>(
	(props, ref): JSX.Element => {
		const { autoFocus, value, maxHeight, ...rest } = props
		const textAreaRef = useRef<HTMLTextAreaElement>(null)

		const focusAtEnd = useCallback(() => {
			textAreaRef.current?.setSelectionRange(
				textAreaRef.current.value.length,
				textAreaRef.current.value.length
			)
			textAreaRef.current?.focus()
		}, [])

		useImperativeHandle(ref, () => ({
			focusAtEnd,
		}))

		useEffect(() => {
			if (autoFocus && textAreaRef.current) {
				focusAtEnd()
			}
		}, [autoFocus, focusAtEnd, textAreaRef])

		useLayoutEffect(() => {
			if (textAreaRef.current) {
				textAreaRef.current.style.height = '0px'

				const { scrollHeight } = textAreaRef.current

				if (scrollHeight > maxHeight) {
					textAreaRef.current.style.height = maxHeight + 'px'
				} else {
					textAreaRef.current.style.height = scrollHeight + 'px'
				}
			}
		}, [autoFocus, maxHeight, textAreaRef, value])

		return (
			<TextArea
				id="expandable-text-area"
				ref={textAreaRef}
				value={value}
				rows={1}
				{...rest}
			/>
		)
	}
)

ExpandableTextArea.displayName = 'ExpandableTextArea'

const RelativeContainer = styled(Relative)`
	font-size: 14px;
	border: 1px solid ${colors.borderLightGray};
	border-radius: 11px;
	color: ${colors.black70};
	padding: 20px 16px;

	&.focused {
		outline: 2px solid #b074b1;
		outline-offset: -2px;
	}
`

const AbsoluteCounter = styled.div`
	position: absolute;
	right: 6px;
	bottom: 4px;
	font-size: 12px;
	color: ${colors.heather};
`

export const ExpandableTextAreaWithCounter = forwardRef<
	ExpandableTextAreaRef,
	Props & { limit?: number }
>((props, ref): JSX.Element => {
	const { autoFocus, value, maxHeight, style, onChange, ...rest } = props
	const textAreaRef = useRef<HTMLTextAreaElement>(null)
	const containerRef = useRef<HTMLDivElement>(null)

	const [height, setHeight] = useState<string>('0px')

	const focusAtEnd = useCallback(() => {
		textAreaRef.current?.setSelectionRange(
			textAreaRef.current.value.length,
			textAreaRef.current.value.length
		)
		textAreaRef.current?.focus()

		if (containerRef.current) {
			containerRef.current.classList.add('focused')
		}
	}, [])

	useImperativeHandle(ref, () => ({
		focusAtEnd,
	}))

	useEffect(() => {
		if (autoFocus && textAreaRef.current) {
			focusAtEnd()
		}
	}, [autoFocus, focusAtEnd, textAreaRef])

	const focus = useCallback(() => {
		if (document.activeElement !== textAreaRef.current) {
			focusAtEnd()
		}
	}, [focusAtEnd])

	useEffect(() => {
		if (textAreaRef.current) {
			textAreaRef.current.style.height = '0px'

			const { scrollHeight } = textAreaRef.current

			if (scrollHeight > maxHeight) {
				textAreaRef.current.style.height = maxHeight + 'px'
				setHeight(textAreaRef.current.style.height)
			} else {
				textAreaRef.current.style.height = scrollHeight + 'px'

				setHeight(textAreaRef.current.style.height)
			}
		}
	}, [autoFocus, maxHeight, textAreaRef, value, height])

	return (
		<RelativeContainer
			ref={containerRef}
			onClick={focus}
			style={{
				border: style?.border,
				padding: style?.padding,
				paddingBottom: '20px',
			}}
		>
			<TextArea
				id="expandable-text-area"
				ref={textAreaRef}
				value={value}
				onChange={e => {
					if (onChange && e.target.value.length <= (props?.limit || 1000)) {
						onChange(e)
					}
				}}
				onFocus={() => {
					if (containerRef.current) {
						containerRef.current.classList.add('focused')
					}
				}}
				onBlur={() => {
					if (containerRef.current) {
						containerRef.current.classList.remove('focused')
					}
				}}
				rows={1}
				{...rest}
				style={{ ...style, border: 'none', padding: '0px' }}
				maxLength={props.limit}
			/>
			<AbsoluteCounter>
				{value.length} / {props.limit}
			</AbsoluteCounter>
		</RelativeContainer>
	)
})
ExpandableTextAreaWithCounter.displayName = 'ExpandableTextAreaWithCounter'
