import React from 'react'

export const IconChecked: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="100%"
		height="100%"
		viewBox="0 0 9.414 7.121"
	>
		<path
			id="Path_11515"
			data-name="Path 11515"
			d="M19336.945,6723.5l3,3,5-5"
			transform="translate(-19336.238 -6720.793)"
			fill="none"
			stroke="currentColor"
			strokeWidth="2"
		/>
	</svg>
)
