import { useCallback } from 'react'
import { Config } from 'common/config'
import { useReconnectingWebsocket } from './use-reconnecting-websocket'

export type TalkDispersionReporter = (data: number) => void

type UseTalkDispersionReporting = (
	user?: string,
	email?: string,
	group?: string
) => TalkDispersionReporter | undefined

export const useTalkDispersionReporting: UseTalkDispersionReporting = (
	user,
	email,
	group
) => {
	const shouldConnect = Boolean(user && group)
	const { sendJsonMessage } = useReconnectingWebsocket(
		Config.userEngagementReportingUrl,
		shouldConnect
	)

	const sendReport = useCallback(
		(audio_level: number) => {
			const timestamp = new Date().toISOString()
			const message = {
				type: 'audio_report',
				user,
				email,
				group,
				audio_level,
				timestamp,
			}

			sendJsonMessage(message)
		},
		[user, group, email]
	)

	return shouldConnect ? sendReport : undefined
}
