import { ui } from '@beelday/common'
import styled from '@emotion/styled'
import { PublishBitrates } from 'common/publish-bitrates'
import { RootState } from 'common/redux'
import {
	Group,
	GroupAddress,
	Media,
	TrainerUnavailableReason,
} from 'common/types'
import { useAuthenticatedBeeldayClient } from 'connectivity/beelday-hooks'
import ConfiguredScreenShareGroupTrainee from 'features/screen-share/screen-share-group'
import UserEngagementReporter from 'features/user-engagement/user-engagement-reporter'
import { findKey } from 'lodash'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { colorToHex } from 'room/common/presentation/colorsInHex'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { useAssertedWorkflowUser } from 'room/common/use-workflow-user'
import { ConfiguredEtherPad } from 'room/group-room/configured-ether-pad'
import { filterSceneVideoUsers } from 'room/group-room/model/scene-video-users'
import GroupFlexBackgrounds from 'room/group-room/presentation/group-flex-backgrounds'
import { VideoColumns } from 'room/group-room/presentation/video-columns'
import {
	activateEtherPad,
	activateExcalidraw,
	changeGroupName,
	deactivateEtherPad,
	deactivateExcalidraw,
	groupPutHandDown,
	groupRaiseHand,
	isTrainerInGroup,
	selectGroups,
} from 'room/group-room/redux'
import EqualVideosContainer from 'video-conference-media/presentation/equal-videos-container'
import {
	allStreams,
	selectPublishBitRate,
	setPublishBitrate,
} from 'video-conference-media/redux'
import { getEndUsers, getUserGroup } from '../common/model/room'
import { ConfiguredExcalidraw } from './configured-excalidraw'
import { RoomTrainee } from './presentation/room-trainee'
import { useGroupDetails } from './use-group-details'
import useScreenShareInGroup from 'features/screen-share/use-screen-share-in-group'
import { setIsExpandableCompanyCard } from 'organization/redux'

const GrowingContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	min-height: 0px;
`

const TwoColumnGrid = styled.div`
	width: 100%;
	display: flex;
	flex-grow: 1;
	min-height: 0px;
`

const VideoContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100px;

	${ui.responsive.desktop1440} {
		width: 200px;
	}
`

export const ConfiguredGroupRoomTrainee: FC = () => {
	const joinedRoomAddress = useAssertedJoinedRoomAddress()
	const workflowUser = useAssertedWorkflowUser()
	const groups = useSelector(selectGroups)
	const currentGroup = getUserGroup(groups, workflowUser)
	const currentGroupId = currentGroup?.groupDescription.id || ''
	const groupAddress = useMemo(() => {
		return { ...joinedRoomAddress, groupId: currentGroupId }
	}, [joinedRoomAddress, currentGroupId])

	if (!groups) return <div>No groups or no currentUser</div>

	if (!currentGroup || !currentGroupId)
		return <div>User is not in any group</div>

	return <GroupRoomTrainee address={groupAddress} group={currentGroup} />
}

type Props = { address: GroupAddress; group: Group }
const GroupRoomTrainee: FC<Props> = ({ address, group }) => {
	const currentGroupId = group.groupDescription.id

	const beeldayClient = useAuthenticatedBeeldayClient()
	const details = useGroupDetails(address)
	const trainerIsInGroup = useSelector((state: RootState) =>
		isTrainerInGroup(state, currentGroupId)
	)

	const sceneVideoUsers = useSelector(allStreams)

	const dispatch = useDispatch()
	dispatch(setIsExpandableCompanyCard(true))

	const etherPadState = useSelector(
		(state: RootState) => state.groupRoom.etherPadsStates[currentGroupId]
	)
	const excalidrawState = useSelector(
		(state: RootState) => state.groupRoom.excalidraw[currentGroupId]
	)

	const groupVideoSceneUsers = useMemo(
		() => filterSceneVideoUsers(getEndUsers(group.users), sceneVideoUsers),
		[group, sceneVideoUsers]
	)

	const { userGroupScreenShareState } = useScreenShareInGroup()

	const mediaState: Record<Media, { active?: boolean }> = {
		EtherPad: etherPadState,
		Excalidraw: excalidrawState,
	}

	const openMedia = findKey(mediaState, 'active') as Media | undefined

	const publishBitrate = useSelector(selectPublishBitRate)

	const announcement = useMemo(() => {
		if (group.attention.type !== 'TRAINER_UNAVAILABLE') {
			return undefined
		}
		switch (group.attention.reason) {
			case TrainerUnavailableReason.IN_OTHER_GROUP:
				return 'groups.notification.in_other_group'
			case TrainerUnavailableReason.BE_RIGHT_BACK:
				return 'groups.notification.be_right_back'
		}
	}, [group.attention])

	const handleRaiseHand = useCallback(() => {
		dispatch(groupRaiseHand(currentGroupId))
		beeldayClient.raiseHand(address)
	}, [beeldayClient, address, currentGroupId, dispatch])

	const handlePutHandDown = useCallback(() => {
		dispatch(groupPutHandDown(currentGroupId))
		beeldayClient.lowerHand(address)
	}, [beeldayClient, address, currentGroupId, dispatch])

	const handleOpenExcalidraw = useCallback(() => {
		dispatch(deactivateEtherPad(currentGroupId))
		dispatch(activateExcalidraw(currentGroupId))
		beeldayClient.openExcalidraw(address)
	}, [beeldayClient, address, currentGroupId, dispatch])

	const handleCloseExcalidraw = useCallback(() => {
		dispatch(deactivateExcalidraw(currentGroupId))
		beeldayClient.closeExcalidraw(address)
	}, [beeldayClient, address, currentGroupId, dispatch])

	const handleOpenEtherpad = useCallback(() => {
		dispatch(deactivateExcalidraw(currentGroupId))
		dispatch(activateEtherPad(currentGroupId))
		beeldayClient.openEtherPad(address)
	}, [beeldayClient, address, currentGroupId, dispatch])

	const handleCloseEtherpad = useCallback(() => {
		dispatch(deactivateEtherPad(currentGroupId))
		beeldayClient.closeEtherPad(address)
	}, [beeldayClient, address, currentGroupId, dispatch])

	const handleGroupNameChange = useCallback(
		(name: string) => {
			beeldayClient.changeGroupName(address, name)
			dispatch(changeGroupName(currentGroupId, name))
		},
		[beeldayClient, address, currentGroupId, dispatch]
	)

	const userCount = groupVideoSceneUsers.length + (trainerIsInGroup ? 1 : 0)

	useEffect(() => {
		let bitrate
		if (openMedia || userCount > 4 || userCount === 1) {
			bitrate = PublishBitrates.low
		} else if (userCount > 2) {
			bitrate = PublishBitrates.medium
		} else {
			bitrate = PublishBitrates.high
		}

		if (publishBitrate !== bitrate) {
			dispatch(setPublishBitrate(bitrate))
		}
	}, [dispatch, openMedia, userCount, publishBitrate])

	useEffect(() => {
		if (userGroupScreenShareState?.active) {
			beeldayClient.closeEtherPad(address)
			beeldayClient.closeExcalidraw(address)
		}
	}, [
		address,
		beeldayClient,
		currentGroupId,
		dispatch,
		userGroupScreenShareState?.active,
	])

	return (
		<RoomTrainee
			taskDetails={details}
			group={group}
			onTimerFinish={() => null}
			timerInitialValue={5 * 30}
			onGroupNameChange={handleGroupNameChange}
			onRaiseHand={handleRaiseHand}
			onPutHandDown={handlePutHandDown}
			onOpenEtherPad={handleOpenEtherpad}
			onCloseEtherPad={handleCloseEtherpad}
			onOpenExcalidraw={handleOpenExcalidraw}
			onCloseExcalidraw={handleCloseExcalidraw}
			openMedia={openMedia}
			trainerIsInGroup={trainerIsInGroup}
			announcement={announcement}
		>
			<UserEngagementReporter group={group.groupDescription.id} />
			{openMedia || userGroupScreenShareState?.active ? (
				<GrowingContainer>
					<TwoColumnGrid>
						{userGroupScreenShareState?.active ? (
							<ConfiguredScreenShareGroupTrainee />
						) : openMedia === 'EtherPad' ? (
							<ConfiguredEtherPad
								groupAddress={address}
								groupColor={group.groupDescription.color}
							/>
						) : (
							<ConfiguredExcalidraw
								groupAddress={address}
								groupColor={group.groupDescription.color}
							/>
						)}
						<VideoContainer>
							<VideoColumns sceneVideoUsers={groupVideoSceneUsers} />
						</VideoContainer>
					</TwoColumnGrid>
				</GrowingContainer>
			) : (
				<GroupFlexBackgrounds
					colorHex={colorToHex[group.groupDescription.color]}
				>
					<EqualVideosContainer videos={groupVideoSceneUsers} />
				</GroupFlexBackgrounds>
			)}
		</RoomTrainee>
	)
}
