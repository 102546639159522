import { intl } from '@beelday/common'
import { GroupReview } from 'common/types'
import { useAuthenticatedBeeldayClient } from 'connectivity/beelday-hooks'
import CamMenuItem from 'features/camera/camera-menu-item'
import { MicMenuItem } from 'features/microphone'
import ScreenShareMenuItem from 'features/screen-share/screen-share-with-permission-menu-item'
import useScreenShare from 'features/screen-share/use-screen-share'
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import {
	Menu,
	MenuItemLine,
	MenuToggleItem,
} from 'room/common/presentation/menu'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { useAssertedWorkflowUser } from 'room/common/use-workflow-user'
import {
	handleReviewEtherPadClosed,
	handleReviewEtherPadOpened,
	handleReviewExcalidrawClosed,
	handleReviewExcalidrawOpened,
} from 'room/training-room/redux'
import { Document } from '../../common/presentation/icon/document'
import { Whiteboard } from '../../common/presentation/icon/whiteboard'
import MiscPanelMenu from '../../misc/misc-panel-menu'

type Props = {
	group: GroupReview
	activeMedia?: 'ETHERPAD' | 'EXCALIDRAW' | null
}

const ReviewMenuTrainee: FC<Props> = ({ group, activeMedia }) => {
	const dispatch = useDispatch()
	const beeldayClient = useAuthenticatedBeeldayClient()
	const address = useAssertedJoinedRoomAddress()
	const currentUser = useAssertedWorkflowUser()
	const groupAddress = { ...address, groupId: group.id }
	const currentUserGroup = !!group.members.find(m => m.id === currentUser.id)

	const { screenShareState } = useScreenShare()

	const startExcalidraw = () => {
		if (activeMedia === 'EXCALIDRAW') {
			beeldayClient.closeExcalidrawForReview(groupAddress)
		} else {
			beeldayClient.openExcalidrawForReview(groupAddress)
			dispatch(handleReviewEtherPadClosed(group.id))
			dispatch(handleReviewExcalidrawOpened(group.id))
		}
	}

	const closeExcalidraw = () => {
		if (activeMedia === 'EXCALIDRAW') {
			beeldayClient.closeExcalidrawForReview(groupAddress)
		}
	}

	const startEtherpad = () => {
		if (currentUserGroup) {
			if (activeMedia === 'ETHERPAD') {
				beeldayClient.closeEtherPadForReview(address)
			} else {
				beeldayClient.openEtherPadForReview(address)
				dispatch(handleReviewExcalidrawClosed(group.id))
				dispatch(handleReviewEtherPadOpened(group.id))
			}
		}
	}

	const closeEtherpad = () => {
		if (activeMedia === 'ETHERPAD') {
			beeldayClient.closeEtherPadForReview(address)
		}
	}

	return (
		<Menu>
			<MenuToggleItem
				icon={<Whiteboard />}
				disabled={!currentUserGroup || !!screenShareState.user}
				active={activeMedia === 'EXCALIDRAW'}
				onClick={
					activeMedia === 'EXCALIDRAW' ? closeExcalidraw : startExcalidraw
				}
				label={<intl.Translate>room_menu.whiteboard</intl.Translate>}
			/>
			<MenuToggleItem
				icon={<Document />}
				disabled={!currentUserGroup || !!screenShareState.user}
				active={activeMedia === 'ETHERPAD'}
				onClick={activeMedia === 'ETHERPAD' ? closeEtherpad : startEtherpad}
				label={<intl.Translate>room_menu.notepad</intl.Translate>}
			/>
			<ScreenShareMenuItem canStart={!!!screenShareState.user} />
			<MicMenuItem />
			<CamMenuItem />
			<MenuItemLine />
			<MiscPanelMenu />
		</Menu>
	)
}

export default ReviewMenuTrainee
