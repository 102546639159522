import { css } from '@emotion/react'
import { noop, reduce } from 'lodash'
import { FC, useMemo } from 'react'
import {
	GroupDescription,
	GroupId,
	GroupSuggestionAssignment,
	User,
} from 'common/types'
import GroupPickerLayout from 'room/training-room/set-groups/presentation/group-picker-layout'
import InfoBanner from 'common/presentation/info-banner'
import ConfirmBanner from 'common/presentation/confirm-banner'
import { GroupFormingStage } from 'room/training-room/set-groups/types'
import { translate } from 'intl/translate'
import AroundLayout from 'room/training-room/set-groups/presentation/around-layout'
import BottomLayout from 'room/training-room/set-groups/presentation/bottom-layout'
import UserToPick from 'room/training-room/set-groups/presentation/user-to-pick'
import { DragDropContext } from 'react-beautiful-dnd'
import UserToPickPlaceholder from 'room/training-room/set-groups/presentation/user-to-pick-placeholder'
import { SceneVideoUser } from 'video-conference-media'

type Props = {
	groups: GroupSuggestionAssignment[]
	sceneVideoUsers: SceneVideoUser[]
	selectedGroup?: GroupDescription
	stage: GroupFormingStage
	unassignedUsers: User[]
	users: User[]

	onAcceptGroup: () => void
	onDeclineGroup: () => void
	onGroupSelect: (groupId: GroupId) => void
}

const GroupPickerTrainee: FC<Props> = ({
	groups,
	sceneVideoUsers,
	selectedGroup,
	stage,
	unassignedUsers,
	users,
	onAcceptGroup,
	onDeclineGroup,
	onGroupSelect,
}) => {
	const streamsByID: { [key: string]: SceneVideoUser } = useMemo(
		() =>
			reduce(
				sceneVideoUsers,
				(acc, u) => {
					return { ...acc, [u.userId]: u }
				},
				{}
			),
		[sceneVideoUsers]
	)
	const numberOfUsers = users.length

	return (
		<DragDropContext onDragEnd={noop}>
			{stage === GroupFormingStage.SELECT_GROUP ? (
				<InfoBanner message="groups.select_group" />
			) : stage === GroupFormingStage.CONFIRM_GROUP ? (
				<ConfirmBanner
					message={translate('groups.confirm_group', {
						groupName: selectedGroup?.name,
					})}
					onAccept={onAcceptGroup}
					onDecline={onDeclineGroup}
				/>
			) : (
				<InfoBanner>
					{translate('groups.confirmed', { groupName: selectedGroup?.name })}
				</InfoBanner>
			)}
			{numberOfUsers <= 8 ? (
				<BottomLayout
					center={
						<GroupPickerLayout
							droppable={false}
							groups={groups}
							streams={streamsByID}
							onGroupSelect={
								stage === GroupFormingStage.CONFIRMED
									? undefined
									: onGroupSelect
							}
						/>
					}
				>
					{users.map((u, i) => {
						return unassignedUsers.find(uu => uu.id === u.id) ? (
							<div
								css={css`
									margin: 5px;
									height: 100%;
									width: 100%;
								`}
								key={u.id}
							>
								<UserToPick
									draggable={false}
									id={u.id}
									index={i}
									key={u.id}
									stream={streamsByID[u.id]}
								/>
							</div>
						) : (
							<UserToPickPlaceholder key={u.id} />
						)
					})}
				</BottomLayout>
			) : (
				<AroundLayout
					center={
						<GroupPickerLayout
							droppable={false}
							groups={groups}
							streams={streamsByID}
							onGroupSelect={
								stage === GroupFormingStage.CONFIRMED
									? undefined
									: onGroupSelect
							}
						/>
					}
				>
					{users.map((u, i) => {
						return unassignedUsers.find(uu => uu.id === u.id) ? (
							<div
								css={css`
									margin: 5px;
									height: 100%;
									width: 100%;
								`}
							>
								<UserToPick
									draggable={false}
									id={u.id}
									index={i}
									key={u.id}
									stream={streamsByID[u.id]}
								/>
							</div>
						) : (
							<UserToPickPlaceholder key={u.id} />
						)
					})}
				</AroundLayout>
			)}
		</DragDropContext>
	)
}

export default GroupPickerTrainee
