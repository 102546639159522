import { Color } from 'common/types'
import { Colors } from 'common/presentation/colors'

type ColorToHex = {
	[key in Color]: string
}

export const colorToHex: ColorToHex = {
	blue: '#49c9e6',
	gray: '#a19e99',
	green: '#46c35a',
	navy: '#063d66',
	orange: '#f57655',
	pink: '#f880aa',
	purple: '#bb56d6',
	yellow: '#fac55b',
	white: '#ffffff',
	indigo: Colors.indigoBlue,
}
