import styled from '@emotion/styled'
import colors from '../colors'

type TabContentProps = {
	id: string
	activeTab: string
	children: JSX.Element
}

type TabNavItemProps = {
	id: string
	title: string
	activeTab: string
	setActiveTab: (tab: string) => unknown
}

const TabNavButton = styled.div`
	cursor: pointer;
	position: relative;
	z-index: 1;
	font-size: 14px;
	line-height: 17px;
	font-weight: 500;
	color: ${colors.heather};
	padding: 15px;
	transition: color 0.25s;

	&:after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 0;
		height: 2px;
		background-color: ${colors.indigoBlue};
		transition: width 0.25s;
	}

	&.active {
		color: ${colors.indigoBlue};

		&:after {
			width: 100%;
		}
	}
`

export const TabNav = styled.div`
	position: relative;
	display: flex;
	padding: 0 25px;

	&:after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 1px;
		background-color: ${colors.brightGray};
	}
`

export const TabContent = ({
	id,
	activeTab,
	children,
}: TabContentProps): JSX.Element | null => {
	return activeTab === id ? <div className="TabContent">{children}</div> : null
}

export const TabNavItem = ({
	id,
	title,
	activeTab,
	setActiveTab,
}: TabNavItemProps): JSX.Element => {
	const handleClick = () => {
		setActiveTab(id)
	}

	return (
		<TabNavButton
			onClick={handleClick}
			className={activeTab === id ? 'active' : ''}
		>
			{title}
		</TabNavButton>
	)
}
