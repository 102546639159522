import {
	InteractionSchemeRoomTransferWithCountdownEvent,
	InteractionSchemeStateChangeEvent,
	RoomId,
	RoomType,
	UserId,
} from 'common/types'

export const getCurrentRoom = (
	interactionSchemeEvent: InteractionSchemeStateChangeEvent,
	userId: string
) => {
	const roomStates = interactionSchemeEvent?.state?.roomStates
	const userRoom = roomStates.find(roomState =>
		roomState.users.includes(userId)
	)
	// TODO user without room
	if (!userRoom) {
		return null
	}

	return { roomId: userRoom.roomId, roomType: userRoom.roomType }
}

export const getLobbyAddress = (
	interactionSchemeEvent?: InteractionSchemeStateChangeEvent
): RoomId | undefined => {
	const roomStates = interactionSchemeEvent?.state?.roomStates || []
	const lobby = roomStates.find(r => r.roomType === RoomType.Lobby)
	return lobby?.roomId
}

export const findGroup = (
	event: InteractionSchemeRoomTransferWithCountdownEvent,
	userId: UserId
) => {
	if (event.payload.type !== 'TO_GROUP_ROOM') {
		throw Error(`Unexpected payload type type: ${event.payload.type}`)
	}
	return event.payload.groups.find(group =>
		group.users.find(user => user.userId === userId)
	)
}

export function isUsersTransfer(
	transfer: InteractionSchemeRoomTransferWithCountdownEvent,
	userId: UserId
) {
	return transfer.transferAll || transfer.usersToTransfer.includes(userId)
}
