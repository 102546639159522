import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import styled from '@emotion/styled'
import colors from '../colors'
import success from '../images/circle-ok.svg'
import error from '../images/triangle-error.svg'
import warning from '../images/circle-warning.svg'
import info from '../images/circle-info.svg'

const StyledToastContainer = styled(ToastContainer)`
	.Toastify__toast {
		min-height: 56px;
		--toastify-color-info: ${colors.indigoBlue};
		--toastify-color-success: ${colors.greenishCyan};
		--toastify-color-warning: ${colors.dustyOrange};
		--toastify-color-error: ${colors.strongPink};
		--toastify-color-progress-info: ${colors.indigoBlue};
		--toastify-color-progress-success: ${colors.greenishCyan};
		--toastify-color-progress-warning: ${colors.dustyOrange};
		--toastify-color-progress-error: ${colors.strongPink};
	}

	.Toastify__close-button {
		opacity: 1;
		margin: auto;
		padding: 8px;
		color: ${colors.heather};

		&:hover {
			color: ${colors.indigoBlue};
		}
	}

	.Toastify__toast-body {
		padding: 5px 11px;
		font-size: 14px;
		line-height: 17px;
	}

	.Toastify__close-button > svg {
		width: 20px;
		height: 20px;
	}

	.Toastify__toast-theme--light {
		background: ${colors.white};
		color: ${colors.black};
		box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.1);
	}

	.Toastify__toast-icon {
		width: 15px;

		img {
			width: 15px;
			height: 15px;
		}
	}
`

export const Toasts = (): JSX.Element => {
	const infoIcon = (type: 'info' | 'success' | 'warning' | 'error') => {
		switch (type) {
			case 'success':
				return success
			case 'error':
				return error
			case 'warning':
				return warning
			case 'info':
				return info
		}
	}

	return (
		<StyledToastContainer
			autoClose={2000}
			icon={({ type }) =>
				type !== 'default' ? <img src={infoIcon(type)} /> : null
			}
		/>
	)
}
