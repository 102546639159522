export const CloseIcon = (): JSX.Element => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="10.121"
		height="10.121"
		viewBox="0 0 10.121 10.121"
	>
		<g transform="translate(-1233.179 -214.179)">
			<line
				x2="8"
				y2="8"
				transform="translate(1234.24 215.24)"
				fill="none"
				stroke="currentColor"
				strokeLinecap="round"
				strokeWidth="1.5"
			/>
			<line
				x1="8"
				y2="8"
				transform="translate(1234.24 215.24)"
				fill="none"
				stroke="currentColor"
				strokeLinecap="round"
				strokeWidth="1.5"
			/>
		</g>
	</svg>
)
