export const Google = (): JSX.Element => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="36"
		height="36"
		viewBox="0 0 36 36"
	>
		<g data-name="Group 25654" transform="translate(-50 -623)">
			<circle
				data-name="Ellipse 760"
				cx="18"
				cy="18"
				r="18"
				transform="translate(50 623)"
				fill="#e93f33"
			/>
			<path
				data-name="Path 12111"
				d="M8 6.857c-.009.907 0 1.82 0 2.726 1.523.05 3.051.028 4.573.05-.673 3.381-5.273 4.478-7.7 2.267a5.119 5.119 0 0 1 .219-7.981 5.287 5.287 0 0 1 6.223.163 27.757 27.757 0 0 0 2.027-2.1A8.108 8.108 0 0 0 8 .007a8 8 0 0 0-2.9 15.458c3.117 1.2 7.113.381 9.1-2.418a9.174 9.174 0 0 0 1.444-6.185C13.1 6.846 10.543 6.852 8 6.857"
				transform="translate(60.158 633)"
				fill="#fff"
			/>
		</g>
	</svg>
)
