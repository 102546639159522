export const ExitIcon = (): JSX.Element => (
	<svg width="100%" height="100%" viewBox="0 0 22 18" version="1.1">
		<g transform="matrix(1,0,0,1,-5.02575,-5.23693)">
			<g id="Group_23266">
				<g id="Group_4062">
					<g id="Group_4061">
						<g id="Group_4060">
							<path
								id="Path_8647"
								d="M8.25,7.008L8.25,21.508L19.25,21.508L19.25,18.714L20.75,18.714L20.75,21.508C20.741,22.281 20.122,22.921 19.35,22.956L8.235,22.956C7.462,22.965 6.808,22.362 6.753,21.591L6.753,7.008C6.762,6.235 7.381,5.595 8.153,5.56L19.27,5.56C20.043,5.551 20.697,6.154 20.752,6.925L20.752,9.99L19.252,9.99L19.252,7.008L8.25,7.008Z"
								fill="currentColor"
							/>
							<path
								id="Path_8648"
								d="M22.822,16.686C22.522,16.962 22.491,17.432 22.752,17.745C22.991,18.032 23.417,18.084 23.718,17.863L23.776,17.816L26.576,15.285C26.8,15.092 26.93,14.81 26.93,14.514C26.93,14.256 26.832,14.008 26.657,13.82L23.782,11.19C23.651,11.069 23.479,11.001 23.301,11.001C23.092,11.001 22.893,11.094 22.759,11.254C22.513,11.544 22.517,11.974 22.769,12.259L22.821,12.313L25.227,14.513L22.827,16.686L22.822,16.686Z"
								fill="currentColor"
							/>
							<path
								id="Path_8649"
								d="M25.5,13.533C25.512,13.532 25.524,13.532 25.536,13.532C25.933,13.532 26.26,13.859 26.26,14.257C26.26,14.64 25.955,14.961 25.572,14.98L16.5,14.98C16.488,14.981 16.476,14.981 16.464,14.981C16.067,14.981 15.74,14.654 15.74,14.256C15.74,13.873 16.045,13.552 16.428,13.533L25.5,13.533Z"
								fill="currentColor"
							/>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
)
