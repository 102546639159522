import { ui } from '@beelday/common'
import { RootState } from 'common/redux'
import { ScriptDetails } from 'features/script'
import UserChat from 'messaging/chat'
import { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MiscSelectedSelectedItem, setMiscPanelOpen } from '../common/redux'
import MiscPanelWrapper from './misc-panel-wrapper'

const MiscPanel: FC = () => {
	const dispatch = useDispatch()
	const training = useSelector((state: RootState) => state.sessionInfo.training)
	const selectedItem = useSelector(
		(state: RootState) => state.room.miscPanel.selectedItem
	)
	const isOpen = useSelector((state: RootState) => state.room.miscPanel.isOpen)
	const participantsEmails =
		training?.participants
			.map(participant => participant.email)
			.concat([training.upperEchelon.email]) || []

	const close = useCallback(() => {
		dispatch(setMiscPanelOpen(false))
	}, [dispatch])

	let title
	switch (selectedItem) {
		case MiscSelectedSelectedItem.Agenda:
			title = 'lobby.misc.agenda'
			break
		case MiscSelectedSelectedItem.Messages:
			title = 'lobby.misc.messages'
			break
		case MiscSelectedSelectedItem.Boardlet:
			title = 'lobby.misc.BOARDLET'
			break
	}

	if (!training) return null

	let width
	switch (selectedItem) {
		case MiscSelectedSelectedItem.Boardlet:
			training.feedType === 'BOARDLET' ? (width = 1000) : (width = 700)
			break
		default:
			width = 500
	}

	return (
		<MiscPanelWrapper open={isOpen} onClose={close} title={title} width={width}>
			{selectedItem === MiscSelectedSelectedItem.Agenda && (
				<div
					style={{
						padding: '20px',
						flex: 1,
						overflowY: 'auto',
						overflowX: 'hidden',
						minWidth: `${width}px`,
					}}
				>
					<ScriptDetails />
				</div>
			)}
			{selectedItem === MiscSelectedSelectedItem.Messages && (
				<UserChat
					trainingId={training.id}
					participantsEmails={participantsEmails}
				/>
			)}
			{selectedItem === MiscSelectedSelectedItem.Boardlet && (
				<ui.FlexColumn
					style={{
						padding: '20px',
						flex: 1,
						minHeight: 0,
						overflow: 'auto',
					}}
				>
					<beelday-training-feed
						trainingId={training.id}
						trainerId={training.upperEchelon.userId}
						feedType={training.feedType}
						style={{ display: 'flex', flex: 1, minHeight: 0 }}
					/>
				</ui.FlexColumn>
			)}
		</MiscPanelWrapper>
	)
}

export default MiscPanel
