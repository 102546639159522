import {
	createContext,
	FC,
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import logger from '../logger'
import keycloak, { InitializedKeycloak } from './keycloak-facade'
import {
	Auth,
	AuthenticatedUser,
	AuthToken,
	KeycloakConfig,
	RefreshTokens,
	Tokens,
	TokenState,
} from './security-models'
import {
	clearTokens,
	getAuthenticatedUser,
	restoreTokens,
	saveTokens,
} from './session-storage'

const AuthContext = createContext<Auth | null>(null)
const log = logger.create('session')

export const useAuth = (): Auth => {
	const auth = useContext(AuthContext)
	if (!auth) {
		throw Error('Auth context has not been provided!')
	}
	return auth
}

export const useToken = (): TokenState => {
	const auth = useAuth()
	return auth.tokenState
}

export const useValidToken = (): AuthToken => {
	const auth = useAuth()

	if (auth.tokenState.type !== 'VALID_TOKENS') {
		throw new Error(`Invalid token.`)
	}

	return auth.tokenState.accessToken
}

export { getAuthenticatedUser }
export const useAuthenticatedUser = (): AuthenticatedUser => {
	const tokens = useToken()

	if (tokens.type !== 'VALID_TOKENS') {
		throw Error('No User authenticated')
	}

	return useMemo(
		() => getAuthenticatedUser(tokens.accessToken),
		[tokens.accessToken]
	)
}
export const useUserIfAuthenticated = (): AuthenticatedUser | undefined => {
	const tokens = useToken()

	return useMemo(() => {
		if (tokens.type === 'VALID_TOKENS') {
			return getAuthenticatedUser(tokens.accessToken)
		}
	}, [tokens])
}

const toTokenState = (tokens: Tokens | undefined): TokenState => {
	if (!tokens) {
		return { type: 'INVALID_TOKENS' }
	}

	if (isValidToken(tokens)) {
		return {
			type: 'VALID_TOKENS',
			accessToken: tokens.accessToken,
			expirationDate: tokens.accessTokenExpiration,
			refreshToken: tokens.refreshToken,
			refreshTokenExpiration: tokens.refreshTokenExpiration,
		}
	} else if (canRefresh(tokens)) {
		return {
			type: 'EXPIRED_TOKENS',
			refreshToken: tokens.refreshToken,
			refreshTokenExpiration: tokens.refreshTokenExpiration,
		}
	} else {
		return { type: 'INVALID_TOKENS' }
	}
}

type Props = {
	config: KeycloakConfig
}
export const ProvideAuth: FC<Props> = ({ children, config }) => {
	const [tokens, setTokenState] = useState(restoreTokens())
	const [kc, setKc] = useState<InitializedKeycloak | null>(null)

	const signOut = useCallback(() => {
		log.info('Sign out')
		clearTokens()
		setTokenState(undefined)
		if (kc?.keycloak.authenticated) {
			kc?.keycloak?.clearToken()
		}
	}, [kc])

	const setTokens = useCallback(
		(newTokens: Tokens, rememberMe?: boolean | undefined) => {
			if (isValidToken(newTokens)) {
				saveTokens(newTokens, rememberMe)
				setTokenState(newTokens)
			} else {
				clearTokens()
				setTokenState(undefined)
			}
		},
		[]
	)

	useEffect(() => {
		keycloak
			.initKeycloak(config)
			.then(kc => {
				setKc(kc)
				if (kc.tokens) {
					setTokens(kc.tokens)
				}
			})
			.catch(e => log.error('Failed to initialize Keycloak', e))
	}, [config, setTokens])

	const refreshing = useRef<Promise<Tokens>>()
	const refresh = useCallback(
		(refreshToken: RefreshTokens): Promise<Tokens> => {
			if (refreshing.current) return refreshing.current

			log.debug('Refreshing tokens')
			refreshing.current = keycloak
				.refreshToken(config, refreshToken.refreshToken)
				.then(update => {
					log.debug(
						'Refreshing tokens done',
						new Date(update.accessTokenExpiration),
						new Date(update.refreshTokenExpiration)
					)
					setTokens(update)
					return update
				})
				.finally(() => (refreshing.current = undefined))

			return refreshing.current
		},
		[config, setTokens]
	)

	useEffect(() => {
		if (tokens && canRefresh(tokens)) {
			//Refresh 30 seconds before expiration
			let refreshIn = tokens.accessTokenExpiration - Date.now() - 30000
			if (refreshIn < 0 && !canRefresh(tokens, 30000)) {
				//Make sure we logout when refresh token expires
				refreshIn = tokens.refreshTokenExpiration - Date.now() + 100
			}

			refreshIn = Math.max(refreshIn, 1000)

			log.debug('Will refresh token on', new Date(Date.now() + refreshIn))
			let timeout: NodeJS.Timeout | null = setTimeout(() => {
				refresh(tokens).catch(e => {
					log.error('Error refreshing tokens', e)
					if (tokens.accessTokenExpiration < Date.now()) {
						//Logout if current token expired and we could not refresh
						signOut()
					} else {
						//Make sure we refresh again
						setTokens({ ...tokens })
					}
				})
				timeout = null
			}, refreshIn)
			return () => {
				if (timeout) {
					log.debug('Clear token refresh timeout')
					clearTimeout(timeout)
				}
			}
		} else if (tokens) {
			signOut()
		}
	}, [tokens, signOut, refresh, setTokens])

	useEffect(() => {
		const refreshToken = () => {
			if (document.hidden) {
			} else {
				if (tokens && canRefresh(tokens)) {
					refresh(tokens).catch(e => {
						log.error('Error refreshing tokens', e)
						if (tokens.accessTokenExpiration < Date.now()) {
							//Logout if current token expired and we could not refresh
							signOut()
						} else {
							//Make sure we refresh again
							setTokens({ ...tokens })
						}
					})
				}
			}
		}
		document.addEventListener('visibilitychange', refreshToken)
		return () => {
			document?.removeEventListener('visibilitychange', refreshToken)
		}
	}, [refresh, setTokens, signOut, tokens])

	const signIn = useCallback(
		async (
			username: string,
			password: string,
			rememberMe: boolean
		): Promise<void> => {
			const result = await keycloak.passwordSignIn(
				username,
				password,
				config,
				rememberMe
			)
			setTokens(result, rememberMe)
		},
		[setTokens, config]
	)

	const identityUrls = useMemo(() => ({ google: kc?.google }), [kc])

	const auth = useMemo(
		() => ({
			identityUrls,
			signIn,
			signOut,
			tokenState: toTokenState(tokens),
			refresh,
		}),
		[identityUrls, signIn, signOut, tokens, refresh]
	)

	return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export const RequireLogin: FC<{
	children: ReactNode | (() => ReactNode)
	redirectTo: string
}> = ({ children, redirectTo }) => {
	const token = useToken()
	useEffect(() => {
		if (token?.type !== 'VALID_TOKENS') {
			window.location.replace(redirectTo)
		}
		return undefined
	}, [redirectTo, token])

	if (token.type === 'VALID_TOKENS') {
		return typeof children === 'function' ? children() : children
	} else {
		return null
	}
}

export const DevelopersOnly: FC<{
	children: ReactNode | (() => ReactNode)
	redirectTo: string
}> = ({ children, redirectTo }) => {
	const token = useToken()

	const isDev = useMemo(() => {
		return (
			token.type === 'VALID_TOKENS' &&
			!!getAuthenticatedUser(token.accessToken)._dev
		)
	}, [token])

	useEffect(() => {
		if (!isDev) {
			window.location.replace(redirectTo)
		}
		return undefined
	}, [isDev, redirectTo])

	if (isDev) {
		return typeof children === 'function' ? children() : children
	} else {
		return null
	}
}

export default ProvideAuth

const isValidToken = (tokens: Tokens): boolean => {
	return (
		tokens.accessTokenExpiration > Date.now() &&
		tokens.refreshTokenExpiration > Date.now()
	)
}

const canRefresh = (
	tokens: Tokens | null | undefined,
	minTimeLeft: number = 0
): boolean => {
	if (!tokens) return false
	return tokens.refreshTokenExpiration - minTimeLeft > Date.now()
}
