import { colors, icons, ui } from '@beelday/common'
import { css, SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'

const MenuButtonWrapper = styled.div`
	position: relative;

	&:hover {
		.popupMenuModal {
			opacity: 1;
			pointer-events: all;
			bottom: 100%;
		}
	}
`

const MenuButton = styled.button<{ active?: boolean }>`
	position: relative;
	background: none;
	border: none;
	box-shadow: none;
	padding: 0;
	margin: 0 2px;
	flex-direction: column;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${colors.indigo};
	font-size: 11px;
	transition: 0.2s ease-out;
	cursor: pointer;
	width: 60px;

	${ui.desktop1440} {
		width: 66px;
		font-size: 12px;
	}

	:disabled {
		cursor: not-allowed;
		opacity: 0.6;
	}

	:hover:enabled {
		transform: translateY(-4px);

		svg {
			fill: ${props => (props.active ? colors.whitish : colors.greenishCyan)};
		}
	}

	.label {
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`

const MenuButtonCircle = styled.i<{ active?: boolean }>`
	width: 46px;
	height: 46px;
	background-color: ${props =>
		props.active ? colors.indigoBlue : colors.whitish};
	box-shadow: 0 8px 12px 0 rgba(79, 23, 190, 0.35);
	display: flex;
	text-align: center;
	align-content: center;
	align-items: center;
	border-radius: 50%;
	margin-bottom: 2px;

	${ui.desktop1440} {
		width: 56px;
		height: 56px;
		margin-bottom: 4px;
	}

	svg {
		fill: ${props => (props.active ? colors.whitish : colors.indigo)};
		color: ${props => (props.active ? colors.whitish : colors.indigo)};
		margin-left: auto;
		margin-right: auto;
		max-width: 20px;
		max-height: 20px;

		${ui.desktop1440} {
			max-width: 100%;
			max-height: 100%;
		}
	}
`

const Number = styled.div`
	align-items: center;
	background-color: rgb(241, 49, 94);
	border-radius: 50%;
	border: 2px solid rgb(255, 255, 255);
	color: white;
	display: flex;
	font-size: 11px;
	font-weight: 500;
	height: 20px;
	justify-content: center;
	position: absolute;
	right: 8px;
	top: 3px;
	width: 20px;
`

export const MenuItemLine = styled.div`
	height: 40px;
	width: 1px;
	background-color: ${colors.darkIndigo15};
	margin: 0 10px;
	:first-child {
		display: none;
		margin: 0;
	}
	:last-child {
		display: none;
		margin: 0;
	}
`

type ItemProps = {
	onClick?: () => unknown
	icon: React.ReactNode
	label: string | React.ReactNode
	disabled?: boolean
	hidden?: boolean
	className?: string
}
export const MenuItem: React.FC<ItemProps> = ({
	children,
	onClick,
	icon,
	label,
	disabled,
	hidden,
}) => {
	if (hidden) return null
	return (
		<ui.Relative>
			<MenuButton disabled={disabled} onClick={onClick}>
				<MenuButtonCircle>{icon}</MenuButtonCircle>
				<div className="label">{label}</div>
			</MenuButton>
			{children}
		</ui.Relative>
	)
}

export type ToggleItemProps = ItemProps & {
	active?: boolean
	activeIcon?: React.ReactNode
	number?: number
	css?: SerializedStyles
}
export const MenuToggleItem: React.FC<ToggleItemProps> = ({
	children,
	active,
	activeIcon,
	icon,
	label,
	onClick,
	disabled,
	hidden,
	number,
	className,
	css,
}) => {
	if (hidden) return null
	const classes = className ? className : ''
	return (
		<MenuButtonWrapper className={`button-wrapper ${classes}`}>
			<MenuButton
				active={active}
				disabled={disabled}
				onClick={onClick}
				className={`button-container ${classes}`}
				css={css}
			>
				{number ? <Number>{number}</Number> : null}

				<MenuButtonCircle
					className={`button-circle ${classes}`}
					css={css}
					active={active}
				>
					{active && activeIcon ? activeIcon : icon}
				</MenuButtonCircle>
				<div className="label">{label}</div>
			</MenuButton>
			{children}
		</MenuButtonWrapper>
	)
}

export const MenuItemBubble = styled.div`
	position: absolute;
	top: -24px;
	overflow-y: auto;
	left: 50%;
	max-height: 50vh;
	background-color: ${colors.white};
	z-index: 1000;
	transform: translate(-50%, -100%);
	border-radius: 14px;
`

const MenuWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 6px 25px;
	border-radius: 48px;
	background-color: ${colors.lightPurple};
	margin: 4px 10px 10px 10px;

	${ui.desktop1440} {
		padding: 12px 32px;
	}
`

type MenuProps = {
	short?: boolean
	style?: React.CSSProperties
	children: React.ReactNode
}
export const Menu = React.forwardRef<HTMLDivElement, MenuProps>(
	({ children, short, style }, ref) => (
		<ui.FlexCenterAll
			ref={ref}
			css={css`
				width: ${short ? 'auto' : '100%'};
			`}
			style={style}
		>
			<MenuWrapper>{children}</MenuWrapper>
		</ui.FlexCenterAll>
	)
)
Menu.displayName = 'Menu'

const PopupMenuModal = styled.div`
	position: absolute;
	left: 50%;
	bottom: 90%;
	transform: translateX(-50%);
	padding-bottom: 25px;
	opacity: 0;
	pointer-events: none;
	z-index: 5;
	transition: opacity 0.25s, bottom 0.25s;
`

const PopupMenuMain = styled.div`
	width: 225px;
	border-radius: 10px;
	box-shadow: 0 5px 15px 0 rgba(19, 16, 13, 0.1);
	border: solid 1px #e2dfe4;
	background-color: ${colors.white};
`

const PopupMenuNested = styled.div`
	width: 225px;
	position: absolute;
	left: 100%;
	background-color: ${colors.white};
	bottom: 0;
	border-radius: 10px;
	box-shadow: 0 5px 15px 0 rgba(19, 16, 13, 0.1);
	border: solid 1px #e2dfe4;
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.25s;
	max-height: 360px;
	overflow: auto;

	&::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 7px;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: rgba(0, 0, 0, 0.5);
		box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
		-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
	}
`

const PopupMenuItem = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	position: relative;
	padding: 8px 15px;
	border-bottom: solid 1px #e2dfe4;
	font-size: 14px;
	line-height: 18px;
	height: 46px;
	transition: background-color 0.25s;

	&:first-child {
		border-radius: 10px 10px 0 0;
	}

	&:last-child {
		border-bottom: none;
		border-radius: 0 0 10px 10px;
	}

	&:hover {
		background-color: ${colors.borderLightGray};

		.popupMenuNested {
			opacity: 1;
			pointer-events: all;
		}
	}
`

const PopupMenuItemText = styled.div`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`

const PopupMenuItemIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 8px;
	flex: 0 0 24px;
	width: 24px;
	height: 24px;
	color: ${colors.white};
	border-radius: 50%;
	background-color: ${colors.blueGroup};

	svg {
		height: 10px;
	}
`

const PopupMenuItemArrow = styled.div`
	margin-left: auto;
	padding-left: 8px;
	color: ${colors.borderGray};

	svg {
		height: 12px;
	}
`

const PopupMenuItemCheck = styled.div`
	margin-left: auto;
	padding-left: 8px;
	color: ${colors.indigoBlue};

	svg {
		height: 8px;
		width: 12px;
	}
`

type PopupMenuProps = {
	children: JSX.Element
}

export const PopupMenu = ({ children }: PopupMenuProps): JSX.Element => {
	return (
		<PopupMenuModal className="popupMenuModal">
			<PopupMenuMain>{children}</PopupMenuMain>
		</PopupMenuModal>
	)
}

type PopupMenuElementProps = {
	text: string
	icon?: JSX.Element
	children?: JSX.Element
	onClick?: () => unknown
	style?: React.CSSProperties
	active?: boolean
}

export const PopupMenuElement = ({
	text,
	icon,
	children,
	onClick,
	style,
	active,
}: PopupMenuElementProps): JSX.Element => {
	return (
		<PopupMenuItem onClick={onClick}>
			{icon ? <PopupMenuItemIcon>{icon}</PopupMenuItemIcon> : null}
			<PopupMenuItemText style={style}>{text}</PopupMenuItemText>
			{children ? (
				<PopupMenuItemArrow>
					<icons.Chevron />
				</PopupMenuItemArrow>
			) : null}
			{active ? (
				<PopupMenuItemCheck>
					<icons.IconChecked />
				</PopupMenuItemCheck>
			) : null}
			{children}
		</PopupMenuItem>
	)
}

type PopupNestedMenuProps = {
	children: JSX.Element
}

export const PopupNestedMenu = ({
	children,
}: PopupNestedMenuProps): JSX.Element => {
	return (
		<PopupMenuNested className="popupMenuNested">{children}</PopupMenuNested>
	)
}
