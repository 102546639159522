import { ui } from '@beelday/common'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { roomEndUsers } from 'room/common/redux'
import { filterSceneVideoUsers } from 'room/group-room/model/scene-video-users'
import ThreeColumnsVideoGrid from 'room/training-room/ice-breaker/presentation/three-columns-video-grid'
import { SlideRenderer } from 'slides/slide-renderer'
import { allStreams } from 'video-conference-media/redux'
import { selectActiveSlideId } from './slide-redux'

export const ConfiguredSlideViewer = (): JSX.Element => {
	const sceneVideoUsers = useSelector(allStreams)
	const endUsers = useSelector(roomEndUsers)
	const videoUsers = useMemo(
		() => filterSceneVideoUsers(endUsers, sceneVideoUsers),
		[endUsers, sceneVideoUsers]
	)
	const slideId = useSelector(selectActiveSlideId)

	return (
		<ThreeColumnsVideoGrid sceneVideoUsers={videoUsers}>
			{slideId ? <SlideRenderer slideId={slideId} /> : <ui.FullPageSpinner />}{' '}
		</ThreeColumnsVideoGrid>
	)
}

export default ConfiguredSlideViewer
