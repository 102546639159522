import { colors, icons, images, intl, ui } from '@beelday/common'
import styled from '@emotion/styled'
import { useFormik } from 'formik'

import { v4 as uuidv4 } from 'uuid'
import { useState } from 'react'
import { css } from '@emotion/react'
import { set } from 'lodash'

import { NewPollingMultipleChoice } from './multiple-choice-polling-models'
import { removeAtIndex } from 'session-info/utils/arrays'

const Container = styled.div`
	display: flex;
`

const Column = styled.div`
	flex: 0 0 50%;
	max-width: 50%;
`

const FormTitle = styled.div`
	font-size: 18px;
	line-height: 28px;
	font-weight: 500;
	margin-bottom: 20px;
`

const FormRow = styled.div`
	margin-bottom: 25px;
`

const Preview = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${colors.heather10};
	border-radius: 7px;
	padding: 25px;
	margin-left: 30px;
	height: 100%;
`

const PreviewTitle = styled.div`
	font-size: 18px;
	line-height: 28px;
`

const PreviewImage = styled.div`
	margin-top: 3rem;

	img {
		max-width: 100%;
	}
`

const OptionRow = styled.div`
	display: flex;
	align-items: center;
`

const OptionInput = styled.div`
	flex: 1;

	input {
		width: 100%;
	}
`
const OptionButton = styled.div`
	margin-left: 15px;
`

type ValidationErrors = {
	question?: 'field.required' | 'field.length'
	answers?: 'field.required' | 'field.length'[]
}

type Props = {
	onSubmit: (polling: NewPollingMultipleChoice) => Promise<unknown>
	onClose: () => void
}

const empty: NewPollingMultipleChoice = {
	id: '',
	question: '',
	answers: ['', ''],
	letFacilitatorAnswer: true,
	allowForMultipleAnswer: true,
	type: 'POLLING_MULTIPLE_CHOICE',
}

const PollingMultipleChoiceForm = ({
	onSubmit,
	onClose,
}: Props): JSX.Element => {
	const translate = intl.useTranslate()
	const answerLength = 120
	const questionLength = 250
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

	const formik = useFormik({
		validate: values => {
			const errors: ValidationErrors = {}

			if (!values.question) {
				errors.question = 'field.required'
			}

			if (values.question.length > questionLength) {
				errors.question = 'field.length'
			}

			values.answers.forEach((answer, index) => {
				if (!answer) {
					set(errors, `answers[${index}]`, 'field.required')
				}

				if (answer.length > answerLength) {
					set(errors, `answers[${index}]`, 'field.length')
				}
			})

			return errors
		},
		initialValues: empty,
		onSubmit: values => {
			const newTemplate: NewPollingMultipleChoice = {
				id: values.id ? values.id : uuidv4(),
				question: values.question,
				answers: values.answers,
				letFacilitatorAnswer: values.letFacilitatorAnswer,
				allowForMultipleAnswer: values.allowForMultipleAnswer,
				type: 'POLLING_MULTIPLE_CHOICE',
			}
			onSubmit(newTemplate).finally(() => formik.setSubmitting(false))
		},
	})

	return (
		<>
			<Container>
				<Column>
					<FormTitle>
						<intl.Translate>polling.multiple_choice</intl.Translate>
					</FormTitle>
					<FormRow>
						<ui.InputLabeled
							label={translate('polling.multiple_choice.question.label')}
						>
							<ui.TextAreaLimited
								id={`question`}
								name={`question`}
								placeholder={translate(
									'polling.multiple_choice.question.placeholder'
								)}
								value={formik.values.question}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								style={{ minHeight: '150px' }}
								limit={questionLength}
								incorrect={!!formik.errors.question && isSubmitted}
							/>
							{formik.errors.question && isSubmitted && (
								<ui.ErrorPlaceholder>
									<intl.Translate>{formik.errors.question}</intl.Translate>
								</ui.ErrorPlaceholder>
							)}
						</ui.InputLabeled>
					</FormRow>
					<FormRow>
						<ui.InputLabeled
							label={translate('polling.multiple_choice.options.label')}
						>
							{formik.values.answers.map((answer, index) => (
								<div key={index}>
									<OptionRow style={{ marginTop: index > 0 ? '12px' : '0' }}>
										<OptionInput>
											<ui.TextAreaLimited
												id={`answers[${index}]`}
												name={`answers[${index}]`}
												placeholder={translate(
													'polling.multiple_choice.options.placeholder'
												)}
												value={formik.values.answers[index]}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												incorrect={
													!!formik.errors.answers?.[index] && isSubmitted
												}
												style={{ minHeight: '76px' }}
												limit={answerLength}
											/>
										</OptionInput>
										<OptionButton>
											{formik.values.answers.length > 2 ? (
												<ui.ButtonInline
													onClick={() =>
														formik.setFieldValue(
															'answers',
															removeAtIndex(formik.values.answers, index)
														)
													}
													type="button"
													css={css`
														svg {
															height: 16px;
															min-height: 16px;
															width: 16px;
															min-width: 16px;
														}
													`}
												>
													<icons.Trash />
												</ui.ButtonInline>
											) : null}
										</OptionButton>
									</OptionRow>
									{formik.errors.answers?.[index] && isSubmitted && (
										<ui.ErrorPlaceholder>
											<intl.Translate>
												{formik.errors.answers?.[index]}
											</intl.Translate>
										</ui.ErrorPlaceholder>
									)}
								</div>
							))}
						</ui.InputLabeled>
					</FormRow>
					{formik.values.answers.length <= 15 ? (
						<ui.AddNewButtonSection
							style={{ marginTop: '15px', marginBottom: '15px' }}
						>
							<ui.AddNewButton
								onClick={() =>
									formik.setFieldValue('answers', [
										...formik.values.answers,
										'',
									])
								}
							>
								<intl.Translate>polling.creator.add_option</intl.Translate>
							</ui.AddNewButton>
						</ui.AddNewButtonSection>
					) : null}
					<FormRow>
						<ui.InputLabeled
							label={translate(
								'polling.multiple_choice.additional_options.label'
							)}
						>
							<ui.Checkbox
								id="letFacilitatorAnswer"
								name="letFacilitatorAnswer"
								checked={formik.values.letFacilitatorAnswer}
								text={translate(
									'polling.multiple_choice.letFacilitatorAnswer.text'
								)}
								onChange={value =>
									formik.setFieldValue('letFacilitatorAnswer', value)
								}
								style={{ marginBottom: '10px' }}
							/>
							<ui.Checkbox
								id="allowForMultipleAnswer"
								name="allowForMultipleAnswer"
								checked={formik.values.allowForMultipleAnswer}
								text={translate(
									'polling.multiple_choice.allowForMultipleAnswer.text'
								)}
								onChange={value =>
									formik.setFieldValue('allowForMultipleAnswer', value)
								}
							/>
						</ui.InputLabeled>
					</FormRow>
				</Column>
				<Column>
					<Preview>
						<PreviewTitle>
							<intl.Translate>
								polling.multiple_choice.preview.title
							</intl.Translate>
						</PreviewTitle>
						<PreviewImage>
							<img src={images.multipleChoiceImage} alt="multipleChoiceImage" />
						</PreviewImage>
					</Preview>
				</Column>
			</Container>

			<ui.StickyWrapper
				style={{ padding: '24px 0px', borderRadius: '0px', boxShadow: 'none' }}
			>
				<ui.FlexSpaceBetween style={{ width: '100%' }}>
					<ui.ButtonMinor onClick={onClose}>
						<intl.Translate>cancel</intl.Translate>
					</ui.ButtonMinor>
					<ui.ButtonPrimary
						onClick={() => {
							formik.handleSubmit()
							setIsSubmitted(true)
						}}
						type="button"
						disabled={formik.isSubmitting}
					>
						<intl.Translate>polling.start_polling</intl.Translate>
					</ui.ButtonPrimary>
				</ui.FlexSpaceBetween>
			</ui.StickyWrapper>
		</>
	)
}

export default PollingMultipleChoiceForm
