export const AppointCompanyAdminIcon = (): JSX.Element => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="19.415"
		height="16.249"
		viewBox="0 0 19.415 16.249"
	>
		<g
			id="Icon_feather-user-plus"
			data-name="Icon feather-user-plus"
			transform="translate(-0.5 -3.5)"
		>
			<path
				id="Path_15063"
				data-name="Path 15063"
				d="M13.374,27.25V25.666A3.166,3.166,0,0,0,10.207,22.5H4.666A3.166,3.166,0,0,0,1.5,25.666V27.25"
				transform="translate(0 -8.501)"
				fill="none"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
			/>
			<path
				id="Path_15064"
				data-name="Path 15064"
				d="M13.083,7.666A3.166,3.166,0,1,1,9.916,4.5a3.166,3.166,0,0,1,3.166,3.166Z"
				transform="translate(-2.479)"
				fill="none"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
			/>
			<path
				id="Path_15065"
				data-name="Path 15065"
				d="M30,12v4.75"
				transform="translate(-13.46 -3.542)"
				fill="none"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
			/>
			<path
				id="Path_15066"
				data-name="Path 15066"
				d="M30.25,16.5H25.5"
				transform="translate(-11.335 -5.667)"
				fill="none"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
			/>
		</g>
	</svg>
)
