import { ui } from '@beelday/common'
import { PublishBitrates } from 'common/publish-bitrates'
import { useQuestions, VotingModal } from 'features/questions'
import { setIsExpandableCompanyCard } from 'organization/redux'
import { FC, useEffect } from 'react'
import { use100vh } from 'react-div-100vh'
import { useDispatch, useSelector } from 'react-redux'
import { RoomContainer } from 'room/common/presentation/room-container'
import RoomHeader from 'room/common/presentation/room-header'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { useAssertedWorkflowUser } from 'room/common/use-workflow-user'
import { MiscPanel } from 'room/misc'
import { TraineeMenu } from 'room/training-room/trainee-menu'
import { VideoGrid } from 'video-conference-media/presentation/video-grid'
import {
	allStreams,
	selectLocalScreenShareStream,
	selectPublishBitRate,
	setPublishBitrate,
} from 'video-conference-media/redux'
import { selectFocusedFeature } from './redux'
import { TraineeFocusedFeature } from './trainee-focused-feature'

export const RoomTraineeWebinar: FC = () => {
	const sceneVideoUsers = useSelector(allStreams)
	const containerHeight = use100vh()
	const roomAddress = useAssertedJoinedRoomAddress()
	const user = useAssertedWorkflowUser()
	const dispatch = useDispatch()

	dispatch(setIsExpandableCompanyCard(false))
	const currentBitrate = useSelector(selectPublishBitRate)
	const focusedFeature = useSelector(selectFocusedFeature)
	const questions = useQuestions()
	const usersCount = sceneVideoUsers.length

	const isLocalScreenShare = !!useSelector(selectLocalScreenShareStream)
	const showingFocusedFeature = !!focusedFeature

	useEffect(() => {
		let bitrate = PublishBitrates.low
		if (isLocalScreenShare) {
			bitrate = PublishBitrates.screenShareUltra
		} else if (!showingFocusedFeature) {
			bitrate = PublishBitrates.ultra
		}

		if (bitrate !== currentBitrate) {
			dispatch(setPublishBitrate(bitrate))
		}
	}, [
		dispatch,
		currentBitrate,
		isLocalScreenShare,
		showingFocusedFeature,
		usersCount,
	])

	return (
		<RoomContainer
			style={{
				height: `${containerHeight}px`,
			}}
		>
			<RoomHeader />

			{showingFocusedFeature ? (
				<TraineeFocusedFeature streams={sceneVideoUsers} />
			) : (
				<>
					{questions.active && (
						<VotingModal roomAddress={roomAddress} me={user.id} />
					)}
					<VideoGrid
						sceneVideoUsers={sceneVideoUsers}
						presenterStyle="spotlight"
					/>
				</>
			)}
			<TraineeMenu />
			<MiscPanel />
			<ui.BeeldayLogoAbsoluteBottom />
		</RoomContainer>
	)
}
