export const ChatIcon: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
	>
		<g transform="translate(0)">
			<path
				d="M9.1,0A9.073,9.073,0,0,0,.073,7.938a9.3,9.3,0,0,0,2.1,7.192,8.533,8.533,0,0,0,6.523,3.1h2.78l.236.221L17.333,24l-.049-6.015A9.157,9.157,0,0,0,14.923,0Z"
				fillRule="evenodd"
			/>
		</g>
	</svg>
)
