import { FC } from 'react'
import styled from '@emotion/styled'
import colors from '../colors'
import { BackgroundCurtain } from './BackgroundCurtain'
import { ui } from '@beelday/common'
import { Modal } from './modal'
import responsive from './responsive'

const Container = styled.div`
	background-color: ${colors.white};
	box-shadow: -10px 0 40px 0 rgba(41, 54, 73, 0.1);
	position: fixed;
	bottom: 0;
	right: 0;
	height: 100%;
	width: 50vw;
	z-index: 11;
	/* overflow: auto; */
	display: flex;
	flex-direction: column;
`

const TitleContainer = styled.div`
	border-bottom: 1px solid ${colors.brightGray};
	display: flex;
	align-items: center;
	padding: 15px 20px;

	${responsive.desktop1440} {
		padding: 20px 50px;
	}
`

const Title = styled.div`
	color: ${colors.black};
	font-size: 28px;
	font-weight: 500;
`

const SmallTitle = styled.div`
	color: ${colors.black};
	font-size: 20px;
	line-height: 28px;
	font-weight: 500;
`

const SubTitle = styled.div`
	color: ${colors.black};
	font-size: 12px;
	line-height: 24px;
`

const ChildrenContainer = styled.div`
	padding: 25px 20px 0 20px;
	overflow: auto;
	flex: 1;

	${responsive.desktop1440} {
		padding: 25px 50px 0 50px;
	}
`

const CloseButton = styled.div`
	margin-left: auto;
`

type Props = {
	header: JSX.Element
	onClose?: () => void
}

const RightDetailsPanelSubTitleAndTitle: FC<{
	title: JSX.Element
	subTitle: JSX.Element
}> = ({ title, subTitle }) => (
	<div>
		<SmallTitle>{title}</SmallTitle>
		<SubTitle>{subTitle}</SubTitle>
	</div>
)

export const RightDetailsPanel: FC<Props> & {
	Title: FC
	SubTitleAndTitle: typeof RightDetailsPanelSubTitleAndTitle
} = ({ header, onClose, children }) => {
	return (
		<Modal backgroundColor="transparent">
			<BackgroundCurtain onClick={onClose} />
			<Container>
				<TitleContainer>
					{header}
					{onClose ? (
						<CloseButton>
							<ui.ButtonClose onClick={onClose} />
						</CloseButton>
					) : null}
				</TitleContainer>
				<ChildrenContainer>{children}</ChildrenContainer>
			</Container>
		</Modal>
	)
}

RightDetailsPanel.Title = Title
RightDetailsPanel.SubTitleAndTitle = RightDetailsPanelSubTitleAndTitle

export default RightDetailsPanel
