import styled from '@emotion/styled'
import { FC, ReactElement } from 'react'
import colors from '../colors'
import { Translate } from '../intl'
import styles from '../styles'
import { BeeldayLogoSmall } from './beelday-logo'
import { tablet } from './responsive'

const HeaderContainer = styled.div`
	background-color: ${colors.indigoBlue};
	display: flex;
	justify-content: space-between;
	height: 60px;
	flex-shrink: 0;
	position: relative;
`

const Title = styled.div`
	flex: 1;
	color: ${colors.white};
	font-family: Ubuntu;
	font-size: 20px;
	font-weight: 500;
	padding-left: 10px;
	padding-right: 10px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	${tablet} {
		width: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding-left: 350px;
		padding-right: 350px;
	}
`

const LinksContainer = styled.div`
	height: 100;
	display: flex;
	align-items: center;
	padding-right: 20px;
	z-index: 1;
`

export const HeaderItem = styled.div`
	color: ${colors.white};
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 5px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: ${colors.darkIndigo};
	object-fit: contain;
	cursor: pointer;
	${styles.zoomOnHover};
	&:hover {
		transform: scale(1.1);
	}
	&:last-child {
		margin-right: 0;
	}
	& > * {
		width: 22px;
		height: 22px;
	}
`

type Props = {
	title?: string | ReactElement<typeof Translate>
}
export const HeaderSmall: FC<Props> = ({ children, title }) => {
	return (
		<HeaderContainer>
			<BeeldayLogoSmall />
			<Title>{title}</Title>
			<LinksContainer>{children}</LinksContainer>
		</HeaderContainer>
	)
}
