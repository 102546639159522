import { FC } from 'react'
import { ui } from '@beelday/common'
import styled from '@emotion/styled'
import Timer from 'common/presentation/timer'
import { Colors } from 'common/presentation/colors'

const RingContainer = styled.div`
	border: solid 4px ${Colors.indigoBlue};
	border-radius: 1000px;
	min-width: 100px;
	min-height: 100px;
	position: relative;
	display: flex;
	justify-content: center;
	justify-items: center;
	align-items: flex-end;
	align-content: center;
`

const Text = styled.p`
	text-align: center;
	position: absolute;
	top: 30%;
	margin: 0;

	color: ${Colors.indigoBlue};
	font-family: Ubuntu;
	font-size: 22px;
	font-weight: 500;

	${ui.desktop} {
		font-size: 32px;
	}
`

type Props = {
	onFinish: () => void
	initialValue: number
	className?: string
}

export const RingTimer: FC<Props> = ({ onFinish, initialValue, className }) => {
	return (
		<RingContainer className={className}>
			<Text>
				<Timer initialValue={initialValue} onFinished={onFinish} />
			</Text>
		</RingContainer>
	)
}
