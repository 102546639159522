import { ReactNode } from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Primary from 'room/common/presentation/images/group-room-grid-background-primary.svgx'
import Secondary from 'room/common/presentation/images/group-room-grid-background-secondary.svgx'

const Container = styled.div`
	position: relative;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	width: 100%;
`

const Backgrounds = styled.div`
	height: 100%;
	position: absolute;
	width: 100%;
`

const Inner = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: 6vh 5% 1vh 5%;
	width: 100%;
`
type Props = {
	children: ReactNode
	colorHex: string
}

const GroupFlexBackgrounds = ({ colorHex, children }: Props): JSX.Element => (
	<Container>
		<Backgrounds>
			<Secondary
				css={css`
					width: 99.8%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;

					.outline {
						fill: ${colorHex};
					}
				`}
			/>
			<Primary
				css={css`
					width: 100%;
					height: 98%;
					position: absolute;
					bottom: 0;
					left: 0;
				`}
			/>
		</Backgrounds>
		<Inner>{children}</Inner>
	</Container>
)

export default GroupFlexBackgrounds
