import {
	PopupMenu,
	PopupMenuElement,
	PopupNestedMenu,
} from '../../common/presentation/menu'
import { colors, icons, intl, ui, UUID } from '@beelday/common'
import { useWhiteboards } from 'features/whiteboard'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'common/redux'
import { WhiteboardId } from 'features/whiteboard/whiteboard-models'
import { css } from '@emotion/react'
import { useFormik } from 'formik'

type ValidationErrors = {
	name?: 'field.required'
}

type Props = {
	activeWhiteboardId?: UUID
	withFocus: boolean
	onClose?: () => unknown
	onOpen?: () => unknown
	child?: JSX.Element
}

export const ExcalidrawMenu = ({
	activeWhiteboardId,
	withFocus,
	onClose,
	onOpen,
	child,
}: Props): JSX.Element => {
	const translate = intl.useTranslate()
	const [showForm, setShowForm] = useState(false)
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

	const { createWhiteboard, openWhiteboard, closeWhiteboard } = useWhiteboards()

	const whiteboards = useSelector(
		(state: RootState) => state.whiteboards.whiteboards
	)

	const empty: { name: string } = {
		name: translate('untitled', { count: whiteboards.length + 1 }),
	}

	const formik = useFormik({
		validate: values => {
			const errors: ValidationErrors = {}

			if (!values.name) {
				errors.name = 'field.required'
			}

			return errors
		},
		initialValues: empty,
		onSubmit: values => {
			createWhiteboard(values.name, withFocus).finally(() => {
				formik.setSubmitting(false)
				setShowForm(false)
				setIsSubmitted(false)
				formik.setFieldValue('name', '')

				if (onOpen) {
					onOpen()
				}
			})
		},
	})

	const open = useCallback(
		(whiteboardId: WhiteboardId) => {
			openWhiteboard(whiteboardId, withFocus).then(() => {
				if (onOpen) {
					onOpen()
				}
			})
		},
		[onOpen, openWhiteboard, withFocus]
	)

	const close = useCallback(() => {
		if (activeWhiteboardId) {
			closeWhiteboard(activeWhiteboardId).then(() => {
				if (onClose) {
					onClose()
				}
			})
		}
	}, [activeWhiteboardId, closeWhiteboard, onClose])

	return (
		<>
			<PopupMenu>
				<>
					{whiteboards.length ? (
						<PopupMenuElement
							text={translate('features.whiteboard.menu.select')}
							icon={<icons.Excalidraw />}
						>
							<PopupNestedMenu>
								<>
									{whiteboards.map(whiteboard => (
										<PopupMenuElement
											key={whiteboard.whiteboardId}
											text={whiteboard.whiteboardName}
											onClick={() => open(whiteboard.whiteboardId)}
											active={activeWhiteboardId === whiteboard.whiteboardId}
										/>
									))}
								</>
							</PopupNestedMenu>
						</PopupMenuElement>
					) : null}

					<PopupMenuElement
						text={translate('features.whiteboard.menu.add')}
						onClick={() => setShowForm(true)}
					/>

					{child}

					{activeWhiteboardId ? (
						<PopupMenuElement
							style={{ color: colors.pinkRed, fontWeight: 'bolder' }}
							text={translate('features.whiteboard.menu.off')}
							onClick={close}
						/>
					) : null}
				</>
			</PopupMenu>
			{showForm ? (
				<ui.Modal
					backgroundColor={colors.transparentPaleGray}
					onClose={() => setShowForm(false)}
				>
					<ui.ModalContainer
						css={css`
							width: 620px;
						`}
					>
						<ui.ModalHeader
							title={
								<intl.Translate>features.whiteboard.menu.create</intl.Translate>
							}
							onClose={() => setShowForm(false)}
						/>
						<ui.ModalContent>
							<ui.FlexColumn style={{ width: '100%' }}>
								<ui.InputLabeled
									label={
										<intl.Translate>
											features.whiteboard.menu.input.label
										</intl.Translate>
									}
								>
									<ui.Input
										id="name"
										name="name"
										placeholder={translate(
											'features.whiteboard.menu.input.placeholder'
										)}
										value={formik.values.name}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										incorrect={!!formik.errors.name && isSubmitted}
									/>
									{formik.errors.name && isSubmitted && (
										<ui.ErrorPlaceholder>
											<intl.Translate>{formik.errors.name}</intl.Translate>
										</ui.ErrorPlaceholder>
									)}
								</ui.InputLabeled>
								<ui.FlexSpaceBetween
									style={{ width: '100%', marginTop: '50px' }}
								>
									<ui.ButtonMinor onClick={() => setShowForm(false)}>
										<intl.Translate>cancel</intl.Translate>
									</ui.ButtonMinor>
									<ui.ButtonPrimary
										onClick={() => {
											formik.handleSubmit()
											setIsSubmitted(true)
										}}
										type="button"
										disabled={formik.isSubmitting}
									>
										<intl.Translate>
											features.whiteboard.menu.continue
										</intl.Translate>
									</ui.ButtonPrimary>
								</ui.FlexSpaceBetween>
							</ui.FlexColumn>
						</ui.ModalContent>
					</ui.ModalContainer>
				</ui.Modal>
			) : null}
		</>
	)
}
