import { FC } from 'react'
import { Droppable, DroppableStateSnapshot } from 'react-beautiful-dnd'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { GroupId, GroupSuggestionAssignment, UserId } from 'common/types'
import { colorToHex } from 'room/common/presentation/colorsInHex'
import { RowPosition } from 'room/training-room/set-groups/types'
import UserToPick from 'room/training-room/set-groups/presentation/user-to-pick'
import {
	GroupBucketBackground,
	GroupBucketBlocker,
} from 'room/training-room/set-groups/presentation/group-bucket-background'
import FreeSpace from 'room/training-room/set-groups/presentation/group-bucket-free-spaces'
import { translate } from 'intl/translate'
import { times } from 'lodash'
import CalculatedRatioGrid from 'video-conference-media/presentation/calculated-ratio-grid'
import { SceneVideoUser } from 'video-conference-media'

const GroupBucketFlex = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
`

const GroupName = styled.div`
	font-family: Ubuntu;
	font-weight: 500;
	font-size: 22px;
	text-align: center;
	transition: color 0.25s;
	padding-top: 30px;
	z-index: 3;
`

const GroupVideos = styled.div`
	margin: 10px 15px 15px;
	flex-grow: 1;
	display: flex;
`

type Props = {
	droppable: boolean
	group: GroupSuggestionAssignment
	index: number
	rowPosition?: RowPosition
	streams: Record<UserId, SceneVideoUser>
	width: number
	onSelect?: (id: GroupId) => void
}

const draggingElementFromThisDroppable = (
	snapshot: DroppableStateSnapshot
): boolean => snapshot.draggingFromThisWith !== snapshot.draggingOverWith

const GroupBucket: FC<Props> = ({
	droppable,
	group,
	index,
	rowPosition = RowPosition.TOP,
	streams,
	width,
	onSelect,
}) => {
	const colorHex = colorToHex[group.groupDescription.color]

	const freeSpacesCount =
		group.groupDescription.maxGroupSize - group.users.length

	const groupPickBlocked = freeSpacesCount <= 0

	const hoverMode =
		onSelect && !groupPickBlocked
			? css`
					&:hover {
						color: white;

						svg path {
							fill: ${colorHex};
						}
						* {
							border-color: white;
						}
					}
			  `
			: ''

	return (
		<div
			css={css`
				cursor: ${onSelect && !groupPickBlocked ? 'pointer' : 'auto'};
				color: ${onSelect && groupPickBlocked ? 'white' : colorHex};
				margin-right: 10px;
				margin-left: 10px;
				position: relative;
				width: ${width}%;
				${hoverMode};
			`}
			key={`group-bucket-${group.groupDescription.id}`}
			onClick={() =>
				onSelect && !groupPickBlocked && onSelect(group.groupDescription.id)
			}
		>
			{groupPickBlocked && onSelect && (
				<GroupBucketBlocker index={index} row={rowPosition} colorHex={colorHex}>
					{translate('group-reached-maximum-members-number')}
				</GroupBucketBlocker>
			)}

			<GroupBucketBackground
				row={rowPosition}
				index={index}
				colorHex={colorHex}
			/>
			<GroupBucketFlex>
				<GroupName>{group.groupDescription.name.toUpperCase()}</GroupName>
				<Droppable
					droppableId={group.groupDescription.id}
					isDropDisabled={!droppable}
				>
					{(provided, snapshot) => {
						const isBlockedOut =
							snapshot.isDraggingOver &&
							draggingElementFromThisDroppable(snapshot) &&
							groupPickBlocked
						return (
							<>
								{isBlockedOut && (
									<GroupBucketBlocker
										index={index}
										row={rowPosition}
										colorHex={colorHex}
									>
										{translate('group-reached-maximum-members-number')}
									</GroupBucketBlocker>
								)}
								<GroupVideos
									ref={provided.innerRef}
									{...provided.droppableProps}
								>
									<CalculatedRatioGrid
										widthRatio={1}
										heightRatio={1}
										spacing={6}
									>
										{group.users.map((u, i) => (
											<UserToPick
												key={u.userId}
												draggable={droppable}
												id={u.userId}
												index={i}
												stream={streams[u.userId]}
												circle
												hideName
											/>
										))}
										{times(freeSpacesCount, () => (
											<FreeSpace colorHex={colorHex} />
										))}
									</CalculatedRatioGrid>
								</GroupVideos>
							</>
						)
					}}
				</Droppable>
			</GroupBucketFlex>
		</div>
	)
}

export default GroupBucket
