import { func, http } from '@beelday/common'
import { RoomAddress } from 'common/types'
import { buildRoomUrl } from 'utils/http'
import { WhiteboardId } from './whiteboard-models'

interface WhiteboardsApi {
	createWhiteboard: (
		addr: RoomAddress,
		whiteboardName: string,
		withFocus: boolean
	) => Promise<void>
	openWhiteboard: (
		addr: RoomAddress,
		whiteboardId: WhiteboardId,
		withFocus: boolean
	) => Promise<void>
	closeWhiteboard: (
		addr: RoomAddress,
		whiteboardId: WhiteboardId
	) => Promise<void>
	changeCollaboration: (
		addr: RoomAddress,
		whiteboardId: WhiteboardId,
		enabled: boolean
	) => Promise<void>
}

const createWhiteboardssApi = (serverUrl: string): WhiteboardsApi =>
	<WhiteboardsApi>{
		createWhiteboard: (
			addr: RoomAddress,
			whiteboardName: string,
			withFocus: boolean
		): Promise<void> => {
			return http
				.fetch(buildRoomUrl(serverUrl, addr, '/features/whiteboard'), {
					method: 'POST',
					body: JSON.stringify({ whiteboardName, withFocus }),
				})
				.then(http.checkStatus)
		},
		openWhiteboard: (
			addr: RoomAddress,
			whiteboardId: WhiteboardId,
			withFocus: boolean
		): Promise<void> => {
			return http
				.fetch(
					buildRoomUrl(serverUrl, addr, `/features/whiteboard/${whiteboardId}`),
					{
						method: 'POST',
						body: JSON.stringify({ withFocus }),
					}
				)
				.then(http.checkStatus)
		},
		closeWhiteboard: (
			addr: RoomAddress,
			whiteboardId: WhiteboardId
		): Promise<void> => {
			return http
				.fetch(
					buildRoomUrl(serverUrl, addr, `/features/whiteboard/${whiteboardId}`),
					{
						method: 'DELETE',
					}
				)
				.then(http.checkStatus)
		},
		changeCollaboration: (
			addr: RoomAddress,
			whiteboardId: WhiteboardId,
			enabled: boolean
		): Promise<void> => {
			return http
				.fetch(
					buildRoomUrl(
						serverUrl,
						addr,
						`/features/whiteboard/${whiteboardId}/collaboration`
					),
					{
						method: 'PUT',
						body: JSON.stringify({ enabled }),
					}
				)
				.then(http.checkStatus)
		},
	}

export const getWhiteboardsApi = func.memoizeSingle(createWhiteboardssApi)
