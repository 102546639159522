import { FC, useCallback } from 'react'
import { isGroupsSelectionStarted } from 'room/training-room/set-groups/model/set-groups'

import { useDispatch, useSelector } from 'react-redux'
import { GroupId, UserId, UserRole } from 'common/types'
import GroupPickerTrainer from 'room/training-room/set-groups/presentation/group-picker-trainer'
import { RootState } from 'common/redux'
import { setGroupsSuggestionAssignment } from 'room/training-room/redux'
import { useIntl } from 'react-intl'
import styled from '@emotion/styled'
import { useAuthenticatedBeeldayClient } from 'connectivity/beelday-hooks'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import ConfirmBanner from 'common/presentation/confirm-banner'
import { roomEndUsers } from 'room/common/redux'
import { allStreams } from 'video-conference-media/redux'

const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	position: relative;
	width: 100%;
	height: 100%;
`

export const SelectGroupPanelTrainerRedux: FC = () => {
	const joinedRoomAddress = useAssertedJoinedRoomAddress()
	const groupsSuggestionAssignment = useSelector(
		(state: RootState) => state.trainingRoom.groupsSuggestionAssignment
	)
	const endUsers = useSelector(roomEndUsers)
	const sceneVideoUsers = useSelector(allStreams)
	const dispatch = useDispatch()
	const beeldayClient = useAuthenticatedBeeldayClient()

	const intl = useIntl()

	const unassignedUsers =
		groupsSuggestionAssignment.getNotAssignedUsers(endUsers)

	const finishChoosingGroup = useCallback(
		() =>
			beeldayClient
				.confirmSuggestionAssigment(joinedRoomAddress)
				.then(error => {
					if (error) {
						alert(
							intl.formatMessage(
								{ id: error.errorCode, defaultMessage: error.errorCode },
								{ ...error.arguments }
							)
						)
					}
				}),
		[beeldayClient, intl, joinedRoomAddress]
	)

	const cancelChoosingGroup = useCallback(
		() => beeldayClient.cancelGroupSuggestion(joinedRoomAddress),
		[beeldayClient, joinedRoomAddress]
	)

	const onRemoveUserFromGroup = useCallback(
		(userId: UserId) => {
			const suggestion = groupsSuggestionAssignment.removeUserFromGroups(userId)
			dispatch(setGroupsSuggestionAssignment(suggestion))

			beeldayClient.assignUserToGroup({
				...joinedRoomAddress,
				groupId: undefined,
				userId,
				userRole: UserRole.UPPER_ECHELON,
			})
		},
		[beeldayClient, dispatch, groupsSuggestionAssignment, joinedRoomAddress]
	)

	const onAssignUserToGroup = useCallback(
		(userId: UserId, groupId: GroupId) => {
			const newGroupsSuggestionAssignment =
				groupsSuggestionAssignment.changeUserGroup(userId, groupId)

			dispatch(setGroupsSuggestionAssignment(newGroupsSuggestionAssignment))

			beeldayClient.assignUserToGroup({
				...joinedRoomAddress,
				groupId,
				userId,
				userRole: UserRole.UPPER_ECHELON,
			})
		},
		[beeldayClient, dispatch, groupsSuggestionAssignment, joinedRoomAddress]
	)

	if (!isGroupsSelectionStarted(groupsSuggestionAssignment)) {
		return null
	}

	return (
		<Container>
			<ConfirmBanner
				message="Grouping"
				onAccept={finishChoosingGroup}
				onDecline={cancelChoosingGroup}
				accept={'accept-groups'}
				decline={'decline-groups'}
			/>
			<GroupPickerTrainer
				assignUserToGroup={onAssignUserToGroup}
				removeUserFromGroup={onRemoveUserFromGroup}
				groups={groupsSuggestionAssignment.getGroupsSuggestionAssignment}
				sceneVideoUsers={sceneVideoUsers}
				unassignedUsers={unassignedUsers}
				users={endUsers}
			/>
		</Container>
	)
}
