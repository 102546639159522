export const ArrowLeft: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="12"
		height="11.694"
		viewBox="0 0 12 11.694"
	>
		<path
			d="M6.617.77v8.6l3.758-3.758a.776.776,0,0,1,1.094,0,.767.767,0,0,1,0,1.086L6.394,11.775a.767.767,0,0,1-1.086,0L.225,6.708A.768.768,0,1,1,1.311,5.622l3.766,3.75V.77a.77.77,0,1,1,1.54,0Z"
			transform="translate(12) rotate(90)"
			fill="currentColor"
			fillRule="evenodd"
		/>
	</svg>
)
