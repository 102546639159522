import styled from '@emotion/styled'
import { Colors } from 'common/presentation/colors'
import { clippingBorderRadius } from 'common/presentation/cross-browser-css'

type Props = {
	colorHex: string
}

const MediaContainer = styled.div<Props>`
	${clippingBorderRadius('0 0 20px 20px')};
	border-top: 6px solid ${props => props.colorHex};
	background: ${Colors.paleGray};
	flex-grow: 1;
	height: 100%;
`

export default MediaContainer
