import styled from '@emotion/styled'
import { PlusIcon } from '../icons'
import colors from '../colors'

const Plus = styled.div`
	align-items: center;
	background-color: ${colors.white};
	border: 1px solid ${colors.brightGray};
	color: ${colors.indigoBlue};
	border-radius: 8px;
	box-shadow: 0 1px 3px 0 rgba(160, 153, 167, 0.3);
	display: flex;
	height: 42px;
	justify-content: center;
	width: 42px;
	transition: background-color 0.25s, color 0.25s;

	svg {
		height: 22px;
		width: 22px;
	}
`

export const SquarePlus = (): JSX.Element => (
	<Plus className="squarePlus">
		<PlusIcon />
	</Plus>
)
