import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FC, useEffect, useRef, useState } from 'react'
import { colors } from '../colors'
import { Chevron } from '../icons'
import { isTwelveHoursFormat, LocalTime, to24HoursFormat } from '../local-time'
import { FlexCenterAll, FlexColumn } from './containers'
import { Input } from './input'
import { ModalPicker } from './modal'

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px 40px;
`

const AMPMButtonWrapper = styled.div<{ active: boolean }>`
	border-radius: 4px;
	padding: 5px;
	background-color: ${({ active }) =>
		active ? colors.brightGray : colors.white};
	opacity: ${({ active }) => (active ? 1 : 0.7)};
`
const AMPMButton = styled.button`
	cursor: pointer;
	width: 51px;
	height: 32px;
	text-align: center;
	background-color: ${colors.indigoBlue};
	border: none;
	border-radius: 4px;
	color: ${colors.white};
	transition: transform 0.2s ease-in-out;
	&:hover {
		transform: scale(1.05);
	}
`

type Props = {
	selected?: LocalTime
	defValue?: LocalTime
	onClose: (value: LocalTime) => unknown
	className?: string
}
export const TimePicker: FC<Props> = ({
	selected,
	defValue,
	onClose,
	className,
}) => {
	const ampm = isTwelveHoursFormat()
	const [hours, setHours] = useState(selected?.hours ?? defValue?.hours ?? 0)
	const [am, setAm] = useState(hours < 12)
	const [minutes, setMinutes] = useState(
		selected?.minutes ?? defValue?.minutes ?? 0
	)

	const updateHours = (value: number, am: boolean) => {
		if (ampm) {
			setHours(to24HoursFormat(value, am))
			setAm(am)
		} else {
			setHours(value)
		}
	}

	const submit = (hours: number, minutes: number, am: boolean) => {
		if (ampm) {
			onClose({ hours: to24HoursFormat(hours, am), minutes })
		} else {
			onClose({ hours, minutes })
		}
	}

	return (
		<ModalPicker
			onClose={() => onClose({ hours, minutes })}
			className={className}
		>
			<Container>
				<NumberInput
					min={ampm ? 1 : 0}
					max={ampm ? 12 : 23}
					value={ampm ? hours % 12 || 12 : hours}
					onChange={value => updateHours(value, am)}
					onSubmit={hours => submit(hours, minutes, am)}
					autoFocus
				/>
				<span style={{ margin: '0 8px' }}>:</span>
				<NumberInput
					min={0}
					max={59}
					value={minutes}
					onChange={setMinutes}
					onSubmit={minutes => submit(hours, minutes, am)}
				/>
				{ampm ? (
					<FlexColumn style={{ marginLeft: '11px' }}>
						<AMPMButtonWrapper active={am}>
							<AMPMButton
								type="button"
								onClick={() => updateHours(hours, true)}
							>
								AM
							</AMPMButton>
						</AMPMButtonWrapper>
						<AMPMButtonWrapper active={!am}>
							<AMPMButton
								type="button"
								onClick={() => updateHours(hours, false)}
							>
								PM
							</AMPMButton>
						</AMPMButtonWrapper>
					</FlexColumn>
				) : null}
			</Container>
		</ModalPicker>
	)
}

const NumberButton = styled.div`
	cursor: pointer;
	padding: 10px;
	color: ${colors.indigoBlue};
	transition: transform 0.2s ease-in-out;
	&:hover {
		transform: scale(1.2);
	}
`
export const NumberInput: FC<{
	min: number
	max: number
	value: number
	onChange: (value: number) => unknown
	onSubmit?: (value: number) => unknown
	autoFocus?: boolean
}> = ({ min, max, value, onChange, onSubmit, autoFocus }) => {
	const [holding, setHolding] = useState<number | null>(null)
	const inputRef = useRef<HTMLInputElement>(null)
	const increment = () => {
		if (value >= max) {
			onChange(min)
		} else {
			onChange(value + 1)
		}
	}

	const decrement = () => {
		if (value <= min) {
			onChange(max)
		} else {
			onChange(value - 1)
		}
	}

	useEffect(() => {
		if (holding) {
			const delay = Math.max(Date.now() - Math.abs(holding) < 400 ? 400 : 100)
			const timeout = setTimeout(holding > 0 ? increment : decrement, delay)
			return () => clearTimeout(timeout)
		}
	})

	useEffect(() => {
		if (autoFocus) {
			setTimeout(() => {
				inputRef.current?.focus()
			}, 0)
		}
	}, [autoFocus])

	return (
		<FlexCenterAll style={{ flexDirection: 'column' }}>
			<NumberButton
				role="button"
				css={css`
					svg {
						transform: rotate(270deg);
					}
				`}
				onClick={increment}
				onMouseDown={() => setHolding(Date.now())}
				onMouseUp={() => setHolding(null)}
			>
				<Chevron />
			</NumberButton>
			<Input
				ref={inputRef}
				css={css`
					::-webkit-outer-spin-button,
					::-webkit-inner-spin-button {
						-webkit-appearance: none;
						margin: 0;
					}
					-moz-appearance: textfield;
					width: 56px;
					height: 40px;
					border-radius: 4px;
					margin: 6px 0 10px 0;
					text-align: center;
				`}
				min={min}
				max={max}
				value={value < 10 ? '0' + value : value}
				autoFocus={autoFocus}
				onFocus={e => {
					e.target.select()
				}}
				onChange={e => {
					const value = parseInt(e.target.value || '0', 10)
					if (value >= min && value <= max) {
						onChange(value)
					}
				}}
				onKeyDown={
					onSubmit &&
					(e => {
						if (e.key === 'Enter') {
							e.preventDefault()
							e.stopPropagation()
							onSubmit(value)
						}
					})
				}
			/>
			<NumberButton
				role="button"
				onClick={decrement}
				onMouseDown={() => setHolding(Date.now() * -1)}
				onMouseUp={() => setHolding(null)}
				css={css`
					svg {
						transform: rotate(90deg);
					}
				`}
			>
				<Chevron />
			</NumberButton>
		</FlexCenterAll>
	)
}

export default TimePicker
