import { colors, intl, UUID } from '@beelday/common'
import styled from '@emotion/styled'
import { RoomAddress } from 'common/types'
import { useCallback, useEffect, useState } from 'react'
import { useProfilesFor } from 'users/redux'
import useFetchMissingProfiles from 'users/use-fetch-missing-profiles'
import thumbDown from './thumb_down.png'
import thumbUp from './thumb_up.png'
import bigCheck from './big_check.svg'
import useQuestion from './use-questions'
import VotingResult from './voting-result'
import { AnimationControls, motion, useAnimation } from 'framer-motion'
import DraggableContent from './draggable-content'
import {
	Header,
	HeaderTitle,
	Fill,
	Body,
	Inner,
	HeaderSubtitle,
	TrainerFace,
} from './ui'
import { useSelector } from 'react-redux'
import { RootState } from 'common/redux'
import { roomEndUsers } from 'room/common/redux'

const VotingResultWrapper = styled(motion.div)`
	position: relative;
	top: -10px;
	opacity: 0;
	display: none;
	transform: translateY(-10%);
`

const Grid = styled(motion.div)`
	column-gap: 16px;
	display: grid;
	grid-template-columns: 1fr 1fr;
`

const Button = styled.button`
	border: solid 1px ${colors.brightGray};
	background-color: ${colors.white};
	border-radius: 17px;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
	cursor: pointer;
	overflow: hidden;
	padding: 50px 62px;
	position: relative;
	transition: background-color 0.25s, border-color 0.25s;

	img {
		width: 70px;
	}
`

const Yes = styled(Button)`
	&:hover {
		background-color: ${colors.transparentGreenishCyan};
		border-color: ${colors.greenishCyan};
	}
`

const No = styled(Button)`
	&:hover {
		background-color: ${colors.transparentStrongPink};
		border-color: ${colors.strongPink};
	}
`

const ButtonInner = styled(motion.div)`
	bottom: 0;
	height: 0;
	left: 0;
	overflow: hidden;
	position: absolute;
	width: 100%;
`

const ButtonContent = styled(motion.div)`
	align-items: center;
	color: white;
	display: flex;
	flex-direction: column;
	font-size: 14px;
	font-weight: 500;
	left: 0;
	line-height: 24px;
	opacity: 0;
	padding: 15px;
	position: absolute;
	text-align: center;
	top: 60%;
	transform: translateY(-50%);
	width: 100%;

	img {
		margin-bottom: 20px;
	}
`

type Props = {
	me: UUID
	roomAddress: RoomAddress
}

const VotingModal = ({ me, roomAddress }: Props) => {
	const training = useSelector((state: RootState) => state.sessionInfo.training)
	const endUsers = useSelector(roomEndUsers)
	const numberOfUsers = endUsers.length

	const trainerProfile = useSelector(
		(state: RootState) =>
			state.users.all[
				training?.upperEchelon.userId ? training?.upperEchelon.userId : ''
			]
	)

	const [disableButtons, setDisableButtons] = useState(false)
	const controlsButtonsGrid = useAnimation()
	const controlsResults = useAnimation()
	const controlsYesBackground = useAnimation()
	const controlsNoBackground = useAnimation()
	const controlsYesText = useAnimation()
	const controlsNoText = useAnimation()

	const { result, voteYes, voteNo } = useQuestion()

	const voted = !!(result && result.voted.includes(me))

	const handleNo = useCallback(() => {
		setDisableButtons(true)
		voteAnimation(controlsNoBackground, controlsNoText).then(() =>
			voteNo(roomAddress)
		)
	}, [roomAddress, voteNo, controlsNoText, controlsNoBackground])

	const handleYes = useCallback(() => {
		setDisableButtons(true)
		voteAnimation(controlsYesBackground, controlsYesText).then(() =>
			voteYes(roomAddress)
		)
	}, [roomAddress, voteYes, controlsYesBackground, controlsYesText])

	useEffect(() => {
		if (voted) {
			showResultsAnimation(controlsButtonsGrid, controlsResults)
		} else {
			showVotingAnimation(
				controlsButtonsGrid,
				controlsResults,
				controlsYesBackground,
				controlsYesText,
				controlsNoBackground,
				controlsNoText
			).then(() => {
				setDisableButtons(false)
			})
		}
	}, [
		voted,
		controlsButtonsGrid,
		controlsResults,
		controlsYesBackground,
		controlsYesText,
		controlsNoBackground,
		controlsNoText,
	])

	useFetchMissingProfiles(result?.voted || [])

	const profiles = useProfilesFor(result?.voted || [])

	return (
		<DraggableContent
			header={
				<Header>
					<TrainerFace className="trainerFace" profile={trainerProfile} />
					<Fill>
						<HeaderTitle>
							<intl.Translate>features.question.ask</intl.Translate>
						</HeaderTitle>
						<HeaderSubtitle>
							<intl.Translate>features.question.answer</intl.Translate>
						</HeaderSubtitle>
					</Fill>
				</Header>
			}
			body={
				<Body>
					<Inner>
						<VotingResultWrapper
							initial={false}
							animate={controlsResults}
							transition={{ duration: 0.4 }}
							style={{
								display: 'none',
								opacity: '0',
								top: '-10px',
							}}
						>
							<VotingResult
								total={numberOfUsers}
								yes={result?.yes || 0}
								voted={profiles}
							/>
						</VotingResultWrapper>
						<Grid
							initial={false}
							animate={controlsButtonsGrid}
							transition={{ duration: 0.5 }}
							style={{
								display: 'flex',
							}}
						>
							<Yes onClick={handleYes} disabled={disableButtons}>
								<img src={thumbUp} alt="thumb up" />
								<ButtonInner
									initial={false}
									animate={controlsYesBackground}
									transition={{ duration: 1 }}
									style={{ backgroundColor: colors.greenishCyan }}
								>
									<ButtonContent
										initial={false}
										animate={controlsYesText}
										transition={{ duration: 0.7 }}
									>
										<img src={bigCheck} alt="big check" />
										Thanks for your answer
									</ButtonContent>
								</ButtonInner>
							</Yes>
							<No onClick={handleNo} disabled={disableButtons}>
								<img src={thumbDown} alt="thumb down" />
								<ButtonInner
									initial={false}
									animate={controlsNoBackground}
									transition={{ duration: 1 }}
									style={{ backgroundColor: colors.strongPink }}
								>
									<ButtonContent
										initial={false}
										animate={controlsNoText}
										transition={{ duration: 0.7 }}
									>
										<img src={bigCheck} alt="big check" />
										Thanks for your answer
									</ButtonContent>
								</ButtonInner>
							</No>
						</Grid>
					</Inner>
				</Body>
			}
		/>
	)
}

async function showResultsAnimation(
	controlsButtonsGrid: AnimationControls,
	controlsResults: AnimationControls
) {
	await controlsButtonsGrid.start({
		opacity: 0,
		height: 0,
		transitionEnd: { display: 'none' },
	})
	await controlsResults.start({
		height: '120px',
	})
	return await controlsResults.start({
		opacity: 1,
		top: '0',
	})
}

async function voteAnimation(
	controlsBackground: AnimationControls,
	controlsText: AnimationControls
) {
	await controlsBackground.start({ height: `100%` })
	await controlsText.start({ opacity: 1, top: '50%' })
}

async function showVotingAnimation(
	controlsButtonsGrid: AnimationControls,
	controlsResults: AnimationControls,
	controlsYesBackground: AnimationControls,
	controlsYesText: AnimationControls,
	controlsNoBackground: AnimationControls,
	controlsNoText: AnimationControls
) {
	await controlsYesText.start({ opacity: 0, top: '60%' }, { duration: 0 })
	await controlsNoText.start({ opacity: 0, top: '60%' }, { duration: 0 })
	await controlsYesBackground.start({ height: `0` }, { duration: 0 })
	await controlsNoBackground.start({ height: `0` }, { duration: 0 })
	await controlsResults.start(
		{
			opacity: 0,
		},
		{ duration: 0.1 }
	)
	await controlsResults.start({
		top: '-10px',
		height: '0',
		transitionEnd: { display: 'none' },
	})
	await controlsButtonsGrid.start({
		display: 'flex',
		height: '182px',
	})
	return await controlsButtonsGrid.start({
		opacity: 1,
	})
}

export default VotingModal
