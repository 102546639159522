import { FunctionComponent, useMemo } from 'react'
import { SceneVideoUser } from '../model/types'
import { AudioStream } from './audio-stream'

type Props = {
	sceneVideoUser: SceneVideoUser
}

export const SceneAudioUserStream: FunctionComponent<Props> = props => {
	const { stream, muted, isSelfView } = props.sceneVideoUser
	const hasMediaStreams = stream?.getAudioTracks().length

	const displayStream = useMemo(() => {
		if (isSelfView) {
			return new MediaStream([])
		} else {
			return stream
		}
	}, [isSelfView, stream])

	return displayStream && hasMediaStreams ? (
		<AudioStream
			media={{
				stream: displayStream,
				muted: muted,
			}}
		/>
	) : null
}
