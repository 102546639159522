import { css, CSSObject, PropsOf, SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'
import countryList from 'country-list'
import { format } from 'date-fns'
import React, {
	ChangeEvent,
	CSSProperties,
	FC,
	HTMLAttributes,
	useEffect,
	useState,
} from 'react'
import Select from 'react-select'
import colors from '../colors'
import { IconChecked } from '../icons'
import calendarIcon from '../images/calendar.svg'
import styles from '../styles'
import { ExpandArrowSmall } from './arrows'
import { Relative } from './containers'
import { DatePicker } from './date-picker'
import responsive from './responsive'
import TimeZonePicker from './time-zone-picker'

const InputIcon = styled.img`
	position: absolute;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);
	line-height: 1.15;
`

const pickerStyle = css`
	position: absolute;
	bottom: -2px;
	left: 0;
`

export const TextArea = styled.textarea<{ incorrect?: boolean }>`
	margin: 0;
	font-size: 14px;
	padding: 20px 15px;
	border-radius: 11px;
	border: 1px solid
		${props => (props.incorrect ? colors.strongPink : colors.brightGray)};
	width: 100%;

	:disabled {
		background-color: ${colors.brighterGray};
	}
`

const TextAreaLimitedContainer = styled.div`
	width: 100%;
	position: relative;
`

const TextAreaLimitedNumber = styled.div`
	font-size: 12px;
	line-height: 17px;
	color: ${colors.heather};
	position: absolute;
	right: 15px;
	bottom: 15px;
`

export const TextAreaLimited = ({
	id,
	name,
	placeholder,
	value,
	limit,
	incorrect,
	onChange,
	style,
	onBlur,
}: {
	id: string
	name: string
	placeholder: string
	incorrect?: boolean
	value: string
	limit?: number
	onChange: (event: ChangeEvent) => unknown
	style: CSSProperties
	onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => unknown
}): JSX.Element => {
	return (
		<TextAreaLimitedContainer>
			<TextArea
				id={id}
				name={name}
				placeholder={placeholder}
				value={value}
				incorrect={incorrect}
				onChange={onChange}
				onBlur={onBlur}
				style={style}
			/>
			{limit ? (
				<TextAreaLimitedNumber>
					{value.length}/{limit}
				</TextAreaLimitedNumber>
			) : null}
		</TextAreaLimitedContainer>
	)
}

export const Input = styled.input<{ incorrect?: boolean }>`
	margin: 0;
	font-size: 14px;
	padding: 20px 15px;
	border-radius: 11px;
	border: 1px solid
		${props => (props.incorrect ? colors.strongPink : colors.brightGray)};

	:disabled {
		background-color: ${colors.brighterGray};
	}
`

export const UnstyledInput = styled.input<{ incorrect?: boolean }>`
	margin: 0;
	font-size: 14px;
	padding: 20px 14px;
	border: none;

	:disabled {
		background-color: unset;
	}
`

export const InputPercentage: FC<{
	onChange: (val: number) => unknown
	value: number
	max?: number
	min?: number
	default?: number
	style?: CSSProperties
	className?: string
}> = ({ onChange, value, max = 1, min = 0, style, className }) => {
	const [text, setText] = useState(Math.round(value * 100).toString(10))
	useEffect(() => {
		setText(s => {
			const percentage = Math.round(value * 100).toString(10)
			if (s !== percentage) return percentage
			else return s
		})
	}, [value])

	return (
		<span
			style={style}
			className={className}
			css={css`
				position: relative;
				:after {
					content: '%';
					position: absolute;
					right: 12px;
					top: 50%;
					transform: translateY(-50%);
				}
			`}
		>
			<Input
				css={css`
					${styles.inputNoArrows};
					display: block;
					flex-grow: 0;
					width: 60px;
					height: 42px;
					text-align: center;
					position: relative;
					padding-right: 20px;
					padding-left: 5px;
				`}
				type="number"
				max={Math.round(max * 100)}
				min={Math.round(min * 100)}
				size={2}
				maxLength={3}
				onChange={e => {
					const { value } = e.target
					const parsed = parseInt(value, 10)
					const normalized = parsed / 100
					if (
						value.length === 0 ||
						(value.length <= 3 &&
							!isNaN(normalized) &&
							normalized <= max &&
							normalized >= min)
					) {
						console.log('value', value)
						setText(value)
						if (!isNaN(normalized)) {
							onChange(normalized)
						}
					}
				}}
				onFocus={e => {
					setTimeout(() => e.target.select(), 0)
				}}
				value={text}
			/>
		</span>
	)
}

export const InputLikeContainer = styled.div<{ incorrect?: boolean }>`
	margin: 0;
	font-size: 14px;
	padding: 20px 15px;
	border-radius: 11px;
	line-height: 1.15;
	border: 1px solid
		${props => (props.incorrect ? colors.strongPink : colors.brightGray)};
`
export const InputLabel = styled.label`
	white-space: nowrap;
	color: ${colors.black};
	font-weight: 500;
	font-size: 14px;
	line-height: 1.2;
	margin-bottom: 8px;
	width: 100%;
`

export const InputHint = styled.span`
	white-space: nowrap;
	font-size: 12px;
	font-weight: 500;
	color: ${colors.black};
	opacity: 0.7;
`

type MaybeTranslated = string | React.ReactElement | null | undefined
export const InputLabeled: React.FC<{
	label?: MaybeTranslated
	hint?: MaybeTranslated
	invalid?: boolean
	className?: string
	cssStyles?: SerializedStyles
}> = ({ label, hint, children, invalid, className, cssStyles }) => (
	<div
		css={css`
			display: flex;
			flex-direction: column;
			align-items: stretch;
			${cssStyles}
		`}
		className={className}
	>
		<div
			css={css`
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 8px;
			`}
		>
			{label ? (
				<InputLabel
					css={css`
						margin-bottom: 0;
						color: ${invalid ? colors.pinkRed : colors.black};
					`}
				>
					{label}
				</InputLabel>
			) : null}
			{hint ? <InputHint>{hint}</InputHint> : null}
		</div>
		{children}
	</div>
)

export const InputDate: React.FC<{
	date?: Date
	onChange: (date: Date) => unknown
	pickerStart?: 'YEAR' | 'MONTH' | 'DAY'
}> = ({ date, onChange, pickerStart }) => {
	const [showPicker, setShowPicker] = useState(false)
	return (
		<Relative css={styles.flexRow}>
			<Input
				css={[styles.fillSpace, styles.clickable]}
				value={date ? format(date, 'dd.MM.yyyy') : ''}
				onFocus={() => setShowPicker(true)}
				readOnly
			/>
			<InputIcon
				css={styles.clickable}
				src={calendarIcon}
				onClick={() => setShowPicker(true)}
			/>
			{showPicker ? (
				<DatePicker
					value={date}
					startMode={pickerStart}
					onClose={() => setShowPicker(false)}
					onChange={date => {
						setShowPicker(false)
						onChange(date)
					}}
					css={pickerStyle}
				/>
			) : null}
		</Relative>
	)
}

export const InputTimeZone: React.FC<{
	value?: string
	onChange: (selected: string) => unknown
}> = ({ value, onChange }) => {
	const [showPicker, setShowPicker] = useState(false)
	return (
		<Relative css={styles.flexRow}>
			<Input
				css={[styles.fillSpace, styles.clickable]}
				value={value}
				onFocus={() => setShowPicker(true)}
				readOnly
			/>
			{showPicker ? (
				<TimeZonePicker
					onClose={() => setShowPicker(false)}
					selected={value}
					onChange={selected => {
						setShowPicker(false)
						onChange(selected)
					}}
					css={pickerStyle}
				/>
			) : null}
		</Relative>
	)
}

export const InputSections: FC<
	HTMLAttributes<HTMLDivElement> & { nowrap?: boolean }
> = props => {
	if (props.nowrap) {
		return <InputSectionsNoWrap {...props} />
	} else {
		return <InputSectionsWrap {...props} />
	}
}

export const InputSectionsWrap = styled.div`
	border-radius: 11px;
	border: 1px solid ${colors.brightGray};
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;

	& > * {
		border-top: 1px solid ${colors.brightGray};
		:first-child {
			border-left: none;
			margin-left: 0;
		}
	}

	${responsive.smallTablet} {
		flex-direction: row;

		& > * {
			border-left: 1px solid ${colors.brightGray};
			border-top: none;
			:first-child {
				border-left: none;
				margin-left: 0;
			}
		}
	}
`
export const InputSectionsNoWrap = styled.div`
	border-radius: 11px;
	border: 1px solid ${colors.brightGray};
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	overflow: hidden;

	& > * {
		border-left: 1px solid ${colors.brightGray};
		border-top: none;
		:first-child {
			border-left: none;
			margin-left: 0;
		}
	}
`

export const SELECT_STYLES = {
	control: (
		base: CSSObject,
		state: {
			isDisabled: boolean
			isFocused: boolean
			menuIsOpen: boolean
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			selectProps: any
		}
	): CSSObject => {
		return {
			...base,
			fontSize: '14px',
			padding: '10px 15px',
			border: `solid 1px ${
				state.selectProps.incorrect ? colors.strongPink : colors.brightGray
			}`,
			borderRadius: state.menuIsOpen ? '11px 11px 0 0' : '11px',
			boxShadow: state.menuIsOpen
				? '0 1px 6px 0 rgba(160, 153, 167, 0.5)'
				: 'none',
			backgroundColor: state.isDisabled ? '#FBFBFB' : base.backgroundColor,
			'&:hover': {
				border: `solid 1px ${
					state.selectProps.incorrect ? colors.strongPink : colors.brightGray
				}`,
			},
		}
	},
	option: (
		base: CSSObject,
		state: { isSelected: boolean; isFocused: boolean }
	): CSSObject => ({
		...base,
		fontSize: '14px',
		backgroundColor: state.isSelected
			? colors.inactiveGray
			: state.isFocused
			? colors.heather10
			: undefined,
		color: state.isSelected ? colors.indigoBlue : colors.black,
		padding: '12px 15px',

		'&:hover': {
			backgroundColor: colors.inactiveGray,
		},
	}),
	menu: (base: CSSObject): CSSObject => ({
		...base,
		borderRadius: '0 0 11px 11px',
		boxShadow: '0 1px 6px 0 rgba(160, 153, 167, 0.5);',
		border: `1px solid ${colors.borderLightGray}`,
		borderTop: 'none',
		marginTop: '0',
	}),
	indicatorSeparator: (): CSSObject => ({
		display: 'none',
	}),
	indicatorsContainer: (base: CSSObject): CSSObject => ({
		...base,
		marginRight: '-8px',
	}),
	valueContainer: (base: CSSObject): CSSObject => ({
		...base,
		padding: 0,
	}),
	multiValue: (base: CSSObject): CSSObject => ({
		...base,
		backgroundColor: colors.brightGray,
		borderRadius: '11px',
		color: colors.black,
		padding: '7px 10px',
		fontFamily: 'Ubuntu',
		fontSize: '12px',
	}),
	multiValueLabel: (base: CSSObject): CSSObject => ({
		...base,
		color: colors.black,
		fontFamily: 'Ubuntu',
		fontSize: '12px',
		fontWeight: 500,
	}),
	multiValueRemove: (base: CSSObject): CSSObject => ({
		...base,
		color: colors.heather,
		backgroundColor: 'transparent',
		':hover': {
			backgroundColor: 'transparent',
			color: colors.red,
			cursor: 'pointer',
		},
	}),
}

export const InputCountry: React.FC<{
	value?: string
	onChange: (selected?: string) => unknown
}> = ({ value, onChange }) => {
	const allCountries = countryList.getData()
	const code = value && countryList.getCode(value)
	return (
		<Select
			isClearable={false}
			isSearchable={true}
			name="color"
			styles={SELECT_STYLES}
			getOptionLabel={(country: typeof allCountries[0]) => country.name}
			getOptionValue={(country: typeof allCountries[0]) => country.name}
			options={allCountries}
			placeholder=""
			value={value && code ? { name: value, code: code } : undefined}
			onChange={e => onChange(e?.name || undefined)}
		/>
	)
}

export const DropdownIndicator: FC = () => (
	<div style={{ paddingRight: '15px' }}>
		<ExpandArrowSmall color={colors.heather} rotation={90} />
	</div>
)

const SELECT_SMALL_STYLES = {
	...SELECT_STYLES,
	container: (base: CSSObject): CSSObject => ({
		...base,
		width: '100%',
		height: '100%',
	}),
	control: (
		base: CSSObject,
		state: {
			isDisabled: boolean
			isFocused: boolean
			menuIsOpen: boolean
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			selectProps: any
		}
	) => ({
		...SELECT_STYLES.control(base, state),
		fontSize: '14px',
		padding: '0 10px',
		borderRadius: '6px',
		height: '100%',
		width: '100%',
		minHeight: 0,
	}),
	option: (
		base: CSSObject,
		state: { isSelected: boolean; isFocused: boolean }
	): CSSObject => ({
		...SELECT_STYLES.option(base, state),
		fontSize: '14px',
		padding: '8px 9px',
	}),
	menu: (base: CSSObject): CSSObject => ({
		...SELECT_STYLES.menu(base),
		marginTop: '2px',
		borderRadius: '6px',
	}),
}

export const DropdownSmall = (props: PropsOf<Select>): JSX.Element => (
	<Select
		{...props}
		styles={SELECT_SMALL_STYLES}
		components={{ DropdownIndicator }}
	/>
)

export const ErrorPlaceholder = styled.div`
	color: ${colors.strongPink};
	font-size: 12px;
	height: 14px;
	margin-top: 5px;
	padding-left: 10px;
	margin-bottom: 8px;

	&:empty {
		margin-bottom: 0;
	}

	${responsive.smallTablet} {
		&:empty {
			margin-bottom: 8px;
		}
	}
`

const CheckboxWrapper = styled.label`
	display: flex;
	position: relative;
	cursor: pointer;
`

const CheckboxText = styled.div`
	font-size: 14px;
	line-height: 20px;
	padding-left: 30px;
`

const CheckboxCheckmark = styled.span`
	align-items: center;
	color: ${colors.white};
	display: flex;
	justify-content: center;
	position: absolute;
	left: 0;
	top: 0;
	border-radius: 4px;
	width: 20px;
	height: 20px;
	background-color: ${colors.white};
	border: 1px solid ${colors.heather30};
	transition: background-color 0.25s;

	svg {
		width: 10px;
	}
`

const CheckboxInput = styled.input`
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;

	:checked ~ .checkboxCheckmark {
		background-color: ${colors.indigoBlue};
	}
`

const SwitcherWrapper = styled.div`
	input {
		display: none;
	}

	span {
		cursor: pointer;
		background-color: ${colors.brightGray};
		border-radius: 8px;
		display: inline-block;
		height: 15px;
		margin-right: 10px;
		position: relative;
		width: 40px;
	}

	span:before {
		background-color: ${colors.heather};
		border-radius: 10px;
		box-shadow: 0 1px 3px 0 rgba(160, 153, 167, 0.3);
		content: '';
		display: inline-block;
		height: 20px;
		position: absolute;
		top: -2px;
		width: 20px;
	}

	input:checked + span {
		background-color: ${colors.transparentGreenishCyan};
	}

	input:checked + span:before {
		background-color: ${colors.greenishCyan};
		color: ${colors.white};
		content: '\\2714';
		padding-left: 4px;
		padding-top: 2px;
		right: 0px;
	}
`

export const CheckboxSwitcher = ({
	id,
	onChange,
	text,
	name,
	checked,
	style,
}: {
	id?: string
	name?: string
	text?: string
	checked?: boolean
	style?: CSSProperties
	onChange: (checked: boolean) => unknown
}): JSX.Element => {
	return (
		<SwitcherWrapper style={style}>
			<InputLabel htmlFor={id}>
				<input
					id={id}
					name={name}
					type="checkbox"
					checked={checked}
					onChange={e => onChange(e.currentTarget.checked)}
				/>
				<span />
				{text}
			</InputLabel>
		</SwitcherWrapper>
	)
}

export const Checkbox = ({
	id,
	text,
	name,
	checked,
	style,
	disabled,
	onChange,
}: {
	id: string
	name: string
	text: string
	checked?: boolean
	style?: CSSProperties
	disabled?: boolean
	onChange: (checked: boolean) => unknown
}): JSX.Element => {
	return (
		<CheckboxWrapper htmlFor={id} style={style}>
			<CheckboxText
				style={{ color: disabled ? colors.heather50 : colors.black }}
			>
				{text}
			</CheckboxText>
			<CheckboxInput
				disabled={disabled}
				id={id}
				type="checkbox"
				name={name}
				checked={checked}
				onChange={e => onChange(e.currentTarget.checked)}
			/>
			<CheckboxCheckmark className="checkboxCheckmark">
				<IconChecked />
			</CheckboxCheckmark>
		</CheckboxWrapper>
	)
}

const VALID_COLOR_REGEX = /^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
export const CustomColorInput: FC<{
	value?: string
	onChange: (value: string) => unknown
	className?: string
	hashWidth?: number
	style: CSSProperties
}> = ({ value: initialValue, onChange, className, style, hashWidth = 32 }) => {
	const [value, setValue] = useState(initialValue?.substring(1) || '')

	useEffect(() => {
		if (initialValue) {
			setValue(initialValue.substring(1))
		}
	}, [initialValue])

	const isValid = (value: string) =>
		(value.length == 6 || value.length == 3) && VALID_COLOR_REGEX.test(value)

	const handleEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target
		setValue(value)
		if (isValid(value)) {
			onChange('#' + value)
		}
	}

	return (
		<InputLikeContainer
			className={className}
			incorrect={!isValid(value)}
			style={{
				...style,
				padding: 0,
				display: 'flex',
				backgroundColor: colors.white,
				overflow: 'hidden',
			}}
		>
			<div
				style={{
					fontSize: '14px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: '#e6e9ec',
					width: hashWidth + 'px',
					color: colors.blackish,
				}}
			>
				#
			</div>
			<input
				type="text"
				pattern="^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$"
				maxLength={6}
				minLength={6}
				value={value || ''}
				onChange={handleEdit}
				css={css`
					position: relative;
					margin-right: 6px;
					padding: 6px 10px;
					color: ${colors.black};
					border: none;
					min-width: 6em;
					width: 6em;
					border: none;
					outline: none;
				`}
			/>
		</InputLikeContainer>
	)
}

export const FormWrapOnMobile = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	${responsive.smallTablet} {
		flex-direction: row;
	}
`
