export const Excalidraw: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="9.126"
		height="12"
		viewBox="0 0 9.126 12"
	>
		<g>
			<g>
				<path
					d="M8.023 1.917a.913.913 0 0 0-.29-1.236L6.849.138a.89.89 0 0 0-1.236.29L2.37 5.627l2.4 1.5z"
					transform="translate(-1.185)"
					fill={'currentColor'}
				/>
				<path
					d="m.828 16.378 1.464-.9a2.888 2.888 0 0 0 .757-.706L.689 13.3a2.537 2.537 0 0 0-.3.984L.222 16a.4.4 0 0 0 .606.378z"
					transform="translate(-.11 -6.649)"
					fill={'currentColor'}
				/>
				<path
					d="M9 20.8a2.406 2.406 0 0 0-3.331-.416 2.052 2.052 0 0 1-.492.214c-.227 0-.277-.063-.467-.328a1.493 1.493 0 0 0-1.38-.79A9.6 9.6 0 0 0 .3 20.59a.562.562 0 0 0 .517 1 9.208 9.208 0 0 1 2.513-.99c.215.026.252.05.454.328a1.577 1.577 0 0 0 1.388.808 2.335 2.335 0 0 0 1.01-.336c.808-.4 1.337-.618 1.956.139a.538.538 0 0 0 .429.2.528.528 0 0 0 .353-.126A.579.579 0 0 0 9 20.8z"
					transform="translate(0 -9.739)"
					fill={'currentColor'}
				/>
			</g>
		</g>
	</svg>
)
