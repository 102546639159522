import styled from '@emotion/styled'
import { default as colors } from '../colors'
import { BeeldayLogoSmall } from './beelday-logo'
import ErrorImage from '../images/404.png'
import { Link } from 'react-router-dom'
import { ButtonPrimary } from './button'
import { Translate } from '../intl'

const PageWrapper = styled.div`
	background-color: ${colors.paleGray2};
	display: flex;
	min-height: 100vh;
`

const Content = styled.div`
	margin: auto;
	text-align: center;
	padding: 70px 20px;
`

const Title = styled.div`
	margin-bottom: 8px;
	font-size: 28px;
	line-height: 38px;
	font-weight: 700;
`

const Desc = styled.div`
	margin-bottom: 40px;
	font-size: 16px;
	line-height: 28px;
`

const Image = styled.img`
	margin-bottom: 30px;
	max-width: 100%;
`

export const NotFound: React.FC = () => {
	return (
		<PageWrapper>
			<BeeldayLogoSmall />
			<Content>
				<Image src={ErrorImage} alt="error image" />
				<Title>
					<Translate>error_page.title</Translate>
				</Title>
				<Desc>
					<Translate>error_page.desc</Translate>
				</Desc>
				<Link to="/">
					<ButtonPrimary color="greenishCyan">
						<Translate>error_page.button</Translate>
					</ButtonPrimary>
				</Link>
			</Content>
		</PageWrapper>
	)
}

export default NotFound
