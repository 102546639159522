import { useSelector } from 'app-redux'
import { Config } from 'common/config'
import { RootState } from 'common/redux'
import { RoomAddress } from 'common/types'
import { useMemo } from 'react'
import { useSimpleGroupingApi } from './simple-grouping-api'

export type UseSimpleGrouping = {
	started: boolean
	start: (address: RoomAddress) => Promise<void>
	finish: (address: RoomAddress) => Promise<void>
}
export const useSimpleGrouping = (): UseSimpleGrouping => {
	const api = useSimpleGroupingApi(Config.beeldayBackendUrl)
	const started = useSelector(
		(state: RootState) => state.simpleGrouping.started
	)

	return useMemo(
		() => ({
			started,
			start: api.start,
			finish: api.finish,
		}),
		[api, started]
	)
}
