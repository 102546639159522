import { FC, useEffect } from 'react'
import * as eventbus from '../eventbus'
import * as security from '../security'

import logger from '../logger'

const log = logger.create('relogin-if-needed')
export const ReLoginIfNeeded: FC = () => {
	const auth = security.useAuth()
	const user = security.useUserIfAuthenticated()
	const eventBus = eventbus.useEventBus()

	useEffect(() => {
		if (user?.id) {
			eventBus.subscribe('USER_AUTH_DATA_CHANGED', user.id)
			return () => eventBus.unsubscribe('USER_AUTH_DATA_CHANGED', user.id)
		}
	}, [eventBus, user, user?.id])

	useEffect(() => {
		return eventBus.addListener(e => {
			if (e.type === 'USER_AUTH_DATA_CHANGED' && e.id === user?.id) {
				log.info('Account auth data changed, refreshing tokens')
				if (
					auth.tokenState.type === 'VALID_TOKENS' ||
					auth.tokenState.type === 'EXPIRED_TOKENS'
				) {
					auth.refresh(auth.tokenState)
				} else {
					auth.signOut()
				}
			}
		})
	}, [eventBus, auth, user?.id])
	return null
}
