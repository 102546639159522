export const Logout: React.FC = () => (
	<svg
		version="1.1"
		id="Group_25560"
		xmlns="http://www.w3.org/2000/svg"
		x="0px"
		y="0px"
		viewBox="0 0 19.5 19"
	>
		<path
			fill="currentColor"
			d="M20.7,8.1C20.7,8.1,20.7,8,20.7,8.1c0-0.1-0.1-0.2-0.1-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1
	c-0.1-0.1-0.2-0.1-0.2-0.1c0.1,0,0.2,0,0.2,0.1l-2.7-2.3c-0.3-0.2-0.7-0.2-0.9,0.1c-0.2,0.3-0.2,0.7,0.1,0.9l1.4,1.2h-2.7v-6
	C15.5,0.7,14.8,0,14,0H1.5c0,0-0.1,0-0.1,0C0.6,0.1,0,0.7,0,1.5v13.5c0,0.7,0.4,1.2,1,1.5l6.7,2.4C7.9,19,8,19,8.2,19
	c0.9,0,1.6-0.7,1.6-1.5v-1H14c0.9,0,1.5-0.7,1.5-1.6v-6h2.7l-1.4,1.2c-0.2,0.1-0.2,0.3-0.2,0.5c0,0.4,0.3,0.7,0.7,0.7
	c0.2,0,0.3,0,0.4-0.1l2.8-2.3c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1
	C20.8,8.3,20.8,8.2,20.7,8.1C20.7,8.1,20.7,8.1,20.7,8.1z M14.2,14.9C14.2,14.9,14.2,14.9,14.2,14.9c0,0.1-0.1,0.2-0.2,0.2H9.8V3.9
	c0-0.7-0.4-1.2-1-1.5L5.5,1.3H14c0.1,0,0.2,0.1,0.2,0.2v6h-2.6c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h2.6V14.9z"
		/>
	</svg>
)
