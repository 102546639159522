import { useLocation } from 'react-router'
import * as H from 'history'
import { useMemo } from 'react'

export const useSearchParam = (key: string): string | null => {
	const { search } = useLocation()
	return new URLSearchParams(search).get(key)
}

export const getSearchParam = (
	location: H.Location,
	key: string
): string | null => {
	return new URLSearchParams(location.search).get(key)
}

type LocationHelpers = {
	replaceSearchParam: (
		key: string,
		value: string | number | boolean | null
	) => H.Location
	preserveSearch: (
		target: string,
		update?: Record<string, string | null>
	) => H.Location
}

export const withSearchParams = (
	href: string,
	params: Record<string, string | null | undefined>
): string => {
	const search = new URLSearchParams(href.split('?')[1] ?? '')
	for (const [key, value] of Object.entries(params)) {
		if (value) {
			search.set(key, value)
		} else {
			search.delete(key)
		}
	}
	return `${href.split('?')[0]}?${search.toString()}`
}

export const useLocationHelpers = (): LocationHelpers => {
	const location = useLocation()
	return useMemo(
		() => ({
			replaceSearchParam: (key, value) => {
				return replaceSearchParam(location, key, value)
			},
			preserveSearch: (target, update) => {
				return preserveSearch(location, target, update)
			},
		}),
		[location]
	)
}

export const locationToHref = (location: H.Location): string => {
	return `${location.pathname}${location.search && '?' + location.search}${
		location.hash && '#' + location.hash
	}`
}

export const preserveSearch = <S = unknown>(
	current: H.Location<S>,
	target: string,
	update?: Record<string, string | null>
): H.Location<S> => {
	let search = current.search
	if (update) {
		const params = new URLSearchParams(search || '')
		for (const [key, value] of Object.entries(update ?? {})) {
			if (value === null) {
				params.delete(key)
			} else {
				params.set(key, value)
			}
		}
		search = params.toString()
	}
	return {
		pathname: target,
		state: current.state,
		hash: current.hash,
		search,
	}
}

export const replaceSearchParam = (
	location: H.Location,
	key: string,
	value: string | number | boolean | null
): H.Location => {
	const search = new URLSearchParams(location.search)
	if (value === null) {
		search.delete(key)
	} else {
		search.set(key, value.toString())
	}
	return {
		pathname: location.pathname,
		state: location.state,
		hash: location.hash,
		search: search.toString(),
	}
}
