import {
	InteractionSchemeAddress,
	RoomAddress,
	RoomId,
	RoomType,
} from 'common/types'

export const roomAddress = (
	isAddress: InteractionSchemeAddress,
	roomType: RoomType,
	roomId: RoomId
): RoomAddress => ({
	...isAddress,
	roomType,
	roomId,
})
