import Janus from './janus'
import { SpotlightChange, VcrId } from 'common/types'
import { RemoteUserStream } from 'video-conference-media/model/types'
import Logger from '../common/logger'
import { Config } from 'common/config'
import RtcMetrics from 'vcr/rtc-metrics'
import { AuthToken } from '@beelday/common'

const logger = new Logger('vcr')

const initJanus = async () => {
	return new Promise<void>(resolve => {
		Janus.init({
			debug: 'all',
			dependencies: Janus.useDefaultDependencies(),
			callback: () => {
				if (!Janus.isWebrtcSupported()) {
					throw new Error('WebRTC is not supported!')
				}
				logger.log('Janus initialized!')
				resolve()
			},
		})
	})
}

export type LostConnectionHandler = (error: Error | string) => void

const createJanusSession = async (
	janusNodeUrl: string,
	onLostConnection: LostConnectionHandler
) => {
	return new Promise(resolve => {
		const janus = new Janus({
			server: `${janusNodeUrl}`,
			success: () => {
				logger.log('Session created!')
				resolve(janus)
			},
			error: (error: Error | string) => {
				logger.error(error)
				onLostConnection(error)
			},
			destroyed: () => {
				logger.warn(`Janus session ${janusNodeUrl} has been destroyed`)
			},
		})
	})
}

type PluginHandle = {
	send: (payload: any) => void
	createOffer: (payload: any) => void
	createAnswer: (payload: any) => void
	handleRemoteJsep: (payload: { jsep: any }) => void
	simulcastStarted: boolean
	videoCodec: string
	hangup: () => void
	webrtcStuff?: any
}

type FeedId = string
type UserId = string
type JanusVcrId = number
type Publisher = {
	id: number
	display: string
	audio_codec: string
	video_codec: string
	simulcast: boolean
	talking: boolean
}
type Message = {
	id: number
	room: JanusVcrId
	videoroom: string
	error?: string
	error_code?: number
	publishers?: Publisher[]
	leaving?: FeedId
	unpublished?: FeedId | 'ok'
	audio_codec?: string
	video_codec?: string
	private_id: string
}

export type MediaStats = {}

export type MediaStreamObserver = (stream: MediaStream) => void
export type RemoteUserStreamsObserver = (streams: RemoteUserStream[]) => void
export type SpotlightChangeObserver = (change: SpotlightChange) => void

export class VCR {
	private privateId: string | undefined = undefined
	private opaqueId = `vcr-oid-${Janus.randomString(12)}`
	private remoteFeeds: Record<UserId, PluginHandle> = {}
	private remoteStreams: Record<FeedId, RemoteUserStream> = {}
	private localFeed: PluginHandle | undefined
	private localStream: MediaStream | undefined
	private localStreamObserver: MediaStreamObserver | undefined
	private remoteUserStreamObserver: RemoteUserStreamsObserver | undefined
	private spotlightChangeObserver: SpotlightChangeObserver | undefined
	private mediaStatsCheckInterval: number | undefined
	private configured = false
	private bitrate: number | undefined = undefined
	private rtcMetrics = new RtcMetrics()
	private camera?: string

	constructor(
		private janus: any,
		private vcrDefinition: VCRDefinition,
		private userId: UserId
	) {}

	configure = (bitrate: number, selectedCamera?: string) => {
		if (selectedCamera) {
			this.camera = selectedCamera
		}
		this.bitrate = bitrate
		if (this.configured) {
			this.reconfigure(bitrate)
		} else {
			this.janus.attach(this.createCallbacks())

			this.configured = true
		}
	}

	private createCallbacks() {
		return {
			plugin: 'janus.plugin.videoroom',
			opaqueId: this.opaqueId,
			success: (pluginHandle: PluginHandle) => {
				this.localFeed = pluginHandle || this.localFeed
				logger.log(
					`Plugin attached! Registering user ${this.userId}`,
					this.localFeed
				)
				this.localFeed.send({
					message: {
						request: 'join',
						room: this.vcrDefinition.janusVcrId,
						ptype: 'publisher',
						display: this.userId,
						token: this.vcrDefinition.janusAccessToken,
						id: this.vcrDefinition.janusUserId,
					},
				})
			},
			error: (error: any) => {
				logger.error(error)
				throw new Error(error)
			},
			consentDialog: (on: any) => {
				logger.log('consentDialog', on)
			},
			mediaState: (medium: 'audio' | 'video', on: boolean) => {
				logger.log('mediaState', medium, on)
			},
			webrtcState: (on: boolean) => {
				logger.log('webrtcState', on)
				if (!this.mediaStatsCheckInterval) {
					this.mediaStatsCheckInterval = window.setInterval(
						this.logStats,
						Config.mediaStatsIntervalMs
					)
				}
			},
			onmessage: (msg: Message, jsep: any) => {
				logger.log('onmessage (publisher)', msg, jsep)
				if (msg.error) {
					logger.error('onmessage error (publisher)', msg, jsep)
					const code = msg.error_code
					if (code === 426) {
						throw new Error('Room does not exist')
					} else if (code === 436) {
						console.error('Got 436!!!!')
						this.janus.reconnect(this.createCallbacks())
						return
					}
					throw new Error(`Error received: (${msg.error_code}) ${msg.error}`)
				}
				const event = msg.videoroom
				const { id, room, publishers } = msg
				switch (event) {
					case 'joined':
						this.privateId = msg.private_id
						logger.log(`Joined room ${room} with id ${id}`)
						this.sendOffer()
						publishers && publishers.forEach(this.subscribe)
						break
					case 'destroyed':
						logger.warn(`The room ${room} has been destroyed!`)
						break
					case 'event':
						publishers && publishers.forEach(this.subscribe)
						if (msg.leaving) {
							logger.log('Leaving', msg.leaving)
							this.deleteRemoteStream(msg.leaving)
						}
						const { unpublished } = msg
						if (unpublished === 'ok') {
							logger.log('Unpublish successful - hanging up')
							this.localFeed?.hangup()
							return
						} else if (unpublished) {
							logger.log('Unpublished', unpublished)
							this.deleteRemoteStream(unpublished)
						}
						break
					case 'talking':
						this.changeSpotlight(id.toString(), true)
						break
					case 'stopped-talking':
						this.changeSpotlight(id.toString(), false)
						break
					default:
						logger.error(`Invalid "videoroom": ${event}`)
				}
				if (jsep) {
					logger.log('Received an SDP', jsep)
					if (!this.localFeed) {
						throw new Error('Local feed is not available!')
					}
					if (!this.localStream) {
						throw new Error('Local stream is not available!')
					}
					this.localFeed.handleRemoteJsep({ jsep })
					if (
						this.localStream?.getAudioTracks() &&
						this.localStream?.getAudioTracks().length > 0 &&
						!msg.audio_codec
					) {
						throw new Error('Audio has been rejected!')
					}
					if (
						this.localStream?.getVideoTracks() &&
						this.localStream?.getVideoTracks().length > 0 &&
						!msg.video_codec
					) {
						throw new Error('Video has been rejected!')
					}
				}
			},
			onlocalstream: (stream: MediaStream) => {
				logger.log('onlocalstream', stream)
				this.setLocalStream(stream)
			},
			onremotestream: (stream: MediaStream) => {
				// The publisher stream is sendonly, we don't expect anything here
			},
			oncleanup: () => {
				logger.log('oncleanup')
			},
		}
	}

	private reconfigure = (bitrate: number) => {
		logger.log(`Reconfigure bitrate: ${bitrate}`)
		this.localFeed?.send({
			message: {
				request: 'configure',
				bitrate,
			},
		})
	}

	private logStats = () => {
		const localPC = this.localFeed?.webrtcStuff?.pc
		localPC && this.rtcMetrics.capture(this.userId, localPC)
		Object.entries(this.remoteFeeds).forEach(e => {
			const userId = e[0]
			const pc = e[1].webrtcStuff?.pc
			pc && this.rtcMetrics.capture(userId, pc)
		})
		logger.log('Current RTC metrics', this.rtcMetrics.current)
	}

	private get remoteUserStreams(): RemoteUserStream[] {
		return Object.entries(this.remoteStreams).map(entry => {
			return {
				feedId: entry[0],
				userId: entry[1].userId,
				stream: entry[1].stream,
				janusId: 'default',
			}
		})
	}

	private sendOffer = () => {
		this.localFeed?.createOffer({
			media: {
				audioRecv: false,
				videoRecv: false,
				audioSend: true,
				video: {
					deviceId: this.camera,
				},
			},
			simulcast: false,
			simulcast2: false,
			success: (sdp: any) => {
				logger.log('Created SDP offer', sdp)
				this.localFeed?.send({
					message: {
						request: 'configure',
						audio: true,
						video: true,
						bitrate: this.bitrate,
					},
					jsep: sdp,
				})
			},
			error: (error: any) => {
				logger.error(error)
			},
		})
	}

	private isSameStream = (
		first?: RemoteUserStream,
		second?: RemoteUserStream
	) => {
		return (
			first &&
			second &&
			first.feedId === second.feedId &&
			first.userId === second.userId
		)
	}

	private putRemoteStream = (
		feedId: FeedId,
		userId: UserId,
		stream: MediaStream
	) => {
		const remoteUserStream: RemoteUserStream = {
			feedId,
			userId,
			stream,
			janusId: 'default',
		}
		const currentStream = this.remoteStreams[feedId]

		if (!this.isSameStream(currentStream, remoteUserStream)) {
			logger.log('put a new remote stream', feedId, userId, stream)
			this.remoteStreams[feedId] = remoteUserStream
			this.remoteUserStreamObserver &&
				this.remoteUserStreamObserver(this.remoteUserStreams)
		}
	}

	private deleteRemoteStream = (feedId: FeedId) => {
		logger.log('deleteRemoteStream', feedId)
		delete this.remoteStreams[feedId]
		this.remoteUserStreamObserver &&
			this.remoteUserStreamObserver(this.remoteUserStreams)
	}

	private setLocalStream = (stream: MediaStream) => {
		logger.log('setLocalStream', stream)
		this.localStream = stream
		this.localStreamObserver && this.localStreamObserver(stream)
	}

	private subscribe = (publisher: Publisher) => {
		logger.log('subscribe', publisher)
		const feedId = publisher.id.toString()
		const userId = publisher.display
		const videoCodec = publisher.video_codec
		const talking = publisher.talking

		let remoteFeed: PluginHandle | null = null
		this.janus.attach({
			plugin: 'janus.plugin.videoroom',
			opaqueId: this.opaqueId,
			success: (pluginHandle: PluginHandle) => {
				if (!this.privateId) {
					throw new Error('Private id is still unavailable!')
				}
				remoteFeed = pluginHandle
				remoteFeed.simulcastStarted = false
				remoteFeed.videoCodec = videoCodec
				remoteFeed.send({
					message: {
						request: 'join',
						room: this.vcrDefinition.janusVcrId,
						ptype: 'subscriber',
						feed: parseInt(feedId),
						private_id: this.privateId,
					},
				})
			},
			error: (error: any) => {
				logger.error(error)
			},
			onmessage: (msg: Message, jsep: any) => {
				logger.log(`onmessage (subscriber ${userId})`, msg, jsep)
				if (msg.error) {
					logger.error(`error received by subscriber ${userId}`, msg, jsep)
				}
				if (!remoteFeed) {
					logger.error('Remote feed is not ready!')
					return
				}
				const event = msg.videoroom
				switch (event) {
					case 'attached':
						logger.log(`Subscriber ${userId} attached`)
						this.remoteFeeds[userId] = remoteFeed
						break
					case 'event':
						logger.log(`Subscriber ${userId} received an event`)
						break
					default:
						logger.error(`Invalid subscriber ${userId} "videoroom": ${event}`)
				}
				if (jsep) {
					logger.log(`Subscriber ${userId} received an SDP`, jsep)
					remoteFeed.createAnswer({
						jsep,
						media: {
							audioSend: false,
							videoSend: false,
						},
						success: (jsep: any) => {
							if (!remoteFeed) {
								logger.error('Remote feed is not ready!')
								return
							}
							logger.log(`Subscriber ${userId} created an SDP answer`, jsep)
							remoteFeed.send({
								jsep,
								message: {
									request: 'start',
									room: this.vcrDefinition.janusVcrId,
								},
							})
						},
						error: (error: any) => {
							logger.error(`Subscriber ${userId} received an error`, error)
						},
					})
				}
			},
			webrtcState: (on: boolean) => {
				logger.log(`webrtcState (subscriber ${userId})`, on)
			},
			onlocalstream: (stream: MediaStream) => {
				// The subscriber stream is recvonly, we don't expect anything here
			},
			onremotestream: (stream: MediaStream) => {
				logger.log(`onremotestream (subscriber ${userId})`)
				this.putRemoteStream(feedId, userId, stream)
			},
			oncleanup: () => {
				logger.log(`oncleanup (subscriber ${userId})`)
			},
		})

		this.spotlightChangeObserver &&
			this.spotlightChangeObserver({
				spotlight: talking,
				userId,
			})
	}

	onRemoteStreamChange = (observer: RemoteUserStreamsObserver) => {
		this.remoteUserStreamObserver = observer
		return this
	}

	onLocalStreamChange = (observer: MediaStreamObserver) => {
		this.localStreamObserver = observer
		return this
	}

	onSpotlightChange = (observer: SpotlightChangeObserver) => {
		this.spotlightChangeObserver = observer
		return this
	}

	disconnect = () => {
		logger.log('Disconnect')
		this.clearResources()
		this.janus.destroy({ cleanupHandles: true })
	}

	private clearResources = () => {
		this.localStreamObserver = undefined
		this.spotlightChangeObserver = undefined
		const remoteUserStreamObserver = this.remoteUserStreamObserver
		this.remoteUserStreamObserver = undefined
		remoteUserStreamObserver && remoteUserStreamObserver([])
		clearInterval(this.mediaStatsCheckInterval)
		this.mediaStatsCheckInterval = undefined
	}

	private getUserId = (feedId: FeedId) => {
		return this.remoteStreams[feedId]?.userId
	}

	private changeSpotlight = (feedId: FeedId, spotlight: boolean) => {
		if (feedId === this.vcrDefinition.janusUserId.toString()) {
			return
		}
		const userId = this.getUserId(feedId)
		if (!userId) {
			logger.warn(
				`User id ${userId} (feed id: ${feedId}) is not available for a spotlight change`
			)
			return
		}
		this.spotlightChangeObserver &&
			this.spotlightChangeObserver({ spotlight, userId })
	}
}

export type VCRProps = {
	vcrId: VcrId
	userId: UserId
	token: AuthToken
	onLostConnection: LostConnectionHandler
}

type VCRDefinition = {
	janusNodeUrl: string
	janusVcrId: JanusVcrId
	janusAccessToken: string
	janusUserId: number
}

type VcrResponse =
	| { type: 'VCR_DEFINITION'; vcrDefinition: VCRDefinition }
	| { type: 'ERROR'; response: string }

const getVcr = async (token: AuthToken, vcrId: VcrId): Promise<VcrResponse> => {
	const response = await fetch(`${Config.vcrManagerUrl}/vcr/${vcrId}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})
	const jsonResponse = await response.json()
	if (typeof jsonResponse === 'string') {
		return {
			type: 'ERROR',
			response: jsonResponse,
		}
	}
	return {
		type: 'VCR_DEFINITION',
		vcrDefinition: {
			janusNodeUrl: jsonResponse.vcr.containerId.janusNodeWebSocketsUrl,
			janusVcrId: jsonResponse.vcr.vcrId.janusUniqueId,
			janusAccessToken: jsonResponse.vcr.janusVcrAccessToken.value,
			janusUserId: jsonResponse.vcr.janusUserId.janusUniqueId,
		},
	}
}

type GetJanusVcrResult =
	| { type: 'success'; vcr: VCR }
	| { type: 'error'; response: VcrResponse }

export async function getJanusVcr(props: VCRProps): Promise<GetJanusVcrResult> {
	const vcrResponse = await getVcr(props.token, props.vcrId)
	if (vcrResponse.type === 'ERROR') {
		return { type: 'error', response: vcrResponse }
	}
	const vcr = await initJanus()
		.then(() =>
			createJanusSession(
				vcrResponse.vcrDefinition.janusNodeUrl,
				props.onLostConnection
			)
		)
		.then(janus => new VCR(janus, vcrResponse.vcrDefinition, props.userId))
		.then(vcr => {
			logger.log('VCR created! Configure it to connect!', vcr)
			return vcr
		})
	return { type: 'success', vcr }
}
