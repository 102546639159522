import styled from '@emotion/styled'
import { AnimatePresence, motion } from 'framer-motion'
import { Colors } from 'common/presentation/colors'
import cancel from 'common/presentation/images/cancel2.svg'
import Translate from 'intl/translate'
import { FC, MouseEvent, useCallback } from 'react'

export const Curtain = styled.div`
	pointer-events: auto;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 1;
	opacity: 1;
`

const Outer = styled.div`
	align-items: center;
	display: flex;
	pointer-events: none;
	position: fixed;
	width: fit-content;
	top: 0;
	bottom: 0;
	right: 0px;
	z-index: 10;
	overflow: visible;
`

const Container = styled(motion.div)`
	background-color: ${Colors.white};
	box-shadow: -10px 0 40px 0 rgba(41, 54, 73, 0.1);
	display: flex;
	flex-direction: column;
	height: 100%;
	pointer-events: auto;
	z-index: 10;
	width: 0;
	max-width: 70vw;
`

const TitleContainer = styled.div`
	border-bottom: 1px solid ${Colors.brightGray};
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px;
	min-width: 0;
`

const Title = styled.div`
	color: ${Colors.black};
	font-family: Ubuntu;
	font-size: 28px;
	font-weight: 500;
`

const Button = styled.button`
	width: 32px;
	height: 32px;
	background-color: ${Colors.brightGray};
	border-radius: 16px;
	padding: 0;
	border: none;
	cursor: pointer;

	&:focus {
		outline: none;
	}
`

const Img = styled.img`
	height: 100%;
	width: 100%;
`

const Content = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	min-height: 0;
	position: relative;
`

const CURTAIN = 'CURTAIN'

type Props = {
	open: boolean
	title: string
	width: number
	onClose: () => void
}

const MiscPanelWrapper: FC<Props> = ({
	title,
	open,
	children,
	onClose,
	width,
}) => {
	const onCurtainClose = useCallback(
		(e: MouseEvent<HTMLDivElement>) => {
			if (e.currentTarget.id === CURTAIN) {
				onClose()
			}
		},
		[onClose]
	)

	return (
		<Outer>
			{open && <Curtain id={CURTAIN} onClick={onCurtainClose} />}
			<AnimatePresence initial>
				{open && (
					<Container
						style={{ width: `${width}px` }}
						initial={{ translateX: width }}
						animate={{ translateX: 0 }}
						exit={{ translateX: width }}
						transition={{ duration: 0.5 }}
					>
						<TitleContainer>
							<Title>
								<Translate>{title}</Translate>
							</Title>
							<Button onClick={onClose}>
								<Img src={cancel} />
							</Button>
						</TitleContainer>
						<Content>{children}</Content>
					</Container>
				)}
			</AnimatePresence>
		</Outer>
	)
}

export default MiscPanelWrapper
