import styled from '@emotion/styled'
import { Colors } from 'common/presentation/colors'
import { ui } from '@beelday/common'
import { PROFILE_MISSING, RoomId, User, UserProfile } from 'common/types'
import Translate from 'intl/translate'
import { FC } from 'react'
import ProfileAvatar from 'users/profile-avatar'
import Users from './chat-room-users'
import { TrainerRoomEngagement } from './engagement'
import chatTrainerRoomBg from './images/chat-trainer-room-background.svg'
import {
	PublicChatRoomEncouragement,
	PublicChatRoomStatus,
	PublicChatRoomType,
} from './model'
import RoomContainer from './room-container'
import rooms from './rooms-presentation'

const Name = styled.div`
	color: ${Colors.black};
	font-family: Ubuntu;
	font-weight: 500;
	font-size: 16px;
	margin-top: 12px;
	text-align: center;

	${ui.responsive.desktop1440} {
		font-size: 18px;
		margin-top: 16px;
	}
`

const TrainerFace = styled(ProfileAvatar)`
	margin-top: 10px;
	height: 80px;
	width: 80px;

	${ui.responsive.desktop1440} {
		margin-top: 20px;
		height: 116px;
		width: 116px;
	}

	img {
		border-radius: 50%;
		height: 100%;
		width: 100%;
	}
`

const EmptyState = styled.div`
	display: flex;
	align-items: center;
	flex: 1;
	margin-top: auto;
	overflow: hidden;
	padding-top: 20px;

	img {
		height: 100%;
		max-width: 200px;
		max-height: 140px;
		width: 100%;
	}
`

type ChatRoom = {
	id: RoomId
	status?: PublicChatRoomStatus
	hobby?: string
	encouragement?: PublicChatRoomEncouragement
	type?: PublicChatRoomType
	users: (User & Partial<UserProfile>)[]
}

type Props = {
	room: ChatRoom
	upperEchelon?: UserProfile | PROFILE_MISSING
	onSelect: () => void
}

const MeetTrainerRoom: FC<Props> = ({ room, upperEchelon, onSelect }) => {
	return upperEchelon ? (
		<RoomContainer
			onSelect={onSelect}
			style={{
				borderRadius: '50px',
				boxShadow: '0 0 40px 0 rgba(94, 92, 154, 0.15)',
			}}
		>
			<TrainerFace profile={upperEchelon} />
			<Name>
				<Translate>lobby.chat_room.upper_echelon</Translate>
			</Name>
			<TrainerRoomEngagement
				encouragement={room.encouragement}
				upperEchelon={upperEchelon}
			/>
			{room.users.length === 0 ? (
				<EmptyState>
					<img src={room.type && rooms[room.type].emptyImg} alt="Empty State" />
				</EmptyState>
			) : (
				<Users users={room.users} roomType="UPPER_ECHELON" onJoin={onSelect} />
			)}
		</RoomContainer>
	) : null
}

export default MeetTrainerRoom
