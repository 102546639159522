export const RightArrowIcon = (): JSX.Element => (
	<svg
		version="1.1"
		id="Layer_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		width="10px"
		height="15px"
		viewBox="0 0 7.3 12.6"
		xmlSpace="preserve"
	>
		<path
			fill="currentColor"
			d="M0.7,12.6c-0.2,0-0.4-0.1-0.5-0.2c-0.3-0.3-0.3-0.7,0-1l5.1-5.1L0.2,1.2c-0.3-0.3-0.3-0.7,0-1s0.7-0.3,1,0
	l6.1,6.1l-6.1,6.1C1.1,12.5,0.9,12.6,0.7,12.6z"
		/>
	</svg>
)
