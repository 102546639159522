import { FC, useEffect } from 'react'
import {
	ConnectionQualityObserver,
	onConnectionQualityChange,
	removeConnectionQualityObserver,
} from 'connectivity/awaited-connections'
import { useDispatch } from 'react-redux'
import { setConnectionQuality } from 'connectivity/redux'

const ConnectionHealthMonitor: FC = () => {
	const dispatch = useDispatch()

	useEffect(() => {
		const observer: ConnectionQualityObserver = quality =>
			dispatch(setConnectionQuality(quality))
		onConnectionQualityChange(observer)
		return () => {
			removeConnectionQualityObserver(observer)
		}
	}, [dispatch])

	return null
}

export default ConnectionHealthMonitor
