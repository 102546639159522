import { FC } from 'react'
import styled from '@emotion/styled'
import { colors, styles, ui } from '@beelday/common'

const ChatRoomContainer = styled.div`
	background-color: ${colors.white};
	border-radius: 20px;
	display: flex;
	flex: 1;
	box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
`

const Inside = styled.div`
	height: 100%;
	width: 100%;
	position: relative;
	pointer-events: none;
	${styles.zoomOnHover}
`

const Clickable = styled.div<{ size: number }>`
	cursor: pointer;
	pointer-events: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	object-fit: contain;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	padding: 15px;

	${ui.responsive.desktop1440} {
		padding: 30px;
	}
`

type Props = {
	onSelect: () => unknown
	className?: string
	style?: React.CSSProperties
}
const RoomContainer: FC<Props> = ({ children, onSelect, className, style }) => (
	<ChatRoomContainer className={className} style={style}>
		<Inside>
			<Clickable onClick={onSelect} size={80} className="zoomOnHoverActivator">
				{children}
			</Clickable>
		</Inside>
	</ChatRoomContainer>
)

export default RoomContainer
