import { colors, UserProfile } from '@beelday/common'
import styled from '@emotion/styled'
import CustomTooltip from 'common/presentation/custom-tooltip'
import { PROFILE_MISSING } from 'common/types'
import ProfileAvatar from 'users/profile-avatar'

const Container = styled.div`
	flex-grow: 0;
	display: flex;
	justify-content: center;
	justify-items: stretch;
	align-items: center;
	padding: 0 15px;
	z-index: 2;
`

const Profile = styled(ProfileAvatar)<{
	movedLeft?: boolean
	size: string
}>`
	width: ${props => props.size};
	height: ${props => props.size};
	border: 2px solid ${colors.white};
	border-radius: 50%;
	margin-left: ${props => (props.movedLeft ? '-10px' : '0px')};
`

const More = styled.div<{ size: string }>`
	width: ${props => props.size};
	height: ${props => props.size};
	border-radius: 100px;
	display: flex;
	justify-items: center;
	justify-content: center;
	align-items: center;
	font-family: Ubuntu;
	font-size: 13px;
	font-weight: 500;
	background-color: ${colors.indigoBlue};
	border: 2px solid ${colors.white};
	margin-left: -10px;
	color: ${colors.white};
`

export const Profiles = ({
	profiles,
	size = '28px',
	maxFacesVisible = 3,
}: Props): JSX.Element | null => {
	if (profiles.length === 0) {
		return null
	} else if (profiles.length <= maxFacesVisible) {
		return (
			<>
				{profiles.map((p, idx) => (
					<Profile
						key={p ? p.id : idx}
						movedLeft={idx > 0}
						profile={p}
						size={size}
					/>
				))}
			</>
		)
	} else {
		const additional = profiles.length - maxFacesVisible
		return (
			<>
				{profiles.map(
					(p, idx) =>
						idx < maxFacesVisible && (
							<Profile
								key={p ? p.id : idx}
								movedLeft={idx > 0}
								profile={p}
								size={size}
							/>
						)
				)}
				<More size={size}>+{additional}</More>
			</>
		)
	}
}

const List = styled.div`
	width: 270px;
	max-height: 180px;
	border-radius: 10px;
	box-shadow: 0 5px 15px 0 ${colors.black15};
	padding: 15px;
	background: ${colors.white};
	overflow-y: auto;
`

const Row = styled.div`
	align-items: center;
	display: flex;
	flex-flow: row nowrap;
	margin-bottom: 10px;
`

const Name = styled.div`
	color: ${colors.black};
	flex-grow: 1;
	font-size: 12px;
	font-weight: 500;
	margin-left: 10px;
`

type Props = {
	profiles: (UserProfile | PROFILE_MISSING)[]
	size?: string
	maxFacesVisible?: number
}

const ProfilesInRow = ({
	profiles,
	size = '28px',
	maxFacesVisible = 3,
}: Props) => {
	return (
		<Container>
			<Profiles
				profiles={profiles}
				size={size}
				maxFacesVisible={maxFacesVisible}
			/>
			{profiles.length > 0 && (
				<CustomTooltip zIndex={4}>
					<List>
						{profiles.map(p =>
							p ? (
								<Row key={p.id}>
									<Profile profile={p} size={size} />
									<Name>{p.displayName || p.name}</Name>
								</Row>
							) : null
						)}
					</List>
				</CustomTooltip>
			)}
		</Container>
	)
}

export default ProfilesInRow
