import { intl } from '@beelday/common'
import ConfirmBanner from 'common/presentation/confirm-banner'
import { useAuthenticatedBeeldayClient } from 'connectivity/beelday-hooks'
import { FC, useState } from 'react'
import {
	Menu,
	MenuItem,
	MenuItemLine,
	MenuToggleItem,
} from 'room/common/presentation/menu'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { Break } from '../../common/presentation/icon/break'
import { Busy } from '../../common/presentation/icon/busy'
import { StartReview } from '../../common/presentation/icon/start-review'
import { MicMenuItem } from 'features/microphone'
import MiscPanelMenu from '../../misc/misc-panel-menu'
import CamMenuItem from 'features/camera/camera-menu-item'

type Props = {
	onTrainerBusy: () => void
	onTrainerBackFromBusy: () => void
	isTrainerBusy: boolean
	onStartBreak: () => void
}

export const TrainerMenuGroupsView: FC<Props> = props => {
	const beeldayClient = useAuthenticatedBeeldayClient()
	const joinedRoomAddress = useAssertedJoinedRoomAddress()
	const [confirmReview, setConfirmReview] = useState(false)

	const startReview = () => setConfirmReview(true)

	return (
		<Menu>
			{confirmReview ? (
				<ConfirmBanner
					message="groups.confirm_review"
					onAccept={() => beeldayClient.reviewTask(joinedRoomAddress)}
					onDecline={() => setConfirmReview(false)}
					accept="yes"
					decline="no"
				/>
			) : null}
			<MenuToggleItem
				icon={<StartReview />}
				active={confirmReview}
				onClick={startReview}
				label={<intl.Translate>room_menu.group_review</intl.Translate>}
			/>
			<MenuToggleItem
				icon={<Busy />}
				active={props.isTrainerBusy}
				onClick={
					props.isTrainerBusy
						? props.onTrainerBackFromBusy
						: props.onTrainerBusy
				}
				label={<intl.Translate>room_menu.trainer_busy</intl.Translate>}
			/>
			<MicMenuItem />
			<CamMenuItem />
			<MenuItem
				icon={<Break />}
				onClick={props.onStartBreak}
				label={<intl.Translate>room_menu.break</intl.Translate>}
			/>
			<MenuItemLine />
			<MiscPanelMenu />
		</Menu>
	)
}
