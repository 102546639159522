import {
	InteractionSchemeKey,
	InteractionSchemeState,
	User,
	UserId,
	WorkflowEvent,
	WorkflowId,
	WorkflowKey,
} from 'common/types'

type UserInteractionScheme = WorkflowKey &
	InteractionSchemeKey & {
		user: User
	}

export const findUserInteractionScheme = (
	workflowId: WorkflowId,
	userId: UserId,
	event: WorkflowEvent
): UserInteractionScheme | undefined => {
	const states: InteractionSchemeState[] =
		event.workflowState.interactionSchemesState
	if (states.length > 1) {
		throw new Error(
			`User ${userId} is found in ${
				states.length
			} interaction schemes (${JSON.stringify(states)})!`
		)
	} else if (states.length === 0) {
		return undefined
	}
	const state = states[0]
	const user = state.users.find(user => user.id === userId)
	return (
		user && {
			workflowId,
			interactionSchemeId: state.id,
			user,
		}
	)
}
