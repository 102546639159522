import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { AspectRatios } from 'common/presentation/aspect-ratios'
import { FC, useMemo } from 'react'
import useResize from 'utils/hooks/use-resize'
import { SceneVideoUser } from 'video-conference-media'
import { SceneVideoUserStream } from 'video-conference-media/presentation/scene-video-user-stream'
import { HighlightStatus } from '../knowledge-checks-redux'

const Container = styled.div`
	flex: 1;
	display: flex;
	min-height: 0;
	width: 100%;
`

const VideoStreamColumnDiv = styled.div`
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
`

const videoMargin = 5

const calculateVideoSize = (
	containerWidth: number,
	containerHeight: number,
	numberOfVideos: number
) => {
	const dividedHeight = Math.floor(
		(containerHeight - numberOfVideos * videoMargin * 2) / numberOfVideos
	)
	const effectiveContainerWidth = containerWidth - 2 * videoMargin
	const percentageEffectiveContainerWidth =
		(effectiveContainerWidth / containerWidth) * 100
	const aspectedWidth = dividedHeight * AspectRatios.standardVideo
	if (aspectedWidth >= effectiveContainerWidth) {
		const height = effectiveContainerWidth / AspectRatios.standardVideo
		return {
			width: `${percentageEffectiveContainerWidth}%`,
			height: `${height}px`,
		}
	}
	return {
		width: `${aspectedWidth}px`,
		height: `${dividedHeight}px`,
	}
}

type VideoStreamColumnProps = Pick<Props, 'sceneVideoUsers'> & {
	width: string
}

type StreamCurtainProps = {
	userStreamId: string
	highlight: HighlightStatus
}

const Curtain = styled.div<{
	isHighlightActive: boolean
	isStreamHighlighted: boolean
}>`
	opacity: ${props =>
		props.isHighlightActive ? (props.isStreamHighlighted ? 1 : 0.5) : 1};
	transition: opacity 0.3s ease-in-out;
	width: 100%;
`

const KnowledgeCheckStreamHighlightCurtain: FC<StreamCurtainProps> = ({
	children,
	userStreamId,
	highlight,
}) => {
	const { highlightedUsers, isHighlightActive } = highlight
	const isStreamHighlighted = highlightedUsers.includes(userStreamId)

	return (
		<Curtain
			isHighlightActive={isHighlightActive}
			isStreamHighlighted={isStreamHighlighted}
		>
			{children}
		</Curtain>
	)
}

const maxNumberOfStreamsInColumn = 8

const VideoStreamColumn: FC<
	VideoStreamColumnProps & { highlight: HighlightStatus }
> = ({ width, sceneVideoUsers, highlight }) => {
	const {
		ref: containerRef,
		width: containerWidth,
		height: containerHeight,
	} = useResize()
	const sizingClass = useMemo(() => {
		const videoSize = calculateVideoSize(
			containerWidth,
			containerHeight,
			Math.min(maxNumberOfStreamsInColumn, sceneVideoUsers.length)
		)
		return css`
			width: ${videoSize.width};
			height: ${videoSize.height};
			margin: ${videoMargin}px;
		`
	}, [containerHeight, containerWidth, sceneVideoUsers.length])

	return (
		<VideoStreamColumnDiv
			css={css`
				width: ${width};
				padding: ${videoMargin}px;
			`}
			ref={containerRef}
		>
			{sceneVideoUsers.map(sceneVideoUser => (
				<KnowledgeCheckStreamHighlightCurtain
					key={sceneVideoUser.userId}
					userStreamId={sceneVideoUser.userId}
					highlight={highlight}
				>
					<SceneVideoUserStream
						css={sizingClass}
						sceneVideoUser={sceneVideoUser}
					/>
				</KnowledgeCheckStreamHighlightCurtain>
			))}
		</VideoStreamColumnDiv>
	)
}

type Columns = SceneVideoUser[][]

const splitColumns = (sceneVideoUsers: SceneVideoUser[]): Columns => {
	return sceneVideoUsers.reduce((previousValue, currentValue, currentIndex) => {
		const columnIndex = Math.floor(currentIndex / maxNumberOfStreamsInColumn)
		if (previousValue.length <= columnIndex) {
			previousValue.push([])
		}
		previousValue[columnIndex].push(currentValue)
		return previousValue
	}, [] as Columns)
}

type Props = {
	sceneVideoUsers: SceneVideoUser[]
	highlight: HighlightStatus
}

export const KnowledgeCheckVideoColumns: FC<Props> = props => {
	const columns = useMemo(
		() => splitColumns(props.sceneVideoUsers),
		[props.sceneVideoUsers]
	)
	return (
		<Container>
			{columns.map((column, index) => (
				<VideoStreamColumn
					key={index}
					width={`${100 / columns.length}%`}
					sceneVideoUsers={column}
					highlight={props.highlight}
				/>
			))}
		</Container>
	)
}
