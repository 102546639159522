import { css } from '@emotion/react'
import ReactPaginate from 'react-paginate'
import colors from '../colors'
import { FC } from 'react'

const paginateStyle = css`
	margin-bottom: 2rem;
	display: flex;
	list-style-type: none;
	padding: 0 5rem;

	li a {
		border-radius: 7px;
		padding: 4px 6px;
		cursor: pointer;
		font-size: 16px;
		transition: color 0.25s;

		&:hover {
			color: ${colors.indigoBlue};
		}
	}

	li.previous a,
	li.next a,
	li.break a {
		border-color: transparent;
	}

	li.selected a {
		background-color: ${colors.indigoBlue};
		border-color: transparent;
		color: white;
	}

	li.disabled a {
		color: grey;
	}
	li.disable,
	li.disabled a {
		cursor: default;
	}
`

type Props = {
	pageCount: number
	onPageChange: ({ selected }: { selected: number }) => void
	initialPage: number
}

export const Pagination = ({
	pageCount,
	onPageChange,
	initialPage,
}: Props): JSX.Element => {
	return (
		<ReactPaginate
			css={paginateStyle}
			breakLabel="..."
			nextLabel=">"
			onPageChange={onPageChange}
			pageRangeDisplayed={5}
			marginPagesDisplayed={1}
			initialPage={initialPage}
			forcePage={initialPage}
			pageCount={pageCount}
			previousLabel="<"
			renderOnZeroPageCount={null}
		/>
	)
}

export const CursorPagination: FC<{
	hasNext: boolean
	hasPrev: boolean
	onNext: () => unknown
	onPrev: () => unknown
}> = ({ hasNext, hasPrev, onNext, onPrev }) => {
	if (!hasNext && !hasPrev) return null
	return (
		<ul css={paginateStyle}>
			{hasPrev ? (
				<li className="previous">
					<a onClick={onPrev}>&lt;</a>
				</li>
			) : null}
			&nbsp;...&nbsp;
			{hasNext ? (
				<li className="next">
					<a onClick={onNext}>&gt;</a>
				</li>
			) : null}
		</ul>
	)
}

export const OffsetPagination: FC<{
	hasNext: boolean
	hasPrev: boolean
	offset: number
	pageSize: number
	onNext: () => unknown
	onPrev: () => unknown
}> = ({ hasNext, hasPrev, onNext, onPrev, offset, pageSize }) => {
	if (!hasNext && !hasPrev) return null
	return (
		<ul css={paginateStyle}>
			{hasPrev ? (
				<li className="previous">
					<a onClick={onPrev}>&lt;</a>
				</li>
			) : null}
			&nbsp;{offset}&nbsp;-&nbsp;{offset + pageSize}&nbsp;
			{hasNext ? (
				<li className="next">
					<a onClick={onNext}>&gt;</a>
				</li>
			) : null}
		</ul>
	)
}
