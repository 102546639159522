import styled from '@emotion/styled'
import {
	addDays,
	addMonths,
	isAfter,
	isBefore,
	isSameDay,
	isSameMonth,
	previousMonday,
	setMonth,
	setYear,
	startOfMonth,
	subMonths,
} from 'date-fns'
import { FC, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import colors from '../colors'
import styles from '../styles'
import { ModalPicker } from './modal'

const Days = styled.div`
	flex: 1;
	display: grid;
	align-items: center;
	grid-template-columns: repeat(7, 1fr);
	padding-bottom: 6px;
`

const Day = styled.div<{
	selected?: boolean
	invalid?: boolean
}>`
	display: flex;
	width: 36px;
	height: 32px;
	justify-content: center;
	align-items: center;
	font-family: Ubuntu;
	font-size: 14px;
	background-color: ${props =>
		props.selected ? colors.indigoBlue : undefined};
	color: ${props => (props.selected ? colors.white : colors.black)};
	text-decoration: ${props => (props.invalid ? 'line-through' : undefined)};
	cursor: pointer;
	border-radius: 4px;
	border: solid 2px transparent;

	:hover {
		border: solid 2px ${colors.heather};
	}

	&.unselectable {
		cursor: not-allowed;
		background-color: ${colors.heather30};
		color: ${colors.heather};

		:hover {
			border: solid 2px transparent;
		}
	}
`

const OtherMonthDay = styled.div<{ selected?: boolean; invalid?: boolean }>`
	display: flex;
	width: 36px;
	height: 32px;
	justify-content: center;
	align-items: center;
	text-decoration: ${props => (props.invalid ? 'line-through' : undefined)};
	font-family: Ubuntu;
	font-size: 14px;
	background-color: ${props =>
		props.selected ? colors.indigoBlue : undefined};
	color: ${props => (props.selected ? colors.white : colors.heather)};
	cursor: pointer;
	border-radius: 4px;
	border: 2px solid transparent;
	:hover {
		border: solid 2px ${colors.heather};
	}
	&.unselectable {
		cursor: not-allowed;
		background-color: ${colors.heather30};
		color: ${colors.heather};

		:hover {
			border: solid 2px transparent;
		}
	}
`

const Calendar = styled.div`
	display: flex;
	flex-direction: column;
	align-content: flex-start;
	justify-content: center;
	align-items: stretch;
	width: 303px;
	height: 280px;
`

const Month = styled.div`
	font-family: Ubuntu;
	font-size: 16px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	text-align: center;
	color: ${colors.black};
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 2px solid #eee;
	height: 60px;
	&.unselectable {
		cursor: not-allowed;
		background-color: ${colors.heather30};
		color: ${colors.heather};

		:hover {
			border: solid 2px transparent;
		}
	}
`

const Arrow = styled.div`
	width: 35px;
	height: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${colors.indigoBlue};
	font-size: 20px;
	cursor: pointer;
	user-select: none;
	:hover,
	:active {
		background-color: ${colors.indigoBlue};
		color: ${colors.white};
	}
`

const LargeGrid = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: stretch;
	align-items: stretch;
	flex-wrap: wrap;
	flex: 1;
`

const LargeField = styled.div<{ selected?: boolean }>`
	display: flex;
	width: 30%;
	flex-grow: 1;
	align-self: stretch;
	min-height: 32px;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	padding: 0.5em;
	margin: 0.1em;
	font-family: Ubuntu;
	font-size: 14px;
	background-color: ${props =>
		props.selected ? colors.indigoBlue : undefined};
	color: ${props => (props.selected ? colors.white : colors.black)};
	cursor: pointer;
	border-radius: 4px;
	border: solid 2px transparent;
	:hover {
		border: solid 2px ${colors.heather};
	}
	&.unselectable {
		cursor: not-allowed;
		background-color: ${colors.heather30};
		color: ${colors.heather};

		:hover {
			border: solid 2px transparent;
		}
	}
`

type DateRange = {
	from: Date
	to: Date
}

type SelectableDateNumber = {
	dateNumber: number
	state: 'SELECTABLE' | 'UNSELECTABLE'
}

type DatePickerProps = {
	value?: Date
	startMode?: 'DAY' | 'MONTH' | 'YEAR'
	onChange: (val: Date) => unknown
	onClose?: () => unknown
	invalid?: Date[]
	className?: string
	range?: DateRange
}

export const DatePicker: FC<DatePickerProps> = ({
	value,
	startMode,
	onChange,
	onClose,
	className,
	invalid,
	range,
}) => {
	const [current, setCurrent] = useState(value)
	const [mode, setMode] = useState(startMode)

	useEffect(() => {
		setCurrent(value)
		setMode(startMode)
	}, [value, startMode])

	return (
		<ModalPicker onClose={onClose} className={className}>
			{mode === 'YEAR' ? (
				<YearPicker
					date={value}
					focus={current || new Date()}
					mode="PAST"
					range={range}
					onChange={date => {
						setCurrent(date)
						setMode('MONTH')
					}}
				/>
			) : mode === 'MONTH' ? (
				<MonthPicker
					date={current}
					range={range}
					onChange={date => {
						setCurrent(date)
						setMode('DAY')
					}}
				/>
			) : (
				<DayPicker
					date={value}
					focus={current || new Date()}
					range={range}
					onChange={onChange}
					onHeaderClick={() => setMode('YEAR')}
					invalid={invalid}
				/>
			)}
		</ModalPicker>
	)
}

const RENDER_YEARS = 18
const calculateYearFrom = (year: number, mode: 'PAST' | 'FUTURE' | 'AROUND') =>
	mode === 'PAST'
		? year - RENDER_YEARS + 1
		: mode === 'FUTURE'
		? year
		: year - Math.ceil(RENDER_YEARS / 2)

const YearPicker: React.FC<{
	date?: Date
	focus: Date
	range?: DateRange
	mode: 'PAST' | 'FUTURE' | 'AROUND'
	onChange: (selected: Date) => unknown
}> = ({ date, focus, mode, range, onChange }) => {
	const year = focus.getFullYear()
	const [from, setFrom] = useState(calculateYearFrom(year, mode))

	useEffect(() => {
		setFrom(calculateYearFrom(year, mode))
	}, [year, mode])

	const to = from + RENDER_YEARS

	const goToPrevYearRange = () => {
		setFrom(from - RENDER_YEARS)
	}

	const goToNextYearRange = () => {
		setFrom(from + RENDER_YEARS)
	}

	let years: Array<SelectableDateNumber>

	if (range) {
		const maxNumberOfPreviousValidYear = range?.from.getFullYear()
		const maxNumberOfFutureValidYear = range?.to.getFullYear()

		years = Array(to - from)
			.fill(from)
			.map((_, i) => {
				if (from + i > maxNumberOfFutureValidYear) {
					return { dateNumber: from + i, state: 'UNSELECTABLE' }
				} else if (from + i < maxNumberOfPreviousValidYear) {
					return { dateNumber: from + i, state: 'UNSELECTABLE' }
				} else {
					return { dateNumber: from + i, state: 'SELECTABLE' }
				}
			})
	} else {
		years = Array(to - from)
			.fill(from)
			.map((_, i) => {
				return { dateNumber: from + i, state: 'SELECTABLE' }
			})
	}

	return (
		<Calendar>
			<Month>
				<Arrow onClick={goToPrevYearRange}>&lt;</Arrow>
				<div>
					{years[0].dateNumber} - {years[years.length - 1].dateNumber}
				</div>
				<Arrow onClick={goToNextYearRange}>&gt;</Arrow>
			</Month>
			<LargeGrid>
				{years.map(current => (
					<LargeField
						key={current.dateNumber}
						selected={date && date.getFullYear() === current.dateNumber}
						onClick={() => onChange(setYear(focus, current.dateNumber))}
						className={
							current.state === 'UNSELECTABLE' ? 'unselectable' : 'undefined'
						}
						// 	style={{
						// 		backgroundColor:
						// 			current.state === 'UNSELECTABLE' ? colors.heather30 : undefined,
						// 		color:
						// 			current.state === 'UNSELECTABLE' ? colors.heather : undefined,
						// 	}}
					>
						{current.dateNumber}
					</LargeField>
				))}
			</LargeGrid>
		</Calendar>
	)
}

const MonthPicker: React.FC<{
	date?: Date
	range?: DateRange
	onChange: (date: Date) => unknown
}> = ({ date, range, onChange }) => {
	const { locale } = useIntl()
	const dateFormatter = useMemo(
		() =>
			Intl.DateTimeFormat(locale, {
				month: 'long',
			}),
		[locale]
	)

	let months: Array<SelectableDateNumber>

	if (range && date) {
		if (date > range.to) {
			date = range.to
		}

		const maxNumberOfPreviousValidMonth = range?.from.getMonth()
		const maxNumberOfFutureValidMonth = range?.to.getMonth()

		months = Array(12)
			.fill(0)
			.map((_, i) => {
				if (date?.getFullYear() === range.to.getFullYear()) {
					if (i > maxNumberOfFutureValidMonth) {
						return { dateNumber: i, state: 'UNSELECTABLE' }
					} else {
						return { dateNumber: i, state: 'SELECTABLE' }
					}
				}
				if (date?.getFullYear() === range.from.getFullYear()) {
					if (i < maxNumberOfPreviousValidMonth) {
						return { dateNumber: i, state: 'UNSELECTABLE' }
					} else {
						return { dateNumber: i, state: 'SELECTABLE' }
					}
				}

				if (
					//control flow analysis fail
					(date as Date).getFullYear() > range.from.getFullYear() &&
					(date as Date).getFullYear() < range.to.getFullYear()
				) {
					return { dateNumber: i, state: 'SELECTABLE' }
				} else {
					return { dateNumber: i, state: 'UNSELECTABLE' }
				}
			})
	} else {
		months = Array(12)
			.fill(0)
			.map((_, i) => {
				return { dateNumber: i, state: 'SELECTABLE' }
			})
	}

	return (
		<Calendar>
			<LargeGrid>
				{months.map(month => (
					<LargeField
						key={month.dateNumber}
						selected={date && date.getMonth() === month.dateNumber}
						onClick={() =>
							onChange(setMonth(date || new Date(), month.dateNumber))
						}
						className={
							month.state === 'UNSELECTABLE' ? 'unselectable' : 'undefined'
						}
					>
						<span css={styles.ellipsis}>
							{dateFormatter.format(
								setMonth(date || new Date(), month.dateNumber)
							)}
						</span>
					</LargeField>
				))}
			</LargeGrid>
		</Calendar>
	)
}

const SHOW_DAYS = 42
const DayPicker: React.FC<{
	date?: Date
	focus: Date
	onChange: (date: Date) => unknown
	onHeaderClick?: () => unknown
	range?: DateRange
	invalid?: Date[]
}> = ({ date, focus, range, onChange, onHeaderClick, invalid }) => {
	const [currentDay, setCurrentDay] = useState(focus)

	const firstDay = previousMonday(startOfMonth(currentDay))

	const goToPrevMonth = () => setCurrentDay(subMonths(currentDay, 1))
	const goToNextMonth = () => setCurrentDay(addMonths(currentDay, 1))

	const { locale } = useIntl()
	const dateFormatter = useMemo(
		() =>
			Intl.DateTimeFormat(locale, {
				month: 'long',
				year: 'numeric',
			}),
		[locale]
	)

	const days = Array(SHOW_DAYS)
		.fill(0)
		.map((_, i) => {
			return { dateNumber: i, state: 'SELECTABLE' }
		})

	return (
		<Calendar>
			<Month>
				<Arrow onClick={goToPrevMonth}>&lt;</Arrow>
				<div css={styles.clickable} onClick={onHeaderClick}>
					{dateFormatter.format(currentDay)}
				</div>
				<Arrow onClick={goToNextMonth}>&gt;</Arrow>
			</Month>

			<Days>
				{days.map(day => {
					const current = addDays(firstDay, day.dateNumber)
					const sameMonth = isSameMonth(current, currentDay)
					const isInvalid = invalid?.some(d => isSameDay(d, current))

					if (range) {
						if (isAfter(current, range.to)) {
							day.state = 'UNSELECTABLE'
						} else if (isBefore(current, range.from)) {
							day.state = 'UNSELECTABLE'
						}
					}

					const handleClick = () => {
						if (day.state === 'UNSELECTABLE') {
							return
						}
						onChange(current)
					}

					return sameMonth ? (
						<Day
							selected={date && isSameDay(date, current)}
							onClick={handleClick}
							invalid={invalid?.some(d => isSameDay(d, current))}
							key={day.dateNumber}
							className={
								day.state === 'UNSELECTABLE' ? 'unselectable' : 'undefined'
							}
						>
							{current.getDate()}
						</Day>
					) : (
						<OtherMonthDay
							selected={date && isSameDay(date, current)}
							onClick={handleClick}
							invalid={isInvalid}
							key={day.dateNumber}
							className={
								day.state === 'UNSELECTABLE' ? 'unselectable' : 'undefined'
							}
						>
							{current.getDate()}
						</OtherMonthDay>
					)
				})}
			</Days>
		</Calendar>
	)
}

export default DatePicker
