import { FC, useEffect, useState } from 'react'
import EtherPadIframe from 'room/group-room/presentation/ether-pad-iframe'
import { EtherpadSessionDto } from 'connectivity/beelday-client'
import { Color, GroupAddress } from 'common/types'
import { useAuthenticatedBeeldayClient } from 'connectivity/beelday-hooks'
import { useSelector } from 'react-redux'
import { RootState } from 'common/redux'

type Props = {
	groupAddress: GroupAddress
	groupColor: Color
}

export const ConfiguredEtherPad: FC<Props> = ({ groupAddress, groupColor }) => {
	const beeldayClient = useAuthenticatedBeeldayClient()
	const [session, setSession] = useState<EtherpadSessionDto>()
	const etherPadState = useSelector(
		(state: RootState) => state.groupRoom.etherPadsStates[groupAddress.groupId]
	)
	useEffect(() => {
		etherPadState.loaded &&
			beeldayClient.getEtherPadSession(groupAddress).then(s => setSession(s))
	}, [beeldayClient, etherPadState.loaded, groupAddress])

	return session && etherPadState && etherPadState.loaded ? (
		<EtherPadIframe
			color={groupColor}
			loaded={true}
			padId={session.padId}
			sessionId={session.sessionId}
		/>
	) : (
		<EtherPadIframe color={groupColor} loaded={false} />
	)
}
