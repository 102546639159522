import Keycloak from 'keycloak-js'
import { http } from '..'
import { checkStatus } from '../http'
import {
	InvalidCredentialsError,
	KeycloakConfig,
	Tokens,
} from './security-models'

const openIdTokenUrl = (config: KeycloakConfig) =>
	`${config.authUrl}/realms/${config.authRealm}/protocol/openid-connect/token`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const tokensFromJson = (json: any): Tokens => {
	const currentTime = new Date().getTime()
	if (!json.access_token || !json.refresh_token) {
		throw new Error('Could not parse tokens from response')
	}
	return {
		accessToken: json.access_token,
		refreshToken: json.refresh_token,
		accessTokenExpiration: currentTime + json.expires_in * 1000,
		refreshTokenExpiration: currentTime + json.refresh_expires_in * 1000,
	}
}

export const refreshToken = async (
	config: KeycloakConfig,
	refreshToken: string
): Promise<Tokens> =>
	fetch(openIdTokenUrl(config), {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		body: new URLSearchParams({
			refresh_token: refreshToken,
			client_id: config.clientId,
			grant_type: 'refresh_token',
		}),
	})
		.then(checkStatus)
		.then(http.parseBody)
		.then(tokensFromJson)

export const passwordSignIn = async (
	username: string,
	password: string,
	config: KeycloakConfig,
	rememberMe: boolean
): Promise<Tokens> => {
	const response = await fetch(openIdTokenUrl(config), {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		body: new URLSearchParams({
			username,
			password,
			client_id: config.clientId,
			grant_type: 'password',
			rememberMe: rememberMe.toString(),
		}),
	})
	if (response.status === 401) {
		return Promise.reject(new InvalidCredentialsError())
	}
	const json = await response.json()
	return tokensFromJson(json) || Promise.reject('INVALID RESPONSE')
}

const tokensFromKeycloak = (keycloak: Keycloak): Tokens => {
	const { token, refreshToken, tokenParsed, refreshTokenParsed } = keycloak
	if (!token || !refreshToken || !tokenParsed || !refreshTokenParsed) {
		throw Error('Keycloak instance is missing required properties')
	}
	const tokenParsedExp = tokenParsed['exp']
	const refreshTokenParsedExp = refreshTokenParsed['exp']
	if (!tokenParsedExp || !refreshTokenParsedExp) {
		throw Error('Parsed tokens are invalid!')
	}
	return {
		accessToken: token,
		refreshToken,
		accessTokenExpiration: tokenParsedExp * 1000,
		refreshTokenExpiration: refreshTokenParsedExp * 1000,
	}
}

export type InitializedKeycloak = {
	keycloak: Keycloak
	tokens: Tokens | undefined
	google: (redirectUri?: string) => string
}
export const initKeycloak = (
	config: KeycloakConfig
): Promise<InitializedKeycloak> => {
	const keycloak = new Keycloak({
		url: config.authUrl,
		realm: config.authRealm,
		clientId: config.clientId,
	})
	return keycloak.init({}).then((authenticated: boolean) => {
		const tokens = authenticated ? tokensFromKeycloak(keycloak) : undefined

		return {
			keycloak,
			tokens,
			google: (redirectUri?: string) =>
				keycloak.createLoginUrl({ idpHint: 'google', redirectUri }),
		}
	})
}

export default {
	refreshToken,
	initKeycloak,
	passwordSignIn,
}
