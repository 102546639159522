export const Facebook = (): JSX.Element => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="36"
		height="36"
		viewBox="0 0 36 36"
	>
		<g data-name="Group 25653" transform="translate(-50 -568)">
			<circle
				data-name="Ellipse 759"
				cx="18"
				cy="18"
				r="18"
				transform="translate(50 568)"
				fill="#1877f2"
			/>
			<path
				data-name="Path 12110"
				d="m7.948 8.932.441-2.874H5.631V4.193a1.437 1.437 0 0 1 1.621-1.552h1.253V.194A15.287 15.287 0 0 0 6.28 0a3.508 3.508 0 0 0-3.755 3.868v2.19H0v2.874h2.525v6.947a10.027 10.027 0 0 0 3.106 0V8.932z"
				transform="translate(64 578)"
				fill="#fff"
			/>
		</g>
	</svg>
)
