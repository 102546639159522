import styled from '@emotion/styled'
import colors from '../colors'

const Switcher = styled.div`
	width: 150px;
	margin: 10px 10px 4px 10px;
	border-radius: 12px;
	padding: 3px;
	background-color: ${colors.paleGray2};
	display: flex;
	align-items: center;
`

const SwitcherButton = styled.div<{ selected?: boolean }>`
	width: 73px;
	border-radius: 10px;
	background-color: ${props =>
		props.selected ? colors.white : colors.paleGray2};
	font-family: Ubuntu;
	font-weight: normal;
	font-size: 12px;
	color: ${props => (props.selected ? colors.black : colors.heather)};
	cursor: pointer;
	padding: 10px;
	text-align: center;
	${props => props.selected && 'box-shadow: 0 2px 3px 0 rgba(41, 54, 73, 0.1);'}
`

type Props = {
	firstText: string
	secondText: string
	selected: boolean
	onChange: (value: boolean) => void
}

const TextSwitcher = ({
	firstText,
	secondText,
	selected,
	onChange,
}: Props): JSX.Element => {
	return (
		<Switcher>
			<SwitcherButton selected={selected} onClick={() => onChange(true)}>
				{firstText}
			</SwitcherButton>
			<SwitcherButton selected={!selected} onClick={() => onChange(false)}>
				{secondText}
			</SwitcherButton>
		</Switcher>
	)
}

export default TextSwitcher
