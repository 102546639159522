/* eslint-disable @typescript-eslint/no-explicit-any */
import { v4 as uuidgen } from 'uuid'

export type Paged<T> = {
	values: T[]
	page: {
		number: number
		size: number
		totalElements: number
		totalPages: number
	}
}

export type CursorPaged<T, ID = string> = {
	data: T[]
	hasMore: boolean
	cursor?: ID
}
export type OffsetPaged<T> = {
	data: T[]
	hasMore: boolean
	offset: number
}
export type SpringPaged<T> = {
	content: T[]
	empty: boolean
	first: boolean
	last: boolean
	number: number
	numberOfElements: number
	pageable: {
		offset: number
		pageNumber: number
		pageSize: number
		paged: boolean
		sort: {
			empty: boolean
			sorted: boolean
			unsorted: boolean
		}
		unpaged: boolean
	}
	size: number
	sort: {
		empty: boolean
		sorted: boolean
		unsorted: boolean
	}
	totalElements: number
	totalPages: number
}

export const checkStatus = (response: Response): Response | Promise<any> => {
	if (response.status >= 200 && response.status < 300) {
		return response
	}

	return response
		.json()
		.catch(() => Promise.reject({ status: response.status }))
		.then(json => Promise.reject({ ...json, status: response.status }))
}

export const checkStatusAllowNotFound = (
	response: Response
): Response | Promise<any> => {
	if (
		response.status === 404 ||
		(response.status >= 200 && response.status < 300)
	) {
		return response
	}

	return response
		.json()
		.catch(() => Promise.reject({ status: response.status }))
		.then(json => Promise.reject({ ...json, status: response.status }))
}

export const extractDownloadFileName = (
	response: Response
): string | undefined => {
	const header = response.headers.get('Content-Disposition')
	const match = header && /filename\s*=\s*([^\;]+)/gi.exec(header)
	if (match?.[1]) {
		return match[1]?.trim()
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseBody = (response: Response): Promise<any> => {
	if (response.status === 404) {
		return Promise.resolve(null)
	}
	const contentType = response.headers.get('Content-Type') || ''
	if (/text\/plain/.test(contentType)) {
		return response.text()
	} else if (
		/application\/json/.test(contentType) ||
		/application\/hal\+json/.test(contentType)
	) {
		return response.json()
	} else {
		return Promise.resolve()
	}
}

type HAL = {
	_embedded: Record<string, unknown>
	page: Paged<unknown>['page']
}

const isHAL = (obj: unknown): obj is HAL => {
	if (typeof obj === 'object' && obj !== null && '_embedded' in obj) {
		return true
	}

	return false
}

export const extractHALEmbedded = (json: unknown): any => {
	if (isHAL(json)) {
		// eslint-disable-next-line no-underscore-dangle
		const embeddedResources = json._embedded
		return {
			...embeddedResources,
			page: json.page,
		}
	}

	return json
}

export const fetch: typeof window.fetch = (info, input) => {
	return window.fetch(info, {
		headers: {
			'Content-Type': 'application/json',
			'PB-Correlation-Id': uuidgen().replaceAll('-', '').slice(0, 16),
			credentials: 'include',
			...input?.headers,
		},
		...input,
	})
}

export const getQuery = (name: string): string | null => {
	const search = window.location.search
	const params = new URLSearchParams(search)
	return params.get(name)
}

export const withQuery = (
	url: string,
	query: Record<string, string | number | boolean | undefined>
): string => {
	const params = new URLSearchParams()
	Object.entries(query).forEach(([key, value]) => {
		if (value) {
			params.append(key, value.toString())
		}
	})
	return `${url}?${params.toString()}`
}
