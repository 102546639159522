import styled from '@emotion/styled'
import { FC, ReactChild } from 'react'
import { NavLink } from 'react-router-dom'
import colors from '../colors'

import { RightArrowIcon } from '../icons'

const ButtonLink = styled(NavLink)`
	align-items: center;
	border-radius: 8px;
	border: 1px solid ${colors.brightGray};
	background-color: ${colors.white};
	color: ${colors.black};
	column-gap: 16px;
	display: flex;
	height: 58px;
	margin-bottom: 8px;
	width: 100%;
`

const Icon = styled.img`
	display: flex;
	height: 20px;
	margin-left: 18px;
	position: relative;
	width: 20px;
`

const Text = styled.div`
	display: flex;
	display: flex;
	flex-grow: 1;
	flex-wrap: nowrap;
	font-size: 16px;

	width: 100%;
`
const IconContianer = styled.div`
	color: ${colors.marlinBlue};
	margin: 0px 18px 0px 0px;

	svg {
		width: 7px;
		height: 11px;
	}
`

type LinkProps = {
	pathname: string
	state?: { from: string }
}

type Props = {
	msg: string | ReactChild
	icon?: string
	style?: React.CSSProperties
	link: LinkProps
}

export const ButtonLinkMobile: FC<Props> = ({ msg, icon, style, link }) => {
	return (
		<ButtonLink style={style} to={link}>
			{icon && <Icon src={icon} />}
			<Text>{msg}</Text>
			<IconContianer>
				<RightArrowIcon />
			</IconContianer>
		</ButtonLink>
	)
}
