import { Config } from 'common/config'
import { useMemo } from 'react'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { WhiteboardId } from './whiteboard-models'
import { getWhiteboardsApi } from './whiteboards-api'

type WhiteboardHook = {
	createWhiteboard: (
		whiteboardName: string,
		withFocus: boolean
	) => Promise<unknown>
	openWhiteboard: (
		whiteboardId: WhiteboardId,
		withFocus: boolean
	) => Promise<unknown>
	closeWhiteboard: (whiteboardId: WhiteboardId) => Promise<unknown>
	changeCollaboration: (whiteboardId: WhiteboardId, enabled: boolean) => void
}
export const useWhiteboards = (): WhiteboardHook => {
	const whiteboardsApi = getWhiteboardsApi(Config.beeldayBackendUrl)
	const roomAddress = useAssertedJoinedRoomAddress()

	return useMemo(
		() => ({
			createWhiteboard: async (
				whiteboardName: string,
				withFocus: boolean
			): Promise<void> => {
				return whiteboardsApi.createWhiteboard(
					roomAddress,
					whiteboardName,
					withFocus
				)
			},
			openWhiteboard: async (whiteboardId, withFocus): Promise<void> => {
				return whiteboardsApi.openWhiteboard(
					roomAddress,
					whiteboardId,
					withFocus
				)
			},
			closeWhiteboard: (whiteboardId: WhiteboardId): Promise<void> => {
				return whiteboardsApi.closeWhiteboard(roomAddress, whiteboardId)
			},
			changeCollaboration: (whiteboardId: WhiteboardId, enabled: boolean) => {
				whiteboardsApi.changeCollaboration(roomAddress, whiteboardId, enabled)
			},
		}),
		[whiteboardsApi, roomAddress]
	)
}
