import { FC, useState } from 'react'
import styled from '@emotion/styled'
import { Colors } from './colors'
import bg from './images/info-banner.svg'
import down from 'common/presentation/droppable-object/images/down.svg'
import { translate } from 'intl/translate'
import { usePreloaded } from 'connectivity/preload'
import CloseButton from './close-button'

type Props = {
	message?: string
	title?: string
	fixed?: boolean
}

const TextContainer = styled.div`
	background: url(${bg});
	background-size: 100% 100%;
	position: fixed;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	margin: 0 auto;
	padding: 10px 50px 40px 50px;
	width: 30vw;
	z-index: 10000;
`

const Text = styled.div`
	color: ${Colors.white};
	font-family: Ubuntu;
	font-size: 18px;
	font-weight: 500;
	text-align: center;
`

const CloseButtonContainer = styled.div`
	position: absolute;
	right: 10px;
	top: 5px;
	width: 25px;
	height: 25px;
	cursor: pointer;
`

const TriggerContainer = styled.div`
	display: flex;
	justify-items: center;
	position: fixed;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	margin: 0 auto;
	z-index: 10000;
`

const DropTrigger = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
`

const Title = styled.div`
	color: ${Colors.indigoBlue};
	font-family: Ubuntu;
	font-size: 14px;
	font-weight: 500;
	text-align: center;
`

const InfoBanner: FC<Props> = ({ children, title, message, fixed }) => {
	const [visible, setVisible] = useState(true)
	const preloaded = usePreloaded([bg, down])

	if (!preloaded) {
		return null
	}

	return visible ? (
		<TextContainer>
			{!fixed && (
				<CloseButtonContainer>
					<CloseButton onClick={() => setVisible(false)} />
				</CloseButtonContainer>
			)}
			<Text>{message ? translate(message) : children}</Text>
		</TextContainer>
	) : (
		<TriggerContainer>
			<DropTrigger onClick={() => setVisible(true)}>
				<img src={down} alt="down" />
				{title && <Title>{translate(title)}</Title>}
			</DropTrigger>
		</TriggerContainer>
	)
}

export default InfoBanner
