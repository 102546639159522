import { colors, intl } from '@beelday/common'
import { useEffect, useState } from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
	margin: 0px 10px 0px 0px;
`

const TimeLeft = styled.p`
	font-size: 12px;
	font-weight: 500;
	color: ${colors.heather};
	line-height: 2.4;
	margin: 0px;
`

const Countdown = styled.p`
	font-size: 18px;
	font-weight: bold;
	margin: 0px;
`

const secondsToTime = (secs: number) => {
	const hours = Math.floor(secs / 3600)

	const divisor_for_minutes = secs % (60 * 60)
	const minutes = Math.floor(divisor_for_minutes / 60)

	const divisor_for_seconds = divisor_for_minutes % 60
	const seconds = Math.floor(divisor_for_seconds)

	return {
		h: hours < 10 ? '0' + hours : hours,
		m: minutes < 10 ? '0' + minutes : minutes,
		s: seconds < 10 ? '0' + seconds : seconds,
	}
}

type Props = { startTime: Date; maxDurationInSeconds: number }

const KnowledgeCheckTimer = ({
	startTime,
	maxDurationInSeconds,
}: Props): JSX.Element => {
	const endOfKnowledgeCheck =
		new Date(startTime).getTime() + maxDurationInSeconds * 1000

	const [timer, setTimer] = useState<number>(
		(endOfKnowledgeCheck - new Date().getTime()) / 1000
	)

	useEffect(() => {
		const interval = setInterval(() => {
			setTimer(v => (v > 0 ? v - 1 : 0))
		}, 1000)
		return () => clearInterval(interval)
	}, [])

	const time = secondsToTime(timer)

	return (
		<Container>
			<TimeLeft>
				<intl.Translate>knowledge_check.time_left</intl.Translate>
			</TimeLeft>
			<Countdown>
				{timer <= 0 ? '00:00:00' : time.h + ':' + time.m + ':' + time.s}
			</Countdown>
		</Container>
	)
}
export default KnowledgeCheckTimer
