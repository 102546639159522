import { colors, icons, intl } from '@beelday/common'
import styled from '@emotion/styled'
import { useEffect, useState } from 'react'

const Container = styled.div`
	align-items: center;
	background-color: ${colors.indigoBlue10};
	border-radius: 11px;
	display: flex;
	padding: 20px 15px;
	width: 100%;
`

const Icon = styled.div`
	min-height: 40px;
	min-width: 40px;
	width: 40px;
	height: 40px;
	margin-right: 15px;

	svg {
		width: 100%;
		height: 100%;
	}
`

const Text = styled.div`
	width: 100%;
	font-size: 14px;
	text-align: left;
`

const A = styled.a`
	color: ${colors.indigoBlue};
	text-decoration: underline;
`
type BrowserType = 'Safari' | 'Chrome' | 'Firefox' | 'Edge' | 'Unknown'

export const useDetectBrowser = (): BrowserType => {
	const [browser, setBrowser] = useState<BrowserType>('Unknown')

	useEffect(() => {
		const userAgent = navigator.userAgent

		const isChrome = userAgent.includes('Chrome') && !userAgent.includes('Edg/')
		const isFirefox = userAgent.includes('Firefox')
		const isSafari =
			userAgent.includes('Safari') &&
			!userAgent.includes('Chrome') &&
			!userAgent.includes('Edg/')
		const isEdge = userAgent.includes('Edg/')

		if (isEdge) {
			setBrowser('Edge')
		} else if (isSafari) {
			setBrowser('Safari')
		} else if (isChrome) {
			setBrowser('Chrome')
		} else if (isFirefox) {
			setBrowser('Firefox')
		} else {
			setBrowser('Unknown')
		}
	}, [])

	return browser
}

export const SafariNotSupportedInfo = (): JSX.Element => (
	<Container>
		<Icon>
			<icons.SafariLogoIcon />
		</Icon>
		<Text>
			<strong>
				<intl.Translate>hardware_check.safari_user</intl.Translate>
			</strong>
			&nbsp;
			<intl.Translate>hardware_check.safari_user.description</intl.Translate>
			&nbsp;
			<A href="https://www.google.com/chrome/" target="_blank">
				<intl.Translate>
					hardware_check.safari_user.download_chrome
				</intl.Translate>
			</A>
		</Text>
	</Container>
)
