import { enUS, pl } from 'date-fns/locale'
import baseMessages, { Translations } from './messages'

declare global {
	interface Navigator {
		userLanguage?: string
		browserLanguage?: string
	}
}

const FALLBACK = 'en'
const FNS_FALLBACK = enUS
const FNS_LOCALES: Record<string, Locale> = { pl, enUS }

const getNavigatorLanguage = () => {
	if (navigator.languages && navigator.languages.length) {
		return navigator.languages[0]
	}

	return (
		navigator.userLanguage ||
		navigator.language ||
		navigator.browserLanguage ||
		FALLBACK
	)
}

export const getLocale = (): string => {
	let locale = localStorage.getItem('locale')
	if (!locale) {
		locale = getNavigatorLanguage()
	}
	return locale
}

export const getSupportedLocale = (): string => {
	let locale = localStorage.getItem('locale')
	if (!locale) {
		locale = getNavigatorLanguage()
	}

	if (Object.keys(baseMessages).includes(locale)) {
		return locale
	}

	return FALLBACK
}

export const getFnsLocale = (): Locale => {
	const currentLocale = getLocale()
	const locale = FNS_LOCALES[currentLocale]

	if (!locale) {
		return FNS_FALLBACK
	}

	return locale
}

export const setLocale = (locale: string | null): void => {
	if (locale) {
		localStorage.setItem('locale', locale)
	} else {
		localStorage.removeItem('locale')
	}
}

export const getMessages = (
	translations: Translations,
	locale: string
): Record<string, string> => {
	const languageWithoutRegionCode = locale.toLowerCase().split(/[_-]+/)[0]
	return (
		translations[locale] ||
		translations[languageWithoutRegionCode] ||
		translations[FALLBACK]
	)
}
