import * as Sentry from '@sentry/browser'

const stringify = (obj: unknown): string => {
	if (obj instanceof Error) {
		return obj.message
	} else if (typeof obj === 'string') {
		return obj
	} else if (obj instanceof MessageEvent) {
		return 'EVENT: ' + JSON.stringify(obj.data)
	} else {
		return JSON.stringify(obj)
	}
}

const stringifyAndJoin = (logs: any[]) => {
	return logs.map(stringify).join(' --- ')
}

export default class Logger {
	private readonly name: string

	constructor(name: string) {
		this.name = name
	}

	log(...args: any) {
		console.log(this.format(...args))
	}

	warn(...args: any) {
		console.warn(this.format(...args))
	}

	debug(...args: any) {
		console.debug(this.format(...args))
	}

	trace(...args: any) {
		console.trace(this.format(...args))
	}

	error(...args: any) {
		const error = args.find((a: unknown) => a instanceof Error)
		if (error) {
			Sentry.captureException(error)
		} else {
			Sentry.captureMessage(this.format(...args))
		}
		console.error(this.format(...args), error)
	}

	private format(...args: any) {
		const date = new Date()
		const time = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
		return `${time} ${this.name}: ${stringifyAndJoin(args)}`
	}
}
