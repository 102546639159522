export const Chevron: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="7.302"
		height="14"
		viewBox="0 0 7.302 14"
	>
		<path
			d="M1266.332,32.3l-6.64-5.533a1,1,0,0,1,1.28-1.536l5.36,4.467,5.36-4.467a1,1,0,1,1,1.28,1.536Z"
			transform="translate(-25 1273.332) rotate(-90)"
			fill="currentColor"
		/>
	</svg>
)
