import { intl, ui } from '@beelday/common'
import styled from '@emotion/styled'
import { Excalidraw } from '@pragmatic-brains/excalidraw'
import { Config } from 'common/config'
import { RootState } from 'common/redux'
import { compact } from 'lodash'
import { useSelector } from 'react-redux'
import { roomEndUsers } from 'room/common/redux'
import { useAssertedWorkflowUser } from 'room/common/use-workflow-user'
import { VideoColumns } from 'room/group-room/presentation/video-columns'
import { useMyProfile } from 'users'
import { useProfileFor } from 'users/redux'
import { sceneVideoUsersById } from 'video-conference-media/redux'
import { useWhiteboards } from './use-whiteboards'
import { SceneVideoUser } from 'video-conference-media'

const TwoColumnGrid = styled.div`
	display: flex;
	flex: 1;
	height: 100%;
	min-height: 0;
	width: 100%;
`

const ColumnLayout = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;
	margin-top: 20px;

	${ui.responsive.tablet} {
		flex: 0 0 300px;
		margin-left: 30px;
		margin-top: 0;
	}

	${ui.responsive.desktop} {
		flex: 0 0 400px;
	}
`

const WhiteboardContainer = styled.div`
	width: 100%;
	height: 100%;
	padding: 0 20px 20px 20px;
	position: relative;
`

export const ConfiguredWhiteboard = ({
	isTrainer,
	streams,
}: {
	isTrainer: boolean
	streams: SceneVideoUser[]
}): JSX.Element => {
	const translate = intl.useTranslate()
	const { changeCollaboration } = useWhiteboards()
	const workflowUser = useAssertedWorkflowUser()
	const workflowUserProfile = useProfileFor(workflowUser.id)

	const whiteboardDefinition = useSelector(
		(state: RootState) => state.whiteboards.whiteboardDefinition
	)

	const user = useMyProfile()

	let isViewMode = false

	if (whiteboardDefinition?.collaborators && workflowUserProfile?.id) {
		isViewMode = !whiteboardDefinition.collaborators.includes(
			workflowUserProfile.id
		)
	}

	return (
		<TwoColumnGrid>
			<WhiteboardContainer>
				{whiteboardDefinition && workflowUserProfile ? (
					<Excalidraw
						key={whiteboardDefinition.whiteboardId}
						viewModeEnabled={isViewMode}
						collabServerUrl={Config.EXCALIDRAW_SERVER_URL}
						assetsServerUrl={Config.EXCALIDRAW_ASSETS_SERVER_URL}
						collabRoom={{ id: whiteboardDefinition.whiteboardId }}
						isCollaborating={true}
						allowForceFollow={user.isTrainer}
						user={user}
					/>
				) : null}
			</WhiteboardContainer>
			<ColumnLayout>
				{whiteboardDefinition && isTrainer ? (
					<ui.FlexRow style={{ marginBottom: '30px' }}>
						<ui.CheckboxSwitcher
							id="collaborationSwitcher"
							name="collaborationSwitcher"
							checked={whiteboardDefinition.collaborators.length > 1}
							onChange={val =>
								changeCollaboration(whiteboardDefinition.whiteboardId, val)
							}
							text={translate('features.whiteboard.switcher')}
						/>
					</ui.FlexRow>
				) : null}

				<VideoColumns sceneVideoUsers={streams} />
			</ColumnLayout>
		</TwoColumnGrid>
	)
}

export default ConfiguredWhiteboard
