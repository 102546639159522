import { intl, ui } from '@beelday/common'
import styled from '@emotion/styled'
import { AssignMode, GroupsOfSize } from 'common/types'
import { translate } from 'intl/translate'
import { FunctionComponent } from 'react'
import { HeadingP } from './heading'
import GroupProgress from './images/group-progress-3-small.svg'
import { ToolTipP } from './tool-tip'

const GroupSelectionProgressBackground = styled.div`
	background: url(${GroupProgress});
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 20px;
	margin-top: 22px;
	margin-bottom: 10px;
	width: 100%;
`

const Text: FunctionComponent<{
	selectedGroups: GroupsOfSize[]
}> = props => {
	return (
		<ToolTipP>
			{translate('group-people-3-text-part1')}
			{props.selectedGroups.map((selectedGroup, index) => (
				<span key={index}>
					{index >= 1 ? (
						<>
							{' '}
							<intl.Translate>and</intl.Translate>{' '}
						</>
					) : (
						''
					)}
					<strong>
						<intl.Translate {...selectedGroup}>users-per-group</intl.Translate>
					</strong>
				</span>
			))}
			<intl.Translate>group-people-3-text-part3</intl.Translate>
		</ToolTipP>
	)
}

type Props = {
	onAssignmentModeSelected(assignMode: AssignMode): void
	selectedGroups: GroupsOfSize[]
}

export const GroupGroupingMode: React.FC<Props> = ({
	onAssignmentModeSelected,
	selectedGroups,
}) => {
	return (
		<ui.FlexColumnCenter>
			<HeadingP>
				<intl.Translate>group-people</intl.Translate>
			</HeadingP>
			<GroupSelectionProgressBackground />
			<Text selectedGroups={selectedGroups} />
			<ui.FlexRow style={{ width: '100%', flex: 1, marginTop: '20px' }}>
				<ui.ButtonSecondary
					color="indigoBlue"
					borderColor="indigoBlue"
					onClick={() => onAssignmentModeSelected(AssignMode.Manual)}
					style={{
						marginRight: '4px',
						flexBasis: '50%',
						flex: 1,
						whiteSpace: 'break-spaces',
					}}
				>
					<intl.Translate>users-choose-groups-themselves</intl.Translate>
				</ui.ButtonSecondary>
				<ui.ButtonPrimary
					onClick={() => onAssignmentModeSelected(AssignMode.Automatic)}
					style={{
						marginLeft: '6px',
						flexBasis: '50%',
						flex: 1,
						whiteSpace: 'break-spaces',
					}}
				>
					<intl.Translate>system-assigns-users-to-groups</intl.Translate>
				</ui.ButtonPrimary>
			</ui.FlexRow>
		</ui.FlexColumnCenter>
	)
}

export default GroupGroupingMode
