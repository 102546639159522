import { FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { ellipsis } from '@beelday/common/src/styles'
import { Color } from 'common/types'
import { Colors } from 'common/presentation/colors'
import { colorToHex } from 'room/common/presentation/colorsInHex'
import { SceneVideoUser } from '../model/types'

export const BaseUsername = styled.div<{
	color?: string
	backgroundColor?: string
}>`
	border-radius: 4px;
	color: ${props => props.color ?? Colors.white};
	background-color: ${props => props.backgroundColor ?? Colors.darkViolet};
	font-size: 16px;
	font-weight: 500;
	padding: 1px 5px;
	${ellipsis}
`

type Props = {
	sceneVideoUser: SceneVideoUser
	hideName?: boolean
	fillColor?: Color
	fontColor?: string
}

export const Username: FunctionComponent<Props> = props => {
	if (props.hideName) {
		return null
	}

	return (
		<BaseUsername
			color={props.fontColor}
			backgroundColor={props.fillColor && colorToHex[props.fillColor]}
		>
			{props.sceneVideoUser.userName}
		</BaseUsername>
	)
}
