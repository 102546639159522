import React from 'react'

export const IconThinChecked: React.FC = () => (
	<svg
		version="1.1"
		id="Layer_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 18.9 14.1"
		xmlSpace="preserve"
	>
		<g>
			<polygon
				fill="currentColor"
				points="6.2,14.1 0,8 1.4,6.6 6.2,11.3 17.5,0 18.9,1.4 	"
			/>
		</g>
	</svg>
)
