import { FC } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

export const translate = (id: string, value = {}) => {
	return <FormattedMessage id={id} values={{ ...value }} />
}

type ChunksFormatter = (chunks: string) => JSX.Element
type Props = Record<string, string | number | Date | ChunksFormatter> & {
	children: string
}

export const useTranslatedMessage = (id?: string) => {
	const intl = useIntl()

	return id ? intl.formatMessage({ id }) : ''
}

const Translate: FC<Props> = ({ children, ...rest }) => (
	<FormattedMessage id={children} values={rest} />
)

export const Emphasize: ChunksFormatter = chunks => <em>{chunks}</em>

export default Translate
