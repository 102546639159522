import { userApi } from '@beelday/common'
import { GrowthColumnContainer } from 'common/presentation/containers'
import { RootState } from 'common/redux'
import { GroupReview, isReviewUser } from 'common/types'
import { useAuthenticatedBeeldayClient } from 'connectivity/beelday-hooks'
import { differenceBy } from 'lodash'
import { FC, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { roomEndUsers } from 'room/common/redux'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { useAssertedWorkflowUser } from 'room/common/use-workflow-user'
import {
	findActiveGroup,
	mapToGroupMembers,
} from 'room/training-room/model/review-task'
import { reviewMediaActive } from 'room/training-room/redux'
import { useProfileFor } from 'users/redux'
import { sceneVideoUsersById } from 'video-conference-media/redux'
import ReviewMenuTrainee from './review-menu-trainee'
import ReviewTask from './review-task'

const ConfiguredReviewTask: FC = () => {
	const beeldayClient = useAuthenticatedBeeldayClient()
	const roomAddress = useAssertedJoinedRoomAddress()
	const videoUsers = useSelector(sceneVideoUsersById)
	const endUsers = useSelector(roomEndUsers)
	const activeGroupID = useSelector(
		(state: RootState) => state.trainingRoom.reviewActiveGroup
	)
	const activeMedia = useSelector(reviewMediaActive)
	const workflowUser = useAssertedWorkflowUser()
	const workflowUserProfile = useProfileFor(workflowUser.id)

	const whiteboardDefinition = useSelector(
		(state: RootState) => state.whiteboards.whiteboardDefinition
	)

	const notepadDefinition = useSelector(
		(state: RootState) => state.notepads.notepadDefinition
	)

	const notepadSessionId = useSelector(
		(state: RootState) => state.notepads.sessionId
	)

	const [groups, setGroups] = useState<GroupReview[]>([])

	useEffect(() => {
		beeldayClient.getGroupsForReview(roomAddress).then(setGroups)
	}, [beeldayClient, roomAddress])

	const all = useMemo(() => {
		const groupMembers = mapToGroupMembers(groups)
		const otherEndUsers = differenceBy(endUsers, groupMembers, 'id')
		return [...groupMembers, ...otherEndUsers].filter(isReviewUser)
	}, [groups, endUsers])

	const activeGroup = useMemo(
		() => findActiveGroup(groups, activeGroupID),
		[activeGroupID, groups]
	)

	const userName = userApi.getDisplayName(workflowUserProfile)

	return activeGroup ? (
		<>
			<GrowthColumnContainer>
				<ReviewTask
					activeGroup={activeGroup}
					activeMedia={activeMedia}
					roomAddress={roomAddress}
					users={all}
					groups={groups}
					videoUsers={videoUsers}
					userProfile={workflowUserProfile}
					title={userName ? `${userName}, ` : ''}
					whiteboardDefinition={whiteboardDefinition}
					notepadDefinition={notepadDefinition}
					notepadSessionId={notepadSessionId}
					isTrainer={false}
				/>
			</GrowthColumnContainer>
			<ReviewMenuTrainee group={activeGroup} activeMedia={activeMedia} />
		</>
	) : null
}

export default ConfiguredReviewTask
