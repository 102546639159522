import { FC, useCallback, useEffect, useRef } from 'react'
import { Config } from 'common/config'
import Logger from 'common/logger'

const logger = new Logger('HEART_BEAT')

type Props = {
	onBeat: (secondsSinceLastActivity: number) => void
}

function secondsSinceLastActivity(lastUserActivityTime: number): number {
	return (new Date().getTime() - lastUserActivityTime) / 1000
}

export const PresenceHeartBeat: FC<Props> = ({ onBeat }) => {
	useEffect(() => {
		const interval = setInterval(() => onBeat(0), Config.heartbeatFrequency)

		return () => {
			clearInterval(interval)
		}
	}, [onBeat])

	return null
}

const ACTIVITY_EVENTS = ['mousemove', 'mousedown', 'keydown', 'scroll']

export const ActivityHeartBeat: FC<Props> = ({ onBeat }) => {
	const lastUserActivityTime = useRef<Date>(new Date())

	const handleUserActivity = useCallback(() => {
		lastUserActivityTime.current = new Date()
	}, [])

	useEffect(() => {
		ACTIVITY_EVENTS.forEach(event =>
			document.addEventListener(event, handleUserActivity)
		)

		return () => {
			ACTIVITY_EVENTS.forEach(event =>
				document.removeEventListener(event, handleUserActivity)
			)
		}
	}, [handleUserActivity])

	useEffect(() => {
		logger.log(
			`Registering a heartbeat interval of ${Config.heartbeatFrequency}`
		)
		const interval = setInterval(() => {
			const sinceLastActivity = secondsSinceLastActivity(
				lastUserActivityTime.current.getTime()
			)
			logger.log(`A beat with sinceLastActivity=${sinceLastActivity}`)
			onBeat(sinceLastActivity)
		}, Config.heartbeatFrequency)

		return () => {
			logger.log('Clearing the heartbeat interval')
			clearInterval(interval)
		}
	}, [onBeat])

	return null
}
