import { intl, ui } from '@beelday/common'
import styled from '@emotion/styled'
import { PartitionMode } from 'common/types'
import { HeadingP } from './heading'
import GroupProgressLeft from './images/group-progress-1-half-wide.svg'
import GroupProgressRight from './images/group-progress-1-of-1.svg'
import { ToolTipP } from './tool-tip'

const GroupSelectionImage = styled.img`
	height: 20px;
	width: 100%;
	margin-top: 22px;
`

type Props = {
	onPartitionModeSelected(partitionMode: PartitionMode): void
}

export const GroupSizeMode: React.FC<Props> = ({ onPartitionModeSelected }) => {
	const translate = intl.useTranslate()
	return (
		<ui.FlexColumn style={{ alignItems: 'center' }}>
			<HeadingP>{translate('group-sizes')}</HeadingP>
			<ui.FlexRow style={{ width: '100%', flex: 1 }}>
				<ui.FlexColumnCenter style={{ flexBasis: '50%', flex: 1 }}>
					<GroupSelectionImage src={GroupProgressLeft} />
					<ToolTipP style={{ flex: 1 }}>
						{translate('choose-for-yourself-text')}
					</ToolTipP>
					<ui.ButtonSecondary
						color="indigoBlue"
						borderColor="indigoBlue"
						onClick={() => onPartitionModeSelected(PartitionMode.Manual)}
						style={{ marginTop: '12px' }}
					>
						<intl.Translate>choose</intl.Translate>
					</ui.ButtonSecondary>
				</ui.FlexColumnCenter>

				<ui.FlexColumnCenter style={{ flexBasis: '50%', flex: 1 }}>
					<GroupSelectionImage src={GroupProgressRight} />
					<ToolTipP style={{ flex: 1 }}>
						{translate('let-system-decide-text')}
					</ToolTipP>
					<ui.ButtonPrimary
						onClick={() => onPartitionModeSelected(PartitionMode.Automatic)}
						style={{ marginTop: '12px' }}
					>
						<intl.Translate>choose</intl.Translate>
					</ui.ButtonPrimary>
				</ui.FlexColumnCenter>
			</ui.FlexRow>
		</ui.FlexColumn>
	)
}

export default GroupSizeMode
