import { ui } from '@beelday/common'
import styled from '@emotion/styled'
import { useSelector } from 'app-redux'
import { Organization } from 'organization/model'
import { Colors } from 'common/presentation/colors'
import { GroupWorkInProgressNotification } from 'common/presentation/notifications'
import { ISPhase } from 'event-source-manager/redux'
import Translate from 'intl/translate'
import { FC, useRef } from 'react'
import { useDispatch } from 'react-redux'
import Exit from 'room/common/presentation/exit-icon'
import { Training } from 'session-info/training'
import { editProfile } from './redux'
import { CompanyLogo, colors } from '@beelday/common/src/ui'
import { Config } from 'common/config'
import { Flex } from 'common/presentation/containers'
import hello from './images/lobby-hello-cloud.png'

const HelloContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 70px 22px 70px;
	position: relative;
	z-index: 0;
	margin-right: auto;
	margin-left: auto;
	width: calc(100% - 140px);
	max-width: 1600px;

	${ui.responsive.desktop1440} {
		padding: 23px 70px 32px 70px;
	}
`

const HelloImg = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: -1;
`

const Hello = styled.div`
	font-family: Ubuntu;
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 4px;

	${ui.responsive.desktop1440} {
		font-size: 24px;
		margin-bottom: 8px;
	}
`

const Next = styled.div`
	color: ${Colors.heather};
	font-family: Ubuntu;
	font-size: 15px;

	${ui.responsive.desktop1440} {
		font-size: 16px;
	}
`

const ProfileContainer = styled.div`
	position: relative;
	width: 92px;
	min-width: 92px;

	${ui.responsive.desktop1440} {
		width: 125px;
		min-width: 125px;
	}
`

const ProfileImg = styled.div`
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: -66px;

	${ui.responsive.desktop1440} {
		top: -86px;
	}
`

const Header = styled.div<{ bgColor: string }>`
	background-color: ${props => props.bgColor};
	display: flex;
	justify-content: flex-end;
	height: 60px;
	position: relative;

	${ui.responsive.desktop1440} {
		height: 90px;
	}
`

const Title = styled.div`
	color: ${Colors.white};
	font-family: Ubuntu;
	font-size: 16px;
	font-weight: 500;

	display: flex;
	justify-content: center;
	align-items: center;

	left: 181px;
	top: -38px;
	padding-right: 230px;

	${ui.responsive.desktop1440} {
		left: 215px;
		top: -56px;
		font-size: 20px;
	}
`

const LinksContainer = styled.div`
	align-items: center;
	display: flex;
	height: fit-content;
	padding-top: 10px;
	padding-right: 32px;
	z-index: 1;

	${ui.responsive.desktop1440} {
		padding-top: 24px;
	}
`

const ActiveLink = styled.div`
	color: ${Colors.white};
	cursor: pointer;
	font-family: Ubuntu;
	font-size: 16px;
	font-weight: 500;
	margin-right: 30px;
	text-decoration: underline;
`

const InactiveLink = styled.div`
	color: ${Colors.white};
	cursor: pointer;
	font-family: Ubuntu;
	font-size: 16px;
	margin-right: 30px;
	white-space: nowrap;
`

const AbsoluteCenterWrapper = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`

type Props = {
	training: Training | undefined
	organization: Organization
}
const LobbyHeader: FC<Props> = ({ training, organization }) => {
	const dispatch = useDispatch()

	const phase = useSelector(ISPhase)

	const logoContainerRef = useRef<HTMLDivElement>(null)

	const isDesktop1440 = ui.responsive.useIsDesktop1440()

	return (
		<>
			<Header bgColor={organization?.branding.backgroundColor || ''}>
				{phase === 'TASK_WORK' && (
					<AbsoluteCenterWrapper>
						<GroupWorkInProgressNotification />
					</AbsoluteCenterWrapper>
				)}

				<LinksContainer>
					<ActiveLink style={{ color: organization.branding.fontColor }}>
						<Translate>lobby</Translate>
					</ActiveLink>
					<InactiveLink
						style={{ color: organization.branding.fontColor }}
						onClick={() => dispatch(editProfile())}
					>
						<Translate>lobby.links.my_profile</Translate>
					</InactiveLink>

					<Exit
						color={colors.indigoBlue}
						trainerId={training?.upperEchelon.userId}
					/>
				</LinksContainer>
			</Header>

			<HelloContainer>
				<HelloImg src={hello} />
				<Flex style={{ columnGap: '20px' }}>
					<ProfileContainer ref={logoContainerRef}>
						<ProfileImg>
							<CompanyLogo
								size={isDesktop1440 ? '120px' : '92px'}
								userApiUrl={Config.USER_API_URL}
								logoId={organization?.branding.logoId}
								style={{ boxShadow: `0px 0px 8px 1px ${colors.black10}` }}
								border={`4px solid ${Colors.white}`}
							/>
						</ProfileImg>
					</ProfileContainer>
					<Title
						style={{
							position: 'absolute',
							color: organization.branding.fontColor,
						}}
					>
						{organization.name}&nbsp;
					</Title>
					<div>
						<Hello>{training?.subject}</Hello>
						<Next>
							<Translate>lobby.user.lobby.join_room</Translate>
						</Next>
					</div>
				</Flex>
			</HelloContainer>
		</>
	)
}

export default LobbyHeader
