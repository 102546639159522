import { FC, useEffect, useRef, useState } from 'react'
import { throttle } from 'lodash'
import styled from '@emotion/styled'
import { GroupId, ReviewUser, GroupReview, UserId } from 'common/types'
import { css } from '@emotion/react'
import GroupSwitcher from './group-switcher'
import { calculateActive } from './review-common'
import MotionStreamVideo from './motion-stream-video'
import { MotionStream } from './motion-stream-video'
import { SceneVideoUser } from 'video-conference-media'
import { ui } from '@beelday/common'

type Props = {
	activeGroup: GroupReview
	users: ReviewUser[]
	videoUsers: Record<UserId, SceneVideoUser>
	groups: Pick<GroupReview, 'members' | 'id' | 'properties'>[]
	onSelect?: (groupId: GroupId | null) => unknown
}

export const StreamsContainer = styled.div`
	position: relative;
	width: calc(100% - 40px);
	margin-left: 20px;

	${ui.responsive.desktop1440} {
		width: calc(100% - 70px);
		margin-left: 35px;
	}
`

export const GroupSwitcherWrapper = styled.div`
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 0;
	padding-bottom: 11px;
`

export const ReviewMotionGrid: FC<Props> = ({
	activeGroup,
	users,
	videoUsers,
	groups,
	onSelect,
}) => {
	const streamsRef = useRef<HTMLDivElement>(null)
	const [streams, setStreams] = useState<MotionStream[]>([])
	const [hoverGroup, setHoverGroup] = useState<string | null>(null)

	useEffect(() => {
		const onResize = throttle(() => {
			const layoutItems = calculateLayoutItems(
				streamsRef.current
					? streamsRef.current.getBoundingClientRect()
					: {
							width: 0,
							height: 0,
					  },
				groups,
				users
			)
			setStreams(layoutItems.streams)
		}, 500)
		window.addEventListener('resize', onResize)
		onResize()

		return () => window.removeEventListener('resize', onResize)
	}, [groups, users])

	return (
		<StreamsContainer ref={streamsRef}>
			{streams.map(stream => {
				return (
					<MotionStreamVideo
						key={stream.groupMember.id}
						defaultValues={stream.defaultValues}
						activeValues={stream.activeValues}
						user={stream.groupMember}
						isActive={activeGroup.id === stream.groupMember.group?.id}
						videoUsers={videoUsers}
						showOverlay={stream.groupMember.group?.id === hoverGroup}
						onHover={setHoverGroup}
						onSelect={onSelect}
					/>
				)
			})}
			<GroupSwitcherWrapper
				css={css`
					top: 0px;
				`}
			>
				<GroupSwitcher
					groups={groups}
					active={activeGroup}
					onSelect={onSelect}
					bottomOpen
				/>
			</GroupSwitcherWrapper>
		</StreamsContainer>
	)
}

function calculateLayoutItems(
	containerSize: {
		width: number
		height: number
	},
	groups: Pick<GroupReview, 'members' | 'id' | 'properties'>[],
	users: ReviewUser[]
): {
	streams: MotionStream[]
} {
	const streams: MotionStream[] = []
	let groupIndex = 0
	let leftColumnIndex = 0
	let rightColumnIndex = 0
	const spacing = 10
	let columnSize = 6
	let height = (containerSize.height - spacing * (columnSize - 1)) / columnSize
	let width = (height * 16) / 9
	const bottomStreamsCount = 0
	let isLeft = true

	const unGroupUsers = users.filter(user => !user.group)

	const reviewGroups = [
		...groups,
		{
			id: null,
			members: unGroupUsers,
		},
	]

	let helpGroupId = reviewGroups[0]?.id

	reviewGroups.forEach((item, index) => {
		item.members.forEach((member, memberIndex) => {
			const user = users.find(user => user.id === member.id)
			if (!user) return
			isLeft = index < (Object.keys(reviewGroups).length - 1) / 2
			const groupSize = item.members.length

			if (helpGroupId === user.group?.id) {
				if (memberIndex === 0) {
					groupIndex = 0
				} else {
					groupIndex = groupIndex + 1
				}
			} else {
				helpGroupId = user.group?.id ?? helpGroupId
				groupIndex = 0
			}
			streams.push({
				index,
				defaultValues: {
					width: width,
					height: height,
					mediaLeft: 0,
					mediaTop: 0,
				},
				activeValues: {
					width: 0,
					height: 0,
					mediaLeft: 0,
					mediaTop: 0,
				},
				groupSize: groupSize,
				columnIndex: isLeft ? leftColumnIndex : rightColumnIndex,
				groupIndex: groupIndex,
				left: isLeft,
				groupMember: user,
			})

			if (isLeft) {
				leftColumnIndex = leftColumnIndex + 1
			} else {
				rightColumnIndex = rightColumnIndex + 1
			}
		})
	})

	const leftColumnSize = streams.filter(item => item.left).length
	const rightColumnSize = streams.filter(item => !item.left).length

	columnSize = calculateColumnSize(
		columnSize,
		containerSize,
		bottomStreamsCount,
		width,
		spacing,
		height,
		leftColumnSize,
		rightColumnSize
	)

	height = (containerSize.height - spacing * (columnSize - 1)) / columnSize
	width = (height * 16) / 9

	const containerInnerSize = {
		height: 0,
		width: 0,
	}

	const offsetForSwitcher = 80 + height
	const verticalAspectRatio = 0.6
	const innerContainerSizeScale = 0.7
	const mobileOffsetForSwitcher = 100

	containerInnerSize.height = containerSize.height - offsetForSwitcher
	containerInnerSize.width =
		(containerSize.width - width * 2) * innerContainerSizeScale

	const isVertical =
		containerInnerSize.width / containerInnerSize.height < verticalAspectRatio

	if (isVertical) {
		width = (containerSize.width - spacing * 9) / 10
		height = (width * 4) / 4
		containerInnerSize.height = containerSize.height - mobileOffsetForSwitcher
		containerInnerSize.width = containerSize.width
	}

	const freeSpace =
		containerSize.width -
		(bottomStreamsCount + 2) * width -
		bottomStreamsCount * 10

	streams.forEach((member, index) => {
		const { rows, rowSize } = calculateRows(isVertical, member.groupSize)

		const defaultValues = calculateDefault(
			isVertical,
			width,
			height,
			index,
			containerSize,
			member.columnIndex ? member.columnIndex : 0,
			member.left ? leftColumnSize : rightColumnSize,
			columnSize,
			freeSpace,
			bottomStreamsCount,
			member.left,
			spacing
		)

		const activeValues = calculateActive(
			rows,
			rowSize,
			member.groupSize,
			width,
			height,
			containerSize,
			containerInnerSize,
			member.groupIndex
		)

		member.defaultValues = defaultValues
		member.activeValues = activeValues
	})

	return { streams }
}

function calculateDefault(
	isVertical: boolean,
	width: number,
	height: number,
	realIndex: number,
	containerSize: { width: number; height: number },
	index: number,
	columnCount: number,
	leftRightMaxColumnSize: number,
	freeSpace: number,
	bottomStreamsCount: number,
	leftColumn: boolean | undefined,
	spacing: number
): {
	width: number
	height: number
	mediaLeft: number
	mediaTop: number
} {
	const mediaHeight = height
	const mediaWidth = width
	let mediaLeft = 0
	let mediaTop = 0

	if (isVertical) {
		const mobileRowSize = 10
		if (realIndex >= mobileRowSize) {
			const columnOffset = realIndex * (width + spacing)
			mediaLeft = columnOffset - mobileRowSize * width - mobileRowSize * spacing
			mediaTop = containerSize.height - height * 2 - spacing
		} else {
			mediaLeft = realIndex * (height + spacing)
			mediaTop = containerSize.height - height
		}
	} else {
		let initialOffsetTop = 0
		mediaLeft = leftColumn ? 0 : containerSize.width - mediaWidth
		mediaTop = index > 0 ? index * (height + spacing) : index * height

		if (columnCount < leftRightMaxColumnSize) {
			initialOffsetTop =
				(containerSize.height -
					columnCount * mediaHeight -
					spacing * (columnCount - 1)) /
				2
			mediaTop = mediaTop + initialOffsetTop
		}
		if (index >= leftRightMaxColumnSize - 1) {
			mediaTop = containerSize.height - mediaHeight
			let offsetIndex = index - leftRightMaxColumnSize + 1
			let offset = offsetIndex * mediaWidth

			if (freeSpace > mediaWidth * 2 && leftRightMaxColumnSize > 6) {
				const leftInitialOffset = mediaWidth + spacing
				const rightInitialOffset = containerSize.width - mediaWidth - spacing
				offsetIndex = index - leftRightMaxColumnSize
				offset = offsetIndex * mediaWidth
				mediaLeft = leftColumn
					? leftInitialOffset + offset + offsetIndex * spacing
					: rightInitialOffset - offset - mediaWidth - offsetIndex * spacing
			} else {
				mediaLeft = leftColumn
					? offset + offsetIndex * spacing
					: containerSize.width - offset - mediaWidth - offsetIndex * spacing
			}
		}
	}

	return {
		width,
		height,
		mediaLeft,
		mediaTop,
	}
}

function calculateRows(
	isVertical: boolean,
	groupSize: number
): {
	rows: number
	rowSize: number
} {
	let rows = 1
	let rowSize = 2

	if (groupSize > 9) {
		if (isVertical) {
			rowSize = 4
			rows = 3
		} else {
			rowSize = 3
			rows = 4
		}
	} else if (groupSize > 6) {
		rows = 3
		rowSize = 3
	} else if (groupSize > 4) {
		rows = 3
		rowSize = 2
	} else if (groupSize > 2) {
		rows = 2
	}

	return {
		rows,
		rowSize,
	}
}

function calculateColumnSize(
	columnSize: number,
	containerSize: { width: number; height: number },
	bottomStreamsCount: number,
	width: number,
	spacing: number,
	height: number,
	leftStreamsCount: number,
	rightStreamsCount: number
): number {
	let newColumnSize = columnSize
	bottomStreamsCount = leftStreamsCount + rightStreamsCount - columnSize * 2

	const freeSpace =
		containerSize.width -
		(bottomStreamsCount + 2) * width -
		bottomStreamsCount * spacing

	if (width < 0) return columnSize

	if (freeSpace < spacing) {
		newColumnSize = newColumnSize + 1
		height = (containerSize.height - spacing * (columnSize - 1)) / columnSize
		width = (height * 16) / 9
		bottomStreamsCount = leftStreamsCount + rightStreamsCount - columnSize * 2
		newColumnSize = calculateColumnSize(
			newColumnSize,
			containerSize,
			bottomStreamsCount,
			width,
			spacing,
			height,
			leftStreamsCount,
			rightStreamsCount
		)
	}
	return newColumnSize
}

export default ReviewMotionGrid
