export const DraggableSmall: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="9"
		height="15"
		viewBox="0 0 9 15"
	>
		<g transform="translate(-446 -443)">
			<rect
				width="3"
				height="3"
				rx="1"
				transform="translate(446 443)"
				fill="currentColor"
			/>
			<rect
				width="3"
				height="3"
				rx="1"
				transform="translate(446 449)"
				fill="currentColor"
			/>
			<rect
				width="3"
				height="3"
				rx="1"
				transform="translate(446 455)"
				fill="currentColor"
			/>
			<rect
				width="3"
				height="3"
				rx="1"
				transform="translate(452 443)"
				fill="currentColor"
			/>
			<rect
				width="3"
				height="3"
				rx="1"
				transform="translate(452 449)"
				fill="currentColor"
			/>
			<rect
				width="3"
				height="3"
				rx="1"
				transform="translate(452 455)"
				fill="currentColor"
			/>
		</g>
	</svg>
)
