export const CancelIcon = (): JSX.Element => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="100%"
		height="100%"
		viewBox="0 0 9 9"
	>
		<line
			x2="8"
			y2="8"
			transform="translate(0.53 0.53)"
			fill="none"
			stroke="currentColor"
			strokeWidth="1.5"
		/>
		<line
			x1="8"
			y2="8"
			transform="translate(0.53 0.53)"
			fill="none"
			stroke="currentColor"
			strokeWidth="1.5"
		/>
	</svg>
)
