import { Menu } from 'room/common/presentation/menu'
import ResumeTrainingMenuItem from 'room/common/presentation/resume-training-menu-item'
import MiscPanelMenu from 'room/misc/misc-panel-menu'

const LobbyMenuUpperEchelon = (): JSX.Element => {
	return (
		<Menu>
			<MiscPanelMenu />
			<ResumeTrainingMenuItem />
		</Menu>
	)
}

export default LobbyMenuUpperEchelon
