export const Avatar: React.FC<{ color?: string; square?: boolean }> = ({
	color,
	square,
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 116 116">
		<defs>
			<clipPath id="clip-path">
				<circle
					id="Ellipse_715"
					data-name="Ellipse 715"
					cx="58"
					cy="58"
					r="58"
					fill="#abb8c3"
				/>
			</clipPath>
		</defs>
		<g id="avatar" transform="translate(-599.5 -604.5)">
			<circle
				id="Ellipse_714"
				data-name="Ellipse 714"
				cx="58"
				cy="58"
				r="58"
				transform="translate(599.5 604.5)"
				fill={color || '#abb8c3'}
			/>
			<g
				id="Mask_Group_171"
				data-name="Mask Group 171"
				transform="translate(599.5 604.5)"
				clipPath={square ? undefined : 'url(#clip-path)'}
			>
				<g
					id="Group_24905"
					data-name="Group 24905"
					transform="translate(12.019 17.512)"
				>
					<g
						id="Group_24906"
						data-name="Group 24906"
						transform="translate(18.394)"
					>
						<g id="Group_24905-2" data-name="Group 24905">
							<path
								id="Path_11759"
								data-name="Path 11759"
								d="M148.59,0a27.59,27.59,0,1,0,27.59,27.59A27.622,27.622,0,0,0,148.59,0Z"
								transform="translate(-121)"
								fill="#e7eced"
							/>
						</g>
					</g>
					<g
						id="Group_24908"
						data-name="Group 24908"
						transform="translate(0 61.312)"
					>
						<g
							id="Group_24907"
							data-name="Group 24907"
							transform="translate(0)"
						>
							<path
								id="Path_11760"
								data-name="Path 11760"
								d="M111.312,311.893A39.332,39.332,0,0,0,83.115,300H70.853a39.332,39.332,0,0,0-28.2,11.893A40.209,40.209,0,0,0,31,340.261a3.066,3.066,0,0,0,3.066,3.066H119.9a3.066,3.066,0,0,0,3.066-3.066A40.209,40.209,0,0,0,111.312,311.893Z"
								transform="translate(-31 -300)"
								fill="#e7eced"
							/>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
)

export default Avatar
