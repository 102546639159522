export const EmojiIcon: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="17.5"
		height="17.5"
		viewBox="0 0 17.5 17.5"
	>
		<g transform="translate(0.75 0.75)">
			<circle
				cx="8"
				cy="8"
				r="8"
				fill="none"
				stroke="currentColor"
				strokeLinejoin="round"
				strokeWidth="1.5"
			/>
			<path
				id="Path_9017"
				data-name="Path 9017"
				d="M843.267,155.867a1.066,1.066,0,1,1,2.133,0"
				transform="translate(-839 -150)"
				fill="none"
				stroke="currentColor"
				strokeLinejoin="round"
				strokeWidth="1.5"
			/>
			<path
				d="M848.6,155.867a1.066,1.066,0,1,1,2.133,0"
				transform="translate(-839 -150)"
				fill="none"
				stroke="currentColor"
				strokeLinejoin="round"
				strokeWidth="1.5"
			/>
			<path
				d="M851.267,158.533a4.267,4.267,0,0,1-8.534,0"
				transform="translate(-839 -150)"
				fill="none"
				stroke="currentColor"
				strokeLinejoin="round"
				strokeWidth="1.5"
			/>
		</g>
	</svg>
)
