import { http, UserId } from '@beelday/common'
import { InteractionSchemeAddress } from 'common/types'
import { useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'

export type MicApi = {
	muteMic: (address: InteractionSchemeAddress, userId: UserId) => Promise<void>
	unmuteMic: (
		address: InteractionSchemeAddress,
		userId: UserId
	) => Promise<void>
}

export const createMicApi = (serverUrl: string): MicApi => ({
	muteMic: (address: InteractionSchemeAddress, userId: UserId) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/features/microphone/${userId}/muted`,
			{
				method: 'PUT',
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
				body: JSON.stringify(true),
			}
		).then(http.checkStatus),
	unmuteMic: (address: InteractionSchemeAddress, userId: UserId) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/features/microphone/${userId}/muted`,
			{
				method: 'PUT',
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
				body: JSON.stringify(false),
			}
		).then(http.checkStatus),
})

export const useMicApi = (serverUrl: string): MicApi => {
	return useMemo(() => createMicApi(serverUrl), [serverUrl])
}

export default useMicApi
