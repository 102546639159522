import { time } from '@beelday/common'
import { CompanyLogo } from '@beelday/common/src/ui'
import styled from '@emotion/styled'
import { useSelector } from 'app-redux'
import { useOrganizationBranding } from 'organization/use-organization-branding'
import { Config } from 'common/config'
import { Colors } from 'common/presentation/colors'
import Modal from 'common/presentation/modal'
import { Color } from 'common/types'
import html2canvas from 'html2canvas'
import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { colorToHex } from 'room/common/presentation/colorsInHex'
import { selectTraining } from 'session-info/redux'

const zIndexFront = 999999

type CurtainProps = {
	imageData: ImageData
	leftStart: string
	leftEnd: string
	onFadeOut?: () => void
}

type CurtainCanvasProps = {
	left: string
}

const CURTAIN_SLIDE_TIME_MS = 1000
const CurtainCanvas = styled.canvas<CurtainCanvasProps>`
	width: 50vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: ${props => props.left};
	z-index: ${zIndexFront};
	transition: left ${CURTAIN_SLIDE_TIME_MS}ms cubic-bezier(0.59, 0, 0.49, 0.67);
	/* 100ms; */
`

const Curtain: FC<CurtainProps> = ({
	imageData,
	leftEnd,
	leftStart,
	onFadeOut,
}) => {
	const ref = useRef<HTMLCanvasElement>(null)

	useLayoutEffect(() => {
		if (!ref.current) return

		const context = ref.current.getContext('2d')
		if (!context) {
			throw new Error(`Cannot get left or right side canvas context`)
		}
		context.putImageData(imageData, 0, 0)
	}, [imageData])

	useEffect(() => {
		ref.current && (ref.current.style.left = leftEnd)
	}, [leftEnd])

	return (
		<CurtainCanvas
			ref={ref}
			width={imageData.width}
			height={imageData.height}
			left={leftStart}
			onTransitionEnd={onFadeOut}
		/>
	)
}

type ImageHalves = {
	left: ImageData
	right: ImageData
}

const splitInHalf = (canvas: HTMLCanvasElement): ImageHalves => {
	const { width, height } = canvas
	const leftWidth = Math.floor(width / 2)
	const rightWidth = width - leftWidth
	const context = canvas.getContext('2d', { willReadFrequently: true })

	if (!context) {
		throw new Error(`Cannot get body canvas context`)
	}
	const left = context.getImageData(0, 0, leftWidth, height)
	const right = context.getImageData(leftWidth, 0, rightWidth, height)
	return { left, right }
}

type RunningCountdown = {
	message: string
	color: Color
	initialCountdownValue: number
}

export type CountdownProps = {
	runningCountdown?: RunningCountdown
	onCountdownFinished(): void
}

type CountdownContainerProps = {
	backgroundColor: string
}

const CountdownContainer = styled.div`
	width: 100vw;
	height: 100vh;
	background-color: ${(props: CountdownContainerProps) =>
		props.backgroundColor};
	opacity: 1;
	position: fixed;
	top: 0;
	left: 0;
	justify-content: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: ${zIndexFront};
`

type CountdownCircleProps = {
	color: string
}

const CountdownCircle = styled.div`
	border-radius: 100%;
	justify-content: center;
	display: flex;
	align-items: center;
	width: 200px;
	height: 200px;
	font-family: Ubuntu;
	font-size: 42px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	${(props: CountdownCircleProps) => `
		border: 7px solid ${props.color};
   		color: ${props.color};
    `}
`

const CountdownMessage = styled.div`
	width: 60%;
	color: ${Colors.white};
	font-size: 4vh;
	font-family: Ubuntu;
	font-weight: bold;
	text-align: center;
	margin: 30px 0px;
`

const Curtains: React.FC<{
	halves: ImageHalves
}> = ({ halves }) => {
	return halves ? (
		<>
			<Curtain imageData={halves.left} leftStart={'0%'} leftEnd={'-50%'} />
			<Curtain imageData={halves.right} leftStart={'50%'} leftEnd={'100%'} />
		</>
	) : null
}

const Timer = React.forwardRef<
	HTMLDivElement,
	{
		seconds: number
		message: string
		onDone: () => unknown
		color: keyof typeof colorToHex
	}
>(({ seconds, message, onDone }, ref) => {
	const { value, done } = time.useTimer(seconds)
	useEffect(() => {
		if (done) onDone()
	}, [done, onDone])

	const training = useSelector(state => selectTraining(state.sessionInfo))
	const branding = useOrganizationBranding(training?.id || '')

	return (
		<CountdownContainer backgroundColor={branding?.backgroundColor} ref={ref}>
			<CompanyLogo
				size="35vh"
				userApiUrl={Config.USER_API_URL}
				logoId={branding?.logoId}
			/>
			<CountdownMessage style={{ color: branding?.fontColor }}>
				{message}
			</CountdownMessage>
			<CountdownCircle color={branding.fontColor}>
				{time.toTimeString(value)}
			</CountdownCircle>
		</CountdownContainer>
	)
})
Timer.displayName = 'Timer'

const Countdown: FC<CountdownProps> = ({
	runningCountdown,
	onCountdownFinished,
}) => {
	const modalContentRef = useRef<HTMLDivElement | null>(null)
	const [halves, setHalves] = useState<ImageHalves | null>(null)

	if (!runningCountdown) return null

	const runCurtains = () => {
		if (modalContentRef.current) {
			html2canvas(modalContentRef.current, { backgroundColor: Colors.violet })
				.then(capturedCanvas => {
					const halves = splitInHalf(capturedCanvas)
					setHalves(halves)
					setTimeout(() => {
						setHalves(null)
						onCountdownFinished()
					}, CURTAIN_SLIDE_TIME_MS)
				})
				.catch(e => {
					onCountdownFinished()
					console.error(e)
					setHalves(null)
				})
		} else {
			onCountdownFinished()
			setHalves(null)
		}
	}

	return (
		<Modal backgroundColor="transparent">
			{halves ? (
				<Curtains halves={halves} />
			) : (
				<Timer
					color={runningCountdown.color}
					message={runningCountdown.message}
					seconds={runningCountdown.initialCountdownValue}
					onDone={runCurtains}
					ref={modalContentRef}
				/>
			)}
		</Modal>
	)
}

export default Countdown
