export const WordCloudIcon: React.FC = () => (
	<svg
		version="1.1"
		id="Layer_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 32.2 28.2"
		xmlSpace="preserve"
	>
		<path
			fill="currentColor"
			d="M20.2,14.1c0,1.2-0.9,2.1-2.1,2.1h-10C7,16.2,6,15.3,6,14.1C6,13,7,12,8.1,12h10C19.3,12,20.2,13,20.2,14.1z"
		/>
		<path
			fill="currentColor"
			d="M32.2,8.1c0,1.2-1,2.1-2.1,2.1h-6C23,10.2,22,9.3,22,8.1C22,7,23,6,24.1,6h6C31.3,6,32.2,7,32.2,8.1z"
		/>
		<path
			fill="currentColor"
			d="M8.2,8.1c0,1.2-0.9,2.1-2.1,2.1h-4C1,10.2,0,9.3,0,8.1C0,7,1,6,2.1,6h4C7.3,6,8.2,7,8.2,8.1z"
		/>
		<path
			fill="currentColor"
			d="M28.2,20.1c0,1.2-0.9,2.1-2.1,2.1h-6c-1.2,0-2.1-0.9-2.1-2.1c0-1.2,1-2.1,2.1-2.1h6
			C27.3,18,28.2,19,28.2,20.1z"
		/>
		<path
			fill="currentColor"
			d="M20.2,26.1c0,1.2-0.9,2.1-2.1,2.1h-10c-1.2,0-2.1-1-2.1-2.1S7,24,8.1,24h10C19.3,24,20.2,25,20.2,26.1z"
		/>
		<path
			fill="currentColor"
			d="M20.3,3.1c0,0.6-0.5,1.1-1.1,1.1h-8c-0.6,0-1.1-0.5-1.1-1.1S10.5,2,11.1,2h8C19.7,2,20.3,2.5,20.3,3.1z"
		/>
		<path
			fill="currentColor"
			d="M30.2,1.1c0,0.6-0.5,1.1-1.1,1.1h-6c-0.6,0-1.1-0.5-1.1-1.1S22.5,0,23.1,0h6C29.7,0,30.2,0.5,30.2,1.1z"
		/>
		<path
			fill="currentColor"
			d="M32.2,13.1c0,0.6-0.5,1.1-1.1,1.1h-6c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1h6C31.7,12,32.2,12.5,32.2,13.1z
			"
		/>
		<path
			fill="currentColor"
			d="M8.2,1.1c0,0.6-0.5,1.1-1.1,1.1h-4C2.5,2.2,2,1.7,2,1.1S2.5,0,3.1,0h4C7.7,0,8.2,0.5,8.2,1.1z"
		/>
		<path
			fill="currentColor"
			d="M14.2,19.1c0,0.6-0.5,1.1-1.1,1.1h-10c-0.6,0-1.1-0.5-1.1-1.1S2.5,18,3.1,18h10C13.7,18,14.2,18.5,14.2,19.1z
			"
		/>
		<path
			fill="currentColor"
			d="M18.2,9.1c0,0.6-0.5,1.1-1.1,1.1h-6c-0.6,0-1.1-0.5-1.1-1.1S10.5,8,11.1,8h6C17.7,8,18.2,8.5,18.2,9.1z"
		/>
	</svg>
)
