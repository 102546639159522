import styled from '@emotion/styled'
import { colors, ui } from '@beelday/common'
import ProfileAvatar from 'users/profile-avatar'
import circleDraggIcon from './circle-dragg-icon.svg'

export const Header = styled.div`
	align-items: center;
	background-color: ${colors.white};
	border-radius: 40px 15px 0 40px;
	cursor: pointer;
	display: flex;
	height: 65px;
	padding: 10px 20px 10px 10px;

	${ui.responsive.desktop1440} {
		height: 85px;
		padding: 10px 40px 10px 10px;
	}

	&:hover {
		.trainerFace {
			&:before {
				opacity: 1;
			}

			&:after {
				opacity: 1;
			}
		}
	}
`

export const TrainerFace = styled(ProfileAvatar)`
	height: 50px;
	margin-right: 20px;
	position: relative;
	width: 50px;

	${ui.responsive.desktop1440} {
		height: 65px;
		width: 65px;
		margin-right: 25px;
	}

	&:before {
		background: ${colors.black50};
		border-radius: 50%;
		content: '';
		height: 100%;
		left: 0;
		opacity: 0;
		position: absolute;
		top: 0;
		transition: opacity 0.25s;
		width: 100%;
	}

	&:after {
		background-image: url(${circleDraggIcon});
		background-size: contain;
		bottom: 0;
		content: '';
		display: block;
		height: 27px;
		opacity: 0;
		position: absolute;
		right: 0;
		transition: opacity 0.25s;
		width: 27px;
	}
`

export const HeaderTitle = styled.div`
	color: ${colors.black};
	font-family: Ubuntu;
	font-size: 20px;
	font-weight: 500;
`

export const HeaderSubtitle = styled.div`
	color: ${colors.black};
	font-family: Ubuntu;
	font-size: 12px;
`

export const Fill = styled.div`
	flex-grow: 1;
`

export const Body = styled.div`
	padding-left: 60px;
`

export const Inner = styled.div`
	background-color: ${colors.white};
	border-radius: 0 0 15px 15px;
	border-top: 1px solid ${colors.brightGray};
	padding: 25px 20px 30px 20px;
	overflow: hidden;

	${ui.responsive.desktop1440} {
		padding: 29px 40px 40px 40px;
	}
`
