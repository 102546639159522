import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Branding, Organization, OrganizationId } from './model'
import { RootState } from 'common/redux'
import createOrganizationApi from './organization-api'
import { colors } from '@beelday/common'

export type OrganizationState = {
	organization: Organization | null
	brandingDefault: Branding
	expandableCompanyCard: boolean
}

const FALLBACK = {
	logoId: '',
	heroPhotoId: '',
	backgroundColor: colors.indigoBlue,
	fontColor: colors.white,
}

const INITIAL_STATE: OrganizationState = {
	organization: null,
	brandingDefault: FALLBACK,
	expandableCompanyCard: true,
}

const fetchOrganizationById = createAsyncThunk<
	Organization,
	OrganizationId,
	{ state: RootState }
>(
	'organization/fetchOrganizationById',
	async (organizationId: OrganizationId) => {
		const api = createOrganizationApi()

		return api.getOrganizationById(organizationId)
	},
	{
		condition: (_, { getState }) => !getState().organization.organization,
	}
)

const setIsExpandableCompanyCard = createAction<boolean>(
	'SET_IS_EXPANDABLE_COMPANY_CARD'
)

export const organizationSlice = createSlice({
	name: 'organization',
	initialState: INITIAL_STATE,
	reducers: {},

	extraReducers: builder => {
		builder.addCase(fetchOrganizationById.fulfilled, (state, action) => {
			if (action.payload !== null) {
				state.organization = action.payload
			}
		})
		builder.addCase(setIsExpandableCompanyCard, (state, { payload }) => {
			state.expandableCompanyCard = payload
		})
	},
})

const selectIsExpandableCompanyCard = (state: RootState): boolean => {
	return state.organization.expandableCompanyCard
}

const selectBranding = (state: RootState): Branding => {
	const branding = state.organization.organization?.branding

	if (branding) {
		return branding
	}

	return state.organization.brandingDefault
}

const selectOrganization = (state: RootState): Organization => {
	const organization = state.organization.organization

	if (organization) {
		if (organization?.branding) {
			return organization
		} else {
			return { ...organization, branding: state.organization.brandingDefault }
		}
	}
	return {
		name: '',
		branding: state.organization.brandingDefault,
		contactEmail: '',
		description: '',
		id: '',
		websiteLink: '',
	}
}

//actions
export { fetchOrganizationById, setIsExpandableCompanyCard }

//selectors
export { selectBranding, selectOrganization, selectIsExpandableCompanyCard }
