import { pull } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import io from 'socket.io-client'
import { memoizeSingle } from '../func'
import logger from '../logger'
import { useToken } from '../security'

const log = logger.create('eventbus')

type EventBus = {
	subscribe: (type: string, id: string) => void
	unsubscribe: (type: string, id: string) => void
	addListener: (listener: Listener) => Unsubscribe
}

declare global {
	interface Window {
		beelday_eventbus: PrivateEventBus
	}
}

const createEventBus = memoizeSingle((): PrivateEventBus => {
	const listeners: Listener[] = []
	const subscriptions: Array<[type: string, id: string, count: number]> = []

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let socket: any

	return {
		isConnected() {
			return !!socket
		},
		connect() {
			if (!socket) {
				log.info('Connecting to eventbus')
				socket = io('/socket.io', {
					path: '/socket.io',
					upgrade: true,
					rememberUpgrade: false,
					transports: ['websocket', 'polling'],
					withCredentials: true,
					//We have bad types for socket.io-client
				} as never)

				socket.on('connect', () => {
					log.info('Websocket connected')
					//Make sure we subscribe again to all previous entities
					subscriptions.forEach(([type, id]) => {
						log.debug('Websocket re-subscribe', type, id)
						socket.emit('subscribe', { type, id })
					})
				})

				socket.on('event', (event: Event) => listeners.forEach(l => l(event)))
			}
		},
		subscribe(type, id) {
			const current = subscriptions.find(s => s[0] === type && s[1] === id)
			if (current) {
				current[2]++
				return
			} else {
				log.debug('Websocket subscribe', type, id)
				subscriptions.push([type, id, 1])
				if (socket?.connected) {
					socket.emit('subscribe', { type, id })
				} else {
					log.warn(
						`Eventbus not connected yet! Trying to subscribe to: ${type}:${id}`
					)
				}
			}
		},
		unsubscribe(type, id) {
			const currentIndex = subscriptions.findIndex(
				s => s[0] === type && s[1] === id
			)
			if (--subscriptions[currentIndex][2] === 0) {
				subscriptions.splice(currentIndex, 1)
				log.debug('Websocket unsubscribe', type, id)
				if (socket) {
					socket.emit('unsubscribe', { type, id })
				}
			}
		},
		addListener(listener: Listener) {
			log.debug('Registering event bus listener')
			listeners.push(listener)
			return () => {
				pull(listeners, listener)
			}
		},
		listSubscriptions() {
			return subscriptions
				.map(s => `${s[0]}(${s[1]}) - ${s[2]}`)
				.sort()
				.join('\n')
		},
	}
})

const EventBusContext = React.createContext<EventBus | null>(null)
export const useEventBus = (): EventBus => {
	const eventBus = useContext(EventBusContext)
	if (eventBus == null) throw new Error('Event bus not in context!')

	return eventBus
}

export const ConnectEventBus: React.FC<{
	eventHandler: (event: Event) => unknown
}> = ({ eventHandler, children }) => {
	const [eventBus, setEventBus] = useState<EventBus | null>(null)
	const token = useToken()
	const isLoggedIn = token?.type === 'VALID_TOKENS'

	useEffect(() => {
		if (!window.beelday_eventbus) {
			log.info('Creating global event bus')
			window.beelday_eventbus = createEventBus()
		}

		if (!window.beelday_eventbus.isConnected()) {
			if (isLoggedIn) {
				log.info('Connecting to event bus')
				window.beelday_eventbus.connect()
			} else {
				log.warn('Not logged in, skip connecting to event bus')
			}
		}

		const unsubscribe = window.beelday_eventbus.addListener(eventHandler)
		setEventBus(window.beelday_eventbus)
		return () => {
			log.info('Disconnecting from event bus')
			unsubscribe()
		}
	}, [eventHandler, isLoggedIn])

	return (
		<EventBusContext.Provider value={eventBus}>
			{eventBus ? children : null}
		</EventBusContext.Provider>
	)
}

type Listener = (event: Event) => unknown
type Unsubscribe = () => void
type PrivateEventBus = EventBus & {
	connect: () => void
	isConnected: () => boolean
	addListener: (listener: Listener) => Unsubscribe
	listSubscriptions: () => string
}

type Event = {
	type: string
	id: string
}
