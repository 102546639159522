import styled from '@emotion/styled'
import { CSSProperties, useCallback } from 'react'
import colors from '../colors'
import { useTranslate } from '../intl'
import { Input } from './input'
import search from './search.svg'

const SearchContainer = styled.div`
	position: relative;
	align-items: center;
	border: 1px solid ${colors.brightGray};
	border-radius: 10px;
	display: flex;
`

const SearchInput = styled(Input)`
	border: none;
	flex: 1;
	padding: 10px;
	padding-right: 80px;
`

const SearchBtn = styled.button`
	align-items: center;
	background-color: ${colors.indigoBlue};
	border: none;
	border-radius: 10px;
	box-shadow: 3px 5.2px 10px 0 rgba(79, 23, 190, 0.2);
	cursor: pointer;
	display: flex;
	justify-content: center;
	position: absolute;
	right: 0;
	top: 0;
	padding: 8px 20px;

	&:active {
		opacity: 0.6;
	}
`

const SearchIcon = styled.img`
	height: 20px;
	width: 20px;
`

type Props = {
	placeholder: string
	style?: CSSProperties
	value: string
	onChange: (val: string) => void
}

export const SmallSearch = ({
	placeholder,
	style,
	value,
	onChange,
}: Props): JSX.Element => {
	const translate = useTranslate()
	const handleKey = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const { value } = event.currentTarget
			onChange(value)
		},
		[onChange]
	)

	return (
		<SearchContainer style={style}>
			<SearchInput
				placeholder={translate(placeholder)}
				value={value}
				onChange={handleKey}
			/>
			<SearchBtn>
				<SearchIcon src={search} />
			</SearchBtn>
		</SearchContainer>
	)
}
