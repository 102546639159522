import { intl } from '@beelday/common'
import { includes } from 'lodash'
import { FC, useState } from 'react'
import {
	Menu,
	MenuItemLine,
	MenuToggleItem,
} from 'room/common/presentation/menu'
import { RaiseHand } from '../../common/presentation/icon/raise-hand'
import { Whiteboard } from '../../common/presentation/icon/whiteboard'
import { Document } from '../../common/presentation/icon/document'
import { Description } from '../../common/presentation/icon/description'
import { MicMenuItem } from 'features/microphone'
import MiscPanelMenu from '../../misc/misc-panel-menu'
import { GroupReview } from '../../../common/types'
import GroupsTaskDescriptionPopup from './groups-task-description-popup'
import CamMenuItem from 'features/camera/camera-menu-item'
import ScreenShareMenuItem from 'features/screen-share/screen-share-group-menu-item'
import useScreenShareInGroup from 'features/screen-share/use-screen-share-in-group'

export enum TraineeMenuOption {
	RequireAttention,
	EtherPad,
	Excalidraw,
}

type Props = {
	onMenuOptionSelected: (menuOption: TraineeMenuOption) => void
	onMenuOptionDeselected: (menuOption: TraineeMenuOption) => void
	selectedMenuOptions: TraineeMenuOption[]
	taskDetails: GroupReview | undefined
}

export const TraineeMenu: FC<Props> = ({
	onMenuOptionSelected,
	onMenuOptionDeselected,
	selectedMenuOptions,
	taskDetails,
}) => {
	const [showDescription, setShowDescription] = useState(false)
	const { userGroupScreenShareState } = useScreenShareInGroup()

	const raiseHandEnabled = includes(
		selectedMenuOptions,
		TraineeMenuOption.RequireAttention
	)
	const etherPadEnabled = includes(
		selectedMenuOptions,
		TraineeMenuOption.EtherPad
	)

	const excalidrawEnabled =
		includes(selectedMenuOptions, TraineeMenuOption.Excalidraw) &&
		!userGroupScreenShareState?.active

	return (
		<Menu short>
			<MenuToggleItem
				icon={<RaiseHand />}
				active={raiseHandEnabled}
				label={<intl.Translate>room_menu.raise_hand</intl.Translate>}
				onClick={() =>
					raiseHandEnabled
						? onMenuOptionDeselected(TraineeMenuOption.RequireAttention)
						: onMenuOptionSelected(TraineeMenuOption.RequireAttention)
				}
			/>
			<ScreenShareMenuItem />
			<MenuToggleItem
				icon={<Whiteboard />}
				active={excalidrawEnabled}
				disabled={userGroupScreenShareState?.active}
				label={<intl.Translate>room_menu.whiteboard</intl.Translate>}
				onClick={() =>
					excalidrawEnabled
						? onMenuOptionDeselected(TraineeMenuOption.Excalidraw)
						: onMenuOptionSelected(TraineeMenuOption.Excalidraw)
				}
			/>
			<MenuToggleItem
				icon={<Document />}
				active={etherPadEnabled}
				disabled={userGroupScreenShareState?.active}
				label={<intl.Translate>room_menu.notepad</intl.Translate>}
				onClick={() =>
					etherPadEnabled
						? onMenuOptionDeselected(TraineeMenuOption.EtherPad)
						: onMenuOptionSelected(TraineeMenuOption.EtherPad)
				}
			/>
			<MicMenuItem />
			<CamMenuItem />
			<MenuItemLine />
			<MiscPanelMenu />
			{taskDetails?.task?.description ? (
				<MenuToggleItem
					icon={<Description />}
					active={showDescription}
					label={<intl.Translate>room_menu.task_description</intl.Translate>}
					onClick={() => setShowDescription(true)}
				/>
			) : null}

			{taskDetails?.task?.description && showDescription ? (
				<GroupsTaskDescriptionPopup
					taskDetails={taskDetails}
					onClose={() => setShowDescription(false)}
				/>
			) : null}
		</Menu>
	)
}
