export const EditIcon: React.FC = () => (
	<svg
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 16 16"
		xmlSpace="preserve"
	>
		<g>
			<path
				fill="currentColor"
				d="M16,3.4c0-0.4-0.2-0.8-0.4-1.1l-1.9-1.9c-0.6-0.6-1.5-0.6-2.1,0c0,0,0,0,0,0L0.7,11.3
		c-0.1,0.1-0.2,0.2-0.2,0.4L0,15.2c-0.1,0.4,0.2,0.7,0.6,0.8c0.1,0,0.1,0,0.2,0l3.5-0.5c0.2,0,0.3-0.1,0.4-0.2L15.6,4.4
		C15.8,4.1,16,3.8,16,3.4 M3.9,14.1l-2.3,0.3l0.3-2.3l8.8-8.8l2,2L3.9,14.1z M14.6,3.4l-0.9,0.9l-2-2l0,0l0.9-0.9c0,0,0.1,0,0.1,0
		c0,0,0,0,0,0l1.9,1.9C14.6,3.3,14.6,3.4,14.6,3.4"
			/>
		</g>
	</svg>
)
