import {
	colors,
	form,
	icons,
	intl,
	router,
	styles,
	ui,
	userApi,
} from '@beelday/common'
import styled from '@emotion/styled'
import { HeaderSmall } from 'common/presentation/header-small'
import React, { useMemo } from 'react'
import { Router } from 'react-router'
import { useAssertedWorkflowUser } from 'room/common/use-workflow-user'
import { ProfileAvatarWithInterests } from 'users/profile-avatar'
import { useProfileFor } from 'users/redux'
import useFetchMissingProfiles from 'users/use-fetch-missing-profiles'

const ProfileContainer = styled.div`
	overflow: auto;
	background-color: ${colors.whitish};
	padding: 55px;
	flex: 1;
`

const MyProfileMenu: React.FC = () => (
	<ui.SubMenu
		href="/"
		icon={<icons.ProfileIcon />}
		title={<intl.Translate>menu.profile</intl.Translate>}
	>
		<ui.SubMenu.Item href="/overview" exact={true}>
			<intl.Translate>menu.profile.overview</intl.Translate>
		</ui.SubMenu.Item>
		<ui.SubMenu.Item href="/edit/basic" exact={true}>
			<intl.Translate>menu.profile.basic</intl.Translate>
		</ui.SubMenu.Item>
		<ui.SubMenu.Item href="/overview/friends" exact={true}>
			<intl.Translate>menu.profile.friends</intl.Translate>
		</ui.SubMenu.Item>
		<ui.SubMenu.Item href="/edit/hobby">
			<intl.Translate>menu.profile.hobby</intl.Translate>
		</ui.SubMenu.Item>
		<ui.SubMenu.Item href="/edit/password">
			<intl.Translate>menu.profile.password</intl.Translate>
		</ui.SubMenu.Item>
	</ui.SubMenu>
)

const Menu: React.FC<{ onClose: () => unknown }> = ({ onClose }) => {
	const currentUser = useAssertedWorkflowUser()
	const userProfile = useProfileFor(currentUser.id)

	useFetchMissingProfiles([currentUser.id])

	return (
		<ui.SideMenu>
			<div style={{ width: '115px', margin: '0 auto' }}>
				<ProfileAvatarWithInterests profile={userProfile} />
			</div>
			<div
				css={styles.ellipsis}
				style={{
					margin: '20px auto 22px auto',
					fontWeight: 500,
					textAlign: 'center',
				}}
			>
				{userApi.getDisplayName(userProfile)}
			</div>
			<ui.FlexCenter style={{ marginBottom: '40px' }}>
				<ui.ButtonMinor
					onClick={onClose}
					style={{ display: 'flex', alignItems: 'center' }}
				>
					<span
						style={{
							width: '18px',
							display: 'inline-block',
							marginRight: '10px',
						}}
					>
						<icons.BackIcon />
					</span>
					<intl.Translate>menu.profile.back_to_lobby</intl.Translate>
				</ui.ButtonMinor>
			</ui.FlexCenter>
			<MyProfileMenu />
		</ui.SideMenu>
	)
}

const UserProfileRouted: React.FC<{ onClose: () => unknown }> = ({
	onClose,
}) => {
	const userProfileRef = React.useRef(null)
	const path = router.useDelegatingHistory(userProfileRef)
	const hasUnsaved = form.useHasUnsavedInWebComponent(userProfileRef)
	const confirm = ui.useConfirm()

	form.useHandleUnsavedRaw(hasUnsaved)

	const handleClose = () => {
		if (hasUnsaved) {
			confirm.unsaved().then(onClose)
		} else {
			onClose()
		}
	}

	return (
		<ui.FlexRow css={styles.fillSpace} style={{ overflow: 'hidden' }}>
			<Menu onClose={handleClose} />
			<ProfileContainer>
				<beelday-user-profile path={path} ref={userProfileRef} />
			</ProfileContainer>
		</ui.FlexRow>
	)
}

type Props = {
	onClose: () => unknown
}
export const UserProfileEdit: React.FC<Props> = ({ onClose }) => {
	const history = useMemo(
		() =>
			router.createMemoryHistory({
				initialEntries: ['/overview'],
			}),
		[]
	)

	return (
		<ui.FlexColumn css={styles.fillScreen}>
			<HeaderSmall />
			<Router history={history}>
				<UserProfileRouted onClose={onClose} />
			</Router>
		</ui.FlexColumn>
	)
}

export default UserProfileEdit
