export const Back: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="19.724"
		viewBox="0 0 24 19.724"
	>
		<g data-name="Group 25580">
			<path
				data-name="Path 11813"
				d="M17.04 5.813H8.633V.774A.717.717 0 0 0 7.428.2l-7.2 7.23a.81.81 0 0 0 0 1.122l7.2 7.23a.712.712 0 0 0 1.205-.575v-5.04h8.325a2.647 2.647 0 0 1 2.656 2.355 2.6 2.6 0 0 1-2.6 2.848h-3.507a1.157 1.157 0 0 0-1.15 1.15v2.054a1.157 1.157 0 0 0 1.15 1.15h3.369A7.054 7.054 0 0 0 24 12.96a6.94 6.94 0 0 0-6.96-7.147z"
			/>
		</g>
	</svg>
)
