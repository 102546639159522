import { colors, date, intl, localTime, styles, ui } from '@beelday/common'
import styled from '@emotion/styled'
import { useSelector } from 'app-redux'
import { AnimatePresence, motion } from 'framer-motion'
import { map } from 'lodash'
import { FC, ReactNode, useEffect, useRef } from 'react'
import { useAssertedWorkflowUser } from 'room/common/use-workflow-user'
import { useIsFirstRender } from 'usehooks-ts'
import end from './images/end.svg'
import start from './images/start.svg'
import { Activity, ScriptItem, SessionChunk } from './script-model'
import { selectScript } from './script-redux'
import ScriptActivityDescription from './script-activity-description'

const Time = styled.div`
	color: ${colors.black70};
	font-size: 14px;
	width: 80px;
	margin-bottom: 5px;
`

const Title = styled.div`
	color: ${colors.black};
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 10px;
	display: flex;
	align-items: center;

	&:last-child {
		margin-bottom: 0;
	}
`

const Description = styled.div`
	color: ${colors.black70};
	font-family: Ubuntu;
	font-size: 14px;
	margin-bottom: 10px;

	&:last-child {
		margin-bottom: 0;
	}
`

const ItemContainer = styled.div`
	position: relative;
	border: 1px solid ${colors.borderLightGray};
	border-radius: 11px;
	padding: 15px 20px;
	margin-bottom: 10px;
`

const ActiveBadge = styled(motion.div)`
	background-color: ${colors.greenishCyan};
	padding: 4px 5px;
	border-radius: 4px;
	position: absolute;
	font-size: 11px;
	font-weight: bold;
	color: ${colors.black70};
	top: 10px;
	right: 10px;
`

const ActivityContainer = styled.div`
	position: relative;
	border: 1px solid ${colors.borderLightGray};
	border-radius: 11px;
	padding: 4px;
	margin-bottom: 5px;
	display: flex;
	align-items: center;
	font-size: 12px;

	&:last-child {
		margin-bottom: 0;
	}

	svg {
		height: 43px;
		width: auto;
		margin-right: 4px;
	}
`

const ActivityTitle = styled.span`
	font-weight: bold;
	margin-left: 4px;
`

const DayLabel = styled.div`
	font-size: 16px;
	color: ${colors.indigoBlue};
	margin: 15px 0;
`

const StartEndIcon = styled.img`
	width: 18px;
	height: 18px;
	margin-right: 5px;
`

const ScriptActivityItem: FC<{ activity: Activity }> = ({ activity }) => {
	const translate = intl.useTranslate()
	return (
		<ActivityContainer>
			<ui.ActivityIcon type={activity.type} size={32} />
			<ActivityTitle>
				{translate(`training.script.tool.${activity.type}.title`)}:
			</ActivityTitle>
			&nbsp;
			<span css={styles.ellipsis}>
				<ScriptActivityDescription activity={activity} />
			</span>
		</ActivityContainer>
	)
}

const ScriptDetailsItem: FC<{
	item: ScriptItem
	active?: boolean
	finished?: boolean
	children?: ReactNode | ReactNode[]
}> = ({ item, active, finished, children }) => {
	const dateFormat = date.useDateFormat()
	return (
		<ItemContainer
			style={{
				borderColor: active ? colors.indigoBlue : colors.borderLightGray,
				borderWidth: active ? '2px' : '1px',
				textDecoration: finished ? 'line-through' : 'none',
			}}
		>
			<AnimatePresence>
				{active ? (
					<ActiveBadge
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
					>
						<intl.Translate>script.badge_now</intl.Translate>
					</ActiveBadge>
				) : null}
			</AnimatePresence>
			<Time>{dateFormat.formatTime(item.startTime)}</Time>
			<Title>{item.title}</Title>
			{item.description ? <Description>{item.description}</Description> : null}
			{children}
		</ItemContainer>
	)
}

const ScriptChunkStart = ({
	chunk,
	finished,
}: {
	chunk: SessionChunk
	finished?: boolean
}) => {
	const translate = intl.useTranslate()
	return (
		<ItemContainer
			style={{
				textDecoration: finished ? 'line-through' : 'none',
			}}
		>
			<Time>{localTime.format(chunk.startTime)}</Time>
			<Title>
				<StartEndIcon src={start} alt="Session start icon" />
				{chunk.startTitle ||
					translate('training.script.start.default_description')}
			</Title>
		</ItemContainer>
	)
}

const ScriptChunkEnd = ({
	chunk,
	finished,
}: {
	chunk: SessionChunk
	finished?: boolean
}) => {
	const translate = intl.useTranslate()
	return (
		<ItemContainer
			style={{
				textDecoration: finished ? 'line-through' : 'none',
			}}
		>
			<Time>{localTime.format(chunk.endTime)}</Time>
			<Title>
				<StartEndIcon src={end} alt="Session end icon" />
				{chunk.startTitle ||
					translate('training.script.end.default_description')}
			</Title>
		</ItemContainer>
	)
}

export const ScriptDetails: FC = () => {
	const dateFormat = date.useDateFormat()
	const user = useAssertedWorkflowUser()
	const { chunks, currentSessionChunkIndex, currentItemIndex } =
		useSelector(selectScript)

	const showActivities = user?.role === 'UPPER_ECHELON'

	const activeItemRef = useRef<HTMLDivElement>(null)
	const firstRender = useIsFirstRender()

	useEffect(() => {
		activeItemRef.current?.scrollIntoView({
			behavior: firstRender ? 'auto' : 'smooth',
		})
	}, [chunks, firstRender])

	return (
		<div>
			{map(chunks, (chunk, chunkIdx) => {
				return (
					<div key={chunkIdx}>
						<DayLabel
							style={{
								marginTop: currentSessionChunkIndex === 0 ? 0 : undefined,
							}}
						>
							{dateFormat.formatDate(chunk.startDate, 'LONG')}
						</DayLabel>
						<ScriptChunkStart
							chunk={chunk}
							finished={
								currentSessionChunkIndex > chunkIdx ||
								(currentSessionChunkIndex === chunkIdx && currentItemIndex >= 0)
							}
						/>
						{chunk.items.map((item, itemIdx) => {
							const active =
								currentSessionChunkIndex === chunkIdx &&
								currentItemIndex === itemIdx
							const finished =
								(currentSessionChunkIndex === chunkIdx &&
									currentItemIndex > itemIdx) ||
								currentSessionChunkIndex > chunkIdx

							return (
								<div ref={active ? activeItemRef : undefined} key={itemIdx}>
									<ScriptDetailsItem
										item={item}
										active={active}
										finished={finished}
									>
										{showActivities
											? map(item.activities, (activity, i) => (
													<ScriptActivityItem key={i} activity={activity} />
											  ))
											: null}
									</ScriptDetailsItem>
								</div>
							)
						})}
						<ScriptChunkEnd
							chunk={chunk}
							finished={currentSessionChunkIndex > chunkIdx}
						/>
					</div>
				)
			})}
		</div>
	)
}

export default ScriptDetails
