import React from 'react'
import { css } from '@emotion/react'
import ubuntuLightItalic from 'common/presentation/fonts/Ubuntu-LightItalic.ttf'
import ubuntuMediumItalic from 'common/presentation/fonts/Ubuntu-MediumItalic.ttf'
import ubuntuBoldItalic from 'common/presentation/fonts/Ubuntu-BoldItalic.ttf'
import ubuntuLight from 'common/presentation/fonts/Ubuntu-Light.ttf'
import ubuntuRegular from 'common/presentation/fonts/Ubuntu-Regular.ttf'
import ubuntuMedium from 'common/presentation/fonts/Ubuntu-Medium.ttf'
import ubuntuBold from 'common/presentation/fonts/Ubuntu-Bold.ttf'

export const PreloadedFonts = React.memo(() => {
	return (
		<div
			css={css`
				@font-face {
					font-family: 'Ubuntu';
					font-style: italic;
					font-weight: 300;
					font-display: swap;
					src: local('Ubuntu Light Italic'), local('Ubuntu-LightItalic'),
						url(${ubuntuLightItalic}) format('truetype');
				}
				@font-face {
					font-family: 'Ubuntu';
					font-style: italic;
					font-weight: 500;
					font-display: swap;
					src: local('Ubuntu Medium Italic'), local('Ubuntu-MediumItalic'),
						url(${ubuntuMediumItalic}) format('truetype');
				}
				@font-face {
					font-family: 'Ubuntu';
					font-style: italic;
					font-weight: 700;
					font-display: swap;
					src: local('Ubuntu Bold Italic'), local('Ubuntu-BoldItalic'),
						url(${ubuntuBoldItalic}) format('truetype');
				}
				@font-face {
					font-family: 'Ubuntu';
					font-style: normal;
					font-weight: 300;
					font-display: swap;
					src: local('Ubuntu Light'), local('Ubuntu-Light'),
						url(${ubuntuLight}) format('truetype');
				}
				@font-face {
					font-family: 'Ubuntu';
					font-style: normal;
					font-weight: 400;
					font-display: swap;
					src: local('Ubuntu Regular'), local('Ubuntu-Regular'),
						url(${ubuntuRegular}) format('truetype');
				}
				@font-face {
					font-family: 'Ubuntu';
					font-style: normal;
					font-weight: 500;
					font-display: swap;
					src: local('Ubuntu Medium'), local('Ubuntu-Medium'),
						url(${ubuntuMedium}) format('truetype');
				}
				@font-face {
					font-family: 'Ubuntu';
					font-style: normal;
					font-weight: 700;
					font-display: swap;
					src: local('Ubuntu Bold'), local('Ubuntu-Bold'),
						url(${ubuntuBold}) format('truetype');
				}
			`}
		>
			<div
				style={{
					fontFamily: 'Ubuntu',
					fontWeight: 300,
					fontStyle: 'italic',
					position: 'fixed',
					top: -1000,
				}}
			>
				Ubuntu Light Italic
			</div>
			<div
				style={{
					fontFamily: 'Ubuntu',
					fontWeight: 500,
					fontStyle: 'italic',
					position: 'fixed',
					top: -1000,
				}}
			>
				Ubuntu Medium Italic
			</div>
			<div
				style={{
					fontFamily: 'Ubuntu',
					fontWeight: 700,
					fontStyle: 'italic',
					position: 'fixed',
					top: -1000,
				}}
			>
				Ubuntu Bold Italic
			</div>
			<div
				style={{
					fontFamily: 'Ubuntu',
					fontWeight: 300,
					fontStyle: 'normal',
					position: 'fixed',
					top: -1000,
				}}
			>
				Ubuntu Light
			</div>
			<div
				style={{
					fontFamily: 'Ubuntu',
					fontWeight: 400,
					fontStyle: 'normal',
					position: 'fixed',
					top: -1000,
				}}
			>
				Ubuntu Regular
			</div>
			<div
				style={{
					fontFamily: 'Ubuntu',
					fontWeight: 500,
					fontStyle: 'normal',
					position: 'fixed',
					top: -1000,
				}}
			>
				Ubuntu Medium
			</div>
			<div
				style={{
					fontFamily: 'Ubuntu',
					fontWeight: 700,
					fontStyle: 'normal',
					position: 'fixed',
					top: -1000,
				}}
			>
				Ubuntu Bold
			</div>
		</div>
	)
})
