import { ui } from '@beelday/common'
import { keyBy, map } from 'lodash'
import { FC, useMemo } from 'react'
import { useSelector } from 'app-redux'
import { useProfilesFor } from 'users/redux'
import useFetchMissingProfiles from 'users/use-fetch-missing-profiles'

export const DevToolsEngagement: FC = () => {
	const engagement = useSelector(state => state.groupEngagement)
	const groups = useSelector(state => state.groupRoom?.groups)
	const userIds = useMemo(() => {
		return Object.values(engagement).reduce((acc, { user_scores }) => {
			if (user_scores) {
				acc.push(...Object.keys(user_scores))
			}
			return acc
		}, [] as string[])
	}, [engagement])
	useFetchMissingProfiles(userIds)
	const profiles = useProfilesFor(userIds)
	const profilesById = useMemo(() => keyBy(profiles, 'id'), [profiles])

	return (
		<ui.FlexColumn>
			<ui.FlexRow style={{ alignItems: 'center' }}>
				<ui.FillSpace>
					<b>Group Engagement:</b>
				</ui.FillSpace>
			</ui.FlexRow>
			{map(groups, (group, groupId) => {
				const groupData = engagement[groupId]
				return (
					<div key={groupId}>
						<hr />
						<div>
							<strong>
								{group?.groupDescription.name || groupId}(
								{groupData?.group_score ?? '?'})
							</strong>
						</div>
						{map(groupData?.user_scores, (score, userId) => {
							return (
								<div>
									{profilesById[userId]?.name || userId}: {score}
								</div>
							)
						})}
						<div>
							<small>
								{groupData?.lower_quantile} - {groupData?.upper_quantile}
							</small>
						</div>
						<div>
							<small>
								{groupData?.window_start} - {groupData?.window_end}
							</small>
						</div>
					</div>
				)
			})}
		</ui.FlexColumn>
	)
}

export default DevToolsEngagement
