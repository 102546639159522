import styled from '@emotion/styled'
import { CSSProperties, FC } from 'react'
import { ButtonClose } from '.'
import colors from '../colors'
import responsive from './responsive'

const Container = styled.div`
	background-color: ${colors.white};
	display: flex;
	flex-direction: column;
	border-radius: 18px;
	max-height: 90vh;
`

const TitleContainer = styled.div`
	border-bottom: 1px solid ${colors.brightGray};
	display: flex;
	align-items: center;
	padding: 16px 20px;
	justify-content: space-between;

	${responsive.smallTablet} {
		padding: 25px 40px;
	}
`

const Title = styled.div`
	color: ${colors.black};
	font-family: Ubuntu;
	font-size: 20px;
	font-weight: 500;
	margin-right: 1em;
`

const ChildrenContainer = styled.div<{ withInnerChildrenPadding: boolean }>`
	display: flex;
	flex-direction: column;
	height: 100px;
	padding: ${props => (props.withInnerChildrenPadding ? '20px' : '0px')};
	overflow: auto;
	display: flex;
	flex: 1;

	${responsive.smallTablet} {
		padding: ${props =>
			props.withInnerChildrenPadding ? '35px 40px 40px 40px' : '0px'};
	}
`

type Props = {
	title: JSX.Element | string
	onClose: () => void
	className?: string
	afterTitle?: JSX.Element
	style?: CSSProperties
	withInnerChildrenPadding?: boolean
}

export const DetailsPanel: FC<Props> = ({
	title,
	onClose,
	className,
	afterTitle,
	children,
	style,
	withInnerChildrenPadding = true,
}) => (
	<Container className={className} style={style}>
		<TitleContainer>
			<Title>{title}</Title>
			<ButtonClose onClick={onClose} />
		</TitleContainer>
		{afterTitle}
		<ChildrenContainer withInnerChildrenPadding={withInnerChildrenPadding}>
			{children}
		</ChildrenContainer>
	</Container>
)

export default DetailsPanel
