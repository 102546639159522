import { Excalidraw } from '@pragmatic-brains/excalidraw'
import { Config } from 'common/config'
import { Colors } from 'common/presentation/colors'
import { Spinner } from 'common/presentation/spinner'
import { Color } from 'common/types'
import { FC, useMemo } from 'react'
import { colorToHex } from 'room/common/presentation/colorsInHex'
import { useMyProfile as useMyTrainingProfile } from 'users'
import MediaContainer from './media-container'
type LoadedProps = {
	color: Color
	loaded: true
	boardId: string
	viewModeEnabled: boolean
}
type NotLoadedProps = { color: Color; loaded: false; viewModeEnabled: boolean }
type Props = LoadedProps | NotLoadedProps

const ExcalidrawContainer: FC<Props> = props => {
	const user = useMyTrainingProfile()
	const boardId = props.loaded ? props.boardId : undefined
	const roomId = useMemo(
		() => (boardId ? { id: boardId } : undefined),
		[boardId]
	)

	return (
		<MediaContainer colorHex={colorToHex[props.color]}>
			{props.loaded ? (
				<Excalidraw
					key={props.boardId}
					viewModeEnabled={props.viewModeEnabled}
					collabServerUrl={Config.EXCALIDRAW_SERVER_URL}
					assetsServerUrl={Config.EXCALIDRAW_ASSETS_SERVER_URL}
					collabRoom={roomId}
					isCollaborating={true}
					allowForceFollow={user.isTrainer}
					user={user}
				/>
			) : (
				<Spinner color={Colors.indigoBlue} />
			)}
		</MediaContainer>
	)
}

export default ExcalidrawContainer
