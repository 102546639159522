export const MessagesIcon = (): JSX.Element => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="20.001"
		height="20"
		viewBox="0 0 20.001 20"
	>
		<g id="message-chat-right-text-comments" transform="translate(-3 -3)">
			<path
				id="Path_12416"
				data-name="Path 12416"
				d="M3,6.333A3.333,3.333,0,0,1,6.333,3H19.667A3.333,3.333,0,0,1,23,6.333V22.444a.556.556,0,0,1-.85.471l-3.419-2.137H6.333A3.333,3.333,0,0,1,3,17.445Z"
				transform="translate(0 0)"
				fill="currentColor"
			/>
			<line
				id="Line_831"
				data-name="Line 831"
				x2="8"
				transform="translate(9 10)"
				fill="none"
				stroke="#fff"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
			/>
			<line
				id="Line_832"
				data-name="Line 832"
				x1="8"
				transform="translate(9 14)"
				fill="none"
				stroke="#fff"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
			/>
		</g>
	</svg>
)
