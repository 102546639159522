import { useEffect, useRef } from 'react'
import { VideoStreamMedia } from '../model/types'

type Props = {
	media: VideoStreamMedia
	className?: string
}
export const AudioStream = ({ media, className }: Props): JSX.Element => {
	const { stream } = media
	const audioRef = useRef<HTMLAudioElement>(null)

	useEffect(() => {
		if (audioRef.current) {
			audioRef.current.srcObject = stream
		}
	}, [stream])

	return (
		<audio autoPlay ref={audioRef} className={className} muted={media.muted} />
	)
}
