import { FC, useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import { Colors } from 'common/presentation/colors'
import Podium from 'room/training-room/ice-breaker/presentation/podium'
import WinnersList from 'room/training-room/ice-breaker/presentation/winners-list'
import UserRanking from 'room/training-room/ice-breaker/presentation/user-ranking'
import { useSelector } from 'react-redux'
import { RootState } from 'common/redux'
import { useWorkflowUser } from 'room/common/use-workflow-user'
import NoWinnersBanner from 'room/training-room/ice-breaker/presentation/no-winners-banner'
import { useAuthenticatedBeeldayClient } from 'connectivity/beelday-hooks'
import { UserRole } from 'common/types'
import { translate } from 'intl/translate'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import useFetchMissingProfiles from 'users/use-fetch-missing-profiles'
import { selectActiveQuiz } from '../redux'
import { ui } from '@beelday/common'

const Container = styled.div`
	box-shadow: inset 0 3px 6px 0 rgba(0, 0, 0, 0.3);
	width: 100%;
	background-color: ${Colors.white};
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
`

const TrainerActionsContainer = styled.div`
	background-color: ${Colors.indigoBlue};
	display: flex;
	justify-content: center;
`

const QuizRanking: FC = () => {
	const beeldayClient = useAuthenticatedBeeldayClient()
	const roomAddress = useAssertedJoinedRoomAddress()
	const user = useWorkflowUser()
	const profiles = useSelector((state: RootState) => state.users.all)
	const quiz = useSelector(selectActiveQuiz)
	const playerUserIds = useMemo(() => {
		if (quiz?.phase === 'quiz-concluded') {
			return quiz.ranking.players.map(p => p.id)
		}
	}, [quiz])

	useFetchMissingProfiles(playerUserIds || [])

	const finish = useCallback(
		(quizId: string) => {
			beeldayClient.finishQuiz({ ...roomAddress, quizId })
		},
		[beeldayClient, roomAddress]
	)

	if (quiz?.phase !== 'quiz-concluded' || !user) return null

	const { ranking } = quiz

	const userRanking = ranking.players.find(p => p.id === user.id)

	const isWinner = userRanking?.place === 1
	const quizId = quiz.quizId
	return (
		<Container>
			{ranking.thereAreNoWinners ? (
				<NoWinnersBanner />
			) : (
				<Podium
					winner={isWinner ? userRanking : undefined}
					first={ranking.firstPlace}
					second={ranking.secondPlace}
					third={ranking.thirdPlace}
					profiles={profiles}
					total={ranking.totalQuestions}
				/>
			)}
			<WinnersList
				noWinners={ranking.thereAreNoWinners}
				players={ranking.players}
				profiles={profiles}
				total={ranking.totalQuestions}
			/>
			{userRanking && (
				<UserRanking
					noWinners={ranking.thereAreNoWinners}
					ranking={userRanking}
					total={ranking.totalQuestions}
				/>
			)}
			{user.role === UserRole.UPPER_ECHELON && (
				<TrainerActionsContainer>
					<ui.ButtonPrimary
						onClick={() => finish(quizId)}
						style={{ width: '100%' }}
					>
						{translate('quiz.finish')}
					</ui.ButtonPrimary>
				</TrainerActionsContainer>
			)}
		</Container>
	)
}

export default QuizRanking
