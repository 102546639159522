import { RootState } from 'common/redux'
import { QuizUser } from 'common/types'
import { useAuthenticatedBeeldayClient } from 'connectivity/beelday-hooks'
import { translate } from 'intl/translate'
import { FC, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import {
	AnswerResult,
	QuizUserProfile,
} from 'room/training-room/ice-breaker/presentation/answer-stats-button'
import LeadableQuizQuestion from 'room/training-room/ice-breaker/presentation/leadable-quiz-question'
import ProgressingIceBreaker from 'room/training-room/ice-breaker/presentation/progressing-ice-breaker'
import QuizRanking from 'room/training-room/ice-breaker/quiz-ranking'
import useFetchMissingProfiles from 'users/use-fetch-missing-profiles'
import { assertUnreachable } from 'utils/unreachable'
import { SceneVideoUser } from 'video-conference-media'
import { selectActiveQuiz } from '../redux'

type Props = {
	streams: SceneVideoUser[]
}
const ConfiguredIceBreakerTrainer: FC<Props> = ({ streams }) => {
	const quiz = useSelector(selectActiveQuiz)
	const beeldayClient = useAuthenticatedBeeldayClient()
	const roomAddress = useAssertedJoinedRoomAddress()
	const profiles = useSelector((state: RootState) => state.users.all)
	const answeringUserIds = useMemo(() => {
		if (
			quiz?.phase === 'question-answered' ||
			quiz?.phase === 'question-completed'
		) {
			return Object.values(quiz.state.selectedAnswers)
				.flatMap(u => u)
				.map(u => u.id)
		}
	}, [quiz])

	useFetchMissingProfiles(answeringUserIds || [])

	const toQuizUserProfiles = useCallback(
		(quizUsers: QuizUser[]): QuizUserProfile[] => {
			return quizUsers
				.map(quizUser => {
					const profile = profiles[quizUser.id]
					return profile
						? {
								...profile,
								...quizUser,
						  }
						: undefined
				})
				.filter(p => p !== undefined) as QuizUserProfile[]
		},
		[profiles]
	)

	const nextQuestion = useCallback(
		(quizId: string) => {
			beeldayClient.nextQuizQuestion({ ...roomAddress, quizId })
		},
		[beeldayClient, roomAddress]
	)

	const finishQuestion = useCallback(
		(quizId: string) => {
			beeldayClient.finishQuizQuestion({ ...roomAddress, quizId })
		},
		[beeldayClient, roomAddress]
	)

	const concludeQuiz = useCallback(
		(quizId: string) => {
			beeldayClient.concludeQuiz({ ...roomAddress, quizId })
		},
		[beeldayClient, roomAddress]
	)

	const abortQuiz = useCallback(
		(quizId: string) => {
			beeldayClient.finishQuiz({ ...roomAddress, quizId })
		},
		[beeldayClient, roomAddress]
	)

	const answerResults = useMemo((): AnswerResult[] | undefined => {
		if (!quiz) {
			return undefined
		}
		switch (quiz.phase) {
			case 'question-asked':
				return Object.entries(quiz.state.answerTexts).map(([id, text]) => ({
					id,
					text,
					playersWhoSelectedThisAnswer: [],
				}))
			case 'question-answered':
				return Object.entries(quiz.state.answerTexts).map(([id, text]) => ({
					id,
					text,
					playersWhoSelectedThisAnswer: toQuizUserProfiles(
						quiz.state.selectedAnswers[id]
					),
				}))
			case 'question-completed':
				return Object.entries(quiz.state.answerTexts).map(([id, text]) => ({
					id,
					text,
					playersWhoSelectedThisAnswer: toQuizUserProfiles(
						quiz.state.selectedAnswers[id]
					),
					correct: quiz.state.correctAnswers.includes(id),
					percentOfAllAnswers:
						quiz.state.numberOfAnswers !== 0
							? (100 * (quiz.state.selectedAnswers[id] || []).length) /
							  quiz.state.numberOfAnswers
							: 0,
				}))
			case 'quiz-concluded':
				return
			default:
				assertUnreachable('QuizPhase', quiz)
		}
	}, [quiz, toQuizUserProfiles])

	if (!quiz) {
		return null
	}

	if (quiz.phase === 'quiz-concluded') {
		return (
			<ProgressingIceBreaker
				sceneVideoUsers={streams}
				title={translate('quiz.ranking')}
			>
				<QuizRanking />
			</ProgressingIceBreaker>
		)
	} else if (answerResults) {
		const quizId = quiz.quizId
		return (
			<ProgressingIceBreaker
				sceneVideoUsers={streams}
				title={translate('question')}
				progress={{
					currentValue: quiz.state.number,
					maxValue: quiz.state.totalQuestions,
				}}
			>
				<LeadableQuizQuestion
					text={quiz.state.text}
					imageUrl={
						quiz.state.imageId && beeldayClient.quizImgUrl(quiz.state.imageId)
					}
					results={answerResults}
					responses={
						quiz.phase === 'question-asked'
							? undefined
							: quiz.state.numberOfAnswers
					}
					expectedResponses={
						quiz.phase === 'question-asked'
							? undefined
							: quiz.state.expectedNumberOfAnswers
					}
					onNext={() => nextQuestion(quizId)}
					onCheck={() => finishQuestion(quizId)}
					last={quiz.state.number === quiz.state.totalQuestions}
					onConclude={() => concludeQuiz(quizId)}
					onAbort={() => abortQuiz(quizId)}
				/>
			</ProgressingIceBreaker>
		)
	} else {
		return null
	}
}

export default ConfiguredIceBreakerTrainer
