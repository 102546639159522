type Line = [x1: number, y1: number, x2: number, y2: number]
type BBox = [x1: number, y1: number, x2: number, y2: number]
type Circle = [x: number, y: number, r: number]
type Point = [x: number, y: number]

export const lineCircleCollide = (line: Line, circle: Circle): boolean => {
	const distance = lineCircleCollideDistance(line, circle)
	const cr = circle[2]
	return distance !== null && distance < cr
}

export const lineCircleCollideDistance = (
	line: Line,
	circle: Circle
): number | null => {
	const [x1, y1, x2, y2] = line
	const [cx, cy, cr] = circle
	if (pointCircleCollide(x1, y1, circle)) return 0
	if (pointCircleCollide(x2, y2, circle)) return 0

	const distX = x1 - x2
	const distY = y1 - y2
	const len = Math.sqrt(distX * distX + distY * distY)

	const dot = ((cx - x1) * (x2 - x1) + (cy - y1) * (y2 - y1)) / Math.pow(len, 2)
	const closestX = x1 + dot * (x2 - x1)
	const closestY = y1 + dot * (y2 - y1)

	const collides = (x2 - x1) * (closestY - y1) === (closestX - x1) * (y2 - y1)
	if (collides) {
		const d1 = closestX - cx
		const d2 = closestY - cy
		const distance = Math.sqrt(d1 * d1 + d2 * d2)

		if (distance <= cr) {
			return distance
		}
	}
	return null
}
export const pointBBoxDistance = (point: Point, bbox: BBox): number => {
	const dx1 = point[0] - bbox[0]
	const dx2 = point[0] - bbox[2]
	const dy1 = point[1] - bbox[1]
	const dy2 = point[1] - bbox[3]
	if (dx1 > 0 && dx2 < 0 && dy1 > 0 && dy2 < 0) return 0
	if (dx1 > 0 && dx2 < 0) return Math.min(Math.abs(dy1), Math.abs(dy2))
	if (dy1 > 0 && dy2 < 0) return Math.min(Math.abs(dx1), Math.abs(dx2))
	return Math.min(
		pointPointDistance(point, [bbox[0], bbox[1]]),
		pointPointDistance(point, [bbox[2], bbox[1]]),
		pointPointDistance(point, [bbox[0], bbox[3]]),
		pointPointDistance(point, [bbox[2], bbox[3]])
	)
}

export const pointPointDistance = (p1: Point, p2: Point): number => {
	const [x1, y1] = p1
	const [x2, y2] = p2
	const distX = x1 - x2
	const distY = y1 - y2
	return Math.sqrt(distX * distX + distY * distY)
}

export const pointCircleCollide = (
	px: number,
	py: number,
	circle: Circle
): boolean => {
	// get distance between the point and circle's center
	// using the Pythagorean Theorem
	const distX = px - circle[0]
	const distY = py - circle[1]
	const distance = Math.sqrt(distX * distX + distY * distY)

	if (distance <= circle[2]) {
		return true
	}
	return false
}

export const linePointCollide = (
	line: Line,
	point: [x: number, y: number]
): boolean => {
	const [x1, y1, x2, y2] = line
	const [x, y] = point
	return (x2 - x1) * (y - y1) === (x - x1) * (y2 - y1)
}
