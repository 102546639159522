export type Duration = {
	minutes: number
	seconds: number
}

const SECONDS_PER_MINUTE = 60

export const toDuration = (totalSeconds: number): Duration => {
	const minutes = Math.floor(totalSeconds / SECONDS_PER_MINUTE)
	const seconds = totalSeconds % SECONDS_PER_MINUTE

	return {
		minutes,
		seconds,
	}
}

export const toSeconds = (duration: Duration): number => {
	return duration.minutes * SECONDS_PER_MINUTE + duration.seconds
}
