import { FC, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { filterSceneVideoUsers } from 'room/group-room/model/scene-video-users'
import ProgressingIceBreaker from 'room/training-room/ice-breaker/presentation/progressing-ice-breaker'
import { translate } from 'intl/translate'
import { QuizAnswerId } from 'common/types'
import { useAuthenticatedBeeldayClient } from 'connectivity/beelday-hooks'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { useAssertedWorkflowUser } from 'room/common/use-workflow-user'
import { assertUnreachable } from 'utils/unreachable'
import PlayableQuizQuestion, {
	AvailableAnswer,
} from 'room/training-room/ice-breaker/presentation/playable-quiz-question'
import QuizRanking from 'room/training-room/ice-breaker/quiz-ranking'
import { roomEndUsers } from 'room/common/redux'
import { allStreams } from 'video-conference-media/redux'
import { selectActiveQuiz } from '../redux'

const ConfiguredIceBreakerTrainee: FC = () => {
	const sceneVideoUsers = useSelector(allStreams)
	const endUsers = useSelector(roomEndUsers)
	const sceneVideoEndUsers = filterSceneVideoUsers(endUsers, sceneVideoUsers)
	const quiz = useSelector(selectActiveQuiz)
	const beeldayClient = useAuthenticatedBeeldayClient()
	const roomAddress = useAssertedJoinedRoomAddress()
	const workflowUser = useAssertedWorkflowUser()

	const confirm = useCallback(
		(quizId: string, answerId: QuizAnswerId) => {
			beeldayClient.confirmQuizAnswer({ ...roomAddress, answerId, quizId })
		},
		[beeldayClient, roomAddress]
	)

	const availableAnswers = useMemo((): AvailableAnswer[] | undefined => {
		if (!quiz) {
			return undefined
		}
		switch (quiz.phase) {
			case 'question-asked':
				return Object.entries(quiz.state.answerTexts).map(([id, text]) => ({
					id,
					text,
					confirmed: false,
				}))
			case 'question-answered':
				return Object.entries(quiz.state.answerTexts).map(([id, text]) => ({
					id,
					text,
					confirmed: !!quiz.state.selectedAnswers[id]?.find(
						quizUser => quizUser.id === workflowUser.id
					),
				}))
			case 'question-completed':
				return Object.entries(quiz.state.answerTexts).map(([id, text]) => ({
					id,
					text,
					confirmed: !!quiz.state.selectedAnswers[id]?.find(
						quizUser => quizUser.id === workflowUser.id
					),
					correct: quiz.state.correctAnswers.includes(id),
				}))
			case 'quiz-concluded':
				return
			default:
				assertUnreachable('QuizPhase', quiz)
		}
	}, [quiz, workflowUser.id])

	if (!quiz) {
		return null
	}

	if (quiz.phase === 'quiz-concluded') {
		return (
			<ProgressingIceBreaker
				sceneVideoUsers={sceneVideoEndUsers}
				title={translate('quiz.ranking')}
			>
				<QuizRanking />
			</ProgressingIceBreaker>
		)
	} else if (availableAnswers) {
		return (
			<ProgressingIceBreaker
				sceneVideoUsers={sceneVideoEndUsers}
				title={translate('question')}
				progress={{
					currentValue: quiz.state.number,
					maxValue: quiz.state.totalQuestions,
				}}
			>
				<PlayableQuizQuestion
					text={quiz.state.text}
					availableAnswers={availableAnswers}
					imageUrl={
						quiz.state.imageId && beeldayClient.quizImgUrl(quiz.state.imageId)
					}
					onConfirm={(answerId: QuizAnswerId) => confirm(quiz.quizId, answerId)}
				/>
			</ProgressingIceBreaker>
		)
	} else {
		return null
	}
}

export default ConfiguredIceBreakerTrainee
