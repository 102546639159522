import styled from '@emotion/styled'

export const GrowthColumnContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	min-height: 100px;
	width: 100%;
`

export const GrowthRowContainer = styled.div`
	display: flex;
	flex-grow: 1;
	min-height: 0;
	width: 100%;
	padding: 10px 0;
`

export const MenuContainer = styled.div`
	width: 100%;
`

export const FlexVerticalCenter = styled.div`
	display: flex;
	align-items: center;
`

export const Flex = styled.div`
	display: flex;
`
export const NarrowContentContainer = styled.div`
	max-width: 1600px;
	margin: auto;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	flex: 1;
`
export const CenterFullScreen = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100vw;
	height: 100vh;
`
