import { UUID } from '@beelday/common/lib'

export enum GroupFormingStage {
	SELECT_GROUP,
	CONFIRM_GROUP,
	CONFIRMED,
}

export enum RowPosition {
	TOP = 'top',
	BOTTOM = 'bottom',
	NONE = 'none',
}

export type GroupingType = 'MANUAL' | 'AUTOMATIC'

export type Grouping = {
	id: UUID
	name: string
	description: string
	groupSize: number
	groupingType: GroupingType
}
