import { useDispatch, useSelector } from 'app-redux'
import { RoomAddress } from 'common/types'
import { useEffect } from 'react'
import { KnowledgeCheckID, KnowledgeCheckStats } from './knowledge-check'
import { setStats, knowledgeCheckStats } from './knowledge-checks-redux'
import useKnowledgeChecks from './use-knowledge-checks'

const useStats = (
	roomAddress: RoomAddress,
	knowledgeCheckId?: KnowledgeCheckID
): KnowledgeCheckStats => {
	const dispatch = useDispatch()
	const knowledgeChecks = useKnowledgeChecks()

	const stats = useSelector(knowledgeCheckStats)

	useEffect(() => {
		if (knowledgeCheckId) {
			knowledgeChecks
				.getTrainerStats(roomAddress, knowledgeCheckId)
				.then(fetched => dispatch(setStats(fetched)))
		}
	}, [dispatch, knowledgeChecks, roomAddress, knowledgeCheckId])

	return stats
}

export default useStats
