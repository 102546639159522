export const CameraOff: React.FC = () => (
	<svg
		version="1.1"
		id="Layer_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		width="24"
		height="24"
		viewBox="0 0 48 48"
	>
		<defs>
			<clipPath>
				<rect width="24" height="24" />
			</clipPath>
		</defs>
		<g>
			<path
				fill="currentColor"
				d="M25.6,42.7c-0.6,0.1-1.1,0.1-1.7,0.1H12.1C5.4,42.8,0,37.4,0,30.7v-9.1c0-3.5,1.5-6.8,4.2-9.1L25.6,42.7z"
			/>
			<path
				fill="currentColor"
				d="M36,30.7c0,3.1-1.2,6.1-3.3,8.3l6.4,9h-4.9L0.2,0h4.9l6.7,9.5c0.1,0,0.2,0,0.3,0h11.7
		c6.7,0,12.1,5.4,12.1,12.1v2l12-11.5v28L36,28.7V30.7z"
			/>
		</g>
	</svg>
)
