import { Menu, MenuItemLine } from 'room/common/presentation/menu'
import { MicMenuItem } from 'features/microphone'
import ScreenShareMenuItem from 'features/screen-share/screen-share-with-permission-menu-item'
import { useSelector } from 'react-redux'
import { RootState } from 'common/redux'
import { FocusFeature, TrainingRoomPhase } from 'common/types'
import MiscPanelMenu from '../misc/misc-panel-menu'
import { useQuestions } from '../../features/questions'
import { selectFocusedFeature } from './redux'
import CamMenuItem from 'features/camera/camera-menu-item'
export const isFeatureAcive = (
	phase: TrainingRoomPhase,
	focusedFeature: FocusFeature,
	questionActive: boolean
): boolean => {
	return !(
		(phase === TrainingRoomPhase.INITIAL ||
			phase === TrainingRoomPhase.REVIEWING_TASKS) &&
		!focusedFeature &&
		!questionActive
	)
}

export const TraineeMenu = (): JSX.Element => {
	const focusedFeature = useSelector(selectFocusedFeature)
	const trainingRoomPhase = useSelector(
		(state: RootState) => state.room.trainingRoomPhase
	)
	const questions = useQuestions()

	const featureActive = isFeatureAcive(
		trainingRoomPhase,
		focusedFeature,
		questions.active
	)

	return (
		<Menu>
			<MicMenuItem />
			<CamMenuItem />
			<ScreenShareMenuItem canStart={!featureActive} />
			<MenuItemLine />
			<MiscPanelMenu />
		</Menu>
	)
}

export default TraineeMenu
