export const scrollIntoViewIfNeeded = (
	element: HTMLElement | null | undefined,
	options: ScrollIntoViewOptions = { behavior: 'smooth' }
): void => {
	if (!element) return
	const rect = element.getBoundingClientRect()
	if (rect.bottom > window.innerHeight || rect.top < 0) {
		element.scrollIntoView(options)
	}
}

export const scrollIntoViewAndShake = (
	element: HTMLElement | null | undefined,
	anchor: 'start' | 'end' = 'start',
	offset?: number
): void => {
	if (element) {
		try {
			const observer = new IntersectionObserver(
				() => {
					observer.disconnect()
					element.classList.add('horizontal-shaking')
					setTimeout(() => {
						element.classList.remove('horizontal-shaking')
					}, 2700)
				},
				{
					threshold: 1,
				}
			)
			observer.observe(element)
		} catch (e) {}
		if (offset) {
			const parent = getScrollableParent(element)
			if (parent) {
				const totalOffset = getTotalOffset(parent, element)
				const viewportHeight = parent.clientHeight
				const top =
					anchor === 'start'
						? totalOffset.top - offset
						: totalOffset.top + element.offsetHeight - viewportHeight + offset
				parent.scrollTo({ top, behavior: 'smooth' })
			} else {
				element.scrollIntoView({ behavior: 'smooth', block: anchor })
			}
		} else {
			element.scrollIntoView({ behavior: 'smooth', block: anchor })
		}
	}
}

const isScrollable = (e: Element) => {
	const hasScrollableContent = e.scrollHeight > e.clientHeight

	const overflowYStyle = window.getComputedStyle(e).overflowY
	const isOverflowHidden = overflowYStyle.includes('hidden')

	return hasScrollableContent && !isOverflowHidden
}

export const getScrollableParent = (
	e: HTMLElement | null | undefined
): HTMLElement | null | undefined => {
	if (!e || e === document.body) {
		return document.body
	} else if (isScrollable(e)) {
		return e
	} else {
		return getScrollableParent(e.parentElement)
	}
}

export const getTotalOffset = (
	parent: HTMLElement,
	child: HTMLElement
): { top: number; left: number } => {
	let top = 0
	let left = 0
	let current = child
	while (current && current !== parent && current !== document.body) {
		top += current.offsetTop
		left += current.offsetLeft
		current = current.offsetParent as HTMLElement
	}
	return { top, left }
}

export const scrollToError = (
	container: HTMLElement,
	options?: ScrollIntoViewOptions
): void => {
	const hasError = container.querySelector(
		'*[data-has-error="true"]'
	) as HTMLElement
	if (hasError) {
		scrollIntoViewIfNeeded(hasError, options)
	}
}
