import { FC, ReactElement, useCallback, useState } from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'
import styled from '@emotion/styled'
import colors from '../colors'
import { VerticalFlex } from './layout'
import { ExpandArrowSmall } from './arrows'
import { css } from '@emotion/react'
import { FlexCenterAll, FlexColumn } from '.'

const SubMenuTitle = styled.div`
	align-items: center;
	cursor: pointer;
	display: flex;
	color: ${colors.black};
	justify-content: space-between;
	text-decoration: none;

	&.active {
		font-weight: 500;
	}
	.icon {
		width: 16px;
		height: 16px;
		margin-right: 10px;
		margin-left: 10px;
		color: ${colors.heather};
	}
`

const ArrowContainer = styled.div`
	transition: transform 0.3s ease-out;
`

const Title = styled.div`
	font-size: 14px;
	user-select: none;
	white-space: nowrap;
`

const SubItem = styled(NavLink)`
	display: block;
	color: ${colors.black};
	cursor: pointer;
	font-size: 14px;
	text-decoration: none;
	padding-left: 36px;
	padding: 8px 36px;
	user-select: none;
	&.active {
		background-color: ${colors.indigoBlue};
		color: ${colors.white};
		border-radius: 16px;
	}
`

const mainLinkClass = css`
	display: block;
	color: ${colors.black};
	padding: 8px 0;
	cursor: pointer;
	.icon {
		width: 16px;
		height: 16px;
		margin-right: 10px;
		margin-left: 10px;
		color: ${colors.heather};
	}
	&.active {
		background-color: ${colors.indigoBlue};
		color: ${colors.white};
		border-radius: 16px;
		.icon {
			color: ${colors.white};
		}
	}
`

const Collapsable = styled.div<{ collapsed: boolean }>`
	max-height: ${props => (props.collapsed ? '0' : '260px')};
	transition: 0.5s ease-in-out;
	overflow: hidden;
	opacity: ${props => (props.collapsed ? '0' : '1')};
`

export const SubMenuItem: React.FC<{ href: string; exact?: boolean }> = ({
	href,
	exact,
	children,
}) => (
	<SubItem to={href} exact={exact} activeClassName="active">
		{children}
	</SubItem>
)

type SubMenuProps = {
	title: string | ReactElement
	icon: ReactElement
	href: string
	children:
		| ReactElement<typeof SubMenuItem>
		| Array<ReactElement<typeof SubMenuItem>>
}
type SubMenuType = ((props: SubMenuProps) => ReactElement) & {
	Item: typeof SubMenuItem
}
export const SubMenu: SubMenuType = ({ href, title, icon, children }) => {
	const match = useRouteMatch(href)
	const [collapsed, setCollapsed] = useState(!match)

	const toggleCollapse = useCallback(
		() => setCollapsed(!collapsed),
		[collapsed]
	)

	return (
		<FlexColumn style={{ rowGap: '8px' }}>
			<SubMenuTitle
				onClick={toggleCollapse}
				className={match ? 'active' : undefined}
			>
				<VerticalFlex>
					<FlexCenterAll className="icon">{icon}</FlexCenterAll>
					<Title>{title}</Title>
				</VerticalFlex>
				<ArrowContainer
					style={{ transform: collapsed ? undefined : 'rotate(90deg)' }}
				>
					<ExpandArrowSmall />
				</ArrowContainer>
			</SubMenuTitle>
			<Collapsable collapsed={collapsed}>{children}</Collapsable>
		</FlexColumn>
	)
}
SubMenu.Item = SubMenuItem

export const MenuItem: FC<{
	icon: ReactElement
	href: string
	exact?: boolean
}> = ({ icon, href, exact, children }) => (
	<NavLink css={mainLinkClass} to={href} exact={exact} activeClassName="active">
		<VerticalFlex>
			<div className="icon">{icon}</div>
			<Title>{children}</Title>
		</VerticalFlex>
	</NavLink>
)

export const ClickableMenuItem: FC<{
	icon: ReactElement
	onClick: () => void
}> = ({ children, icon, onClick }): JSX.Element => (
	<div css={mainLinkClass}>
		<VerticalFlex onClick={onClick}>
			<div className="icon">{icon}</div>
			<Title>{children}</Title>
		</VerticalFlex>
	</div>
)

const SideMenuContainer = styled.div`
	z-index: 1;
	background-color: ${colors.white};
	box-shadow: 5px 0 20px 0 rgba(0, 0, 0, 0.05);
	padding: 30px 15px;
	width: 242px;
	border-radius: 25px 0 0 0;
	height: 100%;
`
const SideMenuBackground = styled.div`
	display: block;
	background-color: ${colors.indigoBlue};
`
export const SideMenu: React.FC = ({ children }) => (
	<SideMenuBackground>
		<SideMenuContainer>{children}</SideMenuContainer>
	</SideMenuBackground>
)
