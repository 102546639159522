import { Config } from 'common/config'
import { useMemo } from 'react'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { getNotepadsApi } from './notepads-api'

type NotepadHook = {
	createNotepad: (notepadName: string, withFocus: boolean) => Promise<unknown>
	openNotepad: (notepadId: string, withFocus: boolean) => Promise<unknown>
	closeNotepad: (notepadId: string) => Promise<unknown>
	changeCollaboration: (notepadId: string, enabled: boolean) => void
}
export const useNotepads = (): NotepadHook => {
	const notepadsApi = getNotepadsApi(Config.beeldayBackendUrl)
	const roomAddress = useAssertedJoinedRoomAddress()

	return useMemo(
		() => ({
			createNotepad: async (
				notepadName: string,
				withFocus: boolean
			): Promise<void> => {
				return notepadsApi.createNotepad(roomAddress, notepadName, withFocus)
			},
			openNotepad: async (
				notepadId: string,
				withFocus: boolean
			): Promise<void> => {
				return notepadsApi.openNotepad(roomAddress, notepadId, withFocus)
			},
			closeNotepad: (notepadId: string): Promise<void> => {
				return notepadsApi.closeNotepad(roomAddress, notepadId)
			},
			changeCollaboration: (notepadId: string, enabled: boolean) => {
				notepadsApi.changeCollaboration(roomAddress, notepadId, enabled)
			},
		}),
		[notepadsApi, roomAddress]
	)
}
