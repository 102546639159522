import { format, isSameDay, subDays } from 'date-fns'
import { useMemo } from 'react'
import { getFnsLocale } from '../intl'

export const formatRelative = (date: Date): string => {
	const now = new Date()

	if (isSameDay(now, date)) {
		return 'date_format.today'
	} else if (isSameDay(subDays(now, 1), date)) {
		return 'date_format.yesterday'
	} else {
		return format(date, 'yyyy-MM-dd')
	}
}

type DateFormatters = {
	formatTime: (date?: Date | null) => string
	formatDate: (
		date?: Date | null,
		format?: 'LONG' | 'SHORT' | 'RELATIVE'
	) => string
	format: (date: Date | null | undefined, format: string) => string
}
export const useDateFormat = (): DateFormatters => {
	const locale = getFnsLocale()

	return useMemo<DateFormatters>(
		() => ({
			formatTime: date => (date ? format(date, 'p', { locale }) : ''),
			formatDate: (date, formatType = 'SHORT') => {
				if (!date) return ''
				switch (formatType) {
					case 'LONG':
						return format(date, 'eeee - dd LLLL', { locale })
					case 'SHORT':
						return format(date, 'do MMM', { locale })
					case 'RELATIVE':
						return formatRelative(date)
				}
			},
			format: (date, fmt) => (date ? format(date, fmt, { locale }) : ''),
		}),
		[locale]
	)
}

export default formatRelative
