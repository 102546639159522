import { useState } from 'react'

type State = {
	name: string
	metadata?: Record<string, unknown>
}
type Transition = {
	from: State
	to: State
}
type Config = {
	initialState: State
	states: State[]
	transitions?: Transition[]
}

type History = {
	past: State[]
	present: State
	future: State[]
}

type UseStateMachineReturn = {
	state: State
	canGoBack: boolean
	canGoForward: boolean
	changeState: (newState: State) => void
	goBack: () => void
	goForward: () => void
	reset: () => void
}

export const useStateMachine = (config: Config): UseStateMachineReturn => {
	const { initialState } = config
	const [history, setHistory] = useState<History>({
		past: [],
		present: initialState,
		future: [],
	})

	const changeState = (newState: State) => {
		setHistory(prev => ({
			past: [...prev.past, prev.present],
			present: newState,
			future: [],
		}))
	}

	const goBack = () => {
		setHistory(prev => {
			if (prev.past.length === 0) return prev
			const previous = prev.past[prev.past.length - 1]
			const newPast = prev.past.slice(0, prev.past.length - 1)
			return {
				past: newPast,
				present: previous,
				future: [prev.present, ...prev.future],
			}
		})
	}

	const goForward = () => {
		setHistory(prev => {
			if (prev.future.length === 0) return prev
			const next = prev.future[0]
			const newFuture = prev.future.slice(1)
			return {
				past: [...prev.past, prev.present],
				present: next,
				future: newFuture,
			}
		})
	}

	const reset = () => {
		setHistory({
			past: [],
			present: config.initialState,
			future: [],
		})
	}

	return {
		state: history.present,
		changeState,
		goBack,
		goForward,
		reset,
		canGoBack: history.past.length > 0,
		canGoForward: history.future.length > 0,
	}
}

export default useStateMachine
