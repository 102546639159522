import styled from '@emotion/styled'
import { colors, MaybeUserProfile, ui, intl } from '@beelday/common'
import { getDisplayName } from '@beelday/common/lib/user-api'

const Container = styled.div`
	display: flex;
	align-items: center;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 120px;
	background: ${colors.white};
	border-radius: 50px;
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.15);
	padding: 10px 20px;
`

const Text = styled.div`
	font-size: 14px;
	line-height: 20px;
	margin-right: 15px;
`

type Props = {
	onAccept: () => unknown
	onClose: () => unknown
	profile: MaybeUserProfile
}

const ScreenShareTrainerPopup = ({
	onClose,
	onAccept,
	profile,
}: Props): JSX.Element => {
	return (
		<Container>
			<Text>
				<b>{getDisplayName(profile)}</b>&nbsp;
				<intl.Translate>
					room_menu.screen_share.popup.trainer_text
				</intl.Translate>
			</Text>
			<ui.ButtonPrimary
				style={{ height: '30px', padding: '0 20px', marginRight: '15px' }}
				onClick={onAccept}
			>
				<intl.Translate>room_menu.screen_share.popup.accept</intl.Translate>
			</ui.ButtonPrimary>
			<ui.ButtonClose onClick={onClose} />
		</Container>
	)
}

export default ScreenShareTrainerPopup
