import { GrowthColumnContainer } from 'common/presentation/containers'
import { RootState } from 'common/redux'
import { GroupReview, isReviewUser } from 'common/types'
import { useAuthenticatedBeeldayClient } from 'connectivity/beelday-hooks'
import { differenceBy } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { roomEndUsers } from 'room/common/redux'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { useAssertedWorkflowUser } from 'room/common/use-workflow-user'
import {
	findActiveGroup,
	mapToGroupMembers,
} from 'room/training-room/model/review-task'
import { reviewMediaActive } from 'room/training-room/redux'
import { useProfileFor } from 'users/redux'
import { sceneVideoUsersById } from 'video-conference-media/redux'
import ReviewMenuTrainer from './review-menu-trainer'
import ReviewTask from './review-task'

const ConfiguredReviewTask: FC = () => {
	const beeldayClient = useAuthenticatedBeeldayClient()
	const address = useAssertedJoinedRoomAddress()
	const videoUsers = useSelector(sceneVideoUsersById)
	const endUsers = useSelector(roomEndUsers)
	const activeGroupID = useSelector(
		(state: RootState) => state.trainingRoom.reviewActiveGroup
	)

	const whiteboardDefinition = useSelector(
		(state: RootState) => state.whiteboards.whiteboardDefinition
	)

	const notepadDefinition = useSelector(
		(state: RootState) => state.notepads.notepadDefinition
	)

	const notepadSessionId = useSelector(
		(state: RootState) => state.notepads.sessionId
	)

	const activeMedia = useSelector(reviewMediaActive)
	const workflowUser = useAssertedWorkflowUser()
	const workflowUserProfile = useProfileFor(workflowUser.id)

	const [groups, setGroups] = useState<GroupReview[]>([])

	useEffect(() => {
		beeldayClient.getGroupsForReview(address).then(setGroups)
	}, [beeldayClient, address])

	const all = useMemo(() => {
		const groupMembers = mapToGroupMembers(groups)
		const otherEndUsers = differenceBy(endUsers, groupMembers, 'id')
		return [...groupMembers, ...otherEndUsers].filter(isReviewUser)
	}, [groups, endUsers])

	const activeGroup = useMemo(
		() => findActiveGroup(groups, activeGroupID),
		[activeGroupID, groups]
	)

	const nextHandler = useCallback(
		() => beeldayClient.reviewNextGroup(address),
		[beeldayClient, address]
	)

	const selectHandler = useCallback(
		groupId => beeldayClient.reviewSelect(address, groupId),
		[beeldayClient, address]
	)

	const prevHandler = useCallback(
		() => beeldayClient.reviewPrevGroup(address),
		[beeldayClient, address]
	)

	const completeHandler = useCallback(
		() => beeldayClient.completeReview(address),
		[beeldayClient, address]
	)

	let isWhiteboardViewMode = false

	if (whiteboardDefinition?.collaborators && workflowUserProfile?.id) {
		isWhiteboardViewMode = !whiteboardDefinition.collaborators.includes(
			workflowUserProfile.id
		)
	}

	return activeGroup ? (
		<>
			<GrowthColumnContainer>
				<ReviewTask
					activeMedia={activeMedia}
					whiteboardDefinition={whiteboardDefinition}
					isWhiteboardViewMode={isWhiteboardViewMode}
					notepadDefinition={notepadDefinition}
					notepadSessionId={notepadSessionId}
					activeGroup={activeGroup}
					roomAddress={address}
					users={all}
					videoUsers={videoUsers}
					groups={groups}
					onNext={nextHandler}
					onPrev={prevHandler}
					onSelect={selectHandler}
					userProfile={workflowUserProfile}
					isTrainer={true}
				/>
			</GrowthColumnContainer>
			<ReviewMenuTrainer
				group={activeGroup}
				activeMedia={activeMedia}
				onComplete={completeHandler}
			/>
		</>
	) : null
}

export default ConfiguredReviewTask
