export const BackIcon: React.FC = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.401 16.616">
		<g
			id="Group_22441"
			data-name="Group 22441"
			transform="translate(-615.658 -547.007)"
		>
			<path
				id="Path_4870"
				data-name="Path 4870"
				d="M617.774,552.738h14.342a4.943,4.943,0,0,1,4.943,4.943h0a4.943,4.943,0,0,1-4.943,4.942h-2.89"
				fill="none"
				stroke="currentColor"
				strokeMiterlimit="10"
				strokeWidth="2"
			/>
			<path
				id="Path_4871"
				data-name="Path 4871"
				d="M622.042,547.714l-4.97,4.97,5.051,5.051"
				fill="none"
				stroke="currentColor"
				strokeMiterlimit="10"
				strokeWidth="2"
			/>
		</g>
	</svg>
)
