export const UseInIcon: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 17 17"
		xmlSpace="preserve"
	>
		<g transform="translate(0.5 0.5)">
			<g>
				<path
					fill="currentColor"
					d="M11.4,9.3c0,0,0,0.1,0,0.1c0,1.2-0.5,2.4-1.4,3.4l-2.3,2.3c-0.9,0.9-2.2,1.4-3.4,1.4c-1.2,0-2.5-0.5-3.4-1.4
			C0,14.2-0.5,13-0.5,11.7S0,9.2,0.9,8.3L3.2,6C3.5,5.7,4,5.7,4.3,6c0.2,0.1,0.2,0.3,0.2,0.5c0,0,0,0.1,0,0.1c0,0.2-0.1,0.4-0.2,0.5
			L2.1,9.4c-0.6,0.6-0.9,1.4-0.9,2.2c0,0.9,0.3,1.6,0.9,2.2c1.2,1.2,3.3,1.2,4.5,0l2.3-2.3c1.2-1.2,1.2-3.2,0-4.5
			C8.7,7,8.6,6.8,8.6,6.6c0-0.2,0.1-0.4,0.2-0.6C9.2,5.7,9.7,5.7,10,6C10.9,6.9,11.4,8.1,11.4,9.3z"
				/>
			</g>
			<g>
				<path
					fill="currentColor"
					d="M15.1,7.8L12.8,10c-0.2,0.2-0.4,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.2-0.3-0.2-0.5V9.3
			c0-0.2,0.1-0.4,0.2-0.5l2.3-2.3c0.6-0.6,0.9-1.4,0.9-2.2c0-0.9-0.3-1.6-0.9-2.2c-1.2-1.2-3.3-1.2-4.5,0L7.2,4.3
			C6,5.6,6,7.6,7.2,8.8c0.3,0.3,0.3,0.9,0,1.2c-0.3,0.3-0.9,0.3-1.2,0C5.1,9.1,4.6,7.9,4.6,6.7c0,0,0-0.1,0-0.1
			c0-1.2,0.5-2.4,1.4-3.4l2.4-2.4c0.9-0.9,2.1-1.3,3.3-1.3h0.1c1.3,0,2.5,0.5,3.4,1.5C17.1,2.8,17,5.9,15.1,7.8z"
				/>
			</g>
		</g>
	</svg>
)
