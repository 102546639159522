import { useEffect, useState } from 'react'
import { hooks } from '@beelday/common'
import { GroupAddress, GroupReview } from 'common/types'
import { useAuthenticatedBeeldayClient } from 'connectivity/beelday-hooks'

export const useGroupDetails = (
	address: GroupAddress
): GroupReview | undefined => {
	const beeldayClient = useAuthenticatedBeeldayClient()
	const isMounted = hooks.useIsMounted()
	const [details, setDetails] = useState<GroupReview | undefined>()

	useEffect(() => {
		beeldayClient.getGroup(address).then(result => {
			if (result && isMounted()) {
				setDetails(result)
			}
		})
	}, [
		address.workflowId,
		address.interactionSchemeId,
		address.roomType,
		address.roomId,
		address.groupId,
		beeldayClient,
	])

	return details
}
