import styled from '@emotion/styled'
import responsive from './responsive'

type Props = {
	height: string
	color: string
	fillColor: string
	progress: number
	score: string
}

const Score = styled.div`
	display: flex;
	height: fit-content;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	font-weight: 500;
`
const StyledSVG = styled.svg`
	width: 100%;
`

const Bar = styled.rect<{ color: string; width: string; height: string }>`
	width: ${props => props.color};
	fill: ${props => props.color};
`

const ScoreContent = styled.div`
	max-width: 42px;
	min-width: 42px;
	margin-left: 15px;
	font-size: 12px;

	${responsive.smallTablet} {
		font-size: 16px;
	}
`

export const ScoreBar = ({
	progress,
	score,
	color,
	fillColor,
	height,
}: Props): JSX.Element => (
	<Score>
		<StyledSVG style={{ height: height }}>
			<Bar color={color} width="100%" height={height} rx="4px" />
			<Bar color={fillColor} width={`${progress}%`} height={height} rx="4px" />
		</StyledSVG>
		{score ? <ScoreContent>{score}</ScoreContent> : null}
	</Score>
)
