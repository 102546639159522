import styled from '@emotion/styled'
import { UUID } from 'common/types'

const Container = styled.div`
	width: 100%;
	align-items: stretch;
	display: flex;
	flex-direction: column;
	height: 100%;

	beelday-chat {
		height: 100%;
	}
`

type Props = {
	trainingId: UUID
	participantsEmails: string[]
}
export const Chat: React.FC<Props> = ({ trainingId, participantsEmails }) => {
	return (
		<Container>
			<beelday-chat
				trainingId={trainingId}
				participantsEmails={participantsEmails.toString()}
			/>
		</Container>
	)
}

export default Chat
