import { intl, ui, images } from '@beelday/common'
import { RoomAddress } from 'common/types'
import {
	KnowledgeCheckID,
	KnowledgeCheck,
	KnowledgeCheckReview,
} from './knowledge-check'
import { useCallback, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import useKnowledgeChecks from './use-knowledge-checks'

const Title = styled.div`
	font-size: 16px;
	line-height: 22px;
	font-weight: 500;
	margin-bottom: 15px;
`

const KnowledgeCheckRadioListWrapper = styled.div`
	margin-bottom: 30px;
`

type Props = {
	roomAddress: RoomAddress
	onClose: () => void
}

const StartKnowledgeCheck = ({ roomAddress, onClose }: Props) => {
	const knowledgeChecks = useKnowledgeChecks()
	const [knowledgeChecksList, setKnowledgeChecksList] = useState<
		KnowledgeCheck[]
	>([])
	const [knowledgeChecksReviewList, setKnowledgeChecksReviewList] = useState<
		KnowledgeCheckReview[]
	>([])
	const [selected, setSelected] = useState<KnowledgeCheckID>()
	const [selectedForReview, setSelectedForReview] = useState<KnowledgeCheckID>()

	const startKnowledgeCheck = useCallback(() => {
		if (selected) {
			knowledgeChecks
				.startKnowledgeCheck(roomAddress, selected)
				.then(() => onClose())
		}
	}, [knowledgeChecks, onClose, roomAddress, selected])

	const startKnowledgeCheckReview = useCallback(() => {
		if (selectedForReview) {
			knowledgeChecks
				.startKnowledgeCheckReview(roomAddress, selectedForReview)
				.then(() => onClose())
		}
	}, [knowledgeChecks, onClose, roomAddress, selectedForReview])

	useEffect(() => {
		knowledgeChecks
			.getAvailableKnowledgeChecks(roomAddress)
			.then(setKnowledgeChecksList)
		knowledgeChecks
			.getAvailableForReview(roomAddress)
			.then(setKnowledgeChecksReviewList)
	}, [knowledgeChecks, roomAddress])

	return (
		<ui.RightDetailsPanel
			header={
				<ui.RightDetailsPanel.SubTitleAndTitle
					title={
						<intl.Translate>
							room_menu.knowledge_check.start_title
						</intl.Translate>
					}
					subTitle={
						<intl.Translate>
							room_menu.knowledge_check.management_sub_title
						</intl.Translate>
					}
				/>
			}
			onClose={onClose}
		>
			<Title>
				<intl.Translate>room_menu.knowledge_check.start_title</intl.Translate>
			</Title>
			<KnowledgeCheckRadioListWrapper>
				<ui.CheckboxList>
					{knowledgeChecksList.map(option => (
						<ui.CheckboxListItem
							key={option.id}
							type="radio"
							name={option.name}
							value={option.id}
							description={
								<intl.Translate count={option.questions.length}>
									knowledge_check.questions.count
								</intl.Translate>
							}
							checked={selected === option.id}
							imageSrc={images.knowledgeCheckTemplateIcon}
							onChange={checked => {
								if (checked) {
									setSelected(option.id)
									setSelectedForReview(undefined)
								}
							}}
						/>
					))}
				</ui.CheckboxList>
			</KnowledgeCheckRadioListWrapper>

			<Title>
				<intl.Translate>
					room_menu.knowledge_check.start_review_title
				</intl.Translate>
			</Title>
			<KnowledgeCheckRadioListWrapper>
				<ui.CheckboxList>
					{knowledgeChecksReviewList.map(option => (
						<ui.CheckboxListItem
							key={option.id}
							type="radio"
							name={option.name}
							value={option.id}
							description={
								<intl.Translate count={option.questions.length}>
									knowledge_check.questions.count
								</intl.Translate>
							}
							checked={selectedForReview === option.id}
							imageSrc={images.knowledgeCheckTemplateIcon}
							onChange={checked => {
								if (checked) {
									setSelected(undefined)
									setSelectedForReview(option.id)
								}
							}}
						/>
					))}
				</ui.CheckboxList>
			</KnowledgeCheckRadioListWrapper>

			{selected && (
				<ui.ButtonPrimary onClick={startKnowledgeCheck}>
					<intl.Translate>
						room_menu.knowledge_check.start_button
					</intl.Translate>
				</ui.ButtonPrimary>
			)}

			{selectedForReview && (
				<ui.ButtonPrimary onClick={startKnowledgeCheckReview}>
					<intl.Translate>
						room_menu.knowledge_check.start_review_button
					</intl.Translate>
				</ui.ButtonPrimary>
			)}
		</ui.RightDetailsPanel>
	)
}

export default StartKnowledgeCheck
