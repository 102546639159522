import { FC } from 'react'
import styled from '@emotion/styled'
import { circleBorder } from 'common/presentation/cross-browser-css'
import { intl } from '@beelday/common'

type Props = {
	colorHex: string
}

const Circle = styled.div`
	${circleBorder};
	align-items: center;
	border: solid 2px ${(props: { borderColor: string }) => props.borderColor};
	display: flex;
	justify-content: center;
	font-family: Ubuntu;
	font-weight: 500;
	font-size: 16px;
	height: 100%;
	opacity: 0.5;
	text-align: center;
	width: 100%;
	transition: border 0.25s, color 0.25s;
`

const FreeSpace: FC<Props> = ({ colorHex }) => (
	<Circle borderColor={colorHex}>
		<intl.Translate>free-space</intl.Translate>
	</Circle>
)

export default FreeSpace
