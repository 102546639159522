import { ui } from '@beelday/common'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import ThreeColumnsVideoGrid from 'room/training-room/ice-breaker/presentation/three-columns-video-grid'
import { SlideRenderer } from 'slides/slide-renderer'
import { SceneVideoUser } from 'video-conference-media'
import { selectActiveSlideId } from './slide-redux'

export const ConfiguredSlideTrainer: FC<{ streams: SceneVideoUser[] }> = ({
	streams,
}) => {
	const slideId = useSelector(selectActiveSlideId)

	return (
		<ThreeColumnsVideoGrid sceneVideoUsers={streams}>
			{slideId ? <SlideRenderer slideId={slideId} /> : <ui.FullPageSpinner />}
		</ThreeColumnsVideoGrid>
	)
}

export default ConfiguredSlideTrainer
