import { icons, intl } from '@beelday/common'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Colors } from 'common/presentation/colors'
import { RootState } from 'common/redux'
import { MuteIcon } from 'features/microphone'
import { FunctionComponent, useEffect, useRef } from 'react'
import Draggable, { DraggableData } from 'react-draggable'
import { useDispatch, useSelector } from 'react-redux'
import { VideoStream } from 'video-conference-media/presentation/video-stream'
import { setDraggablePosition } from '../redux'
import { selectTrainerVideoMuted } from 'interaction-scheme/redux'
import { VideoStreamAvatarOverlay } from 'common/presentation/video-stream-avatar-overlay'
import { SceneVideoUser } from 'video-conference-media'

type Props = {
	mirrored?: boolean
	sceneVideoUser: SceneVideoUser
	mutedIcon?: boolean
	closeStream: () => void
}

const backgroundOpacity = `
	background: #000;
	content: '';
	height: 100%;
	opacity: 58%;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
`

const DraggableContent = styled.div`
	background: white;
	border: 1px solid ${Colors.darkIndigo};
	border-radius: 5px;
	height: 126px;
	overflow: hidden;
	position: absolute;
	right: 120px;
	top: 70px;
	width: 220px;
	z-index: 100;
`

const DraggableVideo = styled.div`
	&:hover {
		.draggableOverlay {
			opacity: 1;
		}
	}
`

const DraggableOverlay = styled.div`
	align-items: center;
	cursor: pointer;
	display: flex;
	height: 100%;
	justify-content: center;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 0;
	transition: opacity 0.25s;
	width: 100%;

	&:before {
		${backgroundOpacity}
	}
`

const DraggableOverlayContent = styled.div`
	align-items: center;
	border-radius: 50px;
	color: white;
	display: inline-flex;
	overflow: hidden;
	padding: 5px 15px;
	position: relative;

	&:before {
		${backgroundOpacity}
	}
`

const DraggableOverlayText = styled.div`
	font-family: Ubuntu;
	font-size: 10px;
	font-weight: 500;
	line-height: 17px;
	position: relative;
	z-index: 1;
`

const DraggableOverlayIcon = styled.div`
	margin-left: 5px;
	position: relative;
	z-index: 1;
`

const DraggableClose = styled.button`
	align-items: center;
	background: transparent;
	border: 0;
	border-radius: 50%;
	cursor: pointer;
	display: flex;
	height: 24px;
	justify-content: center;
	overflow: hidden;
	position: absolute;
	right: 5px;
	top: 5px;
	width: 24px;
	z-index: 1;

	&:before {
		background: white;
		content: '';
		height: 100%;
		left: 0;
		opacity: 0.5;
		position: absolute;
		top: 0;
		transition: opacity 0.25s;
		width: 100%;
	}

	svg {
		position: relative;
		z-index: 1;
	}

	&:hover {
		&:before {
			opacity: 1;
		}
	}
`

const MuteContainer = styled.div`
	bottom: 4px;
	left: 4px;
	position: absolute;
`

const DraggableStream: FunctionComponent<Props> = ({
	sceneVideoUser,
	closeStream,
	mirrored,
	mutedIcon,
}) => {
	const refDraggableContent = useRef<HTMLDivElement>(null)
	const dispatch = useDispatch()

	const isTrainerVideoMuted = useSelector(selectTrainerVideoMuted)

	const triggerMouseEvent = (
		element: HTMLDivElement | Document | null,
		eventType: string
	) => {
		const mouseEvent = new CustomEvent(eventType, {
			bubbles: true,
			cancelable: true,
		})
		element?.dispatchEvent(mouseEvent)
	}

	useEffect(() => {
		const listener = () => {
			triggerMouseEvent(refDraggableContent.current, 'mouseover')
			triggerMouseEvent(refDraggableContent.current, 'mousedown')
			triggerMouseEvent(document, 'mousemove')
			triggerMouseEvent(refDraggableContent.current, 'mouseup')
			triggerMouseEvent(refDraggableContent.current, 'click')
		}
		addEventListener('resize', listener)
		return () => removeEventListener('resize', listener)
	}, [])

	const draggableStreamPosition = useSelector(
		(state: RootState) => state.room.draggableStreamPosition
	)

	const trackPos = (data: DraggableData) => {
		dispatch(setDraggablePosition({ x: data.x, y: data.y }))
	}

	return (
		<Draggable
			onDrag={(e, data) => trackPos(data)}
			bounds="html"
			position={{
				x: draggableStreamPosition?.x ?? 0,
				y: draggableStreamPosition?.y ?? 0,
			}}
		>
			<DraggableContent
				style={{ display: 'inline-block' }}
				ref={refDraggableContent}
			>
				<DraggableClose onClick={closeStream}>
					<icons.CloseIcon />
				</DraggableClose>
				{sceneVideoUser.stream?.getVideoTracks().length === 0 ? (
					<DraggableVideo>
						<VideoStreamAvatarOverlay userId={sceneVideoUser.userId} />
						<DraggableOverlay className="draggableOverlay">
							<DraggableOverlayContent>
								<DraggableOverlayText>
									<intl.Translate>video.drag_move</intl.Translate>
								</DraggableOverlayText>
								<DraggableOverlayIcon>
									<icons.DraggableIcon />
								</DraggableOverlayIcon>
							</DraggableOverlayContent>
						</DraggableOverlay>
					</DraggableVideo>
				) : (
					<DraggableVideo>
						<VideoStream
							css={css`
								height: 100%;
								left: 0;
								object-fit: cover;
								position: absolute;
								top: 0;
								${mirrored ? 'transform: rotateY(180deg);' : ''}
								width: 100%;
							`}
							userId={sceneVideoUser.userId}
						/>

						{isTrainerVideoMuted && (
							<VideoStreamAvatarOverlay userId={sceneVideoUser.userId} />
						)}

						{mutedIcon ? (
							<MuteContainer>
								<MuteIcon />
							</MuteContainer>
						) : null}
						<DraggableOverlay className="draggableOverlay">
							<DraggableOverlayContent>
								<DraggableOverlayText>
									<intl.Translate>video.drag_move</intl.Translate>
								</DraggableOverlayText>
								<DraggableOverlayIcon>
									<icons.DraggableIcon />
								</DraggableOverlayIcon>
							</DraggableOverlayContent>
						</DraggableOverlay>
					</DraggableVideo>
				)}
			</DraggableContent>
		</Draggable>
	)
}

export default DraggableStream
