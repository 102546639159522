import { chunk, map } from 'lodash'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import useResize from 'utils/hooks/use-resize'
import { SceneVideoUserStream } from 'video-conference-media/presentation/scene-video-user-stream'
import { SceneVideoUser } from 'video-conference-media'

const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	position: relative;
	max-width: 100%;
`

const Row = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
`

const VIDEO_ASPECT_RATIO = 640 / 416

const calculateMaxVideoSize = (
	containerWidth: number,
	containerHeight: number
): { height: number; width: number } => {
	if (containerWidth <= containerHeight * VIDEO_ASPECT_RATIO) {
		return {
			height: Math.floor(containerWidth / VIDEO_ASPECT_RATIO),
			width: containerWidth,
		}
	} else {
		return {
			height: containerHeight,
			width: Math.floor(containerHeight * VIDEO_ASPECT_RATIO),
		}
	}
}

const calculateVideoSize = (
	containerHeight: number,
	containerWidth: number,
	numberOfRows: number,
	numberOfVideosInRow: number
): { height: number; width: number } => {
	const fragmentHeight = containerHeight / numberOfRows
	const fragmentWidth = containerWidth / numberOfVideosInRow

	return calculateMaxVideoSize(fragmentWidth, fragmentHeight)
}

const getNumberOfRows = (noOfVideos: number): number => {
	if (noOfVideos <= 2) return 1
	else if (noOfVideos <= 8) return 2
	else if (noOfVideos <= 21) return 3
	else return 4
}

const calculateStyles = (videoHeight: number, videoWidth: number) =>
	css`
		height: ${videoHeight}px;
		width: ${videoWidth}px;
	`

const VideoContainer = styled.div`
	padding: 5px;
`

type Props = {
	videos: SceneVideoUser[]
}

const EqualVideosContainer = ({ videos }: Props): JSX.Element => {
	const {
		ref: containerRef,
		width: containerWidth,
		height: containerHeight,
	} = useResize()

	const numberOfRows = getNumberOfRows(videos.length)
	const numberOfVideosInRow = Math.ceil(videos.length / numberOfRows)
	const chunked = chunk(videos, numberOfVideosInRow)

	const { height: videoHeight, width: videoWidth } = calculateVideoSize(
		containerHeight,
		containerWidth,
		numberOfRows,
		numberOfVideosInRow
	)

	const videoSizeAndMargins = calculateStyles(videoHeight, videoWidth)

	return (
		<Container ref={containerRef}>
			{map(chunked, (videosInRow, i) => (
				<Row key={i}>
					{map(videosInRow, v => (
						<VideoContainer key={v.userId} css={videoSizeAndMargins}>
							<SceneVideoUserStream sceneVideoUser={v} />
						</VideoContainer>
					))}
				</Row>
			))}
		</Container>
	)
}

export default EqualVideosContainer
