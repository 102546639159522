import { RootState } from 'common/redux'
import { useSelector } from 'react-redux'
import { useAssertedWorkflowUser } from './use-workflow-user'

const useIsTrainer = (): boolean => {
	const training = useSelector((state: RootState) => state.sessionInfo.training)
	const user = useAssertedWorkflowUser()
	const isTrainer = user?.id === training?.upperEchelon.userId

	return isTrainer
}

export default useIsTrainer
