export const Duplicate: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 16.3 17.5"
		xmlSpace="preserve"
	>
		<path
			fill="currentColor"
			d="M13.5,6.5v-1c0,0,0-0.1,0-0.1c0-0.2-0.1-0.3-0.2-0.4L8.5,0.2C8.3,0.1,8.2,0,8,0H0.8C0.3,0,0,0.3,0,0.8v16
					c0,0.4,0.3,0.8,0.8,0.8h9.3c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c3.3,0,6-2.7,6-6C16.3,9.4,15.2,7.6,13.5,6.5z M1.5,16V1.5h6.1
					L12,5.8c0,0,0,0,0,0v0l0,0c-0.5-0.2-1.1-0.2-1.6-0.2c-3.3,0-5.9,2.7-5.9,6c0,1.8,0.8,3.4,2,4.5H1.5z M10.3,16
					c-2.4,0-4.4-2-4.4-4.5s2-4.5,4.4-4.5c2.5,0,4.5,2,4.5,4.5S12.8,16,10.3,16z"
		/>
		<path fill="currentColor" d="M12,5.8L12,5.8L12,5.8C12,5.8,12,5.8,12,5.8z" />
		<path
			fill="currentColor"
			d="M13.1,11.5c0,0.4-0.3,0.8-0.8,0.8h-1.2v1.2c0,0.4-0.3,0.8-0.8,0.8c-0.4,0-0.8-0.3-0.8-0.8v-1.2H8.3c-0.4,0-0.8-0.3-0.8-0.8
		c0-0.4,0.3-0.8,0.8-0.8h1.2V9.5c0-0.4,0.3-0.8,0.8-0.8c0.4,0,0.8,0.3,0.8,0.8v1.2h1.2C12.8,10.8,13.1,11.1,13.1,11.5z"
		/>
		<path fill="currentColor" d="M12,5.8L12,5.8L12,5.8C12,5.8,12,5.8,12,5.8z" />
	</svg>
)
