import * as history from 'history'
import { isString } from 'lodash'

const isSameLocation = (
	l1: string | history.Location,
	l2: string | history.Location
): boolean => {
	if (isString(l1) && isString(l2)) {
		return l1 === l2
	} else if (isString(l1)) {
		return l1 === (l2 as history.Location).pathname
	} else if (isString(l2)) {
		return l2 === (l1 as history.Location).pathname
	} else {
		return (
			l1.pathname === l2.pathname &&
			l1.search === l2.search &&
			l1.hash === l2.hash
		)
	}
}

const patchHistory = (customHistory: history.History): history.History => {
	const origPush = customHistory.push.bind(customHistory)
	const origReplace = customHistory.replace.bind(customHistory)
	let lastLocation = customHistory.location

	customHistory.listen(location => {
		lastLocation = location
	})
	customHistory.push = (pathname: string, state = {}) => {
		if (!isSameLocation(pathname, lastLocation)) {
			const isLocationLike = !isString(pathname)
			origPush(pathname, isLocationLike ? undefined : state)
		}
	}
	customHistory.replace = (pathname: string, state = {}) => {
		if (!isSameLocation(pathname, lastLocation)) {
			const isLocationLike = !isString(pathname)
			origReplace(pathname, isLocationLike ? undefined : state)
		}
	}
	return customHistory
}

export const createBrowserHistory = (basename: string): history.History => {
	const customHistory = history.createBrowserHistory({ basename })
	return patchHistory(customHistory)
}

export const createMemoryHistory = (
	config?: history.MemoryHistoryBuildOptions
): history.History => {
	const customHistory = history.createMemoryHistory(config)
	return patchHistory(customHistory)
}
