import { ui, colors, intl, icons } from '@beelday/common'
import styled from '@emotion/styled'
import { HeadingP } from './heading'

const Button = styled.button`
	border: 1px solid ${colors.borderGray50};
	border-radius: 10px;
	color: ${colors.black};
	cursor: pointer;
	min-height: 130px;
	padding: 20px;
	background-color: ${colors.white};
	transition: background-color 0.25s, border 0.25s, color 0.25s,
		box-shadow 0.25s;

	svg {
		max-width: 30px;
		max-height: 30px;
		width: 100%;
		height: 100%;
		color: ${colors.indigoBlue};
		margin-bottom: 10px;
		transition: color 0.25s;
	}

	&.typeTools {
		svg {
			max-width: 24px;
			max-height: 24px;
		}
	}

	&:hover {
		color: ${colors.white};
		border: 1px solid ${colors.indigoBlue};
		background-color: ${colors.indigoBlue};
		box-shadow: 3px 5.2px 10px 0 rgba(79, 23, 190, 0.2);

		svg {
			color: ${colors.white};
		}
	}
`

const ButtonText = styled.div`
	font-size: 14px;
	line-height: 17px;
	text-align: center;
`

type Props = { onManualChose(): unknown; onTemplateChose(): unknown }

export const GroupSelectType: React.FC<Props> = ({
	onManualChose,
	onTemplateChose,
}) => {
	return (
		<div>
			<HeadingP style={{ marginBottom: '30px' }}>
				<intl.Translate>groups.type-title</intl.Translate>
			</HeadingP>
			<ui.FlexRow style={{ alignItems: 'center', margin: '0 -5px' }}>
				<ui.FlexColumn style={{ flex: 1, padding: '0 5px' }}>
					<Button onClick={() => onTemplateChose()} className="typeTools">
						<icons.TrainingTools />
						<ButtonText>
							<intl.Translate>groups.type-tools</intl.Translate>
						</ButtonText>
					</Button>
				</ui.FlexColumn>
				<ui.FlexColumn style={{ flex: 1, padding: '0 5px' }}>
					<Button onClick={() => onManualChose()}>
						<icons.GroupingIcon />
						<ButtonText>
							<intl.Translate>groups.type-manual</intl.Translate>
						</ButtonText>
					</Button>
				</ui.FlexColumn>
			</ui.FlexRow>
		</div>
	)
}

export default GroupSelectType
