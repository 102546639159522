import { useEffect } from 'react'
import { useDispatch, useSelector } from 'app-redux'
import {
	fetchOrganizationById,
	selectBranding,
	selectOrganization,
} from './redux'
import { Branding, Organization, OrganizationId } from './model'

export const useOrganizationBranding = (
	organizationId: OrganizationId | undefined
): Branding => {
	const dispatch = useDispatch()

	useEffect(() => {
		if (organizationId) dispatch(fetchOrganizationById(organizationId))
	}, [dispatch, organizationId])

	return useSelector(state => selectBranding(state))
}

export const useOrganization = (
	organizationId: OrganizationId | undefined
): Organization => {
	const dispatch = useDispatch()

	useEffect(() => {
		if (organizationId) dispatch(fetchOrganizationById(organizationId))
	}, [dispatch, organizationId])

	return useSelector(state => selectOrganization(state))
}
