import BeeldayClient from 'connectivity/beelday-client'
import { AssignMode, PartitionMode, RoomAddress, User } from 'common/types'
import { GroupsSuggestionAssignment } from 'room/training-room/set-groups/model/groups-suggestion-assignment'

export function isGroupsSelectionStarted(
	groupsSuggestionAssignment: GroupsSuggestionAssignment
): boolean {
	return (
		groupsSuggestionAssignment.getGroupsSuggestionAssignment &&
		groupsSuggestionAssignment.getGroupsSuggestionAssignment.length > 0
	)
}

type SendGroupSuggestionAssignmentProps = RoomAddress & {
	beeldayClient: BeeldayClient
	partitionMode: PartitionMode
	assignMode: AssignMode
	taskName: string
	maxGroupSize?: number
}

export function sendGroupsSuggestionAssignment(
	props: SendGroupSuggestionAssignmentProps
): Promise<void> {
	const { beeldayClient } = props

	const { maxGroupSize } = props
	if (maxGroupSize) {
		return beeldayClient.groupSuggestionWithMaxGroupSize({
			...props,
			maxGroupSize,
		})
	} else {
		return beeldayClient.groupSuggestionWithoutMaxGroupSize(props)
	}
}

const minGroupsCount = 2
const minGroupsSize = 2
const minimalUserCountToStartGroupWork = minGroupsSize * minGroupsCount

export function isEnoughUsersToStartGroupWork(endUsers: User[]): boolean {
	return endUsers.length >= minimalUserCountToStartGroupWork
}
