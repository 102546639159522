import { Config } from 'common/config'
import Logger from 'common/logger'
import { PublishBitrates } from 'common/publish-bitrates'
import { SpotlightChange, VcrConfig } from 'common/types'
import { isArray, isNil, max, values } from 'lodash'
import { RemoteUserStream } from 'video-conference-media/model/types'
import { JanusNode, getJanusNode, shortJanusName } from './janus-node'
import JunoClient from './juno-client'

type FeedId = string
type JanusUrl = string

type CompareJanusNodesResponse = {
	nodesToSubscribe: JanusUrl[]
	nodesToUnsubscribe: JanusUrl[]
	nodesToPublish: JanusUrl[]
	nodesToUnpublish: JanusUrl[]
}

const ERROR_COUNT_THRESHOLD = 3

export class JunoVCR {
	private logger: Logger
	private janusRoomId: string | undefined = undefined

	private publishNodesIds: JanusUrl[] = []
	private subscribeNodesIds: JanusUrl[] = []

	private januses: Record<JanusUrl, JanusNode> = {}
	private remoteStreams: Record<FeedId, RemoteUserStream> = {}
	private localStream: MediaStream | undefined
	private remoteUserStreamObserver: RemoteUserStreamsObserver | undefined
	private spotlightChangeObserver: SpotlightChangeObserver | undefined
	private reconnectThresholdChangeObserver:
		| ReconnectThresholdChangeObserver
		| undefined
	private mediaStatsCheckInterval: number | undefined
	private bitrate = PublishBitrates.low
	private junoEventListenerInterval: number | undefined

	private junoClient: JunoClient

	private static maxJoinRetries = 100
	private static retryDelay = 1000

	private publish: boolean
	private subscribe: boolean

	private inProgress = false
	private isClosing = false
	public selectedMicrophone: string | undefined
	public selectedCamera: string | undefined
	public cannotConnect = false

	constructor(
		private vcrAddress: string,
		private username: string,
		private token: string,
		vcrConfig?: VcrConfig
	) {
		this.logger = new Logger(`juno[${vcrAddress}]`)
		this.junoClient = new JunoClient(token, username, vcrAddress)
		this.logger.log(`Juno instance created for ${vcrAddress}`)
		this.publish = vcrConfig?.publish ?? true
		this.subscribe = vcrConfig?.subscribe ?? true
	}

	configure = async (config: VcrConfig): Promise<void> => {
		if (config.subscribe !== this.subscribe) {
			this.subscribe = config.subscribe
			await this.subscribeToNodes(this.subscribeNodesIds)
		}
		if (config.publish !== this.publish) {
			this.publish = config.publish
			await this.publishAndUnpublish(this.publishNodesIds, [])
		}
	}

	private connectionErrors: Record<string, number> = {}
	private connectionError = (type: string) => {
		this.connectionErrors[type] = (this.connectionErrors[type] || 0) + 1
		this.notifyConnectionErrors()
	}
	private connectionSuccess = (type: string) => {
		this.connectionErrors[type] = 0
		this.notifyConnectionErrors()
	}
	private notifyConnectionErrors = (): void => {
		const cannotConnect =
			(max(values(this.connectionErrors)) || 0) >= ERROR_COUNT_THRESHOLD
		console.log('cannotConnect', cannotConnect, this.connectionErrors)
		if (cannotConnect !== this.cannotConnect) {
			this.logger.warn(
				'Connection errors countes threshold change',
				this.connectionErrors
			)
			this.cannotConnect = cannotConnect
			this.reconnectThresholdChangeObserver?.(cannotConnect)
		}
	}

	setToken = (token: string): void => {
		this.token = token
		this.junoClient.setToken(token)
	}

	configureTracks = async (stream: MediaStream): Promise<void> => {
		if (this.isClosing) {
			this.logger.log('Juno is closing. Skip configure request')
			return
		}

		this.localStream = stream

		this.distributeToAll(janusUrl => this.reconfigureTracksJanus(janusUrl))
	}

	configureBitrate = async (bitrate: number): Promise<void> => {
		if (this.isClosing) {
			this.logger.log('Juno is closing. Skip configure request')
			return
		}

		if (this.bitrate !== bitrate) {
			this.logger.log(`Switching bitrate on  nodes to ${bitrate}`)
		}

		this.bitrate = bitrate

		this.distributeToAll(this.reconfigureJanus)
	}

	join = async (localStream?: MediaStream, retry = 0): Promise<void> => {
		if (retry > 0) {
			await new Promise(resolve => setTimeout(resolve, JunoVCR.retryDelay))
		}

		if (this.isClosing) {
			this.logger.log('Juno is closing. Skip join request')
			return
		}

		try {
			this.localStream = localStream
			const { room } = await this.junoClient.join()
			this.janusRoomId = room
			this.logger.log(`Joined Juno room ${room}`)

			if (this.isClosing) {
				this.logger.log('Juno is closing. Skip join request')
				return
			}

			this.startJunoEventListener(Config.junoPollInterval)
			this.connectionSuccess('JOIN')
			return
		} catch (error) {
			if (retry <= JunoVCR.maxJoinRetries) {
				return this.join(localStream, retry + 1)
			} else {
				this.logger.error('Error when joining room in Juno:')
				this.logger.error(error)
				this.connectionError('JOIN')
				throw error
			}
		}
	}

	leave = async (): Promise<void> => {
		this.logger.log('User left the room, destroy this Juno connection')
		this.isClosing = true
		this.clearResources()
		await this.distributeToAll(this.deleteJanus)
	}

	startScreenShare = async (
		screenShareStream: MediaStream
	): Promise<unknown> => {
		const track = screenShareStream.getVideoTracks()[0]
		return track
			? this.distributeRequests((janusUrl: string) => {
					const janusNode = this.januses[janusUrl]
					return janusNode?.startScreenShare(track)
			  }, this.publishNodesIds)
			: Promise.reject(
					new Error('Missing video track in screen share media stream')
			  )
	}

	stopScreenShare = async (): Promise<unknown> => {
		return this.distributeRequests((janusUrl: string) => {
			const janusNode = this.januses[janusUrl]
			return janusNode?.stopScreenShare()
		}, this.publishNodesIds)
	}

	private compareJanusNodes = (
		publish: JanusUrl[],
		subscribe: JanusUrl[]
	): CompareJanusNodesResponse => {
		const nodesToPublish = this.withoutDuplicates(
			publish.filter(node => !this.publishNodesIds.includes(node))
		)

		const nodesToUnpublish = this.withoutDuplicates(
			this.publishNodesIds.filter(node => !publish.includes(node))
		)

		const nodesToSubscribe = this.withoutDuplicates(
			subscribe.filter(node => !this.subscribeNodesIds.includes(node))
		)

		const nodesToUnsubscribe = this.withoutDuplicates(
			this.subscribeNodesIds.filter(node => !subscribe.includes(node))
		)

		return {
			nodesToPublish,
			nodesToUnpublish,
			nodesToSubscribe,
			nodesToUnsubscribe,
		}
	}

	private withoutDuplicates = (list: JanusUrl[]): JanusUrl[] => {
		return [...new Set(list)]
	}

	private missingUsersCheck: Promise<unknown> | undefined
	private checkForMissingUsers = async (): Promise<void> => {
		if (this.missingUsersCheck) {
			this.logger.warn('Previous missing users check did not finish yet')
		} else {
			this.missingUsersCheck = Promise.allSettled(
				Object.values(this.januses).map(janus => janus.reconnectMissingUsers())
			)
				.then(res =>
					res.forEach(r => {
						if (r.status === 'rejected') {
							throw r.reason
						}
					})
				)
				.then(() => this.connectionSuccess('MISSING_USERS'))
				.catch(e => {
					this.logger.error('Failed to reconnect missing users', e)
					this.connectionError('MISSING_USERS')
				})
				.finally(() => (this.missingUsersCheck = undefined))
		}
	}
	private pollJuno = async () => {
		if (this.isClosing) {
			this.logger.log('Juno is closing. Skip syncing')
			return
		}

		this.checkForMissingUsers()

		let response
		try {
			response = await this.junoClient.poll()
		} catch (error) {
			this.logger.error('Cannot poll Juno')
			return
		}

		if (this.inProgress) {
			this.logger.log('Previous sync did not finished yet. Skip this one')
			return
		}

		this.inProgress = true

		const { publish, subscribe, active } = response

		if (!active) {
			this.logger.error(
				'User no longer active on this call. Juno should be already removed'
			)
			await this.leave()
			return
		}

		if (!isArray(publish) || !isArray(subscribe)) {
			this.logger.error(
				'Error: publish or subscribe nodes are not arrays',
				publish,
				subscribe
			)
			return
		}

		this.logger.log(
			`Syncing with Juno (publish: ${publish
				.map(shortJanusName)
				.join(',')}; subscribe: ${subscribe.map(shortJanusName).join(',')})`
		)

		const {
			nodesToPublish,
			nodesToUnpublish,
			nodesToSubscribe,
			nodesToUnsubscribe,
		} = this.compareJanusNodes(publish, subscribe)

		await this.subscribeToNodes(nodesToSubscribe)

		if (this.isClosing) {
			this.logger.log('Juno is closing. Skip syncing')
			return
		}

		await this.publishAndUnpublish(nodesToPublish, nodesToUnpublish)

		if (this.isClosing) {
			this.logger.log('Juno is closing. Skip syncing')
			return
		}

		await this.unsubscribeFromNodes(nodesToUnsubscribe)

		this.inProgress = false
	}

	private subscribeToNodes = async (nodesToSubscribe: JanusUrl[]) => {
		if (nodesToSubscribe.length) {
			this.logger.log(
				`Start subscribing to ${nodesToSubscribe.length} new nodes`
			)
		}

		const subscribed = await this.distributeRequests(
			this.newJanus,
			nodesToSubscribe
		)

		this.subscribeNodesIds = [...this.subscribeNodesIds, ...subscribed]

		if (this.hasDuplicates(this.subscribeNodesIds)) {
			this.logger.error('Detected duplicate subscribe nodes!')
			this.logger.log(this.subscribeNodesIds)
		}

		if (nodesToSubscribe.length) {
			this.logger.log('Subscribing to nodes ready')
		}
	}

	private hasDuplicates = (nodes: JanusUrl[]): boolean => {
		return nodes.length !== [...new Set(nodes)].length
	}

	private unsubscribeFromNodes = async (nodesToUnsubscribe: JanusUrl[]) => {
		if (nodesToUnsubscribe.length) {
			this.logger.log(
				`Start usubscribing from ${nodesToUnsubscribe.length} stale nodes`
			)
		}

		const unsubscribed = await this.distributeRequests(
			this.deleteJanus,
			nodesToUnsubscribe
		)

		this.subscribeNodesIds = this.subscribeNodesIds.filter(
			node => !unsubscribed.includes(node)
		)

		if (nodesToUnsubscribe.length) {
			this.logger.log('Unsubscribing from nodes ready')
		}
	}

	private publishAndUnpublish = async (
		nodesToPublish: JanusUrl[],
		nodesToUnpublish: JanusUrl[]
	) => {
		const logPublishing = nodesToPublish.length || nodesToUnpublish.length

		if (logPublishing) {
			this.logger.log(
				`Start publishing (${nodesToPublish.length} nodes) and unpublishing (${nodesToUnpublish.length} nodes)`
			)
		}

		if (!this.publish) {
			nodesToPublish = []
			nodesToUnpublish = this.publishNodesIds
		}

		const [published, unpublished] = await Promise.all([
			this.distributeRequests(this.publishToJanus, nodesToPublish),
			this.distributeRequests(this.unpublishFromJanus, nodesToUnpublish),
		])

		const notifyJuno =
			published.length && published.length === nodesToPublish.length

		this.publishNodesIds = [...this.publishNodesIds, ...published].filter(
			node => !unpublished.includes(node)
		)

		if (this.hasDuplicates(this.publishNodesIds)) {
			this.logger.error('Detected duplicate publish nodes!')
			this.logger.log(this.subscribeNodesIds)
		}

		if (notifyJuno) {
			await this.junoClient.publishing()
			this.logger.log('Juno informed about publishing status')
		}

		if (logPublishing) {
			this.logger.log('Publishing and unpublishing ready')
		}
	}

	private distributeRequests = async (
		request: (id: JanusUrl) => unknown,
		nodes: JanusUrl[]
	) => {
		const promises = await Promise.allSettled(nodes.map(request))
		const ok: JanusUrl[] = []

		for (const [index, result] of promises.entries()) {
			const node = nodes[index]

			if (result.status === 'fulfilled') {
				ok.push(node)
			} else {
				this.logger.warn(`Failed to sync ${node}: ${result.reason}`)
			}
		}
		return ok
	}

	private distributeToAll = async (request: (id: JanusUrl) => unknown) => {
		return this.distributeRequests(request, Object.keys(this.januses))
	}

	private startJunoEventListener = (interval: number) => {
		if (this.junoEventListenerInterval) {
			this.logger.error('Juno poll interval not cleaned correctly')
		}
		this.pollJuno()
		this.junoEventListenerInterval = window.setInterval(this.pollJuno, interval)
	}

	private stopJunoEventListener = () => {
		clearInterval(this.junoEventListenerInterval)
		this.junoEventListenerInterval = undefined
	}

	private newJanus = async (janusUrl: JanusUrl) => {
		if (isNil(this.janusRoomId)) {
			throw new Error('JANUS ROOM ID undefined. Cannot connect to new Janus')
		}

		if (isNil(this.bitrate)) {
			throw new Error('Bitrate not specified')
		}

		const onConnectFailed = () => {
			this.logger.error('Failed to connect to Janus. Deleting it', janusUrl)
			this.deleteJanus(janusUrl, true)
			this.connectionError('CONNECT')
		}

		const janusNode = await getJanusNode(
			{
				janusNodeUrl: janusUrl,
				janusVcrId: this.janusRoomId,
				janusUserId: this.username,
			},
			this.username,
			onConnectFailed
		)

		const node = janusUrl
		this.januses[node] = janusNode

		janusNode.onNewRemoteStream(newStream => {
			this.putRemoteStream(newStream)
		})
		janusNode.onUserLeft(feedId => {
			this.deleteRemoteStream(feedId, node)
		})
		janusNode.onSpotlightChange(change => {
			this.changeSpotlight(change.userId, change.spotlight)
		})

		return new Promise((resolve, reject) => {
			janusNode.attach({
				bitrate: this.bitrate,
				localStream: this.localStream,
				successCallback: resolve,
				errorCallback: reject,
			})
		})
			.then(res => {
				this.connectionSuccess('CONNECT')
				return res
			})
			.catch(e => {
				this.connectionError('CONNECT')
				throw e
			})
	}

	private reconfigureTracksJanus = async (janusUrl: JanusUrl) => {
		const janus = this.januses[janusUrl]

		if (isNil(janus)) {
			throw new Error('Janus node designated to delete does not exist')
		}

		if (this.localStream) {
			janus.reconfigureTracks(this.localStream)
			return
		}

		return true // Fire & forget
	}

	private reconfigureJanus = async (janusUrl: JanusUrl) => {
		const janus = this.januses[janusUrl]

		if (isNil(janus)) {
			throw new Error('Janus node designated to delete does not exist')
		}

		if (isNil(this.bitrate)) {
			return
		}

		janus.reconfigure(this.bitrate)

		return true // Fire & forget
	}

	private deleteJanus = async (janusUrl: JanusUrl, force?: boolean) => {
		const janus = this.januses[janusUrl]

		if (isNil(janus)) {
			throw new Error('Janus node designated to delete does not exist')
		}

		this.logger.log(`Trying to remove Janus[${shortJanusName(janusUrl)}]`)
		if (force) {
			janus.disconnect()
		} else {
			await new Promise(resolve => janus.disconnect(resolve))
		}

		this.publishNodesIds = this.publishNodesIds.filter(url => url !== janusUrl)
		this.subscribeNodesIds = this.subscribeNodesIds.filter(
			url => url !== janusUrl
		)

		delete this.januses[janusUrl]
		this.logger.log(`Janus[${shortJanusName(janusUrl)}] removed`)
	}

	private publishToJanus = async (janusUrl: JanusUrl) => {
		const janusNode = this.januses[janusUrl]

		if (isNil(janusNode)) {
			throw new Error(
				`Janus node ${janusUrl} designated to publish does not exist`
			)
		}

		await new Promise((resolve, reject) =>
			janusNode.publish(resolve, reject)
		).catch(e => {
			this.logger.error('Error when publishing to Janus, kill connection', e)
			this.deleteJanus(janusUrl, true)
			throw e
		})
		this.logger.log(`Started publishing on Janus ${janusUrl}`)
	}

	private unpublishFromJanus = (janusUrl: JanusUrl) => {
		const myPublishingJanus = this.januses[janusUrl]

		if (isNil(myPublishingJanus)) {
			throw new Error(
				`Janus node ${janusUrl} designated to unpublish does not exist`
			)
		}

		this.logger.log(`Unpublishing from Janus ${janusUrl}`)

		return new Promise((resolve, reject) =>
			myPublishingJanus.unpublish(resolve, reject)
		)
	}

	private getRemoteUserStreams = (): RemoteUserStream[] => {
		return Object.entries(this.remoteStreams).map(([id, remoteStream]) => {
			return {
				...remoteStream,
				feedId: id,
			}
		})
	}

	private putRemoteStream = (newStream: RemoteUserStream) => {
		const currentStream = this.remoteStreams[newStream.feedId]
		if (
			!currentStream ||
			currentStream !== newStream ||
			newStream.janusId !== currentStream?.janusId
		) {
			this.remoteStreams[newStream.feedId] = newStream
			this.remoteUserStreamObserver?.(this.getRemoteUserStreams())
		}
	}

	private deleteRemoteStream = (feedId: FeedId, janusId: JanusUrl) => {
		const stream = this.remoteStreams[feedId]
		if (stream?.janusId === janusId) {
			delete this.remoteStreams[feedId]
			this.remoteUserStreamObserver?.(this.getRemoteUserStreams())
		}
	}

	onRemoteStreamChange = (observer: RemoteUserStreamsObserver): this => {
		this.remoteUserStreamObserver = observer
		return this
	}

	onSpotlightChange = (observer: SpotlightChangeObserver): this => {
		this.spotlightChangeObserver = observer
		return this
	}

	onReconnectThresholdPassed = (
		observer: ReconnectThresholdChangeObserver | undefined
	): this => {
		this.reconnectThresholdChangeObserver = observer
		return this
	}

	private clearResources = () => {
		this.logger.log('Clear resources')
		this.spotlightChangeObserver = undefined
		this.remoteUserStreamObserver && this.remoteUserStreamObserver([])
		this.remoteUserStreamObserver = undefined
		clearInterval(this.mediaStatsCheckInterval)
		this.mediaStatsCheckInterval = undefined
		this.stopJunoEventListener()
	}

	private changeSpotlight = (userId: string, spotlight: boolean) => {
		this.spotlightChangeObserver?.({ spotlight, userId })
	}

	public _getDebugData = (): {
		publishNodes?: JanusUrl[]
		subscribeNodes?: JanusUrl[]
		roomId?: string | null
	} => {
		return {
			publishNodes: this.publishNodesIds,
			subscribeNodes: this.subscribeNodesIds,
			roomId: this.janusRoomId,
		}
	}
}

export type MediaStreamObserver = (stream: MediaStream) => void
export type NewRemoteUserStreamObserver = (stream: RemoteUserStream) => void
export type DeleteRemoteUserStreamObserver = (feedId: FeedId) => void
export type RemoteUserStreamsObserver = (streams: RemoteUserStream[]) => void
export type SpotlightChangeObserver = (change: SpotlightChange) => void
export type ReconnectThresholdChangeObserver = (cannotConnect: boolean) => void
