import styled from '@emotion/styled'
import { Colors } from 'common/presentation/colors'

export const RoomContainer = styled.div`
	width: 100vw;
	height: 100vh;
	background-color: ${Colors.whiteGray};
	display: flex;
	flex-direction: column;
	overflow: hidden;
	min-height: 0px;
`
