import { drop, take } from 'lodash'

import { css } from '@emotion/react'
import GroupBucket from 'room/training-room/set-groups/presentation/group-bucket'
import { RowPosition } from 'room/training-room/set-groups/types'
import { GroupId, GroupSuggestionAssignment, UserId } from 'common/types'
import styled from '@emotion/styled'
import { SceneVideoUser } from 'video-conference-media'

type Props = {
	droppable: boolean
	groups: GroupSuggestionAssignment[]
	streams: Record<UserId, SceneVideoUser>
	onGroupSelect?: (groupId: GroupId) => void
}

const GroupPickerLayout = (props: Props) => {
	const numberOfGroups = props.groups.length

	return numberOfGroups < 4 ? (
		<SingleRowLayout {...props} />
	) : numberOfGroups <= 8 ? (
		<DoubleRowLayout {...props} />
	) : (
		<TripleRowLayout {...props} />
	)
}

const SingleRowLayout = ({
	droppable,
	groups,
	streams,
	onGroupSelect,
}: Props) => {
	const elementWidth = 100 / groups.length

	return (
		<div
			css={css`
				flex-grow: 1;
				display: flex;
				justify-content: center;
				height: 100%;
				width: 100%;
			`}
		>
			{groups.map((g, i) => (
				<GroupBucket
					droppable={droppable}
					key={g.groupDescription.id}
					group={g}
					index={i}
					streams={streams}
					width={elementWidth}
					onSelect={onGroupSelect}
				/>
			))}
		</div>
	)
}

const DoubleRowLayout = ({
	droppable,
	groups,
	streams,
	onGroupSelect,
}: Props) => {
	const numberOfElementsInFirstRow = Math.ceil(groups.length / 2)
	const topRow = take(groups, numberOfElementsInFirstRow)
	const bottomRow = drop(groups, numberOfElementsInFirstRow)
	const elementWidth = 100 / topRow.length

	return (
		<div
			css={css`
				display: flex;
				flex-direction: column;
				height: 100%;
				width: 100%;
			`}
		>
			<div
				css={css`
					display: flex;
					justify-content: center;
					padding-bottom: 0.5em;
					flex-grow: 1;
					width: 100%;
				`}
			>
				{topRow.map((g, i) => (
					<GroupBucket
						droppable={droppable}
						key={g.groupDescription.id}
						group={g}
						index={i}
						rowPosition={RowPosition.TOP}
						streams={streams}
						width={elementWidth}
						onSelect={onGroupSelect}
					/>
				))}
			</div>
			<div
				css={css`
					display: flex;
					justify-content: center;
					padding-top: 0.5em;
					flex-grow: 1;
					width: 100%;
				`}
			>
				{bottomRow.map((g, i) => (
					<GroupBucket
						droppable={droppable}
						key={g.groupDescription.id}
						group={g}
						index={i}
						rowPosition={RowPosition.BOTTOM}
						streams={streams}
						width={elementWidth}
						onSelect={onGroupSelect}
					/>
				))}
			</div>
		</div>
	)
}

const GrowingGroupsRow = styled.div`
	display: flex;
	justify-content: center;
	padding-bottom: 0.5em;
	flex-grow: 1;
	width: 100%;
`

const TripleRowLayout = ({
	droppable,
	groups,
	streams,
	onGroupSelect,
}: Props) => {
	const numberOfElementsInRow = Math.ceil(groups.length / 3)
	const topRow = take(groups, numberOfElementsInRow)
	const rest = drop(groups, numberOfElementsInRow)
	const midRow = take(rest, numberOfElementsInRow)
	const botRow = drop(rest, numberOfElementsInRow)
	const elementWidth = 100 / topRow.length

	return (
		<div
			css={css`
				display: flex;
				flex-direction: column;
				height: 100%;
				width: 100%;
			`}
		>
			<GrowingGroupsRow>
				{topRow.map((g, i) => (
					<GroupBucket
						droppable={droppable}
						key={g.groupDescription.id}
						group={g}
						index={i}
						rowPosition={RowPosition.NONE}
						streams={streams}
						width={elementWidth}
						onSelect={onGroupSelect}
					/>
				))}
			</GrowingGroupsRow>
			<GrowingGroupsRow>
				{midRow.map((g, i) => (
					<GroupBucket
						droppable={droppable}
						key={g.groupDescription.id}
						group={g}
						index={i + numberOfElementsInRow}
						rowPosition={RowPosition.NONE}
						streams={streams}
						width={elementWidth}
						onSelect={onGroupSelect}
					/>
				))}
			</GrowingGroupsRow>
			<GrowingGroupsRow>
				{botRow.map((g, i) => (
					<GroupBucket
						droppable={droppable}
						key={g.groupDescription.id}
						group={g}
						index={i + numberOfElementsInRow * 2}
						rowPosition={RowPosition.NONE}
						streams={streams}
						width={elementWidth}
						onSelect={onGroupSelect}
					/>
				))}
			</GrowingGroupsRow>
		</div>
	)
}

export default GroupPickerLayout
