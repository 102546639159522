export type UserId = string
export type AuthToken = string

export type RefreshTokens = {
	refreshToken: AuthToken
	refreshTokenExpiration: number
	rememberMe?: boolean
}
export type AccessTokens = {
	accessToken: AuthToken
	accessTokenExpiration: number
}
export type Tokens = RefreshTokens & AccessTokens

export enum AuthGroups {
	UPPER_ECHELON = 'upper-echelons',
	COMPANY_ADMIN = 'company-admin',
	ACCOUNT_OWNER = 'account-owner',
}

export enum UserRoles {
	UPPER_ECHELON = 'UPPER_ECHELON',
	COMPANY_ADMIN = 'COMPANY_ADMIN',
	ACCOUNT_OWNER = 'ACCOUNT_OWNER',
	END_USER = 'END_USER',
}

export type AuthenticatedUser = {
	id: UserId
	email: string
	name: string
	surname: string
	_dev?: boolean
	roles: Array<UserRoles>
}

type InvalidTokens = { type: 'INVALID_TOKENS' }
type ValidTokens = {
	type: 'VALID_TOKENS'
	accessToken: AuthToken
	expirationDate: number
	refreshToken: AuthToken
	refreshTokenExpiration: number
}

export type KeycloakConfig = {
	clientId: string
	authUrl: string
	authRealm: string
}

export type ExpiredTokens = {
	type: 'EXPIRED_TOKENS'
	refreshToken: AuthToken
	refreshTokenExpiration: number
}

export type TokenState = InvalidTokens | ValidTokens | ExpiredTokens

export type GenerateIdentityUrl = (redirectUri?: string) => string

export type Auth = {
	identityUrls: {
		google?: GenerateIdentityUrl
	}
	tokenState: TokenState
	signIn: (
		username: string,
		password: string,
		rememberMe: boolean
	) => Promise<void>
	signOut: () => void
	refresh: (tokens: RefreshTokens) => Promise<Tokens>
}

export class InvalidCredentialsError extends Error {}
