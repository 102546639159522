import styled from '@emotion/styled'
import { ReactNode, useMemo, useState } from 'react'
import colors from '../colors'
import { Translate, useTranslate } from '../intl'
import { AddIconText } from './add-icon-text'
import { ButtonMinor, ButtonPrimary } from './button'
import { FlexEnd } from './containers'
import { DetailsPanel } from './details-panel'
import { Modal } from './modal'
import Search from './search'

const Content = styled.div`
	width: 100%;
`

const SearchContainer = styled.div`
	margin-bottom: 15px;
`

const Results = styled.div`
	max-height: 280px;
	overflow: auto;
	margin-bottom: 5px;
`

const AddButton = styled.button`
	background-color: ${colors.heather05};
	border: 0;
	border-bottom: 1px solid ${colors.brightGray};
	padding: 15px;
	cursor: pointer;

	&:hover {
		.squarePlus {
			background-color: ${colors.indigoBlue};
			color: ${colors.white};
		}
	}
`

const alwaysMatches = () => true
const createFilter = <T extends Record<string, unknown>>(
	field: keyof T,
	query?: string | null
): ((e: T) => boolean) => {
	if (!query) return alwaysMatches

	const rg = new RegExp(query, 'i')
	return (item: T) => {
		const value = item[field]
		const str =
			value && typeof value === 'string' ? value : JSON.stringify(value)

		return rg.test(str)
	}
}

const useFilter = <T extends Record<string, unknown>>(
	field: keyof T,
	query?: string | null
): ((e: T) => boolean) => {
	return useMemo(() => createFilter(field, query), [field, query])
}

type Props = {
	title: string
	onSubmit: () => unknown
	onCancel: () => unknown
	onFilter: (query: string) => unknown
	onCreate?: () => unknown
	createLabel?: string
	children: ReactNode | ReactNode[]
}

export const FindItemPopup = ({
	title,
	onSubmit,
	onCancel,
	onFilter,
	onCreate,
	createLabel,
	children,
}: Props): JSX.Element => {
	const [searchPattern, setSearchPattern] = useState('')
	const translate = useTranslate()

	return (
		<Modal backgroundColor={colors.transparentPaleGray}>
			<DetailsPanel
				onClose={onCancel}
				title={title}
				style={{ width: '620px' }}
				afterTitle={
					onCreate ? (
						<AddButton onClick={onCreate}>
							<AddIconText text={createLabel || translate('create')} />
						</AddButton>
					) : undefined
				}
			>
				<Content>
					<SearchContainer>
						<Search
							placeholder="search"
							value={searchPattern}
							onChange={query => {
								setSearchPattern(query)
								onFilter(query)
							}}
							theme="PRIMARY"
						/>
					</SearchContainer>
					<Results>{children}</Results>
					<FlexEnd>
						<ButtonMinor style={{ marginRight: '15px' }} onClick={onCancel}>
							<Translate>cancel</Translate>
						</ButtonMinor>
						<ButtonPrimary color="indigoBlue" type="button" onClick={onSubmit}>
							<Translate>continue</Translate>
						</ButtonPrimary>
					</FlexEnd>
				</Content>
			</DetailsPanel>
		</Modal>
	)
}

FindItemPopup.useFilter = useFilter
FindItemPopup.createFilter = createFilter

export default FindItemPopup
