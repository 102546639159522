import { FC } from 'react'
import styled from '@emotion/styled'
import { colors, icons } from '@beelday/common'
import {
	KnowledgeCheckOptionID,
	KnowledgeCheckOption,
} from '../knowledge-check'

type Props = {
	options: KnowledgeCheckOption[]
	checked: KnowledgeCheckOptionID[]
	onChange: (checked: KnowledgeCheckOptionID[]) => void
}

const CheckboxList = styled.div`
	margin-top: 20px;
`

const Checkbox = styled.div``

const CheckboxLabel = styled.label`
	border-radius: 11px;
	margin-top: 10px;
	padding: 10px 15px;
	display: flex;
	cursor: pointer;
	align-items: center;
	min-height: 56px;
	border: 1px solid ${colors.brightGray};
	font-size: 16px;
	line-height: 22px;
	color: ${colors.heather};
	outline: 1px solid transparent;
	transition: outline 0.25s, border 0.25s, background-color 0.25s;

	&:hover {
		border: 1px solid ${colors.indigoBlue};
		outline: 1px solid ${colors.indigoBlue};

		.checkboxMark {
			background-color: ${colors.indigoBlue};

			svg {
				opacity: 1;
			}
		}
	}

	&.checked {
		border: 1px solid ${colors.indigoBlue};
		background-color: ${colors.indigoBlue};
		color: ${colors.white};

		.checkboxMark {
			background-color: ${colors.darkIndigo};

			svg {
				opacity: 1;
			}
		}
	}
`

const CheckboxInput = styled.input`
	display: none;
`

const CheckboxMark = styled.div`
	align-items: center;
	background-color: ${colors.borderGray50};
	border-radius: 50%;
	color: ${colors.white};
	display: flex;
	flex: 0 0 22px;
	height: 22px;
	justify-content: center;
	margin-right: 15px;
	transition: background-color 0.25s;
	width: 22px;

	svg {
		opacity: 0;
		transition: opacity 0.25s;
		width: 10px;
	}
`

const KnowledgeCheckAnswers: FC<Props> = ({ options, checked, onChange }) => {
	const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
		let updatedList = [...checked]
		if (event.target.checked) {
			updatedList = [...checked, event.target.value]
		} else {
			updatedList.splice(checked.indexOf(event.target.value), 1)
		}
		onChange(updatedList)
	}

	const isChecked = (option: KnowledgeCheckOptionID) => checked.includes(option)

	return (
		<CheckboxList>
			{options.map(option => (
				<Checkbox key={option.id}>
					<CheckboxInput
						id={`answer_${option.id}`}
						value={option.id}
						type="checkbox"
						onChange={handleCheck}
						checked={isChecked(option.id)}
					/>
					<CheckboxLabel
						htmlFor={`answer_${option.id}`}
						className={isChecked(option.id) ? 'checked' : ''}
					>
						<CheckboxMark className="checkboxMark">
							<icons.IconChecked />
						</CheckboxMark>
						{option.answer}
					</CheckboxLabel>
				</Checkbox>
			))}
		</CheckboxList>
	)
}

export default KnowledgeCheckAnswers
