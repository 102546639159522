import styled from '@emotion/styled'
import { MinusIcon, PlusIcon } from '../icons'
import colors from '../colors'
import { Input } from './input'

const InputWrapper = styled.div`
	width: 100%;
	position: relative;
`

const InputButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${colors.indigoBlue};
	cursor: pointer;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 32px;
	height: 32px;
	border-radius: 50%;
	background-color: ${colors.borderLightGray};
	z-index: 1;
	border: unset;
	box-shadow: unset;

	&:disabled {
		cursor: default;

		svg {
			opacity: 0.5;
		}
	}

	svg {
		max-width: 22px;
		height: 22px;
	}
`

type Props = {
	number: number
	min: number
	onChange: (val: number) => void
}

export const NumberButtonsPicker = ({
	number,
	min,
	onChange,
}: Props): JSX.Element => {
	return (
		<InputWrapper>
			<InputButton
				style={{ left: '15px' }}
				onClick={() => onChange(number > min ? number - 1 : min)}
				disabled={number === min}
			>
				<MinusIcon />
			</InputButton>
			<Input
				style={{ textAlign: 'center', width: '100%' }}
				value={number}
				onChange={e =>
					onChange(
						parseInt(e.currentTarget.value) > min
							? parseInt(e.currentTarget.value) - 1
							: min
					)
				}
			/>
			<InputButton
				style={{ right: '15px' }}
				onClick={() => onChange(number + 1)}
			>
				<PlusIcon />
			</InputButton>
		</InputWrapper>
	)
}
