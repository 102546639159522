import {
	FeatureType,
	FeaturesChangedEvent,
	InteractionSchemeRoomTransferWithCountdownEvent,
	RoomKey,
	UserId,
	UserRole,
	isInteractionSchemeAddress,
} from 'common/types'
import { isUsersTransfer } from './model/interaction-scheme'
import { createSelector } from 'reselect'
import { RootState } from 'common/redux'
import { first } from 'lodash'

const interactionSchemeReducerDefaultState: InteractionSchemeState = {
	joinedRoom: null,
	enabledFeatures: [],
	usersState: {},
}

type ISUserState = {
	audioMuted: boolean
	videoMuted: boolean
}

export interface InteractionSchemeState {
	joinedRoom: RoomKey | null
	enabledFeatures: FeatureType[]
	userTransferWithCountdown?: InteractionSchemeRoomTransferWithCountdownEvent
	usersState: Record<UserId, ISUserState>
}

type InteractionSchemeAction =
	| {
			type: 'SET_IS_ROOM_TRANSFER_WITH_COUNTDOWN'
			event: InteractionSchemeRoomTransferWithCountdownEvent
			userId: UserId
	  }
	| { type: 'CLEAR_IS_ROOM_TRANSFER_WITH_COUNTDOWN' }
	| { type: 'SET_JOINED_ROOM'; roomKey: RoomKey }
	| {
			type: 'SSE_MICROPHONE_MUTED'
			payload: {
				user: UserId
			}
	  }
	| {
			type: 'SSE_MICROPHONE_UNMUTED'
			payload: {
				user: UserId
			}
	  }
	| {
			type: 'SSE_CAMERA_MUTED'
			payload: {
				user: UserId
			}
	  }
	| {
			type: 'SSE_CAMERA_UNMUTED'
			payload: {
				user: UserId
			}
	  }
	| {
			type: 'SSE_FEATURES_CHANGED'
			payload: FeaturesChangedEvent
	  }

export const setJoinedRoom = (roomKey: RoomKey) => {
	return {
		type: 'SET_JOINED_ROOM',
		roomKey,
	}
}

export const setISRoomTransferWithCountdown = (
	event: InteractionSchemeRoomTransferWithCountdownEvent,
	userId: UserId
): InteractionSchemeAction => {
	return { type: 'SET_IS_ROOM_TRANSFER_WITH_COUNTDOWN', event, userId }
}

export const clearISRoomTransferWithCountdown = (): InteractionSchemeAction => {
	return { type: 'CLEAR_IS_ROOM_TRANSFER_WITH_COUNTDOWN' }
}

export const interactionSchemeReducer = (
	state: InteractionSchemeState = interactionSchemeReducerDefaultState,
	action: InteractionSchemeAction
): InteractionSchemeState => {
	switch (action.type) {
		case 'SET_JOINED_ROOM':
			return {
				...state,
				joinedRoom: action.roomKey,
			}
		case 'SET_IS_ROOM_TRANSFER_WITH_COUNTDOWN':
			return {
				...state,
				userTransferWithCountdown: isUsersTransfer(action.event, action.userId)
					? action.event
					: state.userTransferWithCountdown,
			}
		case 'CLEAR_IS_ROOM_TRANSFER_WITH_COUNTDOWN':
			return {
				...state,
				userTransferWithCountdown: undefined,
			}
		case 'SSE_MICROPHONE_MUTED': {
			const userID = action.payload.user

			return {
				...state,
				usersState: {
					...state.usersState,
					[userID]: {
						...state.usersState[userID],
						audioMuted: true,
					},
				},
			}
		}
		case 'SSE_MICROPHONE_UNMUTED': {
			const userID = action.payload.user

			return {
				...state,
				usersState: {
					...state.usersState,
					[userID]: {
						...state.usersState[userID],
						audioMuted: false,
					},
				},
			}
		}
		case 'SSE_CAMERA_MUTED': {
			const userID = action.payload.user

			return {
				...state,
				usersState: {
					...state.usersState,
					[userID]: {
						...state.usersState[userID],
						videoMuted: true,
					},
				},
			}
		}
		case 'SSE_CAMERA_UNMUTED': {
			const userID = action.payload.user

			return {
				...state,
				usersState: {
					...state.usersState,
					[userID]: {
						...state.usersState[userID],
						videoMuted: false,
					},
				},
			}
		}

		case 'SSE_FEATURES_CHANGED':
			if (isInteractionSchemeAddress(action.payload.context)) {
				return {
					...state,
					enabledFeatures: action.payload.enabled,
				}
			} else {
				return state
			}

		default:
			return state
	}
}

export const selectCurrentUserVideoMuted = createSelector(
	(state: RootState) => state.workflow.user,
	(state: RootState) => state.interactionScheme,
	(me, interactionScheme) =>
		(me && interactionScheme?.usersState[me.id]?.videoMuted) || false
)

export const selectCurrentUserMuted = createSelector(
	(state: RootState) => state.workflow.user,
	(state: RootState) => state.interactionScheme,
	(me, interactionScheme) =>
		(me && interactionScheme?.usersState[me.id]?.audioMuted) || false
)

export const selectTrainerVideoMuted = createSelector(
	(state: RootState) => state.room.users,
	(state: RootState) => state.interactionScheme,
	(users, interactionScheme) => {
		const trainer = first(users.filter(u => u.role === UserRole.UPPER_ECHELON))
		return (
			(trainer && interactionScheme.usersState[trainer.id]?.videoMuted) || false
		)
	}
)
export const selectTrainerMuted = createSelector(
	(state: RootState) => state.room.users,
	(state: RootState) => state.interactionScheme,
	(users, interactionScheme) => {
		const trainer = first(users.filter(u => u.role === UserRole.UPPER_ECHELON))
		return (
			(trainer && interactionScheme.usersState[trainer.id]?.audioMuted) || false
		)
	}
)

export const selectEnabledFeatures = (state: RootState): FeatureType[] =>
	state.interactionScheme.enabledFeatures
