import React, { FunctionComponent, useState } from 'react'
import { sampleRate } from './settings'

type ReactAudioContextType = {
	initAudioContext: () => void
	audioContext?: AudioContext
}

export const ReactAudioContext = React.createContext<ReactAudioContextType>({
	initAudioContext: () => undefined,
})

export const AudioContextProvider: FunctionComponent = ({ children }) => {
	const [audioContext, setAudioContext] = useState<AudioContext | undefined>()

	const initAudioContext = () => {
		const context = new AudioContext({ sampleRate })
		setAudioContext(context)
	}

	return (
		<ReactAudioContext.Provider value={{ initAudioContext, audioContext }}>
			{children}
		</ReactAudioContext.Provider>
	)
}
