import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, {
	forwardRef,
	ReactNode,
	useCallback,
	useContext,
	useImperativeHandle,
	useRef,
	useState,
} from 'react'
import { intl, ui } from '..'
import colors from '../colors'
import { useToggle } from '../hooks'
import { contextMenu } from '../images'
import { HandleUnfocused } from './modal'

const CONTEXT_MENU_DOTS_BUTTON_SIZE = 36
const CONTEXT_MENU_DOTS_BUTTON_HALF_OF_SIZE = 18
const CONTEXT_MENU_MODAL_HEIGHT = 85

const Dots = styled.div<{ active: boolean }>`
	align-items: center;
	border-radius: 50%;
	background-color: ${props =>
		props.active ? colors.borderLightGray : 'transparent'};
	cursor: pointer;
	display: flex;
	justify-content: center;
	height: ${CONTEXT_MENU_DOTS_BUTTON_SIZE}px;
	width: ${CONTEXT_MENU_DOTS_BUTTON_SIZE}px;
	position: relative;

	img {
		height: 4px;
		width: 16px;
	}

	&:hover {
		background-color: ${colors.borderLightGray};
	}
`

const Content = styled.div`
	padding: 15px;
`

type Props = {
	children: JSX.Element | JSX.Element[]
	position?: Positions
}

export const ContextMenu = ({ children, position }: Props): JSX.Element => {
	const ref = useRef<HTMLDivElement>(null)
	const [visible, setVisible] = useState(false)
	const { value, toggle, setOff } = useToggle()

	const parentWithScroll = useContext(parentContext)

	const findAvailablePosition = useCallback((): Positions => {
		const currentElement = ref.current

		const relativePos = { top: 0 }

		const parentPos = parentWithScroll?.getBoundingClientRect()
		const childPos = currentElement?.getBoundingClientRect()

		if (parentPos && childPos) {
			relativePos.top = childPos.top - parentPos.top
		}

		if (parentPos === undefined && position) {
			return position
		} else if (relativePos.top - CONTEXT_MENU_MODAL_HEIGHT < 0) {
			return 'bottomRight'
		} else if (position) {
			return position
		}

		return 'bottomLeft'
	}, [parentWithScroll, position])

	const [contextMenuPosition, setContextMenuPosition] = useState<Positions>(
		findAvailablePosition()
	)

	return (
		<ui.Tooltip
			overlay={<intl.Translate>more</intl.Translate>}
			placement="top"
			visible={visible}
			trigger={[]}
		>
			<div
				ref={ref}
				className="context-menu"
				onMouseEnter={() => setVisible(!visible)}
				onMouseLeave={() => setVisible(false)}
				style={{ borderRadius: '50%' }}
			>
				<Dots
					active={value}
					onClick={() => {
						toggle()
						setVisible(false)
						setContextMenuPosition(findAvailablePosition())
					}}
				>
					<img src={contextMenu} alt="menu" />
				</Dots>
				{value ? (
					<ui.Relative
						style={{ display: 'flex' }}
						onMouseEnter={() => setVisible(false)}
					>
						<ContextMenuModalPicker
							position={
								position != contextMenuPosition ? contextMenuPosition : position
							}
							onClose={setOff}
						>
							<Content>{children}</Content>
						</ContextMenuModalPicker>
					</ui.Relative>
				) : null}
			</div>
		</ui.Tooltip>
	)
}

export const ContextMenuItem = styled.div`
	cursor: pointer;
	display: flex;
	margin-bottom: 15px;
	white-space: nowrap;
	transition: color 0.25s;

	&:last-child {
		margin-bottom: 0;
	}

	img {
		height: 16px;
		margin-right: 15px;
		width: 14px;
	}

	span {
		font-size: 14px;
	}

	&:hover {
		color: ${colors.indigoBlue};

		svg,
		a {
			color: ${colors.indigoBlue};
		}
	}
`

export const ContextMenuIcon = styled.div`
	display: flex;
	margin-right: 10px;

	svg {
		color: ${colors.heather};
		height: 16px;
		transition: color 0.25s;
	}
`

export type Positions =
	| 'topLeft'
	| 'topRight'
	| 'bottomLeft'
	| 'bottomRight'
	| 'rightBottom'
	| 'top'
	| 'right'
	| 'bottom'
	| 'left'

const positionStyles = (position?: Positions): string => {
	switch (position) {
		case 'topLeft': {
			return `bottom: calc(100% + ${CONTEXT_MENU_DOTS_BUTTON_SIZE}px);
						right: calc(100% - ${CONTEXT_MENU_DOTS_BUTTON_SIZE}px);
						margin-bottom: 5px;
						`
		}
		case 'topRight': {
			return `bottom: calc(100% + ${CONTEXT_MENU_DOTS_BUTTON_SIZE}px);
						margin-bottom: 5px;`
		}
		case 'bottomLeft': {
			return `margin-top: 5px;
				right: calc(100% - ${CONTEXT_MENU_DOTS_BUTTON_SIZE}px);`
		}
		case 'bottomRight': {
			return 'margin-top: 5px;'
		}

		case 'rightBottom': {
			return `
			top:  -${CONTEXT_MENU_DOTS_BUTTON_SIZE}px;
			left: 100%;
			margin-left: 5px;`
		}

		case 'top': {
			return `right: 0;
				bottom: calc(100% + ${CONTEXT_MENU_DOTS_BUTTON_SIZE}px);
				translate: calc(50% - ${CONTEXT_MENU_DOTS_BUTTON_HALF_OF_SIZE}px);
				margin-bottom: 5px;`
		}

		case 'bottom': {
			return `right: 0;
				translate: calc(50% - ${CONTEXT_MENU_DOTS_BUTTON_HALF_OF_SIZE}px);
				margin-top: 5px;`
		}

		case 'right': {
			return `transform: translateY(calc(-50% - ${CONTEXT_MENU_DOTS_BUTTON_HALF_OF_SIZE}px));
						left: 100%;
						margin-left: 5px;`
		}
		case 'left': {
			return `transform: translateY(calc(-50% - ${CONTEXT_MENU_DOTS_BUTTON_HALF_OF_SIZE}px));
						right: 100%;
						margin-right: 5px;`
		}

		default: {
			return `right: 0`
		}
	}
}

export const ContextMenuModalPicker: React.FC<{
	position?: Positions
	onClose?: () => unknown
	className?: string
}> = ({ position, onClose, className, children }) => {
	return (
		<HandleUnfocused
			onClose={onClose}
			className={className}
			css={css`
				z-index: 2;
			`}
		>
			<div
				css={css`
					background-color: ${colors.white};
					border-radius: 11px;
					box-shadow: 0 1px 6px 0 rgba(160, 153, 167, 0.5);
					position: absolute;
					${position ? positionStyles(position) : ''}
				`}
			>
				{children}
			</div>
		</HandleUnfocused>
	)
}

const parentContext = React.createContext<HTMLDivElement | null>(null)

type ContextMenuParentProps = {
	children: ReactNode
	className?: string
}

export type ContextMenuParentRef = {
	scrollToBottom: () => void
}

export const ContextMenuParent = forwardRef<
	ContextMenuParentRef,
	ContextMenuParentProps
>(({ children, className }, ref) => {
	const divRef = useRef<HTMLDivElement>(null)

	useImperativeHandle(ref, () => ({
		scrollToBottom: () => {
			if (divRef.current) {
				divRef.current.scrollTop = divRef.current.scrollHeight
			}
		},
	}))

	return (
		<div ref={divRef} className={className}>
			<parentContext.Provider value={divRef.current}>
				{children}
			</parentContext.Provider>
		</div>
	)
})

ContextMenuParent.displayName = 'ContextMenuParent'
