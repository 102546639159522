import { FC, useMemo } from 'react'
import { VideoColumns } from 'room/group-room/presentation/video-columns'
import { GrowthRowContainer } from 'common/presentation/containers'
import styled from '@emotion/styled'
import { ui } from '@beelday/common'
import { SceneVideoUser } from 'video-conference-media'

const VideoContainer = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 0;
	width: 200px;

	${ui.responsive.desktop} {
		width: 300px;
	}

	${ui.responsive.desktopFHD} {
		width: 400px;
	}
`

const ChildrenContainer = styled.div`
	width: 100%;
	flex: 1;
`

type Props = {
	sceneVideoUsers: SceneVideoUser[]
}

const ThreeColumnsVideoGrid: FC<Props> = ({ sceneVideoUsers, children }) => {
	const { leftUsers, rightUsers } = useMemo(() => {
		const copy = [...sceneVideoUsers]
		const half = Math.ceil(copy.length / 2)
		const leftUsers = copy.splice(0, half)
		const rightUsers = copy.splice(-half)
		return { leftUsers, rightUsers }
	}, [sceneVideoUsers])

	return (
		<GrowthRowContainer>
			<VideoContainer>
				<VideoColumns sceneVideoUsers={leftUsers} />
			</VideoContainer>
			<ChildrenContainer>{children}</ChildrenContainer>
			<VideoContainer>
				<VideoColumns sceneVideoUsers={rightUsers} />
			</VideoContainer>
		</GrowthRowContainer>
	)
}

export default ThreeColumnsVideoGrid
