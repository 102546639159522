import {
	arrays,
	http,
	logger,
	MaybeUserProfile,
	ui,
	userApi,
} from '@beelday/common'
import { Config } from 'common/config'
import { InteractionSchemeRoomState, UserId } from 'common/types'
import { groupBy, keyBy, map } from 'lodash'
import { FC, Fragment, useEffect, useMemo, useState } from 'react'
import { useJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { useProfilesFor } from 'users/redux'
import useFetchMissingProfiles from 'users/use-fetch-missing-profiles'

const log = logger.create('devtools')

type Payload = {
	eventType: 'IS_STATE_CHANGED'
	id: string
	isPhase: 'BREAK' | null
	timestamp: string
	state: {
		roomStates: InteractionSchemeRoomState[]
	}
}

type Props = {
	pollInterval: number
}
export const DevToolsBackend: FC<Props> = ({ pollInterval }) => {
	const joinedRoomAddress = useJoinedRoomAddress()
	const isAddress = joinedRoomAddress?.interactionSchemeId
	const [isState, setIsState] = useState<Payload | null>(null)
	const [fetchingState, setFetchingState] = useState<
		'LOADING' | 'ERROR' | 'NO_DEBUG' | null
	>(null)

	useEffect(() => {
		if (isAddress) {
			let intervalId: NodeJS.Timer | null = null

			const updateIsState = () => {
				setFetchingState('LOADING')
				fetch(`${Config.beeldayBackendUrl}/api/debug/is/${isAddress}`)
					.then(http.checkStatusAllowNotFound)
					.then(res => {
						if (res.status === 404) {
							setFetchingState('NO_DEBUG')
							if (intervalId) {
								clearInterval(intervalId)
								intervalId = null
							}
							throw new Error('Debug endpoint is not enabled on backend')
						}
						return res
					})
					.then(http.parseBody)
					.then(body => {
						if (body.eventType === 'IS_STATE_CHANGED') {
							setIsState(body)
						} else {
							throw new Error('Invalid data received: ' + JSON.stringify(body))
						}
					})
					.then(() => setFetchingState(null))
					.catch(e => {
						log.error('Failed to fetch live-session IS data', e)
						setFetchingState('ERROR')
					})
			}

			updateIsState()
			intervalId = setInterval(updateIsState, pollInterval)
			return () => {
				if (intervalId) {
					clearInterval(intervalId)
					intervalId = null
				}
			}
		}
	}, [isAddress, pollInterval])

	const userIds = isState?.state.roomStates.reduce((acc, room) => {
		arrays.pushAll(acc, room.users)
		return acc
	}, [] as UserId[])

	useFetchMissingProfiles(userIds || [])
	const profiles = useProfilesFor(userIds || [])
	const profilesById = useMemo(() => keyBy(profiles, 'id'), [profiles])

	const roomsPerType = groupBy(isState?.state.roomStates, 'roomType')

	if (fetchingState === 'NO_DEBUG') {
		return (
			<ui.FlexCenterAll>
				<h2>DEBUG is disabled on backend!</h2>
			</ui.FlexCenterAll>
		)
	} else {
		return (
			<ui.FlexColumn>
				<ui.FlexRow style={{ alignItems: 'center' }}>
					<ui.FillSpace>
						<b>IS phase:</b> {isState?.isPhase}
					</ui.FillSpace>
					<small>
						{fetchingState === 'LOADING'
							? '⏲ Loading'
							: fetchingState === 'ERROR'
							? '☠️ Error'
							: null}
					</small>
				</ui.FlexRow>
				<div style={{ overflowY: 'auto', flex: 1 }}>
					{map(roomsPerType, (rooms, type) => {
						return (
							<Fragment key={type}>
								{rooms.map((room, i) => {
									if (room.users.length) {
										return (
											<RoomState
												key={room.roomId}
												room={room}
												roomName={`${room.roomType} ${i + 1}`}
												profiles={profilesById}
											/>
										)
									} else {
										return null
									}
								})}
							</Fragment>
						)
					})}
				</div>
			</ui.FlexColumn>
		)
	}
}

const RoomState: FC<{
	room: InteractionSchemeRoomState
	roomName: string
	profiles: Record<UserId, MaybeUserProfile>
}> = ({ room, roomName, profiles }) => (
	<div style={{ flexGrow: 1 }}>
		<hr />
		<h3>{roomName}</h3>
		<ul>
			{room.users.map(u => (
				<li key={u}>
					<b>{userApi.getDisplayName(profiles[u]) || u}</b>
				</li>
			))}
		</ul>
	</div>
)

export default DevToolsBackend
