export const RaiseHand: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="22.154"
		height="24"
		viewBox="0 0 22.154 24"
	>
		<path
			data-name="Path 11807"
			d="M21.506 12.938a1.865 1.865 0 0 0-2.713-.07L16.56 15.1a1.1 1.1 0 0 1-1.691.1c-.271-.32-.164-1.306-.088-1.931L15.9 4.062a1.5 1.5 0 1 0-2.98-.363l-1.234 7.231c-.065.18-.2.211-.219-.031l-.42-9.4a1.5 1.5 0 0 0-3 0l-.03 9.469c.008.109-.086.141-.1.016l-1.34-7.39a1.533 1.533 0 0 0-1.743-1.257 1.547 1.547 0 0 0-1.21 1.79l1.189 8.649c0 .154-.051.242-.124.045L2.915 8.079A1.526 1.526 0 0 0 1 7.12a1.552 1.552 0 0 0-.913 1.963L3.2 19.241c.016.056.034.112.051.167l.007.021A6.613 6.613 0 0 0 9.546 24a8.952 8.952 0 0 0 5.323-1.8c1.607-.967 6.754-6.5 6.754-6.5a1.92 1.92 0 0 0-.117-2.762z"
		/>
	</svg>
)
