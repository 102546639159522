import { FunctionComponent, useEffect, useMemo, useState } from 'react'

type Props = {
	initialValue: number
	onFinished: () => void
}

const toTimeString = (seconds: number) => {
	const date = new Date(seconds * 1000)
	if (seconds < 3600) return date.toISOString().substr(14, 5)
	return date.toISOString().substr(11, 8)
}

const toCounterValue = (finishTime: number, currentTime: number) => {
	const value = (finishTime - currentTime) / 1000
	if (value <= 0) {
		return 0
	}
	return value
}

const Timer: FunctionComponent<Props> = ({ initialValue, onFinished }) => {
	const [currentTime, setCurrentTime] = useState(new Date().getTime())
	const finishTime = useMemo(
		() => new Date().getTime() + initialValue * 1000,
		[initialValue]
	)
	const counterValue = useMemo(
		() => toCounterValue(finishTime, currentTime),
		[currentTime, finishTime]
	)

	useEffect(() => {
		if (counterValue === 0) {
			onFinished()
		}
	}, [counterValue, onFinished])

	useEffect(() => {
		const interval = setInterval(
			() => setCurrentTime(new Date().getTime()),
			250
		)
		return () => clearInterval(interval)
	}, [])

	return <>{toTimeString(counterValue)}</>
}

export default Timer
