import { Config } from 'common/config'
import { useMemo } from 'react'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { getMultipleChoicePollingApi } from './multiple-choice-polling-api'
import {
	NewPollingMultipleChoice,
	PollingMultipleChoicePollingDefinition,
	PollingMultipleChoicePollingId,
} from './multiple-choice-polling-models'

type ListPollingHook = {
	getPollingDefinitions: () => Promise<PollingMultipleChoicePollingDefinition[]>
	createPolling: (
		polling: NewPollingMultipleChoice
	) => Promise<PollingMultipleChoicePollingDefinition>
	startPolling: (pollingId: PollingMultipleChoicePollingId) => Promise<void>
	stopPolling: (pollingId: PollingMultipleChoicePollingId) => Promise<void>
}
export const useMultipleChoicePolling = (): ListPollingHook => {
	const multipleChoicePollingApi = getMultipleChoicePollingApi(
		Config.beeldayBackendUrl
	)
	const roomAddress = useAssertedJoinedRoomAddress()

	return useMemo(
		() => ({
			getPollingDefinitions: (): Promise<
				PollingMultipleChoicePollingDefinition[]
			> => {
				return multipleChoicePollingApi.getPollingDefinitions(roomAddress)
			},
			createPolling: (
				polling
			): Promise<PollingMultipleChoicePollingDefinition> => {
				return multipleChoicePollingApi.createMultipleChoice(
					roomAddress,
					polling
				)
			},
			startPolling: (pollingId): Promise<void> => {
				return multipleChoicePollingApi.startPolling(roomAddress, pollingId)
			},
			stopPolling: (pollingId): Promise<void> => {
				return multipleChoicePollingApi.stopPolling(roomAddress, pollingId)
			},
		}),
		[roomAddress, multipleChoicePollingApi]
	)
}
