import _ from 'lodash'

type ExchangedBytes = {
	timestamp: number
	sentAudio: number
	sentVideo: number
	sentAll: number
	receivedAudio: number
	receivedVideo: number
	receivedAll: number
}

type Key = string

function matchingFields<T>(
	objs: T[],
	field: keyof T,
	predicate?: (obj: T) => boolean
) {
	const filter = (obj: T) => obj[field] && (!predicate || predicate(obj))
	return objs.filter(filter).map(s => s[field])
}

function sumBy<T>(objs: T[], field: keyof T, predicate?: (obj: T) => boolean) {
	return _.sum(matchingFields(objs, field, predicate))
}

function meanBy<T>(
	objs: T[],
	field: keyof T,
	predicate?: (obj: any) => boolean
) {
	return _.mean(matchingFields(objs, field, predicate))
}

type Traffic = {
	inKBps: number
	outKBps: number
}

type Metrics = {
	audio: Traffic
	video: Traffic
	all: Traffic
	timestamp: number
}

function toKBps(
	previous: ExchangedBytes | undefined,
	current: ExchangedBytes,
	field: keyof ExchangedBytes
) {
	return previous
		? (current[field] - previous[field]) /
				(current.timestamp - previous.timestamp)
		: 0
}

export default class RtcMetrics {
	private exchangedBytes: Record<Key, ExchangedBytes> = {}
	private currentMetrics: Record<Key, Metrics> = {}

	async capture(key: Key, pc: RTCPeerConnection) {
		const rawReport = await pc.getStats()
		const report = Object.fromEntries(rawReport.entries())
		const streamStats = Object.values(report).filter(s =>
			s['id'].includes('Stream')
		)
		const previous = this.exchangedBytes[key]
		const current: ExchangedBytes = {
			sentAudio: sumBy(streamStats, 'bytesSent', s => s['kinds'] === 'audio'),
			sentVideo: sumBy(streamStats, 'bytesSent', s => s['kind'] === 'video'),
			sentAll: sumBy(streamStats, 'bytesSent'),
			receivedAudio: sumBy(
				streamStats,
				'bytesReceived',
				s => s['kind'] === 'audio'
			),
			receivedVideo: sumBy(
				streamStats,
				'bytesReceived',
				s => s['kind'] === 'video'
			),
			receivedAll: sumBy(streamStats, 'bytesReceived'),
			timestamp: meanBy(streamStats, 'timestamp'),
		}
		this.exchangedBytes[key] = current
		this.currentMetrics[key] = {
			all: {
				inKBps: toKBps(previous, current, 'receivedAll'),
				outKBps: toKBps(previous, current, 'sentAll'),
			},
			audio: {
				inKBps: toKBps(previous, current, 'receivedAudio'),
				outKBps: toKBps(previous, current, 'sentAudio'),
			},
			video: {
				inKBps: toKBps(previous, current, 'receivedVideo'),
				outKBps: toKBps(previous, current, 'sentVideo'),
			},
			timestamp: current.timestamp,
		}
	}

	get current() {
		const currentMetrics = Object.values(this.currentMetrics)
		const total: Metrics = {
			all: {
				outKBps: _.sumBy(currentMetrics, m => m.all.outKBps),
				inKBps: _.sumBy(currentMetrics, m => m.all.inKBps),
			},
			audio: {
				outKBps: _.sumBy(currentMetrics, m => m.audio.outKBps),
				inKBps: _.sumBy(currentMetrics, m => m.audio.inKBps),
			},
			video: {
				outKBps: _.sumBy(currentMetrics, m => m.video.outKBps),
				inKBps: _.sumBy(currentMetrics, m => m.video.inKBps),
			},
			timestamp: _.meanBy(currentMetrics, m => m.timestamp),
		}
		return { ...this.currentMetrics, '#total': total }
	}
}
