import { Color } from 'common/types'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { RootState } from 'common/redux'
import { assertUnreachable } from 'utils/unreachable'
import { useAssertedWorkflowUser } from 'room/common/use-workflow-user'
import { findGroup } from 'interaction-scheme/model/interaction-scheme'

type RoomTransferMessage = {
	message: string
	color: Color
	initialCountdownValue: number
}

export const useRoomTransferMessage = (): RoomTransferMessage | undefined => {
	const intl = useIntl()
	const userTransferWithCountdown = useSelector(
		(state: RootState) => state.interactionScheme.userTransferWithCountdown
	)

	const workflowUser = useAssertedWorkflowUser()

	if (!userTransferWithCountdown) {
		return undefined
	}

	const initialCountdownValue = userTransferWithCountdown.countdown

	switch (userTransferWithCountdown.payload.type) {
		case 'TO_LOBBY':
			return {
				message: intl.formatMessage({ id: 'transfer-to-lobby' }),
				color: Color.WHITE,
				initialCountdownValue,
			}
		case 'TO_TRAINING_ROOM':
			return {
				message: intl.formatMessage({ id: 'transfer-to-training-room' }),
				color: Color.WHITE,
				initialCountdownValue,
			}
		case 'TO_GROUP_ROOM': {
			if (workflowUser.role === 'UPPER_ECHELON') {
				return {
					message: intl.formatMessage({ id: 'transfer-to-group-room' }),
					color: Color.WHITE,
					initialCountdownValue,
				}
			}
			const group = findGroup(userTransferWithCountdown, workflowUser.id)
			if (!group) {
				throw Error(
					`The user ${
						workflowUser.id
					} has not been found in any group in the event: ${JSON.stringify(
						userTransferWithCountdown
					)}`
				)
			}
			return {
				message: `${intl.formatMessage({ id: 'join-group' })} ${group.name}`,
				color: group.color,
				initialCountdownValue,
			}
		}
		case 'TO_PUBLIC_CHAT':
			return {
				message: intl.formatMessage({ id: 'transfer-to-public-chat' }),
				color: Color.WHITE,
				initialCountdownValue,
			}
		case 'TO_TRAINING_ROOM_WITH_GROUP_WORK_RESULTS':
			return {
				message: intl.formatMessage({
					id: 'transfer-to-training-room-review-phase',
				}),
				color: Color.WHITE,
				initialCountdownValue,
			}
		case 'EMPTY':
			return {
				message: intl.formatMessage({ id: 'transfer-empty' }),
				color: Color.WHITE,
				initialCountdownValue,
			}

		default:
			assertUnreachable('TransferTo', userTransferWithCountdown.payload)
	}
}
