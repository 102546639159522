import { FC } from 'react'
import styled from '@emotion/styled'
import { RoomId, User, UserProfile } from 'common/types'
import chatRoomBg from './images/chat-room-background.svg'
import { Colors } from 'common/presentation/colors'
import {
	PublicChatRoomEncouragement,
	PublicChatRoomStatus,
	PublicChatRoomType,
} from './model'
import Translate from 'intl/translate'
import { ui } from '@beelday/common'
import Users from './chat-room-users'
import Engagement from './engagement'
import rooms from './rooms-presentation'
import RoomContainer from './room-container'

const Logo = styled.img`
	margin-top: 5px;

	${ui.responsive.desktop1440x768} {
		margin-top: 25px;
	}

	${ui.responsive.desktop1440x900} {
		margin-top: 50px;
	}
`

const Name = styled.div`
	color: ${Colors.black};
	font-family: Ubuntu;
	font-size: 16px;
	font-weight: 500;
	margin-top: 12px;
	text-align: center;

	${ui.responsive.desktop1440} {
		font-size: 18px;
		margin-top: 16px;
	}
`

const EmptyState = styled.div`
	display: flex;
	align-items: center;
	flex: 1;
	margin-top: auto;
	overflow: hidden;
	padding-top: 20px;

	img {
		height: 100%;
		max-width: 200px;
		max-height: 140px;
		width: 100%;
	}
`

type ChatRoom = {
	id: RoomId
	status?: PublicChatRoomStatus
	hobby?: string
	encouragement?: PublicChatRoomEncouragement
	type?: PublicChatRoomType
	users: (User & Partial<UserProfile>)[]
}

type Props = {
	room: ChatRoom
	onSelect: () => void
}

const OtherRoom: FC<Props> = ({ room, onSelect }) => {
	return room.type ? (
		<RoomContainer onSelect={onSelect}>
			<Logo src={rooms[room.type].img} />
			<Name>
				<Translate>{rooms[room.type].name}</Translate>
			</Name>
			<Engagement
				encouragement={room.encouragement}
				numberOfUsersInRoom={room.users.length}
			/>
			{room.users.length === 0 ? (
				<EmptyState>
					<img src={room.type && rooms[room.type].emptyImg} alt="Empty State" />
				</EmptyState>
			) : (
				<Users users={room.users} roomType={room.type} onJoin={onSelect} />
			)}
		</RoomContainer>
	) : null
}

export default OtherRoom
