import { FC } from 'react'
import styled from '@emotion/styled'
import { Colors } from 'common/presentation/colors'
import { Avatar, QuizUser } from 'common/types'
import CustomTooltip from 'common/presentation/custom-tooltip'
import ProfileAvatar from 'users/profile-avatar'
import { ui } from '@beelday/common'
const Container = styled.div`
	height: 45px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-items: center;
	align-content: stretch;
	border: solid 2px ${Colors.brightGray};
	border-radius: 11px;
	background-color: ${Colors.paleGray30};
	position: relative;
	overflow: hidden;
	margin-bottom: 10px;

	${ui.responsive.desktop1440} {
		height: 55px;
	}
`

type ChartBarProps = {
	percent: number
	correct: boolean
}

const ChartBar = styled.div`
	position: absolute;
	width: ${(props: ChartBarProps) => props.percent}%;
	height: 100%;
	background-color: ${(props: ChartBarProps) =>
		props.correct
			? Colors.transparentGreenishCyan
			: Colors.transparentStrongPink};
	z-index: 1;
`

const RespondentContainer = styled.div`
	flex-grow: 0;
	display: flex;
	justify-content: center;
	justify-items: stretch;
	align-items: center;
	padding: 0 15px;
	z-index: 2;
`

const NoRespondents = styled.div`
	width: 28px;
	height: 28px;
	border: dashed 1px ${Colors.black};
	opacity: 0.2;
	border-radius: 100px;
	display: flex;
	justify-items: center;
	justify-content: center;
	align-items: center;
	font-family: Ubuntu;
	font-size: 13px;
	font-weight: 500;
`

const Content = styled.div`
	flex-grow: 1;
	font-family: Ubuntu;
	font-weight: 500;
	font-size: 15px;
	color: ${Colors.heather};
	z-index: 2;

	${ui.responsive.desktop1440} {
		font-size: 16px;
	}
`

const Percents = styled.div`
	flex-grow: 0;
	font-family: Ubuntu;
	font-weight: 500;
	font-size: 15px;
	color: ${Colors.black};
	z-index: 2;
	padding: 0 16px;

	${ui.responsive.desktop1440} {
		font-size: 16px;
	}
`

type PlaceholderProps = {
	movedLeft?: boolean
}

const Respondent = styled(ProfileAvatar)<{ movedLeft?: boolean }>`
	width: 28px;
	height: 28px;
	border: 2px solid ${Colors.white};
	border-radius: 50%;
	margin-left: ${props => (props.movedLeft ? '-10px' : '0px')};
`

const MoreRespondents = styled.div`
	width: 28px;
	height: 28px;
	border-radius: 100px;
	display: flex;
	justify-items: center;
	justify-content: center;
	align-items: center;
	font-family: Ubuntu;
	font-size: 13px;
	font-weight: 500;
	background-color: ${Colors.indigoBlue};
	border: 2px solid ${Colors.white};
	margin-left: ${(props: PlaceholderProps) =>
		props.movedLeft ? '-10px' : '0px'};
	color: ${Colors.white};
`

export type QuizUserProfile = QuizUser & {
	avatar?: Avatar
}

export type AnswerResult = {
	text: string
	correct?: boolean
	playersWhoSelectedThisAnswer: QuizUserProfile[]
	percentOfAllAnswers?: number
}

const Respondents: FC<Pick<AnswerResult, 'playersWhoSelectedThisAnswer'>> = ({
	playersWhoSelectedThisAnswer,
}) => {
	const players = playersWhoSelectedThisAnswer.length
	if (players === 0) {
		return <NoRespondents>0</NoRespondents>
	}
	if (players <= 4) {
		return (
			<>
				{playersWhoSelectedThisAnswer.map((profile, index) => (
					<Respondent
						key={profile.id}
						movedLeft={index > 0}
						profile={profile}
					/>
				))}
			</>
		)
	}
	const amountAboveThree = players - 3
	return (
		<>
			{playersWhoSelectedThisAnswer.map(
				(profile, index) =>
					index < 3 && (
						<Respondent
							key={profile.id}
							movedLeft={index > 0}
							profile={profile}
						/>
					)
			)}
			<MoreRespondents movedLeft>+{amountAboveThree}</MoreRespondents>
		</>
	)
}

const RespondentList = styled.div`
	width: 270px;
	max-height: 180px;
	border-radius: 10px;
	box-shadow: 0 5px 15px 0 ${Colors.black15};
	padding: 15px;
	background: ${Colors.white};
	overflow-y: auto;
`

const RespondentListRow = styled.div`
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	margin-bottom: 10px;
`

const RespondentListName = styled.div`
	flex-grow: 1;
	font-family: Ubuntu;
	font-weight: 500;
	font-size: 12px;
	color: ${Colors.black};
	padding-left: 10px;
`

const AnswerStatsButton: FC<AnswerResult> = ({
	text,
	correct,
	playersWhoSelectedThisAnswer,
	percentOfAllAnswers,
}) => {
	return (
		<Container>
			{percentOfAllAnswers !== undefined && correct !== undefined && (
				<ChartBar correct={correct} percent={percentOfAllAnswers} />
			)}
			<RespondentContainer>
				<Respondents
					playersWhoSelectedThisAnswer={playersWhoSelectedThisAnswer}
				/>
			</RespondentContainer>
			<Content>{text}</Content>
			{percentOfAllAnswers !== undefined && (
				<Percents>{percentOfAllAnswers.toFixed(0)}%</Percents>
			)}
			{playersWhoSelectedThisAnswer.length > 0 && (
				<CustomTooltip zIndex={4}>
					<RespondentList>
						{playersWhoSelectedThisAnswer.map(profile => (
							<RespondentListRow key={profile.id}>
								<Respondent key={profile.id} profile={profile} />
								<RespondentListName>
									{profile.displayName || profile.name}
								</RespondentListName>
							</RespondentListRow>
						))}
					</RespondentList>
				</CustomTooltip>
			)}
		</Container>
	)
}

export default AnswerStatsButton
