import { useCallback } from 'react'
import { SceneVideoUser } from 'video-conference-media'
import { Config } from 'common/config'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { useSelector } from 'react-redux'
import { RootState } from 'common/redux'
import { VideoColumns } from 'room/group-room/presentation/video-columns'
import { ColumnLayout } from 'room/training-room/review-tasks/presentation/review-common'
import styled from '@emotion/styled'
import { getMultipleChoicePollingApi } from './multiple-choice-polling-api'
import { colors, MaybeUserProfile, ui } from '@beelday/common'
import MultipleChoiceForm from './multiple-choice-form'
import { unionBy } from 'lodash'
import useFetchMissingProfiles from 'users/use-fetch-missing-profiles'
import { useProfilesFor } from 'users/redux'

const TwoColumnGrid = styled.div`
	display: flex;
	flex: 1;
	height: 100%;
	min-height: 0;
	width: 100%;
	margin-bottom: 15px;
`

const Container = styled.div`
	background-color: ${colors.white};
	width: 100%;
	height: 100%;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
	border-radius: 7px;
	margin-left: 20px;
`

type Props = {
	streams: SceneVideoUser[]
	userProfile?: MaybeUserProfile
	isTrainer: boolean
}

const MultipleChoicePollingPage = ({
	streams,
	isTrainer,
	userProfile,
}: Props): JSX.Element | null => {
	const multipleChoicePollingApi = getMultipleChoicePollingApi(
		Config.beeldayBackendUrl
	)
	const roomAddress = useAssertedJoinedRoomAddress()
	const pollingDefinition = useSelector(
		(state: RootState) => state.multipleChoicePolling.pollingDefinition
	)
	const questionWithAnswers = useSelector(
		(state: RootState) => state.multipleChoicePolling.questionWithAnswers
	)
	const userAnswered = !!questionWithAnswers?.find(
		answer => answer.userId === userProfile?.id
	)
	const answers = useSelector(
		(state: RootState) => state.multipleChoicePolling.answerers
	)
	const allUsers = useSelector(
		(state: RootState) => state.multipleChoicePolling.allUsers
	)

	const userAnsweredList = unionBy(questionWithAnswers, 'userId')
	const userAnsweredListIds = userAnsweredList.map(user => user.userId)
	useFetchMissingProfiles(userAnsweredListIds)

	const answeredUserProfiles = useProfilesFor(answers)

	const onSubmit = useCallback(
		answerIds => {
			if (pollingDefinition) {
				multipleChoicePollingApi.sendAnswer(
					roomAddress,
					pollingDefinition.id,
					answerIds
				)
			}
		},
		[multipleChoicePollingApi, pollingDefinition, roomAddress]
	)

	return pollingDefinition ? (
		<>
			<TwoColumnGrid>
				<Container>
					{isTrainer &&
					!userAnswered &&
					pollingDefinition.letFacilitatorAnswer ? (
						<MultipleChoiceForm
							pollingDefinition={pollingDefinition}
							onSubmit={onSubmit}
							userProfile={userProfile}
						/>
					) : null}
					{!isTrainer && !userAnswered ? (
						<MultipleChoiceForm
							pollingDefinition={pollingDefinition}
							onSubmit={onSubmit}
							userProfile={userProfile}
						/>
					) : null}

					{(userAnswered && pollingDefinition && questionWithAnswers) ||
					(isTrainer && !pollingDefinition.letFacilitatorAnswer) ? (
						<ui.MultipleChoiceResults
							possibleAnswers={pollingDefinition.possibleAnswers}
							question={pollingDefinition.question}
							questionWithAnswers={questionWithAnswers}
							total={allUsers.length}
							answeredUserProfiles={answeredUserProfiles}
							userApiUrl={Config.USER_API_URL}
						/>
					) : null}
				</Container>
				<ColumnLayout>
					<VideoColumns sceneVideoUsers={streams} />
				</ColumnLayout>
			</TwoColumnGrid>
		</>
	) : null
}

export default MultipleChoicePollingPage
