import { useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const useScrollTopOnRouteChange = (
	container?: HTMLElement | null | undefined
): void => {
	const { pathname } = useLocation()
	const scrollable = container || document.documentElement

	useLayoutEffect(() => {
		scrollable?.scrollTo({
			top: 0,
			left: 0,
		})
	}, [pathname, scrollable])
}
