import { Group, GroupReview, Media } from 'common/types'
import { FC, useMemo } from 'react'
import { RoomContainer } from 'room/common/presentation/room-container'
import styled from '@emotion/styled'
import { TraineeMenu, TraineeMenuOption } from './trainee-menu'
import { TrainerStreamView } from 'video-conference-media/presentation/top-right-stream-view'
import { GroupPropertiesPanel } from 'room/group-room/presentation/group-properties-panel'
import { isHandRaised } from '../model/attention'
import InfoBanner from 'common/presentation/info-banner'
import { MiscPanel } from 'room/misc'
import RoomHeader from 'room/common/presentation/room-header'
import { ui, intl } from '@beelday/common'
import { RingTimer } from 'room/common/presentation/ring-timer'
import { use100vh } from 'react-div-100vh'

const GrowingContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding: 5px 15px 5px 15px;
	width: 100%;
	min-height: 0px;
`

const GrowthAllowingContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	min-height: 0px;
`

const MenuContainer = styled.div`
	display: flex;
	justify-content: stretch;
	align-items: flex-end;
	padding: 0 20px;
	${ui.desktop} {
		padding: 0 40px;
	}
`

const GroupNameContainer = styled.div`
	height: 100%;
	margin-right: auto;
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 1;
`

const RingTimerContainer = styled.div`
	overflow: hidden;
	position: relative;
	height: 100%;
	width: 120px;
	margin-left: auto;
	display: flex;
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: 0;
	justify-content: flex-end;
	opacity: 0;

	${ui.desktop} {
		width: 160px;
	}
	.ring {
		position: absolute;
		bottom: -50px;
		width: 120px;
		height: 120px;
		${ui.desktop} {
			bottom: -70px;
			width: 160px;
			height: 160px;
		}
	}
`

type TrainingRoomTraineeProps = {
	taskDetails: GroupReview | undefined
	group: Group
	trainerIsInGroup: boolean
	onTimerFinish: () => void
	timerInitialValue: number
	onGroupNameChange: (name: string) => void
	onRaiseHand: () => void
	onPutHandDown: () => void
	onOpenExcalidraw: () => void
	onCloseExcalidraw: () => void
	onOpenEtherPad: () => void
	onCloseEtherPad: () => void
	openMedia?: Media
	announcement?: string
	disableStream?: boolean
}

const toSelectedMenuOptions = (
	props: TrainingRoomTraineeProps
): TraineeMenuOption[] => {
	const result = []
	if (isHandRaised(props.group)) {
		result.push(TraineeMenuOption.RequireAttention)
	}

	switch (props.openMedia) {
		case 'Excalidraw':
			result.push(TraineeMenuOption.Excalidraw)
			break
		case 'EtherPad':
			result.push(TraineeMenuOption.EtherPad)
			break
	}
	return result
}

export const RoomTrainee: FC<TrainingRoomTraineeProps> = props => {
	const containerHeight = use100vh()
	const selectedMenuOptions = useMemo(
		() => toSelectedMenuOptions(props),
		[props]
	)

	const handleMenuOptionSelected = (menuOption: TraineeMenuOption) => {
		switch (menuOption) {
			case TraineeMenuOption.RequireAttention:
				if (!props.trainerIsInGroup) {
					props.onRaiseHand()
				}
				break
			case TraineeMenuOption.Excalidraw:
				props.onOpenExcalidraw()
				break
			case TraineeMenuOption.EtherPad:
				props.onOpenEtherPad()
				break
		}
	}

	const handleMenuOptionDeselected = (menuOption: TraineeMenuOption) => {
		switch (menuOption) {
			case TraineeMenuOption.RequireAttention:
				props.onPutHandDown()
				break
			case TraineeMenuOption.Excalidraw:
				props.onCloseExcalidraw()
				break
			case TraineeMenuOption.EtherPad:
				props.onCloseEtherPad()
				break
		}
	}

	return (
		<RoomContainer
			style={{
				height: `${containerHeight}px`,
			}}
		>
			<RoomHeader
				streamView={
					props.trainerIsInGroup ? <TrainerStreamView startVisible /> : null
				}
			>
				<intl.Translate>groups.information.trainer_eavesdrop</intl.Translate>
			</RoomHeader>

			<GrowthAllowingContainer>
				<GrowingContainer>{props.children}</GrowingContainer>
				<MenuContainer>
					<GroupNameContainer>
						<GroupPropertiesPanel
							taskDetails={props.taskDetails}
							groupName={props.group.groupDescription.name}
							onGroupNameChange={props.onGroupNameChange}
						/>
					</GroupNameContainer>
					<ui.FillSpace style={{ flexBasis: 0 }}>
						<TraineeMenu
							onMenuOptionSelected={handleMenuOptionSelected}
							onMenuOptionDeselected={handleMenuOptionDeselected}
							selectedMenuOptions={selectedMenuOptions}
							taskDetails={props.taskDetails}
						/>
					</ui.FillSpace>
					<RingTimerContainer>
						<RingTimer
							className="ring"
							onFinish={props.onTimerFinish}
							initialValue={props.timerInitialValue}
						/>
					</RingTimerContainer>
				</MenuContainer>
			</GrowthAllowingContainer>
			{props.announcement && (
				<InfoBanner title="groups.notification" message={props.announcement} />
			)}
			<MiscPanel />
		</RoomContainer>
	)
}
