export const Description: React.FC = () => (
	<svg
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 19.2 24"
		xmlSpace="preserve"
		width="19.2"
		height="24"
	>
		<g>
			<path
				d="M2.4,0h14.4c1.3,0,2.4,1.1,2.4,2.4V24l-3.6-3.6L13.2,24l-3.6-3.6L6,24l-2.4-3.6L0,24V2.4
		C0,1.1,1.1,0,2.4,0L2.4,0z M9.6,16.8h4.8c0.7,0,1.2-0.5,1.2-1.2c0-0.7-0.5-1.2-1.2-1.2c0,0,0,0,0,0H9.6c-0.7,0-1.2,0.5-1.2,1.2
		C8.4,16.3,8.9,16.8,9.6,16.8C9.6,16.8,9.6,16.8,9.6,16.8L9.6,16.8z M9.6,12h4.8c0.7,0,1.2-0.5,1.2-1.2c0-0.7-0.5-1.2-1.2-1.2H9.6
		c-0.7,0-1.2,0.5-1.2,1.2C8.4,11.5,8.9,12,9.6,12C9.6,12,9.6,12,9.6,12L9.6,12z M4.8,16.8H6c0.7,0,1.2-0.5,1.2-1.2
		c0-0.7-0.5-1.2-1.2-1.2c0,0,0,0,0,0H4.8c-0.7,0-1.2,0.5-1.2,1.2S4.1,16.8,4.8,16.8C4.8,16.8,4.8,16.8,4.8,16.8L4.8,16.8z M4.8,12H6
		c0.7,0,1.2-0.5,1.2-1.2c0-0.7-0.5-1.2-1.2-1.2H4.8c-0.7,0-1.2,0.5-1.2,1.2C3.6,11.5,4.1,12,4.8,12C4.8,12,4.8,12,4.8,12L4.8,12z
		 M4.8,6h9.6c0.7,0,1.2-0.5,1.2-1.2s-0.5-1.2-1.2-1.2H4.8c-0.7,0-1.2,0.5-1.2,1.2S4.1,6,4.8,6L4.8,6z"
			/>
		</g>
	</svg>
)
