type Props = {
	children: React.ReactNode
	condition: boolean
}
export const RenderIfMetCondition: React.FC<Props> = ({
	condition,
	children,
}): JSX.Element | null => {
	return condition ? <>{children}</> : null
}
