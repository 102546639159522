import { pull } from 'lodash'
import React, { useContext, useEffect } from 'react'

type Cleanup = () => void
type UnsavedListener = (hasChanges: boolean) => unknown

export class UnsavedChanges {
	private _waitingFor = 0
	private _listeners: UnsavedListener[] = []

	private _notify(hasChanges: boolean) {
		this._listeners.forEach(l => l(hasChanges))
	}

	clear(): void {
		const wasEmpty = this.isEmpty()
		this._waitingFor = 0

		if (!wasEmpty) this._notify(false)
	}
	lock(): void {
		const wasEmpty = this.isEmpty()
		this._waitingFor++

		if (wasEmpty) this._notify(true)
	}
	release(): void {
		const wasEmpty = this.isEmpty()

		if (!wasEmpty) {
			this._waitingFor--
			this._notify(false)
		}
	}
	isEmpty(): boolean {
		return this._waitingFor === 0
	}
	onChange(listener: UnsavedListener): Cleanup {
		this._listeners.push(listener)
		return () => {
			pull(this._listeners, listener)
		}
	}
}

export const UnsavedChangesContext = React.createContext<UnsavedChanges | null>(
	null
)

export const useHasUnsavedChanges = (hasChanges: boolean): void => {
	const unsavedChanges = useContext(UnsavedChangesContext)
	if (!unsavedChanges) {
		throw new Error('No unsaved changes context provided')
	}

	useEffect(() => {
		if (hasChanges) {
			unsavedChanges.lock()
			return () => unsavedChanges.release()
		}
	}, [unsavedChanges, hasChanges])
}

export const useUnsavedChanges = (): UnsavedChanges | null =>
	useContext(UnsavedChangesContext)

export const NotifyAboutUnsavedChanges: React.FC<{
	onChange: (hasUnsaved: boolean) => unknown
}> = ({ onChange }) => {
	const unsavedChanges = useContext(UnsavedChangesContext)
	useEffect(
		() => unsavedChanges?.onChange(onChange),
		[unsavedChanges, onChange]
	)

	return null
}
