import { useCallback, useEffect, useMemo, useState } from 'react'
import {
	Client,
	openConnection,
	RemoteControlToken,
} from './remote-control-client'
import { MouseControlEvent } from './remote-control-models'
import { logger } from '@beelday/common'

const log = logger.create('remote-control')

type RemoteControlActions = {
	state: 'ON' | 'OFF' | 'CONNECTING' | 'ERROR'
	setVideoElement: (video: HTMLVideoElement | null) => void
	connect: (url: string, token: RemoteControlToken) => Promise<void>
	disconnect: () => Promise<void>
	error?: Error | null
}

export const useRemoteControl = (): RemoteControlActions => {
	const [videoElement, setVideoElement] = useState<HTMLVideoElement | null>(
		null
	)
	const [state, setState] = useState<RemoteControlActions['state']>('OFF')
	const [error, setError] = useState<Error | null>(null)
	const [client, setClient] = useState<Client | null>(null)

	const disconnect = useCallback((): Promise<void> => {
		if (client) {
			return client
				?.disconnect()
				.catch(e => {
					log.error('Failed to disconnect remote control client: ', e)
					setError(e)
				})
				.finally(() => {
					setState('OFF')
					setClient(null)
				})
		}
		return Promise.resolve()
	}, [client])

	const connect = useCallback(
		async (url: string, token: RemoteControlToken): Promise<void> => {
			try {
				await disconnect()
				const newClient = await openConnection(url, token)
				setClient(newClient)
				setState('ON')
			} catch (e) {
				setState('ERROR')
				setError(e instanceof Error ? e : new Error('' + e))
			}
		},
		[disconnect]
	)

	useEffect(() => {
		if (!videoElement || !client) return

		const handleKeyboard = (e: KeyboardEvent): void => {
			if (e.type === 'keydown') {
				client.forward({
					type: e.type,
					key: e.key,
					keycode: e.keyCode,
					ctrl: e.ctrlKey,
					alt: e.altKey,
					meta: e.metaKey,
					shift: e.shiftKey,
				})
			}
		}

		const handleMouse = (e: MouseEvent): void => {
			const {
				width: w,
				height: h,
				left,
				top,
			} = videoElement.getBoundingClientRect()
			const x = Math.round(e.clientX - left)
			const y = Math.round(e.clientY - top)

			//Out of video element bounds
			if (x < 0 || x > w || y < 0 || y > h) return

			const type = e.type as MouseControlEvent['type']
			const btn = e.button

			client.forward({ type, btn, x, y, w, h })
		}

		window.addEventListener('keydown', handleKeyboard)
		window.addEventListener('mousemove', handleMouse)
		window.addEventListener('mousedown', handleMouse)
		window.addEventListener('mouseup', handleMouse)

		return () => {
			window.removeEventListener('keydown', handleKeyboard)
			window.removeEventListener('mousemove', handleMouse)
			window.removeEventListener('mousedown', handleMouse)
			window.removeEventListener('mouseup', handleMouse)
		}
	}, [videoElement, client])

	return useMemo(
		() => ({
			connect,
			disconnect,
			setVideoElement,
			error,
			state,
		}),
		[connect, disconnect, setVideoElement, error, state]
	)
}
