import { UUID, hooks, intl, ui } from '@beelday/common'
import { RootState } from 'common/redux'
import { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ErrorWithStreams } from 'room/common/error-with-streams'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { SceneVideoUser } from 'video-conference-media'
import { KnowledgeCheckReview } from './knowledge-check'
import KnowledgeCheckReviewPopup from './knowledge-check-review-popup'
import KnowledgeCheckTrainerGridView from './presentation/knowledge-check-trainer-grid-view'
import useKnowledgeChecks from './use-knowledge-checks'
import useStats from './use-stats'

const ConfiguredKnowledgeCheckTrainer: FC<{
	knowledgeCheckId: UUID
	streams: SceneVideoUser[]
}> = ({ knowledgeCheckId, streams }) => {
	const roomAddress = useAssertedJoinedRoomAddress()
	const { getKnowledgeCheck, getReview, finishKnowledgeCheck } =
		useKnowledgeChecks()

	const confirm = ui.useConfirm()
	const training = useSelector((state: RootState) => state.sessionInfo.training)
	const trainerProfile = useSelector(
		(state: RootState) =>
			state.users.all[
				training?.upperEchelon.userId ? training?.upperEchelon.userId : ''
			]
	)

	const stats = useStats(roomAddress, knowledgeCheckId)

	const [review, setReview] = useState<KnowledgeCheckReview>()
	const [showPartialResults, setShowPartialResults] = useState<boolean>(false)

	const loadKnowledgeCheck = useCallback(
		() => getKnowledgeCheck(roomAddress, knowledgeCheckId),
		[getKnowledgeCheck, roomAddress, knowledgeCheckId]
	)

	const {
		result: knowledgeCheck,
		error,
		reload,
	} = hooks.useLoading(loadKnowledgeCheck)

	useEffect(() => {
		if (knowledgeCheckId) {
			getReview(roomAddress, knowledgeCheckId).then(setReview)
		}
		if (showPartialResults && knowledgeCheckId) {
			getReview(roomAddress, knowledgeCheckId).then(setReview)
		}
	}, [getReview, roomAddress, knowledgeCheckId, showPartialResults])

	const onFinish = () => {
		if (knowledgeCheckId) {
			confirm
				.generic(
					<intl.Translate>
						knowledge_check.question.finish.popup.title
					</intl.Translate>,
					<intl.Translate>
						knowledge_check.question.finish.popup.desc.confirm
					</intl.Translate>,
					<intl.Translate>
						knowledge_check.question.finish.popup.confirm
					</intl.Translate>,
					<intl.Translate>
						knowledge_check.question.finish.popup.cancel
					</intl.Translate>
				)
				.then(() => finishKnowledgeCheck(roomAddress, knowledgeCheckId))
		}
	}

	if (error) {
		return (
			<ErrorWithStreams retry={reload}>
				<intl.Translate>knowledge_check.error.load</intl.Translate>
			</ErrorWithStreams>
		)
	}

	return (
		<>
			<KnowledgeCheckTrainerGridView
				sceneVideoUsers={streams}
				userProfile={trainerProfile}
				title={knowledgeCheck?.name || ''}
				maxDurationInSeconds={knowledgeCheck?.maxDurationInSeconds}
				startTime={knowledgeCheck?.startTime}
				stats={stats}
				onFinish={onFinish}
				onShowReview={() => setShowPartialResults(true)}
			/>
			{showPartialResults && review ? (
				<KnowledgeCheckReviewPopup
					onClose={() => setShowPartialResults(false)}
					review={review}
					useCorrectAnswers={knowledgeCheck?.useCorrectAnswers || false}
					stats={stats}
				/>
			) : null}
		</>
	)
}

export default ConfiguredKnowledgeCheckTrainer
