export const HomeIcon: React.FC = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.439 13.724">
		<g
			id="Group_22530"
			data-name="Group 22530"
			transform="translate(0 -12.675)"
		>
			<g
				id="Group_22533"
				data-name="Group 22533"
				transform="translate(0 12.675)"
			>
				<g id="Group_22532" data-name="Group 22532" transform="translate(0 0)">
					<path
						id="Path_8978"
						data-name="Path 8978"
						d="M14.2,18.773l-6.478-5.9a.737.737,0,0,0-1,0l-6.478,5.9a.74.74,0,0,0,.5,1.288H1.776v5.914A.425.425,0,0,0,2.2,26.4H5.751a.425.425,0,0,0,.425-.425V22.384H8.263v3.591a.425.425,0,0,0,.425.425h3.551a.425.425,0,0,0,.425-.425V20.061H13.7a.74.74,0,0,0,.5-1.288Z"
						transform="translate(0 -12.676)"
						fill="currentColor"
					/>
				</g>
			</g>
			<g
				id="Group_22535"
				data-name="Group 22535"
				transform="translate(9.7 13.523)"
			>
				<g id="Group_22534" data-name="Group 22534">
					<path
						id="Path_8979"
						data-name="Path 8979"
						d="M346.825,42.73h-2.852l3.276,2.979V43.155A.425.425,0,0,0,346.825,42.73Z"
						transform="translate(-343.973 -42.73)"
						fill="currentColor"
					/>
				</g>
			</g>
		</g>
	</svg>
)
