import { security } from '@beelday/common'
import { Config } from 'common/config'
import Logger from 'common/logger'
import { RootState } from 'common/redux'
import { UserId } from 'common/types'
import { useAuthenticatedBeeldayClient } from 'connectivity/beelday-hooks'
import { FC, useCallback, useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import {
	getJanusVcr,
	MediaStreamObserver,
	RemoteUserStreamsObserver,
	SpotlightChangeObserver,
	VCR,
} from 'vcr/vcr'
import VcrUnreachable from 'video-conference-media/presentation/vcr-unreachable'
import { useMediaDevices } from '../utils/hooks/use-media-devices'

const logger = new Logger('embedded-vcr')

type EmbeddedVCRProps = {
	userId: UserId
	onLocalMediaStream: MediaStreamObserver
	onRemoteStreams: RemoteUserStreamsObserver
	onSpotlightChange: SpotlightChangeObserver
}

export const EmbeddedVCR: FC<EmbeddedVCRProps> = (props: EmbeddedVCRProps) => {
	const { selectedMicrophone, selectedCamera } = useMediaDevices()

	const token = security.useToken()
	const beeldayClient = useAuthenticatedBeeldayClient()
	const vcrRoomAddress = useSelector(
		(state: RootState) => state.videoConferenceMedia.vcrRoomAddress,
		shallowEqual
	)
	const [connectionAttempt, setConnectionAttempt] = useState(1)
	const publishBitrate = useSelector(
		(state: RootState) => state.videoConferenceMedia.publishBitrate
	)
	const [vcr, setVcr] = useState<VCR>()

	const getVcr = useCallback(async () => {
		if (!vcrRoomAddress) {
			throw Error('VCR room address is unavailable!')
		}
		return beeldayClient.getVcr(vcrRoomAddress)
	}, [beeldayClient, vcrRoomAddress])

	const nextConnectionAttempt = useCallback(() => {
		setTimeout(
			() => setConnectionAttempt(prevState => prevState + 1),
			Config.vcrConnectionRetryIntervalMs
		)
	}, [])

	useEffect(() => {
		if (!vcrRoomAddress) {
			return
		}
		setConnectionAttempt(1)
	}, [vcrRoomAddress])

	useEffect(() => {
		if (!vcrRoomAddress || token.type !== 'VALID_TOKENS') {
			return
		}
		if (connectionAttempt === 0) {
			return
		}
		if (connectionAttempt > Config.vcrConnectionMaxRetries) {
			return
		}
		getVcr()
			.then(async dto => {
				const janusVcrResponse = await getJanusVcr({
					vcrId: dto.vcrId,
					userId: props.userId,
					token: token.accessToken,
					onLostConnection: nextConnectionAttempt,
				})
				if (janusVcrResponse.type === 'success') {
					logger.log(
						`Successful Janus VCR response: ${JSON.stringify(janusVcrResponse)}`
					)
					setConnectionAttempt(0)
					setVcr(janusVcrResponse.vcr)
				} else {
					logger.log(
						`Unsuccessful Janus VCR response: ${JSON.stringify(
							janusVcrResponse
						)}`
					)
					nextConnectionAttempt()
				}
			})
			.catch(e => {
				logger.warn(
					`Error caught during getting VCR info from VCR Manager: ${e}`
				)
				nextConnectionAttempt()
			})
	}, [
		connectionAttempt,
		getVcr,
		nextConnectionAttempt,
		props.userId,
		token,
		vcrRoomAddress,
	])

	useEffect(() => {
		const previousVcr = vcr
		logger.log(`New VCR: ${JSON.stringify(vcr)}`)
		return () => {
			if (!previousVcr) {
				return
			}
			logger.log(`Disconnect from VCR: ${JSON.stringify(previousVcr)}`)
			previousVcr.disconnect()
		}
	}, [vcr])

	useEffect(() => {
		if (!vcr) {
			return
		}
		vcr
			.onLocalStreamChange(props.onLocalMediaStream)
			.onRemoteStreamChange(props.onRemoteStreams)
			.onSpotlightChange(props.onSpotlightChange)
	}, [
		props.onLocalMediaStream,
		props.onRemoteStreams,
		props.onSpotlightChange,
		vcr,
	])

	useEffect(() => {
		if (!vcr || !publishBitrate) {
			return
		}
		logger.log(
			`VCR will be configured with bitrate ${publishBitrate} when available`
		)
		vcr.configure(publishBitrate, selectedCamera)
	}, [publishBitrate, vcr, selectedCamera])

	return connectionAttempt > Config.vcrConnectionMaxRetries ? (
		<VcrUnreachable />
	) : null
}
