import { useRef, useEffect } from 'react'
import * as Sentry from '@sentry/browser'
import useWebSocket from 'react-use-websocket'

export const useReconnectingWebsocket = (
	url: string,
	shouldConnect: boolean = true
) => {
	const isMounted = useRef(true)

	const api = useWebSocket(
		url,
		{
			reconnectAttempts: 10,
			reconnectInterval: 1000,
			retryOnError: true,
			shouldReconnect: closeEvent => {
				const reconnectOn = [1000, 1001, 1005, 1011, 1012, 1013, 1015]
				return (
					reconnectOn.includes(closeEvent.code) && isMounted.current === true
				)
			},
			onReconnectStop: attempts => {
				if (isMounted.current) {
					Sentry.captureException(
						`Cannot connect to ${url} after ${attempts} attempts`
					)
				}
			},
		},
		shouldConnect
	)

	useEffect(() => {
		return () => {
			isMounted.current = false
		}
	}, [])

	return api
}
