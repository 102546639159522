import { useEffect, useMemo, useRef, useState } from 'react'
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ChartOptions,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import styled from '@emotion/styled'
import { MaybeUserProfile, userApi, ui, UUID } from '@beelday/common'
import datalabels from 'chartjs-plugin-datalabels'
import colors from '../../colors'
import { throttle } from 'lodash'
import { Modal, ModalHeader, ModalContainer, ModalContent } from '../modal'
import { css } from '@emotion/react'
import { ProfileAvatar } from '../profile-avatar'
import responsive from '../responsive'

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	datalabels
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const defaultOptions: ChartOptions<'bar'> | any = {
	indexAxis: 'x',
	responsive: true,
	maintainAspectRatio: true,
	layout: {
		padding: {
			right: 30,
			top: 30,
		},
	},
	plugins: {
		tooltip: {
			enabled: true,
		},
		legend: {
			display: false,
		},
		title: {
			display: false,
		},
		datalabels: {
			anchor: 'end',
			align: 'end',
			clamp: true,
			formatter: Math.round,
			color: colors.black,
			font: {
				weight: 500,
				size: 18,
			},
		},
	},
	scales: {
		x: {
			suggestedMin: 0,
			suggestedMax: 1,
			grid: {
				color: colors.heather50,
			},
			ticks: {
				color: colors.black,
				font: {
					size: 16,
				},
			},
		},
		y: {
			suggestedMin: 0,
			suggestedMax: 1,
			grid: {
				color: colors.heather50,
			},
			ticks: {
				color: colors.black,
				font: {
					size: 16,
				},
			},
		},
	},
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
	height: 100%;
	overflow: hidden;

	${responsive.desktop1440} {
		padding: 30px;
	}
`

const PollTitle = styled.div`
	font-size: 22px;
	line-height: 30px;
	font-weight: 700;
	margin-bottom: 10px;

	${responsive.desktop1440} {
		font-size: 28px;
		line-height: 36px;
		margin-bottom: 30px;
	}
`

const PollSmallTitle = styled.div`
	font-size: 18px;
	line-height: 26px;
	font-weight: 700;
	margin-bottom: 30px;
`

const PollChart = styled.div`
	display: flex;
	justify-content: center;
	flex: 1;
	width: 100%;
	overflow: auto;
	height: 100%;

	&::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 5px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: rgba(0, 0, 0, 0.3);
		box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
		-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
	}
`

const Statistics = styled.div`
	margin-top: 15px;
	display: flex;
	align-items: center;
	cursor: pointer;

	${responsive.desktop1440} {
		margin-top: 30px;
	}
`

const StatisticsNumber = styled.div`
	margin-left: 10px;
	font-size: 16px;
	line-height: 17px;

	b,
	strong {
		font-weight: 700;
		font-size: 16px;
		line-height: 17px;
	}
`

const UserLine = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 10px;
`

const UserName = styled.div`
	margin-left: 10px;
	font-size: 14px;
	line-height: 18px;
`

const calculateChartSize = (
	containerHeight: number,
	containerWidth: number,
	countElements: number,
	longAnswers: boolean
): {
	width: number
	height: number
} => {
	const minHeight = 90
	const minWidth = 200
	let width = containerWidth
	let height = containerHeight

	if (longAnswers && countElements * minHeight > containerHeight) {
		height = countElements * minHeight
	}

	if (!longAnswers && countElements * minWidth > containerWidth) {
		width = countElements * minWidth
	}

	return {
		width: width,
		height: height,
	}
}

type Props = {
	question: string
	possibleAnswers: {
		text: string
		answerId: UUID
	}[]
	questionWithAnswers?: {
		answerId: UUID
		userId: UUID
	}[]
	total: number
	answeredUserProfiles: MaybeUserProfile[]
	userApiUrl: string
}

export const MultipleChoiceResults = ({
	question,
	possibleAnswers,
	questionWithAnswers,
	total,
	answeredUserProfiles,
	userApiUrl,
}: Props): JSX.Element => {
	const options = defaultOptions
	const chartRef = useRef(null)
	const parentChartRef = useRef<HTMLDivElement>(null)
	const [showResponded, setShowResponded] = useState(false)

	const [labels, longAnswers, chartData, answeredDataProfiles] = useMemo(() => {
		let longAnswers = false
		const chartData: number[] = []
		const answeredDataProfiles: Array<(MaybeUserProfile | undefined)[]> = []
		const labels = possibleAnswers.map(answer => {
			const answerData = questionWithAnswers?.filter(
				questionAnswer => questionAnswer.answerId === answer.answerId
			)
			const answerProfiles = answerData?.map(singleAnswer =>
				answeredUserProfiles.find(
					userProfile => singleAnswer.userId === userProfile?.id
				)
			)

			if (answerProfiles) {
				answeredDataProfiles.push(answerProfiles)
			}

			if (answerData) {
				chartData.push(answerData.length)
			}
			if (answer.text.length < 25) {
				longAnswers = false
				return answer.text
			} else if (answer.text.length > 25 && answer.text.length < 80) {
				longAnswers = true
				const partLength = Math.floor(answer.text.length / 2)
				return [answer.text.slice(0, partLength), answer.text.slice(partLength)]
			} else {
				longAnswers = true
				const partLength = Math.floor(answer.text.length / 3)
				return [
					answer.text.slice(0, partLength),
					answer.text.slice(partLength, partLength * 2),
					answer.text.slice(partLength * 2),
				]
			}
		})

		return [labels, longAnswers, chartData, answeredDataProfiles]
	}, [answeredUserProfiles, possibleAnswers, questionWithAnswers])

	const data = {
		labels,
		datasets: [
			{
				answeredDataProfiles: answeredDataProfiles,
				data: chartData,
				hoverBackgroundColor: colors.indigoBlue,
				backgroundColor: [
					userApi.USER_AVATAR_COLORS.ORANGE,
					userApi.USER_AVATAR_COLORS.YELLOW,
					userApi.USER_AVATAR_COLORS.MINT,
					userApi.USER_AVATAR_COLORS.GREEN,
					userApi.USER_AVATAR_COLORS.AZURE,
					userApi.USER_AVATAR_COLORS.BLUE,
					userApi.USER_AVATAR_COLORS.RED,
					userApi.USER_AVATAR_COLORS.PINK,
				],
			},
		],
	}

	options.plugins = {
		...options.plugins,
		tooltip: {
			enabled: true,
			callbacks: {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				title: (data: any) => {
					const index = data[0].dataIndex
					const profiles = data[0].dataset.answeredDataProfiles
					return (profiles[index] as []).map((profile: MaybeUserProfile) =>
						userApi.getDisplayName(profile)
					)
				},
				label: () => {
					return ''
				},
			},
		},
	}

	if (longAnswers) {
		options.indexAxis = 'y'
		options.maintainAspectRatio = false
	} else {
		options.indexAxis = 'x'
		options.maintainAspectRatio = true
	}

	if (options.scales?.x) {
		options.scales.x.suggestedMax = total
	}
	if (options.scales?.y) {
		options.scales.y.suggestedMax = total
	}

	useEffect(() => {
		const onResize = throttle(() => {
			if (parentChartRef.current) {
				parentChartRef.current.style.height = `100%`
				parentChartRef.current.style.width = `100%`

				const containerSize = calculateChartSize(
					parentChartRef.current.clientHeight,
					parentChartRef.current.clientWidth,
					possibleAnswers.length,
					longAnswers
				)
				parentChartRef.current.style.height = `${containerSize.height}px`
				// parentChartRef.current.style.width = `${containerSize.width}px`
			}
		}, 500)

		window.addEventListener('resize', onResize)
		setTimeout(() => onResize(), 1)
		return () => window.removeEventListener('resize', onResize)
	}, [longAnswers, possibleAnswers.length])

	return (
		<>
			<Container>
				{question.length > 50 ? (
					<PollSmallTitle>{question}</PollSmallTitle>
				) : (
					<PollTitle>{question}</PollTitle>
				)}

				<div style={{ height: '100%' }}>
					<PollChart>
						<div
							ref={parentChartRef}
							style={{
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<Bar ref={chartRef} options={options} data={data} />
						</div>
					</PollChart>
				</div>

				<Statistics onClick={() => setShowResponded(true)}>
					<ui.ProfileAvatars
						size={'32px'}
						profiles={answeredUserProfiles}
						userApiUrl={userApiUrl}
					/>
					<StatisticsNumber>
						<b>{answeredUserProfiles.length}</b>/{total}
					</StatisticsNumber>
				</Statistics>
			</Container>
			{showResponded ? (
				<Modal backgroundColor={colors.transparentPaleGray}>
					<ModalContainer
						css={css`
							width: 620px;
						`}
					>
						<ModalHeader
							title={'Participants who responded'}
							onClose={() => setShowResponded(false)}
						/>
						<ModalContent>
							<div>
								{answeredUserProfiles.map(profile => (
									<UserLine key={profile?.id}>
										<ProfileAvatar size={'32px'} userApiUrl={userApiUrl} />
										<UserName>{userApi.getDisplayName(profile)}</UserName>
									</UserLine>
								))}
							</div>
						</ModalContent>
					</ModalContainer>
				</Modal>
			) : null}
		</>
	)
}
