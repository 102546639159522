import { css } from '@emotion/react'
import { FC } from 'react'
import colors from '../colors'

type Props = {
	color?: string
	size?: number
	className?: string
}
export const ColorMarker: FC<Props> = ({ color, className, size = 17 }) => (
	<span
		css={css`
			all: unset;
			display: inline-block;
			border-radius: 50%;
			border: 1px solid ${colors.brightGray};
			width: ${size}px;
			height: ${size}px;
		`}
		className={className}
		style={{
			backgroundColor: color || 'transparent',
		}}
	/>
)
