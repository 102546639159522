import { UserId } from '@beelday/common'
import { RootState } from 'common/redux'
import {
	EtherPadState,
	ExcalidrawState,
	Group,
	GroupId,
	HandPosition,
	ScreenShareInGroupState,
	Task,
} from 'common/types'
import {
	EtherPadClosedEvent,
	GroupStateChangedEvent,
	HandPositionChangedEvent,
	TrainerBecameAvailableEvent,
	TrainerBecameUnavailableEvent,
	TrainerIsJoiningGroupEvent,
	TrainerStatusChangedEvent,
} from 'connectivity/beelday-client'
import { pickBy, reduce } from 'lodash'
import { createSelector } from 'reselect'
import {
	fromHandPositionChanged,
	none,
	raisedHand,
	trainerBecameUnavailable,
	trainerJoining,
	updateAttentionFor,
} from './model/attention'

type TrainerInGroup = {
	status: 'TRAINER_IN_GROUP'
	inGroup: GroupId
}

type TrainerBeRightBack = {
	status: 'TRAINER_BE_RIGHT_BACK'
	listensToGroup?: GroupId
}
type TrainerLooksAtGroupsView = {
	status: 'TRAINER_LOOKS_AT_GROUPS_VIEW'
	listensToGroup?: GroupId
}

type TrainerState =
	| TrainerInGroup
	| TrainerBeRightBack
	| TrainerLooksAtGroupsView

const groupRoomReducerDefaultState: GroupRoomState = {
	groups: {},
	tasks: {},
	trainerState: { status: 'TRAINER_LOOKS_AT_GROUPS_VIEW' },
	etherPadsStates: {},
	excalidraw: {},
	screenShare: {},
}

export interface GroupRoomState {
	groups: Record<GroupId, Group>
	tasks: Record<GroupId, Task>
	trainerState: TrainerState
	etherPadsStates: Record<GroupId, EtherPadState>
	excalidraw: Record<GroupId, ExcalidrawState>
	screenShare: Record<GroupId, ScreenShareInGroupState>
}

export type GroupRoomAction =
	| { type: 'GROUP_STATE_CHANGED'; event: GroupStateChangedEvent }
	| { type: 'GROUP_NAME_CHANGED'; groupId: GroupId; groupName: string }
	| {
			type: 'TRAINER_IS_JOINING_GROUP'
			event: TrainerIsJoiningGroupEvent
	  }
	| {
			type: 'TRAINER_STATUS_CHANGED'
			event: TrainerStatusChangedEvent
	  }
	| {
			type: 'TRAINER_BECAME_UNAVAILABLE'
			event: TrainerBecameUnavailableEvent
	  }
	| { type: 'HAND_POSITION_CHANGED'; event: HandPositionChangedEvent }
	| {
			type: 'GROUP_HAND_POSITION_CHANGED'
			groupId: string
			position: HandPosition
	  }
	| { type: 'TRAINER_BECAME_AVAILABLE'; event: TrainerBecameAvailableEvent }
	| { type: 'ACTIVATE_ETHER_PAD'; groupId: GroupId }
	| { type: 'DEACTIVATE_ETHER_PAD'; groupId: GroupId }
	| { type: 'ACTIVATE_EXCALIDRAW'; groupId: GroupId }
	| { type: 'DEACTIVATE_EXCALIDRAW'; groupId: GroupId }
	| { type: 'HANDLE_ETHER_PAD_OPENED'; groupId: GroupId }
	| { type: 'HANDLE_ETHER_PAD_CLOSED'; groupId: GroupId }
	| { type: 'SSE_EXCALIDRAW_OPENED'; groupId: GroupId }
	| { type: 'SSE_EXCALIDRAW_CLOSED'; groupId: GroupId }
	| { type: 'CLEAR_GROUP_ROOM' }
	| { type: 'LISTEN_TO_GROUP'; groupId: GroupId }
	| { type: 'MUTE_GROUPS' }
	| {
			type: 'SSE_SCREEN_SHARING_STARTED'
			payload: {
				user: UserId
			}
	  }
	| { type: 'SSE_SCREEN_SHARING_STOPPED'; payload: { user: UserId } }

const handleGroupHandPositionChanged =
	(position: HandPosition) =>
	(groupId: GroupId): GroupRoomAction => {
		return { type: 'GROUP_HAND_POSITION_CHANGED', groupId, position }
	}

export const groupRaiseHand = handleGroupHandPositionChanged(HandPosition.UP)
export const groupPutHandDown = handleGroupHandPositionChanged(
	HandPosition.DOWN
)

export const handleHandPositionChangedEvent = (
	event: HandPositionChangedEvent
): GroupRoomAction => {
	return { type: 'HAND_POSITION_CHANGED', event }
}

export const handleGroupStateChangedEvent = (
	event: GroupStateChangedEvent
): GroupRoomAction => {
	return { type: 'GROUP_STATE_CHANGED', event }
}

export const handleTrainerIsJoiningGroupEvent = (
	event: TrainerIsJoiningGroupEvent
): GroupRoomAction => {
	return { type: 'TRAINER_IS_JOINING_GROUP', event }
}

export const handleTrainerStatusChangedEvent = (
	event: TrainerStatusChangedEvent
): GroupRoomAction => {
	return { type: 'TRAINER_STATUS_CHANGED', event }
}

export const handleTrainerBecameUnavailableEvent = (
	event: TrainerBecameUnavailableEvent
): GroupRoomAction => {
	return {
		type: 'TRAINER_BECAME_UNAVAILABLE',
		event,
	}
}

export const handleTrainerBecameAvailableEvent = (
	event: TrainerBecameAvailableEvent
): GroupRoomAction => {
	return { type: 'TRAINER_BECAME_AVAILABLE', event }
}

export const activateEtherPad = (groupId: GroupId): GroupRoomAction => {
	return {
		type: 'ACTIVATE_ETHER_PAD',
		groupId,
	}
}

export const activateExcalidraw = (groupId: GroupId): GroupRoomAction => {
	return {
		type: 'ACTIVATE_EXCALIDRAW',
		groupId,
	}
}

export const deactivateEtherPad = (groupId: GroupId): GroupRoomAction => {
	return {
		type: 'DEACTIVATE_ETHER_PAD',
		groupId,
	}
}

export const deactivateExcalidraw = (groupId: GroupId): GroupRoomAction => {
	return {
		type: 'DEACTIVATE_EXCALIDRAW',
		groupId,
	}
}

export const handleStartScreenShareInGroup = (
	user: UserId
): GroupRoomAction => {
	return {
		type: 'SSE_SCREEN_SHARING_STARTED',
		payload: {
			user,
		},
	}
}

export const handleStopScreenShareInGroup = (user: UserId): GroupRoomAction => {
	return {
		type: 'SSE_SCREEN_SHARING_STOPPED',
		payload: { user },
	}
}

export const handleEtherPadOpened = (groupId: GroupId): GroupRoomAction => {
	return { type: 'HANDLE_ETHER_PAD_OPENED', groupId }
}

export const handleExcalidrawOpened = (groupId: GroupId): GroupRoomAction => {
	return { type: 'SSE_EXCALIDRAW_OPENED', groupId }
}

export const handleExcalidrawClosed = (groupId: GroupId): GroupRoomAction => {
	return { type: 'SSE_EXCALIDRAW_CLOSED', groupId }
}

export const handleEtherPadClosed = (
	event: EtherPadClosedEvent
): GroupRoomAction => {
	return { type: 'HANDLE_ETHER_PAD_CLOSED', groupId: event.groupId }
}

export const changeGroupName = (
	groupId: GroupId,
	groupName: string
): GroupRoomAction => {
	return { type: 'GROUP_NAME_CHANGED', groupId, groupName }
}

export const clearGroupRoom = (): GroupRoomAction => {
	return { type: 'CLEAR_GROUP_ROOM' }
}

export const unmuteGroup = (id: GroupId) => ({
	type: 'LISTEN_TO_GROUP',
	groupId: id,
})

export const muteGroups = () => ({
	type: 'MUTE_GROUPS',
})

export function groupRoomReducer(
	state: GroupRoomState = groupRoomReducerDefaultState,
	action: GroupRoomAction
): GroupRoomState {
	switch (action.type) {
		case 'GROUP_STATE_CHANGED': {
			const groupsState = action.event.groups
			const updated = reduce(
				groupsState,
				(groups, group) => {
					const groupId = group.groupDescription.id
					const current = state.groups[groupId]
					groups[groupId] = current
						? { ...current, ...group }
						: { ...group, attention: none }
					return groups
				},
				{} as GroupRoomState['groups']
			)
			return {
				...state,
				groups: updated,
			}
		}
		case 'GROUP_NAME_CHANGED': {
			const groupsState = state.groups
			const group = groupsState[action.groupId]
			return {
				...state,
				groups: {
					...groupsState,
					[action.groupId]: {
						...group,
						users: group?.users || [],
						attention: group?.attention || { type: 'NONE' },
						groupDescription: {
							...group?.groupDescription,
							id: action.groupId,
							name: action.groupName,
							color: group?.groupDescription.color || 'indigo',
						},
					},
				},
			}
		}

		case 'HAND_POSITION_CHANGED': {
			const { groupId, position } = action.event
			if (
				position === HandPosition.UP &&
				state.groups[groupId].attention.type !== 'NONE'
			) {
				return state
			}
			const updated = updateAttentionFor(
				state.groups,
				groupId,
				fromHandPositionChanged(position)
			)
			return {
				...state,
				groups: updated,
			}
		}

		case 'TRAINER_IS_JOINING_GROUP': {
			const { recipientGroupId, countdown } = action.event
			const updated = updateAttentionFor(
				state.groups,
				recipientGroupId,
				trainerJoining(countdown)
			)
			return {
				...state,
				groups: updated,
			}
		}

		case 'TRAINER_STATUS_CHANGED': {
			const status = action.event.trainerStatus

			if (status === 'TRAINER_BE_RIGHT_BACK') {
				return {
					...state,
					trainerState: {
						...state.trainerState,
						status: 'TRAINER_BE_RIGHT_BACK',
					},
				}
			} else if (status === 'TRAINER_IN_GROUP') {
				return {
					...state,
					trainerState: {
						status: 'TRAINER_IN_GROUP',
						inGroup: action.event.groupId,
					},
				}
			} else {
				return {
					...state,
					trainerState: {
						...state.trainerState,
						status: 'TRAINER_LOOKS_AT_GROUPS_VIEW',
					},
				}
			}
		}

		case 'TRAINER_BECAME_UNAVAILABLE': {
			const updatedGroups = action.event.recipientGroupIds.reduce(
				(groups, groupId) => {
					const group = state.groups[groupId]
					return {
						...groups,
						[groupId]: {
							...group,
							attention: trainerBecameUnavailable(action.event.reason),
						},
					}
				},
				{}
			)

			return {
				...state,
				groups: {
					...state.groups,
					...updatedGroups,
				},
			}
		}
		case 'TRAINER_BECAME_AVAILABLE':
			const updatedGroups = action.event.recipientGroupIds.reduce(
				(groups, groupId) => {
					const group = state.groups[groupId]
					return {
						...groups,
						[groupId]: { ...group, attention: raisedHand },
					}
				},
				{}
			)
			return {
				...state,
				groups: {
					...state.groups,
					...updatedGroups,
				},
			}

		case 'GROUP_HAND_POSITION_CHANGED':
			const updated = updateAttentionFor(
				state.groups,
				action.groupId,
				fromHandPositionChanged(action.position)
			)
			return {
				...state,
				groups: updated,
			}
		case 'HANDLE_ETHER_PAD_OPENED': {
			return {
				...state,
				etherPadsStates: {
					...state.etherPadsStates,
					[action.groupId]: { active: true, loaded: true },
				},
			}
		}
		case 'ACTIVATE_ETHER_PAD': {
			return {
				...state,
				etherPadsStates: {
					...state.etherPadsStates,
					[action.groupId]: { active: true, loaded: false },
				},
			}
		}
		case 'ACTIVATE_EXCALIDRAW': {
			return {
				...state,
				excalidraw: {
					...state.excalidraw,
					[action.groupId]: { active: true, loaded: false },
				},
			}
		}
		case 'SSE_EXCALIDRAW_OPENED': {
			return {
				...state,
				excalidraw: {
					...state.excalidraw,
					[action.groupId]: { active: true, loaded: true },
				},
			}
		}
		case 'SSE_EXCALIDRAW_CLOSED': {
			return {
				...state,
				excalidraw: {
					...state.excalidraw,
					[action.groupId]: { active: false, loaded: false },
				},
			}
		}
		case 'HANDLE_ETHER_PAD_CLOSED':
		case 'DEACTIVATE_ETHER_PAD':
			return {
				...state,
				etherPadsStates: {
					...state.etherPadsStates,
					[action.groupId]: { active: false, loaded: false },
				},
			}
		case 'DEACTIVATE_EXCALIDRAW':
			return {
				...state,
				excalidraw: {
					...state.excalidraw,
					[action.groupId]: { active: false, loaded: false },
				},
			}
		case 'CLEAR_GROUP_ROOM':
			return {
				groups: {},
				tasks: {},
				trainerState: { status: 'TRAINER_LOOKS_AT_GROUPS_VIEW' },
				etherPadsStates: {},
				excalidraw: {},
				screenShare: {},
			}

		case 'SSE_SCREEN_SHARING_STARTED':
			const userGroup = Object.values(state.groups).find(g =>
				g.users.find(u => u.id === action.payload.user)
			)

			if (userGroup) {
				return {
					...state,
					screenShare: {
						...state.screenShare,
						[userGroup.groupDescription.id]: {
							active: true,
							userId: action.payload.user,
						},
					},
				}
			} else {
				return { ...state }
			}

		case 'SSE_SCREEN_SHARING_STOPPED':
			const userGroup1 = Object.values(state.groups).find(g =>
				g.users.find(u => u.id === action.payload.user)
			)

			if (userGroup1) {
				const activeScreenShareUserId =
					state.screenShare[userGroup1.groupDescription.id]?.userId
				if (activeScreenShareUserId === action.payload.user) {
					return {
						...state,
						screenShare: {
							...state.screenShare,
							[userGroup1.groupDescription.id]: {
								active: false,
							},
						},
					}
				} else {
					return { ...state }
				}
			} else {
				return { ...state }
			}

		case 'LISTEN_TO_GROUP':
			if (state.trainerState.status !== 'TRAINER_IN_GROUP') {
				return {
					...state,
					trainerState: {
						...state.trainerState,
						listensToGroup: action.groupId,
					},
				}
			} else {
				return state
			}
		case 'MUTE_GROUPS':
			if (state.trainerState.status !== 'TRAINER_IN_GROUP') {
				return {
					...state,
					trainerState: {
						...state.trainerState,
						listensToGroup: undefined,
					},
				}
			} else {
				return state
			}
		default:
			return state
	}
}

export const isTrainerInGroup = createSelector(
	(state: RootState) => state.groupRoom.trainerState,
	(_: RootState, groupId: GroupId) => groupId,
	(trainerState, groupId) =>
		trainerState.status === 'TRAINER_IN_GROUP' &&
		trainerState.inGroup === groupId
)

export const isTrainerBusy = (state: RootState): boolean =>
	state.groupRoom.trainerState.status === 'TRAINER_BE_RIGHT_BACK'

//Sometimes we get some group data from the previous groupping from backend
//Let's filter them out, so we dont crash
export const selectGroups = (state: RootState): Record<GroupId, Group> => {
	const groups =
		pickBy(
			state.groupRoom.groups,
			group => !!group.users && !!group.groupDescription
		) || []

	return groups
}

export const selectTrainerInGroup = createSelector(
	selectGroups,
	(state: RootState) => state.groupRoom.trainerState,
	(groups, trainerState) => {
		if (trainerState.status === 'TRAINER_IN_GROUP') {
			return groups[trainerState.inGroup]
		} else {
			return undefined
		}
	}
)

export const selectListenToGroup = (state: RootState): GroupId | undefined => {
	const { trainerState } = state.groupRoom

	if (
		trainerState.status === 'TRAINER_LOOKS_AT_GROUPS_VIEW' ||
		trainerState.status === 'TRAINER_BE_RIGHT_BACK'
	) {
		return trainerState.listensToGroup
	}

	return undefined
}

export const selectUserGroupId = createSelector(
	selectGroups,
	(state: RootState) => state,
	(groups, state) => {
		const me = state.workflow.user

		if (me) {
			return Object.values(groups).find(g => g.users.find(u => u.id === me.id))
				?.groupDescription.id
		}
	}
)

export const selectScreenShareStateInGroup = createSelector(
	selectUserGroupId,
	(state: RootState) => state,
	(groupId, state) => {
		if (groupId) {
			return state.groupRoom.screenShare[groupId]
		}
	}
)

export const selectUserGroup = createSelector(
	selectUserGroupId,
	(state: RootState) => state,
	(groupId, state) => {
		if (groupId) {
			return state.groupRoom.groups[groupId]
		}
	}
)
