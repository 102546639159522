export const clippingBorderRadius = (borderRadius: string) =>
	transformedClippingBorderRadius(borderRadius)

export const transformedClippingBorderRadius = (
	borderRadius: string,
	transform?: string
) => `
	border-radius: ${borderRadius};
	overflow: hidden;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0) ${transform || ''};
	-moz-transform: translate3d(0, 0, 0) ${transform || ''};
	${transform && `transform: ${transform};`}
`

export const circleBorder = clippingBorderRadius('50%')
