import { FC } from 'react'
import styled from '@emotion/styled'
import colors from '../colors'

type SvgProps = {
	value: number
}

const Progress = styled.div`
	position: relative;
	width: 50px;
	height: 50px;
`

const ProgressCircle = styled.svg<SvgProps>`
	height: 50px;
	transform: rotate(-90deg);
	width: 50px;

	circle {
		fill: none;
		stroke-width: 2;
		stroke: ${colors.greenishCyan};

		&:nth-of-type(1) {
			stroke-dashoffset: 0;
			stroke: ${colors.brightGray};
		}

		&:nth-of-type(2) {
			stroke: ${colors.greenishCyan};
			stroke-dasharray: 100 100;
			stroke-dashoffset: ${props => props.value};
			stroke-linecap: round;
			transition: stroke-dashoffset 1s ease-in-out;
		}
	}
`

const ProgressText = styled.p`
	color: ${colors.black};
	font-size: 14px;
	font-weight: 700;
	left: 50%;
	margin: 0;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
`

const calculatePercentage = (currentValue: number, maxValue: number) => {
	return 100 - (100 * (currentValue / maxValue) * 100) / 100
}

type Props = {
	value: number
	maxValue: number
}

export const CircleProgress: FC<Props> = ({ value, maxValue }) => (
	<Progress>
		<ProgressCircle
			value={calculatePercentage(value, maxValue)}
			viewBox="-1 -1 34 34"
		>
			<circle cx="16" cy="16" r="15.9155" />
			<circle cx="16" cy="16" r="15.9155" />
		</ProgressCircle>
		<ProgressText>
			{value}/{maxValue}
		</ProgressText>
	</Progress>
)

export default CircleProgress
