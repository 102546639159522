import { FC } from 'react'
import { SplashMessage } from 'common/presentation/splash-container'
import { Colors } from 'common/presentation/colors'
import Translate from 'intl/translate'
import Modal from 'common/presentation/modal'
import ConfirmBanner from 'common/presentation/confirm-banner'

const VcrUnreachable: FC<{
	error?: Error | null
	reconnectThresholdPassed?: boolean
}> = ({ error, reconnectThresholdPassed }) => {
	if (error) {
		return (
			<Modal backgroundColor={Colors.transparentViolet}>
				<SplashMessage>
					{error?.name === 'NotReadableError' ? (
						<Translate>vcr.error.camera_used</Translate>
					) : (
						<Translate>vcr.unavailable</Translate>
					)}
				</SplashMessage>
			</Modal>
		)
	} else if (reconnectThresholdPassed) {
		return (
			<ConfirmBanner
				message="vcr.reconnect.threshold_passed"
				accept="vcr.reconnect.threshold_passed.accept"
				onAccept={() => {
					window.location.reload()
				}}
			/>
		)
	}
	return null
}

export default VcrUnreachable
