import { PublishBitrates } from 'common/publish-bitrates'
import { FunctionComponent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setPublishBitrate } from 'video-conference-media/redux'
import { useUserEngagementResults } from 'features/user-engagement'
import { ConfiguredGroupRoomTrainerGroups } from './configured-group-room-trainer-groups'
import { ConfiguredGroupRoomTrainerInGroup } from './configured-group-room-trainer-in-group'
import { useAuthenticatedBeeldayClient } from '../../connectivity/beelday-hooks'
import { hooks } from '@beelday/common'
import { useAssertedJoinedRoomAddress } from '../common/use-joined-room-address'
import { selectTrainerInGroup } from './redux'

export const ConfiguredGroupRoomTrainer: FunctionComponent = () => {
	const dispatch = useDispatch()
	const beeldayClient = useAuthenticatedBeeldayClient()
	const joinedRoomAddress = useAssertedJoinedRoomAddress()
	const trainerInGroup = useSelector(selectTrainerInGroup)

	useUserEngagementResults()

	const handleStartBreak = hooks.useSerializedCallback(
		() => beeldayClient.startBreak(joinedRoomAddress),
		[joinedRoomAddress]
	)

	useEffect(() => {
		dispatch(setPublishBitrate(PublishBitrates.low))
	}, [dispatch])

	if (trainerInGroup) {
		return (
			<ConfiguredGroupRoomTrainerInGroup
				currentGroup={trainerInGroup}
				onStartBreak={handleStartBreak}
			/>
		)
	}

	return <ConfiguredGroupRoomTrainerGroups onStartBreak={handleStartBreak} />
}
