import styled from '@emotion/styled'
import { FC, useEffect, useState } from 'react'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { KnowledgeCheckID, UserKnowledgeCheckResults } from './knowledge-check'
import KnowledgeCheckResultQuestions from './knowledge-check-result-questions'
import KnowledgeCheckResultScore from './knowledge-check-result-score'
import useKnowledgeChecks from './use-knowledge-checks'

const Container = styled.div`
	align-items: center;
	align-self: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	overflow: auto;
	padding: 32px 40px;
	scrollbar-gutter: stable;
`

type Props = {
	knowledgeCheckId: KnowledgeCheckID
	useCorrectAnswers: boolean
}

const KnowledgeCheckResult: FC<Props> = ({
	knowledgeCheckId,
	useCorrectAnswers,
}) => {
	const roomAddress = useAssertedJoinedRoomAddress()
	const [userResult, setUserResult] = useState<UserKnowledgeCheckResults>()

	const api = useKnowledgeChecks()

	useEffect(() => {
		api
			.getUserResults(roomAddress, knowledgeCheckId)
			.then(result => setUserResult(result))
	}, [api, knowledgeCheckId, roomAddress])

	return (
		<Container>
			{userResult ? (
				<>
					{useCorrectAnswers ? (
						<KnowledgeCheckResultScore
							knowledgeCheckScore={userResult.scoreDto}
						/>
					) : null}
					<KnowledgeCheckResultQuestions
						knowledgeCheckQuestions={userResult.questions}
						useCorrectAnswers={useCorrectAnswers}
					/>
				</>
			) : null}
		</Container>
	)
}

export default KnowledgeCheckResult
