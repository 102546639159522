import { useEffect, useState } from 'react'

export const useTimedRerender = (interval: number = 1000): void => {
	console.error(
		"Performance testing tool: useTimedRerender is enabled. DON'T USE ON PRODUCTION!!!"
	)

	const [dummy, setDummy] = useState(0)

	useEffect(() => {
		setTimeout(() => {
			setDummy(dummy + 1)
		}, interval)
	}, [dummy, interval])
}
