import { useEffect, useState, useCallback, useRef } from 'react'

function useResize<T extends HTMLElement>() {
	const [element, setElement] = useState<HTMLElement>()
	const [width, setWidth] = useState(0)
	const [height, setHeight] = useState(0)

	const ref = useCallback((node: HTMLElement | null) => {
		if (node) {
			setElement(node)
		}
	}, [])

	const observer = useRef<ResizeObserver>()

	const observe = useCallback(() => {
		observer.current = new ResizeObserver(([entry]) => {
			setWidth(entry.contentRect.width)
			setHeight(entry.contentRect.height)
		})
		if (element) observer.current.observe(element)
	}, [element])

	const disconnect = useCallback(() => observer.current?.disconnect(), [])

	useEffect(() => {
		observe()

		return () => disconnect()
	}, [observe, disconnect])

	return { ref, width, height }
}

export default useResize
