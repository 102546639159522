import { PropsOf } from '@emotion/react'
import styled from '@emotion/styled'
import { Relative } from '.'
import colors from '../colors'

const Image = styled.img`
	background-color: ${colors.white};
	width: 100%;
	object-fit: cover;
`

type Props = PropsOf<typeof Relative> & {
	photoId?: string
	border?: string
	height: string
	width: string
	userApiUrl: string
}

export const CompanyHeroPhoto: React.FC<Props> = ({
	border,
	photoId,
	width,
	height,
	children,
	style,
	userApiUrl,
	...rest
}) => {
	const getLogoUrl = (photoId?: string) => {
		if (photoId && photoId?.startsWith('http')) {
			return photoId
		}
		if (photoId) {
			return `${userApiUrl}/files/${photoId}`
		}
	}

	const logoUrl = getLogoUrl(photoId)

	return (
		<Relative
			{...rest}
			style={{
				background: colors.white,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: height,
				width: width,
				...style,
			}}
		>
			{logoUrl && (
				<Image
					src={logoUrl}
					style={{
						maxHeight: '100%',
						maxWidth: '100%',
						border: border,
					}}
				/>
			)}
			{children}
		</Relative>
	)
}
