import type { PlayerRanking } from '../types'

import { FC } from 'react'
import styled from '@emotion/styled'
import Translate from 'intl/translate'
import { Colors } from 'common/presentation/colors'
import WinnersListItem from './winners-list-item'
import { PROFILE_MISSING, UserId, UserProfile } from 'common/types'

const Container = styled.div`
	padding: 20px 30px 20px 50px;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	overflow: hidden;
`

const Title = styled.div`
	color: ${Colors.black};
	font-size: 15px;
	font-family: Ubuntu;
	font-weight: 500;
	line-height: 30px;
`

const PlayersContainer = styled.div`
	flex-grow: 1;
	position: relative;
`
const InnerContainer = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	overflow: auto;
`

type Props = {
	noWinners: boolean
	players: PlayerRanking[]
	profiles: Record<UserId, UserProfile | PROFILE_MISSING>
	total: number
}

const WinnersList: FC<Props> = ({ noWinners, players, profiles, total }) => {
	return (
		<Container>
			<Title>
				<Translate>quiz.result_list</Translate>
			</Title>
			<PlayersContainer>
				<InnerContainer>
					{players.map(p => (
						<WinnersListItem
							key={p.id}
							noWinners={noWinners}
							player={{
								...p,
								...profiles[p.id],
							}}
							total={total}
						/>
					))}
				</InnerContainer>
			</PlayersContainer>
		</Container>
	)
}

export default WinnersList
