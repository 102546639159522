import styled from '@emotion/styled'
import { useSlides } from 'features/slides'
import { FC, useCallback, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useMyProfile } from 'users'

const Container = styled.div`
	width: 100%;
	height: 100%;
	min-height: 0;
	position: relative;
`

export const SlideRenderer: FC<{ slideId: string }> = ({ slideId }) => {
	const location = useLocation<{ from?: string }>()
	const history = useHistory()
	const ref = useRef<HTMLDivElement>(null)
	const { closeSlide } = useSlides()

	const close = useCallback(() => {
		closeSlide()
	}, [closeSlide])

	useEffect(() => {
		const element = ref.current
		element?.addEventListener('close', close)

		return () => element?.removeEventListener('close', close)
	}, [history, location, close])

	const profile = useMyProfile()

	return (
		<Container>
			<beelday-slide-renderer
				slideId={slideId}
				ref={ref}
				withClose={profile.isTrainer ? true : false}
			/>
		</Container>
	)
}
