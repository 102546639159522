import { FC, useEffect, useState } from 'react'
import { UserId } from 'common/types'
import {
	RemoteUserStreamsObserver,
	SpotlightChangeObserver,
} from '../vcr/juno/juno-vcr'
import VcrUnreachable from './presentation/vcr-unreachable'
import { useJuno } from '../vcr/juno/juno-provider'

type EmbeddedVCRProps = {
	userId: UserId
	onRemoteStreams: RemoteUserStreamsObserver
	onSpotlightChange: SpotlightChangeObserver
}

export const EmbeddedJunoVCR: FC<EmbeddedVCRProps> = (
	props: EmbeddedVCRProps
) => {
	const { juno, connectionError } = useJuno()
	const [reconnectThresholdPassed, setReconnectThresholdPassed] =
		useState(false)

	useEffect(() => {
		juno
			?.onRemoteStreamChange(props.onRemoteStreams)
			?.onSpotlightChange(props.onSpotlightChange)
			?.onReconnectThresholdPassed(setReconnectThresholdPassed)
	}, [props.onRemoteStreams, props.onSpotlightChange, juno])

	if (connectionError || reconnectThresholdPassed) {
		return (
			<VcrUnreachable
				error={connectionError}
				reconnectThresholdPassed={reconnectThresholdPassed}
			/>
		)
	}

	return null
}
