import { ui } from '@beelday/common'
import { GrowthColumnContainer } from 'common/presentation/containers'
import { PublishBitrates } from 'common/publish-bitrates'
import { RootState } from 'common/redux'
import { TrainingRoomPhase } from 'common/types'
import { useQuestions, VotingModal } from 'features/questions'
import { setIsExpandableCompanyCard } from 'organization/redux'
import { FC, useEffect } from 'react'
import { use100vh } from 'react-div-100vh'
import { useDispatch, useSelector } from 'react-redux'
import { ErrorWithStreams } from 'room/common/error-with-streams'
import { RoomContainer } from 'room/common/presentation/room-container'
import RoomHeader from 'room/common/presentation/room-header'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { useAssertedWorkflowUser } from 'room/common/use-workflow-user'
import { MiscPanel } from 'room/misc'
import ConfiguredReviewTask from 'room/training-room/review-tasks/configured-review-trainee'
import { SelectGroupPanelTraineeRedux } from 'room/training-room/set-groups/select-group-panel-trainee'
import { TraineeMenu } from 'room/training-room/trainee-menu'
import { TrainerStreamView } from 'video-conference-media/presentation/top-right-stream-view'
import { VideoGrid } from 'video-conference-media/presentation/video-grid'
import {
	allStreams,
	endUserStreams,
	selectLocalScreenShareStream,
	selectPublishBitRate,
	setPublishBitrate,
} from 'video-conference-media/redux'
import { selectFocusedFeature } from './redux'
import { TraineeFocusedFeature } from './trainee-focused-feature'

export const RoomTrainee: FC = () => {
	const allSceneVideoUsers = useSelector(allStreams)
	const endUserSceneVideoUsers = useSelector(endUserStreams)
	const containerHeight = use100vh()
	const roomAddress = useAssertedJoinedRoomAddress()
	const user = useAssertedWorkflowUser()
	const dispatch = useDispatch()
	const trainingRoomPhase = useSelector(
		(state: RootState) => state.room.trainingRoomPhase
	)
	dispatch(setIsExpandableCompanyCard(false))
	const currentBitrate = useSelector(selectPublishBitRate)
	const focusedFeature = useSelector(selectFocusedFeature)
	const questions = useQuestions()
	const streamsCount = allSceneVideoUsers.length

	const isLocalScreenShare = !!useSelector(selectLocalScreenShareStream)
	const showingFocusedFeature =
		focusedFeature && trainingRoomPhase === TrainingRoomPhase.INITIAL
	const trainerInPopup =
		focusedFeature || trainingRoomPhase === TrainingRoomPhase.REVIEWING_TASKS

	useEffect(() => {
		let bitrate = PublishBitrates.low
		if (streamsCount <= 1) {
			bitrate = PublishBitrates.low
		} else if (isLocalScreenShare) {
			bitrate = PublishBitrates.screenShare
		} else if (!showingFocusedFeature && streamsCount < 7) {
			bitrate = PublishBitrates.medium
		}

		if (bitrate !== currentBitrate) {
			dispatch(setPublishBitrate(bitrate))
		}
	}, [
		dispatch,
		currentBitrate,
		isLocalScreenShare,
		showingFocusedFeature,
		streamsCount,
	])

	const streams = trainerInPopup ? endUserSceneVideoUsers : allSceneVideoUsers

	return (
		<RoomContainer
			style={{
				height: `${containerHeight}px`,
			}}
		>
			<RoomHeader
				streamView={trainerInPopup ? <TrainerStreamView startVisible /> : null}
			/>

			{showingFocusedFeature ? (
				<>
					<TraineeFocusedFeature streams={streams} />
					<TraineeMenu />
				</>
			) : (
				(() => {
					switch (trainingRoomPhase) {
						case TrainingRoomPhase.INITIAL:
							return (
								<>
									{showingFocusedFeature ? (
										<TraineeFocusedFeature streams={streams} />
									) : (
										<>
											{questions.active && (
												<VotingModal roomAddress={roomAddress} me={user.id} />
											)}
											<VideoGrid
												sceneVideoUsers={streams}
												presenterStyle="spotlight"
											/>
										</>
									)}
									<TraineeMenu />
								</>
							)
						case TrainingRoomPhase.REVIEWING_TASKS:
							return <ConfiguredReviewTask />
						case TrainingRoomPhase.SELECTING_GROUPS:
							return (
								<>
									<GrowthColumnContainer>
										<SelectGroupPanelTraineeRedux />
									</GrowthColumnContainer>
									<TraineeMenu />
								</>
							)
						default:
							return <ErrorWithStreams />
					}
				})()
			)}
			<MiscPanel />
			<ui.BeeldayLogoAbsoluteBottom />
		</RoomContainer>
	)
}
