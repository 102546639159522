import { useMemo } from 'react'
import { userApi } from '@beelday/common'
import BeeldayClient from './beelday-client'
import { Config } from 'common/config'

export const useAuthenticatedBeeldayClient = (): BeeldayClient => {
	return useMemo(() => new BeeldayClient(), [])
}

export const useUserApi = (): userApi.UserApi =>
	userApi.useUserApi(Config.USER_API_URL)
