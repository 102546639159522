import { UUID } from '@beelday/common'
import {
	PollingMultipleChoiceAnsweredAnswer,
	PollingMultipleChoicePollingDefinition,
} from './multiple-choice-polling-models'
import { RootState } from 'common/redux'

type MultipleChoicePollingDefinitionsAvailabilitySSE = {
	available: boolean
}

export type MultipleChoicePollingState = {
	available: boolean
	pollingDefinition?: PollingMultipleChoicePollingDefinition
	questionWithAnswers?: PollingMultipleChoiceAnsweredAnswer[]
	allUsers: UUID[]
	answerers: UUID[]
}

const initialState: MultipleChoicePollingState = {
	available: false,
	allUsers: [],
	answerers: [],
}

type MultipleChoicePollingAction =
	| {
			type: 'SSE_MULTIPLE_CHOICE_POLLING_DEFINITIONS_AVAILABILITY'
			payload: MultipleChoicePollingDefinitionsAvailabilitySSE
	  }
	| {
			type: 'SSE_MULTIPLE_CHOICE_POLLING_STARTED'
			payload: {
				pollingDefinition: PollingMultipleChoicePollingDefinition
			}
	  }
	| {
			type: 'SSE_MULTIPLE_CHOICE_POLLING_ANSWERS'
			payload: {
				answers: PollingMultipleChoiceAnsweredAnswer[]
			}
	  }
	| {
			type: 'SSE_MULTIPLE_CHOICE_POLLING_ANSWERERS_STATS_CHANGED'
			payload: {
				allUsers: UUID[]
				answerers: UUID[]
			}
	  }

export function reducer(
	state: MultipleChoicePollingState = initialState,
	action: MultipleChoicePollingAction
): MultipleChoicePollingState {
	switch (action.type) {
		case 'SSE_MULTIPLE_CHOICE_POLLING_DEFINITIONS_AVAILABILITY': {
			return {
				...state,
				available: action.payload.available,
			}
		}
		case 'SSE_MULTIPLE_CHOICE_POLLING_STARTED': {
			return {
				...state,
				pollingDefinition: action.payload.pollingDefinition,
			}
		}
		case 'SSE_MULTIPLE_CHOICE_POLLING_ANSWERS': {
			return {
				...state,
				questionWithAnswers: action.payload.answers,
			}
		}
		case 'SSE_MULTIPLE_CHOICE_POLLING_ANSWERERS_STATS_CHANGED': {
			return {
				...state,
				allUsers: action.payload.allUsers,
				answerers: action.payload.answerers,
			}
		}

		default:
			return state
	}
}

export const redux = {
	reducer,
}

export const selectIsMultipleChoicePollingAvaliable = (
	state: RootState
): boolean => state.multipleChoicePolling.available
