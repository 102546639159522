import { intl, ui } from '@beelday/common'
import { GroupReview } from 'common/types'
import styled from '@emotion/styled'
import { colors } from '@beelday/common'

const TaskName = styled.div`
	background-color: ${colors.heather10};
	border-radius: 11px;
	padding: 20px;
	margin-bottom: 20px;
`

const TaskNameTitle = styled.div`
	font-size: 14px;
	font-weight: 500;
	line-height: 17px;
`

const TaskNameDescription = styled.div`
	font-size: 18px;
	line-height: 24px;
`

const Content = styled.div`
	color: ${colors.blackish};
	font-size: 16px;
	font-weight: normal;
	line-height: 24px;
	white-space: pre-wrap;
	text-align: left;
	padding: 0;
`

type Props = { taskDetails: GroupReview; onClose: () => unknown }

const GroupsTaskDescriptionPopup = ({
	taskDetails,
	onClose,
}: Props): JSX.Element => {
	return (
		<ui.RightDetailsPanel
			header={
				<ui.RightDetailsPanel.Title>
					<intl.Translate>room_menu.description.popup.title</intl.Translate>
				</ui.RightDetailsPanel.Title>
			}
			onClose={onClose}
		>
			<TaskName>
				<TaskNameTitle>
					<intl.Translate>task-name</intl.Translate>
				</TaskNameTitle>
				<TaskNameDescription>{taskDetails.task.name}</TaskNameDescription>
			</TaskName>
			<Content>{taskDetails.task.description}</Content>
		</ui.RightDetailsPanel>
	)
}

export default GroupsTaskDescriptionPopup
