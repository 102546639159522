export const TOP = 'top'
export const BOTTOM = 'bottom'
export const LEFT = 'left'
export const RIGHT = 'right'

export const isDropArea = (droppableID: string) => {
	return (
		droppableID === BOTTOM ||
		droppableID === TOP ||
		droppableID === LEFT ||
		droppableID === RIGHT
	)
}
