import {
	createAction,
	createAsyncThunk,
	createSlice,
	PayloadAction,
} from '@reduxjs/toolkit'
import { RootState } from 'common/redux'
import type { Middleware } from 'redux'
import createAPI from './api'
import { Training } from './training'

export type SessionPlanningState = {
	training?: Training
	loading: boolean
}

const initialState: SessionPlanningState = { loading: false }

type SessionPlanningAction = {
	type: 'sessionInfo/trainingUpdated'
	training: Training
}

export const trainingUpdated = (training: Training): SessionPlanningAction => ({
	type: 'sessionInfo/trainingUpdated',
	training,
})

const loadingStarted = createAction('sessionInfo/loadingStarted')

const fetchSessionByLiveSessionId = createAsyncThunk<
	Training | null,
	string,
	{ state: RootState }
>(
	'sessionInfo/fetchSessionByLiveSessionId',
	async (id: string, { dispatch }) => {
		const api = createAPI()
		dispatch(loadingStarted())
		return api.fetchSessionByLiveId(id)
	},
	{
		condition: (_, { getState }) => {
			return (
				getState().sessionInfo.training === undefined &&
				getState().sessionInfo.loading === false
			)
		},
	}
)
const sessionInfoSlice = createSlice({
	name: 'sessionInfo',
	initialState: initialState,
	reducers: {
		updateTraining(state, { payload }: PayloadAction<{ training: Training }>) {
			state.training = payload.training
		},
	},
	extraReducers: builder => {
		builder.addCase(loadingStarted, (state): void => {
			if (state.loading === false) {
				state.loading = true
			}
		})
		builder.addCase(
			fetchSessionByLiveSessionId.fulfilled,
			(state, { payload }): void => {
				if (payload) {
					state.training = payload
				}
				state.loading = false
			}
		)
		builder.addCase(fetchSessionByLiveSessionId.rejected, (state): void => {
			state.loading = false
		})
	},
})

const selectTraining = (state: SessionPlanningState): Training | undefined => {
	return state.training
}

const trainingApi = createAPI()
//Middleware
export const middleware: Middleware = () => next => action => {
	if (action.type === 'EVENTBUS/SESSION_UPDATED') {
		const { id } = action.payload

		trainingApi.fetchSession(id).then(session => {
			if (session) {
				next(trainingUpdated(session))
			}
		})
	}

	next(action)
}

//selectors
export { selectTraining }

//actions
export { fetchSessionByLiveSessionId }

//reducer
export const reducer = sessionInfoSlice.reducer
