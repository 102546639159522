export const Colors = {
	azure: '#24d2e2',
	mediumViolet: '#3c3f96',
	darkViolet: '#31028f',
	ligthBlue: '#3494dd',
	indigoBlue: '#4f17be',
	mediumIndigoBlue: '#8273ed',
	darkIndigo: '#31018f',
	lighterIndigoBlue: '#612fc5',
	transparentViolet: 'rgba(71, 19, 179, 0.9)',
	violet: 'rgb(71, 19, 179)',
	brightCyan: '#59fded',
	fuchsia: '#d510c4',
	white: '#ffffff',
	whiteGray: '#f8f8fb',
	greenishCyan: '#4ae3a8',
	paleGray: '#f8f5ff',
	paleGrayTransparent: 'rgba(41, 54, 73, 0.7)',
	lightGray: '#999aaf',
	gray: '#babbd3',
	lightishPurple: '#bb56d6',
	red: '#ca4b3c',
	lightIndigo: '#9570d8',
	blueyPurple: '#6d3cca',
	heather: '#a099a7',
	heather10: 'rgba(160, 153, 167, 0.1)',
	black: '#000000',
	black70: 'rgba(0,0,0,0.7)',
	indigo: '#31018f',
	lipstick: '#db1864',
	lipstick2: '#F1315E',
	greenishTeal: '#33ce92',
	strongPink: '#fc0f6c',
	brightGray: '#ebebeb',
	whitish: '#f9fafc',
	transparentGreenishCyan: '#b5e1d1',
	transparentStrongPink: '#fbb3d0',
	paleGray30: 'rgba(239, 239, 240, 0.3)\n',
	darkBlue: '#3b3e53',
	warmGrey: '#707070',
	black15: 'rgba(19, 16, 13, 0.15)',
	dustyOrange: '#f49126',
}
