import { colors, intl, ui, icons } from '@beelday/common'
import styled from '@emotion/styled'
import { useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import {
	selectCurrentScriptStep,
	selectNextScriptStep,
	selectPrevScriptStep,
	selectScript,
} from './script-redux'
import useScript from './use-script'
import ScriptActivityDescription from './script-activity-description'

const Container = styled.div`
	background-color: ${colors.white};
	border: 1px solid ${colors.borderGray50};
	border-radius: 11px;
	width: 100%;
	z-index: 2;
`

const ContainerInner = styled.div`
	align-items: center;
	text-align: left;
	display: flex;
	height: 40px;
	padding: 5px;

	${ui.responsive.desktop1440} {
		height: 48px;
		padding: 10px;
	}
`

const Title = styled.div`
	color: ${colors.black};
	font-size: 12px;
	padding-left: 10px;
	padding-right: 10px;
	overflow: hidden;
`

const Item = styled.div`
	font-weight: 400;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`

const Activity = styled.div`
	font-weight: 600;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`

const Time = styled.div`
	font-size: 14px;
	width: 35px;
`

const StartContainer = styled.div`
	align-items: center;
	background-color: ${colors.white};
	border: 1px solid ${colors.borderGray50};
	border-radius: 11px;
	display: flex;
	height: 40px;
	padding: 5px 5px 5px 10px;

	${ui.responsive.desktop1440} {
		height: 48px;
	}
`
const Start = styled.div`
	color: ${colors.black};
	flex: 1;
	font-size: 14px;
	margin-right: 10px;
	text-align: left;
`

const MoveWrapper = styled.div`
	background-color: ${colors.white};
	border: 1px solid ${colors.borderGray50};
	border-radius: 11px;
	cursor: pointer;
	display: flex;
	height: 40px;
	position: absolute;
	top: 0;
	overflow: hidden;
	max-width: 280px;

	${ui.responsive.desktop1440} {
		height: 48px;
	}

	&:hover {
		.moveDescription {
			border-left: 1px solid ${colors.borderGray50};
		}

		.moveButton {
			background-color: ${colors.indigoBlue};
			color: ${colors.white};
		}
	}
`

const Move = styled.div`
	align-items: center;
	background-color: ${colors.white};
	color: ${colors.heather};
	display: flex;
	height: 40px;
	justify-content: center;
	width: 40px;
	flex: 0 0 40px;
	transition: background-color 0.25s, color 0.25s;

	${ui.responsive.desktop1440} {
		height: 48px;
		width: 48px;
		flex: 0 0 48px;
	}
`

const MoveDescription = styled.div`
	align-items: center;
	display: flex;
	border-left: 1px solid transparent;
	width: 0;
	overflow: hidden;
	transition: opacity 0.25s, width 0.25s, border 0.25s;
`

const MoveDescriptionIcon = styled.div`
	padding-left: 10px;
`

const MoveDescriptionContent = styled.div`
	text-align: left;
	padding: 0 10px;
	overflow: hidden;
`

const MoveDescriptionTitle = styled.div`
	font-size: 12px;
	line-height: 14px;
	color: ${colors.black};
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-weight: 400;

	${ui.responsive.desktop1440} {
		line-height: 17px;
	}
`

const MoveDescriptionDesc = styled.div`
	line-height: 14px;
	color: ${colors.black};
	font-size: 12px;
	font-weight: 700;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	${ui.responsive.desktop1440} {
		line-height: 17px;
	}
`

const ConfirmationBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-top: 1px solid ${colors.borderGray50};
	padding: 12px 10px;
`

const ConfirmationBoxText = styled.div`
	font-size: 14px;
	line-height: 17px;
	color: ${colors.black};
	font-weight: 400;
`

const ConfirmationBoxAction = styled.div``

export const ScriptNavigation = (): JSX.Element | null => {
	const { chunks, currentSessionChunkIndex } = useSelector(selectScript)
	const showStartButton = chunks[currentSessionChunkIndex]
		? chunks[currentSessionChunkIndex].items.length
		: false
	const refPrev = useRef<HTMLDivElement>(null)
	const refNext = useRef<HTMLDivElement>(null)
	const script = useScript()
	const address = useAssertedJoinedRoomAddress()
	const current = useSelector(selectCurrentScriptStep)
	const prev = useSelector(selectPrevScriptStep)
	const next = useSelector(selectNextScriptStep)

	const onStart = useCallback(() => {
		script.start(address)
	}, [script, address])

	const onNext = useCallback(() => {
		script.next(address)
	}, [script, address])

	const onPrev = useCallback(() => {
		script.prev(address)
	}, [script, address])

	const onConfirm = useCallback(() => {
		script.confirm(address)
	}, [script, address])

	const setElementWidth = (
		direction: 'PREV' | 'NEXT',
		type: 'ENTER' | 'LEAVE'
	) => {
		const el = direction === 'PREV' ? refPrev : refNext

		if (el.current) {
			el.current.style.width =
				type === 'ENTER' ? `${el.current.scrollWidth}px` : '0px'
		}
	}

	return current ? (
		<ui.Flex style={{ position: 'relative', width: '390px' }}>
			{prev && (
				<MoveWrapper
					style={{ left: '-10px', transform: 'translateX(-100%)' }}
					onMouseEnter={() => setElementWidth('PREV', 'ENTER')}
					onMouseLeave={() => setElementWidth('PREV', 'LEAVE')}
					onClick={onPrev}
				>
					<Move className="moveButton" style={{ transform: 'scaleX(-1)' }}>
						<icons.Chevron />
					</Move>
					<MoveDescription ref={refPrev} className="moveDescription">
						{prev.activity ? (
							<MoveDescriptionIcon>
								<ui.ActivityIcon type={prev.activity?.type} />
							</MoveDescriptionIcon>
						) : null}

						<MoveDescriptionContent>
							<MoveDescriptionTitle>{prev.item.title}</MoveDescriptionTitle>

							<MoveDescriptionDesc>
								{prev.activity ? (
									<ScriptActivityDescription activity={prev.activity} />
								) : (
									prev.item.description
								)}
							</MoveDescriptionDesc>
						</MoveDescriptionContent>
					</MoveDescription>
				</MoveWrapper>
			)}
			<Container>
				<ContainerInner>
					{current.activity?.type ? (
						<ui.ActivityIcon type={current.activity?.type} />
					) : null}
					<Title>
						<Item>{current.item.title}</Item>
						<Activity>
							{current.activity ? (
								<ScriptActivityDescription activity={current.activity} />
							) : (
								current.item.description
							)}
						</Activity>
					</Title>
					<Time />
				</ContainerInner>

				{current.activity?.needsConfirmation ? (
					<ConfirmationBox>
						<ConfirmationBoxText>
							<intl.Translate>
								{`script.confirm.${current.activity.type}.desc`}
							</intl.Translate>
						</ConfirmationBoxText>
						<ConfirmationBoxAction>
							<ui.ButtonPrimary
								style={{ height: '30px', padding: '0 20px' }}
								onClick={onConfirm}
							>
								<intl.Translate>script.confirm.button</intl.Translate>
							</ui.ButtonPrimary>
						</ConfirmationBoxAction>
					</ConfirmationBox>
				) : null}
			</Container>
			{next && (
				<MoveWrapper
					style={{
						right: '-10px',
						transform: 'translateX(100%)',
					}}
					onClick={onNext}
				>
					<Move className="moveButton">
						<icons.Chevron />
					</Move>
					<MoveDescription
						ref={refNext}
						className="moveDescription"
						style={{
							width: 'auto',
							borderLeft: `1px solid ${colors.borderGray50}`,
						}}
					>
						{next.activity ? (
							<MoveDescriptionIcon>
								<ui.ActivityIcon type={next.activity?.type} />
							</MoveDescriptionIcon>
						) : null}

						<MoveDescriptionContent>
							<MoveDescriptionTitle>{next.item.title}</MoveDescriptionTitle>
							<MoveDescriptionDesc>
								{next.activity ? (
									<ScriptActivityDescription activity={next.activity} />
								) : (
									next.item.description
								)}
							</MoveDescriptionDesc>
						</MoveDescriptionContent>
					</MoveDescription>
				</MoveWrapper>
			)}
		</ui.Flex>
	) : showStartButton ? (
		<StartContainer>
			<Start>
				<intl.Translate>script.start.desc</intl.Translate>
			</Start>
			<ui.ButtonPrimary style={{ height: '38px' }} onClick={onStart}>
				<intl.Translate>script.start</intl.Translate>
			</ui.ButtonPrimary>
		</StartContainer>
	) : null
}

export default ScriptNavigation
