import { UserProfile, PROFILE_MISSING } from 'common/types'
import { FC } from 'react'
import styled from '@emotion/styled'
import { Colors } from 'common/presentation/colors'
import { userApi, icons, ui } from '@beelday/common'
import { useUserApi } from 'connectivity/beelday-hooks'

export const DEFAULT_AVATAR = 'avatar.png'
const mapping = userApi.INTEREST_ICON

const Img = styled.img`
	border-radius: 50%;
	height: 100%;
	width: 100%;
`

const SingleContainer = styled.div`
	bottom: -6px;
	display: flex;
	position: absolute;
	right: 0px;
`

const MultipleContainer = styled.div`
	bottom: -18px;
	display: flex;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
`

const Interest = styled.img`
	display: inline-block;
	background-color: ${Colors.white};
	padding: 2px;
	border-radius: 50%;
	height: 33px;
	width: 33px;
`

type Props = {
	profile?: UserProfile | PROFILE_MISSING
}

export const ProfileAvatar: FC<{
	profile?: { avatar?: userApi.Avatar } | null
	className?: string
}> = ({ profile, className }) => {
	const userApi = useUserApi()
	if (profile?.avatar?.fileId) {
		return (
			<Img
				className={className}
				src={userApi.getAvatarUrl(profile.avatar.fileId)}
			/>
		)
	} else {
		return (
			<div className={className}>
				<icons.Avatar color={profile?.avatar?.color} />
			</div>
		)
	}
}

export const ProfileInterests: FC<Props> = ({ profile }) => {
	if (!profile?.interests) return null

	const { interests } = profile

	return interests.length > 1 ? (
		<MultipleContainer>
			{interests.map(i => (
				<Interest src={mapping[i]} key={i} />
			))}
		</MultipleContainer>
	) : (
		<SingleContainer>
			{interests.map(i => (
				<Interest src={mapping[i]} key={i} />
			))}
		</SingleContainer>
	)
}

export const ProfileAvatarWithInterests: FC<Props> = ({ profile }) => {
	const hasInterests = (profile?.interests?.length || 0) > 1

	return (
		<div>
			<ui.Relative>
				<ProfileAvatar profile={profile} />
				<ProfileInterests profile={profile} />
			</ui.Relative>
			<div style={{ height: hasInterests ? '9px' : 0 }} />
		</div>
	)
}

export default ProfileAvatar
