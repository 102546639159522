import { func, http } from '@beelday/common'
import { RoomAddress } from 'common/types'
import { buildRoomUrl } from 'utils/http'
import { ReadOnlyNotepadId } from './notepad-models'
import { WhiteboardId } from '../whiteboard/whiteboard-models'

interface NotepadsApi {
	createNotepad: (
		addr: RoomAddress,
		notepadName: string,
		withFocus: boolean
	) => Promise<void>
	openNotepad: (
		addr: RoomAddress,
		notepadId: ReadOnlyNotepadId,
		withFocus: boolean
	) => Promise<void>
	closeNotepad: (
		addr: RoomAddress,
		notepadId: ReadOnlyNotepadId
	) => Promise<void>
	changeCollaboration: (
		addr: RoomAddress,
		notepadId: ReadOnlyNotepadId,
		enabled: boolean
	) => Promise<void>
}

const createNotepadssApi = (serverUrl: string): NotepadsApi =>
	<NotepadsApi>{
		createNotepad: (
			addr: RoomAddress,
			notepadName: string,
			withFocus: boolean
		): Promise<void> => {
			return http
				.fetch(buildRoomUrl(serverUrl, addr, '/features/notepad'), {
					method: 'POST',
					body: JSON.stringify({ notepadName, withFocus }),
				})
				.then(http.checkStatus)
		},
		openNotepad: (
			addr: RoomAddress,
			notepadName: ReadOnlyNotepadId,
			withFocus: boolean
		): Promise<void> => {
			return http
				.fetch(
					buildRoomUrl(serverUrl, addr, `/features/notepad/${notepadName}`),
					{
						method: 'POST',
						body: JSON.stringify({ withFocus }),
					}
				)
				.then(http.checkStatus)
		},
		closeNotepad: (
			addr: RoomAddress,
			notepadId: ReadOnlyNotepadId
		): Promise<void> => {
			return http
				.fetch(
					buildRoomUrl(serverUrl, addr, `/features/notepad/${notepadId}`),
					{
						method: 'DELETE',
					}
				)
				.then(http.checkStatus)
		},
		changeCollaboration: (
			addr: RoomAddress,
			whiteboardId: WhiteboardId,
			enabled: boolean
		): Promise<void> => {
			return http
				.fetch(
					buildRoomUrl(
						serverUrl,
						addr,
						`/features/notepad/${whiteboardId}/collaboration`
					),
					{
						method: 'PUT',
						body: JSON.stringify({ enabled }),
					}
				)
				.then(http.checkStatus)
		},
	}

export const getNotepadsApi = func.memoizeSingle(createNotepadssApi)
