import { Group, GroupId, UserId } from 'common/types'
import { drop, take } from 'lodash'
import { RowPosition } from 'room/training-room/set-groups/types'
import GroupBucket from './group-bucket'
import styled from '@emotion/styled'
import { ui } from '@beelday/common'
import { SceneVideoUser } from 'video-conference-media'

export type GroupsPreviewProps = {
	groups: Group[]
	streams: Record<UserId, SceneVideoUser>
	onEnter: (groupId: string) => void
	disableStream?: boolean
	listenToGroup?: GroupId
	onMuteGroups: () => unknown
	onUnmuteGroup: (id: GroupId) => unknown
}

const MAX_GROUPS_COUNT_IN_SINGLE_ROW = 3
const MAX_GROUPS_COUNT_IN_DOUBLE_ROW = 8

const GroupsPreview = (props: GroupsPreviewProps): JSX.Element => {
	const numberOfGroups = props.groups.length

	return numberOfGroups <= MAX_GROUPS_COUNT_IN_SINGLE_ROW ? (
		<SingleRowGroupsPreview {...props} />
	) : numberOfGroups <= MAX_GROUPS_COUNT_IN_DOUBLE_ROW ? (
		<DoubleRowGroupsPreview {...props} />
	) : (
		<TripleRowGroupsPreview {...props} />
	)
}

const SingleRowGroupsPreviewLayout = styled.div`
	flex-grow: 1;
	display: flex;
	justify-content: center;
	height: 100%;
	width: 100%;

	${ui.tablet} {
		width: 90%;
		margin: 0 auto;
	}

	${ui.desktop1536} {
		width: 80%;
		margin: 0 auto;
	}
`

const SingleRowGroupsPreview = ({
	listenToGroup,
	groups,
	streams,
	onEnter,
	onMuteGroups,
	onUnmuteGroup,
}: GroupsPreviewProps) => {
	const elementWidth = 100 / groups.length

	return (
		<SingleRowGroupsPreviewLayout>
			{groups.map((group, groupIndex) => {
				const id = group.groupDescription.id
				return (
					<GroupBucket
						key={id}
						group={group}
						index={groupIndex}
						streams={streams}
						width={elementWidth}
						onEnter={() => onEnter(id)}
						listening={id === listenToGroup}
						onMute={onMuteGroups}
						onUnmute={() => onUnmuteGroup(id)}
					/>
				)
			})}
		</SingleRowGroupsPreviewLayout>
	)
}

const DoubleRowGroupsPreviewLayout = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	height: 100%;
	width: 100%;

	${ui.tablet} {
		width: 90%;
		margin: 0 auto;
	}

	${ui.desktop1536} {
		width: 80%;
		margin: 0 auto;
	}
`

const DoubleRowGroupsPreviewTopRowLayout = styled.div`
	display: flex;
	justify-content: center;
	padding-bottom: 0.5em;
	flex-grow: 1;
	width: 100%;

	${ui.tablet} {
		width: 90%;
		margin: 0 auto;
	}

	${ui.desktop1536} {
		width: 80%;
		margin: 0 auto;
	}
`

const DoubleRowGroupsPreviewBottomRowLayout = styled.div`
	display: flex;
	justify-content: center;
	padding-top: 0.5em;
	flex-grow: 1;
	width: 100%;

	${ui.tablet} {
		width: 90%;
		margin: 0 auto;
	}

	${ui.desktop1536} {
		width: 80%;
		margin: 0 auto;
	}
`

const DoubleRowGroupsPreview = ({
	listenToGroup,
	groups,
	streams,
	onEnter,
	onMuteGroups,
	onUnmuteGroup,
}: GroupsPreviewProps) => {
	const numberOfElementsInFirstRow = Math.ceil(groups.length / 2)
	const topRow = take(groups, numberOfElementsInFirstRow)
	const bottomRow = drop(groups, numberOfElementsInFirstRow)
	const elementWidth = 100 / topRow.length

	return (
		<DoubleRowGroupsPreviewLayout>
			<DoubleRowGroupsPreviewTopRowLayout>
				{topRow.map((group, groupIndex) => {
					const id = group.groupDescription.id
					return (
						<GroupBucket
							key={id}
							group={group}
							index={groupIndex}
							rowPosition={RowPosition.TOP}
							streams={streams}
							width={elementWidth}
							onEnter={() => onEnter(id)}
							listening={id === listenToGroup}
							onMute={onMuteGroups}
							onUnmute={() => onUnmuteGroup(id)}
						/>
					)
				})}
			</DoubleRowGroupsPreviewTopRowLayout>
			<DoubleRowGroupsPreviewBottomRowLayout>
				{bottomRow.map((group, groupIndex) => {
					const id = group.groupDescription.id
					return (
						<GroupBucket
							key={id}
							group={group}
							index={groupIndex}
							streams={streams}
							width={elementWidth}
							onEnter={() => onEnter(id)}
							listening={id === listenToGroup}
							onMute={onMuteGroups}
							onUnmute={() => onUnmuteGroup(id)}
						/>
					)
				})}
			</DoubleRowGroupsPreviewBottomRowLayout>
		</DoubleRowGroupsPreviewLayout>
	)
}

const TripleRowGroupsPreviewLayout = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;

	${ui.tablet} {
		width: 90%;
		margin: 0 auto;
	}

	${ui.desktop1536} {
		width: 80%;
		margin: 0 auto;
	}
`

const TripleRowGroupsPreviewRowContainer = styled.div`
	display: flex;
	justify-content: center;
	padding-bottom: 0.5em;
	flex-grow: 1;
	width: 100%;

	${ui.tablet} {
		width: 90%;
		margin: 0 auto;
	}

	${ui.desktop1536} {
		width: 80%;
		margin: 0 auto;
	}
`
const TripleRowGroupsPreview = ({
	listenToGroup,
	groups,
	streams,
	onEnter,
	onMuteGroups,
	onUnmuteGroup,
}: GroupsPreviewProps) => {
	const numberOfElementsInRow = Math.ceil(groups.length / 3)
	const topRow = take(groups, numberOfElementsInRow)
	const rest = drop(groups, numberOfElementsInRow)
	const midRow = take(rest, numberOfElementsInRow)
	const botRow = drop(rest, numberOfElementsInRow)
	const elementWidth = 100 / topRow.length

	return (
		<TripleRowGroupsPreviewLayout>
			<TripleRowGroupsPreviewRowContainer>
				{topRow.map((group, groupIndex) => {
					const id = group.groupDescription.id
					return (
						<GroupBucket
							key={id}
							group={group}
							index={groupIndex}
							streams={streams}
							width={elementWidth}
							onEnter={() => onEnter(id)}
							rowPosition={RowPosition.NONE}
							listening={id === listenToGroup}
							onMute={onMuteGroups}
							onUnmute={() => onUnmuteGroup(id)}
						/>
					)
				})}
			</TripleRowGroupsPreviewRowContainer>
			<TripleRowGroupsPreviewRowContainer>
				{midRow.map((group, groupIndex) => {
					const id = group.groupDescription.id
					return (
						<GroupBucket
							key={id}
							group={group}
							index={groupIndex + numberOfElementsInRow}
							streams={streams}
							width={elementWidth}
							onEnter={() => onEnter(id)}
							rowPosition={RowPosition.NONE}
							listening={id === listenToGroup}
							onMute={onMuteGroups}
							onUnmute={() => onUnmuteGroup(id)}
						/>
					)
				})}
			</TripleRowGroupsPreviewRowContainer>
			<TripleRowGroupsPreviewRowContainer>
				{botRow.map((group, groupIndex) => {
					const id = group.groupDescription.id
					return (
						<GroupBucket
							key={id}
							group={group}
							index={groupIndex + numberOfElementsInRow * 2}
							streams={streams}
							width={elementWidth}
							onEnter={() => onEnter(id)}
							rowPosition={RowPosition.NONE}
							listening={id === listenToGroup}
							onMute={onMuteGroups}
							onUnmute={() => onUnmuteGroup(id)}
						/>
					)
				})}
			</TripleRowGroupsPreviewRowContainer>
		</TripleRowGroupsPreviewLayout>
	)
}

export default GroupsPreview
