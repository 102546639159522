export const removeAtIndex = <T>(arr: T[], index: number): T[] => [
	...arr.slice(0, index),
	...arr.slice(index + 1, arr.length),
]

export const updateAtIndex = <T>(arr: T[], index: number, value: T): T[] => [
	...arr.slice(0, index),
	value,
	...arr.slice(index + 1, arr.length),
]
