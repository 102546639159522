export const ProfileIcon: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="12.948"
		height="13.861"
		viewBox="0 0 12.948 13.861"
	>
		<g id="Group_22846" transform="translate(-30.536)">
			<g id="Group_22847" transform="translate(33.437)">
				<g id="Group_22846-2">
					<path
						id="Path_9007"
						d="M124.655 0a3.655 3.655 0 1 0 3.655 3.655A3.659 3.659 0 0 0 124.655 0z"
						transform="translate(-120.901)"
						fill="currentColor"
					/>
				</g>
			</g>
			<g id="Group_22849" transform="translate(31 8.122)">
				<g id="Group_22848">
					<path
						id="Path_9008"
						d="M42.307 301.674a5.538 5.538 0 0 0-3.97-1.674h-1.726a5.538 5.538 0 0 0-3.97 1.675A5.661 5.661 0 0 0 31 305.668a.432.432 0 0 0 .432.432h12.085a.432.432 0 0 0 .432-.432 5.661 5.661 0 0 0-1.642-3.994z"
						transform="translate(-31.464 -300.361)"
						fill="currentColor"
					/>
				</g>
			</g>
		</g>
	</svg>
)
