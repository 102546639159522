import { colors, intl, icons } from '@beelday/common'
import { KnowledgeCheckReviewQuestion } from '../knowledge-check'
import styled from '@emotion/styled'

const Question = styled.div`
	background: ${colors.heather10};
	border-radius: 7px;
	margin-bottom: 12px;
`

const QuestionHeader = styled.div`
	border-bottom: 1px solid ${colors.heather30};
`

const QuestionHeaderContent = styled.div`
	padding: 15px 20px;
`

const QuestionHeaderTitle = styled.div`
	font-size: 14px;
	line-height: 18px;
	opacity: 0.7;
`

const QuestionHeaderName = styled.div`
	font-size: 16px;
	line-height: 22px;
	font-weight: 500;
`

const QuestionList = styled.div`
	padding: 20px 20px 28px 20px;
`

const QuestionOption = styled.div`
	margin-bottom: 10px;

	&:last-child {
		margin-bottom: 0;
	}
`

const QuestionOptionAnswer = styled.div`
	font-size: 14px;
	line-height: 18px;
	opacity: 0.7;
	margin-bottom: 8px;
`

const QuestionOptionCorrectAnswer = styled.div`
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
	margin-bottom: 8px;
`

const QuestionOptionCheck = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 0 0 16px;
	width: 16px;
	height: 16px;
	background: ${colors.greenishCyan};
	border-radius: 50%;
	margin-right: 5px;

	svg {
		width: 8px;
		color: ${colors.white};
	}
`

const QuestionOptionContent = styled.div`
	display: flex;
`

const QuestionOptionProgress = styled.div`
	height: 20px;
	border-radius: 4px;
	background: ${colors.white};
	flex: 1;
	margin-right: 10px;
	position: relative;
	overflow: hidden;
`

const QuestionOptionProgressContent = styled.div`
	background: red;
	border-radius: 4px;
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
`

const QuestionOptionNumber = styled.div`
	text-align: center;
	width: 40px;
	font-size: 16px;
	font-weight: 500;
	line-height: 22px;
`

const calculatePercent = (
	numberOfSelected: number,
	countUsers: number
): number => {
	const result = Math.round((100 * numberOfSelected) / countUsers)
	return isNaN(result) ? 0 : result
}

type Props = {
	question: KnowledgeCheckReviewQuestion
	number: number
	countUsers: number
	useCorrectAnswers: boolean
}

const KnowledgeCheckReviewQuestion = ({
	question,
	number,
	countUsers,
	useCorrectAnswers,
}: Props) => {
	return (
		<Question>
			<QuestionHeader>
				<QuestionHeaderContent>
					<QuestionHeaderTitle>
						<intl.Translate count={number}>
							knowledge_check.review.question_count
						</intl.Translate>
					</QuestionHeaderTitle>
					<QuestionHeaderName>{question.question}</QuestionHeaderName>
				</QuestionHeaderContent>
			</QuestionHeader>
			<QuestionList>
				{question.options.map(option => (
					<QuestionOption key={option.id}>
						{option.correct && useCorrectAnswers ? (
							<QuestionOptionCorrectAnswer>
								<QuestionOptionCheck>
									<icons.IconChecked />
								</QuestionOptionCheck>
								{option.answer}
							</QuestionOptionCorrectAnswer>
						) : (
							<QuestionOptionAnswer>{option.answer}</QuestionOptionAnswer>
						)}

						<QuestionOptionContent>
							<QuestionOptionProgress>
								<QuestionOptionProgressContent
									style={{
										width: `${calculatePercent(
											option.numberOfSelected,
											countUsers
										)}%`,
										background: useCorrectAnswers
											? option.correct
												? colors.greenishCyan
												: colors.strongPink30
											: colors.indigoBlue30,
									}}
								/>
							</QuestionOptionProgress>
							<QuestionOptionNumber>
								{calculatePercent(option.numberOfSelected, countUsers)}%
							</QuestionOptionNumber>
						</QuestionOptionContent>
					</QuestionOption>
				))}
			</QuestionList>
		</Question>
	)
}

export default KnowledgeCheckReviewQuestion
