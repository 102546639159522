import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Config } from 'common/config'
import { clearStaleResultsAfter } from './settings'
import { useReconnectingWebsocket } from './use-reconnecting-websocket'
import { clearStaleGroupEngagement, logGroupEngagement } from './redux'
import { selectGroups } from 'room/group-room/redux'

const getGroupIdParams = (groupIds: string[]): string => {
	return groupIds
		.filter(id => id)
		.sort((a, b) => (a > b ? 1 : -1))
		.map(id => `group=${id}`)
		.join('&')
}

export const useUserEngagementResults = () => {
	const dispatch = useDispatch()
	const groups = useSelector(selectGroups)
	const groupIds = [...new Set(Object.keys(groups || {}))]
	const params = getGroupIdParams(groupIds)
	const shouldConnect = Boolean(params.length)

	const { lastJsonMessage: result } = useReconnectingWebsocket(
		`${Config.userEngagementResultsUrl}?${params}`,
		shouldConnect
	)

	useEffect(() => {
		if (result && dispatch) {
			dispatch(logGroupEngagement(result))
		}
	}, [result, dispatch])

	useEffect(() => {
		if (dispatch) {
			const interval = setInterval(() => {
				dispatch(clearStaleGroupEngagement(new Date()))
			}, clearStaleResultsAfter)

			return () => clearInterval(interval)
		}
	}, [dispatch])
}
