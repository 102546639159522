import { PublishBitrates } from 'common/publish-bitrates'
import type { GroupId } from 'common/types'
import { useAuthenticatedBeeldayClient } from 'connectivity/beelday-hooks'
import { useUserEngagementResults } from 'features/user-engagement'
import { keyBy } from 'lodash'
import { setIsExpandableCompanyCard } from 'organization/redux'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { filterEndUsers } from 'room/group-room/model/group'
import { allStreams, setPublishBitrate } from 'video-conference-media/redux'
import { RoomTrainerGroupsView } from './presentation/room-trainer-groups-view'
import {
	isTrainerBusy,
	muteGroups,
	selectGroups,
	selectListenToGroup,
	unmuteGroup,
} from './redux'

type Props = {
	onStartBreak: () => void
}

export const ConfiguredGroupRoomTrainerGroups: FC<Props> = ({
	onStartBreak,
}) => {
	const dispatch = useDispatch()
	const beeldayClient = useAuthenticatedBeeldayClient()
	const joinedRoomAddress = useAssertedJoinedRoomAddress()
	const sceneVideoUsers = useSelector(allStreams)
	const groups = useSelector(selectGroups)
	const groupsWithEndUsersOnly = Object.values(groups).map(filterEndUsers)
	const listenToGroup = useSelector(selectListenToGroup)

	useUserEngagementResults()

	dispatch(setIsExpandableCompanyCard(false))

	const streamsInGroups = useMemo(() => {
		return keyBy(sceneVideoUsers, 'userId')
	}, [sceneVideoUsers])

	const handleEnterGroup = useCallback(
		(groupId: string) =>
			beeldayClient.trainerJoinsGroup({
				...joinedRoomAddress,
				groupId,
			}),
		[beeldayClient, joinedRoomAddress]
	)

	const handleUnmuteGroup = useCallback(
		(id: GroupId) => dispatch(unmuteGroup(id)),
		[dispatch]
	)
	const handleMuteGroup = useCallback(() => dispatch(muteGroups()), [dispatch])

	const busy = useSelector(isTrainerBusy)
	const handleSetBusy = useCallback(
		() => beeldayClient.trainerAnnouncedToBeRightBack(joinedRoomAddress),
		[beeldayClient, joinedRoomAddress]
	)

	const handleSetBackFromBusy = useCallback(
		() => beeldayClient.trainerCameBack(joinedRoomAddress),
		[beeldayClient, joinedRoomAddress]
	)

	useEffect(() => {
		dispatch(setPublishBitrate(PublishBitrates.low))
	}, [dispatch])

	return (
		<RoomTrainerGroupsView
			listenToGroup={listenToGroup}
			streams={streamsInGroups}
			groups={groupsWithEndUsersOnly}
			onEnter={handleEnterGroup}
			onMuteGroups={handleMuteGroup}
			onUnmuteGroup={handleUnmuteGroup}
			onTrainerBusy={handleSetBusy}
			onTrainerBackFromBusy={handleSetBackFromBusy}
			isTrainerBusy={busy}
			onStartBreak={onStartBreak}
		/>
	)
}
