import { motion } from 'framer-motion'
import { FC, forwardRef, ReactNode, useImperativeHandle, useState } from 'react'
import styled from '@emotion/styled'
import { css, SerializedStyles } from '@emotion/react'
import colors from '../colors'
import { PlusIcon, MinusIcon } from '../icons'
import { FlexCenterAll } from './containers'

const SectionContainer = styled.div`
	box-shadow: 0 0 20px 0 rgba(94, 92, 154, 0.15);
	background-color: ${colors.white};
	border-radius: 8px;
	display: flex;
	flex: 1;
	min-width: 0;
	flex-direction: column;
	padding: 30px 50px;
	position: relative;
	width: 100%;
	margin-bottom: 10px;
`

export const CollapsedSectionHeader = styled.div`
	display: flex;
	align-items: center;
`

export const CollapsedSectionIcon = styled.img`
	margin-right: 20px;
`

const CollapsedSectionTitleWrapper = styled.div`
	flex-grow: 1;
	display: flex;
	align-items: center;
`

export const CollapsedSectionTitle = styled.div`
	font-size: 20px;
	font-weight: 500;
	color: ${colors.black};
`

const CollapseIcon: FC<{
	collapsed: boolean
	className: string
	onClick: () => unknown
}> = ({ collapsed, className, onClick }) => (
	<div
		className={className}
		role="button"
		onClick={onClick}
		css={css`
			cursor: pointer;
			padding: 4px 0;
			svg {
				width: 20px;
				color: ${colors.indigoBlue};
			}
		`}
	>
		{collapsed ? <PlusIcon /> : <MinusIcon />}
	</div>
)

const CollapseIconPrimary: FC<{
	collapsed: boolean
	className: string
	onClick: () => unknown
}> = ({ collapsed, className, onClick }) => (
	<FlexCenterAll
		className={className}
		role="button"
		onClick={onClick}
		css={css`
			cursor: pointer;
			padding: 8px;
			border-radius: 8px;
			height: 40px;
			width: 40px;
			background-color: ${colors.indigoBlue};
			svg {
				width: 20px;
				height: 20px;
				color: ${colors.white};
			}
		`}
	>
		{collapsed ? <PlusIcon /> : <MinusIcon />}
	</FlexCenterAll>
)
const CollapseIconSecondary: FC<{
	collapsed: boolean
	className: string
	onClick: () => unknown
}> = ({ collapsed, className, onClick }) => (
	<FlexCenterAll
		className={className}
		role="button"
		onClick={onClick}
		css={css`
			cursor: pointer;
			padding: 8px;
			border-radius: 8px;
			border: 1px solid ${colors.indigoBlue};
			height: 40px;
			width: 40px;
			background-color: ${colors.white};
			svg {
				width: 20px;
				height: 20px;
				color: ${colors.indigoBlue};
			}
		`}
	>
		{collapsed ? <PlusIcon /> : <MinusIcon />}
	</FlexCenterAll>
)

export const CollapsedSection: FC<{
	collapsible?: boolean
	title?: ReactNode
	children: ReactNode
}> = ({ collapsible, title, children }) => {
	const [collapsed, setCollapsed] = useState(false)

	return (
		<SectionContainer>
			{title ? (
				<CollapsedSectionHeader>
					<CollapsedSectionTitleWrapper>{title}</CollapsedSectionTitleWrapper>
					{collapsible ? (
						<CollapseIcon
							className="collapsible-icon"
							collapsed={collapsed}
							onClick={() => setCollapsed(!collapsed)}
						/>
					) : null}
				</CollapsedSectionHeader>
			) : null}
			<motion.div
				style={{
					overflow: collapsed ? 'hidden' : 'visible',
				}}
				animate={{ height: collapsed ? 0 : 'auto' }}
			>
				{title ? <div style={{ height: '25px' }} /> : null}
				{children}
			</motion.div>
		</SectionContainer>
	)
}

const Container = styled.div`
	border-radius: 8px;
	display: flex;
	flex: 1;
	min-width: 0;
	flex-direction: column;
	position: relative;
	width: 100%;
`

type CollapsibleProps = {
	initialCollapsed?: boolean
	collapsible?: boolean
	collapseOnTitleClick?: boolean
	title?: ReactNode
	cssStyles?: SerializedStyles
	children: ReactNode
	collapsibleIcon?: 'PRIMARY' | 'SECONDARY' | 'DEFAULT'
}
export type CollapsibleHandle = {
	collapse: () => void
	expand: () => void
}

export const Collapsible = forwardRef<CollapsibleHandle, CollapsibleProps>(
	(
		{
			initialCollapsed = false,
			collapsible,
			collapseOnTitleClick = false,
			title,
			cssStyles,
			children,
			collapsibleIcon = 'DEFAULT',
		},
		ref
	): JSX.Element => {
		const [collapsed, setCollapsed] = useState(initialCollapsed)

		useImperativeHandle(ref, () => ({
			collapse: () => setCollapsed(true),
			expand: () => setCollapsed(false),
		}))

		return (
			<Container className="collapsible-container" css={cssStyles}>
				{title ? (
					<CollapsedSectionHeader
						className="collapsible-header"
						onClick={
							collapseOnTitleClick ? () => setCollapsed(!collapsed) : undefined
						}
						style={collapseOnTitleClick ? { cursor: 'pointer' } : {}}
					>
						<CollapsedSectionTitleWrapper className="collapsible-title-wrapper">
							{title}
						</CollapsedSectionTitleWrapper>
						{collapsible ? (
							collapsibleIcon === 'PRIMARY' ? (
								<CollapseIconPrimary
									className="collapsible-icon"
									collapsed={collapsed}
									onClick={() => setCollapsed(!collapsed)}
								/>
							) : collapsibleIcon === 'SECONDARY' ? (
								<CollapseIconSecondary
									className="collapsible-icon"
									collapsed={collapsed}
									onClick={() => setCollapsed(!collapsed)}
								/>
							) : (
								<CollapseIcon
									className="collapsible-icon"
									css={css`
										padding: 0px;
									`}
									collapsed={collapsed}
									onClick={() => setCollapsed(!collapsed)}
								/>
							)
						) : null}
					</CollapsedSectionHeader>
				) : null}
				<motion.div
					id="option-menu-portal"
					className="collapsible-content"
					style={{
						overflow: 'hidden',
						height: collapsed ? '0px' : 'auto',
					}}
					initial={false}
					animate={{
						height: collapsed ? '0px' : 'auto',
					}}
					transition={{ duration: 0.5 }}
				>
					{children}
				</motion.div>
			</Container>
		)
	}
)

Collapsible.displayName = 'Collapsible'
