import { PropsOf } from '@emotion/react'
import styled from '@emotion/styled'
import { Relative } from '.'
import colors from '../colors'

const MAX_HEIGHT = 400
const MAX_WIDTH = MAX_HEIGHT * 3.45

const Image = styled.img`
	background-color: ${colors.white};
	width: 100%;
	object-fit: cover;
`

type Props = PropsOf<typeof Relative> & {
	logoId?: string
	border?: string
	height: string
	width: string
	userApiUrl: string
}

export const CompanyHeroBanner: React.FC<Props> = ({
	border,
	logoId,
	width,
	height,
	children,
	style,
	userApiUrl,
	...rest
}) => {
	const getLogoUrl = (logoId?: string) => {
		if (logoId && logoId?.startsWith('http')) {
			return logoId
		}
		if (logoId) {
			return `${userApiUrl}/files/${logoId}`
		}
	}

	const logoUrl = getLogoUrl(logoId)

	return (
		<Relative
			{...rest}
			style={{
				background: colors.white,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: height,
				width: width,

				maxWidth: MAX_WIDTH + 'px',
				maxHeight: MAX_HEIGHT + 'px',
				...style,
			}}
		>
			{logoUrl && (
				<Image
					src={logoUrl}
					style={{
						border: border,
					}}
				/>
			)}
			{children}
		</Relative>
	)
}
