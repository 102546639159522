import { FunctionComponent, useCallback } from 'react'
import { isGroupsSelectionStarted } from 'room/training-room/set-groups/model/set-groups'
import { useDispatch, useSelector } from 'react-redux'
import { GroupId, UserRole } from 'common/types'
import { translate } from 'intl/translate'
import { RootState } from 'common/redux'
import { setGroupsSuggestionAssignment } from 'room/training-room/redux'
import GroupPickerTrainee from 'room/training-room/set-groups/presentation/group-picker-trainee'
import { GroupFormingStage } from 'room/training-room/set-groups/types'
import { useAuthenticatedBeeldayClient } from 'connectivity/beelday-hooks'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { useAssertedWorkflowUser } from 'room/common/use-workflow-user'
import { roomEndUsers } from 'room/common/redux'
import { allStreams } from 'video-conference-media/redux'

export const SelectGroupPanelTraineeRedux: FunctionComponent = () => {
	const joinedRoomAddress = useAssertedJoinedRoomAddress()
	const beeldayClient = useAuthenticatedBeeldayClient()
	const workflowUser = useAssertedWorkflowUser()
	const groupsSuggestionAssignment = useSelector(
		(state: RootState) => state.trainingRoom.groupsSuggestionAssignment
	)
	const endUsers = useSelector(roomEndUsers)
	const sceneVideoUsers = useSelector(allStreams)

	const dispatch = useDispatch()

	const unassignedUsers =
		groupsSuggestionAssignment.getNotAssignedUsers(endUsers)

	const assignToGroupByTrainee = useCallback(
		(groupId: GroupId) => {
			const newGroupsSuggestionAssignment =
				groupsSuggestionAssignment.changeUserGroup(workflowUser.id, groupId)
			dispatch(setGroupsSuggestionAssignment(newGroupsSuggestionAssignment))
			beeldayClient.assignUserToGroup({
				...joinedRoomAddress,
				groupId,
				userId: workflowUser.id,
				userRole: UserRole.END_USER,
			})
		},
		[
			beeldayClient,
			dispatch,
			groupsSuggestionAssignment,
			joinedRoomAddress,
			workflowUser,
		]
	)

	const finishChoosingGroupByTrainee = useCallback(() => {
		const selectedGroupId = groupsSuggestionAssignment.getUserSelectedGroupId(
			workflowUser.id
		)

		if (!selectedGroupId)
			throw new Error(
				`Cannot finish choosing groups because group was not selected`
			)

		const newGroupsSuggestionAssignment =
			groupsSuggestionAssignment.finalizeUsersGroupSelection(workflowUser.id)
		dispatch(setGroupsSuggestionAssignment(newGroupsSuggestionAssignment))

		beeldayClient.finalAssignUserToGroup({
			...joinedRoomAddress,
			groupId: selectedGroupId,
			userId: workflowUser.id,
			userRole: UserRole.END_USER,
		})
	}, [
		beeldayClient,
		dispatch,
		groupsSuggestionAssignment,
		joinedRoomAddress,
		workflowUser,
	])

	const declineGroupByTrainee = useCallback(() => {
		const selectedGroupId = groupsSuggestionAssignment.getUserSelectedGroupId(
			workflowUser.id
		)

		if (!selectedGroupId)
			throw new Error(`Cannot decline group when none is selected`)

		const newGroupsSuggestionAssignment =
			groupsSuggestionAssignment.leaveGroupBy(workflowUser.id)
		dispatch(setGroupsSuggestionAssignment(newGroupsSuggestionAssignment))
		beeldayClient.assignUserToGroup({
			...joinedRoomAddress,
			groupId: undefined,
			userId: workflowUser.id,
			userRole: UserRole.END_USER,
		})
	}, [
		beeldayClient,
		dispatch,
		groupsSuggestionAssignment,
		joinedRoomAddress,
		workflowUser,
	])

	if (!isGroupsSelectionStarted(groupsSuggestionAssignment)) {
		return <div>{translate('loading')}</div>
	}

	const selectedGroup = groupsSuggestionAssignment.getUserSelectedGroup(
		workflowUser.id
	)
	const final = groupsSuggestionAssignment.isUserSelectedGroupFinal(
		workflowUser.id
	)

	const groupFormingStage = final
		? GroupFormingStage.CONFIRMED
		: selectedGroup
		? GroupFormingStage.CONFIRM_GROUP
		: GroupFormingStage.SELECT_GROUP

	return (
		<GroupPickerTrainee
			groups={groupsSuggestionAssignment.getGroupsSuggestionAssignment}
			sceneVideoUsers={sceneVideoUsers}
			selectedGroup={selectedGroup}
			stage={groupFormingStage}
			unassignedUsers={unassignedUsers}
			users={endUsers}
			onAcceptGroup={finishChoosingGroupByTrainee}
			onDeclineGroup={declineGroupByTrainee}
			onGroupSelect={groupId => assignToGroupByTrainee(groupId)}
		/>
	)
}
