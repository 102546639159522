export const ChangeNameIcon: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 15.7 15.7"
		xmlSpace="preserve"
	>
		<path
			fill="currentColor"
			d="M15,0H0.8C0.3,0,0,0.3,0,0.8V15c0,0.4,0.3,0.8,0.8,0.8H15c0.4,0,0.8-0.3,0.8-0.8V0.8C15.7,0.3,15.4,0,15,0z M14.2,14.2H1.5
V1.5h12.7V14.2z"
		/>
		<path
			fill="currentColor"
			d="M12.2,4.8v1c0,0.4-0.3,0.8-0.8,0.8c-0.4,0-0.8-0.3-0.8-0.8V5.6h-2v5.6h0.3c0.4,0,0.8,0.3,0.8,0.8c0,0.4-0.3,0.8-0.8,0.8h-2
c-0.4,0-0.8-0.3-0.8-0.8c0-0.4,0.3-0.8,0.8-0.8h0.3V5.6H5v0.3c0,0.4-0.3,0.8-0.8,0.8c-0.4,0-0.8-0.3-0.8-0.8v-1
c0-0.4,0.3-0.8,0.8-0.8h7.1C11.8,4.1,12.2,4.4,12.2,4.8z"
		/>
	</svg>
)
