export const AgendaIcon: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="21.599"
		height="24"
		viewBox="0 0 21.599 24"
	>
		<g transform="translate(0 0)">
			<path
				d="M1.2,18H5.858L3.726,22.262a1.2,1.2,0,1,0,2.148,1.073l2.4-4.8A1.183,1.183,0,0,0,8.388,18H9.6v3.6a1.2,1.2,0,1,0,2.4,0V18h1.212a1.185,1.185,0,0,0,.12.536l2.4,4.8a1.2,1.2,0,1,0,2.148-1.073L15.741,18H20.4a1.2,1.2,0,0,0,1.2-1.2V4.8a1.2,1.2,0,0,0-1.2-1.2H12V1.2a1.2,1.2,0,1,0-2.4,0V3.6H1.2A1.2,1.2,0,0,0,0,4.8v12A1.2,1.2,0,0,0,1.2,18M8.4,9.6a1.2,1.2,0,0,1,0-2.4h4.8a1.2,1.2,0,0,1,1.2,1.2v4.8a1.2,1.2,0,1,1-2.4,0V11.3L9.248,14.048a1.2,1.2,0,0,1-1.7-1.7L10.3,9.6Z"
				transform="translate(0 0)"
			/>
		</g>
	</svg>
)
