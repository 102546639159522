import { FC } from 'react'
import { Colors } from 'common/presentation/colors'
import styled from '@emotion/styled'

const GroupNumberBackground = styled.div`
	position: absolute;
	z-index: 1;
	width: 18px;
	height: 18px;
	padding: 1px 5px 0 3px;
	border-radius: 3px;
	background-color: ${Colors.white};
`

const GroupNumber = styled.div`
	width: 10px;
	height: 17px;
	font-family: Ubuntu, serif;
	font-size: 14px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: ${Colors.indigoBlue};
`

type Props = {
	groupNumber: number
}
const ActiveGroupNumber: FC<Props> = ({ groupNumber }) => (
	<GroupNumberBackground>
		<GroupNumber>{groupNumber}</GroupNumber>
	</GroupNumberBackground>
)

export default ActiveGroupNumber
