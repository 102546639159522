import { useEffect, useState } from 'react'

export type UseMediaDevices = {
	uiReady: boolean
	checkingPermissions: boolean
	permissionsGranted: UserPermissions | undefined
	askForPermission: (deviceToCheck?: DeviceKind) => Promise<{
		permission: PermissionState | undefined
		permissionError: MediaDevicePermissionError | undefined
		error: HardwareCheckError
	}>
	syncPermissions: () => Promise<UserPermissions>

	error: HardwareCheckError
	permissionErrors?: PermissionsErrors

	stream?: MediaStream
	stopStream: () => void
	startStream: () => Promise<void>

	selectedCamera?: string
	selectedSpeaker?: string
	selectedMicrophone?: string

	unavailableCamera?: string
	unavailableSpeaker?: string
	unavailableMicrophone?: string

	selectCamera: (deviceId: string) => Promise<void>
	selectSpeaker: (deviceId: string) => Promise<void>
	selectMicrophone: (deviceId: string) => Promise<void>

	availableDevices: MediaDeviceInfo[]
	availableCameras: MediaDeviceInfo[]
	availableSpeakers: MediaDeviceInfo[]
	availableMicrophones: MediaDeviceInfo[]
}
export type DeviceKind =
	| 'CAMERA_DEVICE'
	| 'MICROPHONE_DEVICE'
	| 'CAMERA_MICROPHONE_DEVICES'

export type TestMediaDeviceResult = {
	kind: DeviceKind
	error?: Error
}

export type UserPermissions = {
	camera: PermissionState
	microphone: PermissionState
}

export type PermissionsErrors = {
	camera: MediaDevicePermissionError | undefined
	microphone: MediaDevicePermissionError | undefined
}

export type MediaDevicePermissionError =
	| 'PERMISSION_DISMISSED'
	| 'PERMISSION_DENIED'

export type MediaDeviceError =
	| 'DEVICE_IS_IN_USE'
	| 'FAILED_TO_START'
	| 'DEVICE_NOT_FOUND'

export type HardwareCheckError = {
	error:
		| 'LISTING_DEVICES_FAILED'
		| 'ASKING_FOR_PERMISSIONS_FAILED'
		| 'UNKNOWN_ERROR'
		| undefined
	deviceErrors: {
		camera: MediaDeviceError | undefined
		microphone: MediaDeviceError | undefined
	}
}

const LS_CAMERA_KEY = 'selected-camera'
const LS_MICROPHONE_KEY = 'selected-microphone'
const LS_SPEAKER_KEY = 'selected-speaker'

let allDevices: MediaDeviceInfo[] = []
const failedDevices: MediaDeviceInfo[] = []
let availableDevices: MediaDeviceInfo[] = []
let availableCameras: MediaDeviceInfo[] = []
let availableSpeakers: MediaDeviceInfo[] = []
let availableMicrophones: MediaDeviceInfo[] = []

let selectedCamera: string | undefined =
	window.localStorage.getItem(LS_CAMERA_KEY) || undefined
let selectedMicrophone: string | undefined =
	window.localStorage.getItem(LS_SPEAKER_KEY) || undefined
let selectedSpeaker: string | undefined =
	window.localStorage.getItem(LS_MICROPHONE_KEY) || undefined

const allStreams = new Map<string, MediaStream | undefined>()
let stream: MediaStream | undefined = undefined
let error: HardwareCheckError = {
	error: undefined,
	deviceErrors: {
		camera: undefined,
		microphone: undefined,
	},
}
const permissionErrors: PermissionsErrors = {
	camera: undefined,
	microphone: undefined,
}

let uiReady = true
let checkingPermissions = false
let permissionsGranted: UserPermissions | undefined = undefined

const subscriptions = new Set<() => void>()

const subscribe = (callback: () => void) => {
	subscriptions.add(callback)
	return () => {
		subscriptions.delete(callback)
	}
}

const notifySubscribers = (reason?: string) => {
	if (reason && subscriptions.size) {
		// console.log(
		// 	`MediaDevices | ${reason} (notifying ${subscriptions.size} components)`
		// )
	}
	subscriptions.forEach(callback => callback())
}

const startAsyncOperation = (reason?: string) => {
	uiReady = false
	notifySubscribers(reason ? `Started ${reason}` : undefined)
}

const finishAsyncOperation = (reason?: string) => {
	uiReady = true
	notifySubscribers(reason ? `Completed ${reason}` : undefined)
}

const syncDevices = () => {
	availableDevices = allDevices.filter(
		device => !failedDevices.includes(device)
	)
	availableCameras = availableDevices.filter(
		device => device.kind === 'videoinput'
	)
	availableSpeakers = availableDevices.filter(
		device => device.kind === 'audiooutput'
	)
	availableMicrophones = availableDevices.filter(
		device => device.kind === 'audioinput'
	)

	selectedCamera = selectedCamera ?? availableCameras[0]?.deviceId
	selectedSpeaker = selectedSpeaker ?? availableSpeakers[0]?.deviceId
	selectedMicrophone = selectedMicrophone ?? availableMicrophones[0]?.deviceId

	if (
		selectedCamera &&
		!availableCameras.find(device => device.deviceId === selectedCamera)
	) {
		selectedCamera = undefined
		window.localStorage.removeItem(LS_CAMERA_KEY)
	}

	if (
		selectedSpeaker &&
		!availableSpeakers.find(device => device.deviceId === selectedSpeaker)
	) {
		selectedSpeaker = undefined
		window.localStorage.removeItem(LS_SPEAKER_KEY)
	}

	if (
		selectedMicrophone &&
		!availableMicrophones.find(device => device.deviceId === selectedMicrophone)
	) {
		selectedMicrophone = undefined
		window.localStorage.removeItem(LS_MICROPHONE_KEY)
	}
}

const stopStream = (): void => {
	allStreams.forEach(stream => {
		stream?.getTracks().forEach(track => track.stop())
	})
	allStreams.clear()

	stream?.getTracks().forEach(track => track.stop())
}

const syncPermissions = async (): Promise<UserPermissions> => {
	startAsyncOperation('syncPermissions')
	const cameraQueryName = 'camera' as PermissionName
	const microphoneQueryName = 'microphone' as PermissionName

	const cameraPermission = await navigator.permissions
		.query({ name: cameraQueryName })
		.then(cameraStatus => {
			return cameraStatus.state
		})
	const microphonePermission = await navigator.permissions
		.query({ name: microphoneQueryName })
		.then(microphoneStatus => {
			return microphoneStatus.state
		})

	if (!permissionsGranted) {
		permissionsGranted = {
			camera: cameraPermission,
			microphone: microphonePermission,
		}
	} else {
		permissionsGranted.camera = cameraPermission
		permissionsGranted.microphone = microphonePermission
	}

	finishAsyncOperation('syncPermissions')
	return permissionsGranted
}

const testStream = async (
	deviceToCheck?: DeviceKind,
	device?: MediaDeviceInfo
): Promise<Array<TestMediaDeviceResult>> => {
	await syncPermissions()

	if (!permissionsGranted) {
		return Promise.reject()
	}

	if (deviceToCheck === 'CAMERA_MICROPHONE_DEVICES') {
		return await testCameraAndMicrophone(device)
	} else if (deviceToCheck === 'MICROPHONE_DEVICE') {
		return [await testMicrophone()]
	} else if (deviceToCheck === 'CAMERA_DEVICE') {
		return [await testCamera(device)]
	}

	return await testCameraAndMicrophone()

	// if (
	// 	permissionsGranted.camera === 'prompt' &&
	// 	permissionsGranted.microphone === 'prompt'
	// ) {
	// 	return await testCameraAndMicrophone(device)
	// } else if (
	// 	permissionsGranted.camera === 'granted' &&
	// 	permissionsGranted.microphone === 'granted'
	// ) {
	// 	return await testCameraAndMicrophone(device)
	// } else if (permissionsGranted.microphone === 'prompt') {
	// 	// return await testCameraAndMicrophone()
	// 	return [await testMicrophone()]
	// } else if (permissionsGranted.camera === 'prompt') {
	// 	// return await testCameraAndMicrophone()
	// 	return [await testCamera(device)]
	// } else if (
	// 	permissionsGranted.camera === 'denied' &&
	// 	permissionsGranted.microphone === 'granted'
	// ) {
	// 	// return await testCameraAndMicrophone()
	// 	return [await testMicrophone()]
	// } else if (
	// 	permissionsGranted.microphone === 'denied' &&
	// 	permissionsGranted.camera === 'granted'
	// ) {
	// 	// return await testCameraAndMicrophone()
	// 	return [await testCamera()]
	// } else {
	// 	return await testCameraAndMicrophone()
	// }
}

const testCameraAndMicrophone = async (
	device?: MediaDeviceInfo
): Promise<Array<TestMediaDeviceResult>> => {
	startAsyncOperation('testCameraAndMicrophone')

	if (permissionsGranted?.camera === 'granted') {
		testCamera(device)
	} else if (permissionsGranted?.microphone === 'granted') {
		testMicrophone()
	}

	try {
		const testStream = await navigator.mediaDevices.getUserMedia({
			audio: true,
			video: device ? { deviceId: { exact: device.deviceId } } : true,
		})

		await listDevices()

		testStream.getTracks().forEach(track => track.stop())

		finishAsyncOperation('testCameraAndMicrophone')
		return Promise.resolve([
			{ kind: 'MICROPHONE_DEVICE' },
			{ kind: 'CAMERA_DEVICE' },
		])

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		finishAsyncOperation('testCameraAndMicrophone')
		if (permissionsGranted?.camera === 'granted') {
			return Promise.resolve([
				{ kind: 'CAMERA_DEVICE' },
				{ kind: 'MICROPHONE_DEVICE', error: e },
			])
		} else if (permissionsGranted?.microphone === 'granted') {
			return Promise.resolve([
				{ kind: 'CAMERA_DEVICE', error: e },
				{ kind: 'MICROPHONE_DEVICE' },
			])
		} else {
			return Promise.resolve([
				{ kind: 'MICROPHONE_DEVICE', error: e },
				{ kind: 'CAMERA_DEVICE', error: e },
			])
		}
	}
}

export const testCamera = async (
	device?: MediaDeviceInfo
): Promise<TestMediaDeviceResult> => {
	startAsyncOperation('testCamera')
	try {
		const testStream = await navigator.mediaDevices.getUserMedia({
			video: device ? { deviceId: { exact: device.deviceId } } : true,
		})

		await listDevices()

		testStream.getTracks().forEach(track => track.stop())

		finishAsyncOperation('testCamera')
		return Promise.resolve({ kind: 'CAMERA_DEVICE' })
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		finishAsyncOperation('testCamera')
		return Promise.resolve({ kind: 'CAMERA_DEVICE', error: e })
	}
}

export const testMicrophone = async (): Promise<TestMediaDeviceResult> => {
	startAsyncOperation('testMicrophone')
	try {
		const testStream = await navigator.mediaDevices.getUserMedia({
			audio: true,
		})

		await listDevices()

		testStream.getTracks().forEach(track => track.stop())

		finishAsyncOperation('testMicrophone')
		return Promise.resolve({ kind: 'MICROPHONE_DEVICE' })
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		finishAsyncOperation('testMicrophone')
		return Promise.resolve({ kind: 'MICROPHONE_DEVICE', error: e })
	}
}

const startStream = async () => {
	startAsyncOperation('startStream')
	syncDevices()
	stopStream()

	const device = availableCameras.find(
		device => device.deviceId === selectedCamera
	)

	if (!device || !selectedCamera) {
		return undefined
	}

	await askForPermission('CAMERA_DEVICE')

	try {
		const startingStream = await navigator.mediaDevices.getUserMedia({
			video: { deviceId: { exact: selectedCamera } },
		})
		allStreams.set(selectedCamera, startingStream)
		stream = startingStream
		error = {
			error: undefined,
			deviceErrors: { camera: undefined, microphone: undefined },
		}
	} catch (e) {
		// console.log('MediaDevices | Starting the stream failed')
		// console.log(e)
		failedDevices.push(device)
		error = {
			error: undefined,
			deviceErrors: { ...error.deviceErrors, camera: 'FAILED_TO_START' },
		}
		syncDevices()
		stopStream()
	}

	finishAsyncOperation('startStream')
}

const askForPermission = async (deviceToCheck?: DeviceKind) => {
	startAsyncOperation('askForPermission')
	checkingPermissions = true
	const result = await testStream(deviceToCheck)

	result.map(r => {
		if (r.error) {
			if (r.error?.name === 'NotAllowedError') {
				if (r.error.message === 'Permission dismissed') {
					if (r.kind === 'CAMERA_DEVICE') {
						permissionErrors.camera = 'PERMISSION_DISMISSED'
					} else if (r.kind === 'MICROPHONE_DEVICE') {
						permissionErrors.microphone = 'PERMISSION_DISMISSED'
					} else {
						permissionErrors.camera = 'PERMISSION_DISMISSED'
						permissionErrors.microphone = 'PERMISSION_DISMISSED'
					}
				} else if (r.error.message === 'Permission denied') {
					if (r.kind === 'CAMERA_DEVICE') {
						permissionErrors.camera = 'PERMISSION_DENIED'
					} else if (r.kind === 'MICROPHONE_DEVICE') {
						permissionErrors.microphone = 'PERMISSION_DENIED'
					} else {
						permissionErrors.camera = 'PERMISSION_DENIED'
						permissionErrors.microphone = 'PERMISSION_DENIED'
					}
				}
			} else if (r.error?.name === 'NotReadableError') {
				if (r.kind === 'CAMERA_DEVICE') {
					error.deviceErrors.camera = 'DEVICE_IS_IN_USE'
				} else if (r.kind === 'MICROPHONE_DEVICE') {
					error.deviceErrors.microphone = 'DEVICE_IS_IN_USE'
				} else {
					error.deviceErrors.camera = 'DEVICE_IS_IN_USE'
					error.deviceErrors.microphone = 'DEVICE_IS_IN_USE'
				}
			} else if (r.error?.name === 'NotFoundError') {
				if (r.error.message === 'Requested device not found') {
					if (r.kind === 'CAMERA_DEVICE') {
						error.deviceErrors.camera = 'DEVICE_NOT_FOUND'
					} else if (r.kind === 'MICROPHONE_DEVICE') {
						error.deviceErrors.microphone = 'DEVICE_NOT_FOUND'
					} else {
						error.deviceErrors.camera = 'DEVICE_NOT_FOUND'
						error.deviceErrors.microphone = 'DEVICE_NOT_FOUND'
					}
				}
			}
		} else {
			if (r.kind === 'CAMERA_DEVICE') {
				permissionErrors.camera = undefined
				error.deviceErrors.camera = undefined
			} else if (r.kind === 'MICROPHONE_DEVICE') {
				permissionErrors.microphone = undefined
				error.deviceErrors.microphone = undefined
			} else {
				permissionErrors.camera = undefined
				permissionErrors.microphone = undefined
				error.deviceErrors.camera = undefined
				error.deviceErrors.microphone = undefined
			}
		}
	})

	checkingPermissions = false
	finishAsyncOperation('askForPermission')
	await syncPermissions()
	return {
		permission:
			deviceToCheck === 'CAMERA_DEVICE'
				? permissionsGranted?.camera
				: permissionsGranted?.microphone,
		permissionError:
			deviceToCheck === 'CAMERA_DEVICE'
				? permissionErrors?.camera
				: permissionErrors?.microphone,
		error,
	}
}

const updateCamera = (deviceId?: string) => {
	selectedCamera = deviceId
	if (deviceId) {
		window.localStorage.setItem(LS_CAMERA_KEY, deviceId)
	} else {
		window.localStorage.removeItem(LS_CAMERA_KEY)
	}
	finishAsyncOperation('selectCamera')
}

const selectCamera = async (deviceId: string): Promise<void> => {
	startAsyncOperation('selectCamera')
	stopStream()

	const device = allDevices.find(device => device.deviceId === deviceId)
	const failed = failedDevices.find(device => device.deviceId === deviceId)

	if (!device || failed) {
		return updateCamera()
	}

	try {
		await testStream('CAMERA_DEVICE', device)
		updateCamera(deviceId)
	} catch (e) {
		// console.log('MediaDevices | Selecting the camera failed')
		// console.log(e)
		failedDevices.push(device)
		error = {
			...error,
			deviceErrors: { ...error.deviceErrors, camera: 'FAILED_TO_START' },
		}
		updateCamera()
		syncDevices()
	}
}

const updateSpeaker = (deviceId?: string) => {
	selectedSpeaker = deviceId
	if (deviceId) {
		window.localStorage.setItem(LS_SPEAKER_KEY, deviceId)
	} else {
		window.localStorage.removeItem(LS_SPEAKER_KEY)
	}
	notifySubscribers('selectSpeaker')
	return undefined
}

const selectSpeaker = async (deviceId: string): Promise<void> => {
	const device = allDevices.find(device => device.deviceId === deviceId)
	const failed = failedDevices.find(device => device.deviceId === deviceId)

	if (!device || failed) {
		return updateSpeaker()
	}

	updateSpeaker(deviceId)
}

const updateMicrophone = (deviceId?: string) => {
	selectedMicrophone = deviceId
	if (deviceId) {
		window.localStorage.setItem(LS_MICROPHONE_KEY, deviceId)
	} else {
		window.localStorage.removeItem(LS_MICROPHONE_KEY)
	}
	notifySubscribers('selectMicrophone')
	return undefined
}

const selectMicrophone = async (deviceId: string): Promise<void> => {
	const device = allDevices.find(device => device.deviceId === deviceId)
	const failed = failedDevices.find(device => device.deviceId === deviceId)

	if (!device || failed) {
		return updateMicrophone()
	}

	updateMicrophone(deviceId)
}

const listDevices = async () => {
	allDevices = (await navigator.mediaDevices.enumerateDevices()).filter(
		mdi => mdi.deviceId
	)
	syncDevices()
}

window.navigator.mediaDevices.addEventListener('devicechange', async () => {
	startAsyncOperation('devicechange')
	await listDevices()
	finishAsyncOperation('devicechange')
})

const useRerender = () => {
	const [, setRerender] = useState(0)
	const callback = () => setRerender(prev => prev + 1)

	useEffect(() => {
		return subscribe(callback)
	}, [])
}

export const useMediaDevices = (): UseMediaDevices => {
	useRerender()

	return {
		uiReady,
		checkingPermissions,
		permissionsGranted,
		askForPermission,
		syncPermissions,

		error,
		permissionErrors,

		stream,
		stopStream,
		startStream,

		selectedCamera,
		selectedSpeaker,
		selectedMicrophone,

		selectCamera,
		selectMicrophone,
		selectSpeaker,

		availableDevices,
		availableCameras,
		availableSpeakers,
		availableMicrophones,
	}
}
