import { Config } from 'common/config'
import { useMemo } from 'react'
import useKnowledgeChecksApi from './use-knowledge-checks-api'

const useKnowledgeChecks = () => {
	const api = useKnowledgeChecksApi(Config.beeldayBackendUrl)

	return useMemo(
		() => ({
			getAvailableKnowledgeChecks: api.getAvailableKnowledgeChecks,
			getAvailableForReview: api.getAvailableForReview,
			startKnowledgeCheck: api.startKnowledgeCheck,
			startKnowledgeCheckReview: api.startKnowledgeCheckReview,
			getKnowledgeCheck: api.getKnowledgeCheck,
			answerQuestion: api.answerQuestion,
			prevQuestion: api.prevQuestion,
			nextQuestion: api.nextQuestion,
			finishKnowledgeCheck: api.finishKnowledgeCheck,
			getTrainerStats: api.getTrainerStats,
			getReview: api.getReview,
			nextReviewQuestion: api.nextReviewQuestion,
			prevReviewQuestion: api.prevReviewQuestion,
			close: api.close,
			getUserResults: api.getUserResults,
		}),
		[api]
	)
}

export default useKnowledgeChecks
