import { ui } from '@beelday/common'
import styled from '@emotion/styled'
import { useOrganization } from 'organization/use-organization-branding'
import { Colors } from 'common/presentation/colors'
import { NarrowContentContainer } from 'common/presentation/containers'
import { RootState } from 'common/redux'
import { RoomId } from 'common/types'
import { useAuthenticatedBeeldayClient } from 'connectivity/beelday-hooks'
import Translate from 'intl/translate'
import { flatMap, map } from 'lodash'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { use100vh } from 'react-div-100vh'
import { useDispatch, useSelector } from 'react-redux'
import { RoomContainer } from 'room/common/presentation/room-container'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { useAssertedWorkflowUser } from 'room/common/use-workflow-user'
import { MiscPanel } from 'room/misc'
import { useProfileFor } from 'users/redux'
import useFetchMissingProfiles from 'users/use-fetch-missing-profiles'
import { Menu } from '../common/presentation/menu'
import MiscPanelMenu from '../misc/misc-panel-menu'
import LobbyHeader from './lobby-header'
import { setCurrentPublicChatRooms, visiblePublicChatRooms } from './redux'
import MeetTrainerRoom from './room-meet-trainer'
import RoomOther from './room-other'

const Body = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 0 70px 0px;
`

const Instruction = styled.div`
	display: flex;
	font-size: 18px;
	font-weight: 500;
	margin-top: 15px;
	margin-left: 40px;
	margin-bottom: 10px;

	${ui.responsive.desktop1440} {
		margin-top: 30px;
		margin-bottom: 18px;
		font-size: 20px;
	}
`

const ChatRoomsContainer = styled.div`
	display: flex;
	align-items: stretch;
	justify-content: center;
	flex: 1;
	margin: 0 -20px;
`
const MainRoomContainer = styled.div`
	flex-basis: 38%;
	display: flex;
`
const CommonRoomContainer = styled.div`
	padding: 25px 20px 25px 20px;
	flex-basis: 31%;
	display: flex;

	${ui.responsive.desktop1440} {
		padding: 30px 20px 30px 20px;
	}
`

const Info = styled.div`
	border: 2px solid ${Colors.heather};
	border-radius: 50%;
	color: ${Colors.heather};
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: Ubuntu;
	font-size: 16px;
	font-weight: 500;
	margin-left: 10px;
	height: 23px;
	width: 23px;
`
const BottomMenuContainer = styled.div`
	margin-top: 10px;

	${ui.responsive.desktop1440} {
		margin-top: 20px;
	}
`

const LobbyEndUser: FC = () => {
	const dispatch = useDispatch()
	const beeldayClient = useAuthenticatedBeeldayClient()
	const training = useSelector((state: RootState) => state.sessionInfo.training)
	const user = useAssertedWorkflowUser()
	const upperEchelonProfile = useProfileFor(training?.upperEchelon.userId)
	const address = useAssertedJoinedRoomAddress()
	const rooms = useSelector(visiblePublicChatRooms)
	const users = useMemo(
		() =>
			map(
				flatMap(rooms, r => r.users),
				'id'
			),
		[rooms]
	)

	useEffect(() => {
		beeldayClient.getPublicChatRooms(address).then(rooms => {
			dispatch(setCurrentPublicChatRooms(rooms))
		})
	}, [dispatch, beeldayClient, address])

	const requiredProfiles = training
		? [...users, user.id, training.upperEchelon.userId]
		: [...users, user.id]
	useFetchMissingProfiles(requiredProfiles)

	const joinPublicChatRoom = useCallback(
		(room: RoomId) => {
			beeldayClient.transfer(address, room)
		},
		[beeldayClient, address]
	)

	const containerHeight = use100vh()

	const organization = useOrganization(training?.organizationId || '')

	return (
		<RoomContainer
			style={{
				backgroundColor: `${Colors.paleGray}`,
				height: `${containerHeight}px`,
			}}
		>
			<LobbyHeader training={training} organization={organization} />
			<NarrowContentContainer>
				<Body>
					<Instruction>
						<Translate>lobby.instruction.join</Translate>
						<Info>i</Info>
					</Instruction>
					<ChatRoomsContainer>
						{map(rooms, r => {
							if (r.type === 'UPPER_ECHELON')
								return (
									<MainRoomContainer key={r.id}>
										<MeetTrainerRoom
											room={r}
											upperEchelon={upperEchelonProfile}
											onSelect={() => joinPublicChatRoom(r.id)}
										/>
									</MainRoomContainer>
								)
							else
								return (
									<CommonRoomContainer key={r.id}>
										<RoomOther
											room={r}
											onSelect={() => joinPublicChatRoom(r.id)}
										/>
									</CommonRoomContainer>
								)
						})}
					</ChatRoomsContainer>
					<BottomMenuContainer>
						<Menu>
							<MiscPanelMenu />
						</Menu>
					</BottomMenuContainer>
				</Body>
				<MiscPanel />
			</NarrowContentContainer>
			<ui.BeeldayLogoAbsoluteBottom />
		</RoomContainer>
	)
}

export default LobbyEndUser
