import styled from '@emotion/styled'

export const H1 = styled.h1`
	padding: 0;
	margin: 0;
	line-height: normal;
	font-size: 28px;
	font-weight: bold;
`

export const H2 = styled.h2`
	padding: 0;
	margin: 0;
	line-height: normal;
	font-size: 20px;
	font-weight: 500;
`

export const H3 = styled.h3`
	padding: 0;
	margin: 0;
	line-height: normal;
	font-size: 18px;
	font-weight: 500;
`

export const H5 = styled.h3`
	padding: 0;
	margin: 0;
	line-height: normal;
	font-size: 16px;
	font-weight: 500;
`

export default {
	H1,
	H2,
	H3,
	H5,
}
