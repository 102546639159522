import { RootState } from 'common/redux'
import { Color, GroupAddress } from 'common/types'
import { ExcalidrawSessionDto } from 'connectivity/beelday-client'
import { useAuthenticatedBeeldayClient } from 'connectivity/beelday-hooks'
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ExcalidrawContainer from './presentation/excalidraw-container'

type Props = {
	groupAddress: GroupAddress
	groupColor: Color
}

export const ConfiguredExcalidraw: FC<Props> = ({
	groupAddress,
	groupColor,
}) => {
	const beeldayClient = useAuthenticatedBeeldayClient()
	const [session, setSession] = useState<ExcalidrawSessionDto>()
	const excalidrawState = useSelector(
		(state: RootState) => state.groupRoom.excalidraw[groupAddress.groupId]
	)
	const loaded = excalidrawState?.loaded
	useEffect(() => {
		if (loaded) {
			beeldayClient.getExcalidrawSession(groupAddress).then(s => setSession(s))
		}
	}, [beeldayClient, loaded, groupAddress])

	return session && excalidrawState && excalidrawState.loaded ? (
		<ExcalidrawContainer
			color={groupColor}
			loaded={true}
			boardId={session.boardId.value}
			viewModeEnabled={session.role !== 'CREATOR'}
		/>
	) : null
}
