export const FinishReview: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="19.019"
		viewBox="0 0 24 19.019"
	>
		<g data-name="Group 25597">
			<path
				data-name="Path 11829"
				d="M21.69 7.709a.273.273 0 0 1-.17-.23.23.23 0 0 1 .04-.14.182.182 0 0 1 .11-.1 2.749 2.749 0 1 0-3.66-1.33 2.731 2.731 0 0 0 1.33 1.33.259.259 0 0 1 .11.1.319.319 0 0 1 .03.14.239.239 0 0 1-.05.14.313.313 0 0 1-.11.09 3.517 3.517 0 0 0-1.35.88 6.58 6.58 0 0 0-3.39-3.38 3.242 3.242 0 0 0-3.85-4.95 3.254 3.254 0 0 0-1.72 4.26 3.327 3.327 0 0 0 .38.66 6.526 6.526 0 0 0-3.42 3.34 3.335 3.335 0 0 0-1.28-.81.277.277 0 0 1-.17-.23.23.23 0 0 1 .04-.14.266.266 0 0 1 .11-.1 2.749 2.749 0 1 0-3.66-1.32 2.794 2.794 0 0 0 1.33 1.32.346.346 0 0 1 .11.1.223.223 0 0 1 .03.14.326.326 0 0 1-.04.14.273.273 0 0 1-.12.09 3.522 3.522 0 0 0-2.32 3.3v1.5a.585.585 0 0 0 .04.19.619.619 0 0 0 .11.17.5.5 0 0 0 .35.14h.83a.25.25 0 0 1 .25.22l.42 3.85a.532.532 0 0 0 .17.31.46.46 0 0 0 .33.13h2a.46.46 0 0 0 .33-.13.443.443 0 0 0 .17-.31l.43-3.85a.224.224 0 0 1 .08-.15.238.238 0 0 1 .11-.06 6.569 6.569 0 0 0 4.29 4.46l.09 1.08a.5.5 0 0 0 .5.46h3a.467.467 0 0 0 .34-.13.5.5 0 0 0 .16-.33l.09-1.12a6.547 6.547 0 0 0 4.19-4.43h.05a.25.25 0 0 1 .25.22l.42 3.85a.532.532 0 0 0 .17.31.46.46 0 0 0 .33.13h2a.46.46 0 0 0 .33-.13.443.443 0 0 0 .17-.31l.43-3.85a.224.224 0 0 1 .08-.15.219.219 0 0 1 .17-.07h.82a.406.406 0 0 0 .19-.04.451.451 0 0 0 .27-.27.406.406 0 0 0 .04-.19v-1.5a3.5 3.5 0 0 0-2.31-3.3M16 13.349a3.388 3.388 0 0 1-.41.65 4.45 4.45 0 0 1-1.31 1.17 4.559 4.559 0 0 1-6-1.21 3.067 3.067 0 0 1-.28-.42 4.544 4.544 0 0 1-.63-2.31A4.583 4.583 0 0 1 14.86 7.7a.176.176 0 0 0 .05.04 4.6 4.6 0 0 1 1.62 3.49 4.472 4.472 0 0 1-.53 2.12"
			/>
			<path
				data-name="Path 11830"
				d="M15.7 11.229a.762.762 0 0 1-.76.77H8.96a.765.765 0 0 1 0-1.53h5.98a.76.76 0 0 1 .76.76"
			/>
		</g>
	</svg>
)
