import { colors, icons, intl } from '@beelday/common'
import styled from '@emotion/styled'
import { useCallback, useState } from 'react'

const Container = styled.div`
	align-items: center;
	background-color: ${colors.white};
	border-radius: 28px;
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.15);
	display: flex;
	justify-content: space-between;
	height: fit-content;
	padding: 10px;
	width: fit-content;
`

const IconContainer = styled.div`
	align-items: center;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	padding: 10px;
	color: ${colors.indigoBlue};
	min-width: 36px;
	height: 36px;
	background-color: ${colors.indigoBlue10};
`

const ContentContainer = styled.div`
	align-items: center;
	display: flex;
	font-size: 14px;
	justify-content: center;
	width: 100%;
	text-align: center;
	padding: 0px 16px;
`

const DismissContainer = styled.div`
	align-items: center;
	cursor: pointer;
	display: flex;
	height: 32px;
	justify-content: center;
	width: 32px;
	padding: 10px;
`
type Props = {
	icon: JSX.Element
	content: JSX.Element | string
}

const Notification = ({ icon, content }: Props): JSX.Element | null => {
	const [isDismissed, setIsDismissed] = useState(false)

	const onDismiss = useCallback(() => setIsDismissed(true), [])

	const translated =
		typeof content === 'string' ? (
			<intl.Translate>{content}</intl.Translate>
		) : (
			content
		)

	if (isDismissed) return null

	return (
		<Container>
			<IconContainer>{icon}</IconContainer>
			<ContentContainer>{translated}</ContentContainer>
			<DismissContainer onClick={onDismiss}>
				<icons.CloseIcon />
			</DismissContainer>
		</Container>
	)
}

export const GroupWorkInProgressNotification = (): JSX.Element => (
	<Notification
		icon={<icons.HourglassIcon />}
		content="lobby.notification.grouping_in_progress"
	/>
)
