import { useAssertedWorkflowUser } from 'room/common/use-workflow-user'
import { useProfileFor } from 'users/redux'
import { SceneVideoUser } from 'video-conference-media'
import MultipleChoicePolling from './multiple-choice-polling'

const ConfiguredMultopleChoicePolling = ({
	isTrainer,
	streams,
}: {
	isTrainer: boolean
	streams: SceneVideoUser[]
}): JSX.Element => {
	const currentUser = useAssertedWorkflowUser()
	const userProfile = useProfileFor(currentUser.id)
	return (
		<MultipleChoicePolling
			streams={streams}
			isTrainer={isTrainer}
			userProfile={userProfile}
		/>
	)
}

export default ConfiguredMultopleChoicePolling
