import { http } from '@beelday/common'
import { RoomAddress } from 'common/types'
import { useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'

export type QuestionApi = {
	finishVoting: (address: RoomAddress) => Promise<void>
	startVoting: (address: RoomAddress) => Promise<void>
	voteNo: (address: RoomAddress) => Promise<void>
	voteYes: (address: RoomAddress) => Promise<void>
}

export const createQuestionApi = (serverUrl: string): QuestionApi => ({
	finishVoting: (address: RoomAddress) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/simplequestions/finish`,
			{
				method: 'PUT',
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		).then(http.checkStatus),
	startVoting: (address: RoomAddress) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/simplequestions`,
			{
				method: 'POST',
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		).then(http.checkStatus),
	voteNo: (address: RoomAddress) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/simplequestions/no`,
			{
				method: 'POST',
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		).then(http.checkStatus),
	voteYes: (address: RoomAddress) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/simplequestions/yes`,
			{
				method: 'POST',
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		).then(http.checkStatus),
})

export const useQuestionApi = (serverUrl: string): QuestionApi => {
	return useMemo(() => createQuestionApi(serverUrl), [serverUrl])
}

export default useQuestionApi
