import { FunctionComponent, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Countdown from 'interaction-scheme/presentation/countdown'
import { useRoomTransferMessage } from 'interaction-scheme/room-transfer-message'
import { muteVcrScene, unmuteVcrScene } from 'video-conference-media/redux'
import { clearISRoomTransferWithCountdown } from 'interaction-scheme/redux'

export const RoomTransferCountdown: FunctionComponent = () => {
	const dispatch = useDispatch()
	const onCountdownFinished = useCallback(
		() => dispatch(clearISRoomTransferWithCountdown()),
		[dispatch]
	)
	const roomTransferMessage = useRoomTransferMessage()
	const isCountdownRunning = !!roomTransferMessage

	useEffect(() => {
		if (isCountdownRunning) {
			dispatch(muteVcrScene())
			return () => {
				dispatch(unmuteVcrScene())
			}
		}
	}, [dispatch, isCountdownRunning])

	return (
		<Countdown
			runningCountdown={roomTransferMessage}
			onCountdownFinished={onCountdownFinished}
		/>
	)
}
