import { FC } from 'react'
import styled from '@emotion/styled'
import { Colors } from 'common/presentation/colors'
import { translate } from 'intl/translate'

type Props = {
	message: string
}

const Container = styled.div`
	background: #fff;
	background-size: 100% 100%;
	position: fixed;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	margin: 0 auto;
	padding: 10px 50px 40px 50px;
	width: 30vw;
	z-index: 10000;
	border-radius: 0 0 20px 20px;
`

const Text = styled.div`
	color: ${Colors.indigoBlue};
	font-family: Ubuntu;
	font-size: 18px;
	font-weight: 500;
	text-align: center;
`

const OfflineFriendlyInfoBanner: FC<Props> = ({ message }) => {
	return (
		<Container>
			<Text>{translate(message)}</Text>
		</Container>
	)
}

export default OfflineFriendlyInfoBanner
