import { func, http } from '@beelday/common'
import { RoomAddress, UUID } from 'common/types'
import { buildRoomUrl } from 'utils/http'
import {
	NewPollingMultipleChoice,
	PollingMultipleChoicePollingDefinition,
	PollingMultipleChoicePollingId,
} from './multiple-choice-polling-models'

interface MultipleChoicePollingApi {
	getPollingDefinitions: (
		room: RoomAddress
	) => Promise<PollingMultipleChoicePollingDefinition[]>
	createMultipleChoice: (
		room: RoomAddress,
		polling: NewPollingMultipleChoice
	) => Promise<PollingMultipleChoicePollingDefinition>
	sendAnswer: (
		room: RoomAddress,
		pollingId: PollingMultipleChoicePollingId,
		answerIds: UUID[]
	) => Promise<void>
	startPolling: (
		room: RoomAddress,
		pollingId: PollingMultipleChoicePollingId
	) => Promise<void>
	stopPolling: (
		room: RoomAddress,
		pollingId: PollingMultipleChoicePollingId
	) => Promise<void>
}

const createMultipleChoicePollingApi = (
	serverUrl: string
): MultipleChoicePollingApi =>
	<MultipleChoicePollingApi>{
		getPollingDefinitions: (
			room: RoomAddress
		): Promise<PollingMultipleChoicePollingDefinition[]> => {
			return http
				.fetch(
					buildRoomUrl(serverUrl, room, '/features/multiple-choice-polling')
				)
				.then(http.checkStatus)
				.then(http.parseBody)
		},
		createMultipleChoice: (room, polling) => {
			http
				.fetch(
					buildRoomUrl(serverUrl, room, '/features/multiple-choice-polling'),
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify(polling),
					}
				)
				.then(http.checkStatus)
				.then(http.parseBody)
		},
		startPolling: (room: RoomAddress, pollingId): Promise<void> => {
			return http
				.fetch(
					buildRoomUrl(serverUrl, room, '/features/multiple-choice-polling'),
					{
						method: 'PUT',
						body: JSON.stringify({ pollingId }),
					}
				)
				.then(http.checkStatus)
				.then(http.parseBody)
		},
		stopPolling: (room: RoomAddress, pollingId): Promise<void> => {
			return http
				.fetch(
					buildRoomUrl(serverUrl, room, '/features/multiple-choice-polling'),
					{
						method: 'DELETE',
						body: JSON.stringify({ pollingId }),
					}
				)
				.then(http.checkStatus)
				.then(http.parseBody)
		},
		sendAnswer: (
			room: RoomAddress,
			pollingId,
			answerIds: UUID[]
		): Promise<void> => {
			return http
				.fetch(
					buildRoomUrl(
						serverUrl,
						room,
						'/features/multiple-choice-polling/answer'
					),
					{
						method: 'POST',
						body: JSON.stringify({ pollingId, answerIds }),
					}
				)
				.then(http.checkStatus)
		},
	}

export const getMultipleChoicePollingApi = func.memoizeSingle(
	createMultipleChoicePollingApi
)
