import React, { FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { intl } from '@beelday/common'
import { Colors } from 'common/presentation/colors'
import group1url from './icons/group1.svg'
import group2url from './icons/group2.svg'
import group3url from './icons/group3.svg'
import group4url from './icons/group4.svg'
import group5url from './icons/group5.svg'
import group6url from './icons/group6.svg'
import group7url from './icons/group7.svg'
import group8url from './icons/group8.svg'
import group9url from './icons/group9.svg'

type Props = {
	score?: number
	handRaised?: boolean
}

const getBaseScore = (score: number) => Math.round(score * 10)

const getScoreUrl = (score: number): string => {
	switch (score) {
		case 10:
		case 9:
			return group9url
		case 8:
			return group8url
		case 7:
			return group7url
		case 6:
			return group6url
		case 5:
			return group5url
		case 4:
			return group4url
		case 3:
			return group3url
		case 2:
			return group2url
		case 1:
		default:
			return group1url
	}
}

const Gauge = styled.div`
	width: 60px;
	height: auto;
	display: inline-block;
	margin-right: 10px;
	position: relative;

	& > img {
		width: 100%;
	}

	.tooltip {
		display: none;
		position: absolute;
		background: ${Colors.darkBlue};
		color: white;
		font-family: Ubuntu;
		font-size: 13px;
		text-align: center;
		padding: 8px;
		border-radius: 4px;
		width: 150px;
		max-width: 260px;
		bottom: 70px;
		left: 50%;
		transform: translateX(-50%);

		strong {
			display: block;
			margin-top: 2px;
		}

		&:after {
			position: absolute;
			bottom: -6px;
			content: '';
			width: 0;
			height: 0;
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
			border-top: 6px solid ${Colors.darkBlue};
			left: 50%;
			transform: translateX(-6px);
		}
	}

	&:hover .tooltip,
	&:focus .tooltip {
		display: block;
	}
`

const GroupEngagementLabel: FunctionComponent<{ score: number }> = ({
	score,
}) => {
	if (score <= 3) {
		return <intl.Translate>groups.engagement-poor</intl.Translate>
	} else if (score <= 6) {
		return <intl.Translate>groups.engagement-fair</intl.Translate>
	} else {
		return <intl.Translate>groups.engagement-good</intl.Translate>
	}
}

export const GroupEngagementGauge: FunctionComponent<Props> = ({
	score,
	handRaised,
}) => {
	if (typeof score === 'undefined') {
		return null
	}

	const baseScore = getBaseScore(score)
	const url = getScoreUrl(baseScore)

	return (
		<Gauge style={{ opacity: handRaised ? 0.5 : 1 }}>
			<div className="tooltip">
				<intl.Translate>groups.engagement</intl.Translate>
				<strong>
					<GroupEngagementLabel score={baseScore} />
				</strong>
			</div>
			<img src={url} alt="Group engagement" />
		</Gauge>
	)
}
