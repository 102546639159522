import { ControlEvent } from './remote-control-models'
import { logger } from '@beelday/common'

const log = logger.create('remote-control')

export type RemoteControlToken = string

export type Client = {
	forward: (e: ControlEvent) => Promise<unknown>
	disconnect: () => Promise<void>
}

export const openConnection = (
	url: string,
	token: RemoteControlToken
): Promise<Client> => {
	return new Promise((resolve, reject) => {
		log.info('Connecting to remote control server', url)
		let socket = new WebSocket(`${url}/${token}`)
		let resolved = false

		socket.onclose = () => {
			log.info('Socket connection closed, reconnecting')
			socket = new WebSocket(`${url}/${token}`)
		}
		socket.onerror = e => {
			log.error('Socket error', e)
			socket.close()
			if (!resolved) {
				reject(e)
			}
		}
		socket.onopen = () => {
			socket.send(JSON.stringify({ type: 'join' }))
			resolved = true
			resolve({
				forward: (e: ControlEvent): Promise<void> => {
					socket.send(JSON.stringify(e))
					return Promise.resolve()
				},
				disconnect: () => {
					socket.send(JSON.stringify({ type: 'leave' }))
					log.info('Shutdown remote control server connection')
					socket.onclose = null
					socket.close()
					return Promise.resolve()
				},
			})
		}
	})
}
