function parseText(text: string) {
	const results = []

	const findUrlRegex = /(?:(?:https?:\/\/)|(?:www\.))[^\s]+/g

	let match: RegExpExecArray | null
	let indexOfStartOfString = 0

	do {
		match = findUrlRegex.exec(text)

		if (match) {
			const matchText = text.substr(
				indexOfStartOfString,
				match.index - indexOfStartOfString
			)

			results.push({ type: 'text', value: matchText })
			results.push({ type: 'url', value: match[0] })

			indexOfStartOfString = match.index + match[0].length
		}
	} while (match)

	const remainingText = text.substr(
		indexOfStartOfString,
		text.length - indexOfStartOfString
	)
	results.push({ type: 'text', value: remainingText })

	return results
}

export const SlugifyString = ({ text }: { text: string }): JSX.Element => {
	const results = parseText(text)

	const content = results.map(part => {
		return part.type === 'url' ? (
			<a href={part.value} target={'_blank'} rel={'noreferrer'}>
				{part.value}
			</a>
		) : (
			part.value
		)
	})

	return <>{content}</>
}
