import { intl, ui } from '@beelday/common'
import { useDispatch } from 'app-redux'
import { RootState } from 'common/redux'
import { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { SceneVideoUser } from 'video-conference-media'
import { UUID } from '../../common/types'
import { setReview } from './knowledge-checks-redux'
import KnowledgeCheckTrainerReviewGridView from './presentation/knowledge-check-trainer-review-grid-view'
import useKnowledgeChecks from './use-knowledge-checks'

const ConfiguredKnowledgeCheckReviewTrainer: FC<{
	knowledgeCheckId: UUID
	streams: SceneVideoUser[]
}> = ({ knowledgeCheckId, streams }) => {
	const dispatch = useDispatch()
	const roomAddress = useAssertedJoinedRoomAddress()
	const { getReview, close, nextReviewQuestion, prevReviewQuestion } =
		useKnowledgeChecks()
	const confirm = ui.useConfirm()
	const training = useSelector((state: RootState) => state.sessionInfo.training)
	const trainerProfile = useSelector(
		(state: RootState) =>
			state.users.all[
				training?.upperEchelon.userId ? training?.upperEchelon.userId : ''
			]
	)
	const review = useSelector((state: RootState) => state.knowledgeChecks.review)

	useEffect(() => {
		if (knowledgeCheckId) {
			getReview(roomAddress, knowledgeCheckId).then(reviewData =>
				dispatch(setReview(reviewData))
			)
		}
	}, [getReview, roomAddress, knowledgeCheckId, dispatch])

	const onCloseReview = () => {
		if (knowledgeCheckId) {
			confirm
				.generic(
					<intl.Translate>
						knowledge_check.review.finish.popup.title
					</intl.Translate>,
					<intl.Translate>
						knowledge_check.review.finish.popup.desc
					</intl.Translate>,
					<intl.Translate>
						knowledge_check.review.finish.popup.confirm
					</intl.Translate>,
					<intl.Translate>
						knowledge_check.review.finish.popup.cancel
					</intl.Translate>
				)
				.then(() => close(roomAddress, knowledgeCheckId))
		}
	}

	const onPrev = () => {
		if (knowledgeCheckId) {
			prevReviewQuestion(roomAddress, knowledgeCheckId).then(() => {
				console.log('prevReviewQuestion')
			})
		}
	}

	const onNext = () => {
		if (knowledgeCheckId) {
			nextReviewQuestion(roomAddress, knowledgeCheckId).then(() => {
				console.log('prevReviewQuestion')
			})
		}
	}

	return review ? (
		<KnowledgeCheckTrainerReviewGridView
			sceneVideoUsers={streams}
			userProfile={trainerProfile}
			review={review}
			onPrev={onPrev}
			onNext={onNext}
			onClose={onCloseReview}
		/>
	) : null
}

export default ConfiguredKnowledgeCheckReviewTrainer
