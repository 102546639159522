import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FC } from 'react'
import { DropzoneRootProps, useDropzone } from 'react-dropzone'
import colors from '../colors'
import { Trash } from '../icons'
import { Translate } from '../intl'
import { ButtonChunk, Emphasize } from '../intl/translate'
import styles from '../styles'
import { ButtonMinor, ButtonPrimary, UnstyledButton } from './button'
import { FillSpace } from './containers'
import {
	Modal,
	ModalButtons,
	ModalContainer,
	ModalContent,
	ModalHeader,
} from './modal'
import { CircleProgressSmall } from './circle-progress-small'
import React from 'react'

type Props = {
	title?: JSX.Element | string
	header?: JSX.Element
	disabled?: boolean
	accept?: string
	done?: boolean
	onSelect: (files: File[]) => unknown
	onConfirm: () => unknown
	onCancel: () => unknown
	children?: JSX.Element[]
}
export const UploadMultipleFiles: FC<Props> = ({
	title,
	header,
	accept,
	disabled,
	done,
	children,
	onSelect,
	onCancel,
	onConfirm,
}) => {
	const { isDragActive, getRootProps, getInputProps } = useDropzone({
		onDrop: onSelect,
		multiple: true,
		accept,
		disabled,
	})

	return (
		<Modal backgroundColor={colors.transparentBlackish}>
			<ModalContainer style={{ width: '600px' }}>
				<ModalHeader
					title={title || <Translate>upload_file.title</Translate>}
					onClose={onCancel}
				/>
				<ModalContent>
					{header}
					<input {...getInputProps()} />
					<DropContainer {...getRootProps({ isDragActive })}>
						<Title>
							<Translate em={Emphasize}>upload_file.dnd.title</Translate>
						</Title>
						<Subtitle>
							<Translate link={ButtonChunk}>upload_file.dnd.subtitle</Translate>
						</Subtitle>
					</DropContainer>
					{children}
				</ModalContent>
				{done ? (
					<ModalButtons>
						<ButtonPrimary onClick={onCancel}>
							<Translate>close</Translate>
						</ButtonPrimary>
					</ModalButtons>
				) : (
					<ModalButtons>
						<ButtonMinor onClick={onCancel}>
							<Translate>cancel</Translate>
						</ButtonMinor>
						<ButtonPrimary onClick={onConfirm} disabled={disabled}>
							<Translate>upload_file.confirm</Translate>
						</ButtonPrimary>
					</ModalButtons>
				)}
			</ModalContainer>
		</Modal>
	)
}

export const SingleFileUpload = React.forwardRef<
	HTMLDivElement,
	{
		file: File
		icon: JSX.Element
		percentage?: number
		failed?: boolean
		onRemove?: (file: File) => unknown
	}
>(({ icon, file, percentage, failed, onRemove }, ref) => {
	return (
		<div
			ref={ref}
			css={css`
				margin: 10px 0;
				font-size: 14px;
				padding: 10px;
				border-radius: 11px;
				line-height: 1.15;
				border: 1px solid ${colors.brightGray};
				display: flex;
				align-items: center;
				column-gap: 8px;
			`}
		>
			<div style={{ height: '29px', width: '29px' }}>{icon}</div>
			<FillSpace css={styles.ellipsis}>{file.name}</FillSpace>
			{failed ? (
				<span style={{ color: colors.pinkRed }}>
					<Translate>upload_file.failed</Translate>
				</span>
			) : null}
			{percentage !== undefined ? (
				<UploadProgress percentage={percentage} failed={failed} />
			) : onRemove ? (
				<UnstyledButton
					onClick={() => onRemove(file)}
					css={css`
						color: ${colors.heather};
						padding-top: 2px;
						${styles.zoomOnHover}
						svg {
							width: 16px;
						}
					`}
				>
					<Trash />
				</UnstyledButton>
			) : null}
		</div>
	)
})
SingleFileUpload.displayName = 'SingleFileUpload'

const UploadProgress: FC<{ percentage: number; failed?: boolean }> = ({
	percentage,
	failed,
}) => {
	return (
		<CircleProgressSmall
			percentage={percentage}
			size={14}
			color={
				failed
					? colors.pinkRed
					: percentage >= 100
					? colors.greenishCyan
					: colors.indigoBlue
			}
		/>
	)
}

const DropContainer = styled.div<DropzoneRootProps>`
	border-width: 2px;
	border-style: ${props => (props.isDragActive ? 'solid' : 'dashed')};
	border-color: ${props =>
		props.isDragActive ? colors.indigoBlue : colors.brightGray};
	border-radius: 25px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: 1;
	padding: 30px;
	margin-bottom: 24px;
	cursor: pointer;

	&:last-child {
		margin-bottom: 0;
	}

	em {
		font-style: normal;
		font-weight: bold;
		color: ${colors.indigoBlue};
	}
	button {
		all: unset;
		cursor: pointer;
		color: ${colors.indigoBlue};
		text-decoration: underline;
		font-weight: bold;
	}
`

const Title = styled.div`
	color: ${colors.black};
	font-family: Ubuntu;
	font-size: 24px;
	font-weight: 500;
	margin-bottom: 15px;
	text-align: center;
	${styles.ellipsis};
`

const Subtitle = styled.span`
	color: ${colors.lightGray};
	font-family: Ubuntu;
	font-size: 14px;
	${styles.ellipsis};
`
