import styled from '@emotion/styled'
import { CSSProperties } from 'react'
import colors from '../colors'
import { Translate } from '../intl'
import * as ui from '../ui'
import { INTEREST_ICON, UserProfile } from '../user-api'

const mapping = INTEREST_ICON

const SingleContainer = styled.div`
	bottom: -6px;
	display: flex;
	position: absolute;
	right: 0px;
`

const MultipleContainer = styled.div`
	bottom: -14px;
	display: flex;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
`

const Interest = styled.img`
	display: inline-block;
	background-color: ${colors.white};
	padding: 1px;
	border-radius: 50%;
	height: 33px;
	width: 33px;
	text-transform: capitalize;
`

const Container = styled.div`
	bottom: -20px;
	display: flex;
	position: absolute;
	right: 0;
`

type Props = {
	interests: UserProfile['interests']
	style?: CSSProperties
}

export const ProfileInterests = ({
	interests,
	style,
}: Props): JSX.Element | null => {
	if (!interests) return null

	return interests.length > 1 ? (
		<MultipleContainer>
			{interests.map(i => (
				<Interest style={style} src={mapping[i]} key={i} />
			))}
		</MultipleContainer>
	) : (
		<SingleContainer>
			{interests.map(i => (
				<Interest src={mapping[i]} key={i} />
			))}
		</SingleContainer>
	)
}

export const ProfileInterestsWithPopup = ({
	interests,
	style,
}: Props): JSX.Element | null => {
	if (!interests) return null

	return (
		<Container>
			{interests.map(i => (
				<div key={i}>
					<ui.Tooltip
						placement="top"
						overlayStyle={{ textTransform: 'capitalize' }}
						overlay={<Translate>{`common_interest.${i}`}</Translate>}
					>
						<Interest style={style} src={mapping[i]} />
					</ui.Tooltip>
				</div>
			))}
		</Container>
	)
}
