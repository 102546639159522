import { tablet, desktop1440 } from './responsive'
import styled from '@emotion/styled'
import { css, PropsOf } from '@emotion/react'
import { FC, ReactNode } from 'react'
import colors from '../colors'
import { CancelIcon } from '../icons'
import styles from '../styles'
import Spinner from './spinner'

type Props = {
	color?: keyof typeof colors
	backgroundColor?: keyof typeof colors
	fontColor?: keyof typeof colors
	borderColor?: keyof typeof colors
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onClick?: (e: any) => unknown
	className?: string
	square?: boolean
	small?: boolean
}
export const Button = styled.button<Props>(
	props => css`
		position: relative;
		color: ${colors[props.color || 'white']};
		background-color: ${colors[props.backgroundColor || 'greenishCyan']};
		border: ${props.borderColor
			? `1px solid ${colors[props.borderColor]}`
			: 'none'};
		border-radius: ${props.square ? '11px' : '24px'};
		font-family: Ubuntu;
		font-weight: 600;
		padding: ${props.small ? '0 17px' : '0 40px'};
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-size: 12px;
		height: ${props.small ? '32px' : '48px'};
		margin: 0;
		text-transform: uppercase;
		box-shadow: 0 1px 3px 0 rgba(160, 153, 167, 0.3);

		${desktop1440} {
			padding: ${props.small ? '0 25px' : '0 42px'};
		}

		${tablet} {
			padding: ${props.small ? '0 17px' : '0 30px'};
		}

		&:active {
			opacity: 0.7;
		}

		&:focus {
			outline: none;
		}

		:disabled {
			opacity: 0.5;
		}

		&:not(:disabled) {
			cursor: pointer;
			${styles.zoomOnHover};
		}
	`
)

export const ButtonSpinner: FC<PropsOf<typeof Spinner>> = props => (
	<Spinner
		css={css`
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		`}
		size={32}
		{...props}
	/>
)

export const ButtonPrimary = styled(Button)`
	background-color: ${({ color }) =>
		color ? colors[color] : colors.indigoBlue};
	box-shadow: 3px 5.2px 10px 0 rgba(79, 23, 190, 0.2);
	color: #fff;
`

export const ButtonSecondary = styled(Button)`
	background-color: #fff;
	color: ${({ color }) => (color ? colors[color] : colors.greenishCyan)};
`

export const ButtonMinor = styled(Button)`
	background-color: #fff;
	border: 1px solid ${colors.inactiveGray};
	box-shadow: none;
	color: ${colors.lightGray};
`

export const ButtonInline = styled.button`
	background-color: ${colors.inactiveGray};
	border: none;
	border-radius: 11px;
	color: ${colors.indigoBlue};
	cursor: pointer;
	display: flex;
	align-items: center;
	font-size: 12px;
	font-weight: 500;
	min-width: fit-content;
	padding: 10px 20px;
	transition: opacity 0.25s;

	&:active {
		opacity: 0.5;
	}

	&:focus {
		outline: none;
	}

	&:hover {
		opacity: 0.8;
	}
`

export const ButtonClose: FC<Props> = ({
	onClick,
	className,
	color,
	fontColor,
}) => (
	<button
		className={className}
		onClick={onClick}
		css={css(`
			color: ${fontColor ? fontColor : colors.indigoBlue};
			width: 32px;
			height: 32px;
			min-width: 32px;
			background-color: ${colors[color || 'brightGray']};
			border-radius: 50%;
			padding: 10px;
			border: none;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: color 0.25s, background 0.25s;

			&:focus {
				outline: none;
			}

			&:hover {
				background: ${colors.indigoBlue};
				color: ${colors.white};
			}
		`)}
	>
		<CancelIcon />
	</button>
)

const ButtonLargeContainer = styled.button`
	border: 1px solid ${colors.borderGray50};
	border-radius: 10px;
	color: ${colors.black};
	cursor: pointer;
	min-height: 130px;
	padding: 20px;
	background-color: ${colors.white};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transition: background-color 0.25s, border 0.25s, color 0.25s,
		box-shadow 0.25s;

	font-size: 14px;
	line-height: 17px;
	text-align: center;

	svg {
		width: 30px;
		height: 30px;
		color: ${colors.indigoBlue};
		margin-bottom: 10px;
		transition: color 0.25s;
	}

	&:hover:enabled {
		color: ${colors.white};
		border: 1px solid ${colors.indigoBlue};
		background-color: ${colors.indigoBlue};
		box-shadow: 3px 5.2px 10px 0 rgba(79, 23, 190, 0.2);

		svg {
			color: ${colors.white};
		}
	}

	&:disabled {
		cursor: default;
		background-color: ${colors.heather10};
		color: ${colors.heather50};

		svg {
			color: ${colors.heather50};
		}
	}
`

export const ButtonLarge: FC<PropsOf<typeof Button> & { icon?: ReactNode }> = ({
	icon,
	children,
	...rest
}) => (
	<ButtonLargeContainer {...rest}>
		{icon}
		{children}
	</ButtonLargeContainer>
)

export const LinkButton = styled.button`
	all: unset;
	color: ${colors.indigoBlue};
	text-decoration: underline;
	cursor: pointer;
	font-size: 14px;
	gap: 10px;
	display: flex;
	align-items: center;
	svg {
		width: 14px;
		height: 14px;
		color: ${colors.indigoBlue};
	}
`

export const UnstyledButton = styled.button`
	all: unset;
	cursor: pointer;
`

export default Button
