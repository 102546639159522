export const CompanyLogoPlaceholder: React.FC<{
	color?: string
	square?: boolean
}> = ({}) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="400"
		height="400"
		viewBox="0 0 400 400"
	>
		<g data-name="Group 51571">
			<g data-name="Group 51570" style={{ opacity: '.5' }}>
				<g data-name="Group 4047">
					<g data-name="Group 4046">
						<g data-name="Group 4045">
							<path
								data-name="Exclusion 3"
								d="M63.012 120.615A63.925 63.925 0 0 1 18.5 102.909a58.31 58.31 0 0 1 0-85.2A63.891 63.891 0 0 1 63.012 0a63.942 63.942 0 0 1 44.516 17.706 58.642 58.642 0 0 1 0 85.2 63.942 63.942 0 0 1-44.516 17.709zM51.745 34.659c-5.7 0-10.658 4.6-10.82 10.058v30.448a9.527 9.527 0 0 0 2.7 7.086 11.017 11.017 0 0 0 7.962 3.419 10.839 10.839 0 0 0 5.867-1.752l9.734-6.3a1.576 1.576 0 0 0 .238-.143 1.656 1.656 0 0 0 .171-.1 3.141 3.141 0 0 1 .962-.486L85.08 69.07a9.593 9.593 0 0 0 5.734-8.629 9.811 9.811 0 0 0-5.734-9.077L68.4 43.383a3.555 3.555 0 0 1-1.219-.781l-9.731-6.248a10.384 10.384 0 0 0-5.705-1.695z"
								transform="translate(150.378 137.836)"
								style={{
									stroke: 'transparent',
									strokeMiterlimit: 10,
									fill: '#a099a7',
								}}
							/>
							<path
								data-name="Path 8642"
								d="M48.836 96.8a28.881 28.881 0 0 1-39.561 0 25.946 25.946 0 0 1 0-37.855 28.829 28.829 0 0 1 39.551 0 25.93 25.93 0 0 1 0 37.855m193.388 21.116a14.858 14.858 0 0 1-20.268 0 13.363 13.363 0 0 1 0-19.411 14.858 14.858 0 0 1 20.268 0 13.363 13.363 0 0 1 0 19.411M158.942 24.13a15.122 15.122 0 0 1-20.475 0 13.6 13.6 0 0 1 0-19.6 15.122 15.122 0 0 1 20.475 0 13.278 13.278 0 0 1 0 19.6M52.4 145.316a16.343 16.343 0 0 1-22.654 0 15.034 15.034 0 0 1 0-21.688c6.161-5.905 16.493-6.092 22.654 0a15.034 15.034 0 0 1 0 21.688m177.9 51.174a25.258 25.258 0 0 1-34.583 0 22.718 22.718 0 0 1 0-33.1 25.258 25.258 0 0 1 34.583 0 22.734 22.734 0 0 1 0 33.1"
								transform="translate(75.925 91.461)"
								style={{ fill: '#a099a7' }}
							/>
							<path
								data-name="Path 8643"
								d="M39.347 32.252a19.42 19.42 0 0 1-26.637 0 17.475 17.475 0 0 1 0-25.483 19.44 19.44 0 0 1 26.637 0 17.487 17.487 0 0 1 0 25.493m41.74 170.074a19.44 19.44 0 0 1-26.637 0 17.487 17.487 0 0 1 0-25.493 19.44 19.44 0 0 1 26.637 0 17.5 17.5 0 0 1 0 25.493"
								transform="translate(130.13 99.881)"
								style={{ fill: '#a099a7' }}
							/>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
)
