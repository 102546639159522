import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
	sceneVideoUsersById,
	selectLocalScreenShareStream,
} from 'video-conference-media/redux'
import { OnlyScreenShare } from './screen-share-group'

const ConfiguredScreenShareReview = (): JSX.Element => {
	const localScreenShare = useSelector(selectLocalScreenShareStream)

	if (localScreenShare) {
		return <MeScreenSharing />
	} else {
		return <OtherScreenShare />
	}
}

const MeScreenSharing = (): JSX.Element => {
	const localScreenShare = useSelector(selectLocalScreenShareStream)

	return <OnlyScreenShare screen={localScreenShare} local={true} />
}

const OtherScreenShare = (): JSX.Element => {
	const videoUsers = useSelector(sceneVideoUsersById)
	const screenshare = useMemo(
		() => Object.values(videoUsers).find(s => s.stream?.screenShareTrackId),
		[videoUsers]
	)

	const stream = screenshare?.stream

	const screen = useMemo(() => {
		if (!stream) return
		return new MediaStream(
			stream.getVideoTracks().filter(t => t.id === stream.screenShareTrackId)
		)
	}, [stream])

	if (screen) {
		return <OnlyScreenShare screen={screen} local={false} />
	} else {
		return <OnlyScreenShare />
	}
}

export default ConfiguredScreenShareReview
