export type State = {
	started: boolean
}

const initialState: State = {
	started: false,
}

type Action = {
	type: 'SSE_SIMPLE_GROUPING_STATE_CHANGED'
	payload: {
		enabled: boolean
	}
}

export function reducer(state: State = initialState, action: Action): State {
	switch (action.type) {
		case 'SSE_SIMPLE_GROUPING_STATE_CHANGED':
			return {
				...state,
				started: action.payload.enabled,
			}
		default:
			return state
	}
}
