export type Logger = {
	name?: string
	debug(...args: unknown[]): void
	log(...args: unknown[]): void
	info(...args: unknown[]): void
	warn(...args: unknown[]): void
	error(...args: unknown[]): void
	create(name: string): Logger
}

const enabledLoggers = new Set(['ROOT'])

type LoggerConfig = {
	enable: (name: string) => void
	disable: (name: string) => void
	reset: () => void
}
declare global {
	interface Window {
		beelday_logger: LoggerConfig
	}
}

if (typeof window !== 'undefined') {
	if (window.localStorage) {
		const storedLoggers = window.localStorage
			.getItem('beelday_logger')
			?.split(/\s*,\s*/)

		storedLoggers?.forEach(logger => enabledLoggers.add(logger))
	}

	const saveLoggers = () => {
		if (window.localStorage) {
			window.localStorage.setItem(
				'beelday_logger',
				Array.from(enabledLoggers).join(',')
			)
		}
	}

	if (enabledLoggers?.size) {
		console.warn('Enabled loggers:', enabledLoggers)
	}

	window.beelday_logger = {
		enable(name: string) {
			enabledLoggers.add(name)
			saveLoggers()
		},
		disable(name: string) {
			enabledLoggers.delete(name)
			saveLoggers()
		},
		reset() {
			enabledLoggers.clear()
			saveLoggers()
		},
	}
}

const enabled = (name?: string): boolean => {
	return !name || enabledLoggers.has(name)
}
export const create = (name?: string): Logger => {
	const prefix = name ? `[${name}] ` : ''
	return {
		name,
		create: (childName: string) => {
			return create(name && name != 'ROOT' ? name + '-' + childName : childName)
		},
		log(...args: unknown[]) {
			if (enabled(name)) {
				console.log(prefix, ...args)
			}
		},
		info(...args: unknown[]) {
			if (enabled(name)) {
				console.info(prefix, ...args)
			}
		},
		debug(...args: unknown[]) {
			if (enabled(name)) {
				console.debug(prefix, ...args)
			}
		},
		warn(...args: unknown[]) {
			console.warn(prefix, ...args)
		},
		error(...args: unknown[]) {
			console.error(prefix, ...args)
		},
	}
}

export default create('ROOT')
