import { logger } from '@beelday/common'
import { useSelector } from 'app-redux'
import { pull } from 'lodash'
import React, { FC, useEffect, useState } from 'react'
import { BeeldayMediaStream, SceneVideoUser } from './model/types'
import { selectAllowedStreams } from './redux'

const log = logger.create('VideoComponentsManager')

class VideoComponentsManager {
	private mounted: Record<string, number> = {}
	private video: Record<string, HTMLVideoElement> = {}
	private streams: SceneVideoUser[] = []
	private observers: (() => void)[] = []
	constructor(streams: SceneVideoUser[]) {
		this.streamsChanged(streams)
	}

	public attach(userId: string): HTMLVideoElement | undefined {
		const mounted = this.mounted[userId] ?? 0
		this.mounted[userId] = mounted + 1
		return this.video[userId]
	}

	public detach(userId: string): void {
		const mounted = this.mounted[userId] ?? 0
		this.mounted[userId] = Math.max(mounted, 0)
		if (!this.mounted[userId]) {
			try {
				this.video[userId]?.pause()
			} catch (e) {
				log.error('Failed to pause video on unmount', e)
			}
		}
	}

	public streamsChanged(streams: SceneVideoUser[]): boolean {
		if (!Object.is(this.streams, streams)) {
			const current = this.video || {}
			this.video = {}
			this.streams = streams
			const userIds = streams.map(stream => stream.userId)
			for (const key in current) {
				const video = current[key]
				if (userIds.includes(key)) {
					this.video[key] = video
				} else {
					//Make sure we remove media and video from DOM
					video.srcObject = null
					video.src = ''
					video.pause()
					video.remove()
				}
			}

			streams.forEach(stream => {
				if (!stream.stream) return
				const mute = stream.isSelfView || stream.muted
				const currentVideo = current[stream.userId]
				if (!currentVideo) {
					const video = this.createVideoElement(stream)
					if (video) this.video[stream.userId] = video
				} else if (currentVideo.dataset['streamId'] !== stream.stream.id) {
					currentVideo.srcObject = stream.stream
					currentVideo.dataset['streamId'] = stream.stream.id
					currentVideo.muted = mute
				} else if (currentVideo.muted !== mute) {
					currentVideo.muted = mute
				}
			})

			this.notifyObservers()
			return true
		}
		return false
	}

	public resetStream(userId: string): void {
		delete this.video[userId]
		const stream = this.streams.find(stream => stream.userId === userId)
		const element = stream && this.createVideoElement(stream)
		if (element) {
			this.video[userId] = element
		}
		this.notifyObservers()
	}

	public onStreamsChanged(observer: () => unknown): () => void {
		this.observers.push(observer)
		return () => pull(this.observers, observer)
	}

	private notifyObservers(): void {
		this.observers.forEach(observer => observer())
	}

	private createVideoElement(
		stream: SceneVideoUser
	): HTMLVideoElement | undefined {
		if (stream?.stream) {
			log.info('Create video element for user', stream.userId)

			const video = document.createElement('video')
			video.dataset['streamId'] = stream.stream.id
			video.dataset['userId'] = stream.userId
			//Remove screen sharing tracks
			let mediaStream = stream.stream
			if (stream.stream.screenShareTrackId) {
				mediaStream = new BeeldayMediaStream(
					mediaStream
						.getTracks()
						.filter(track => track.id !== mediaStream.screenShareTrackId)
				)
			}
			if (stream.isSelfView) {
				//Ignore audio tracks on self view
				video.srcObject = new MediaStream(mediaStream.getVideoTracks())
			} else {
				video.srcObject = mediaStream
			}
			video.autoplay = true
			video.playsInline = true
			video.style.width = '100%'
			video.style.height = '100%'
			video.style.objectFit = 'cover'
			video.muted = stream.isSelfView || stream.muted
			return video
		}
	}
}

export const useVideoComponents = (): VideoComponentsManager => {
	const videoComponents = React.useContext(videoComponentsContext)
	if (!videoComponents) {
		throw new Error(
			'useVideoComponents must be used within a VideoComponentsProvider'
		)
	}

	return videoComponents
}

export const videoComponentsContext =
	React.createContext<VideoComponentsManager | null>(null)

export const ProvideVideoComponents: FC = ({ children }) => {
	const streams = useSelector(selectAllowedStreams)
	const [manager] = useState<VideoComponentsManager>(
		() => new VideoComponentsManager(streams)
	)

	useEffect(() => {
		manager.streamsChanged(streams)
	}, [streams, manager])

	if (manager) {
		return (
			<videoComponentsContext.Provider value={manager}>
				{children}
			</videoComponentsContext.Provider>
		)
	} else {
		return null
	}
}
