export const ArrowRight: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="12"
		height="11.694"
		viewBox="0 0 12 11.694"
	>
		<path
			d="M108.617-191.77v-8.6l3.758,3.758a.776.776,0,0,0,1.094,0,.767.767,0,0,0,0-1.086l-5.075-5.075a.767.767,0,0,0-.543-.225.767.767,0,0,0-.543.225l-5.083,5.067a.767.767,0,0,0,0,1.086.767.767,0,0,0,1.086,0l3.766-3.75v8.6a.772.772,0,0,0,.77.77A.772.772,0,0,0,108.617-191.77Z"
			transform="translate(-191 -102) rotate(90)"
			fill="currentColor"
			fillRule="evenodd"
		/>
	</svg>
)
