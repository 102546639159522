import styled from '@emotion/styled'
import { CSSProperties } from 'react'
import { AddIconText } from '.'
import colors from '../colors'

const InlineNewContainer = styled.div<{
	borderColor?: string
	dashed?: boolean
}>`
	align-items: center;
	background-image: ${props =>
		props.dashed
			? `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='${
					props.borderColor
						? props.borderColor.replace('#', '%23')
						: '%23EBEBEBFF'
			  }' stroke-width='4' stroke-dasharray='8%2c 14' stroke-dashoffset='16' stroke-linecap='square'/%3e%3c/svg%3e")`
			: ''};
	border: ${props =>
		!props.dashed ? `1px solid ${colors.borderLightGray}` : ''};
	cursor: pointer;
	display: flex;
	justify-content: center;
	padding: 15px;
	border-radius: 8px;
	opacity: 1;
	transition: opacity 0.25s, background 0.25s, border 0.25s;

	&:hover {
		background-image: ${props =>
			props.dashed
				? `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='${colors.indigoBlue.replace(
						'#',
						'%23'
				  )}' stroke-width='4' stroke-dasharray='8%2c 14' stroke-dashoffset='16' stroke-linecap='square'/%3e%3c/svg%3e")`
				: ''};
		border: ${props => (!props.dashed ? `1px solid ${colors.indigoBlue}` : '')};
		.squarePlus {
			background-color: ${colors.indigoBlue};
			color: ${colors.white};
		}

		.addIconText {
			color: ${colors.indigoBlue};
		}
	}
`

export const InlineAddNewButton = ({
	text,
	onClick,
	borderColor,
	styles,
	dashed,
}: {
	onClick: () => void
	text: string
	borderColor?: string
	styles?: CSSProperties
	dashed?: boolean
}): JSX.Element => (
	<InlineNewContainer
		onClick={onClick}
		style={styles}
		borderColor={borderColor}
		dashed={dashed}
	>
		<AddIconText text={text} />
	</InlineNewContainer>
)
