import { FC, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { translate } from 'intl/translate'
import { Colors } from 'common/presentation/colors'
import {
	CheckedAnswerButton,
	ConfirmedAnswerButton,
	SelectedCorrectAnswerButton,
	SelectedWrongAnswerButton,
	UncheckedAnswerButton,
	UnselectedCorrectAnswerButton,
} from 'room/training-room/ice-breaker/presentation/answer-buttons'
import { Spinner } from 'common/presentation/spinner'
import { QuizAnswerId } from 'common/types'
import ApproachingContainer from 'common/presentation/approaching-container'
import QuestionImage from 'room/training-room/ice-breaker/presentation/question-image'
import { colors, ui } from '@beelday/common'

export type AvailableAnswer = {
	id: QuizAnswerId
	text: string
	confirmed: boolean
	correct?: boolean
}

type Props = {
	text: string
	imageUrl?: string
	availableAnswers: AvailableAnswer[]
	onConfirm: (answerId: QuizAnswerId) => void
}

const FlexContainer = styled.div`
	align-items: stretch;
	display: flex;
	flex-flow: column nowrap;
	height: 100%;
	width: 100%;
	padding: 0 5px;
	position: relative;
`

const QuizContent = styled.div`
	margin-bottom: 62px;
	overflow: auto;

	&::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 7px;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: rgba(0, 0, 0, 0.5);
		box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
		-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
	}
`

type TitleProps = {
	color: string
}

const Title = styled.div`
	font-family: Ubuntu;
	font-weight: 300;
	font-size: 14px;
	color: ${(props: TitleProps) => props.color};
`

const QuestionText = styled.div`
	font-family: Ubuntu;
	font-weight: 500;
	color: ${Colors.black};
	font-size: 18px;
	padding: 6px 0 10px 0;

	${ui.responsive.desktop1440} {
		font-size: 20px;
		padding: 10px 0 10px 0;
	}
`

const ActionContainer = styled.div`
	position: absolute;
	background-color: ${colors.white};
	left: 0;
	bottom: 0;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	flex: 0 0 50px;
	padding-top: 10px;
`

const LoadingContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
`

const LoadingMessage = styled.div`
	padding-left: 10px;
	font-family: Ubuntu;
	font-weight: 300;
	font-size: 14px;
	color: ${Colors.heather};
`

type AnswerButtonProps = {
	answer: AvailableAnswer
	checked: boolean
	onClick: () => void
}

type AnswerButtonContainerProps = {
	concise: boolean
}

const AnswerButtonContainer = styled.div`
	flex: 1 0
		${(props: AnswerButtonContainerProps) => (props.concise ? '50%' : '100%')};
	padding: 5px;
`

const AnswerButton: FC<AnswerButtonProps> = ({ answer, checked, onClick }) => {
	if (answer.correct === undefined) {
		if (answer.confirmed) {
			return (
				<ConfirmedAnswerButton onClick={onClick}>
					{answer.text}
				</ConfirmedAnswerButton>
			)
		} else if (checked) {
			return (
				<CheckedAnswerButton onClick={onClick}>
					{answer.text}
				</CheckedAnswerButton>
			)
		} else {
			return (
				<UncheckedAnswerButton onClick={onClick}>
					{answer.text}
				</UncheckedAnswerButton>
			)
		}
	} else if (answer.correct) {
		if (answer.confirmed) {
			return (
				<SelectedCorrectAnswerButton onClick={onClick}>
					{answer.text}
				</SelectedCorrectAnswerButton>
			)
		} else {
			return (
				<UnselectedCorrectAnswerButton onClick={onClick}>
					{answer.text}
				</UnselectedCorrectAnswerButton>
			)
		}
	} else {
		if (answer.confirmed) {
			return (
				<SelectedWrongAnswerButton onClick={onClick}>
					{answer.text}
				</SelectedWrongAnswerButton>
			)
		} else {
			return (
				<UncheckedAnswerButton onClick={onClick}>
					{answer.text}
				</UncheckedAnswerButton>
			)
		}
	}
}

type AnswerContainerProps = {
	concise: boolean
	grow: boolean
}

const AnswerContainer = styled.div`
	display: flex;
	${(props: AnswerContainerProps) =>
		props.concise
			? 'flex-flow: row wrap; align-content: flex-end;'
			: 'flex-flow: column nowrap; justify-content: flex-end;'}
	flex-flow: row wrap;
	align-content: flex-end;
	flex-grow: ${(props: AnswerContainerProps) => (props.grow ? 1 : 0)};
	margin-bottom: 10px;
`

type State = 'selecting' | 'confirmed' | 'correct' | 'wrong' | 'unanswered'

const PlayableQuizQuestion: FC<Props> = ({
	text,
	imageUrl,
	availableAnswers,
	onConfirm,
}) => {
	const [checked, setChecked] = useState<QuizAnswerId>()

	const state = useMemo((): State => {
		const confirmedAnswer = availableAnswers.find(answer => answer.confirmed)
		const correctAnswer = availableAnswers.find(answer => answer.correct)
		if (confirmedAnswer) {
			if (correctAnswer) {
				return confirmedAnswer.id === correctAnswer.id ? 'correct' : 'wrong'
			} else {
				return 'confirmed'
			}
		} else {
			return correctAnswer ? 'unanswered' : 'selecting'
		}
	}, [availableAnswers])

	useEffect(() => {
		state === 'selecting' && setChecked(undefined)
	}, [state])

	return (
		<ApproachingContainer>
			<FlexContainer>
				<QuizContent
					style={
						state === 'selecting' && !checked ? { marginBottom: '0px' } : {}
					}
				>
					{state === 'correct' ? (
						<Title color={Colors.greenishCyan}>
							{translate('quiz.hooray')}
						</Title>
					) : state === 'wrong' ? (
						<Title color={Colors.strongPink}>{translate('quiz.oops')}</Title>
					) : (
						<Title color={Colors.heather}>
							{translate('quiz.select_answer')}
						</Title>
					)}
					<QuestionText>{text}</QuestionText>
					{imageUrl && <QuestionImage imageUrl={imageUrl} />}
					<AnswerContainer concise={!!imageUrl} grow={!imageUrl}>
						{availableAnswers.map(answer => (
							<AnswerButtonContainer key={answer.id} concise={!!imageUrl}>
								<AnswerButton
									key={answer.id}
									answer={answer}
									checked={checked === answer.id}
									onClick={() => state === 'selecting' && setChecked(answer.id)}
								/>
							</AnswerButtonContainer>
						))}
					</AnswerContainer>
				</QuizContent>
				{state === 'selecting' && !checked ? (
					<></>
				) : (
					<ActionContainer>
						{state === 'selecting' && checked ? (
							<ui.ButtonPrimary onClick={() => onConfirm(checked)}>
								{translate('quiz.confirm_answer')}
							</ui.ButtonPrimary>
						) : state !== 'selecting' ? (
							<LoadingContainer>
								<Spinner size={30} color={Colors.indigoBlue} />
								<LoadingMessage>
									{translate('quiz.wait_message')}
								</LoadingMessage>
							</LoadingContainer>
						) : (
							<></>
						)}
					</ActionContainer>
				)}
			</FlexContainer>
		</ApproachingContainer>
	)
}

export default PlayableQuizQuestion
