import { hooks, security } from '@beelday/common'
import { useSelector } from 'app-redux'
import Logger from 'common/logger'
import { RootState } from 'common/redux'
import {
	selectCurrentUserMuted,
	selectCurrentUserVideoMuted,
} from 'interaction-scheme/redux'
import React, {
	FunctionComponent,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { useAssertedWorkflowUser } from 'room/common/use-workflow-user'
import { useMediaDevices } from 'utils/hooks/use-media-devices'
import {
	othersStream,
	selectForceBitRate,
	selectLocalStream,
	selectVCRConfig,
	selectVCRId,
	setLocalUserStream,
} from 'video-conference-media/redux'
import { JunoVCR } from './juno-vcr'
import { VcrConfig } from 'common/types'

const logger = new Logger('juno-provider')

type JunoContextType = {
	juno?: JunoVCR
	connectionError?: Error | null
}

const publishBitrateSelector = (state: RootState) =>
	state.videoConferenceMedia.publishBitrate

const useJunoInstance = (
	vcrAddress?: string,
	vcrConfig?: VcrConfig
): JunoVCR | undefined => {
	const accessToken = security.useValidToken()
	const tokenRef = useRef(accessToken)
	tokenRef.current = accessToken

	const { id: userId } = useAssertedWorkflowUser()

	const juno = useMemo(() => {
		if (!vcrAddress) {
			return undefined
		}

		return new JunoVCR(vcrAddress, userId, tokenRef.current, vcrConfig)
		//FIXME: Don't add vcrConfig to deps, but use it in constructor
	}, [userId, vcrAddress])

	useEffect(() => {
		if (vcrConfig) {
			juno?.configure(vcrConfig)
		}
	}, [juno, vcrConfig])

	useEffect(() => {
		juno?.setToken(accessToken)
	}, [accessToken, juno])

	return juno
}

const useConnectToJuno = (): JunoContextType => {
	const dispatch = useDispatch()
	const isMounted = hooks.useIsMounted()
	const localStream = useSelector(selectLocalStream)
	const otherStreams = useSelector(othersStream)
	const isCurrentUserMuted = useSelector(selectCurrentUserMuted)
	const isCurrentUserVideoMuted = useSelector(selectCurrentUserVideoMuted)
	const isCountdown = useSelector(
		state => state.interactionScheme.userTransferWithCountdown
	)

	const address = useSelector(selectVCRId)
	const vcrConfig = useSelector(selectVCRConfig)
	const juno = useJunoInstance(address, vcrConfig)

	const publishBitrate = useSelector(publishBitrateSelector)
	const forceBitrate = useSelector(selectForceBitRate)
	const [connected, setConnected] = useState<boolean>(false)
	const [error, setError] = useState<Error | null>(null)
	const [cannotConnect, setCannotConnect] = useState<boolean>(false)
	const { selectedCamera, selectedMicrophone } = useMediaDevices()

	const handleConfigurationError = useCallback(
		(e: Error) => {
			logger.error(`Cannot set up Juno connection. Running cleanup.`, e)
			juno?.leave()
			if (isMounted()) {
				setError(e)
			}
		},
		[juno, isMounted]
	)

	useEffect(() => {
		if (!publishBitrate && !forceBitrate) {
			return undefined
		}

		juno?.configureBitrate(forceBitrate || publishBitrate)
	}, [juno, publishBitrate, forceBitrate])

	useEffect(() => {
		if (localStream) {
			localStream.getTracks().forEach(t => {
				const enabled = !(
					isCountdown ||
					(t.kind === 'video' && isCurrentUserVideoMuted) ||
					(t.kind === 'audio' && isCurrentUserMuted)
				)

				if (t.enabled !== enabled) {
					t.enabled = enabled
				}
			})
		}
	}, [
		localStream,
		isCurrentUserMuted,
		isCountdown,
		isCurrentUserVideoMuted,
		selectedCamera,
		selectedMicrophone,
	])

	useEffect(() => {
		const enabled = !isCountdown
		otherStreams.forEach(s =>
			s.stream?.getTracks().forEach(t => {
				if (t.enabled !== enabled) {
					t.enabled = enabled
				}
			})
		)
	}, [otherStreams, isCountdown])

	useEffect(() => {
		return () => {
			if (juno) {
				juno.leave()
				setConnected(false)
			}
		}
	}, [address, juno])

	useEffect(() => {
		if (!juno) {
			setConnected(false)
			return undefined
		}

		setError(null)
		let currentStream: MediaStream | undefined
		Promise.resolve(
			vcrConfig?.publish
				? navigator.mediaDevices.getUserMedia({
						video: selectedCamera
							? { deviceId: { exact: selectedCamera } }
							: false,
						audio: { deviceId: { exact: selectedMicrophone } },
				  })
				: undefined
		)
			.then(stream => {
				currentStream = stream
				if (!connected) {
					return juno.join(currentStream).then(() => {
						dispatch(setLocalUserStream(currentStream))
						setConnected(true)
					})
				}
			})
			.catch(handleConfigurationError)
	}, [
		connected,
		dispatch,
		handleConfigurationError,
		juno,
		selectedCamera,
		selectedMicrophone,
		vcrConfig?.publish,
	])

	return {
		juno: connected ? juno : undefined,
		connectionError: error,
	}
}

export const JunoContext = React.createContext<JunoContextType>({})
export const useJuno = (): JunoContextType => useContext(JunoContext)

export const JunoProvider: FunctionComponent = ({ children }) => {
	const { juno, connectionError } = useConnectToJuno()

	return (
		<JunoContext.Provider value={{ juno, connectionError }}>
			{children}
		</JunoContext.Provider>
	)
}
