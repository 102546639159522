import { FC } from 'react'
import { Group, UserId } from 'common/types'
import { RowPosition } from 'room/training-room/set-groups/types'
import { colorToHex } from 'room/common/presentation/colorsInHex'
import {
	GroupBucketBackground,
	GroupBucketBlocker,
	GroupBucketForeground,
} from 'room/training-room/set-groups/presentation/group-bucket-background'
import styled from '@emotion/styled'
import { styles } from '@beelday/common'
import hand from './images/hand.svg'
import enterGroup from './images/enter-group.svg'
import enterGroupFilled from './images/enter-group-filled.svg'
import { usePreloaded } from 'connectivity/preload'
import { isHandRaised } from '../model/attention'
import Eavsdrop from './eavsdrop'
import { useSelector } from 'react-redux'
import { RootState } from 'common/redux'
import {
	GroupEngagementGauge,
	VideoUserStreamWithEngagement,
} from 'features/user-engagement'
import CalculatedRatioGrid from 'video-conference-media/presentation/calculated-ratio-grid'
import { SceneVideoUser } from 'video-conference-media'

type GroupBucketProps = {
	width: number
}

const GroupBucketLayout = styled.div`
	margin-right: 10px;
	margin-left: 10px;
	position: relative;
	${(props: GroupBucketProps) => `
        width: ${props.width}%;
    `};
`

const NameContainer = styled.div`
	align-items: center;
	display: flex;
	margin: 0 auto;
	padding-top: 20px;
	padding-right: 15px;
	padding-left: 15px;
`

const Name = styled.div<{ color: string }>`
	color: ${props => props.color};
	font-family: Ubuntu, serif;
	font-size: 22px;
	margin-right: 10px;
	z-index: 3;
	${styles.ellipsis};
`

const GroupBucketFlex = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
`

const GroupVideos = styled.div`
	position: relative;
	margin: 10px 20px 70px 20px;
	flex-grow: 1;
	display: flex;
`

type Props = {
	group: Group
	index: number
	rowPosition?: RowPosition
	streams: Record<UserId, SceneVideoUser>
	width: number
	onEnter: () => void
	listening: boolean
	onMute: () => void
	onUnmute: () => void
}

type EnterGroupIconProps = {
	backgroundImage: string
}

const GroupSpecificMenu = styled.div`
	position: absolute;
	right: 4%;
	bottom: 4%;

	& > div {
		display: inline-block;
	}
`

const EnterGroupIcon = styled.div`
	width: 50px;
	height: 50px;
	background-image: url(${(props: EnterGroupIconProps) =>
		props.backgroundImage});
	background-size: 100%;
	background-position: center;
	background-repeat: no-repeat;
	cursor: pointer;
`

const HandIcon = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	background-image: url(${hand});
	background-size: auto 75%;
	background-repeat: no-repeat;
	background-position: center bottom;
	cursor: pointer;

	:hover {
		filter: blur(5px);
	}
`

const GroupBucket: FC<Props> = ({
	group,
	index,
	rowPosition = RowPosition.TOP,
	streams,
	width,
	onEnter,
	listening,
	onMute,
	onUnmute,
}) => {
	const colorHex = colorToHex[group.groupDescription.color]
	const engagementResults = useSelector(
		(state: RootState) => state.groupEngagement
	)

	const engagement = engagementResults?.[group.groupDescription.id]

	const preloaded = usePreloaded([hand, enterGroup, enterGroupFilled])
	if (!preloaded) {
		return null
	}
	return (
		<GroupBucketLayout width={width}>
			<GroupBucketBackground
				row={rowPosition}
				index={index}
				colorHex={colorHex}
			/>
			<GroupBucketForeground
				row={rowPosition}
				index={index}
				colorHex={colorHex}
				onEnter={onEnter}
			/>
			<GroupBucketFlex>
				<NameContainer>
					<Name color={colorHex}>
						{group.groupDescription.name.toUpperCase()}
					</Name>
					<Eavsdrop listening={listening} onMute={onMute} onUnmute={onUnmute} />
				</NameContainer>
				<GroupVideos>
					<CalculatedRatioGrid widthRatio={16} heightRatio={9} spacing={6}>
						{group.users
							.filter(groupUser => streams[groupUser.id])
							.map(groupUser => (
								<VideoUserStreamWithEngagement
									key={groupUser.id}
									sceneVideoUser={streams[groupUser.id]}
								/>
							))}
					</CalculatedRatioGrid>
				</GroupVideos>
			</GroupBucketFlex>
			{isHandRaised(group) ? (
				<GroupBucketBlocker index={index} row={rowPosition} colorHex={colorHex}>
					<HandIcon />
					<GroupSpecificMenu>
						<GroupEngagementGauge
							score={engagement?.group_score}
							handRaised={true}
						/>
						<EnterGroupIcon backgroundImage={enterGroup} />
					</GroupSpecificMenu>
				</GroupBucketBlocker>
			) : (
				<GroupSpecificMenu>
					<GroupEngagementGauge score={engagement?.group_score} />
					<EnterGroupIcon backgroundImage={enterGroupFilled} />
				</GroupSpecificMenu>
			)}
		</GroupBucketLayout>
	)
}

export default GroupBucket
