import { isFunction } from 'lodash'

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const isPromise = (something: any): something is Promise<any> => {
	return (
		something instanceof Promise ||
		(something?.then && isFunction(something.then))
	)
}
/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-explicit-any */
