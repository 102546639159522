import { Children, FC, useLayoutEffect, useState } from 'react'
import styled from '@emotion/styled'
import useResize from 'utils/hooks/use-resize'

export type LayoutItem = {
	top: number
	left: number
	child: React.ReactChild | React.ReactFragment | React.ReactPortal
}

export type ContainerLayout = {
	width: number
	height: number
	layoutItems: LayoutItem[]
}

const Container = styled.div`
	position: relative;
	display: flex;
	width: 100%;
	height: auto;
`

function calculateSize(
	n: number,
	containerWidth: number,
	containerHeight: number,
	itemWidth: number,
	itemHeight: number
): {
	nRows: number
	nCols: number
	itemWidth: number
	itemHeight: number
} {
	containerWidth = (containerWidth * itemHeight) / itemWidth

	const ratio = containerWidth / containerHeight
	const nColsFloat = Math.sqrt(n * ratio)
	const nRowsFloat = n / nColsFloat

	let nRows1 = Math.ceil(nRowsFloat)
	let nCols1 = Math.ceil(n / nRows1)
	while (nRows1 * ratio < nCols1) {
		nRows1++
		nCols1 = Math.ceil(n / nRows1)
	}
	const cellSize1 = containerHeight / nRows1

	let nCols2 = Math.ceil(nColsFloat)
	let nRows2 = Math.ceil(n / nCols2)
	while (nCols2 < nRows2 * ratio) {
		nCols2++
		nRows2 = Math.ceil(n / nCols2)
	}
	const cellSize2 = containerWidth / nCols2

	let nRows, nCols, cellSize
	if (cellSize1 < cellSize2) {
		nRows = nRows2
		nCols = nCols2
		cellSize = cellSize2
	} else {
		nRows = nRows1
		nCols = nCols1
		cellSize = cellSize1
	}

	itemWidth = (cellSize * itemWidth) / itemHeight
	itemHeight = cellSize
	return {
		nRows: nRows,
		nCols: nCols,
		itemWidth: itemWidth,
		itemHeight: itemHeight,
	}
}

function calculateContainerLayout(
	containerHeight: number,
	containerWidth: number,
	count: number,
	nRow: number,
	nCol: number,
	width: number,
	height: number,
	items: (React.ReactChild | React.ReactFragment | React.ReactPortal)[]
): ContainerLayout {
	const baseLeftOffset = (containerWidth - nCol * width) / 2
	const baseTopOffset = (containerHeight - nRow * height) / 2
	let colIndex = 0
	let currentRow = 1

	const layout: ContainerLayout = {
		width,
		height,
		layoutItems: [],
	}
	const maxContainerSize = nRow * nCol
	const emptySpace = ((maxContainerSize - count) * width) / 2

	items.forEach((child, i) => {
		let left = baseLeftOffset + colIndex * width
		if (i > maxContainerSize - nCol - 1 && count < maxContainerSize) {
			left = emptySpace + baseLeftOffset + colIndex * width
		}
		if (i === currentRow * nCol && i > 0) {
			currentRow++
		}

		layout.layoutItems.push({
			top:
				currentRow > 1
					? baseTopOffset + height * (currentRow - 1)
					: baseTopOffset,
			left,
			child,
		})

		if (colIndex >= nCol - 1) {
			colIndex = 0
		} else {
			colIndex++
		}
	})

	return layout
}

type Props = {
	widthRatio: number
	heightRatio: number
	spacing: number
}

const CalculatedRatioGrid: FC<Props> = ({
	children,
	widthRatio,
	heightRatio,
	spacing,
}) => {
	const [layoutItems, setLayoutItems] = useState<ContainerLayout>()

	const {
		ref: containerRef,
		width: containerWidth,
		height: containerHeight,
	} = useResize()

	useLayoutEffect(() => {
		const countElements = Children.count(children)
		if (containerWidth > 0 && containerHeight > 0) {
			const { nRows, nCols, itemWidth, itemHeight } = calculateSize(
				countElements,
				containerWidth,
				containerHeight,
				widthRatio,
				heightRatio
			)

			const calculateLayoutItems = calculateContainerLayout(
				containerHeight,
				containerWidth,
				countElements,
				nRows,
				nCols,
				itemWidth,
				itemHeight,
				Children.toArray(children)
			)

			setLayoutItems(calculateLayoutItems)
		}
	}, [children, containerWidth, containerHeight])

	return (
		<Container ref={containerRef}>
			{layoutItems?.layoutItems.map((item, index) => {
				return (
					<div
						key={index}
						style={{
							padding: `${spacing / 2}px`,
							position: 'absolute',
							height: `${layoutItems.height}px`,
							width: `${layoutItems.width}px`,
							top: `${item.top}px`,
							left: `${item.left}px`,
						}}
					>
						{item.child}
					</div>
				)
			})}
		</Container>
	)
}

export default CalculatedRatioGrid
