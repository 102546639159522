import {
	getDisplayName,
	UserId,
	UserProfile,
	PollingWordCloudAnswer,
} from '../../user-api'
import { throttle, uniqBy } from 'lodash'
import ReactDOM from 'react-dom'
import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { colors } from '../index'
import ReactWordcloud, { OptionsProp } from '@pragmatic-brains/word-cloud'
import './tooltip.css'

const wordCloudColors = [
	'#fdb92e',
	'#fdb92e',
	'#7bdcb5',
	'#22d084',
	'#8ed1fc',
	'#1b93e3',
	'#abb8c3',
	'#eb154c',
	'#f78ca7',
	'#9913ef',
]

const WordCloudWrapper = styled.div`
	width: 100%;
	height: 100%;
	position: relative;

	svg {
		max-width: 100%;
		max-height: 100%;
		width: 100%;
		height: 100%;
	}
`

const WordCloudHelperDiv = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 90%;
	height: 90%;
	font-weight: 500;
	opacity: 0;
	pointer-events: none;
`

const UserTooltipDiv = styled.div`
	pointer-events: none;
	position: fixed;
	max-width: 300px;
	border-radius: 50px;
	background-color: ${colors.indigoBlue};
	font-size: 14px;
	color: ${colors.white};
	padding: 5px 10px;
	transition: top 0.4s, left 0.4s;
	z-index: 999999;
`

const calculateMaxFontSize = (container: HTMLDivElement): number => {
	container.style.overflow = 'auto'
	let fontSize = 14
	container.style.fontSize = fontSize + 'px'
	let changes = 0
	let blnSuccess = true

	while (container.scrollWidth <= container.clientWidth) {
		container.style.fontSize = fontSize + 'px'
		fontSize++
		changes++
		if (changes > 500) {
			blnSuccess = false
			break
		}
	}
	if (changes > 0) {
		if (blnSuccess) fontSize -= 2
		else fontSize -= changes
		container.style.fontSize = fontSize + 'px'
	}

	return fontSize
}

export const WordCloud: React.FC<{
	answers: PollingWordCloudAnswer[]
	onWordMouseOver: (userIds: UserId[]) => unknown
	onWordMouseOut: () => unknown
}> = ({ answers, onWordMouseOver, onWordMouseOut }) => {
	const [fontSize, setFontSize] = useState<[number, number] | undefined>()
	const wordCloudWrapperRef = useRef<HTMLDivElement>(null)
	const wordCloudHelperRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		let maxFontSize = 80

		const onResize = throttle(() => {
			if (wordCloudHelperRef.current) {
				maxFontSize = calculateMaxFontSize(wordCloudHelperRef.current)
			}
			setFontSize([maxFontSize * 0.45, maxFontSize])
		}, 500)
		window.addEventListener('resize', onResize)
		setTimeout(() => onResize(), 1)

		return () => window.removeEventListener('resize', onResize)
	}, [])

	return (
		<>
			<WordCloudWrapper
				ref={wordCloudWrapperRef}
				style={{ width: '100%', height: '100%' }}
			>
				<WordCloudHelperDiv ref={wordCloudHelperRef}>
					XXXXXXXXXXXXXXXXXXXXXXXXX
				</WordCloudHelperDiv>
				{wordCloudWrapperRef.current && fontSize ? (
					<WordCloudComponent
						width={wordCloudWrapperRef.current.clientWidth + Math.random()}
						height={wordCloudWrapperRef.current.clientHeight + Math.random()}
						answers={answers}
						fontSize={fontSize}
						onWordMouseOver={onWordMouseOver}
						onWordMouseOut={onWordMouseOut}
					/>
				) : null}
			</WordCloudWrapper>
		</>
	)
}

interface WordCloudProps {
	width: number
	height: number
	answers: PollingWordCloudAnswer[]
	fontSize: [number, number]
	onWordMouseOver: (userIds: UserId[]) => unknown
	onWordMouseOut: () => unknown
}

const WordCloudComponent: React.FC<WordCloudProps> = ({
	answers,
	fontSize,
	width,
	height,
	onWordMouseOver,
	onWordMouseOut,
}) => {
	const counts = count(answers)

	const options: OptionsProp = {
		containerWidth: width,
		containerHeight: height,
		colors: wordCloudColors,
		enableTooltip: true,
		deterministic: false,
		fontFamily: 'Ubuntu',
		fontSizes: fontSize,
		fontStyle: 'normal',
		fontWeight: '500',
		padding: 2,
		rotations: 2,
		rotationAngles: [0, 90],
		scale: 'linear',
		spiral: 'rectangular',
		transitionDuration: 1000,
		enableOptimizations: true,
		tooltipOptions: {
			allowHTML: true,
			arrow: true,
			placement: 'top',
		},
	}

	return (
		<ReactWordcloud
			words={counts}
			options={options}
			callbacks={{
				getWordTooltip: ({ value }) => {
					return `Added ${value} time`
				},
				onWordMouseOver: word => {
					const userIds = getUsersByAnswer(word.text, answers)
					onWordMouseOver(userIds)
				},
				onWordMouseOut: () => onWordMouseOut(),
			}}
		/>
	)
}

function count(answers: PollingWordCloudAnswer[]) {
	const uniqAnswers = uniqBy(answers, 'text')
	const counts: { text: string; value: number }[] = []
	for (const w in uniqAnswers) {
		const countInstances = answers.filter(
			answer => answer.text === uniqAnswers[w].text
		)
		counts.push({
			text: uniqAnswers[w].text,
			value: countInstances ? countInstances.length : 0,
		})
	}

	return counts
}

function getUsersByAnswer(text: string, answers: PollingWordCloudAnswer[]) {
	const filteredAnswers = answers.filter(answer => answer.text === text)
	return uniqBy(filteredAnswers, 'userId').map(answer => answer.userId)
}

export const UsersTooltip: React.FC<{
	profiles: (UserProfile | null)[]
}> = ({ profiles }) => {
	const container = useRef(document.createElement('div'))
	const [cursorPosition, setCursorPosition] = useState<
		| {
				left: number
				top: number
		  }
		| undefined
	>(undefined)
	useEffect(() => {
		const onMove = throttle(e => {
			setCursorPosition({ top: e.clientY, left: e.clientX })
		}, 100)
		window.addEventListener('mousemove', onMove)

		return () => window.removeEventListener('resize', onMove)
	}, [])

	useEffect(() => {
		const div = container.current

		const { body } = window.document
		body.appendChild(div)
		return () => {
			body.removeChild(div)
		}
	}, [])
	return cursorPosition
		? ReactDOM.createPortal(
				<UserTooltipDiv
					style={{
						top: cursorPosition.top + 15,
						left: cursorPosition.left + 15,
					}}
				>
					{profiles.map((profile, index) => (
						<span key={index}>
							{(index ? ', ' : '') + getDisplayName(profile)}
						</span>
					))}
				</UserTooltipDiv>,
				container.current
		  )
		: null
}
