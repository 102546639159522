import styled from '@emotion/styled'
import colors from '../colors'

export const Table = styled.div`
	display: flex;
	flex-direction: column;
`

export const TableHeader = styled.div`
	align-items: center;
	border-radius: 4px;
	background-color: ${colors.heather10};
	color: ${colors.heather};
	display: grid;
	grid-template-columns: 1fr auto auto;
	height: 36px;
	width: 100%;
	padding: 0 10px;
	font-size: 12px;
	line-height: 17px;
	font-weight: 500;
	text-transform: uppercase;
	text-align: center;

	span {
		padding: 0 10px;

		&:first-child {
			text-align: left;
		}
	}
`

export const TableRowButton = styled.button`
	background: transparent;
	cursor: pointer;
	color: ${colors.indigoBlue};
	padding: 10px;
	border: none;
	box-shadow: none;
`

export const TableRow = styled.div`
	border-bottom: 1px solid ${colors.borderLightGray};
	display: grid;
	cursor: pointer;
	grid-template-columns: 1fr auto auto;
	align-items: center;
	justify-content: center;
	padding: 10px;
	text-align: center;

	span {
		font-size: 14px;
		line-height: 17px;
		padding: 0 10px;

		&:first-child {
			text-align: left;
			font-weight: 500;
		}
	}

	transition: color 0.25s;

	&:hover {
		color: ${colors.indigoBlue};
	}
`
