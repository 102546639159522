import { ui } from '@beelday/common'
import { PublishBitrates } from 'common/publish-bitrates'
import { VotingResultsModal, useQuestions } from 'features/questions'
import { ScriptNavigation } from 'features/script'
import { FC, useEffect } from 'react'
import { use100vh } from 'react-div-100vh'
import { useDispatch, useSelector } from 'react-redux'
import { RoomContainer } from 'room/common/presentation/room-container'
import RoomHeader from 'room/common/presentation/room-header'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { MiscPanel } from 'room/misc'
import TrainerMenu from 'room/training-room/trainer-menu'
import { EqualVideoGrid } from 'video-conference-media/presentation/video-grid'
import {
	allStreams,
	selectLocalScreenShareStream,
	selectPublishBitRate,
	setPublishBitrate,
} from 'video-conference-media/redux'
import { selectFocusedFeature } from './redux'
import { TrainerFocusedFeature } from './trainer-focused-feature'

export const RoomTrainerWebinar: FC = () => {
	const containerHeight = use100vh()

	return (
		<RoomContainer
			style={{
				height: `${containerHeight}px`,
			}}
		>
			<RoomHeader>
				<ScriptNavigation />
			</RoomHeader>
			<MainContents />

			<MiscPanel />
			<ui.BeeldayLogoAbsoluteBottom />
		</RoomContainer>
	)
}

const MainContents: FC = () => {
	const questions = useQuestions()
	const roomAddress = useAssertedJoinedRoomAddress()
	const dispatch = useDispatch()
	const focusedFeature = useSelector(selectFocusedFeature)
	const sceneVideoUsers = useSelector(allStreams)
	const userCount = sceneVideoUsers.length
	const currentBitrate = useSelector(selectPublishBitRate)
	const isLocalScreenShare = !!useSelector(selectLocalScreenShareStream)
	const showingFocusedFeature = !!focusedFeature

	useEffect(() => {
		let bitrate = PublishBitrates.low
		if (isLocalScreenShare) {
			bitrate = PublishBitrates.screenShareUltra
		} else if (!showingFocusedFeature) {
			bitrate = PublishBitrates.ultra
		}

		if (bitrate !== currentBitrate) {
			dispatch(setPublishBitrate(bitrate))
		}
	}, [
		dispatch,
		currentBitrate,
		isLocalScreenShare,
		userCount,
		showingFocusedFeature,
	])

	return (
		<>
			{focusedFeature ? (
				<TrainerFocusedFeature streams={sceneVideoUsers} />
			) : (
				<>
					{questions.active ? (
						<VotingResultsModal
							roomAddress={roomAddress}
							totalNumberOfUsers={sceneVideoUsers.length}
						/>
					) : null}
					<EqualVideoGrid sceneVideoUsers={sceneVideoUsers} />
				</>
			)}
			<TrainerMenu />
		</>
	)
}
