export const TrainingTools = (): JSX.Element => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="14"
		height="16"
		viewBox="0 0 14 16"
	>
		<g transform="translate(-28 -686)">
			<rect
				width="6"
				height="8"
				rx="1"
				transform="translate(28 686)"
				fill="currentColor"
			/>
			<rect
				width="6"
				height="8"
				rx="1"
				transform="translate(36 694)"
				fill="currentColor"
			/>
			<rect
				width="6"
				height="6"
				rx="1"
				transform="translate(36 686)"
				fill="currentColor"
			/>
			<rect
				width="6"
				height="6"
				rx="1"
				transform="translate(28 696)"
				fill="currentColor"
			/>
		</g>
	</svg>
)
