export const AccountModeIcon = (): JSX.Element => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="20"
		height="20"
		viewBox="0 0 20 20"
	>
		<path
			id="Path_12415"
			data-name="Path 12415"
			d="M16.55,0H3.46A3.459,3.459,0,0,0,0,3.45V16.54A3.474,3.474,0,0,0,3.46,20H16.55A3.459,3.459,0,0,0,20,16.54V3.45A3.457,3.457,0,0,0,16.55,0M4,10a6,6,0,0,1,6-6,5.923,5.923,0,0,1,3.21.93V4.88a.885.885,0,0,1,1.77,0V6.93a.881.881,0,0,1-.88.88H12.05a.78.78,0,0,1-.22-.03.879.879,0,0,1-.67-.85.893.893,0,0,1,.53-.81A4.224,4.224,0,0,0,5.76,10,.88.88,0,0,1,4,10m6,6a5.923,5.923,0,0,1-3.21-.93v.05a.885.885,0,0,1-1.77,0V13.07a.881.881,0,0,1,.88-.88H7.95a.985.985,0,0,1,.17.02.871.871,0,0,1,.72.86.893.893,0,0,1-.53.81A4.224,4.224,0,0,0,14.24,10,.88.88,0,1,1,16,10a6,6,0,0,1-6,6"
			fill="currentColor"
		/>
	</svg>
)
