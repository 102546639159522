import { UserInterest } from './user-models'

import animals from './images/common-interest-animals.svg'
import books from './images/common-interest-books.svg'
import cars from './images/common-interest-cars.svg'
import cooking from './images/common-interest-cooking.svg'
import health from './images/common-interest-health.svg'
import knowledge from './images/common-interest-knowledge.svg'
import meetings from './images/common-interest-meetings.svg'
import movies from './images/common-interest-movies.svg'
import music from './images/common-interest-music.svg'
import paint from './images/common-interest-paint.svg'
import sport from './images/common-interest-sport.svg'
import theater from './images/common-interest-theater.svg'
import travel from './images/common-interest-travel.svg'
import yoga from './images/common-interest-yoga.svg'

export const INTEREST_ICON: Record<UserInterest, string> = {
	ANIMALS: animals,
	BOOKS: books,
	CARS: cars,
	COOKING: cooking,
	HEALTH: health,
	KNOWLEDGE: knowledge,
	MEETINGS: meetings,
	MOVIES: movies,
	MUSIC: music,
	PAINT: paint,
	SPORT: sport,
	THEATER: theater,
	TRAVEL: travel,
	YOGA: yoga,
}

export const INTEREST_COLORS: Record<UserInterest, string> = {
	ANIMALS: '#a27d68',
	BOOKS: '#4ea2d3',
	CARS: '#d2acd1',
	COOKING: '#878b76',
	HEALTH: '#78d574',
	KNOWLEDGE: '#e5a070',
	MEETINGS: '#d34e96',
	MOVIES: '#626f6d',
	MUSIC: '#f1315e',
	PAINT: '#eb70ca',
	SPORT: '#37c4a8',
	THEATER: '#3778c4',
	TRAVEL: '#c0cb45',
	YOGA: '#3ea1f7',
}
