import { Config } from 'common/config'
import { useMemo } from 'react'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { getWordCloudApi } from './word-cloud-api'
import {
	NewPollingWordCloud,
	PollingWordCloudDefinition,
	PollingWordCloudId,
} from './world-cloud-models'

type WordCloudHook = {
	getPollingDefinitions: () => Promise<PollingWordCloudDefinition[]>
	createPolling: (
		polling: NewPollingWordCloud
	) => Promise<PollingWordCloudDefinition>
	startPolling: (pollingId: PollingWordCloudId) => Promise<void>
	stopPolling: (pollingId: PollingWordCloudId) => Promise<void>
}
export const useWordCloud = (): WordCloudHook => {
	const wordCloudAPi = getWordCloudApi(Config.beeldayBackendUrl)
	const roomAddress = useAssertedJoinedRoomAddress()

	return useMemo(
		() => ({
			getPollingDefinitions: (): Promise<PollingWordCloudDefinition[]> => {
				return wordCloudAPi.getPollingDefinitions(roomAddress)
			},
			createPolling: (polling): Promise<PollingWordCloudDefinition> => {
				return wordCloudAPi.createOpenEndedWordCloud(roomAddress, polling)
			},
			startPolling: (pollingId): Promise<void> => {
				return wordCloudAPi.startPolling(roomAddress, pollingId)
			},
			stopPolling: (pollingId): Promise<void> => {
				return wordCloudAPi.stopPolling(roomAddress, pollingId)
			},
		}),
		[roomAddress, wordCloudAPi]
	)
}
