export const Document: React.FC = () => (
	<svg
		data-name="Group 25592"
		xmlns="http://www.w3.org/2000/svg"
		width="18.6"
		height="24"
		viewBox="0 0 18.6 24"
	>
		<defs>
			<clipPath id="qf04nkgt2a">
				<path data-name="Rectangle 3994" d="M0 0h18.6v24H0z" />
			</clipPath>
		</defs>
		<g data-name="Group 25592" style={{ clipPath: 'url(#qf04nkgt2a)' }}>
			<path
				data-name="Path 11823"
				d="M2.2 0h10.876L18.6 5.524V21.8a2.205 2.205 0 0 1-2.2 2.2H2.2A2.205 2.205 0 0 1 0 21.8V2.2A2.2 2.2 0 0 1 2.2 0m.546 18.753h13.107a.674.674 0 0 1 0 1.348H2.745a.674.674 0 0 1 0-1.348m0-4.282h13.108a.674.674 0 0 1 0 1.348H2.745a.674.674 0 0 1 0-1.348m0-4.282h13.108a.674.674 0 0 1 0 1.348H2.745a.674.674 0 0 1 0-1.348M17.2 6.03a1.017 1.017 0 0 1-1.01 1.019l-3.574.016A1.019 1.019 0 0 1 11.6 6.054l-.02-3.811a1.018 1.018 0 0 1 1.011-1.019z"
				style={{ fillRule: 'evenodd' }}
			/>
		</g>
	</svg>
)
