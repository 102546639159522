import { Group } from 'common/types'
import { ui } from '@beelday/common'
import { FunctionComponent, ReactNode } from 'react'
import { RoomContainer } from 'room/common/presentation/room-container'
import styled from '@emotion/styled'
import { SelfStreamView } from 'video-conference-media/presentation/top-right-stream-view'
import { translate } from 'intl/translate'
import { GroupsList } from 'room/group-room/presentation/groups-list'
import { Colors } from 'common/presentation/colors'
import RoomHeader from 'room/common/presentation/room-header'
import { MiscPanel } from 'room/misc'
import { TrainerMenuInGroupView } from './trainer-menu-in-group-view'
import type { Media } from 'common/types'
import { use100vh } from 'react-div-100vh'
import { SceneVideoUser } from 'video-conference-media'

const GrowthAllowingContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	min-height: 0px;
`

const GroupsListTittle = styled.div`
	font-family: Ubuntu;
	font-size: 14px;
	color: ${Colors.indigoBlue};
	margin-bottom: 10px;
	margin-top: 20px;
	margin-left: 10px;
	min-height: 0px;
`

const MenuContainer = styled.div`
	width: 100%;
`

const GroupsListSection = styled.div`
	width: 171px;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-end;
	min-height: 0px;
`

const GroupsLists = styled.div`
	width: 100%;
	padding: 0 10px;
`

const GroupsListsAndGroup = styled.div`
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	width: 100%;
	min-height: 0px;
`

const SideStreamsContainer = styled.div`
	width: 100%;
	flex-grow: 1;
	display: flex;
`

type TrainingRoomTrainerInGroupProps = {
	currentGroup: Group
	sceneVideoUsers: SceneVideoUser[]
	onEnter: (groupId: string) => void
	groups: Group[]
	mainChildren: ReactNode
	sideChildren: ReactNode
	onStartBreak: () => void
	openMedia?: Media | undefined
}

export const RoomTrainerInGroup: FunctionComponent<TrainingRoomTrainerInGroupProps> =
	props => {
		const containerHeight = use100vh()
		const currentGroupId = props.currentGroup.groupDescription.id
		const otherGroupsThatRequestAttention = props.groups.filter(
			group =>
				group.groupDescription.id !== currentGroupId &&
				group.attention.type !== 'NONE'
		)
		const otherGroupsThatNotRequestAttention = props.groups.filter(
			group =>
				group.groupDescription.id !== currentGroupId &&
				group.attention.type === 'NONE'
		)

		return (
			<RoomContainer
				style={{
					height: `${containerHeight}px`,
				}}
			>
				<RoomHeader streamView={<SelfStreamView />} />
				<GrowthAllowingContainer>
					<GroupsListsAndGroup>
						{props.mainChildren}
						<GroupsListSection>
							<SideStreamsContainer>{props.sideChildren}</SideStreamsContainer>
							<GroupsLists>
								{otherGroupsThatRequestAttention.length > 0 && (
									<>
										<GroupsListTittle>
											{translate('raising-hand')}
										</GroupsListTittle>
										<GroupsList
											onEnter={props.onEnter}
											groups={otherGroupsThatRequestAttention}
										/>
									</>
								)}
								{otherGroupsThatNotRequestAttention.length > 0 && (
									<>
										<GroupsListTittle>
											{translate('other-groups')}
										</GroupsListTittle>
										<GroupsList
											onEnter={props.onEnter}
											groups={otherGroupsThatNotRequestAttention}
										/>
									</>
								)}
							</GroupsLists>
						</GroupsListSection>
					</GroupsListsAndGroup>
					<MenuContainer>
						<TrainerMenuInGroupView
							currentGroupId={currentGroupId}
							openMedia={props.openMedia}
							onStartBreak={props.onStartBreak}
						/>
					</MenuContainer>
				</GrowthAllowingContainer>
				<MiscPanel />
				<ui.BeeldayLogoAbsoluteBottom />
			</RoomContainer>
		)
	}
