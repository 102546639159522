import { useEffect } from 'react'
import { Config } from 'common/config'
import { security } from '@beelday/common'

export const exitToSessionPlanning = ({
	isTrainer,
}: {
	isTrainer: boolean | undefined
}): void => {
	window.location.assign(
		`${Config.sessionPlanningHome}${isTrainer ? '/trainer' : ''}`
	)
}

export const ExitToSessionPlanning = (): null => {
	const user = security.useAuthenticatedUser()
	useEffect(() => {
		exitToSessionPlanning({
			isTrainer: user.roles.includes(security.UserRoles.UPPER_ECHELON),
		})
	}, [user.roles])

	return null
}

export default ExitToSessionPlanning
