import { RoomAddress } from 'common/types'
import { useSelector } from 'react-redux'
import { selectJoinedRoomAddress } from './redux'

export const useJoinedRoomAddress = (): RoomAddress | undefined => {
	return useSelector(selectJoinedRoomAddress)
}

export const useAssertedJoinedRoomAddress = () => {
	const joinedRoomAddress = useJoinedRoomAddress()

	if (!joinedRoomAddress) {
		throw Error('There is no joined room available')
	}

	return joinedRoomAddress
}
