import { useCallback, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import show from './show.svg'
import hide from './hide.svg'
import { Colors } from 'common/presentation/colors'
import { RoomId, User, UserProfile } from 'common/types'
import { PublicChatRoom } from 'room/lobby/redux'
import roomsPresentations from 'room/lobby/rooms-presentation'
import Translate from 'intl/translate'
import { drop, take } from 'lodash'
import ProfileAvatar from 'users/profile-avatar'

const Icon = styled.img`
	cursor: pointer;
	height: 75px;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-40px);
	width: 75px;
`

const Container = styled.div`
	background-color: ${Colors.white};
	border-radius: 20px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
	height: 222px;
	left: 100px;
	top: 50%;
	transform: translateY(-50%);
	position: absolute;
	width: 250px;
`

const Top = styled.div`
	cursor: pointer;
	height: 50%;
	padding: 20px 5px;
	width: 100%;
`

const Bottom = styled.div`
	border-radius: 20px;
	box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.12);
	cursor: pointer;
	height: 50%;
	padding: 20px 5px;
	width: 100%;
`

const RoomName = styled.div`
	font-family: Ubuntu;
	font-weight: 600;
	font-size: 13px;
	text-align: center;
`

const EmptyState = styled.div`
	margin-top: 10px;

	img {
		height: 100%;
		max-height: 40px;
		width: 100%;
	}
`

const UsersContainer = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	margin-top: 10px;
`

const Over = styled.div`
	border: 2px solid ${Colors.white};
	border-radius: 50%;
	margin-left: -5px;
	position: relative;
	height: 35px;
	width: 35px;
`

const Avatar = styled(ProfileAvatar)`
	height: 100%;
	width: 100%;
`

const Rest = styled.div`
	background-color: ${Colors.azure};
	border-radius: 50%;
	color: ${Colors.white};
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: Ubuntu;
	font-size: 14px;
	font-weight: 500;
	height: 100%;
	width: 100%;
`

const Users = ({
	users,
}: {
	users: (User & Partial<UserProfile>)[]
}): JSX.Element => {
	const toDisplay = take(users, 3)
	const remaining = users.length - toDisplay.length

	return (
		<UsersContainer>
			{toDisplay.map(u => (
				<Over key={u.id}>
					<Avatar profile={u} />
				</Over>
			))}
			{remaining > 0 ? (
				<Over>
					<Rest>+{remaining}</Rest>
				</Over>
			) : null}
		</UsersContainer>
	)
}

type BoxProps = Props & {
	onClose: () => void
}

const Box = ({
	rooms,
	onPublicRoomChange,
	onClose,
}: BoxProps): JSX.Element | null => {
	const containerRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		const handler = (event: MouseEvent) => {
			if (
				event.target instanceof Node &&
				containerRef.current?.contains(event.target)
			) {
				event.stopPropagation()
			} else {
				onClose()
			}
		}

		document.addEventListener('click', handler)

		return () => document.removeEventListener('click', handler)
	}, [onClose])

	if (rooms.length === 2) {
		const first = rooms[0]
		const second = rooms[1]

		return (
			<Container ref={containerRef}>
				<Top onClick={() => onPublicRoomChange(first.id)}>
					<RoomName>
						{first.type && (
							<Translate>{roomsPresentations[first.type].name}</Translate>
						)}
					</RoomName>
					{!first.users || first.users.length === 0 ? (
						<EmptyState>
							<img
								src={first.type && roomsPresentations[first.type].emptyImg}
								alt="Empty State"
							/>
						</EmptyState>
					) : (
						<Users users={first.users} />
					)}
				</Top>
				<Bottom onClick={() => onPublicRoomChange(second.id)}>
					<RoomName>
						{second.type && (
							<Translate>{roomsPresentations[second.type].name}</Translate>
						)}
					</RoomName>
					{!second.users || second.users.length === 0 ? (
						<EmptyState>
							<img
								src={second.type && roomsPresentations[second.type].emptyImg}
								alt="Empty State"
							/>
						</EmptyState>
					) : (
						<Users users={second.users} />
					)}
				</Bottom>
			</Container>
		)
	} else {
		return null
	}
}

type Props = {
	rooms: PublicChatRoom[]
	onPublicRoomChange: (id: RoomId) => void
}

const QuickChange = ({ rooms, onPublicRoomChange }: Props): JSX.Element => {
	const [quickChangeVisible, setQuickChangeVisible] = useState(false)

	const changeRoom = useCallback(
		(roomId: RoomId) => {
			setQuickChangeVisible(false)
			onPublicRoomChange(roomId)
		},
		[onPublicRoomChange]
	)

	const close = useCallback(() => setQuickChangeVisible(false), [])

	return quickChangeVisible ? (
		<>
			<Icon src={hide} onClick={() => setQuickChangeVisible(false)} />
			<Box rooms={rooms} onPublicRoomChange={changeRoom} onClose={close} />
		</>
	) : (
		<Icon src={show} onClick={() => setQuickChangeVisible(true)} />
	)
}

export default QuickChange
