import { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { SceneVideoUser } from 'video-conference-media'
import {
	selectLocalScreenShareStream,
	selectRemoteScreenShare as selectRemoteScreenShareStream,
} from 'video-conference-media/redux'
import ScreenShare from './screen-share'

const ConfiguredScreenShareTrainee: FC<{ streams: SceneVideoUser[] }> = ({
	streams,
}): JSX.Element => {
	const localScreenShare = useSelector(selectLocalScreenShareStream)

	if (localScreenShare) {
		return <MeScreenSharing streams={streams} />
	} else {
		return <OtherScreenShare streams={streams} />
	}
}

const MeScreenSharing: FC<{ streams: SceneVideoUser[] }> = ({
	streams,
}): JSX.Element => {
	const localScreenShare = useSelector(selectLocalScreenShareStream)

	return (
		<ScreenShare streams={streams} screen={localScreenShare} local={true} />
	)
}

const OtherScreenShare: FC<{ streams: SceneVideoUser[] }> = ({
	streams,
}): JSX.Element => {
	const stream = useSelector(selectRemoteScreenShareStream)

	const screen = useMemo(() => {
		if (!stream) return
		return new MediaStream(
			stream.getVideoTracks().filter(t => t.id === stream.screenShareTrackId)
		)
	}, [stream])

	if (screen) {
		return <ScreenShare streams={streams} screen={screen} local={false} />
	} else {
		return <ScreenShare streams={streams} />
	}
}

export default ConfiguredScreenShareTrainee
