import { hooks, icons, ui } from '@beelday/common'
import React from 'react'

export const HeaderSmall: React.FC = () => {
	const {
		value: logoutVisible,
		setOn: showLogout,
		setOff: hideLogout,
	} = hooks.useToggle(false)
	return (
		<ui.HeaderSmall>
			<ui.HeaderItem>
				<icons.NotificationIcon />
			</ui.HeaderItem>
			<ui.HeaderItem style={{ paddingLeft: '2px' }} onClick={showLogout}>
				<icons.ExitIcon />
			</ui.HeaderItem>
			{logoutVisible ? <ui.LogoutSplashScreen onDismiss={hideLogout} /> : null}
		</ui.HeaderSmall>
	)
}
export default HeaderSmall
