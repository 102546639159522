import type { UUID, FileId } from '../models'

export type UserId = UUID
export type Avatar = {
	fileId?: FileId
	color?: string
}
export type Friend = {
	userId: UserId
	muted: boolean
}

export type UserRegisterInfo = {
	name: string
	surname: string
	email: string
	phoneNumber?: string
	password: string
}

export type MaybeUserProfile = UserProfile | null
export type UserProfile = {
	id: UserId
	avatar?: Avatar
	name?: string
	surname?: string
	displayName?: string
	birthDay?: Date
	country?: string
	timeZone?: string
	email?: string
	interests?: UserInterest[]
	friends?: Friend[]
	aboutMe?: string
}

export const getDisplayName = (
	profile?: Pick<
		UserProfile,
		'name' | 'surname' | 'displayName' | 'email'
	> | null
): string => {
	if (profile) {
		if (profile.displayName) {
			return profile.displayName
		}
		if (profile.name || profile.surname) {
			return `${profile.name || ''} ${profile.surname || ''}`
		}
		if (profile.email) {
			return profile?.email
		}
	}

	return ''
}

export const USER_INTERESTS: UserInterest[] = [
	'ANIMALS',
	'BOOKS',
	'CARS',
	'COOKING',
	'HEALTH',
	'KNOWLEDGE',
	'MEETINGS',
	'MOVIES',
	'MUSIC',
	'PAINT',
	'SPORT',
	'THEATER',
	'TRAVEL',
	'YOGA',
]
export type UserInterest =
	| 'ANIMALS'
	| 'BOOKS'
	| 'CARS'
	| 'COOKING'
	| 'HEALTH'
	| 'KNOWLEDGE'
	| 'MEETINGS'
	| 'MOVIES'
	| 'MUSIC'
	| 'PAINT'
	| 'SPORT'
	| 'THEATER'
	| 'TRAVEL'
	| 'YOGA'

export const USER_AVATAR_COLORS = {
	ORANGE: '#fc6920',
	YELLOW: '#fdb92e',
	MINT: '#7bdcb5',
	GREEN: '#22d084',
	AZURE: '#8ed1fc',
	BLUE: '#1b93e3',
	GREY: '#abb8c3',
	RED: '#eb154c',
	PINK: '#f78ca7',
	VIOLET: '#9913ef',
}

export type PollingWordCloudAnswer = {
	text: string
	userId: UserId
}
