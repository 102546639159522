import { colors, images, intl, ui } from '@beelday/common'
import styled from '@emotion/styled'
import { useFormik } from 'formik'
import { v4 as uuidv4 } from 'uuid'
import { useState } from 'react'
import { NewPollingWordCloud } from './world-cloud-models'

const Container = styled.div`
	display: flex;
`

const Column = styled.div`
	flex: 0 0 50%;
	max-width: 50%;
`

const FormTitle = styled.div`
	font-size: 18px;
	line-height: 28px;
	font-weight: 500;
	margin-bottom: 20px;
`

const FormRow = styled.div`
	margin-bottom: 25px;
`

const Preview = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${colors.heather10};
	border-radius: 7px;
	padding: 25px;
	margin-left: 30px;
	height: 100%;
`

const PreviewTitle = styled.div`
	font-size: 18px;
	line-height: 28px;
`

const PreviewQuestion = styled.div`
	font-weight: 700;
	margin-bottom: 30px;
`

const PreviewImage = styled.div`
	text-align: center;
	margin-top: auto;

	img {
		max-width: 100%;
	}
`

type ValidationErrors = {
	question?: 'field.required' | 'field.length'
}

type Props = {
	onSubmit: (polling: NewPollingWordCloud) => Promise<unknown>
	onClose: () => void
}

const empty: NewPollingWordCloud = {
	id: '',
	question: '',
	letFacilitatorAnswer: true,
	allowForMultipleAnswer: true,
	type: 'POLLING_OPEN_ENDED_WORD_CLOUD',
}

const PollingWordCloudForm = ({ onSubmit, onClose }: Props): JSX.Element => {
	const translate = intl.useTranslate()
	const questionLength = 250
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

	const formik = useFormik({
		validate: values => {
			const errors: ValidationErrors = {}

			if (!values.question) {
				errors.question = 'field.required'
			}

			if (values.question.length > questionLength) {
				errors.question = 'field.length'
			}

			return errors
		},
		initialValues: empty,
		onSubmit: values => {
			const newTemplate: NewPollingWordCloud = {
				id: values.id ? values.id : uuidv4(),
				question: values.question,
				letFacilitatorAnswer: values.letFacilitatorAnswer,
				allowForMultipleAnswer: values.allowForMultipleAnswer,
				type: 'POLLING_OPEN_ENDED_WORD_CLOUD',
			}
			onSubmit(newTemplate).finally(() => formik.setSubmitting(false))
		},
	})

	return (
		<>
			<Container>
				<Column>
					<FormTitle>
						<intl.Translate>polling.open_ended.word_cloud</intl.Translate>
					</FormTitle>
					<FormRow>
						<ui.InputLabeled
							label={translate('polling.open_ended.word_cloud.question.label')}
						>
							<ui.TextAreaLimited
								id={`question`}
								name={`question`}
								placeholder={translate(
									'polling.open_ended.word_cloud.question.placeholder'
								)}
								value={formik.values.question}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								style={{ minHeight: '150px' }}
								limit={questionLength}
								incorrect={!!formik.errors.question && isSubmitted}
							/>
							{formik.errors.question && isSubmitted && (
								<ui.ErrorPlaceholder>
									<intl.Translate>{formik.errors.question}</intl.Translate>
								</ui.ErrorPlaceholder>
							)}
						</ui.InputLabeled>
					</FormRow>
					<FormRow>
						<ui.InputLabeled
							label={translate(
								'polling.open_ended.word_cloud.additional_options.label'
							)}
						>
							<ui.Checkbox
								id="letFacilitatorAnswer"
								name="letFacilitatorAnswer"
								checked={formik.values.letFacilitatorAnswer}
								text={translate(
									'polling.open_ended.word_cloud.letFacilitatorAnswer.text'
								)}
								onChange={value =>
									formik.setFieldValue('letFacilitatorAnswer', value)
								}
								style={{ marginBottom: '10px' }}
							/>
							<ui.Checkbox
								id="allowForMultipleAnswer"
								name="allowForMultipleAnswer"
								checked={formik.values.allowForMultipleAnswer}
								text={translate(
									'polling.open_ended.word_cloud.allowForMultipleAnswer.text'
								)}
								onChange={value =>
									formik.setFieldValue('allowForMultipleAnswer', value)
								}
							/>
						</ui.InputLabeled>
					</FormRow>
				</Column>
				<Column>
					<Preview>
						<PreviewTitle>
							<intl.Translate>
								polling.open_ended.word_cloud.preview.title
							</intl.Translate>
						</PreviewTitle>
						<PreviewQuestion
							style={{
								fontSize: formik.values.question.length < 50 ? '28px' : '18px',
								lineHeight:
									formik.values.question.length < 50 ? '34px' : '24px',
							}}
						>
							{formik.values.question
								? formik.values.question
								: translate(
										'polling.open_ended.word_cloud.question.placeholder'
								  )}
						</PreviewQuestion>
						<PreviewImage>
							<img src={images.wordCloudImage} alt="Word Cloud" />
						</PreviewImage>
					</Preview>
				</Column>
			</Container>

			<ui.StickyWrapper
				style={{ padding: '24px 0px', borderRadius: '0px', boxShadow: 'none' }}
			>
				<ui.FlexSpaceBetween style={{ width: '100%' }}>
					<ui.ButtonMinor onClick={onClose}>
						<intl.Translate>cancel</intl.Translate>
					</ui.ButtonMinor>
					<ui.ButtonPrimary
						onClick={() => {
							formik.handleSubmit()
							setIsSubmitted(true)
						}}
						type="button"
						disabled={formik.isSubmitting}
					>
						<intl.Translate>polling.start_polling</intl.Translate>
					</ui.ButtonPrimary>
				</ui.FlexSpaceBetween>
			</ui.StickyWrapper>
		</>
	)
}

export default PollingWordCloudForm
