import { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'common/redux'
import { filterSceneVideoUsers } from 'room/group-room/model/scene-video-users'
import { roomEndUsers } from 'room/common/redux'
import { allStreams } from 'video-conference-media/redux'
import useKnowledgeChecks from './use-knowledge-checks'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import KnowledgeCheckReviewGridView from './presentation/knowledge-check-review-grid-view'
import { useDispatch } from 'app-redux'
import { setReview } from './knowledge-checks-redux'
import { UUID } from '../../common/types'

const ConfiguredKnowledgeCheckReviewTrainee: FC<{ knowledgeCheckId: UUID }> = ({
	knowledgeCheckId,
}) => {
	const dispatch = useDispatch()
	const roomAddress = useAssertedJoinedRoomAddress()
	const { getReview } = useKnowledgeChecks()
	const sceneVideoUsers = useSelector(allStreams)
	const endUsers = useSelector(roomEndUsers)
	const sceneVideoEndUsers = filterSceneVideoUsers(endUsers, sceneVideoUsers)
	const training = useSelector((state: RootState) => state.sessionInfo.training)
	const trainerProfile = useSelector(
		(state: RootState) =>
			state.users.all[
				training?.upperEchelon.userId ? training?.upperEchelon.userId : ''
			]
	)
	const review = useSelector((state: RootState) => state.knowledgeChecks.review)

	useEffect(() => {
		if (knowledgeCheckId) {
			getReview(roomAddress, knowledgeCheckId).then(reviewData =>
				dispatch(setReview(reviewData))
			)
		}
	}, [getReview, roomAddress, knowledgeCheckId, dispatch])

	return review ? (
		<KnowledgeCheckReviewGridView
			title={training?.subject}
			sceneVideoUsers={sceneVideoEndUsers}
			userProfile={trainerProfile}
			review={review}
		/>
	) : null
}

export default ConfiguredKnowledgeCheckReviewTrainee
