export const Trash: React.FC = () => (
	<svg
		version="1.1"
		id="Layer_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 14.5 16.3"
		width="100%"
		xmlSpace="preserve"
	>
		<path
			fill="currentColor"
			d="M13.8,3.2h-3.7V1.6C10.2,0.7,9.4,0,8.6,0H5.9C5.1,0,4.4,0.7,4.4,1.6v1.6H0.7C0.3,3.2,0,3.5,0,3.9
	c0,0.4,0.3,0.7,0.7,0.7h0.8l0.6,9.5c0.1,1.2,1.1,2.2,2.4,2.2h5.6c1.2,0,2.3-1,2.4-2.2L13,4.6h0.8c0.4,0,0.7-0.3,0.7-0.7
	C14.5,3.5,14.2,3.2,13.8,3.2z M5.8,1.6c0-0.1,0.1-0.2,0.2-0.2h2.6c0.1,0,0.2,0.1,0.2,0.2v1.6h-3V1.6z M11,14c0,0.5-0.5,0.9-1,0.9
	H4.5c0,0,0,0,0,0c-0.5,0-0.9-0.4-1-0.9L2.9,4.6l8.7,0L11,14z"
		/>
		<path
			fill="currentColor"
			d="M9.6,6.8v5.9c0,0.4-0.3,0.7-0.7,0.7h0c-0.4,0-0.7-0.3-0.7-0.7V6.8c0-0.4,0.3-0.7,0.7-0.7
	C9.3,6.1,9.6,6.4,9.6,6.8z"
		/>
		<path
			fill="currentColor"
			d="M6.3,6.8v5.9c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7V6.8c0-0.4,0.3-0.7,0.7-0.7C6,6.1,6.3,6.4,6.3,6.8z"
		/>
	</svg>
)
