import { ConnectionQuality } from 'connectivity/awaited-connections'

export type ConnectivityState = {
	quality: ConnectionQuality
}

const defaultConnectivityState: ConnectivityState = {
	quality: ConnectionQuality.Good,
}

type ConnectivityAction = {
	type: 'SET_CONNECTION_QUALITY'
	quality: ConnectionQuality
}

export const setConnectionQuality = (
	quality: ConnectionQuality
): ConnectivityAction => {
	return { type: 'SET_CONNECTION_QUALITY', quality }
}

export function connectivityReducer(
	state: ConnectivityState = defaultConnectivityState,
	action: ConnectivityAction
): ConnectivityState {
	switch (action.type) {
		case 'SET_CONNECTION_QUALITY':
			return { ...state, quality: action.quality }
		default:
			return state
	}
}
