export const CircleCheck: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="65"
		height="65"
		viewBox="0 0 65 65"
	>
		<g
			id="Group_22482"
			data-name="Group 22482"
			transform="translate(-995 -369)"
		>
			<g
				id="Ellipse_405"
				data-name="Ellipse 405"
				transform="translate(995 369)"
				fill="#fff"
				stroke="#4f17be"
				strokeWidth="2"
			>
				<circle cx="32.5" cy="32.5" r="32.5" stroke="none" />
				<circle cx="32.5" cy="32.5" r="31.5" fill="none" />
			</g>
			<path
				id="Path_8968"
				data-name="Path 8968"
				d="M1828.91,1538.247l9.116,9.116,18.16-17.355"
				transform="translate(-815.41 -1137.509)"
				fill="none"
				stroke="#4f17be"
				strokeWidth="2"
			/>
		</g>
	</svg>
)
