import styled from '@emotion/styled'
import { createContext, FC, useContext, useMemo, useState } from 'react'
import {
	DialogBody,
	MobileSeparator,
	CloseBtnContainer,
	Title,
	Separator,
	Buttons,
} from './confirm'
import colors from '../colors'
import { Translate } from '../intl/translate'
import { ButtonPrimary } from './button'
import CloseButton from './close'
import responsive from './responsive'
import { Modal } from '.'

const Desc = styled.div`
	display: flex;
	font-family: Ubuntu;
	font-size: 20px;
	font-weight: normal;
	height: fit-content;
	margin-bottom: 16px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	width: 80%;

	${responsive.smallTablet} {
		align-items: start;
		font-size: 14px;
		height: 100%;
		justify-content: flex-start;
		margin-bottom: 0px;
		text-align: start;
		width: 100%;
	}
`

const Tip = styled.div`
	color: ${colors.black70};
	display: flex;
	font-family: Ubuntu;
	font-size: 14px;
	font-weight: normal;
	height: fit-content;
	justify-content: center;
	margin-bottom: 32px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	width: 80%;

	${responsive.smallTablet} {
		align-items: start;
		font-size: 14px;
		height: 100%;
		justify-content: flex-start;
		margin-bottom: 0px;
		text-align: start;
		width: 100%;
	}
`

type Props = {
	title: JSX.Element | string
	description: JSX.Element | string
	tip: JSX.Element | string
	proceed: () => void
	cancel: () => void
	okButton?: JSX.Element
}

type ContainerProps = {
	cancel: () => void
}

const DialogContainer: React.FC<ContainerProps> = ({ cancel, children }) => (
	<Modal onClose={cancel} backgroundColor={colors.transparentBlackish}>
		<DialogBody>{children}</DialogBody>
	</Modal>
)

const SingleDialog: FC<Props> = ({
	title,
	description,
	tip,
	proceed,
	cancel,
	okButton,
}) => {
	return (
		<DialogContainer cancel={cancel}>
			<MobileSeparator />
			<CloseBtnContainer>
				<CloseButton onClick={cancel} />
			</CloseBtnContainer>
			<Title>{title}</Title>
			<Separator />
			<Desc>{description}</Desc>
			<Tip>{tip}</Tip>
			<Buttons>
				<ButtonPrimary onClick={proceed}>
					{okButton || <Translate>ok</Translate>}
				</ButtonPrimary>
			</Buttons>
		</DialogContainer>
	)
}

type Result = 'CONFIRMED' | 'CANCELLED'

type Handlers = {
	information(
		title: JSX.Element | string,
		description: JSX.Element | string,
		tip: JSX.Element | string
	): Promise<Result>
}
const createHandlers = (
	setDialog: (dialog: JSX.Element | null) => unknown
): Handlers => ({
	information: (title, description, tip) => {
		return new Promise((resolve, reject) => {
			setDialog(
				<SingleDialog
					title={title}
					description={description}
					tip={tip}
					okButton={<Translate>information.button.close</Translate>}
					proceed={() => {
						setDialog(null)
						resolve('CONFIRMED')
					}}
					cancel={() => {
						setDialog(null)
						reject('CANCELLED')
					}}
				/>
			)
		})
	},
})

const ConfirmContext = createContext<Handlers | null>(null)
export const useSingleConfirm = (): Handlers => {
	const handlers = useContext(ConfirmContext)
	if (!handlers)
		throw new Error('ConfirmProvider is not mounted in parent tree')

	return handlers
}
export const SingleConfirmProvider: FC = ({ children }) => {
	const [dialog, setDialog] = useState<JSX.Element | null>(null)
	const handlers = useMemo(() => createHandlers(setDialog), [])
	return (
		<ConfirmContext.Provider value={handlers}>
			{children}
			{dialog}
		</ConfirmContext.Provider>
	)
}
