import { FC } from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { RoomId, User, UserProfile } from 'common/types'
import plusIcon from 'common/presentation/images/plus.svg'
import { Colors } from 'common/presentation/colors'
import { ui } from '@beelday/common'
import {
	PublicChatRoomEncouragement,
	PublicChatRoomStatus,
	PublicChatRoomType,
} from './model'
import Translate from 'intl/translate'
import Users from './chat-room-users'
import rooms from './rooms-presentation'
import encouragementBgTop from './images/encouragement-blue-bg-top.svg'
import encouragementBgBottom from './images/encouragement-blue-bg-bottom.svg'
import fancyUnderline from './images/fancy-underline.png'
import { useIntl } from 'react-intl'
import RoomContainer from './room-container'
import ProfileAvatar from 'users/profile-avatar'

const Encouragement = styled.div`
	z-index: 1;
	position: relative;
	color: ${Colors.white};
	margin-top: 55px;
	font-size: 16px;
	text-align: center;

	${ui.responsive.desktop1440} {
		font-size: 18px;
	}

	em {
		font-weight: bold;
		font-style: normal;
	}
	img.background-top {
		background: url(${encouragementBgTop});
		border-bottom: 1px solid #4f17be;
		border-top: 2px solid white;
		position: absolute;
		top: -2px;
		left: 50%;
		transform: translateX(-50%);
		height: 40px;
		width: 136px;
	}
	img.background-bottom {
		position: absolute;
		bottom: -24px;
		z-index: -1;
		left: 50%;
		transform: translateX(-50%);
	}
`

const EncouragementBody = styled.div`
	padding: 40px 20px 30px 20px;
	border-radius: 20px;
	background-color: ${Colors.indigoBlue};

	${ui.responsive.desktop1440} {
		padding: 50px 20px 40px 20px;
	}
`

const TrainerFace = styled(ProfileAvatar)`
	height: 54px;
	width: 54px;
	position: absolute;
	left: 50%;
	top: -5px;
	transform: translate(-50%, -50%);

	${ui.responsive.desktop1440} {
		top: -10px;
		height: 63px;
		width: 63px;
	}
`

const JoinText = styled.div`
	font-size: 20px;
	font-weight: bold;
	margin-top: 5px;

	${ui.responsive.desktop1440} {
		font-size: 22px;
	}

	em {
		color: ${Colors.lipstick2};
		font-style: normal;
		position: relative;
	}
	em > img {
		position: absolute;
		bottom: -5px;
		left: 0;
		right: 0;
		height: 8px;
		width: 100%;
		object-fit: fill;
	}
`

const EmptyState = styled.div`
	display: flex;
	align-items: center;
	flex: 1;
	margin-top: auto;
	overflow: hidden;
	padding-top: 20px;

	img {
		height: 100%;
		max-width: 200px;
		max-height: 140px;
		width: 100%;
	}
`

const RoundButton = styled.button`
	border: none;
	box-shadow: none;
	background-color: ${Colors.greenishCyan};
	color: ${Colors.white};
	font-size: 20px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	width: 37px;
	height: 37px;
	img {
		width: 22px;
		height: 22px;
	}
`
const ActionButtonContainer = styled.div`
	position: absolute;
	bottom: -17px;
	left: 50%;
	transform: translateX(-50%);
`
const actionButtonRippleAnimation = keyframes`
0% {
	opacity: 0;
	width: 100%;
	height: 100%;
}
10% {
	opacity: 0.8;
	height: 140%;
	width: 140%;
}
20% {
	opacity: 0;
	height: 180%;
	width: 180%;
}
100% {
	opacity: 0;
	height: 170%;
	width: 170%;
}
`
const ActionButtonRipple = styled.div`
	position: absolute;
	border: 1px solid ${Colors.greenishCyan};
	border-radius: 50%;
	opacity: 0;
	width: 40px;
	height: 40px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	animation: ${actionButtonRippleAnimation} 5s cubic-bezier(0, 0.2, 0.8, 1)
		infinite;
	:nth-child(2) {
		animation-delay: -0.3s;
	}
	:nth-child(3) {
		animation-delay: -0.6s;
	}
`
const ActionButton: React.FC = ({ children }) => (
	<ActionButtonContainer>
		<ActionButtonRipple />
		<ActionButtonRipple />
		<ActionButtonRipple />
		<RoundButton>{children}</RoundButton>
	</ActionButtonContainer>
)
const EmphasizeWithUnderline = (chunks: string) => (
	<em>
		{chunks}
		<img src={fancyUnderline} alt="" />
	</em>
)

type ChatRoom = {
	id: RoomId
	status?: PublicChatRoomStatus
	hobby?: string
	encouragement?: PublicChatRoomEncouragement
	type?: PublicChatRoomType
	users: (User & Partial<UserProfile>)[]
}

type Props = {
	room: ChatRoom
	upperEchelon?: UserProfile | null
	onSelect: () => void
}

const TrainerSelfRoom: FC<Props> = ({ room, upperEchelon, onSelect }) => {
	const intl = useIntl()

	return (
		<RoomContainer
			onSelect={onSelect}
			style={{
				borderRadius: '50px',
				boxShadow: '0 0 40px 0 rgba(94, 92, 154, 0.15)',
			}}
		>
			<JoinText>
				<Translate em={EmphasizeWithUnderline}>
					lobby.chat_room.trainer_room_title
				</Translate>
			</JoinText>
			{room.encouragement ? (
				<Encouragement>
					<img
						src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
						className="background-top"
					/>
					<img
						src={encouragementBgBottom}
						alt=""
						className="background-bottom"
					/>
					<TrainerFace profile={upperEchelon} />
					<EncouragementBody>
						{intl.formatMessage({
							id: `lobby.encouragement.${room.encouragement.code?.toLowerCase()}`,
						})}
						<ActionButton>
							<img src={plusIcon} alt="plus" />
						</ActionButton>
					</EncouragementBody>
				</Encouragement>
			) : null}
			{room.users.length === 0 ? (
				<EmptyState>
					<img src={room.type && rooms[room.type].emptyImg} alt="Empty" />
				</EmptyState>
			) : (
				<Users users={room.users} roomType="UPPER_ECHELON" onJoin={onSelect} />
			)}
		</RoomContainer>
	)
}

export default TrainerSelfRoom
