import styled from '@emotion/styled'
import { colors, ui, intl } from '@beelday/common'

const Container = styled.div`
	display: flex;
	align-items: center;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 120px;
	background: ${colors.white};
	border-radius: 50px;
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.15);
	padding: 10px 20px;
`

const Text = styled.div`
	font-size: 14px;
	line-height: 20px;
	margin-right: 15px;
`

type Props = {
	onClose: () => unknown
	onCancel: () => unknown
	isPermission?: boolean
}

const ScreenShareTraineePopup = ({
	onClose,
	onCancel,
	isPermission,
}: Props): JSX.Element => {
	return (
		<Container>
			{isPermission ? (
				<Text>
					<intl.Translate>
						room_menu.screen_share.popup.trainee_text.accepted
					</intl.Translate>
				</Text>
			) : (
				<>
					<Text>
						<intl.Translate>
							room_menu.screen_share.popup.trainee_text
						</intl.Translate>
					</Text>
					<ui.ButtonMinor
						style={{ height: '30px', padding: '0 20px', marginRight: '15px' }}
						onClick={onCancel}
					>
						<intl.Translate>room_menu.screen_share.popup.cancel</intl.Translate>
					</ui.ButtonMinor>
				</>
			)}
			<ui.ButtonClose onClick={onClose} />
		</Container>
	)
}

export default ScreenShareTraineePopup
