import { isFulfilled } from '@reduxjs/toolkit'
import { useDispatch } from 'app-redux'
import { Config } from 'common/config'
import { useMemo } from 'react'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { Slide, SlideId } from './slide-models'
import { actions } from './slide-redux'
import { getSlidesApi } from './slides-api'

type SlidesHook = {
	openSlide: (slideId: SlideId | null | undefined, slideName: string) => void
	closeSlide: () => void
	getThumbnailUrl: (slide: Slide) => string | undefined
}
export const useSlides = (): SlidesHook => {
	const dispatch = useDispatch()
	const slidesApi = getSlidesApi(Config.beeldayBackendUrl, Config.slidesApiUrl)
	const roomAddress = useAssertedJoinedRoomAddress()

	return useMemo(
		() => ({
			openSlide: async (slideId, slideName): Promise<void> => {
				if (!slideId) {
					const res = await dispatch(actions.fetchAll())
					if (isFulfilled(res)) {
						slideId = res.payload[0]?.id
					}
				}

				if (slideId) {
					return slidesApi.openSlide(roomAddress, slideId, slideName)
				}
			},
			closeSlide: () => {
				slidesApi.closeSlide(roomAddress)
			},
			getThumbnailUrl: slide => {
				return slidesApi.getThumbnailUrl(slide)
			},
		}),
		[slidesApi, roomAddress, dispatch]
	)
}
