import styled from '@emotion/styled'
import { images } from '../index'
import colors from '../colors'
import { NavLink, useLocation } from 'react-router-dom'
import responsive from './responsive'

const BackIcon = styled.img`
	width: 20px;
	height: 15px;
`

const Header = styled.div`
	width: fit-content;
	align-items: center;
	display: flex;
	justify-content: center;
	margin-bottom: 16px;

	${responsive.smallTablet} {
		margin-bottom: 30px;
	}
`

const Title = styled.p`
	border-left: 1px solid ${colors.borderGray50};
	font-size: 16px;
	font-weight: bold;
	margin: 0 0 0 16px;
	padding-left: 16px;

	${responsive.smallTablet} {
		font-size: 28px;
	}
`
type Props = {
	to?: string
	title: JSX.Element
	marginBottom?: string
}

export const BackHeader = ({ to, title, marginBottom }: Props): JSX.Element => {
	const location = useLocation<{ from?: string | null }>()

	const href = location?.state?.from || to

	return (
		<Header style={{ marginBottom: marginBottom }}>
			{href && (
				<NavLink to={href}>
					<BackIcon src={images.backArrow} />
				</NavLink>
			)}
			<Title>{title}</Title>
		</Header>
	)
}

type PropsWithAction = {
	title: JSX.Element
	marginBottom?: string
	backAction: () => void
}
export const BackHeaderWithAction = ({
	title,
	marginBottom,
	backAction,
}: PropsWithAction): JSX.Element => (
	<Header style={{ marginBottom: marginBottom }}>
		{backAction && <BackIcon src={images.backArrow} onClick={backAction} />}
		<Title>{title}</Title>
	</Header>
)
