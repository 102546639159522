import React, { useCallback } from 'react'
import { SceneVideoUser } from 'video-conference-media'
import { Config } from 'common/config'
import { ui } from '@beelday/common'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { useSelector } from 'react-redux'
import { RootState } from 'common/redux'
import { getListPollingApi } from './list-polling-api'
import ListPollingChat from './list-polling-chat'
import { Answer } from './list-polling-models'
import { isEqual } from 'lodash'
import OnlyAudioGrid from 'video-conference-media/presentation/only-audio-grid'
import ThreeColumnsVideoGrid from 'room/training-room/ice-breaker/presentation/three-columns-video-grid'

type Props = {
	streams: SceneVideoUser[]
	isTrainer: boolean
}

const ListPollingPage = ({ streams, isTrainer }: Props): JSX.Element | null => {
	const listPollingApi = getListPollingApi(Config.beeldayBackendUrl)
	const roomAddress = useAssertedJoinedRoomAddress()
	const pollingDefinition = useSelector(
		(state: RootState) => state.listPolling.pollingDefinition
	)

	const answers = useSelector((state: RootState) => state.listPolling.answers)

	const onSubmit = useCallback(
		(questionId, text) => {
			if (pollingDefinition) {
				listPollingApi.sendAnswer(
					roomAddress,
					pollingDefinition.id,
					questionId,
					text
				)
			}
		},
		[listPollingApi, pollingDefinition, roomAddress]
	)
	return pollingDefinition ? (
		<>
			{pollingDefinition.questions.length > 2 ? (
				<OnlyAudioGrid sceneVideoUsers={streams}>
					<ui.ListPollingMainContainer>
						{pollingDefinition.questions.map(question => (
							<MemoizedListPollingChat
								key={question.id}
								question={question}
								isTrainer={isTrainer}
								answers={answers[question.id]}
								letFacilitatorAnswer={pollingDefinition.letFacilitatorAnswer}
								allowForMultipleAnswer={
									pollingDefinition.allowForMultipleAnswer
								}
								onSubmit={(questionId, text) => onSubmit(questionId, text)}
							/>
						))}
					</ui.ListPollingMainContainer>
				</OnlyAudioGrid>
			) : (
				<ThreeColumnsVideoGrid sceneVideoUsers={streams}>
					<ui.ListPollingMainContainer>
						{pollingDefinition.questions.map(question => (
							<MemoizedListPollingChat
								key={question.id}
								question={question}
								isTrainer={isTrainer}
								answers={answers[question.id]}
								letFacilitatorAnswer={pollingDefinition.letFacilitatorAnswer}
								allowForMultipleAnswer={
									pollingDefinition.allowForMultipleAnswer
								}
								onSubmit={(questionId, text) => onSubmit(questionId, text)}
							/>
						))}
					</ui.ListPollingMainContainer>
				</ThreeColumnsVideoGrid>
			)}
		</>
	) : null
}

function chatPropsAreEqual(
	prevAnswers: { answers?: Answer[] },
	newAnswers: { answers?: Answer[] }
): boolean {
	return isEqual(prevAnswers.answers, newAnswers.answers)
}

const MemoizedListPollingChat = React.memo(ListPollingChat, chatPropsAreEqual)

export default ListPollingPage
