export const PlusIcon: React.FC = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
		<title>ionicons-v5-a</title>
		<line
			x1="256"
			y1="112"
			x2="256"
			y2="400"
			style={{
				fill: 'none',
				stroke: 'currentColor',
				strokeLinecap: 'square',
				strokeLinejoin: 'round',
				strokeWidth: '32px',
			}}
		/>
		<line
			x1="400"
			y1="256"
			x2="112"
			y2="256"
			style={{
				fill: 'none',
				stroke: 'currentColor',
				strokeLinecap: 'square',
				strokeLinejoin: 'round',
				strokeWidth: '32px',
			}}
		/>
	</svg>
)
