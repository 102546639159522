import { intl, ui, UserProfile } from '@beelday/common'
import styled from '@emotion/styled'
import colors from '../colors'
import { ProfileAvatar } from './profile-avatar'

const Profile = styled.div`
	margin-left: -10px;
	&:first-of-type {
		margin-left: 0px;
	}
`

const More = styled.div<{ size: string }>`
	position: relative;
	align-items: center;
	background-color: ${colors.indigoBlue};
	border: 2px solid ${colors.white};
	border-radius: 50%;
	color: ${colors.white};
	display: inline-flex;
	font-family: Ubuntu;
	font-size: 13px;
	font-weight: 500;
	height: ${props => props.size};
	justify-items: center;
	justify-content: center;
	min-width: ${props => props.size};
	min-height: ${props => props.size};
	margin-left: -10px;
	width: ${props => props.size};
`

type PROFILE_MISSING = null

type Props = {
	profiles: Array<UserProfile | PROFILE_MISSING>
	size?: string
	maxFacesVisible?: number
	borderColor?: string
	userApiUrl: string
	border?: string
}

export const ProfileAvatars = ({
	profiles,
	size = '28px',
	maxFacesVisible = 3,
	borderColor = colors.white,
	userApiUrl,
	border = `2px solid ${borderColor}`,
}: Props): JSX.Element | null => {
	if (profiles.length === 0) {
		return null
	} else {
		const additional = profiles.length - maxFacesVisible
		return (
			<ui.Flex style={{ cursor: 'pointer' }}>
				<ui.Tooltip
					overlay={<intl.Translate>view_more</intl.Translate>}
					placement="top"
				>
					<ui.FlexMiddle>
						{profiles.map(
							(p, idx) =>
								idx < maxFacesVisible && (
									<Profile key={p ? p.id : idx}>
										<ProfileAvatar
											avatar={p?.avatar}
											size={size}
											border={border}
											userApiUrl={userApiUrl}
										/>
									</Profile>
								)
						)}
						{additional > 0 ? (
							<More
								style={{
									borderColor: borderColor,
									backgroundColor:
										borderColor !== colors.white
											? colors.white
											: colors.indigoBlue,
									color:
										borderColor !== colors.white
											? colors.indigoBlue
											: colors.white,
									border: border,
								}}
								size={size}
							>
								+{additional}
							</More>
						) : null}
					</ui.FlexMiddle>
				</ui.Tooltip>
			</ui.Flex>
		)
	}
}
