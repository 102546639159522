import { Group, GroupId, User, UserRole } from 'common/types'
import BeeldayClient from 'connectivity/beelday-client'

export function getEndUsers(users: User[]): User[] {
	return users.filter(user => user.role === UserRole.END_USER)
}

export const getUserGroup = (
	groups: Record<GroupId, Group>,
	currentUser: User
): Group | undefined => {
	return Object.values(groups).find(group =>
		group.users.find(user => user.id === currentUser.id)
	)
}

export function leaveWorkflow(
	beeldayClient: BeeldayClient,
	workflowId: string,
	userId: string
): void {
	beeldayClient.leaveWorkflow({
		workflowId,
		userId,
	})
}
