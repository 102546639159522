export const HourglassIcon = (): JSX.Element => (
	<svg
		version="1.1"
		id="Group_27272"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		width="11.5px"
		height="16px"
		viewBox="0 0 11.5 16"
		xmlSpace="preserve"
	>
		{/* <style type="text/css"> */}
		{/* .st0{fill:#4F17BE;} */}
		{/* </style> */}
		<g>
			<g id="Group_27273">
				<path
					id="Path_11835"
					fill="currentColor"
					d="M10.2,2.6V1.1H11c0.3,0,0.6-0.2,0.6-0.6S11.3,0,11,0H0.6C0.2,0,0,0.2,0,0.6s0.2,0.6,0.6,0.6
			h0.7v1.5c0,0.6,0.2,1.2,0.5,1.7L4.3,8l-2.5,3.7c-0.3,0.5-0.5,1.1-0.5,1.7v1.5H0.6c-0.3,0-0.6,0.2-0.6,0.6C0,15.8,0.2,16,0.6,16H11
			c0.3,0,0.6-0.2,0.6-0.6c0-0.3-0.2-0.6-0.6-0.6h-0.7v-1.5c0-0.6-0.2-1.2-0.5-1.7L7.3,8l2.5-3.7C10.1,3.8,10.2,3.2,10.2,2.6 M8,2.5
			c0,0.6-0.2,1.3-0.6,1.8L5.8,6.5L4.1,4.3C3.7,3.8,3.5,3.2,3.5,2.5V2H8V2.5z"
				/>
			</g>
		</g>
	</svg>
)
