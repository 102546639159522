import { format } from 'date-fns'
import {
	LocalTime,
	MINUTES,
	of,
	safeAddMinutes,
	twentyFourHoursFormat,
} from './local-time'

const valueOf = (num: number): string => {
	const abs = Math.abs(num)

	if (abs < 10) {
		return `0${abs}`
	} else {
		return `${abs}`
	}
}

export const toUTC = (date: Date, offset: number): string => {
	const formatted = format(date, 'yyyy-MM-dd')
	const hours = valueOf(offset) + ':00'
	const off = offset > 0 ? '+' + hours : '-' + hours
	const proper = new Date(formatted + 'T00:00:00.000' + off)

	return proper.toISOString()
}

export const toUTCWithTime = (
	date: Date,
	time: LocalTime,
	offset: number
): string => {
	const formatted = format(date, 'yyyy-MM-dd')
	const hours = valueOf(offset) + ':00'
	const off = offset > 0 ? '+' + hours : '-' + hours
	const proper = new Date(
		formatted + `T${twentyFourHoursFormat(time)}:00.000` + off
	)

	return proper.toISOString()
}

export const utcToLocalTime = (utc: Date, offset: number): LocalTime => {
	const utcHours = utc.getUTCHours()
	const utcMinutes = utc.getUTCMinutes()

	const utcTime = of(utcHours, utcMinutes)
	const offsetInMinutes = offset * MINUTES

	return safeAddMinutes(utcTime, offsetInMinutes)
}

export enum CONTINENT {
	USA,
	EUROPE,
}
export type TimeZoneData = {
	id: string
	name: string
	continent: CONTINENT
	gmt: number
	short: string
}
export const timeZones: Record<string, TimeZoneData> = {
	'Europe/Warsaw': {
		id: 'Europe/Warsaw',
		name: 'Warsaw',
		continent: CONTINENT.EUROPE,
		gmt: 1,
		short: 'GMT+1',
	},
	'Europe/Berlin': {
		id: 'Europe/Berlin',
		name: 'Berlin',
		continent: CONTINENT.EUROPE,
		gmt: 1,
		short: 'GMT+1',
	},
	'Europe/Paris': {
		id: 'Europe/Paris',
		name: 'Paris',
		continent: CONTINENT.EUROPE,
		gmt: 1,
		short: 'GMT+1',
	},
	'Europe/Amsterdam': {
		id: 'Europe/Amsterdam',
		name: 'Amsterdam',
		continent: CONTINENT.EUROPE,
		gmt: 1,
		short: 'GMT+1',
	},
	'Europe/London': {
		id: 'Europe/London',
		name: 'London',
		continent: CONTINENT.EUROPE,
		gmt: 0,
		short: 'GMT',
	},
	'Europe/Dublin': {
		id: 'Europe/Dublin',
		name: 'Dublin',
		continent: CONTINENT.EUROPE,
		gmt: 0,
		short: 'GMT',
	},
	'Pacific/Honolulu': {
		id: 'Pacific/Honolulu',
		name: 'Hawaii',
		continent: CONTINENT.USA,
		gmt: -10,
		short: 'HST',
	},
	'US/Alaska': {
		id: 'US/Alaska',
		name: 'Alaska',
		continent: CONTINENT.USA,
		gmt: -9,
		short: 'AKST',
	},
	'US/Pacific': {
		id: 'US/Pacific',
		name: 'Pacific',
		continent: CONTINENT.USA,
		gmt: -8,
		short: 'PST',
	},
	'US/Mountain': {
		id: 'US/Mountain',
		name: 'Mountain',
		continent: CONTINENT.USA,
		gmt: -7,
		short: 'MST',
	},
	'US/Central': {
		id: 'US/Central',
		name: 'Central',
		continent: CONTINENT.USA,
		gmt: -6,
		short: 'CST',
	},
	'US/Eastern': {
		id: 'US/Eastern',
		name: 'Eastern',
		continent: CONTINENT.USA,
		gmt: -5,
		short: 'EST',
	},
} as const

export type TimeZoneId = keyof typeof timeZones
