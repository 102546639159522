import { PollingMultipleChoicePollingDefinition } from './multiple-choice-polling-models'
import { MaybeUserProfile, ui, UUID } from '@beelday/common'
import styled from '@emotion/styled'
import { useCallback, useState } from 'react'
import ProfileAvatar from 'users/profile-avatar'

const Container = styled.div`
	max-width: 850px;
	width: 100%;
	margin: 0 auto;
	padding: 20px;
	height: 100%;
	overflow: auto;

	${ui.responsive.desktop1440} {
		padding: 50px 20px;
	}
`

const Title = styled.div`
	font-size: 28px;
	line-height: 36px;
	font-weight: 700;
	margin-bottom: 20px;

	${ui.responsive.desktop1440} {
		font-size: 24px;
		line-height: 30px;
	}
`

const SmallTitle = styled.div`
	font-size: 18px;
	line-height: 26px;
	font-weight: 700;
	margin-bottom: 20px;
`

type Props = {
	pollingDefinition: PollingMultipleChoicePollingDefinition
	userProfile?: MaybeUserProfile
	onSubmit: (answerIds: UUID[]) => unknown
}

const MultipleChoiceForm = ({
	pollingDefinition,
	userProfile,
	onSubmit,
}: Props): JSX.Element => {
	const [selected, setSelected] = useState<UUID[]>([])
	const onChange = useCallback(
		(answerId: UUID) => {
			if (pollingDefinition.allowForMultipleAnswer) {
				let updatedList = [...selected]
				if (updatedList.includes(answerId)) {
					updatedList = updatedList.filter(e => e !== answerId)
				} else {
					updatedList = [...updatedList, answerId]
				}
				setSelected(updatedList)
			} else {
				setSelected([answerId])
			}
		},
		[pollingDefinition.allowForMultipleAnswer, selected]
	)

	return (
		<Container>
			{pollingDefinition.question.length > 50 ? (
				<SmallTitle>{pollingDefinition.question}</SmallTitle>
			) : (
				<Title>{pollingDefinition.question}</Title>
			)}

			<div>
				<ui.CheckboxList>
					{pollingDefinition.possibleAnswers.map(option => (
						<ui.CheckboxListAvatarItem
							key={option.answerId}
							type={
								pollingDefinition.allowForMultipleAnswer ? 'checkbox' : 'radio'
							}
							name={option.text}
							value={option.answerId}
							checked={selected?.includes(option.answerId)}
							avatar={
								selected?.includes(option.answerId) ? (
									<ProfileAvatar profile={userProfile} />
								) : undefined
							}
							onChange={() => {
								onChange(option.answerId)
							}}
						/>
					))}
				</ui.CheckboxList>
				<div>
					<ui.ButtonPrimary
						style={{ width: '100%', marginTop: '20px' }}
						onClick={() => onSubmit(selected)}
						disabled={!selected.length}
					>
						Submit
					</ui.ButtonPrimary>
				</div>
			</div>
		</Container>
	)
}

export default MultipleChoiceForm
