import { ui } from '@beelday/common'
import { GroupReview, RoomAddress } from 'common/types'
import {
	EtherpadSessionDto,
	ExcalidrawSessionDto,
} from 'connectivity/beelday-client'

import styled from '@emotion/styled'
import { Colors } from 'common/presentation/colors'
import { useAuthenticatedBeeldayClient } from 'connectivity/beelday-hooks'
import Translate from 'intl/translate'
import { FC, useEffect, useState } from 'react'
import { colorToHex } from 'room/common/presentation/colorsInHex'
import noMediaIcon from 'room/common/presentation/images/no-media.svg'
import EtherPadIframe from 'room/group-room/presentation/ether-pad-iframe'
import ExcalidrawContainer from 'room/group-room/presentation/excalidraw-container'
import MediaContainer from 'room/group-room/presentation/media-container'

type Props = {
	address: RoomAddress
	activeMedia: 'ETHERPAD' | 'EXCALIDRAW'
	group: GroupReview
}

const MediaReview: FC<Props> = ({ address, activeMedia, group }) => {
	const beeldayClient = useAuthenticatedBeeldayClient()

	const [etherPadData, setEtherPadData] = useState<EtherpadSessionDto>()
	const [excalidrawSession, setExcalidrawSession] =
		useState<ExcalidrawSessionDto>()

	const activeGroupId = group.id
	const groupHasMedia = group.task.usedMedia.find(
		media => media.type === activeMedia
	)

	useEffect(() => {
		groupHasMedia &&
			activeMedia === 'ETHERPAD' &&
			beeldayClient.getEtherPadDataForReview(address).then(setEtherPadData)
	}, [beeldayClient, address, activeMedia, groupHasMedia, activeGroupId])

	useEffect(() => {
		groupHasMedia &&
			activeMedia === 'EXCALIDRAW' &&
			beeldayClient
				.getExcalidrawSessionForReview(address)
				.then(setExcalidrawSession)
	}, [beeldayClient, address, activeMedia, groupHasMedia, activeGroupId])

	return (
		<Container>
			<ContainerInner>
				{!groupHasMedia ? (
					<MediaContainer colorHex={colorToHex[group.properties.color]}>
						<NoMedia>
							<NoMediaMessage>
								<NoMediaImage>
									<img src={noMediaIcon} alt="no media icon" />
								</NoMediaImage>
								<Translate group={group.properties.name}>
									media_review.no_media
								</Translate>
								<br />
								<b>
									<Translate>{activeMedia}</Translate>
								</b>
							</NoMediaMessage>
						</NoMedia>
					</MediaContainer>
				) : excalidrawSession && activeMedia === 'EXCALIDRAW' ? (
					excalidrawSession.boardId ? (
						<ExcalidrawContainer
							color={group.properties.color}
							loaded={true}
							viewModeEnabled={excalidrawSession.role !== 'CREATOR'}
							boardId={excalidrawSession.boardId.value}
						/>
					) : (
						<ExcalidrawContainer
							color={group.properties.color}
							loaded={false}
							viewModeEnabled={false}
						/>
					)
				) : etherPadData ? (
					<EtherPadIframe
						color={group.properties.color}
						loaded
						padId={etherPadData.padId}
						sessionId={etherPadData.sessionId}
					/>
				) : (
					<EtherPadIframe color={group.properties.color} loaded={false} />
				)}
			</ContainerInner>
		</Container>
	)
}

const NoMedia = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;
	height: 100%;
	text-align: center;
	background-color: ${Colors.paleGray};
`

const NoMediaImage = styled.div`
	margin-bottom: 20px;
`

const NoMediaMessage = styled.div`
	font-size: 28px;
	line-height: 38px;
	font-weight: 500;

	b {
		color: ${Colors.indigoBlue};
		text-transform: uppercase;
	}
`

const Container = styled.div`
	width: 100%;
	position: relative;

	&:before {
		display: block;
		content: '';
		width: 100%;
		padding-top: 56.25%;

		${ui.responsive.tablet} {
			display: none;
		}
	}
`

const ContainerInner = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;

	${ui.responsive.tablet} {
		position: relative;
	}
`

export default MediaReview
