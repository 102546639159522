export const DownloadIcon: React.FC = () => (
	<svg
		id="Group_25687"
		data-name="Group 25687"
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="14.4"
		viewBox="0 0 16 14.4"
		fill="currentColor"
	>
		<path
			id="Path_12134"
			data-name="Path 12134"
			fill="currentColor"
			d="M9.2,12a.8.8,0,0,1-.566-.234l-2.4-2.4A.8.8,0,0,1,7.366,8.234L9.2,10.069l1.834-1.834a.8.8,0,0,1,1.131,1.131l-2.4,2.4A.8.8,0,0,1,9.2,12"
			transform="translate(-1.2 -1.6)"
		/>
		<path
			id="Path_12135"
			data-name="Path 12135"
			fill="currentColor"
			d="M12.8,17H3.2A3.2,3.2,0,0,1,0,13.8a.8.8,0,0,1,1.6,0,1.6,1.6,0,0,0,1.6,1.6h9.6a1.6,1.6,0,0,0,1.6-1.6.8.8,0,0,1,1.6,0A3.2,3.2,0,0,1,12.8,17"
			transform="translate(0 -2.6)"
		/>
		<path
			id="Path_12136"
			data-name="Path 12136"
			fill="currentColor"
			d="M9.812,8.8a.8.8,0,0,1-.8-.8V.8a.8.8,0,1,1,1.6,0V8a.8.8,0,0,1-.8.8"
			transform="translate(-1.802 0)"
		/>
	</svg>
)
