import { images } from '..'

export type Props = React.HTMLAttributes<HTMLImageElement> & {
	iconMIME?: string
	width?: number
	height?: number
}

const selectIconByMimeType = (iconMIME: string | undefined) => {
	switch (iconMIME) {
		case 'application/avi':
		case 'video/avi':
			return images.avi
		case 'application/doc':
			return images.doc
		case 'application/eps':
			return images.eps
		case 'application/gif':
		case 'image/gif':
			return images.gif
		case 'application/pdf':
			return images.pdf
		case 'application/png':
		case 'image/png':
			return images.png
		case 'application/vnd.ms-powerpoint':
			return images.ppt
		case 'text/plain':
			return images.txt
		case 'application/vnd.ms-excel':
			return images.xls
		case 'application/zip':
			return images.zip
		default:
			return images.unknown
	}
}

export const AttachmentIcon = ({
	iconMIME,
	height = 40,
	width = 36,
	...rest
}: Props): JSX.Element => {
	return (
		<img
			src={selectIconByMimeType(iconMIME)}
			height={height}
			width={width}
			{...rest}
		/>
	)
}
