import { FC } from 'react'
import styled from '@emotion/styled'
import { useMediaQuery } from 'react-responsive'
import colors from '../colors'
import responsive from './responsive'
import * as intl from '../intl'
import * as images from '../images'

const LogoContainer = styled.div`
	position: relative;
	width: 100%;

	${responsive.tablet} {
		width: 400px;
		min-width: 400px;
	}

	@media (width: 1024px) {
		width: 100%;
	}

	${responsive.desktop} {
		width: 700px;
		min-width: 700px;
	}
`

const BeeldayLogoBackground = styled.img`
	position: absolute;
	pointer-events: none;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 0;

	@media (min-width: 666px) and (max-width: 1024px) {
		top: -200px;
	}
`
const BeeldayLogo = styled.img`
	position: relative;
	margin: 30px auto;
	display: block;

	${responsive.tablet} {
		margin: 0;
		position: absolute;
		width: 180px;
		position: absolute;
		top: 27px;
		left: 50px;
	}

	@media (width: 1024px) {
		width: 200px;
		top: 0px;
		left: 0px;

		position: relative;
		margin: 30px auto;
		display: block;
	}

	${responsive.desktop} {
		top: 50px;
		left: 100px;
		width: 350px;
	}
`

const WelcomeContainer = styled.div`
	display: none;
	position: relative;

	${responsive.tablet} {
		display: block;
		position: absolute;

		top: 80px;
		left: 100px;

		justify-content: center;
		align-items: center;
		color: ${colors.indigoBlue};
		font-size: 24px;
		font-family: 'Ubuntu';
	}

	${responsive.desktop} {
		top: 150px;
		left: 200px;
		font-size: 40px;
	}
`

export const Welcome: FC = () => {
	const isTablet = useMediaQuery({
		query: `(min-width: ${responsive.TABLET_WIDTH + 1}px)`,
	})
	const isInPortraitMode = useMediaQuery({
		query: `(width: 1024px) and (orientation: portrait)`,
	})

	return (
		<LogoContainer>
			<BeeldayLogoBackground
				src={
					isTablet
						? isInPortraitMode
							? images.mobileBeeldayBackground
							: images.beeldayLogoCloud
						: images.mobileBeeldayBackground
				}
			/>
			<BeeldayLogo
				src={
					isTablet
						? isInPortraitMode
							? images.beeldayLogoWhite
							: images.beeldayLogo
						: images.beeldayLogoWhite
				}
				alt="Beelday"
			/>
			<WelcomeContainer>
				<intl.Translate>welcome</intl.Translate>
			</WelcomeContainer>
		</LogoContainer>
	)
}

export default Welcome
