import styled from '@emotion/styled'
import * as icons from '../icons'
import colors, { BRAND_COLORS } from '../colors'

const Icon = styled.div`
	position: absolute;
	z-index: 400;
	bottom: 0;
	right: 0;
	color: white;
	border: 2px solid ${colors.white};
	border-radius: 50%;
	width: 16px;
	height: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 3px;

	svg {
		min-width: 8px;
		color: white;
	}
`

type Status = 'ACTIVE' | 'ACCEPTED' | 'PENDING' | 'REJECTED'

type StateProps = {
	state: Status | undefined
}

export const StatusComponent = ({ state }: StateProps): JSX.Element | null => {
	switch (state) {
		case 'ACTIVE':
			return (
				<Icon style={{ backgroundColor: colors.indigoBlue }}>
					<icons.IconChecked />
				</Icon>
			)
		case 'ACCEPTED':
			return (
				<Icon style={{ backgroundColor: colors.indigoBlue }}>
					<icons.IconChecked />
				</Icon>
			)
		case 'PENDING':
			return (
				<Icon style={{ backgroundColor: BRAND_COLORS.GREY }}>
					<icons.ThreeDotsIcon />
				</Icon>
			)

		case 'REJECTED':
			return (
				<Icon style={{ backgroundColor: colors.strongPink }}>
					<icons.CancelIcon />
				</Icon>
			)
	}

	return null
}
