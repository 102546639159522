import { security } from '@beelday/common'
import { TokenState } from '@beelday/common/src/security'
import { RootState } from 'common/redux'

export type SessionState = {
	token?: security.TokenState | null | undefined
}

type SetTokenAction = {
	type: 'session/SET_TOKEN'
	token: security.TokenState | null
}

type SessionAction = SetTokenAction

export function reducer(
	state: SessionState = {},
	action: SessionAction
): SessionState {
	switch (action.type) {
		case 'session/SET_TOKEN':
			return {
				...state,
				token: action.token,
			}
		default:
			return state
	}
}

export const setToken = (token: TokenState | null): SetTokenAction => ({
	type: 'session/SET_TOKEN',
	token: token,
})

export const selectToken = (state: RootState): SessionState['token'] =>
	state.session.token
