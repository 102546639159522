import { colors, intl } from '@beelday/common'
import styled from '@emotion/styled'
import { FC } from 'react'
import { KnowledgeCheckScore } from './knowledge-check'

const Container = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
`

const Title = styled.div`
	font-size: 22px;
	line-height: 30px;
	font-weight: 500;
	text-align: center;
`
const Progress = styled.div`
	position: relative;
	width: 200px;
	height: 200px;
`

type SvgProps = {
	value: number
}

const ProgressCircle = styled.svg<SvgProps>`
	height: 200px;
	transform: rotate(-90deg);
	width: 200px;

	circle {
		fill: none;
		stroke-width: 11;
		stroke: ${colors.greenishCyan};

		&:nth-of-type(1) {
			stroke-dashoffset: 0;
			stroke: ${colors.brightGray};
		}

		&:nth-of-type(2) {
			stroke: ${colors.greenishCyan};
			stroke-dasharray: 565.48;
			stroke-dashoffset: ${props => props.value};
			stroke-linecap: round;
			transition: stroke-dashoffset 1s ease-in-out;
		}
	}
`

const ProgressText = styled.p`
	color: ${colors.black};
	font-size: 47px;
	font-weight: 700;
	left: 50%;
	margin: 0;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
`

const calculatePercentage = (currentValue: number, maxValue: number) => {
	return (100 - (100 * (currentValue / maxValue) * 100) / 100) * 5.6548
}

type Props = {
	knowledgeCheckScore: KnowledgeCheckScore
}

const KnowledgeCheckResultScore: FC<Props> = ({ knowledgeCheckScore }) => {
	return (
		<Container>
			<Title>
				<intl.Translate>knowledge_check.stats.score</intl.Translate>
			</Title>
			<Progress>
				<ProgressCircle
					value={calculatePercentage(
						knowledgeCheckScore.correctAnswers,
						knowledgeCheckScore.totalQuestions
					)}
				>
					<circle cx="100" cy="100" r="90" />
					<circle cx="100" cy="100" r="90" />
				</ProgressCircle>
				<ProgressText>
					{knowledgeCheckScore.correctAnswers}/
					{knowledgeCheckScore.totalQuestions}
				</ProgressText>
			</Progress>
		</Container>
	)
}

export default KnowledgeCheckResultScore
