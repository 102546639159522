import { Config } from 'common/config'
import { useMemo } from 'react'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { getListPollingApi } from './list-polling-api'
import {
	NewPollingList,
	PollingListPollingDefinition,
	PollingListPollingId,
} from './list-polling-models'

type ListPollingHook = {
	getPollingDefinitions: () => Promise<PollingListPollingDefinition[]>
	createPolling: (
		polling: NewPollingList
	) => Promise<PollingListPollingDefinition>
	startPolling: (pollingId: PollingListPollingId) => Promise<void>
	stopPolling: (pollingId: PollingListPollingId) => Promise<void>
}
export const useListPolling = (): ListPollingHook => {
	const listPollingApi = getListPollingApi(Config.beeldayBackendUrl)
	const roomAddress = useAssertedJoinedRoomAddress()

	return useMemo(
		() => ({
			getPollingDefinitions: (): Promise<PollingListPollingDefinition[]> => {
				return listPollingApi.getPollingDefinitions(roomAddress)
			},
			createPolling: (polling): Promise<PollingListPollingDefinition> => {
				return listPollingApi.createOpenEndedList(roomAddress, polling)
			},
			startPolling: (pollingId): Promise<void> => {
				return listPollingApi.startPolling(roomAddress, pollingId)
			},
			stopPolling: (pollingId): Promise<void> => {
				return listPollingApi.stopPolling(roomAddress, pollingId)
			},
		}),
		[roomAddress, listPollingApi]
	)
}
