import { Helmet } from 'react-helmet'
import { Config } from 'common/config'

export const LoadAppModules: React.FC = () => {
	const versionSuffix = Config.VERSION ? `-${Config.VERSION}` : ''
	return (
		<>
			<Helmet>
				<script
					src={`${Config.moduleMessagingUrl}/main${versionSuffix}.js`}
					type="module"
				/>
				<script
					src={`${Config.moduleUserProfile}/main-profile${versionSuffix}.js`}
					type="module"
				/>
				<script
					src={`${Config.moduleSessionPlanningUrl}/main${versionSuffix}.js`}
					type="module"
				/>
				<script
					src={`${Config.moduleSlidesUrl}/main${versionSuffix}.js`}
					type="module"
				/>
			</Helmet>
		</>
	)
}

export default LoadAppModules
