import { Config } from 'common/config'
import { Attachment } from 'session-info/training'
import { checkStatus, parseBody } from 'utils/http'
import { TrainingDTO } from './model'

type RawTrainingAPI = {
	uploadTrainingIcon: (file: File) => Promise<Attachment>
	trainingIconURL: (fileId?: string) => string
	fetchSession: (id: string) => Promise<TrainingDTO | null>
	fetchSessionByLiveId: (liveSessionId: string) => Promise<TrainingDTO | null>
}

const createTrainingAPI = (): RawTrainingAPI => ({
	uploadTrainingIcon: file => {
		const formData = new FormData()
		formData.append('file', file)
		return fetch(`${Config.sessionPlanningURL}/icons`, {
			method: 'POST',
			body: formData,
		})
			.then(checkStatus)
			.then(parseBody)
	},

	trainingIconURL: fileId => `${Config.sessionPlanningURL}/icons/${fileId}`,
	fetchSession: id => {
		return fetch(`${Config.sessionPlanningURL}/sessions/${id}`, {
			credentials: 'include',
		})
			.then(checkStatus)
			.then(parseBody)
	},
	fetchSessionByLiveId: liveSessionId => {
		return fetch(
			`${Config.sessionPlanningURL}/sessions/find?liveSessionId=${liveSessionId}`,
			{ credentials: 'include' }
		)
			.then(checkStatus)
			.then(parseBody)
			.then(res => res[0])
	},
})

export default createTrainingAPI
