import { UserId } from '@beelday/common'
import { RootState } from 'common/redux'
import { includes } from 'lodash'
import {
	KnowledgeCheckID,
	KnowledgeCheckReview,
	KnowledgeCheckStats,
} from './knowledge-check'
import { useSelector } from 'react-redux'

type KnowledgeCheckDefinitionsAvailabilitySSE = {
	available: boolean
}

type KnowledgeCheckStartSSE = {
	knowledgeCheckId: KnowledgeCheckID
	users: UserId[]
}

type KnowledgeCheckReviewStartedSSE = {
	knowledgeCheckId: KnowledgeCheckID
	users: UserId[]
}

type KnowledgeCheckAnsweredSSE = {
	knowledgeCheckId: KnowledgeCheckID
	userId: UserId
}

type KnowledgeCheckReviewPreviousQuestionSSE = {
	currentIndex: number
}

type KnowledgeCheckReviewNextQuestionSSE = {
	currentIndex: number
}

type KnowledgeCheckPreviousQuestionSSE = {
	knowledgeCheckId: KnowledgeCheckID
	userId: UserId
}

type KnowledgeCheckNextQuestionSSE = {
	knowledgeCheckId: KnowledgeCheckID
	userId: UserId
}

type KnowledgeCheckParticipantJoinedSSE = {
	knowledgeCheckId: KnowledgeCheckID
	userId: UserId
}

type KnowledgeCheckParticipantFinishedSSE = {
	knowledgeCheckId: KnowledgeCheckID
	userId: UserId
}

type KnowledgeCheckClosedSSE = {
	knowledgeCheckId: KnowledgeCheckID
}

type KnowledgeCheckAction =
	| {
			type: 'SSE_KNOWLEDGE_CHECK_DEFINITIONS_AVAILABILITY'
			payload: KnowledgeCheckDefinitionsAvailabilitySSE
	  }
	| {
			type: 'SSE_KNOWLEDGE_CHECK_STARTED'
			payload: KnowledgeCheckStartSSE
	  }
	| {
			type: 'SSE_KNOWLEDGE_CHECK_CLOSED'
			payload: KnowledgeCheckClosedSSE
	  }
	| {
			type: 'SSE_KNOWLEDGE_CHECK_ANSWERED'
			payload: KnowledgeCheckAnsweredSSE
	  }
	| {
			type: 'SSE_KNOWLEDGE_CHECK_PREVIOUS_QUESTION'
			payload: KnowledgeCheckPreviousQuestionSSE
	  }
	| {
			type: 'SSE_KNOWLEDGE_CHECK_NEXT_QUESTION'
			payload: KnowledgeCheckNextQuestionSSE
	  }
	| {
			type: 'SSE_PARTICIPANT_JOINED_KNOWLEDGE_CHECK'
			payload: KnowledgeCheckParticipantJoinedSSE
	  }
	| {
			type: 'SSE_PARTICIPANT_FINISHED_KNOWLEDGE_CHECK'
			payload: KnowledgeCheckParticipantFinishedSSE
	  }
	| {
			type: 'SET_STATS'
			payload: KnowledgeCheckStats
	  }
	| {
			type: 'SET_REVIEW'
			payload: KnowledgeCheckReview
	  }
	| {
			type: 'SSE_KNOWLEDGE_CHECK_REVIEW_STARTED'
			payload: KnowledgeCheckReviewStartedSSE
	  }
	| {
			type: 'SSE_KNOWLEDGE_CHECK_REVIEW_PREVIOUS_QUESTION'
			payload: KnowledgeCheckReviewPreviousQuestionSSE
	  }
	| {
			type: 'SSE_KNOWLEDGE_CHECK_REVIEW_NEXT_QUESTION'
			payload: KnowledgeCheckReviewNextQuestionSSE
	  }
	| {
			type: 'HIGHLIGHT_GROUP'
			payload: HighlightGroup
	  }
	| {
			type: 'REMOVE_HIGHLIGHT'
	  }

type HighlightGroup = keyof KnowledgeCheckStats

export type KnowledgeCheckState = {
	available: boolean
	knowledgeCheckID?: KnowledgeCheckID
	stats: KnowledgeCheckStats
	users: UserId[]
	isReview: boolean
	highlighted?: HighlightGroup
	review?: KnowledgeCheckReview
}

export const setStats = (stats: KnowledgeCheckStats): KnowledgeCheckAction => ({
	type: 'SET_STATS',
	payload: stats,
})

export const setReview = (
	review: KnowledgeCheckReview
): KnowledgeCheckAction => ({
	type: 'SET_REVIEW',
	payload: review,
})

const initialState: KnowledgeCheckState = {
	available: false,
	stats: {
		notStarted: [],
		started: [],
		finished: [],
	},
	isReview: false,
	users: [],
}

export const highlightGroup = (
	payload: HighlightGroup
): KnowledgeCheckAction => {
	return { type: 'HIGHLIGHT_GROUP', payload }
}

export const removeHighlight = (): KnowledgeCheckAction => {
	return { type: 'REMOVE_HIGHLIGHT' }
}

export function reducer(
	state: KnowledgeCheckState = initialState,
	action: KnowledgeCheckAction
): KnowledgeCheckState {
	switch (action.type) {
		case 'SSE_KNOWLEDGE_CHECK_DEFINITIONS_AVAILABILITY': {
			const { payload } = action
			const result = {
				available: payload.available,
			}
			return {
				...state,
				available: result.available,
			}
		}
		case 'SSE_KNOWLEDGE_CHECK_STARTED': {
			const { payload } = action
			const result = {
				knowledgeCheckID: payload.knowledgeCheckId,
				users: payload.users,
			}
			return {
				...state,
				knowledgeCheckID: result.knowledgeCheckID,
				isReview: false,
			}
		}
		case 'SSE_KNOWLEDGE_CHECK_REVIEW_STARTED': {
			const { payload } = action
			const result = {
				knowledgeCheckID: payload.knowledgeCheckId,
				users: payload.users,
			}
			return {
				...state,
				knowledgeCheckID: result.knowledgeCheckID,
				isReview: true,
			}
		}
		case 'SSE_KNOWLEDGE_CHECK_ANSWERED': {
			const { userId } = action.payload
			const { notStarted, started, finished } = state.stats
			if (includes(started, userId) || includes(finished, userId)) {
				return state
			} else {
				const updatedStarted = [...started, userId]
				const updatedNotStarted = notStarted.filter(u => u !== userId)

				return {
					...state,
					stats: {
						...state.stats,
						notStarted: updatedNotStarted,
						started: updatedStarted,
					},
				}
			}
		}
		case 'SSE_PARTICIPANT_JOINED_KNOWLEDGE_CHECK': {
			const { userId } = action.payload
			const { users } = state
			if (includes(users, userId)) {
				return state
			} else {
				const updatedUsers = [...users, userId]
				const updatedNotStarted = [...state.stats.notStarted, userId]
				return {
					...state,
					stats: {
						...state.stats,
						notStarted: updatedNotStarted,
					},
					users: updatedUsers,
				}
			}
		}
		case 'SSE_PARTICIPANT_FINISHED_KNOWLEDGE_CHECK': {
			const { userId } = action.payload
			const { notStarted, started, finished } = state.stats
			if (includes(finished, userId)) {
				return state
			} else {
				const updatedNotStarted = notStarted.filter(u => u !== userId)
				const updatedStarted = started.filter(u => u !== userId)
				const updatedFinished = [...finished, userId]
				return {
					...state,
					stats: {
						notStarted: updatedNotStarted,
						started: updatedStarted,
						finished: updatedFinished,
					},
				}
			}
		}
		case 'SET_STATS': {
			return {
				...state,
				stats: action.payload,
			}
		}
		case 'SET_REVIEW': {
			return {
				...state,
				review: action.payload,
			}
		}
		case 'HIGHLIGHT_GROUP': {
			return {
				...state,
				highlighted: action.payload,
			}
		}
		case 'REMOVE_HIGHLIGHT': {
			return {
				...state,
				highlighted: undefined,
			}
		}
		case 'SSE_KNOWLEDGE_CHECK_REVIEW_PREVIOUS_QUESTION': {
			const currentIndex = action.payload.currentIndex
			return {
				...state,
				...(state.review && {
					review: {
						...state.review,
						currentQuestionIndex: currentIndex,
					},
				}),
			}
		}
		case 'SSE_KNOWLEDGE_CHECK_REVIEW_NEXT_QUESTION': {
			const currentIndex = action.payload.currentIndex
			return {
				...state,
				...(state.review && {
					review: {
						...state.review,
						currentQuestionIndex: currentIndex,
					},
				}),
			}
		}
		case 'SSE_KNOWLEDGE_CHECK_CLOSED': {
			return {
				...state,
				stats: {
					notStarted: [],
					started: [],
					finished: [],
				},
				users: [],
				review: undefined,
			}
		}
		default:
			return state
	}
}

export type HighlightStatus = {
	highlightedUsers: string[]
	isHighlightActive: boolean
}

const highlightStatus = (state: RootState) => {
	const { knowledgeChecks } = state

	const highlight: HighlightStatus = {
		highlightedUsers: [],
		isHighlightActive: false,
	}

	if (knowledgeChecks.highlighted === 'finished') {
		highlight.highlightedUsers = knowledgeChecks.stats.finished
		highlight.isHighlightActive = true
	} else if (knowledgeChecks.highlighted === 'started') {
		highlight.highlightedUsers = knowledgeChecks.stats.started
		highlight.isHighlightActive = true
	} else if (knowledgeChecks.highlighted === 'notStarted') {
		highlight.highlightedUsers = knowledgeChecks.stats.notStarted
		highlight.isHighlightActive = true
	}

	return highlight
}

export const useHighlight = () => {
	return useSelector((state: RootState) => highlightStatus(state))
}

export const selectIsKnowledgeCheckAvaliable = (state: RootState): boolean =>
	state.knowledgeChecks.available

export const knowledgeCheckID = (state: RootState) =>
	state.knowledgeChecks.knowledgeCheckID

export const knowledgeCheckStats = (state: RootState) =>
	state.knowledgeChecks.stats
