import { useSelector } from 'react-redux'
import { RootState } from 'common/redux'
import { User } from 'common/types'

export const useWorkflowUser = (): User | null => {
	return useSelector((state: RootState) => state.workflow.user)
}

export const useAssertedWorkflowUser = (): User => {
	const userId = useWorkflowUser()

	if (!userId) {
		throw Error('Workflow user id is not available!')
	}

	return userId
}
