import { hooks } from '@beelday/common'
import { useCallback, useEffect, useRef } from 'react'
import { reportInterval } from './settings'
import { useInferenceSession } from './use-inference-session'

export type VoiceAnalyzerReporter = (data: Float32Array) => Promise<void>

type UseVoiceAnalyzer = (
	reportCallback?: (avg: number) => void
) => VoiceAnalyzerReporter | undefined

const avg = (collection: number[]) =>
	collection.length > 0
		? collection.reduce((p, c) => p + c, 0) / collection.length
		: 0

export const useVoiceAnalyzer: UseVoiceAnalyzer = report => {
	const isMounted = hooks.useIsMounted()
	const buffer = useRef<number[]>([])
	const { session, createDataPoint } = useInferenceSession()

	useEffect(() => {
		if (!session) {
			return undefined
		}

		const interval = setInterval(() => {
			if (report && isMounted()) {
				const voiceActivityAverage = avg(buffer.current)
				buffer.current = []
				report(voiceActivityAverage)
			}
		}, reportInterval)

		return () => clearInterval(interval)
	}, [session, report])

	const reportAudioInput = useCallback(
		async data => {
			const dataPoint = await createDataPoint(data)

			if (dataPoint && isMounted()) {
				buffer.current.push(dataPoint)
			}
		},
		[createDataPoint]
	)

	return session !== null ? reportAudioInput : undefined
}
