import { ErrorInline } from '@beelday/common/src/ui'
import { useSelector } from 'app-redux'
import { FC, PropsWithChildren } from 'react'
import { selectTrainerStream } from 'room/common/redux'
//TODO: get rid of this import, layouts like this should be a common component in room/common
import ThreeColumnsVideoGrid from 'room/training-room/ice-breaker/presentation/three-columns-video-grid'
import { endUserStreams } from 'video-conference-media/redux'

export const ErrorWithStreams: FC<
	PropsWithChildren<{ retry?: () => unknown; showTrainer?: boolean }>
> = ({ children, retry, showTrainer }) => {
	let streams = useSelector(endUserStreams)
	const trainerStream = useSelector(selectTrainerStream)

	if (showTrainer && trainerStream) {
		streams = [trainerStream, ...streams]
	}

	return (
		<ThreeColumnsVideoGrid sceneVideoUsers={streams}>
			<ErrorInline retry={retry}>{children}</ErrorInline>
		</ThreeColumnsVideoGrid>
	)
}
