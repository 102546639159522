import { intl } from '@beelday/common'
import { Config } from 'common/config'
import { selectCurrentUserVideoMuted } from 'interaction-scheme/redux'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
	MenuToggleItem,
	PopupMenu,
	PopupMenuElement,
	PopupNestedMenu,
} from 'room/common/presentation/menu'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { useAssertedWorkflowUser } from 'room/common/use-workflow-user'
import { useMediaDevices } from 'utils/hooks/use-media-devices'
import useCamApi from './camera-api'
import { CameraOff } from './camera-off'
import { CameraOn } from './camera-on'
import { selectEnabledFeatures } from 'room/common/redux'

const CamMenuItem: React.FC = () => {
	const { availableCameras, selectedCamera, selectCamera } = useMediaDevices()

	const api = useCamApi(Config.beeldayBackendUrl)
	const address = useAssertedJoinedRoomAddress()
	const user = useAssertedWorkflowUser()
	const enabledFeatures = useSelector(selectEnabledFeatures)

	const isFeatureEnabled = enabledFeatures.includes('CameraType')
	const isMuteCam = useSelector(selectCurrentUserVideoMuted)

	const toggleCam = useCallback(() => {
		if (!isMuteCam) {
			api.muteCam(address, user.id)
		} else {
			api.unmuteCam(address, user.id)
		}
	}, [isMuteCam, api, address, user])

	const currentCam = availableCameras.find(
		cam => cam.deviceId === selectedCamera
	)

	if (!isFeatureEnabled) return null
	return (
		<MenuToggleItem
			active={isMuteCam}
			activeIcon={<CameraOff />}
			icon={<CameraOn />}
			onClick={toggleCam}
			label={<intl.Translate>room_menu.camera</intl.Translate>}
			disabled={availableCameras.length <= 0}
		>
			{currentCam ? (
				<PopupMenu>
					<PopupMenuElement text={currentCam.label}>
						<PopupNestedMenu>
							<>
								{availableCameras.map(cam => (
									<PopupMenuElement
										key={cam.deviceId}
										text={cam.label}
										active={selectedCamera === cam.deviceId}
										onClick={() => selectCamera(cam.deviceId)}
									/>
								))}
							</>
						</PopupNestedMenu>
					</PopupMenuElement>
				</PopupMenu>
			) : null}
		</MenuToggleItem>
	)
}

export default CamMenuItem
