const ScreenShareIcon = (): JSX.Element => (
	<svg
		data-name="Group 25550"
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="21.6"
		viewBox="0 0 24 21.6"
	>
		<path
			data-name="Path 11789"
			d="M2.182 0h19.636A2.3 2.3 0 0 1 24 2.4l-.011 14.4a2.3 2.3 0 0 1-2.171 2.4h-5.454v2.4H7.636v-2.4H2.182A2.3 2.3 0 0 1 0 16.8V2.4A2.291 2.291 0 0 1 2.182 0M13.2 11.136v2.628L18 9.276 13.2 4.8v2.556C8.532 8 6.672 11.2 6 14.4c1.668-2.244 3.863-3.264 7.2-3.264"
		/>
	</svg>
)

export default ScreenShareIcon
