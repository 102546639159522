export const Excalidraw: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		width="24px"
		height="24px"
		viewBox="0 0 24 24"
		enableBackground={'new 0 0 24 24'}
		xmlSpace="preserve"
	>
		<g>
			<path d="M16.6,3.8C17.1,3,16.8,1.9,16,1.3l-1.8-1.1c-0.8-0.4-1.9-0.2-2.4,0.6l0,0L5.3,11.3l4.8,3L16.6,3.8z" />
			<path
				d="M4.3,19.5l2.9-1.8c0.6-0.4,1.1-0.9,1.5-1.4l-4.7-3c-0.3,0.6-0.5,1.3-0.6,2l-0.3,3.4c0,0.4,0.3,0.8,0.7,0.9
		C4,19.6,4.2,19.6,4.3,19.5z"
			/>
			<path
				d="M20.9,22.1c-2.5-3-5.2-1.6-6.7-0.8c-0.3,0.2-0.6,0.3-1,0.4c-0.5,0-0.6-0.1-0.9-0.7c-0.5-1-1.6-1.6-2.8-1.6
		c-1.8,0-5.3,1.8-6.1,2.2c-0.6,0.3-0.8,1-0.5,1.5c0.3,0.6,1,0.8,1.5,0.5c1.5-0.8,4.1-2,5-2c0.4,0.1,0.5,0.1,0.9,0.7
		c0.6,1,1.6,1.6,2.8,1.6c0.7-0.1,1.4-0.3,2-0.7c1.6-0.8,2.7-1.2,3.9,0.3c0.2,0.3,0.5,0.4,0.9,0.4c0.3,0,0.5-0.1,0.7-0.3
		C21.2,23.3,21.3,22.6,20.9,22.1z"
			/>
		</g>
	</svg>
)
