import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useAuth } from '../security'
import colors from '../colors'
import { Translate } from '../intl'
import { Button, ButtonMinor, ButtonPrimary } from './button'
import { Modal } from './modal'
import { ui } from '..'

import CloseButton from './close'

const Container = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`

const Message = styled.div`
	color: ${colors.white};
	font-family: Ubuntu;
	font-size: 35px;
	font-weight: 500;
	text-align: center;
`

const Buttons = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 40px;
`

const spacing = css`
	margin-left: 10px;
	margin-right: 10px;
	min-width: 200px;
`

type Props = {
	onDismiss: () => void
}
export const LogoutSplashScreen = ({ onDismiss }: Props): JSX.Element => {
	const auth = useAuth()

	return (
		<Modal backgroundColor={colors.transparentViolet}>
			<Container>
				<Message>
					<Translate>logout.message</Translate>
				</Message>
				<Buttons>
					<Button
						css={spacing}
						color="indigoBlue"
						backgroundColor="lightGray"
						onClick={onDismiss}
					>
						<Translate>logout.cancel</Translate>
					</Button>
					<Button css={spacing} color="white" onClick={auth.signOut}>
						<Translate>logout.confirm</Translate>
					</Button>
				</Buttons>
			</Container>
		</Modal>
	)
}
const DialogBody = styled.div`
	background-color: ${colors.white};
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	height: fit-content;
	opacity: 1;
	position: relative;

	width: 100%;
`

const CloseBtnContainer = styled.div`
	position: absolute;
	right: 0px;
	top: 0px;
`

const Title = styled.div`
	align-items: center;
	display: flex;
	font-size: 16px;
	font-weight: 500;
	justify-content: center;
	padding-top: 6px;
	margin-bottom: 24px;
	width: 75%;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
`

const Desc = styled.div`
	display: flex;
	font-family: Ubuntu;
	font-size: 20px;
	font-weight: normal;
	height: fit-content;
	margin-bottom: 33px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	width: 80%;
`

const ButtonsColumn = styled.div`
	align-items: center;
	display: flex;
	justify-content: flex-end;
	position: relative;
	width: 100%;
	flex-direction: column;
	row-gap: 12px;

	button {
		width: 100%;
	}
`

type LogoutProps = {
	isOpen: boolean
	onClose: () => void
	onProceed: () => void
}
export const LogoutScreenMobile = ({
	isOpen,
	onClose,
	onProceed,
}: LogoutProps): JSX.Element => {
	return (
		<ui.BottomPanel isOpen={isOpen} onClose={onClose} withCurtain={true}>
			<DialogBody>
				<CloseBtnContainer>
					<CloseButton onClick={onClose} />
				</CloseBtnContainer>
				<Title>
					<Translate>menu.logout</Translate>
				</Title>

				<Desc>
					<Translate>logout.message</Translate>
				</Desc>

				<ButtonsColumn>
					<ButtonPrimary onClick={onProceed}>
						{<Translate>logout.confirm</Translate>}
					</ButtonPrimary>
					<ButtonMinor onClick={onClose}>
						{<Translate>logout.cancel</Translate>}
					</ButtonMinor>
				</ButtonsColumn>
			</DialogBody>
		</ui.BottomPanel>
	)
}
