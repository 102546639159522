import {
	InteractionSchemeRoomTransferWithCountdownEvent,
	Interest,
	RoomId,
	User,
	UserId,
} from 'common/types'
import { keyBy, map, reduce } from 'lodash'
import { CurrentPublicChatRoom } from 'connectivity/beelday-client'
import {
	PublicChatRoomEncouragementCode,
	PublicChatRoomStatus,
	PublicChatRoomType,
} from './model'
import { createSelector } from 'reselect'
import { RootState } from 'common/redux'
import { isUsersTransfer } from 'interaction-scheme/model/interaction-scheme'
import { selectRoomRosters } from 'room/roster/redux'

export type PublicChatRoom = {
	id: RoomId
	status?: PublicChatRoomStatus
	hobby?: string
	type?: PublicChatRoomType
	users?: User[]
}

export type LobbyState = {
	encouragements: Record<
		UserId,
		{
			roomId: RoomId
			code: PublicChatRoomEncouragementCode
			user?: UserId
			interest?: Interest
		}
	>
	publicChatRooms: Record<RoomId, PublicChatRoom>
	editingProfile: boolean
	visible: RoomId[]
}

const initialState: LobbyState = {
	encouragements: {},
	publicChatRooms: {},
	editingProfile: false,
	visible: [],
}

type StatusSSE = {
	id: RoomId
	status: PublicChatRoomStatus
}

type EncouragementSSE = {
	id: RoomId
	encouragements: {
		user: UserId
		messageCode: PublicChatRoomEncouragementCode
		params?: { user: UserId; interest?: Interest }
	}[]
}

type DiscouragedSSE = {
	users: UserId[]
}

type LobbyAction =
	| {
			type: 'PUBLIC_CHAT_ROOM_CHANGED'
			id: RoomId
			users: User[]
	  }
	| {
			type: 'CURRENT_PUBLIC_CHAT_ROOMS_CHANGED'
			rooms: CurrentPublicChatRoom[]
	  }
	| {
			type: 'SSE_PUBLIC_CHAT_ROOM_STATUS_CHANGED'
			payload: StatusSSE
	  }
	| {
			type: 'SSE_PUBLIC_CHAT_ROOM_ENCOURAGEMENT_CHANGED'
			payload: EncouragementSSE
	  }
	| {
			type: 'SSE_USERS_DISCOURAGED_TO_VISIT_ANY_PUBLIC_CHAT_ROOM'
			payload: DiscouragedSSE
	  }
	| { type: 'LOBBY_PROFILE_EDIT' }
	| { type: 'LOBBY_PROFILE_EDIT_CANCEL' }
	| {
			type: 'SET_IS_ROOM_TRANSFER_WITH_COUNTDOWN'
			event: InteractionSchemeRoomTransferWithCountdownEvent
			userId: UserId
	  }

export const handlePublicChatRoomChanged = (
	id: RoomId,
	users: User[]
): LobbyAction => ({
	type: 'PUBLIC_CHAT_ROOM_CHANGED',
	id,
	users,
})

export const setCurrentPublicChatRooms = (rooms: CurrentPublicChatRoom[]) => ({
	type: 'CURRENT_PUBLIC_CHAT_ROOMS_CHANGED',
	rooms,
})

export const editProfile = () => ({
	type: 'LOBBY_PROFILE_EDIT',
})

export const cancelEditProfile = () => ({
	type: 'LOBBY_PROFILE_EDIT_CANCEL',
})

export function reducer(
	state: LobbyState = initialState,
	action: LobbyAction
): LobbyState {
	switch (action.type) {
		case 'CURRENT_PUBLIC_CHAT_ROOMS_CHANGED':
			const visible = map(action.rooms, 'id')
			const combined = action.rooms.map(r => ({
				...state.publicChatRooms[r.id],
				...r,
			}))

			return {
				...state,
				visible,
				publicChatRooms: {
					...state.publicChatRooms,
					...keyBy(combined, 'id'),
				},
			}
		case 'PUBLIC_CHAT_ROOM_CHANGED':
			return {
				...state,
				publicChatRooms: {
					...state.publicChatRooms,
					[action.id]: {
						...state.publicChatRooms[action.id],
						id: action.id,
						users: action.users,
					},
				},
			}
		case 'SSE_PUBLIC_CHAT_ROOM_STATUS_CHANGED':
			const { id, status } = action.payload
			return {
				...state,
				publicChatRooms: {
					...state.publicChatRooms,
					[id]: {
						...state.publicChatRooms[id],
						id,
						status,
					},
				},
			}
		case 'SSE_PUBLIC_CHAT_ROOM_ENCOURAGEMENT_CHANGED':
			const { payload } = action
			const updated = reduce(
				payload.encouragements,
				(acc, e) => {
					acc[e.user] = {
						roomId: payload.id,
						code: e.messageCode,
						user: e.params?.user,
						interest: e.params?.interest,
					}
					return acc
				},
				{ ...state.encouragements }
			)

			return {
				...state,
				encouragements: updated,
			}

		case 'SSE_USERS_DISCOURAGED_TO_VISIT_ANY_PUBLIC_CHAT_ROOM':
			const { users } = action.payload
			const without = reduce(
				users,
				(acc, u) => {
					delete acc[u]
					return acc
				},
				{ ...state.encouragements }
			)

			return { ...state, encouragements: without }
		case 'LOBBY_PROFILE_EDIT':
			return { ...state, editingProfile: true }
		case 'LOBBY_PROFILE_EDIT_CANCEL':
			return { ...state, editingProfile: false }
		case 'SET_IS_ROOM_TRANSFER_WITH_COUNTDOWN':
			return isUsersTransfer(action.event, action.userId)
				? { ...state, editingProfile: false }
				: state
		default:
			return state
	}
}

const encouragementForMe = createSelector(
	(state: RootState) => state.lobby.encouragements,
	(state: RootState) => state.workflow.user,
	(encouragements, me) => {
		const maybeID = me?.id
		return maybeID ? encouragements[maybeID] : undefined
	}
)

export const visiblePublicChatRooms = createSelector(
	(state: RootState) => state.lobby.visible,
	(state: RootState) => state.lobby.publicChatRooms,
	(state: RootState) => state.users.all,
	selectRoomRosters,
	encouragementForMe,
	(visible, rooms, userProfiles, rosters, maybeEncouragement) =>
		visible.map(v => {
			const room = rooms[v]
			const users = rosters[v]?.users || []
			const possible =
				maybeEncouragement?.roomId === room.id
					? {
							code: maybeEncouragement.code,
							user: maybeEncouragement.user
								? userProfiles[maybeEncouragement.user]
								: undefined,
							interest: maybeEncouragement.interest,
					  }
					: undefined
			return {
				...room,
				encouragement: possible,
				users: map(users, u => ({ ...u, ...userProfiles[u.id] })),
			}
		})
)

export const isEditingProfile = (state: RootState) =>
	!!state.lobby.editingProfile
