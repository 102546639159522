const LoadingScreenShareIcon = (): JSX.Element => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24.005"
		height="21.6"
		viewBox="0 0 24.005 21.6"
	>
		<g transform="translate(0)">
			<path
				d="M2.185,0H21.821A2.3,2.3,0,0,1,24,2.4l-.011,14.4a2.3,2.3,0,0,1-2.171,2.4H16.367v2.4H7.639V19.2H2.185A2.3,2.3,0,0,1,0,16.8V2.4A2.291,2.291,0,0,1,2.18,0h0"
				fill="#fff"
				fillRule="evenodd"
			/>
			<g transform="translate(-781.995 -688.2)">
				<circle
					cx="2"
					cy="2"
					r="2"
					transform="translate(786 696)"
					fill="#4f17be"
				/>
				<circle
					cx="2"
					cy="2"
					r="2"
					transform="translate(792 696)"
					fill="#4f17be"
					opacity="0.7"
				/>
				<circle
					cx="2"
					cy="2"
					r="2"
					transform="translate(798 696)"
					fill="#4f17be"
					opacity="0.43"
				/>
			</g>
		</g>
	</svg>
)

export default LoadingScreenShareIcon
