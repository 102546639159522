import { logger } from '@beelday/common'
import { useEffect, useRef } from 'react'
import { useVideoComponents } from 'video-conference-media'

const log = logger.create('VideoStream')

type Props = {
	userId: string
	className?: string
}
export const VideoStream = ({ userId, className }: Props): JSX.Element => {
	const containerRef = useRef<HTMLDivElement>(null)
	const videos = useVideoComponents()

	useEffect(() => {
		const handleStreamsChanged = () => {
			const current = containerRef.current
			const comp = videos.attach(userId)
			if (current) {
				const currentVideo = current.children[0] as HTMLVideoElement
				if (
					comp &&
					comp.dataset['streamId'] !== currentVideo?.dataset['streamId']
				) {
					current.replaceChildren(comp)

					if (comp.paused) {
						comp
							.play()
							.then(() => {
								log.info('VIDEO-STREAM: Video play success for: ', userId)
							})
							.catch(e => {
								log.error(
									`VIDEO-STREAM: Failed to play paused for ${userId}, reseting stream`,
									e
								)
								videos.resetStream(userId)
							})
					}
				}
			}
		}
		handleStreamsChanged()
		return videos.onStreamsChanged(handleStreamsChanged)
	}, [userId, videos])

	useEffect(() => {
		return () => {
			log.info('VIDEO-STREAM: Detaching video for: ', userId)
			videos.detach(userId)
		}
	}, [userId, videos])

	return <div ref={containerRef} className={className} />
}
