import { FC } from 'react'
import styled from '@emotion/styled'
import noWinnersBg from './images/no-winners-bg.svg'
import noWinnersThumb from './images/no-winners-thumb.svg'
import { Colors } from 'common/presentation/colors'
import Translate from 'intl/translate'
import { ui } from '@beelday/common'

const Container = styled.div`
	background: url(${noWinnersBg}) no-repeat;
	background-size: cover;
	border-radius: 20px 20px 0 0;
	display: flex;
	align-items: center;
	padding: 25px 20px 20px 35px;
	height: 120px;

	${ui.responsive.desktop1440} {
		padding: 40px 55px 20px 55px;
		height: 200px;
	}
`

const Thumb = styled.img`
	margin-right: 40px;
	height: 110px;
	width: 110px;

	${ui.responsive.desktop1440} {
		margin-right: 40px;
		height: 150px;
		width: 150px;
	}
`

const Title = styled.div`
	color: ${Colors.white};
	font-size: 24px;
	font-family: Ubuntu;
	font-weight: 500;
	margin-bottom: 5px;

	${ui.responsive.desktop1440} {
		font-size: 30px;
		margin-bottom: 15px;
	}
`

const Subtitle = styled.div`
	color: ${Colors.white};
	font-size: 16px;
	font-family: Ubuntu;
`

const NoWinnersBanner: FC = () => (
	<Container>
		<Thumb src={noWinnersThumb} alt="No winners" />
		<div>
			<Title>
				<Translate>quiz.heads_up</Translate>
			</Title>
			<Subtitle>
				<Translate>quiz.heads_up.desc</Translate>
			</Subtitle>
		</div>
	</Container>
)

export default NoWinnersBanner
