import styled from '@emotion/styled'
import { default as colors } from '../colors'
import { BeeldayLogoSmall } from './beelday-logo'
import ErrorImage from '../images/network_problem.svg'
import messages from '../intl/messages'
import { IntlProvider, Translate } from '../intl'
import { ButtonPrimary } from './button'

const PageWrapper = styled.div`
	flex: 1;
	background-color: ${colors.paleGray2};
	display: flex;
	min-height: 100vh;
`

const Content = styled.div`
	margin: auto;
	text-align: center;
	padding: 70px 20px;
`

const Title = styled.div`
	margin-bottom: 8px;
	font-size: 28px;
	line-height: 38px;
	font-weight: 700;
`

const Desc = styled.div`
	margin-bottom: 40px;
	font-size: 16px;
	line-height: 28px;
`

const Image = styled.img`
	margin-bottom: 30px;
	width: 140px;
	max-width: 100%;
`

export const Error: React.FC<{ message?: string }> = () => {
	return (
		<IntlProvider messages={messages}>
			<PageWrapper>
				<BeeldayLogoSmall />
				<Content>
					<Image src={ErrorImage} alt="error image" />
					<Title>
						<Translate>error_page.fatal_error</Translate>
					</Title>
					<Desc>
						<Translate>error_page.fatal_desc</Translate>
					</Desc>
					<a href="/">
						<ButtonPrimary color="greenishCyan">
							<Translate>error_page.button</Translate>
						</ButtonPrimary>
					</a>
				</Content>
			</PageWrapper>
		</IntlProvider>
	)
}

export const ErrorInline: React.FC<{
	children?: React.ReactNode
	retry?: () => unknown
}> = ({ children, retry }) => {
	return (
		<Content>
			<Image src={ErrorImage} alt="error image" />
			<Title>
				<Translate>error_page.fatal_error</Translate>
			</Title>
			<Desc>{children}</Desc>
			{retry ? (
				<ButtonPrimary color="greenishCyan" onClick={retry}>
					<Translate>error_page.button_retry</Translate>
				</ButtonPrimary>
			) : null}
		</Content>
	)
}

export default Error
