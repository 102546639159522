import { logger } from '@beelday/common'
import { Config } from 'common/config'
import { findLast, last, map } from 'lodash'
import { checkStatus } from 'utils/http'

const log = logger.create('juno-client')

type JanusDescriptor1_0 = string
type JanusDescriptor2_0 = {
	id: string
	urls: string[]
}

type PollResponse = {
	active: boolean
	publish: JanusDescriptor1_0[]
	subscribe: JanusDescriptor1_0[]
}

type PollResponse2_0 = {
	active: boolean
	publish: JanusDescriptor2_0[]
	subscribe: JanusDescriptor2_0[]
}

type PublishingResponse = {
	juno: 'publishing'
}

type JoinResponse = {
	juno: 'joined'
	room: string
}

const isHttpUrl = (url: string): boolean => url.startsWith('http')
const isWsUrl = (url: string): boolean => url.startsWith('ws')

const useWs = Config.janusTransport === 'ws'
if (useWs) {
	log.warn('Janus using WS transport')
} else {
	log.warn('Janus using HTTP transport')
}

const toDescriptor1_0 = (newFormat: JanusDescriptor2_0): JanusDescriptor1_0 => {
	//http url should always be last
	const filter = useWs ? isWsUrl : isHttpUrl
	return findLast(newFormat.urls, filter) || last(newFormat.urls) || ''
}

export default class JunoClient {
	constructor(
		private accessToken: string,
		private username: string,
		private roomId: string
	) {}

	public setToken = (accessToken: string): void => {
		this.accessToken = accessToken
	}

	private sendMessage = <T>(method: string, data: object = {}): Promise<T> => {
		const content: RequestInit = {
			method,
			mode: 'cors',
			cache: 'no-store',
			redirect: 'follow',
			referrerPolicy: 'no-referrer',
			headers: {
				Authorization: `Bearer ${this.accessToken}`,
			},
		}

		if (method !== 'GET') {
			content.body = JSON.stringify(data)
		}

		return fetch(`${Config.junoUrl}/${this.roomId}`, content)
			.then(checkStatus)
			.then(response => response.json()) // can't use parseBody() here since Juno headers are incorrect atm
	}

	poll = (): Promise<PollResponse> =>
		this.sendMessage<PollResponse2_0>('GET').then(res => ({
			...res,
			publish: map(res.publish, toDescriptor1_0),
			subscribe: map(res.subscribe, toDescriptor1_0),
		}))
	publishing = (): Promise<PublishingResponse> =>
		this.sendMessage('POST', {
			request: 'publishing',
		})
	join = (): Promise<JoinResponse> =>
		this.sendMessage('POST', {
			request: 'join',
			display: this.username,
		})
}
