import { localTime } from '@beelday/common'
import { createAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'common/redux'
import { QuizId } from 'common/types'
import { parseISO } from 'date-fns'
import { first, last, map } from 'lodash'
import { Activity, ScriptItem, SessionChunk } from './script-model'
import { createSelector } from 'reselect'

type QuizActivityDto = {
	type: 'QUIZ'
	state: {
		onBeginData?: {
			quizId: QuizId
		}
	}
	needsConfirmation: boolean
}

type BreakActivityDto = { type: 'BREAK'; needsConfirmation: boolean }

type ActivityDto = QuizActivityDto | BreakActivityDto

type ScriptItemDto = {
	title: string
	description: string
	startTime: string
	activities: ActivityDto[]
}

type SessionChunkDto = {
	startDate: string
	startTime: string
	startTitle?: string
	startDescription?: string
	endTime: string
	endTitle?: string
	endDescription?: string
	items: ScriptItemDto[]
}

type ScriptStateChangedSSE = {
	placeId: string
	currentItemIndex: number
	currentActivityIndex: number
	currentSessionChunkIndex: number
	sessionChunks: SessionChunkDto[]
}

type ScriptState = {
	currentItemIndex: number
	currentActivityIndex: number
	currentSessionChunkIndex: number
	chunks: SessionChunk[]
}

const initialState: ScriptState = {
	currentItemIndex: 0,
	currentActivityIndex: 0,
	currentSessionChunkIndex: 0,
	chunks: [],
}

const scriptChanged = createAction<ScriptStateChangedSSE>(
	'SSE_SCRIPT_STATE_CHANGED'
)

const dtoToChunk = (dto: SessionChunkDto): SessionChunk => {
	return {
		...dto,
		startDate: parseISO(dto.startDate),
		startTime: localTime.parse(dto.startTime),
		//FIXME: this should be passed from backend
		endTime: localTime.parse(dto.endTime || '23:59'),
		items: map(dto.items, dtoToItem),
	}
}

const dtoToItem = (dto: ScriptItemDto): ScriptItem => {
	const activities = map(dto.activities, a => {
		if (a.type === 'QUIZ') {
			return {
				type: a.type,
				quizId: a.state.onBeginData?.quizId,
				needsConfirmation: a?.needsConfirmation,
			}
		}
		return a
	})

	return {
		...dto,
		activities,
		startTime: parseISO(dto.startTime),
	}
}

export const scriptSlice = createSlice({
	name: 'script',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(scriptChanged, (state, { payload }) => {
			state.currentItemIndex = payload.currentItemIndex
			state.currentActivityIndex = payload.currentActivityIndex
			state.currentSessionChunkIndex = payload.currentSessionChunkIndex
			state.chunks = map(payload.sessionChunks, dtoToChunk)
		})
	},
})

type ScriptStep = {
	chunk: SessionChunk
	item: ScriptItem
	activity?: Activity
}

const selectScript = (state: RootState): ScriptState => state.script

const selectCurrentScriptStep = (state: RootState): ScriptStep | undefined => {
	const {
		currentItemIndex,
		currentActivityIndex,
		currentSessionChunkIndex,
		chunks,
	} = state.script

	const chunk = chunks[currentSessionChunkIndex]
	const item = chunk?.items[currentItemIndex]
	if (item) {
		const activity = item.activities[currentActivityIndex]

		return {
			chunk,
			item,
			activity,
		}
	}
}

const selectScriptStep =
	(direction: -1 | 1) =>
	({
		currentItemIndex,
		currentActivityIndex,
		currentSessionChunkIndex,
		chunks,
	}: ScriptState): ScriptStep | undefined => {
		const rolloverSelector = direction > 0 ? first : last

		let chunk = chunks[currentSessionChunkIndex]
		let item: ScriptItem | undefined = chunk?.items[currentItemIndex]
		let activity: Activity | undefined =
			item?.activities[currentActivityIndex + direction]

		if (!activity) {
			item = chunk?.items[currentItemIndex + direction]
			activity = rolloverSelector(item?.activities)
		}

		if (!item) {
			chunk = chunks?.[currentSessionChunkIndex + direction]
			item = rolloverSelector(chunk?.items)
			activity = rolloverSelector(item?.activities)
		}

		if (item) {
			return {
				chunk,
				item,
				activity,
			}
		} else {
			return undefined
		}
	}

const selectNextScriptStep = createSelector(
	(state: RootState) => state.script,
	selectScriptStep(1)
)
const selectPrevScriptStep = createSelector(
	(state: RootState) => state.script,
	selectScriptStep(-1)
)

//Selectors
export {
	selectScript,
	selectCurrentScriptStep,
	selectPrevScriptStep,
	selectNextScriptStep,
}

//Reducer
export const reducer = scriptSlice.reducer
