import styled from '@emotion/styled'
import styles from '../styles'

export const FlexRow = styled.div`
	display: flex;
	flex-direction: row;
`
export const FlexColumn = styled.div`
	display: flex;
	flex-direction: column;
`
export const SecretScrollbarY = styled.div`
	overflow-y: scroll;
	overflow-x: hidden;
	scrollbar-gutter: stable;
	& > div {
		margin-right: -${styles.getScrollbarWidth()}px;
	}
`

export const FlexColumnMiddle = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`

export const FlexColumnCenter = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const FlexColumnEnd = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`

export const Relative = styled.div`
	position: relative;
`

export const FlexEnd = styled.div`
	display: flex;
	justify-content: flex-end;
`
export const FillSpace = styled.div`
	flex: 1;
`
export const FlexCenterAll = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`

export const CenterFullScreen = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100vw;
	height: 100vh;
`
export const FillScreen = styled.div`
	min-width: 100vw;
	min-height: 100vh;
`
