import { FC } from 'react'
import styled from '@emotion/styled'
import cancel from 'common/presentation/images/cancel.svg'
import { usePreloaded } from 'connectivity/preload'

const Button = styled.button`
	width: 100%;
	height: 100%;
	background: url(${cancel});
	background-size: 100% 100%;
	background-repeat: no-repeat;
	padding: 0px;
	border: none;
	cursor: pointer;
	min-width: 30px;
`

type Props = {
	onClick: () => void
}

const CloseButton: FC<Props> = ({ onClick }) => {
	const preloaded = usePreloaded([cancel])

	return preloaded ? <Button onClick={onClick} /> : null
}

export default CloseButton
