import styled from '@emotion/styled'

export const Fill = styled.div`
	flex-grow: 1;
`

export const Flex = styled.div`
	display: flex;
`

export const FlexAlignStart = styled.div`
	display: flex;
	align-items: flex-start;
`

export const VerticalFlex = styled.div`
	display: flex;
	align-items: center;
`
export const HorizontalFlex = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
`
export const FlexCenter = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`

export const FlexMiddle = styled.div`
	display: flex;
	align-items: center;
`

export const FlexSpaceBetween = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

export const AbsoluteCenter = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`
