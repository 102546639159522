import { FunctionComponent, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { UserRole } from 'common/types'
import { useAssertedJoinedRoomAddress } from '../common/use-joined-room-address'
import { ConfiguredGroupRoomTrainer } from './configured-group-room-trainer'
import { ConfiguredGroupRoomTrainee } from './configured-group-room-trainee'
import { assertUnreachable } from 'utils/unreachable'
import {
	clearVcrRoomAddress,
	setVcrRoomAddress,
} from 'video-conference-media/redux'
import { useAssertedWorkflowUser } from 'room/common/use-workflow-user'
import { clearGroupRoom } from 'room/group-room/redux'

export const GroupRoom: FunctionComponent = () => {
	const dispatch = useDispatch()
	const joinedRoomAddress = useAssertedJoinedRoomAddress()
	const workflowUser = useAssertedWorkflowUser()

	useEffect(() => {
		if (!joinedRoomAddress) {
			return
		}
		dispatch(setVcrRoomAddress(joinedRoomAddress))
		return () => {
			dispatch(clearGroupRoom())
			dispatch(clearVcrRoomAddress())
		}
	}, [dispatch, joinedRoomAddress])

	switch (workflowUser.role) {
		case UserRole.END_USER:
			return <ConfiguredGroupRoomTrainee />
		case UserRole.UPPER_ECHELON:
			return <ConfiguredGroupRoomTrainer />
		default:
			assertUnreachable('UserRole', workflowUser.role)
	}
}
