import styled from '@emotion/styled'
import colors from '../colors'
import { useStableId } from '../hooks'
import { desktop1440 } from './responsive'
export const CheckboxList = styled.div``

const Checkbox = styled.div`
	margin-bottom: 8px;

	&:last-child {
		margin-bottom: 0;
	}
`

const Label = styled.label`
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	border: 1px solid ${colors.brightGray};
	background-color: ${colors.white};
	padding: 10px;
	border-radius: 8px;
	cursor: pointer;
	transition: border 0.25s, background-color 0.25s;

	${desktop1440} {
		padding: 16px;
	}

	&.checked {
		border: 1px solid ${colors.indigoBlue};
		background-color: ${colors.indigoBlue05};
		box-shadow: inset 0 0 0 1px ${colors.indigoBlue};
	}

	&:hover {
		border: 1px solid ${colors.indigoBlue};
	}
`

const LabelImage = styled.img`
	margin-right: 16px;
	max-width: 40px;
	max-height: 40px;
	width: 100%;
	height: 100%;
`

const LabelContent = styled.div``

const LabelTitle = styled.div`
	font-size: 14px;
	line-height: 17px;
	font-weight: 500;
`

const LabelCount = styled.div`
	font-size: 12px;
	line-height: 16px;
	color: ${colors.black70};
	margin-top: 4px;
`

const CheckboxInput = styled.input`
	display: none;
`

type Option = {
	type: 'radio' | 'checkbox'
	value: string
	name: string
	description?: string | React.ReactNode
	imageSrc: string
	checked?: boolean
	onChange: (checked: boolean) => void
}

export const CheckboxListItem = ({
	type,
	value,
	name,
	description,
	imageSrc,
	onChange,
	checked,
}: Option): JSX.Element => {
	const id = useStableId()
	return (
		<Checkbox key={value}>
			<CheckboxInput
				id={id}
				value={value}
				type={type}
				checked={checked}
				onChange={e => onChange(e.currentTarget.checked)}
			/>
			<Label htmlFor={id} className={checked ? 'checked' : ''}>
				<LabelImage src={imageSrc} />
				<LabelContent>
					<LabelTitle>{name}</LabelTitle>
					<LabelCount>{description}</LabelCount>
				</LabelContent>
			</Label>
		</Checkbox>
	)
}

const AvatarPlace = styled.div`
	flex: 0 0 26px;
	width: 26px;
	height: 26px;
	border-radius: 50%;
	border: 1px solid ${colors.borderLightGray};
	margin-right: 15px;

	${desktop1440} {
		flex: 0 0 32px;
		width: 32px;
		height: 32px;
	}
`

type AvatarOption = {
	type: 'radio' | 'checkbox'
	value: string
	name: string
	avatar?: JSX.Element
	checked?: boolean
	onChange: (checked: boolean) => void
}

export const CheckboxListAvatarItem = ({
	type,
	value,
	name,
	avatar,
	onChange,
	checked,
}: AvatarOption): JSX.Element => {
	const id = useStableId()
	return (
		<Checkbox key={value}>
			<CheckboxInput
				id={id}
				value={value}
				type={type}
				checked={checked}
				onChange={e => onChange(e.currentTarget.checked)}
			/>
			<Label htmlFor={id} className={checked ? 'checked' : ''}>
				<AvatarPlace>{avatar}</AvatarPlace>
				<LabelContent>
					<LabelTitle>{name}</LabelTitle>
				</LabelContent>
			</Label>
		</Checkbox>
	)
}
