import { format } from 'date-fns'
import { isEmpty, isString } from 'lodash'
import { UserRegisterInfo } from '.'
import { memoizeSingle } from '../func'
import * as http from '../http'
import { FileContent } from '../models'
import { Avatar, UserId, UserProfile } from './user-models'

export type UserApi = {
	getAvatarUrl: (avatar?: Avatar | string | null) => string
	getUserProfiles: (users: UserId[]) => Promise<UserProfile[]>
	getUserProfilesByEmail: (users: string[]) => Promise<UserProfile[]>
	register: (userData: UserRegisterInfo) => Promise<UserProfile>
	confirmEmail: (token: string) => Promise<void>
	updateMyProfile: (updates: Partial<UserProfile>) => Promise<UserProfile>
	forgotPassword: (email: string) => Promise<void>
	resetPassword: (token: string, newPasword: string) => Promise<void>
	changePassword: (newPasword: string) => Promise<void>
	uploadAvatar: (file: File | Blob) => Promise<FileContent>
	deleteAvatar: () => Promise<UserProfile>
	requestEmailChange: (newEmail: string, why: string) => Promise<void>
	getRequestEmailChange: () => Promise<void>
	muteFriend: (userId: UserId) => Promise<UserProfile>
	unmuteFriend: (userId: UserId) => Promise<UserProfile>
	removeFriend: (userId: UserId) => Promise<UserProfile>
}

type UserProfileDTO = {
	id: string
	avatar?: {
		fileId?: string
		color?: string
	}
	name?: string
	surname?: string
	displayName?: string
	birthDay?: string
	country?: string
	timeZone?: string
	email?: string
}

const parseUser = (data: UserProfileDTO): UserProfile => ({
	...data,
	birthDay: data.birthDay ? new Date(data.birthDay) : undefined,
})

const serializeUser = (
	profile: Partial<UserProfile>
): Omit<UserProfileDTO, 'id'> => ({
	...profile,
	birthDay: profile.birthDay
		? format(profile.birthDay, 'yyyy-MM-dd')
		: undefined,
})

export const createUserAPI = memoizeSingle(
	(serverUrl: string): UserApi => ({
		getAvatarUrl: avatar => {
			const fileId = isString(avatar) ? avatar : avatar?.fileId

			if (fileId) {
				return `${serverUrl}/files/${fileId}`
			}
			return `${serverUrl}/public/avatar.png`
		},
		getUserProfiles: users => {
			if (isEmpty(users)) {
				return Promise.resolve([])
			} else {
				const ids = users.join(',')
				return fetch(`${serverUrl}/profiles?userIds=${ids}`, {
					method: 'GET',
				})
					.then(http.checkStatus)
					.then(http.parseBody)
					.then(body => body.map(parseUser))
			}
		},

		getUserProfilesByEmail: users => {
			if (isEmpty(users)) {
				return Promise.resolve([])
			} else {
				const emails = users.join(',')
				return fetch(`${serverUrl}/profiles?emails=${emails}`, {
					method: 'GET',
				})
					.then(http.checkStatus)
					.then(http.parseBody)
					.then(body => body.map(parseUser))
			}
		},

		register: data => {
			return fetch(`${serverUrl}/profiles`, {
				method: 'POST',
				headers: {
					'Content-Type': 'Application/JSON',
				},
				body: JSON.stringify(data),
			})
				.then(http.checkStatus)
				.then(http.parseBody)
				.then(parseUser)
		},
		confirmEmail: token => {
			return fetch(`${serverUrl}/account/confirm`, {
				method: 'POST',
				headers: {
					'Content-Type': 'Application/JSON',
				},
				body: JSON.stringify({ token }),
			}).then(http.checkStatus)
		},
		updateMyProfile: updates => {
			return fetch(`${serverUrl}/profiles/me`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'Application/JSON',
				},
				body: JSON.stringify(serializeUser(updates)),
			})
				.then(http.checkStatus)
				.then(http.parseBody)
				.then(parseUser)
		},
		changePassword: newPassword => {
			return fetch(`${serverUrl}/account/password`, {
				method: 'POST',
				headers: {
					'Content-Type': 'Application/JSON',
				},
				body: JSON.stringify({ newPassword }),
			}).then(http.checkStatus)
		},
		forgotPassword: email => {
			return fetch(`${serverUrl}/account/password/forgot`, {
				method: 'POST',
				headers: {
					'Content-Type': 'Application/JSON',
				},
				body: JSON.stringify({ email }),
			}).then(http.checkStatus)
		},
		resetPassword: (token, newPassword) => {
			return fetch(`${serverUrl}/account/password/reset`, {
				method: 'POST',
				headers: {
					'Content-Type': 'Application/JSON',
				},
				body: JSON.stringify({ token, newPassword }),
			}).then(http.checkStatus)
		},
		uploadAvatar: file => {
			const formData = new FormData()
			formData.append('file', file)
			return fetch(`${serverUrl}/files`, {
				method: 'POST',
				body: formData,
				headers: {},
			})
				.then(http.checkStatus)
				.then(http.parseBody)
		},
		deleteAvatar: () => {
			return fetch(`${serverUrl}/profiles/me/avatar`, {
				method: 'DELETE',
				headers: {},
			})
				.then(http.checkStatus)
				.then(http.parseBody)
				.then(parseUser)
		},
		requestEmailChange: (newEmail: string, why: string) => {
			return fetch(`${serverUrl}/account/email/change`, {
				method: 'POST',
				headers: {
					'Content-Type': 'Application/JSON',
				},
				body: JSON.stringify({
					newEmail,
					why,
				}),
			}).then(http.checkStatus)
		},
		getRequestEmailChange: () => {
			return fetch(`${serverUrl}/account/email/change`, {
				method: 'GET',
				headers: {},
			}).then(http.checkStatus)
		},
		muteFriend: (userId: UserId) => {
			return fetch(`${serverUrl}/profiles/me/friends/${userId}/mute`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'Application/JSON',
				},
				body: JSON.stringify(true),
			})
				.then(http.checkStatus)
				.then(http.parseBody)
				.then(parseUser)
		},
		unmuteFriend: (userId: UserId) => {
			return fetch(`${serverUrl}/profiles/me/friends/${userId}/mute`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'Application/JSON',
				},
				body: JSON.stringify(false),
			})
				.then(http.checkStatus)
				.then(http.parseBody)
				.then(parseUser)
		},
		removeFriend: (userId: UserId) => {
			return fetch(`${serverUrl}/profiles/me/friends/${userId}`, {
				method: 'DELETE',
				headers: {
					'Content-Type': 'Application/JSON',
				},
			})
				.then(http.checkStatus)
				.then(http.parseBody)
				.then(parseUser)
		},
	})
)

export const useUserApi = (serverUrl: string): UserApi => {
	return createUserAPI(serverUrl)
}

export default {
	createUserAPI,
	useUserApi,
}
