import { http, UserId } from '@beelday/common'
import { InteractionSchemeAddress } from 'common/types'
import { useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'

export type CamApi = {
	muteCam: (address: InteractionSchemeAddress, userId: UserId) => Promise<void>
	unmuteCam: (
		address: InteractionSchemeAddress,
		userId: UserId
	) => Promise<void>
}

export const createCamApi = (serverUrl: string): CamApi => ({
	muteCam: (address: InteractionSchemeAddress, userId: UserId) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/features/camera/${userId}/muted`,
			{
				method: 'PUT',
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
				body: JSON.stringify(true),
			}
		).then(http.checkStatus),
	unmuteCam: (address: InteractionSchemeAddress, userId: UserId) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/features/camera/${userId}/muted`,
			{
				method: 'PUT',
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
				body: JSON.stringify(false),
			}
		).then(http.checkStatus),
})

export const useCamApi = (serverUrl: string): CamApi => {
	return useMemo(() => createCamApi(serverUrl), [serverUrl])
}

export default useCamApi
