import styled from '@emotion/styled'
import { colors } from '../colors'
import responsive from './responsive'

export const DragHandle = styled.div`
	height: 15px;
	position: absolute;
	width: 15px;
	right: -22.5px;
	top: 50%;
	transform: translateY(-50%);
	z-index: 2;

	transition: 0.1s ease-in-out;

	svg {
		width: 100%;
		height: 100%;
		color: ${colors.heather};
	}

	${responsive.desktop} {
		height: 20px;
		width: 20px;
		right: -35px;
	}
`
