import { reduce } from 'lodash'
import { FC, useCallback, useMemo, useState } from 'react'
import {
	DragDropContext,
	DraggableId,
	DraggableLocation,
} from 'react-beautiful-dnd'
import { css } from '@emotion/react'
import { GroupId, GroupSuggestionAssignment, User, UserId } from 'common/types'
import AroundLayout from 'room/training-room/set-groups/presentation/around-layout'
import BottomLayout from 'room/training-room/set-groups/presentation/bottom-layout'
import GroupPickerLayout from 'room/training-room/set-groups/presentation/group-picker-layout'
import UserUnassigned from 'room/training-room/set-groups/presentation/user-unassigned'
import { isDropArea } from 'room/training-room/set-groups/presentation/drop-areas'
import { SceneVideoUser } from 'video-conference-media'

type Props = {
	assignUserToGroup: (userId: UserId, groupId: GroupId) => void
	removeUserFromGroup: (userId: UserId) => void
	groups: GroupSuggestionAssignment[]
	sceneVideoUsers: SceneVideoUser[]
	unassignedUsers: User[]
	users: User[]
}

const GroupPickerTrainer: FC<Props> = ({
	assignUserToGroup,
	removeUserFromGroup,
	groups,
	sceneVideoUsers,
	unassignedUsers,
	users,
}) => {
	const [draggingUserID, setDraggingUserID] = useState<UserId>()
	const streamsByID: { [key: string]: SceneVideoUser } = useMemo(
		() =>
			reduce(
				sceneVideoUsers,
				(acc, u) => {
					return { ...acc, [u.userId]: u }
				},
				{}
			),
		[sceneVideoUsers]
	)
	const numberOfUsers = users.length

	const canAssignUserToGroup = useCallback(
		(groupId: string) => {
			const assignGroup = groups.find(
				group => group.groupDescription.id === groupId
			)
			return (
				assignGroup &&
				assignGroup.groupDescription.maxGroupSize > assignGroup.users.length
			)
		},
		[groups]
	)

	const onDragEnd = useCallback(
		({
			draggableId,
			destination,
			source,
		}: {
			draggableId: DraggableId
			destination?: DraggableLocation
			source: DraggableLocation
		}) => {
			const destinationID = destination?.droppableId
			if (destinationID && destinationID !== source.droppableId) {
				if (canAssignUserToGroup(destinationID)) {
					assignUserToGroup(draggableId, destinationID)
				} else if (isDropArea(destinationID)) {
					removeUserFromGroup(draggableId)
				}
			}
			setDraggingUserID(undefined)
		},
		[canAssignUserToGroup, assignUserToGroup, removeUserFromGroup]
	)

	const onDragStart = useCallback(
		({ draggableId }: { draggableId: DraggableId }) => {
			setDraggingUserID(draggableId)
		},
		[]
	)

	return (
		<DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
			<div
				css={css`
					display: flex;
					align-items: center;
					flex-direction: column;
					height: 100%;
					width: 100%;
					flex-grow: 1;
				`}
			>
				{numberOfUsers <= 8 ? (
					<BottomLayout
						droppable
						center={
							<GroupPickerLayout
								droppable
								groups={groups}
								streams={streamsByID}
							/>
						}
					>
						{users.map((u, i) => (
							<UserUnassigned
								key={u.id}
								index={i}
								user={u}
								stream={streamsByID[u.id]}
								unassigned={!!unassignedUsers.find(uu => uu.id === u.id)}
								dragging={u.id === draggingUserID}
							/>
						))}
					</BottomLayout>
				) : (
					<AroundLayout
						droppable
						center={
							<GroupPickerLayout
								droppable
								groups={groups}
								streams={streamsByID}
							/>
						}
					>
						{users.map((u, i) => (
							<UserUnassigned
								key={u.id}
								index={i}
								user={u}
								stream={streamsByID[u.id]}
								unassigned={!!unassignedUsers.find(uu => uu.id === u.id)}
								dragging={u.id === draggingUserID}
							/>
						))}
					</AroundLayout>
				)}
			</div>
		</DragDropContext>
	)
}

export default GroupPickerTrainer
