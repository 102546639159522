import {
	selectCurrentUserMuted,
	selectTrainerMuted,
} from 'interaction-scheme/redux'
import { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import DraggableStreamIcon from 'room/common/presentation/draggable-stream-icon'
import { selectTrainerStream } from 'room/common/redux'
import { selectHasVcr } from 'video-conference-media/redux'

export const SelfStreamView = (): JSX.Element | null => {
	const selfVideoUser = useSelector(selectTrainerStream)
	const muted = useSelector(selectCurrentUserMuted)
	const hasVcr = useSelector(selectHasVcr)

	return hasVcr && selfVideoUser ? (
		<DraggableStreamIcon
			mirrored={true}
			sceneVideoUser={selfVideoUser}
			mutedIcon={muted}
			startVisible={true}
		/>
	) : null
}

type TrainerStreamViewProps = {
	startVisible: boolean
}

export const TrainerStreamView: FC<TrainerStreamViewProps> = ({
	startVisible,
}) => {
	const trainerVideoUser = useSelector(selectTrainerStream)
	const trainerMuted = useSelector(selectTrainerMuted)
	const hasVcr = useSelector(selectHasVcr)

	const streamWithoutScreenshare = useMemo(() => {
		if (trainerVideoUser && trainerVideoUser.stream) {
			const { screenShareTrackId } = trainerVideoUser.stream
			if (screenShareTrackId) {
				return new MediaStream(
					trainerVideoUser.stream
						.getTracks()
						.filter(t => t.id !== screenShareTrackId)
				)
			} else {
				return trainerVideoUser.stream
			}
		}

		return undefined
	}, [trainerVideoUser])

	return hasVcr && trainerVideoUser && streamWithoutScreenshare ? (
		<DraggableStreamIcon
			sceneVideoUser={trainerVideoUser}
			mirrored={false}
			mutedIcon={trainerMuted}
			startVisible={startVisible}
		/>
	) : null
}
