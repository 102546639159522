import { FC, useCallback, useEffect, useState } from 'react'
import { intl, logger, ui } from '@beelday/common'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { useSelector } from 'react-redux'
import { RootState } from 'common/redux'
import { filterSceneVideoUsers } from 'room/group-room/model/scene-video-users'
import { roomEndUsers } from 'room/common/redux'
import { allStreams } from 'video-conference-media/redux'
import KnowledgeCheckGridView from './presentation/knowledge-check-grid-view'
import {
	KnowledgeCheck,
	KnowledgeCheckOptionID,
	KnowledgeCheckQuestionID,
} from './knowledge-check'
import useKnowledgeChecks from './use-knowledge-checks'
import { UUID } from '../../common/types'
import { ErrorWithStreams } from 'room/common/error-with-streams'

const calculateAnsweredQuestions = (
	answers: Record<KnowledgeCheckQuestionID, KnowledgeCheckOptionID[]>
): number => {
	return Object.keys(answers).filter(
		item => Array.isArray(answers[item]) && answers[item].length
	).length
}

const ConfiguredKnowledgeCheckTrainee: FC<{ knowledgeCheckId: UUID }> = ({
	knowledgeCheckId,
}) => {
	const roomAddress = useAssertedJoinedRoomAddress()
	const {
		getKnowledgeCheck,
		answerQuestion,
		prevQuestion,
		nextQuestion,
		finishKnowledgeCheck,
	} = useKnowledgeChecks()
	const sceneVideoUsers = useSelector(allStreams)
	const endUsers = useSelector(roomEndUsers)
	const sceneVideoEndUsers = filterSceneVideoUsers(endUsers, sceneVideoUsers)
	const confirm = ui.useConfirm()

	const training = useSelector((state: RootState) => state.sessionInfo.training)
	const trainerProfile = useSelector(
		(state: RootState) =>
			state.users.all[
				training?.upperEchelon.userId ? training?.upperEchelon.userId : ''
			]
	)

	const [knowledgeCheck, setKnowledgeCheck] = useState<KnowledgeCheck>()
	const [error, setError] = useState<Error>()

	const reloadKnowledgeCheck = useCallback(() => {
		if (knowledgeCheckId) {
			setError(undefined)
			getKnowledgeCheck(roomAddress, knowledgeCheckId)
				.then(setKnowledgeCheck)
				.catch(e => {
					logger.error(
						'Failed to fetch knowledge check: ' + knowledgeCheckId,
						e
					)
					setError(e)
				})
		}
	}, [roomAddress, knowledgeCheckId, getKnowledgeCheck])

	useEffect(reloadKnowledgeCheck, [reloadKnowledgeCheck])

	const onAnswer = (
		questionId: KnowledgeCheckQuestionID,
		answers: KnowledgeCheckOptionID[]
	) => {
		if (knowledgeCheck) {
			setKnowledgeCheck({
				...knowledgeCheck,
				state: {
					...knowledgeCheck.state,
					answers: { ...knowledgeCheck.state.answers, [questionId]: answers },
				},
			})
			answerQuestion(roomAddress, knowledgeCheck.id, questionId, answers)
				.then(() => getKnowledgeCheck(roomAddress, knowledgeCheck.id))
				.then(setKnowledgeCheck)
				.catch(error => {
					setKnowledgeCheck(knowledgeCheck)
					logger.error(error)
				})
		}
	}

	const onPrev = () => {
		if (knowledgeCheckId) {
			prevQuestion(roomAddress, knowledgeCheckId).then(() =>
				getKnowledgeCheck(roomAddress, knowledgeCheckId).then(setKnowledgeCheck)
			)
		}
	}

	const onNext = () => {
		if (knowledgeCheckId) {
			nextQuestion(roomAddress, knowledgeCheckId).then(() =>
				getKnowledgeCheck(roomAddress, knowledgeCheckId).then(setKnowledgeCheck)
			)
		}
	}

	const onFinish = (
		questionId: KnowledgeCheckQuestionID,
		answers: KnowledgeCheckOptionID[]
	) => {
		if (knowledgeCheckId) {
			answerQuestion(roomAddress, knowledgeCheckId, questionId, answers).then(
				() =>
					getKnowledgeCheck(roomAddress, knowledgeCheckId).then(
						fetchedKnowledgeCheck => {
							setKnowledgeCheck(fetchedKnowledgeCheck)
							const countAnsweredQuestions = calculateAnsweredQuestions(
								fetchedKnowledgeCheck.state.answers
							)
							confirm
								.generic(
									<intl.Translate>
										knowledge_check.question.finish.popup.title
									</intl.Translate>,
									fetchedKnowledgeCheck.questions.length ===
										countAnsweredQuestions ? (
										<intl.Translate>
											knowledge_check.question.finish.popup.desc.confirm
										</intl.Translate>
									) : (
										<intl.Translate>
											knowledge_check.question.finish.popup.desc.no_answers
										</intl.Translate>
									),
									<intl.Translate>
										knowledge_check.question.finish.popup.confirm
									</intl.Translate>,
									<intl.Translate>
										knowledge_check.question.finish.popup.cancel
									</intl.Translate>
								)
								.then(() =>
									finishKnowledgeCheck(roomAddress, knowledgeCheckId).then(() =>
										getKnowledgeCheck(roomAddress, knowledgeCheckId).then(
											setKnowledgeCheck
										)
									)
								)
						}
					)
			)
		}
	}

	if (error) {
		return (
			<ErrorWithStreams retry={reloadKnowledgeCheck}>
				<intl.Translate>knowledge_check.error.load</intl.Translate>
			</ErrorWithStreams>
		)
	}

	return knowledgeCheck ? (
		<KnowledgeCheckGridView
			title={training?.subject}
			sceneVideoUsers={sceneVideoEndUsers}
			userProfile={trainerProfile}
			knowledgeCheck={knowledgeCheck}
			onAnswer={onAnswer}
			onPrev={onPrev}
			onNext={onNext}
			onFinish={onFinish}
		/>
	) : null
}

export default ConfiguredKnowledgeCheckTrainee
