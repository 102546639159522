import styled from '@emotion/styled'
import { Colors } from 'common/presentation/colors'
import { FC } from 'react'
import whiteTickIcon from 'room/training-room/ice-breaker/presentation/images/white-tick.svg'
import crossIcon from 'room/training-room/ice-breaker/presentation/images/cross.svg'
import sadFaceIcon from 'room/training-room/ice-breaker/presentation/images/sad-face.svg'
import happyFaceIcon from 'room/training-room/ice-breaker/presentation/images/happy-face.svg'
import greenTickIcon from 'room/training-room/ice-breaker/presentation/images/green-tick.svg'
import { ui } from '@beelday/common'

type ContainerColors = {
	border: string
	background: string
}

const Container = styled.div`
	height: 45px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-items: center;
	align-content: stretch;
	border: solid 2px ${(colors: ContainerColors) => colors.border};
	border-radius: 11px;
	background-color: ${(colors: ContainerColors) => colors.background};
	cursor: pointer;

	${ui.responsive.desktop1440} {
		height: 55px;
	}
`

const Icon = styled.div`
	width: 55px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
`

const SelectionEllipse = styled.div`
	width: 18px;
	height: 18px;
	border-radius: 100px;
	background-color: ${Colors.indigo};
	background-image: url(${whiteTickIcon});
	background-repeat: no-repeat;
	background-position: center;

	${ui.responsive.desktop1440} {
		width: 22px;
		height: 22px;
	}
`

const CorrectEllipse = styled.div`
	width: 18px;
	height: 18px;
	border-radius: 100px;
	background-color: ${Colors.greenishTeal};
	background-image: url(${whiteTickIcon});
	background-repeat: no-repeat;
	background-position: center;

	${ui.responsive.desktop1440} {
		width: 22px;
		height: 22px;
	}
`

const WrongEllipse = styled.div`
	width: 18px;
	height: 18px;
	border-radius: 100px;
	background-color: ${Colors.lipstick};
	background-image: url(${crossIcon});
	background-repeat: no-repeat;
	background-position: center;

	${ui.responsive.desktop1440} {
		width: 22px;
		height: 22px;
	}
`

const SelectableEllipse = styled.div`
	width: 18px;
	height: 18px;
	border-radius: 100px;
	background-color: ${Colors.paleGray};

	${ui.responsive.desktop1440} {
		width: 22px;
		height: 22px;
	}
`

const SadFace = styled.div`
	width: 18px;
	height: 18px;
	background-image: url(${sadFaceIcon});
	background-repeat: no-repeat;
	background-position: center;

	${ui.responsive.desktop1440} {
		width: 22px;
		height: 22px;
	}
`

const HappyFace = styled.div`
	width: 18px;
	height: 18px;
	background-image: url(${happyFaceIcon});
	background-repeat: no-repeat;
	background-position: center;

	${ui.responsive.desktop1440} {
		width: 22px;
		height: 22px;
	}
`

const GreenTick = styled.div`
	width: 18px;
	height: 18px;
	background-image: url(${greenTickIcon});
	background-repeat: no-repeat;
	background-position: center;

	${ui.responsive.desktop1440} {
		width: 22px;
		height: 22px;
	}
`

type ContentProps = {
	color: string
}

const Content = styled.div`
	flex-grow: 1;
	font-family: Ubuntu;
	font-weight: 300;
	font-size: 16px;
	color: ${(props: ContentProps) => props.color};
`

type Props = {
	onClick: () => void
}

export const SelectedWrongAnswerButton: FC<Props> = ({ children, onClick }) => {
	return (
		<Container
			background={Colors.strongPink}
			border={Colors.strongPink}
			onClick={onClick}
		>
			<Icon>
				<WrongEllipse />
			</Icon>
			<Content color={Colors.white}>{children}</Content>
			<Icon>
				<SadFace />
			</Icon>
		</Container>
	)
}

export const SelectedCorrectAnswerButton: FC<Props> = ({
	children,
	onClick,
}) => {
	return (
		<Container
			background={Colors.greenishCyan}
			border={Colors.greenishCyan}
			onClick={onClick}
		>
			<Icon>
				<CorrectEllipse />
			</Icon>
			<Content color={Colors.white}>{children}</Content>
			<Icon>
				<HappyFace />
			</Icon>
		</Container>
	)
}

export const UnselectedCorrectAnswerButton: FC<Props> = ({
	children,
	onClick,
}) => {
	return (
		<Container
			background={Colors.white}
			border={Colors.greenishCyan}
			onClick={onClick}
		>
			<Icon>
				<SelectableEllipse />
			</Icon>
			<Content color={Colors.heather}>{children}</Content>
			<Icon>
				<GreenTick />
			</Icon>
		</Container>
	)
}

export const UncheckedAnswerButton: FC<Props> = ({ children, onClick }) => {
	return (
		<Container
			background={Colors.white}
			border={Colors.brightGray}
			onClick={onClick}
		>
			<Icon>
				<SelectableEllipse />
			</Icon>
			<Content color={Colors.heather}>{children}</Content>
		</Container>
	)
}

export const CheckedAnswerButton: FC<Props> = ({ children, onClick }) => {
	return (
		<Container
			background={Colors.white}
			border={Colors.indigoBlue}
			onClick={onClick}
		>
			<Icon>
				<SelectionEllipse />
			</Icon>
			<Content color={Colors.indigo}>{children}</Content>
		</Container>
	)
}

export const ConfirmedAnswerButton: FC<Props> = ({ children, onClick }) => {
	return (
		<Container
			background={Colors.indigoBlue}
			border={Colors.indigoBlue}
			onClick={onClick}
		>
			<Icon>
				<SelectionEllipse />
			</Icon>
			<Content color={Colors.white}>{children}</Content>
		</Container>
	)
}
