import { colors, icons, intl, ui } from '@beelday/common'
import styled from '@emotion/styled'
import { useState } from 'react'
import {
	KnowledgeCheckResultOption,
	KnowledgeCheckResultQuestion,
} from './knowledge-check'

const StyledOption = styled.div<{
	isOpen: boolean
}>`
	align-items: center;
	border-bottom: ${props =>
		props.isOpen
			? `1px solid ${colors.borderLightGray}`
			: `1px solid transparent`};
	display: flex;
	padding: ${props => (props.isOpen ? '10px 0' : '0 0')};
	opacity: ${props => (props.isOpen ? '1' : '0')};
	margin: 0px 50px 0px 54px;

	transition: padding 0.3s ease-in-out, border-bottom 0.3s ease-in-out,
		opacity 0.3s ease-in-out;
	overflow: hidden;

	&:last-child {
		border-bottom: none;
		margin-bottom: 8px;
	}
`

const Status = styled.div`
	align-self: flex-start;
	align-items: center;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	height: 22px;
	min-height: 22px;
	min-width: 22px;
	margin-right: 16px;
	width: 22px;

	svg {
		color: ${colors.white};
		height: 10px;
		width: 10px;
	}
`

const Answer = styled.div`
	color: ${colors.black};
	font-size: 14px;
`

type OptionProps = {
	option: KnowledgeCheckResultOption
	isOpen: boolean
	useCorrectAnswers: boolean
}

const Option = ({ option, isOpen, useCorrectAnswers }: OptionProps) => {
	let statusColor = colors.black
	let statusBgColor = colors.paleGray2

	if (useCorrectAnswers && option.selected && option.correct) {
		statusColor = colors.greenishCyan
		statusBgColor = colors.greenishCyan
	} else if (useCorrectAnswers && option.selected && !option.correct) {
		statusBgColor = colors.strongPink
		statusColor = colors.strongPink
	} else if (!useCorrectAnswers && option.selected) {
		statusBgColor = colors.lighterIndigoBlue
	}

	return (
		<StyledOption isOpen={isOpen} key={option.id}>
			<Status style={{ backgroundColor: statusBgColor }}>
				{option.selected && useCorrectAnswers ? (
					option.correct ? (
						<icons.IconChecked />
					) : (
						<icons.CancelIcon />
					)
				) : null}
			</Status>
			<Answer style={{ color: statusColor }}>{option.answer}</Answer>
		</StyledOption>
	)
}

const QuestionContainer = styled.div`
	border-bottom: 1px solid ${colors.borderLightGray};
	cursor: pointer;
	height: 100%;
	padding: 16px 18px;
	width: 100%;

	&:nth-of-type(2) {
		border-top: 1px solid ${colors.borderLightGray};
	}
`

const QuestionTitle = styled.div`
	font-size: 18px;
	font-weight: 500;
	line-height: 30px;
`

const OptionsContainer = styled.div<{ isOpen: boolean }>`
	display: flex;
	flex-direction: column;
	max-height: ${props => (props.isOpen ? '350px' : '0px')};
	transition: max-height 0.3s ease-in-out;
`

const IconContainer = styled.div<{ isCorrect: boolean }>`
	align-self: flex-start;
	color: ${props =>
		props.isCorrect ? colors.greenishCyan : colors.strongPink};
	height: 26px;
	min-height: 26px;
	margin-right: 28px;
	min-width: 26px;
	width: 26px;
`

const ChevronContainer = styled.div<{ isOpen: boolean }>`
	margin-left: auto;
	svg {
		width: 18px;
		height: 8px;
		transform: ${props => (props.isOpen ? `rotate(-180deg)` : '')};
		transition: transform 0.3s ease-in-out;
	}
`

type QuestionProps = {
	question: KnowledgeCheckResultQuestion
	useCorrectAnswers: boolean
}

const Question = ({ question, useCorrectAnswers }: QuestionProps) => {
	const [isOpen, setIsOpen] = useState(false)

	return (
		<QuestionContainer onClick={() => setIsOpen(!isOpen)}>
			<ui.Flex style={{ alignItems: 'center', height: 'fit-content' }}>
				{useCorrectAnswers ? (
					<IconContainer isCorrect={question.correct}>
						{question.correct ? <icons.IconChecked /> : <icons.CancelIcon />}
					</IconContainer>
				) : null}

				<QuestionTitle>{question.question}</QuestionTitle>
				<ChevronContainer isOpen={isOpen}>
					<icons.ChevronDown />
				</ChevronContainer>
			</ui.Flex>

			<OptionsContainer isOpen={isOpen}>
				{question.options.map(option => (
					<Option
						option={option}
						isOpen={isOpen}
						key={option.id}
						useCorrectAnswers={useCorrectAnswers}
					/>
				))}
			</OptionsContainer>
		</QuestionContainer>
	)
}

const Container = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	width: calc(100% - 40px);
`

const Title = styled.div`
	font-size: 22px;
	font-weight: 500;
	margin: 20px 0px 16px 0px;
	text-align: center;
`

type Props = {
	knowledgeCheckQuestions: KnowledgeCheckResultQuestion[]
	useCorrectAnswers: boolean
}

const KnowledgeCheckResultQuestions = ({
	knowledgeCheckQuestions,
	useCorrectAnswers,
}: Props): JSX.Element => {
	return (
		<Container>
			<Title>
				<intl.Translate>knowledge_check.stats.answers</intl.Translate>
			</Title>
			{knowledgeCheckQuestions.map(question => {
				return (
					<Question
						question={question}
						key={question.id}
						useCorrectAnswers={useCorrectAnswers}
					/>
				)
			})}
		</Container>
	)
}

export default KnowledgeCheckResultQuestions
