import { reduce } from 'lodash'
import { FC, useMemo } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { css } from '@emotion/react'
import {
	BOTTOM,
	TOP,
	LEFT,
	RIGHT,
} from 'room/training-room/set-groups/presentation/drop-areas'
import useResize from 'utils/hooks/use-resize'

const generateUserPanels = (elements: JSX.Element[]) => {
	const empty: JSX.Element[] = []
	const panels = {
		top: empty,
		left: empty,
		bottom: empty,
		right: empty,
	}

	if (elements.length < 22) {
		return reduce(
			elements,
			(acc, u, i) => {
				const x = (i + 1) % 4
				switch (x) {
					case 0:
					case 1:
						return { ...acc, bottom: [...acc.bottom, u] }
					case 2:
						return { ...acc, right: [...acc.right, u] }
					case 3:
						return { ...acc, left: [...acc.left, u] }

					default:
						return acc
				}
			},
			panels
		)
	} else {
		const maxLeft = elements.slice(0, 6)
		const maxRight = elements.slice(6, 12)

		const rest = elements.slice(12)
		const half = Math.ceil(rest.length / 2)
		const fillBottom = rest.slice(0, half)
		const fillTop = rest.slice(half)

		return {
			left: maxLeft,
			right: maxRight,
			bottom: fillBottom,
			top: fillTop,
		}
	}
}

type Props = {
	center: JSX.Element
	children: JSX.Element[]
	droppable?: boolean
}

const calculateElementSize = (width: number, height: number) => {
	const maxNumberOfElementsInRow = 10
	const maxNumberOfElementsInColumn = 9

	const aspectRatio = 12 / 9
	if (width < height) {
		const elemWidth = Math.floor(width / maxNumberOfElementsInRow)
		const elemHeight = Math.floor(elemWidth / aspectRatio)
		return { elemWidth, elemHeight }
	} else {
		const elemHeight = Math.floor(height / maxNumberOfElementsInColumn)
		const elemWidth = Math.floor(elemHeight * aspectRatio)
		return { elemWidth, elemHeight }
	}
}

const AroundLayout: FC<Props> = ({ center, children, droppable }: Props) => {
	const panels = generateUserPanels(children)

	const { ref: containerRef, width, height } = useResize()

	const { elemWidth, elemHeight } = useMemo(
		() => calculateElementSize(width, height),
		[width, height]
	)

	return (
		<div
			css={css`
				height: 100%;
				width: 100%;
				display: flex;
				flex-direction: column;
				flex-grow: 1;
			`}
			ref={containerRef}
		>
			<Droppable droppableId={TOP} isDropDisabled={!droppable}>
				{provided => (
					<div
						css={css`
							align-items: flex-end;
							display: flex;
							justify-content: center;
							width: 100%;
							margin-bottom: 20px;
							${panels.top.length === 0 ? `height: ${elemHeight}px;` : ''}
						`}
						ref={provided.innerRef}
						{...provided.innerRef}
					>
						{panels.top.map(e => (
							<div
								css={css`
									margin: 5px;
									height: ${elemHeight - 10}px;
									width: ${elemWidth - 10}px;
								`}
							>
								{e}
							</div>
						))}
					</div>
				)}
			</Droppable>
			<div
				css={css`
					display: flex;
					flex-grow: 1;
					width: 100%;
				`}
			>
				<Droppable droppableId={LEFT} isDropDisabled={!droppable}>
					{provided => (
						<div
							css={css`
								display: flex;
								flex-direction: column;
								justify-content: center;
								margin-right: 20px;
								margin-left: 60px;
							`}
							ref={provided.innerRef}
							{...provided.innerRef}
						>
							{panels.left.map(e => (
								<div
									css={css`
										margin: 5px;
										height: ${elemHeight - 10}px;
										width: ${elemWidth - 10}px;
									`}
								>
									{e}
								</div>
							))}
						</div>
					)}
				</Droppable>
				<div
					css={css`
						flex-grow: 1;
					`}
				>
					{center}
				</div>
				<Droppable droppableId={RIGHT} isDropDisabled={!droppable}>
					{provided => (
						<div
							css={css`
								display: flex;
								flex-direction: column;
								justify-content: center;
								margin-right: 60px;
								margin-left: 20px;
							`}
							ref={provided.innerRef}
							{...provided.droppableProps}
						>
							{panels.right.map(e => (
								<div
									css={css`
										margin: 5px;
										height: ${elemHeight - 10}px;
										width: ${elemWidth - 10}px;
									`}
								>
									{e}
								</div>
							))}
						</div>
					)}
				</Droppable>
			</div>
			<Droppable droppableId={BOTTOM} isDropDisabled={!droppable}>
				{provided => (
					<div
						css={css`
							display: flex;
							justify-content: center;
							margin-top: 20px;
							width: 100%;
						`}
						ref={provided.innerRef}
						{...provided.droppableProps}
					>
						{panels.bottom.map(e => (
							<div
								css={css`
									margin: 5px;
									height: ${elemHeight - 10}px;
									width: ${elemWidth - 10}px;
								`}
							>
								{e}
							</div>
						))}
					</div>
				)}
			</Droppable>
		</div>
	)
}

export default AroundLayout
