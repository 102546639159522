export const Calendar: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="100%"
		height="100%"
		viewBox="0 0 18 20"
	>
		<g id="Group_4461" transform="translate(-247 -246)">
			<path
				id="Path_8901"
				d="M259,248v-1a1,1,0,0,1,2,0v1a4,4,0,0,1,4,4v10a4,4,0,0,1-4,4H251a4,4,0,0,1-4-4V252a4,4,0,0,1,4-4v-1a1,1,0,0,1,2,0v1Zm4,8H249v6a2,2,0,0,0,2,2h10a2,2,0,0,0,2-2Zm-2-6v1a1,1,0,0,1-2,0v-1h-6v1a1,1,0,0,1-2,0v-1a2,2,0,0,0-2,2v2h14v-2A2,2,0,0,0,261,250Z"
				fill="currentColor"
				fillRule="evenodd"
			/>
		</g>
	</svg>
)
