import {
	Color,
	ColorKeys,
	GroupSuggestionAssignment,
	GroupSuggestionAssignmentDto,
} from 'common/types'

export const toGroupSuggestions = (
	groupDTO: GroupSuggestionAssignmentDto
): GroupSuggestionAssignment => ({
	groupDescription: {
		id: groupDTO.groupId,
		name: groupDTO.name,
		maxGroupSize: groupDTO.maxGroupSize,
		color: Color[groupDTO.color.toUpperCase() as ColorKeys],
	},
	users: groupDTO.users,
})
