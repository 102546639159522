import { FC } from 'react'
import styled from '@emotion/styled'
import colors from '../colors'

type Props = {
	active?: boolean
	onClick?: () => unknown
	className?: string
}

const Button = styled.button`
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${colors.darkIndigo};
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: none;
	color: white;
	transition: color 0.25s, background 0.25s, box-shadow 0.25s;

	&.active,
	&:hover {
		background: white;
		box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
		color: ${colors.darkIndigo};
	}
`

export const CircleIconButton: FC<Props> = ({
	onClick,
	className,
	children,
	active,
}) => (
	<Button
		className={`${className} ${active ? ' active' : ''}`}
		onClick={onClick}
	>
		{children}
	</Button>
)

export default CircleIconButton
