import { intl, ui } from '@beelday/common'
import styled from '@emotion/styled'
import { Colors } from 'common/presentation/colors'
import { UserProfile } from 'common/types'
import { FC } from 'react'
import { KnowledgeCheckReview } from '../knowledge-check'
import KnowledgeCheckHeader from './knowledge-check-header'
import ThreeColumnsVideoGrid from 'room/training-room/ice-breaker/presentation/three-columns-video-grid'
import KnowledgeCheckReviewQuestion from './knowledge-check-review-question'
import { SceneVideoUser } from 'video-conference-media'

const ContainerWrapper = styled.div`
	background-color: ${Colors.white};
	border-radius: 42px 18px 18px 18px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0 auto;
	max-width: 700px;
	overflow: hidden;
	width: 100%;
`

const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	position: relative;
`

const ContainerInner = styled.div`
	display: flex;
	flex-flow: row wrap;
	flex-direction: column;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	padding: 20px 0;
`

const ContainerContent = styled.div`
	flex: 1;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 0 20px;
	width: 100%;
`

const ContainerFooter = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: auto;
	padding: 20px;
`

const LeftButtons = styled.div``

const RightButtons = styled.div``

type Props = {
	sceneVideoUsers: SceneVideoUser[]
	userProfile: UserProfile | undefined | null
	review: KnowledgeCheckReview
	onClose: () => void
	onPrev: () => void
	onNext: () => void
}

const KnowledgeCheckTrainerReviewGridView: FC<Props> = ({
	sceneVideoUsers,
	userProfile,
	review,
	onClose,
	onPrev,
	onNext,
}) => {
	const isLastQuestion =
		review.currentQuestionIndex === review.questions.length - 1
	return (
		<ThreeColumnsVideoGrid sceneVideoUsers={sceneVideoUsers}>
			<ContainerWrapper>
				<KnowledgeCheckHeader
					title={review.name}
					userProfile={userProfile}
					progress={{
						currentValue: review.currentQuestionIndex + 1,
						maxValue: review.questions.length,
					}}
				/>
				<Container>
					<ContainerInner>
						<ContainerContent>
							<KnowledgeCheckReviewQuestion
								question={review.questions[review.currentQuestionIndex]}
								number={review.currentQuestionIndex + 1}
								countUsers={review.users.length}
								useCorrectAnswers={review.useCorrectAnswers}
							/>
						</ContainerContent>
						<ContainerFooter>
							<LeftButtons>
								{!isLastQuestion ? (
									<ui.ButtonMinor onClick={onClose}>
										<intl.Translate>
											knowledge_check.review.close
										</intl.Translate>
									</ui.ButtonMinor>
								) : null}
							</LeftButtons>
							<RightButtons>
								{review.currentQuestionIndex !== 0 ? (
									<ui.ButtonMinor
										onClick={onPrev}
										disabled={review.currentQuestionIndex === 0}
									>
										<intl.Translate>
											knowledge_check.review.prev_question
										</intl.Translate>
									</ui.ButtonMinor>
								) : null}

								{!isLastQuestion ? (
									<ui.ButtonPrimary
										onClick={onNext}
										style={{ marginLeft: '15px' }}
									>
										<intl.Translate>
											knowledge_check.review.next_question
										</intl.Translate>
									</ui.ButtonPrimary>
								) : (
									<ui.ButtonPrimary
										onClick={onClose}
										style={{ marginLeft: '15px' }}
									>
										<intl.Translate>
											knowledge_check.review.close
										</intl.Translate>
									</ui.ButtonPrimary>
								)}
							</RightButtons>
						</ContainerFooter>
					</ContainerInner>
				</Container>
			</ContainerWrapper>
		</ThreeColumnsVideoGrid>
	)
}

export default KnowledgeCheckTrainerReviewGridView
