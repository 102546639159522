import { intl, styles } from '@beelday/common'
import { FC } from 'react'
//TODO: this should be moved to some common/layout folder, not used from ice-breaker
import ThreeColumnsVideoGrid from 'room/training-room/ice-breaker/presentation/three-columns-video-grid'
import { SceneVideoUser } from 'video-conference-media'

type Props = {
	streams: SceneVideoUser[]
}
export const SimpleGroupingFeature: FC<Props> = ({ streams }) => {
	return (
		<ThreeColumnsVideoGrid sceneVideoUsers={streams}>
			<div css={[styles.fillWidthHeight, styles.flexCenterAll]}>
				<h1>
					<intl.Translate>features.simple-grouping.title</intl.Translate>
				</h1>
			</div>
		</ThreeColumnsVideoGrid>
	)
}
