import { SSE } from './models'

const isSSE = (event: unknown): event is SSE => {
	if (typeof event === 'object' && event !== null && 'eventType' in event) {
		return true
	} else {
		return false
	}
}

export const toSSEAction = (
	event: unknown
): { type: string; payload: unknown } | null => {
	if (isSSE(event)) {
		return {
			type: 'SSE_' + event.eventType,
			payload: event,
		}
	} else {
		console.error('No eventType property defined for the event')
		return null
	}
}
