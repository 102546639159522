export const Busy: React.FC = () => (
	<svg
		data-name="Group 25572"
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
	>
		<defs>
			<clipPath id="s235nli6ka">
				<path data-name="Rectangle 3990" d="M0 0h24v24H0z" />
			</clipPath>
		</defs>
		<g data-name="Group 25571">
			<path
				data-name="Path 11806"
				d="M12 0a12 12 0 1 0 12 12A12 12 0 0 0 12 0zm5.688 14.189H6.311c-.874 0-1.583-.98-1.583-2.189s.709-2.189 1.583-2.189h11.377c.874 0 1.583.98 1.583 2.189s-.709 2.189-1.583 2.189z"
			/>
		</g>
	</svg>
)
