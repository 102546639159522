export const EndBreak: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="25.8"
		height="25.6"
		viewBox="0 0 25.8 25.6"
	>
		<path
			d="M12.9 6.1a2.769 2.769 0 0 1-.1.9 2.177 2.177 0 0 1-.5.8 4.349 4.349 0 0 1-.7.6l-.3.1-.1.1h-.1l-.1.1a.1.1 0 0 0-.1.1h-.2V9c0 .1-.1.1-.1.2v.6a.349.349 0 0 1-.1.2.367.367 0 0 1-.3.1h-.1a.349.349 0 0 1-.2-.1l-.1-.1c-.1 0-.1-.1-.2-.1-.5-.2-.5-.3-.6-.4 0-.1-.1-.2-.1-.3v-1c0-.1 0-.1.1-.2 0-.1 0-.1.1-.2 0-.1.1-.1.1-.2a.588.588 0 0 1 .2-.5l.3-.3a.1.1 0 0 0 .1-.1h.1l.1-.2h.1l.1-.1c.1-.1.2-.1.3-.2.1 0 .1-.1.2-.2l.2-.2c.1 0 .1-.1.1-.2.1-.2.1-.4.2-.6v-.4a.4.4 0 0 1 .4-.2.1.1 0 0 1 .1.1.215.215 0 0 1 .2.2 1.555 1.555 0 0 1 .6.8.755.755 0 0 1 .4.7z"
			fill="currentColor"
			transform="translate(3.687 .8)"
		/>
		<path
			d="M15.6 22.8a1.216 1.216 0 0 1-1.2 1.2H1.2A1.135 1.135 0 0 1 0 22.9a1.155 1.155 0 0 1 1.2-1.2h1.4a7.705 7.705 0 0 1-2.3-3.6 28.159 28.159 0 0 1-1-5.8v-.1a1.11 1.11 0 0 1 1.1-1.1h3.9l9.6 9.6a6.47 6.47 0 0 1-.8.9h1.4a.6.6 0 0 1 .4.1 1.208 1.208 0 0 1 .7.7v.4z"
			fill="currentColor"
			transform="translate(3.687 .8)"
		/>
		<g>
			<path
				d="M1115.6 806.1a3.854 3.854 0 0 0 3-3 4.047 4.047 0 0 0-3-4.9 3.4 3.4 0 0 0-1-.1h-7.1l-2.6-2.6c.1 0 .1-.1.2-.1a.367.367 0 0 0 .1-.3v-.8a.756.756 0 0 1 .1-.4v-.2l.1-.1a.1.1 0 0 0 .1-.1l.1-.1c.1 0 .1-.1.2-.1s.1-.1.2-.1l.1-.1.2-.1.4-.3a3.915 3.915 0 0 0 .8-1 6.119 6.119 0 0 0 .5-1.2 2.438 2.438 0 0 0 0-1.2 4.175 4.175 0 0 0-.4-1 2.41 2.41 0 0 0-1-1c-.1-.1-.2-.1-.3-.2-.1 0-.1 0-.2-.1h-.1a.43.43 0 0 0-.4.4v.4c0 .3-.1.6-.1 1 0 .2-.1.3-.1.4-.1.1-.1.2-.2.3l-.3.3-.3.3-.2.2-.1.1-.2.2-.1.1-.1.1a1.756 1.756 0 0 0-.4.5 2.181 2.181 0 0 1-.3.5c0 .1-.1.2-.1.3v.1c0 .1-.1.2-.1.3v1l-7.3-7.3-1.4 1.4.1.1.2-.2 10.6 10.6 8.1 8.1 5.6 5.6 1.2-1.2zm-2-2.3c.2-1.1.4-2.1.6-3.2h.5a1.579 1.579 0 0 1 1.6 1.6v.4a1.621 1.621 0 0 1-1.6 1.2z"
				fill="currentColor"
				transform="translate(-1094.3 -786.2)"
			/>
		</g>
	</svg>
)
