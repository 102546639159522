import { PropsOf } from '@emotion/react'
import styled from '@emotion/styled'
import { Relative } from '.'
import colors from '../colors'
import { CompanyLogoPlaceholder } from '../icons'

type Shape = 'CIRCLE' | 'SQUARE'

const Image = styled.img<{ shape: Shape }>`
	background-color: ${colors.white};
	border-radius: ${props => (props.shape === 'SQUARE' ? '10px' : '50%')};

	width: 100%;
`

const PlaceholderContainer = styled.div<{ shape: Shape }>`
	border-radius: ${props => (props.shape === 'SQUARE' ? '10px' : '50%')};
	background-color: ${colors.white};
	svg {
		height: 100%;
		width: 100%;
		max-width: 100%;
		max-height: 100%;
	}
`

type Props = PropsOf<typeof Relative> & {
	logoId?: string
	border?: string
	shape?: Shape
	size: string
	userApiUrl: string
}

export const CompanyLogo: React.FC<Props> = ({
	shape = 'SQUARE',
	size,
	border,
	logoId,
	children,
	style,
	userApiUrl,
	...rest
}) => {
	const getLogoUrl = (logoId?: string) => {
		if (logoId && logoId?.startsWith('http')) {
			return logoId
		}
		if (logoId) {
			return `${userApiUrl}/files/${logoId}`
		}
	}

	const logoUrl = getLogoUrl(logoId)

	return (
		<Relative
			{...rest}
			style={{
				background: colors.white,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: size,
				width: size,
				minWidth: size,
				borderRadius: shape === 'SQUARE' ? '10px' : '50%',
				...style,
			}}
		>
			{logoUrl ? (
				<Image
					shape={shape}
					src={logoUrl}
					style={{
						border: border,
					}}
				/>
			) : (
				<PlaceholderContainer shape={shape}>
					<CompanyLogoPlaceholder />
				</PlaceholderContainer>
			)}
			{children}
		</Relative>
	)
}
