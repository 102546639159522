import { colors, images } from '@beelday/common'
import styled from '@emotion/styled'
import breakIcon from './images/break.svg'
import quizIcon from './images/quiz.svg'
import groupingIcon from './images/grouping.svg'

const Icon = styled.div`
	align-items: center;
	border-radius: 8px;
	display: flex;
	justify-content: center;
`

const DEFAULT_SIZE = 28

type Props = {
	type:
		| 'BREAK'
		| 'QUIZ'
		| 'GROUPING'
		| 'POLLING'
		| 'POLLING_OPEN_ENDED_WORD_CLOUD'
		| 'POLLING_MULTIPLE_CHOICE'
		| 'POLLING_OPEN_ENDED_LIST'
		| 'SLIDE'
		| 'KNOWLEDGE_CHECK'
		| undefined
	size?: number
}

export const activityIconSrc = (type: Props['type']): string | undefined => {
	switch (type) {
		case 'BREAK':
			return breakIcon
		case 'QUIZ':
			return quizIcon
		case 'GROUPING':
			return groupingIcon
		case 'POLLING':
			return images.pollingTemplateIcon
		case 'POLLING_OPEN_ENDED_WORD_CLOUD':
			return images.pollingTemplateIcon
		case 'POLLING_MULTIPLE_CHOICE':
			return images.pollingTemplateIcon
		case 'POLLING_OPEN_ENDED_LIST':
			return images.pollingTemplateIcon
		case 'SLIDE':
			return images.slidesTemplateIcon
		case 'KNOWLEDGE_CHECK':
			return images.knowledgeCheckTemplateIcon
	}
}

export const ActivityIcon = ({ type, size }: Props): JSX.Element => {
	return type === 'BREAK' ? (
		<Icon
			style={{
				backgroundColor: colors.dustyOrange,
				width: `${size || DEFAULT_SIZE}px`,
				height: `${size || DEFAULT_SIZE}px`,
				flex: `0 0 ${size || DEFAULT_SIZE}px`,
			}}
		>
			<img src={activityIconSrc(type)} />
		</Icon>
	) : type === 'QUIZ' ? (
		<Icon
			style={{
				backgroundColor: colors.indigoBlue,
				width: `${size || DEFAULT_SIZE}px`,
				height: `${size || DEFAULT_SIZE}px`,
				flex: `0 0 ${size || DEFAULT_SIZE}px`,
			}}
		>
			<img src={activityIconSrc(type)} />
		</Icon>
	) : type === 'GROUPING' ? (
		<Icon
			style={{
				backgroundColor: colors.indigoBlue,
				width: `${size || DEFAULT_SIZE}px`,
				height: `${size || DEFAULT_SIZE}px`,
				flex: `0 0 ${size || DEFAULT_SIZE}px`,
			}}
		>
			<img src={activityIconSrc(type)} />
		</Icon>
	) : type === 'SLIDE' ? (
		<Icon
			style={{
				width: `${size || DEFAULT_SIZE}px`,
				height: `${size || DEFAULT_SIZE}px`,
				flex: `0 0 ${size || DEFAULT_SIZE}px`,
			}}
		>
			<img style={{ width: '100%' }} src={activityIconSrc(type)} />
		</Icon>
	) : type === 'KNOWLEDGE_CHECK' ? (
		<Icon
			style={{
				backgroundColor: colors.indigoBlue,
				width: `${size || DEFAULT_SIZE}px`,
				height: `${size || DEFAULT_SIZE}px`,
				flex: `0 0 ${size || DEFAULT_SIZE}px`,
			}}
		>
			<img src={activityIconSrc(type)} />
		</Icon>
	) : type === 'POLLING' ? (
		<Icon
			style={{
				backgroundColor: colors.indigoBlue,
				width: `${size || DEFAULT_SIZE}px`,
				height: `${size || DEFAULT_SIZE}px`,
				flex: `0 0 ${size || DEFAULT_SIZE}px`,
			}}
		>
			<img src={activityIconSrc(type)} />
		</Icon>
	) : type === 'POLLING_OPEN_ENDED_WORD_CLOUD' ? (
		<Icon
			style={{
				backgroundColor: colors.indigoBlue,
				width: `${size || DEFAULT_SIZE}px`,
				height: `${size || DEFAULT_SIZE}px`,
				flex: `0 0 ${size || DEFAULT_SIZE}px`,
			}}
		>
			<img src={activityIconSrc(type)} />
		</Icon>
	) : type === 'POLLING_OPEN_ENDED_LIST' ? (
		<Icon
			style={{
				backgroundColor: colors.indigoBlue,
				width: `${size || DEFAULT_SIZE}px`,
				height: `${size || DEFAULT_SIZE}px`,
				flex: `0 0 ${size || DEFAULT_SIZE}px`,
			}}
		>
			<img src={activityIconSrc(type)} />
		</Icon>
	) : type === 'POLLING_MULTIPLE_CHOICE' ? (
		<Icon
			style={{
				backgroundColor: colors.indigoBlue,
				width: `${size || DEFAULT_SIZE}px`,
				height: `${size || DEFAULT_SIZE}px`,
				flex: `0 0 ${size || DEFAULT_SIZE}px`,
			}}
		>
			<img src={activityIconSrc(type)} />
		</Icon>
	) : (
		<Icon
			style={{
				backgroundColor: colors.white,
				width: `${size || DEFAULT_SIZE}px`,
				height: `${size || DEFAULT_SIZE}px`,
				flex: `0 0 ${size || DEFAULT_SIZE}px`,
			}}
		/>
	)
}
