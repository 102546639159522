import { css } from '@emotion/react'
import { Droppable } from 'react-beautiful-dnd'
import { ui } from '@beelday/common'
import { BOTTOM } from 'room/training-room/set-groups/presentation/drop-areas'
import useResize from 'utils/hooks/use-resize'
import { Children } from 'react'

type Props = {
	droppable?: boolean
	center: JSX.Element
	children: JSX.Element[]
}

const MAX_ELEMENTS = 8

const BottomLayout = ({ droppable, center, children }: Props) => {
	const { ref: containerRef, width } = useResize()
	const elemWidth = width / MAX_ELEMENTS
	const elemHeight = (elemWidth * 9) / 12

	return (
		<div
			css={css`
				display: flex;
				align-items: center;
				flex-direction: column;
				height: 100%;
				width: 100%;
				flex-grow: 1;
			`}
		>
			<div
				css={css`
					display: flex;
					flex-direction: column;
					flex-grow: 1;
					margin-top: 65px;
					width: 75%;

					${ui.responsive.desktopFHD} {
						margin-top: 75px;
					}
				`}
				ref={containerRef}
			>
				{center}
			</div>
			<Droppable droppableId={BOTTOM} isDropDisabled={!droppable}>
				{provided => (
					<div
						css={css`
							display: flex;
							justify-content: center;
							margin-top: 20px;
						`}
						ref={provided.innerRef}
						{...provided.droppableProps}
					>
						{Children.map(children, elem => (
							<div
								css={css`
									margin: 5px;
									width: ${elemWidth - 10}px;
									height: ${elemHeight - 10}px;
								`}
							>
								{elem}
							</div>
						))}
					</div>
				)}
			</Droppable>
		</div>
	)
}

export default BottomLayout
