import { ui, images, UserId } from '@beelday/common'
import styled from '@emotion/styled'
import React, { ChangeEvent, KeyboardEvent, useCallback, useState } from 'react'
import { VideoColumns } from 'room/group-room/presentation/video-columns'
import { SceneVideoUser } from 'video-conference-media'
import { Config } from 'common/config'
import { getWordCloudApi } from './word-cloud-api'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { useSelector } from 'react-redux'
import { RootState } from 'common/redux'
import useFetchMissingProfiles from 'users/use-fetch-missing-profiles'
import { useProfilesFor } from 'users/redux'

const TwoColumnGrid = styled.div`
	display: flex;
	flex: 1;
	height: 100%;
	min-height: 0;
	width: 100%;
`

const ColumnLayout = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;
	margin-top: 20px;

	${ui.responsive.tablet} {
		flex: 0 0 300px;
		margin-left: 30px;
		margin-top: 0;
	}

	${ui.responsive.desktop} {
		flex: 0 0 400px;
	}
`

const WordCloudContainer = styled.div`
	background-color: ${ui.colors.white};
	border-radius: 7px;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 0 10px 20px;
	width: 100%;
	align-items: flex-start;

	${ui.responsive.desktop1440} {
		margin: 0 0 16px 30px;
	}
`

const Header = styled.div`
	padding: 20px 20px 10px 20px;
	font-size: 28px;
	text-align: left;
	font-weight: bold;
	flex-wrap: nowrap;
	width: 100%;

	${ui.responsive.desktop1440} {
		padding: 30px 30px 10px 30px;
		font-size: 28px;
	}
`

const InputContainer = styled.div`
	position: relative;
	font-size: 16px;
	width: 100%;
	display: flex;
`

const Input = styled.input`
	border: 0;
	border-top: 1px solid ${ui.colors.borderLightGray};
	padding: 11px 60px 11px 20px;
	border-radius: 0 0 0 7px;

	${ui.responsive.desktop1440} {
		padding: 24px 80px 24px 30px;
	}

	width: 100%;
	height: 100%;

	&:disabled {
		background-color: ${ui.colors.white};
	}

	&:focus {
		outline: none;
	}
`

const Statistics = styled.div`
	margin-top: 10px;
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 0 20px 10px;

	${ui.responsive.desktop1440} {
		margin-top: 30px;
		padding: 20px;
	}
`

const InputLength = styled.div`
	position: absolute;
	right: 100px;
	top: 50%;
	transform: translateY(-50%);
	color: ${ui.colors.heather};
	font-size: 16px;
	line-height: 1;
`

const Button = styled.button`
	align-items: center;
	background-color: ${ui.colors.greenishCyan};
	border: none;
	border-radius: 0 0 7px 0;
	cursor: pointer;
	display: flex;
	justify-content: center;
	min-width: 50px;
	padding: 15px 0;
	transition: opacity 0.25s;

	${ui.responsive.desktop1440} {
		min-width: 68px;
		padding: 18px 0;
	}

	&:disabled {
		pointer-events: none;
		opacity: 0.5;
	}
`

const StatisticsNumber = styled.div`
	margin-left: 10px;
	font-size: 16px;
	line-height: 17px;

	b,
	strong {
		font-weight: 700;
		font-size: 16px;
		line-height: 17px;
	}
`

type Props = {
	streams: SceneVideoUser[]
	isTrainer: boolean
}

const WordCloudPage = ({ streams, isTrainer }: Props): JSX.Element | null => {
	const wordCloudApi = getWordCloudApi(Config.beeldayBackendUrl)
	const roomAddress = useAssertedJoinedRoomAddress()
	const [word, setWord] = useState<string>('')
	const [answeredUserIds, setAnsweredUserIds] = useState<UserId[]>([])
	const answers = useSelector((state: RootState) => state.wordCloud.answers)
	const allUsers = useSelector((state: RootState) => state.wordCloud.allUsers)
	const userAnswered = useSelector(
		(state: RootState) => state.wordCloud.answerers
	)
	const pollingDefinition = useSelector(
		(state: RootState) => state.wordCloud.pollingDefinition
	)

	useFetchMissingProfiles(answeredUserIds || [])
	const profiles = useProfilesFor(answeredUserIds || [])
	const userAnswerProfiles = useProfilesFor(userAnswered || [])

	const onSubmit = useCallback(() => {
		if (pollingDefinition) {
			wordCloudApi.sendAnswer(roomAddress, pollingDefinition.id, word)
		}
		setWord('')
	}, [pollingDefinition, wordCloudApi, roomAddress, word])

	const keyDownListener = useCallback(
		(e: KeyboardEvent<HTMLInputElement>) => {
			if (e.key === 'Enter' && word) {
				onSubmit()
				e.preventDefault()
			}
		},
		[onSubmit, word]
	)

	const onWordMouseOver = useCallback(e => {
		setAnsweredUserIds(e)
	}, [])

	const onWordMouseOut = useCallback(() => {
		setAnsweredUserIds([])
	}, [])

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		const limit = 25
		setWord(event.target.value.slice(0, limit))
	}

	return pollingDefinition ? (
		<>
			{answeredUserIds.length && isTrainer ? (
				<ui.UsersTooltip profiles={profiles} />
			) : null}
			<TwoColumnGrid>
				<WordCloudContainer>
					<Header>{pollingDefinition.question}</Header>
					<MemoizedWordCloud
						answers={answers}
						onWordMouseOver={onWordMouseOver}
						onWordMouseOut={onWordMouseOut}
					/>
					<Statistics>
						<ui.ProfileAvatars
							size={'32px'}
							profiles={userAnswerProfiles}
							userApiUrl={Config.USER_API_URL}
						/>
						<StatisticsNumber>
							<b>{userAnswered.length}</b>/{allUsers.length} participants
							answered
						</StatisticsNumber>
					</Statistics>

					{isTrainer && !pollingDefinition.letFacilitatorAnswer ? (
						<Statistics>
							<ui.ProfileAvatars
								size={'32px'}
								profiles={userAnswerProfiles}
								userApiUrl={Config.USER_API_URL}
							/>
							<StatisticsNumber>
								<b>{userAnswered.length}</b>/{allUsers.length} participants
								answered
							</StatisticsNumber>
						</Statistics>
					) : (
						<InputContainer>
							<Input
								type="text"
								onKeyDown={keyDownListener}
								value={word}
								onChange={handleInputChange}
								placeholder={
									!pollingDefinition.allowForMultipleAnswer && userAnswered
										? 'You have already given an answer'
										: 'Answer'
								}
								disabled={
									!pollingDefinition.allowForMultipleAnswer &&
									!!userAnswered.length
								}
							/>
							<InputLength>{word.length}/25</InputLength>
							<Button
								onClick={() => onSubmit()}
								disabled={
									(!pollingDefinition.allowForMultipleAnswer &&
										!!userAnswered.length) ||
									!word
								}
							>
								<img src={images.send} />
							</Button>
						</InputContainer>
					)}
				</WordCloudContainer>
				<ColumnLayout>
					<VideoColumns sceneVideoUsers={streams} />
				</ColumnLayout>
			</TwoColumnGrid>
		</>
	) : null
}

const MemoizedWordCloud = React.memo(ui.WordCloud)

export default WordCloudPage
