import { GrowthColumnContainer } from 'common/presentation/containers'
import { PublishBitrates } from 'common/publish-bitrates'
import { ui } from '@beelday/common'
import { RootState } from 'common/redux'
import { TrainingRoomPhase } from 'common/types'
import { VotingResultsModal, useQuestions } from 'features/questions'
import { ScriptNavigation } from 'features/script'
import { FC, useEffect } from 'react'
import { use100vh } from 'react-div-100vh'
import { useDispatch, useSelector } from 'react-redux'
import { RoomContainer } from 'room/common/presentation/room-container'
import RoomHeader from 'room/common/presentation/room-header'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { MiscPanel } from 'room/misc'
import { ErrorWithStreams } from 'room/common/error-with-streams'
import ConfiguredReviewTask from 'room/training-room/review-tasks/configured-review-trainer'
import { SelectGroupPanelTrainerRedux } from 'room/training-room/set-groups/select-group-panel-trainer'
import TrainerMenu from 'room/training-room/trainer-menu'
import { PresenterStyle } from 'video-conference-media/presentation/presenter-grid-layout'
import { SelfStreamView } from 'video-conference-media/presentation/top-right-stream-view'
import { VideoGrid } from 'video-conference-media/presentation/video-grid'
import {
	othersStream,
	selectLocalScreenShareStream,
	selectPublishBitRate,
	setPublishBitrate,
} from 'video-conference-media/redux'
import { selectFocusedFeature } from './redux'
import { TrainerFocusedFeature } from './trainer-focused-feature'

export const RoomTrainer: FC = () => {
	const containerHeight = use100vh()

	return (
		<RoomContainer
			style={{
				height: `${containerHeight}px`,
			}}
		>
			<RoomHeader streamView={<SelfStreamView />}>
				<ScriptNavigation />
			</RoomHeader>
			<MainContents presenterStyle="distinguished" />

			<MiscPanel />
			<ui.BeeldayLogoAbsoluteBottom />
		</RoomContainer>
	)
}

const MainContents: FC<{ presenterStyle: PresenterStyle }> = ({
	presenterStyle,
}) => {
	const questions = useQuestions()
	const roomAddress = useAssertedJoinedRoomAddress()
	const dispatch = useDispatch()
	const trainingRoomPhase = useSelector(
		(state: RootState) => state.room.trainingRoomPhase
	)
	const focusedFeature = useSelector(selectFocusedFeature)
	const sceneVideoUsers = useSelector(othersStream)
	const userCount = sceneVideoUsers.length
	const currentBitrate = useSelector(selectPublishBitRate)
	const isLocalScreenShare = !!useSelector(selectLocalScreenShareStream)

	const showingFocusedFeature =
		focusedFeature && trainingRoomPhase === TrainingRoomPhase.INITIAL

	useEffect(() => {
		let bitrate = PublishBitrates.low
		if (userCount === 0) {
			//Nobody else in the room, no need to stream high quality
			bitrate = PublishBitrates.low
		} else if (isLocalScreenShare) {
			bitrate = PublishBitrates.screenShare
		} else if (
			!showingFocusedFeature &&
			trainingRoomPhase === TrainingRoomPhase.INITIAL
		) {
			bitrate = PublishBitrates.high
		}

		if (bitrate !== currentBitrate) {
			dispatch(setPublishBitrate(bitrate))
		}
	}, [
		dispatch,
		currentBitrate,
		isLocalScreenShare,
		showingFocusedFeature,
		trainingRoomPhase,
		userCount,
	])

	switch (trainingRoomPhase) {
		case TrainingRoomPhase.INITIAL:
			return (
				<>
					{showingFocusedFeature ? (
						<TrainerFocusedFeature streams={sceneVideoUsers} />
					) : (
						<>
							{questions.active ? (
								<VotingResultsModal
									roomAddress={roomAddress}
									totalNumberOfUsers={sceneVideoUsers.length}
								/>
							) : null}
							<VideoGrid
								sceneVideoUsers={sceneVideoUsers}
								presenterStyle={presenterStyle}
							/>
						</>
					)}
					<TrainerMenu />
				</>
			)
		case TrainingRoomPhase.REVIEWING_TASKS:
			return <ConfiguredReviewTask />
		case TrainingRoomPhase.SELECTING_GROUPS:
			return (
				<>
					<GrowthColumnContainer>
						<SelectGroupPanelTrainerRedux />
					</GrowthColumnContainer>
					<TrainerMenu />
				</>
			)
		default:
			return <ErrorWithStreams />
	}
}
