import styled from '@emotion/styled'
import colors from '../colors'
import cancel from '../images/cancel2.svg'

const Button = styled.div`
	width: 32px;
	height: 32px;
	background-color: ${colors.brightGray};
	border-radius: 16px;
	padding: 0;
	border: none;
	cursor: pointer;

	&:focus {
		outline: none;
	}
`

const Img = styled.img`
	height: 100%;
	width: 100%;
`

type Props = {
	onClick: () => void
}

const CloseButton = ({ onClick }: Props): JSX.Element => (
	<Button onClick={onClick}>
		<Img src={cancel} />
	</Button>
)

export default CloseButton
