import styled from '@emotion/styled'
import { ui } from '@beelday/common'
import { FC } from 'react'

const Container = styled.div`
	display: flex;
	flex: 1 0 50px;
	flex-flow: row nowrap;
	justify-content: center;
	margin-bottom: 10px;
`

const Image = styled.img`
	width: auto;
	height: auto;
	max-width: 60%;
	max-height: 170px;
	border-radius: 10px;
	object-fit: cover;

	${ui.responsive.desktop1440} {
		max-height: 200px;
	}
`

type Props = {
	imageUrl: string
}

const QuestionImage: FC<Props> = ({ imageUrl }) => {
	return (
		<Container>
			<Image src={imageUrl} />
		</Container>
	)
}

export default QuestionImage
