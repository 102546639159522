import { parse } from 'date-fns'
import { Training } from 'session-info/training'
import { TimeZoneNames, TrainingDTO } from './model'

export const dtoToTraining = (dto: TrainingDTO): Training => {
	return {
		...dto,
		id: dto.id,
		subject: dto.name,
		startDate: parse(dto.startDate, 'yyyy-MM-dd', new Date()),
		timezone: dto.timezone as TimeZoneNames,
		organizationId: dto.organizationId,
	}
}
