import { PropsOf } from '@emotion/react'
import { motion } from 'framer-motion'

type Props = PropsOf<typeof motion.div> & {
	visible: boolean
}
export const SlideDown: React.FC<Props> = ({ children, visible, ...rest }) => (
	<motion.div
		animate={{
			opacity: visible ? 1 : 0,
			height: visible ? 'auto' : 0,
			overflow: 'hidden',
		}}
		initial={false}
		{...rest}
	>
		{children}
	</motion.div>
)

export default SlideDown
