import { FC } from 'react'
import ThreeColumnsVideoGrid from 'room/training-room/ice-breaker/presentation/three-columns-video-grid'
import styled from '@emotion/styled'
import ProgressBar from 'room/training-room/ice-breaker/presentation/progress-bar'
import { Colors } from 'common/presentation/colors'
import { SceneVideoUser } from 'video-conference-media'

type Progress = {
	currentValue: number
	maxValue: number
}

type Props = {
	title: JSX.Element
	progress?: Progress
	sceneVideoUsers: SceneVideoUser[]
}

const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-evenly;
	align-items: stretch;
`

const TitleRow = styled.div`
	color: ${Colors.indigoBlue};
	font-family: Ubuntu;
	font-weight: 500;
	font-size: 14px;
	line-height: 1;
	margin-bottom: 10px;
`

const ProgressRow = styled.div`
	height: 6px;
	margin-bottom: 10px;
`

const Content = styled.div`
	flex-grow: 1;
	display: flex;
`

const ProgressingIceBreaker: FC<Props> = ({
	title,
	progress,
	sceneVideoUsers,
	children,
}) => {
	return (
		<ThreeColumnsVideoGrid sceneVideoUsers={sceneVideoUsers}>
			<Container>
				<TitleRow>
					{title}
					{progress && ` ${progress.currentValue} / ${progress.maxValue}`}
				</TitleRow>
				<ProgressRow>
					{progress ? (
						<ProgressBar
							value={progress.currentValue}
							max={progress.maxValue}
						/>
					) : (
						<ProgressBar value={1} max={1} />
					)}
				</ProgressRow>
				<Content>{children}</Content>
			</Container>
		</ThreeColumnsVideoGrid>
	)
}

export default ProgressingIceBreaker
