import { intl, ui } from '@beelday/common'
import styled from '@emotion/styled'
import { Config } from 'common/config'
import { RootState } from 'common/redux'
import { useSelector } from 'react-redux'
import { useAssertedWorkflowUser } from 'room/common/use-workflow-user'
import { VideoColumns } from 'room/group-room/presentation/video-columns'
import { useProfileFor } from 'users/redux'
import { SceneVideoUser } from 'video-conference-media'
import { useNotepads } from './use-notepads'

const TwoColumnGrid = styled.div`
	display: flex;
	flex: 1;
	height: 100%;
	min-height: 0;
	width: 100%;
`

const ColumnLayout = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;
	margin-top: 20px;

	${ui.responsive.tablet} {
		flex: 0 0 300px;
		margin-left: 30px;
		margin-top: 0;
	}

	${ui.responsive.desktop} {
		flex: 0 0 400px;
	}
`

const NotepadIFrame = styled.iframe`
	width: 100%;
	height: 100%;
	border: none;
`

const NotepadContainer = styled.div`
	width: 100%;
	height: 100%;
	padding: 0 20px 20px 20px;
`

export const ConfiguredNotepad = ({
	isTrainer,
	streams,
}: {
	isTrainer: boolean
	streams: SceneVideoUser[]
}): JSX.Element => {
	const translate = intl.useTranslate()
	const { changeCollaboration } = useNotepads()
	const workflowUser = useAssertedWorkflowUser()
	const workflowUserProfile = useProfileFor(workflowUser.id)

	const notepadDefinition = useSelector(
		(state: RootState) => state.notepads.notepadDefinition
	)

	const sessionId = useSelector((state: RootState) => state.notepads.sessionId)

	let keyId = undefined
	let isViewMode = false

	if (notepadDefinition?.collaborators && workflowUserProfile?.id) {
		isViewMode = !notepadDefinition.collaborators.includes(
			workflowUserProfile.id
		)
	}

	if (notepadDefinition) {
		keyId = isViewMode
			? notepadDefinition.readOnlyNotepadId
			: notepadDefinition.writableNotepadId
	}

	return (
		<TwoColumnGrid>
			<NotepadContainer>
				{sessionId && notepadDefinition ? (
					<NotepadIFrame
						key={keyId}
						title="EtherPadIFrame"
						src={`${Config.etherPadUrl}/p/${
							isViewMode
								? notepadDefinition.readOnlyNotepadId
								: notepadDefinition.writableNotepadId
						}?sessionID=${sessionId}`}
					/>
				) : null}
			</NotepadContainer>
			<ColumnLayout>
				{notepadDefinition && isTrainer ? (
					<ui.FlexRow style={{ marginBottom: '30px' }}>
						<ui.CheckboxSwitcher
							id="collaborationSwitcher"
							name="collaborationSwitcher"
							checked={notepadDefinition.collaborators.length > 1}
							onChange={val =>
								changeCollaboration(notepadDefinition.writableNotepadId, val)
							}
							text={translate('features.whiteboard.switcher')}
						/>
					</ui.FlexRow>
				) : null}
				<VideoColumns sceneVideoUsers={streams} />
			</ColumnLayout>
		</TwoColumnGrid>
	)
}

export default ConfiguredNotepad
