import { intl, ui } from '@beelday/common'
import { Excalidraw } from '@pragmatic-brains/excalidraw'
import { Config } from 'common/config'
import { useWhiteboards } from 'features/whiteboard'
import { Whiteboard } from 'features/whiteboard/whiteboard-models'
import { FC } from 'react'
import { useMyProfile } from 'users'

type ReviewNotepadProps = {
	whiteboardDefinition: Whiteboard
}

export const ReviewWhiteboard: FC<ReviewNotepadProps> = props => {
	let isViewMode = false
	const user = useMyProfile()

	if (props.whiteboardDefinition?.collaborators && user.id) {
		isViewMode = !props.whiteboardDefinition.collaborators.includes(user.id)
	}

	return (
		<div style={{ width: '100%', height: '100%' }}>
			<Excalidraw
				key={props.whiteboardDefinition.whiteboardId}
				viewModeEnabled={isViewMode}
				collabServerUrl={Config.EXCALIDRAW_SERVER_URL}
				assetsServerUrl={Config.EXCALIDRAW_ASSETS_SERVER_URL}
				collabRoom={{ id: props.whiteboardDefinition.whiteboardId }}
				isCollaborating={true}
				allowForceFollow={user.isTrainer}
				user={user}
			/>
		</div>
	)
}

type ReviewCollabSwitcherProps = { whiteboardDefinition: Whiteboard }

export const ReviewWhiteboardCollabSwitcher: FC<ReviewCollabSwitcherProps> =
	props => {
		const translate = intl.useTranslate()
		const { changeCollaboration } = useWhiteboards()
		return (
			<ui.FlexRow style={{ marginBottom: '30px' }}>
				<ui.CheckboxSwitcher
					id="collaborationWhiteboardSwitcher"
					name="collaborationWhiteboardSwitcher"
					checked={props.whiteboardDefinition.collaborators.length > 1}
					onChange={val =>
						changeCollaboration(props.whiteboardDefinition.whiteboardId, val)
					}
					text={translate('features.whiteboard.switcher')}
				/>
			</ui.FlexRow>
		)
	}
