import React, {
	ChangeEvent,
	KeyboardEvent,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react'
import styled from '@emotion/styled'
import { colors, images, ui, UUID } from '@beelday/common'
import { Answer, PollingListQuestion } from './list-polling-models'
import { useProfileFor } from 'users/redux'
import ProfileAvatar from 'users/profile-avatar'
import { useAssertedWorkflowUser } from 'room/common/use-workflow-user'
import { getDisplayName } from '@beelday/common/src/user-api'

const InputContainer = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	margin-top: auto;
	padding-top: 10px;
`

const Input = styled.input`
	padding: 11px 15px 11px 15px;
	border-radius: 0 0 0 7px;
	border: 1px solid ${colors.heather30};
	width: 100%;
	height: 100%;
	font-size: 14px;
	line-height: 1;

	${ui.responsive.desktop1440} {
		padding: 20px 15px 20px 15px;
	}

	&:disabled {
		background-color: ${ui.colors.white};
	}

	&:focus {
		outline: none;
	}
`

const Button = styled.button`
	align-items: center;
	background-color: ${ui.colors.greenishCyan};
	border: none;
	border-radius: 0 0 7px 0;
	cursor: pointer;
	display: flex;
	justify-content: center;
	min-width: 50px;
	padding: 15px 0;
	transition: opacity 0.25s;

	${ui.responsive.desktop1440} {
		min-width: 68px;
		padding: 18px 0;
	}

	img {
		width: 20px;
		height: 20px;
	}

	&:hover {
		opacity: 0.7;
	}

	&:disabled {
		pointer-events: none;
		opacity: 0.5;
	}
`

type Props = {
	answers?: Answer[]
	question: PollingListQuestion
	isTrainer: boolean
	letFacilitatorAnswer: boolean
	allowForMultipleAnswer: boolean
	onSubmit: (questionId: UUID, text: string) => unknown
}

const ListPollingChat = ({
	question,
	answers,
	isTrainer,
	letFacilitatorAnswer,
	allowForMultipleAnswer,
	onSubmit,
}: Props): JSX.Element | null => {
	const containerRef = useRef<ui.ListPollingContainerRef>(null)
	const [word, setWord] = useState<string>('')
	const currentUser = useAssertedWorkflowUser()

	const userAnswered = !!answers?.find(
		answer => answer.userId === currentUser.id
	)

	const keyDownListener = useCallback(
		(e: KeyboardEvent<HTMLInputElement>) => {
			if (e.key === 'Enter' && word) {
				onSubmit(question.id, word)
				setWord('')
				e.preventDefault()
			}
		},
		[onSubmit, question.id, word]
	)

	useEffect(() => {
		if (containerRef.current) {
			containerRef.current.scrollToBottom()
		}
	}, [answers])

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		setWord(event.target.value)
	}

	return (
		<ui.ListPollingChatContainer>
			<ui.ListPollingHeader>{question.text}</ui.ListPollingHeader>
			<ui.ListPollingScrollContainer ref={containerRef}>
				{answers
					? answers?.map((answer, index) => (
							<SingleAnswer key={`${answer.userId}_${index}`} answer={answer} />
					  ))
					: null}
			</ui.ListPollingScrollContainer>

			{isTrainer && !letFacilitatorAnswer ? null : (
				<InputContainer>
					<Input
						type="text"
						onKeyDown={keyDownListener}
						value={word}
						onChange={handleInputChange}
						placeholder={
							!allowForMultipleAnswer && userAnswered
								? 'You have already given an answer'
								: 'Type your answer here…'
						}
						disabled={!allowForMultipleAnswer && userAnswered}
					/>
					<Button
						disabled={(!allowForMultipleAnswer && userAnswered) || !word}
						onClick={() => {
							onSubmit(question.id, word)
							setWord('')
						}}
					>
						<img src={images.send} alt={'send image'} />
					</Button>
				</InputContainer>
			)}
		</ui.ListPollingChatContainer>
	)
}

const SingleAnswer = ({ answer }: { answer: Answer }): JSX.Element | null => {
	const profile = useProfileFor(answer.userId)
	return (
		<ui.ListPollingAnswer
			name={getDisplayName(profile)}
			text={answer.text}
			avatar={<ProfileAvatar profile={profile} />}
		/>
	)
}

export default ListPollingChat
