import { FC } from 'react'
import { intl, ui } from '@beelday/common'
import { Config } from 'common/config'
import styled from '@emotion/styled'
import { Notepad } from 'features/notepad/notepad-models'
import { UserId } from '@beelday/common'
import { useNotepads } from 'features/notepad'

type ReviewNotepadProps = {
	notepadDefinition: Notepad
	sessionId: string
	userId?: UserId
}

const NotepadIFrame = styled.iframe`
	width: 100%;
	height: 100%;
	border: none;
`

export const ReviewNotepad: FC<ReviewNotepadProps> = props => {
	let isViewMode = false
	let keyId = undefined

	if (props.notepadDefinition?.collaborators && props.userId) {
		isViewMode = !props.notepadDefinition.collaborators.includes(props.userId)
	}

	if (props.notepadDefinition) {
		keyId = isViewMode
			? props.notepadDefinition.readOnlyNotepadId
			: props.notepadDefinition.writableNotepadId
	}

	return (
		<NotepadIFrame
			key={keyId}
			title="EtherPadIFrame"
			src={`${Config.etherPadUrl}/p/${
				isViewMode
					? props.notepadDefinition.readOnlyNotepadId
					: props.notepadDefinition.writableNotepadId
			}?sessionID=${props.sessionId}`}
		/>
	)
}

type ReviewCollabSwitcherProps = { notepadDefinition: Notepad }

export const ReviewNotepadCollabSwitcher: FC<ReviewCollabSwitcherProps> =
	props => {
		const translate = intl.useTranslate()
		const { changeCollaboration } = useNotepads()
		return (
			<ui.FlexRow style={{ marginBottom: '30px' }}>
				<ui.CheckboxSwitcher
					id="collaborationNotepadSwitcher"
					name="collaborationNotepadSwitcher"
					checked={props.notepadDefinition.collaborators.length > 1}
					onChange={val =>
						changeCollaboration(props.notepadDefinition.writableNotepadId, val)
					}
					text={translate('features.notepad.switcher')}
				/>
			</ui.FlexRow>
		)
	}
