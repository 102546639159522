import { GroupId, ReviewUser, UserId } from 'common/types'
import { css } from '@emotion/react'
import { motion } from 'framer-motion'
import ReviewSceneVideoUser from './review-task-scene-video'
import { SceneVideoUser } from 'video-conference-media'

export type MotionStream = {
	defaultValues: {
		width: number
		height: number
		mediaLeft: number
		mediaTop: number
	}
	activeValues: {
		width: number
		height: number
		mediaLeft: number
		mediaTop: number
	}
	groupSize: number
	index: number
	columnIndex?: number
	groupIndex: number
	left?: boolean
	groupMember: ReviewUser
}

const MotionStreamVideo: React.FC<{
	user: ReviewUser
	defaultValues: {
		width: number
		height: number
		mediaLeft: number
		mediaTop: number
	}
	activeValues: {
		width: number
		height: number
		mediaLeft: number
		mediaTop: number
	}
	isActive?: boolean
	videoUsers: Record<UserId, SceneVideoUser>
	showOverlay: boolean
	onHover: (group: string | null) => unknown
	onSelect?: (groupId: GroupId | null) => unknown
}> = ({
	isActive,
	user,
	defaultValues,
	activeValues,
	videoUsers,
	showOverlay,
	onHover,
	onSelect,
}) => {
	let positionLeft = defaultValues.mediaLeft
	let positionTop = defaultValues.mediaTop

	let mediaHeight = defaultValues.height
	let mediaWidth = defaultValues.width

	const dashedPlaceTop = positionTop
	const dashedPlaceLeft = positionLeft

	if (isActive) {
		positionLeft = activeValues.mediaLeft
		positionTop = activeValues.mediaTop
		mediaWidth = activeValues.width
		mediaHeight = activeValues.height
	}

	return (
		<>
			<div
				css={css`
					position: absolute;
					width: ${defaultValues.width}px;
					height: ${defaultValues.height}px;
					top: ${dashedPlaceTop}px;
					left: ${dashedPlaceLeft}px;
					border: 2px dashed darkgrey;
					border-radius: 5px;
				`}
			/>
			<motion.div
				css={css`
					position: absolute;
					cursor: ${isActive || !user.group || !onSelect
						? 'default'
						: 'pointer'};
					padding: ${isActive ? '5px' : '0px'};
				`}
				initial={false}
				animate={{
					width: mediaWidth,
					height: mediaHeight,
					top: positionTop,
					left: positionLeft,
				}}
				transition={{ ease: 'easeOut', duration: 0.3 }}
				onMouseEnter={() => onHover(user.group?.id || null)}
				onMouseLeave={() => onHover('')}
				onClick={() => onSelect?.(user.group?.id || null)}
			>
				{videoUsers[user.id] && (
					<ReviewSceneVideoUser user={user} videoUser={videoUsers[user.id]} />
				)}
			</motion.div>
			<div
				css={css`
					position: absolute;
					width: ${defaultValues.width}px;
					height: ${defaultValues.height}px;
					top: ${dashedPlaceTop}px;
					left: ${dashedPlaceLeft}px;
					background: #5119be;
					pointer-events: none;
					border-radius: 5px;
					transition: opacity 0.25s;
					opacity: ${!isActive && showOverlay && user.group && onSelect
						? '0.2'
						: '0'};
				`}
			/>
		</>
	)
}

export default MotionStreamVideo
