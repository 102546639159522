export const ChevronDown: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="12.96"
		height="6.552"
		viewBox="0 0 12.96 6.552"
	>
		<path
			d="M1260.332,26l6,5,6-5"
			transform="translate(-1259.852 -25.424)"
			fill="none"
			stroke="currentColor"
			strokeWidth="1.5"
		/>
	</svg>
)
