import styled from '@emotion/styled'
import { FC } from 'react'
import beeldayLogoBackGround from '../images/beelday-logo-background.svg'
import beeldayLogo from '../images/beelday-logo.svg'
import { Relative } from './containers'

const BeeldayLogoBackGroundStyle = styled.img`
	min-width: 141px;
	min-height: 51px;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
`
const BeeldayLogoStyle = styled.img`
	position: absolute;
	top: 12px;
	left: 12px;
	bottom: 12px;
	right: 12px;
`

export const BeeldayLogoSmall: FC<{ className?: string }> = ({ className }) => (
	<Relative className={className}>
		<BeeldayLogoBackGroundStyle src={beeldayLogoBackGround} />
		<BeeldayLogoStyle src={beeldayLogo} alt="Beelday" />
	</Relative>
)

export const BeeldayLogoAbsoluteBottom: FC<{ className?: string }> = ({
	className,
}) => (
	<div
		style={{
			position: 'absolute',
			bottom: '0px',
			left: '0px',
			maxWidth: '141px',
			width: '141px',
			height: '48px',
			maxHeight: '48px',
		}}
	>
		<Relative className={className}>
			<BeeldayLogoBackGroundStyle
				src={beeldayLogoBackGround}
				style={{ transform: 'rotate(180deg)' }}
			/>
			<BeeldayLogoStyle src={beeldayLogo} alt="Beelday" />
		</Relative>
	</div>
)
