export const StandardListIcon: React.FC = () => (
	<svg
		version="1.1"
		id="Layer_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 32 28.8"
		xmlSpace="preserve"
	>
		<g id="Group_28043" transform="translate(810 426.2)">
			<path
				fill="currentColor"
				d="M-789.1-407.7h-4.3c-1.8,0-3.2-1.4-3.2-3.2v-4.3c0-0.8,0.3-1.7,0.9-2.3l5.6-5.6h-16.8
		c-1.8,0-3.2,1.4-3.2,3.2v19.2c0,1.8,1.4,3.2,3.2,3.2c0,0,0,0,0,0h25.6c1.8,0,3.2-1.4,3.2-3.2c0,0,0,0,0,0v-16.8l-8.8,8.8
		C-787.4-408-788.2-407.7-789.1-407.7L-789.1-407.7z"
			/>
			<path
				id="Path_12691"
				fill="currentColor"
				d="M-793.3-410.9h4.3l10.6-10.6c0.6-0.6,0.6-1.6,0-2.3l0,0l-2-2c-0.6-0.6-1.6-0.6-2.3,0l0,0
		l-10.6,10.6L-793.3-410.9z"
			/>
		</g>
	</svg>
)
