import styled from '@emotion/styled'
import { FC, ReactElement } from 'react'
import MediaQuery from 'react-responsive'
import { Link } from 'react-router-dom'
import colors from '../colors'
import responsive from './responsive'

const MobileBanner = styled.div`
	align-items: center;
	background-color: ${colors.white};
	border-top: 1px solid ${colors.darkGray30};
	display: flex;
	font-family: Ubuntu;
	font-size: 14px;
	font-weight: 500;
	justify-content: center;
	padding: 24px 0px;
	margin-top: 60px;
	width: 100%;
	text-align: center;
	z-index: 100;

	a {
		text-decoration: none;
		text-align: center;
		color: ${colors.indigoBlue};
	}
`

export const MobileBottomBanner: FC<{
	text: ReactElement | string
	linkText: ReactElement | string
	linkPath: string
}> = ({ text, linkText, linkPath }) => (
	<MediaQuery maxWidth={responsive.TABLET_WIDTH}>
		<MobileBanner>
			{text}
			<Link style={{ marginLeft: '5px' }} to={linkPath}>
				{linkText}
			</Link>
		</MobileBanner>
	</MediaQuery>
)
