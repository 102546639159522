import {
	GroupId,
	GroupSuggestionAssignment,
	GroupSuggestionAssignmentUser,
	User,
} from 'common/types'

export class GroupsSuggestionAssignment {
	private readonly groupsSuggestionAssignment: Array<GroupSuggestionAssignment>

	constructor(groupsSuggestionAssignment: Array<GroupSuggestionAssignment>) {
		const groupsSuggestionAssignmentWithSortedUsers =
			groupsSuggestionAssignment.map(groupSuggestionAssignment => {
				return {
					...groupSuggestionAssignment,
					users: groupSuggestionAssignment.users.sort(
						GroupsSuggestionAssignment.usersComparator()
					),
				}
			})
		this.groupsSuggestionAssignment =
			groupsSuggestionAssignmentWithSortedUsers.sort(
				GroupsSuggestionAssignment.numberAsAStringComparator()
			)
	}

	private static numberAsAStringComparator() {
		return (a: GroupSuggestionAssignment, b: GroupSuggestionAssignment) =>
			a.groupDescription.name.length < b.groupDescription.name.length ||
			(a.groupDescription.name.length === b.groupDescription.name.length &&
				a.groupDescription.name < b.groupDescription.name)
				? -1
				: 1
	}

	private static usersComparator() {
		return (
			a: GroupSuggestionAssignmentUser,
			b: GroupSuggestionAssignmentUser
		) => (a.userId < b.userId ? -1 : 1)
	}

	get getGroupsSuggestionAssignment() {
		return this.groupsSuggestionAssignment
	}

	isUserSelectedGroupFinal(userId: string) {
		return (
			this.groupsSuggestionAssignment.find(
				groupSuggestionAssignment =>
					groupSuggestionAssignment.users.find(
						user => user.userId === userId && user.final
					) !== undefined
			) !== undefined
		)
	}

	getUserSelectedGroupId(userId: string) {
		const userGroup = this.groupsSuggestionAssignment.find(
			groupSuggestionAssignment =>
				groupSuggestionAssignment.users.find(user => user.userId === userId) !==
				undefined
		)
		return userGroup?.groupDescription.id
	}

	getUserSelectedGroup(userId: string) {
		const userGroup = this.groupsSuggestionAssignment.find(
			groupSuggestionAssignment =>
				groupSuggestionAssignment.users.find(user => user.userId === userId) !==
				undefined
		)
		return userGroup?.groupDescription
	}

	getGroupName(groupId: GroupId) {
		const group = this.groupsSuggestionAssignment.find(
			groupSuggestionAssignment =>
				groupSuggestionAssignment.groupDescription.id === groupId
		)
		return group?.groupDescription.name
	}

	getGroupColor(groupId: GroupId) {
		const group = this.groupsSuggestionAssignment.find(
			groupSuggestionAssignment =>
				groupSuggestionAssignment.groupDescription.id === groupId
		)
		return group?.groupDescription.color
	}

	removeUserFromGroups(userId: string) {
		return new GroupsSuggestionAssignment(
			this.groupsSuggestionAssignment.map(groupSuggestionAssignment => {
				return {
					...groupSuggestionAssignment,
					users: groupSuggestionAssignment.users.filter(
						user => user.userId !== userId
					),
				}
			}) as GroupSuggestionAssignment[]
		)
	}

	addUserToGroup(userId: string, groupId: GroupId) {
		const selectedGroup = this.groupsSuggestionAssignment.find(
			groupSuggestionAssignment =>
				groupSuggestionAssignment.groupDescription.id === groupId
		)
		if (!selectedGroup) throw new Error(`There is no group with id: ${groupId}`)

		const selectedGroupUsers = [...selectedGroup.users]
		selectedGroupUsers.push({ userId, final: false })

		return new GroupsSuggestionAssignment(
			this.groupsSuggestionAssignment.map(groupSuggestionAssignment => {
				return {
					...groupSuggestionAssignment,
					users:
						groupSuggestionAssignment.groupDescription.id === groupId
							? selectedGroupUsers
							: [...groupSuggestionAssignment.users],
				}
			}) as GroupSuggestionAssignment[]
		)
	}

	changeUserGroup(userId: string, groupId?: GroupId) {
		const groupsSuggestionAssignmentWithoutUser =
			this.removeUserFromGroups(userId)
		return groupId
			? groupsSuggestionAssignmentWithoutUser.addUserToGroup(userId, groupId)
			: groupsSuggestionAssignmentWithoutUser
	}

	finalizeUsersGroupSelection(userId: string) {
		const selectedGroup = this.groupsSuggestionAssignment.find(
			groupSuggestionAssignment =>
				groupSuggestionAssignment.users.find(
					groupSuggestionAssignmentUser =>
						groupSuggestionAssignmentUser.userId === userId
				) !== undefined
		)
		if (!selectedGroup)
			throw new Error(`There is no group with user of id: ${userId}`)

		const selectedGroupUsers = selectedGroup.users.map(
			groupSuggestionAssignmentUser =>
				groupSuggestionAssignmentUser.userId === userId
					? { userId, final: true }
					: groupSuggestionAssignmentUser
		)

		return new GroupsSuggestionAssignment(
			this.groupsSuggestionAssignment.map(groupSuggestionAssignment => {
				return {
					...groupSuggestionAssignment,
					users:
						groupSuggestionAssignment.groupDescription.id ===
						selectedGroup.groupDescription.id
							? selectedGroupUsers
							: [...groupSuggestionAssignment.users],
				}
			}) as GroupSuggestionAssignment[]
		)
	}

	leaveGroupBy(userId: string) {
		return this.removeUserFromGroups(userId)
	}

	getNotAssignedUsers(users: User[]) {
		return users.filter(
			user => this.getUserSelectedGroupId(user.id) === undefined
		)
	}
}
