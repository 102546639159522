import { http } from '@beelday/common'
import { RoomAddress } from 'common/types'
import { useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
	KnowledgeCheck,
	KnowledgeCheckID,
	KnowledgeCheckOptionID,
	KnowledgeCheckQuestionID,
	KnowledgeCheckReview,
	KnowledgeCheckStats,
	UserKnowledgeCheckResults,
} from './knowledge-check'

export type KnowledgeChecksApi = {
	getAvailableKnowledgeChecks: (
		address: RoomAddress
	) => Promise<KnowledgeCheck[]>
	getAvailableForReview: (
		address: RoomAddress
	) => Promise<KnowledgeCheckReview[]>
	startKnowledgeCheck: (
		address: RoomAddress,
		knowledgeCheckId: KnowledgeCheckID
	) => Promise<void>
	startKnowledgeCheckReview: (
		address: RoomAddress,
		knowledgeCheckId: KnowledgeCheckID
	) => Promise<void>
	getKnowledgeCheck: (
		address: RoomAddress,
		knowledgeCheckId: KnowledgeCheckID
	) => Promise<KnowledgeCheck>
	answerQuestion: (
		address: RoomAddress,
		knowledgeCheckId: KnowledgeCheckID,
		questionId: string,
		answers: string[]
	) => Promise<void>
	prevQuestion: (
		address: RoomAddress,
		knowledgeCheckId: KnowledgeCheckID
	) => Promise<void>
	nextQuestion: (
		address: RoomAddress,
		knowledgeCheckId: KnowledgeCheckID
	) => Promise<void>
	finishKnowledgeCheck: (
		address: RoomAddress,
		knowledgeCheckId: KnowledgeCheckID
	) => Promise<void>
	getTrainerStats: (
		address: RoomAddress,
		knowledgeCheckId: KnowledgeCheckID
	) => Promise<KnowledgeCheckStats>
	getReview: (
		address: RoomAddress,
		knowledgeCheckId: KnowledgeCheckID
	) => Promise<KnowledgeCheckReview>
	nextReviewQuestion: (
		address: RoomAddress,
		knowledgeCheckId: KnowledgeCheckID
	) => Promise<{ currentQuestionIndex: string }>
	prevReviewQuestion: (
		address: RoomAddress,
		knowledgeCheckId: KnowledgeCheckID
	) => Promise<{ currentQuestionIndex: string }>
	close: (
		address: RoomAddress,
		knowledgeCheckId: KnowledgeCheckID
	) => Promise<void>
	getUserResults: (
		address: RoomAddress,
		knowledgeCheckId: KnowledgeCheckID
	) => Promise<UserKnowledgeCheckResults>
}

export const createKnowledgeChecksApi = (
	serverUrl: string
): KnowledgeChecksApi => ({
	getAvailableKnowledgeChecks: (address: RoomAddress) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/knowledge-check-definitions`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
			.then(http.checkStatus)
			.then(http.parseBody),
	getAvailableForReview: (address: RoomAddress) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/knowledge-checks/review/closed`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
			.then(http.checkStatus)
			.then(http.parseBody),
	startKnowledgeCheck: (
		address: RoomAddress,
		knowledgeCheckId: KnowledgeCheckID
	) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/knowledge-checks`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
				body: knowledgeCheckId,
			}
		)
			.then(http.checkStatus)
			.then(http.parseBody),
	startKnowledgeCheckReview: (
		address: RoomAddress,
		knowledgeCheckId: KnowledgeCheckID
	) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/knowledge-checks/${knowledgeCheckId}/review`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
			.then(http.checkStatus)
			.then(http.parseBody),
	getKnowledgeCheck: (
		address: RoomAddress,
		knowledgeCheckId: KnowledgeCheckID
	) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/knowledge-checks/${knowledgeCheckId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
			.then(http.checkStatus)
			.then(http.parseBody),
	answerQuestion: (
		address: RoomAddress,
		knowledgeCheckId: KnowledgeCheckID,
		questionId: KnowledgeCheckQuestionID,
		answers: KnowledgeCheckOptionID[]
	) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/knowledge-checks/${knowledgeCheckId}/questions/${questionId}`,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
				body: JSON.stringify(answers),
			}
		)
			.then(http.checkStatus)
			.then(http.parseBody),
	prevQuestion: (address: RoomAddress, knowledgeCheckId: KnowledgeCheckID) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/knowledge-checks/${knowledgeCheckId}/prev`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
				body: knowledgeCheckId,
			}
		)
			.then(http.checkStatus)
			.then(http.parseBody),
	nextQuestion: (address: RoomAddress, knowledgeCheckId: KnowledgeCheckID) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/knowledge-checks/${knowledgeCheckId}/next`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
				body: knowledgeCheckId,
			}
		)
			.then(http.checkStatus)
			.then(http.parseBody),
	finishKnowledgeCheck: (
		address: RoomAddress,
		knowledgeCheckId: KnowledgeCheckID
	) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/knowledge-checks/${knowledgeCheckId}/finish`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
				body: knowledgeCheckId,
			}
		)
			.then(http.checkStatus)
			.then(http.parseBody),
	getTrainerStats: (address: RoomAddress, knowledgeCheckId: KnowledgeCheckID) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/knowledge-checks/${knowledgeCheckId}/stats`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
			.then(http.checkStatus)
			.then(http.parseBody),

	getReview: (address: RoomAddress, knowledgeCheckId: KnowledgeCheckID) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/knowledge-checks/${knowledgeCheckId}/review`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
			.then(http.checkStatus)
			.then(http.parseBody),
	nextReviewQuestion: (
		address: RoomAddress,
		knowledgeCheckId: KnowledgeCheckID
	) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/knowledge-checks/${knowledgeCheckId}/review/next`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
				body: knowledgeCheckId,
			}
		)
			.then(http.checkStatus)
			.then(http.parseBody),
	prevReviewQuestion: (
		address: RoomAddress,
		knowledgeCheckId: KnowledgeCheckID
	) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/knowledge-checks/${knowledgeCheckId}/review/prev`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
				body: knowledgeCheckId,
			}
		)
			.then(http.checkStatus)
			.then(http.parseBody),
	getUserResults: (address: RoomAddress, knowledgeCheckId: KnowledgeCheckID) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/knowledge-checks/${knowledgeCheckId}/results`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
			.then(http.checkStatus)
			.then(http.parseBody),
	close: (address: RoomAddress, knowledgeCheckId: KnowledgeCheckID) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/knowledge-checks/${knowledgeCheckId}/close`,
			{
				method: 'PUT',
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
			.then(http.checkStatus)
			.then(http.parseBody),
})

export const useKnowledgeChecksApi = (
	serverUrl: string
): KnowledgeChecksApi => {
	return useMemo(() => createKnowledgeChecksApi(serverUrl), [serverUrl])
}

export default useKnowledgeChecksApi
