import { FC, useState } from 'react'
import styled from '@emotion/styled'
import { Translate } from '../intl'
import colors from '../colors'
import { ModalPicker } from './modal'
import { CONTINENT, TimeZoneId, timeZones } from '../timezones'
import { css } from '@emotion/react'

const ContinentSelector = styled.div`
	margin: 10px 10px 4px 10px;
	border-radius: 12px;
	padding: 3px;
	background-color: ${colors.paleGray2};
	display: flex;
	align-items: center;
`

const ContinentButton = styled.div<{ selected?: boolean }>`
	min-width: 73px;
	flex: 1;
	border-radius: 10px;
	background-color: ${props =>
		props.selected ? colors.white : colors.paleGray2};
	font-family: Ubuntu;
	font-weight: normal;
	font-size: 12px;
	color: ${props => (props.selected ? colors.black : colors.heather)};
	cursor: pointer;
	padding: 10px;
	text-align: center;
	${props => props.selected && 'box-shadow: 0 2px 3px 0 rgba(41, 54, 73, 0.1);'}
`

const TimeZoneTextsContainer = styled.div`
	padding-bottom: 6px;
`
const TimeZoneText = styled.div<{ selected?: boolean }>(
	({ selected }) => css`
		font-family: Ubuntu;
		font-size: 14px;
		padding: 0.5em;
		white-space: nowrap;
		cursor: pointer;
		border-radius: 4px;
		border: solid 2px transparent;
		background-color: ${selected ? colors.indigoBlue : undefined};
		color: ${selected ? colors.white : undefined};
		:hover {
			border: solid 2px ${colors.heather};
		}
	`
)

type Props = {
	selected?: TimeZoneId
	onChange: (name: string) => void
	onClose: () => unknown
	className?: string
}
export const TimeZonePicker: FC<Props> = ({
	selected,
	onChange,
	onClose,
	className,
}) => {
	const allZones = Object.values(timeZones)
	const selectedZone = selected ? timeZones[selected] : undefined
	const [selectedContinent, setSelectedContinent] = useState<CONTINENT>(
		selectedZone?.continent || CONTINENT.USA
	)

	return (
		<ModalPicker onClose={onClose} className={className}>
			<ContinentSelector>
				<ContinentButton
					selected={selectedContinent === CONTINENT.USA}
					onClick={() => setSelectedContinent(CONTINENT.USA)}
				>
					<Translate>timezone.usa</Translate>
				</ContinentButton>
				<ContinentButton
					selected={selectedContinent === CONTINENT.EUROPE}
					onClick={() => setSelectedContinent(CONTINENT.EUROPE)}
				>
					<Translate>timezone.europe</Translate>
				</ContinentButton>
			</ContinentSelector>
			<TimeZoneTextsContainer>
				{allZones
					.filter(timeZone => timeZone.continent === selectedContinent)
					.map(timeZone => (
						<TimeZoneText
							key={timeZone.id}
							onClick={() => onChange(timeZone.id)}
							selected={timeZone.id === selected}
						>{`${timeZone.name.toUpperCase()} (${
							timeZone.short
						})`}</TimeZoneText>
					))}
			</TimeZoneTextsContainer>
		</ModalPicker>
	)
}

export default TimeZonePicker
