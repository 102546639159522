export const Drop: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="160.453"
		height="49.253"
		viewBox="0 0 160.453 49.253"
	>
		<path
			fill="currentColor"
			data-name="Path 12147"
			d="M-197.937-88.756V-65H-358.39v-23.725h15.435v-.008a56.25 56.25 0 0 0 15.5-1.59c8.04-2.212 15.043-7.141 21.532-12.386a81.069 81.069 0 0 1 8.977-6.719 33.989 33.989 0 0 1 17.246-4.822h4.3v.015a33.906 33.906 0 0 1 16.748 4.807 80.981 80.981 0 0 1 8.969 6.719c6.489 5.245 13.492 10.175 21.54 12.386 5.636 1.551 11.534 1.682 17.347 1.567z"
			transform="translate(358.39 114.25)"
		/>
	</svg>
)
