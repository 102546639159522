import styled from '@emotion/styled'
import { PublishBitrates } from 'common/publish-bitrates'
import { RootState } from 'common/redux'
import type { Media } from 'common/types'
import { Group } from 'common/types'
import { useAuthenticatedBeeldayClient } from 'connectivity/beelday-hooks'
import ConfiguredScreenShareGroup from 'features/screen-share/screen-share-group'
import useScreenShareInGroup from 'features/screen-share/use-screen-share-in-group'
import { useUserEngagementResults } from 'features/user-engagement'
import { findKey } from 'lodash'
import { setIsExpandableCompanyCard } from 'organization/redux'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getEndUsers } from 'room/common/model/room'
import { colorToHex } from 'room/common/presentation/colorsInHex'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { ConfiguredEtherPad } from 'room/group-room/configured-ether-pad'
import { filterEndUsers } from 'room/group-room/model/group'
import GroupFlexBackgrounds from 'room/group-room/presentation/group-flex-backgrounds'
import { VideoColumns } from 'room/group-room/presentation/video-columns'
import EqualVideosContainer from 'video-conference-media/presentation/equal-videos-container'
import { allStreams, setPublishBitrate } from 'video-conference-media/redux'
import { ConfiguredExcalidraw } from './configured-excalidraw'
import { filterSceneVideoUsers } from './model/scene-video-users'
import { RoomTrainerInGroup } from './presentation/room-trainer-in-group'
import { selectGroups } from './redux'

const GrowingContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	justify-content: center;
	justify-items: center;
	align-items: center;
	align-content: center;
	padding-left: 10px;
	padding-right: 10px;
`

type Props = {
	currentGroup: Group
	onStartBreak: () => void
}

export const ConfiguredGroupRoomTrainerInGroup: FC<Props> = props => {
	const dispatch = useDispatch()
	const beeldayClient = useAuthenticatedBeeldayClient()
	const joinedRoomAddress = useAssertedJoinedRoomAddress()
	const sceneVideoUsers = useSelector(allStreams)
	const groups = useSelector(selectGroups)
	const groupsWithEndUsersOnly = Object.values(groups).map(filterEndUsers)

	useUserEngagementResults()

	dispatch(setIsExpandableCompanyCard(false))

	const { currentGroup } = props
	const currentGroupId = currentGroup.groupDescription.id
	const etherPadState = useSelector(
		(state: RootState) => state.groupRoom.etherPadsStates[currentGroupId]
	)
	const excalidrawState = useSelector(
		(state: RootState) => state.groupRoom.excalidraw[currentGroupId]
	)

	const groupVideoSceneUsers = filterSceneVideoUsers(
		getEndUsers(currentGroup.users),
		sceneVideoUsers
	)

	const { userGroupScreenShareState } = useScreenShareInGroup()

	const mediaState: Record<Media, { active?: boolean }> = {
		EtherPad: etherPadState,
		Excalidraw: excalidrawState,
	}

	const openMedia = findKey(mediaState, 'active') as Media | undefined

	const handleEnterGroup = useCallback(
		(groupId: string) =>
			beeldayClient.trainerJoinsGroup({
				...joinedRoomAddress,
				groupId,
			}),
		[beeldayClient, joinedRoomAddress]
	)

	useEffect(() => {
		dispatch(setPublishBitrate(PublishBitrates.low))
	}, [dispatch])

	const groupAddress = useMemo(() => {
		return { ...joinedRoomAddress, groupId: currentGroupId }
	}, [joinedRoomAddress, currentGroupId])
	return (
		<RoomTrainerInGroup
			sceneVideoUsers={groupVideoSceneUsers}
			groups={groupsWithEndUsersOnly}
			onEnter={handleEnterGroup}
			openMedia={openMedia}
			mainChildren={
				<>
					{userGroupScreenShareState?.active ? (
						<ConfiguredScreenShareGroup />
					) : openMedia === 'EtherPad' ? (
						<ConfiguredEtherPad
							groupAddress={groupAddress}
							groupColor={currentGroup.groupDescription.color}
						/>
					) : openMedia === 'Excalidraw' ? (
						<ConfiguredExcalidraw
							groupAddress={groupAddress}
							groupColor={currentGroup.groupDescription.color}
						/>
					) : (
						<GrowingContainer>
							<GroupFlexBackgrounds
								colorHex={colorToHex[currentGroup.groupDescription.color]}
							>
								<EqualVideosContainer videos={groupVideoSceneUsers} />
							</GroupFlexBackgrounds>
						</GrowingContainer>
					)}
				</>
			}
			sideChildren={
				(openMedia || userGroupScreenShareState?.active) && (
					<VideoColumns sceneVideoUsers={groupVideoSceneUsers} />
				)
			}
			{...props}
		/>
	)
}
