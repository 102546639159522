import { GroupId, GroupReview, GroupMember, User } from 'common/types'

import { reduce, map } from 'lodash'

export const mapToGroupMembers = (groups: GroupReview[]): GroupMember[] => {
	return reduce(
		groups,
		(acc, g) => {
			const members: GroupMember[] = map(g.members, m => ({
				...m,
				group: {
					id: g.id,
					name: g.properties.name,
					color: g.properties.color,
					number: g.properties.number,
				},
				identifiedBy: groups.length > 8 ? 'GROUP_NUMBER' : 'GROUP_COLOR',
			}))

			return [...acc, ...members]
		},
		[] as GroupMember[]
	)
}

export const findActiveGroup = (
	groups: GroupReview[],
	activeGroupId?: GroupId
) => {
	return groups.find(g => g.id === activeGroupId)
}

export const isGroupMember = (user: GroupMember | User): user is GroupMember =>
	(user as GroupMember).group !== undefined &&
	(user as GroupMember).identifiedBy !== undefined
