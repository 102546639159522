import {
	EventsSourcePath,
	InteractionSchemeStateChangeEvent,
	TrainingPhaseName,
	WorkflowEvent,
} from '../common/types'
import { RootState } from 'common/redux'

import { createSelector } from 'reselect'
import { flatMap } from 'lodash'

const eventSourceReducerDefaultState = {
	eventsSourcePath: EventsSourcePath.workflow,
}

export interface EventSourceState {
	eventsSourcePath: EventsSourcePath
	latestInteractionSchemeEvent?: InteractionSchemeStateChangeEvent
	latestWorkflowEvent?: WorkflowEvent
}

type EventSourceAction =
	| { type: 'SET_EVENTS_SOURCE_PATH'; eventsSourcePath: EventsSourcePath }
	| {
			type: 'SET_LATEST_INTERACTION_SCHEME_EVENT'
			interactionSchemeEvent: InteractionSchemeStateChangeEvent
	  }
	| { type: 'SET_LATEST_WORKFLOW_EVENT'; workflowEvent: WorkflowEvent }

export const setEventsSourcePath = (eventsSourcePath: EventsSourcePath) => {
	return { type: 'SET_EVENTS_SOURCE_PATH', eventsSourcePath }
}

export const setLatestInteractionSchemeEvent = (
	interactionSchemeEvent: InteractionSchemeStateChangeEvent
): EventSourceAction => {
	return { type: 'SET_LATEST_INTERACTION_SCHEME_EVENT', interactionSchemeEvent }
}

export const setLatestWorkflowEvent = (
	workflowEvent: WorkflowEvent
): EventSourceAction => {
	return { type: 'SET_LATEST_WORKFLOW_EVENT', workflowEvent }
}

export function eventSourceReducer(
	state: EventSourceState = eventSourceReducerDefaultState,
	action: EventSourceAction
): EventSourceState {
	switch (action.type) {
		case 'SET_EVENTS_SOURCE_PATH':
			return {
				...state,
				eventsSourcePath: action.eventsSourcePath,
			}
		case 'SET_LATEST_INTERACTION_SCHEME_EVENT': {
			const currentTimestamp = state.latestInteractionSchemeEvent?.timestamp
			if (
				currentTimestamp &&
				currentTimestamp > action.interactionSchemeEvent.timestamp
			) {
				console.log(
					'Received an expired interactionSchemeEvent',
					action.interactionSchemeEvent
				)
				return state
			}
			return {
				...state,
				latestInteractionSchemeEvent: action.interactionSchemeEvent,
			}
		}
		case 'SET_LATEST_WORKFLOW_EVENT': {
			const currentTimestamp = state.latestWorkflowEvent?.timestamp
			if (
				currentTimestamp &&
				currentTimestamp > action.workflowEvent.timestamp
			) {
				console.log('Received an expired workflowEvent', action.workflowEvent)
				return state
			}
			return {
				...state,
				latestWorkflowEvent: action.workflowEvent,
			}
		}
		default:
			return state
	}
}

export const workflowUsers = createSelector(
	(state: RootState) => state.eventSource.latestWorkflowEvent,
	(workflowEvent?: WorkflowEvent) =>
		flatMap(workflowEvent?.workflowState.interactionSchemesState, i => i.users)
)

export const ISPhase = (state: RootState): TrainingPhaseName | undefined =>
	state.eventSource.latestInteractionSchemeEvent?.isPhase
