import styled from '@emotion/styled'
import icon from './muted-user.svg'

const IconWrapper = styled.div`
	height: 20px;
	margin-right: 8px;
	width: 20px;
`

const MuteIcon = (): JSX.Element => {
	return (
		<IconWrapper>
			<img src={icon} alt="muted" />
		</IconWrapper>
	)
}

export default MuteIcon
