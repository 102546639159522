export const HelpIcon: React.FC = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
		<g id="Group_4448" data-name="Group 4448" transform="translate(-249 -249)">
			<g id="Group_4441" data-name="Group 4441">
				<g id="Group_4440" data-name="Group 4440">
					<path
						id="Path_8897"
						data-name="Path 8897"
						d="M253.1,253.681l-2.333-2.333a7,7,0,0,0,0,9.3l2.333-2.333a3.689,3.689,0,0,1,0-4.638Z"
						fill="currentColor"
					/>
				</g>
			</g>
			<g id="Group_4443" data-name="Group 4443">
				<g id="Group_4442" data-name="Group 4442">
					<path
						id="Path_8898"
						data-name="Path 8898"
						d="M261.232,251.348l-2.333,2.333a3.689,3.689,0,0,1,0,4.638l2.333,2.333a7,7,0,0,0,0-9.3Z"
						fill="currentColor"
					/>
				</g>
			</g>
			<g id="Group_4445" data-name="Group 4445">
				<g id="Group_4444" data-name="Group 4444">
					<path
						id="Path_8899"
						data-name="Path 8899"
						d="M258.319,258.9a3.689,3.689,0,0,1-4.638,0l-2.333,2.333a7,7,0,0,0,9.3,0Z"
						fill="currentColor"
					/>
				</g>
			</g>
			<g id="Group_4447" data-name="Group 4447">
				<g id="Group_4446" data-name="Group 4446">
					<path
						id="Path_8900"
						data-name="Path 8900"
						d="M256,249a7.009,7.009,0,0,0-4.652,1.768l2.333,2.333a3.689,3.689,0,0,1,4.638,0l2.333-2.333A7.01,7.01,0,0,0,256,249Z"
						fill="currentColor"
					/>
				</g>
			</g>
		</g>
	</svg>
)
