import {
	Component,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import { v4 as uuidv4 } from 'uuid'

export const useToggle = (
	initial?: boolean
): {
	value: boolean
	isOn: boolean
	toggle: () => void
	setOn: () => void
	setOff: () => void
} => {
	const [value, setValue] = useState(initial || false)

	const toggle = useCallback(() => {
		setValue(!value)
	}, [value])
	const setOff = useCallback(() => {
		setValue(false)
	}, [])
	const setOn = useCallback(() => {
		setValue(true)
	}, [])

	return useMemo(
		() => ({ value, isOn: value, toggle, setOn, setOff }),
		[value, toggle, setOn, setOff]
	)
}

type LoadingState<T> = {
	loading: boolean
	reload: () => void
	error?: Error | null
	result?: T
}
export const useLoading = <T>(
	fn: () => Promise<T>,
	lazy?: boolean
): LoadingState<T> => {
	const [loading, setLoading] = useState(!lazy)
	const [error, setError] = useState<Error | null>(null)
	const [result, setResult] = useState<T | undefined>(undefined)

	const reload = useCallback(() => {
		setLoading(true)
		fn().then(
			res => {
				setResult(res)
				setLoading(false)
				setError(null)
			},
			err => {
				setLoading(false)
				setError(err)
			}
		)
	}, [fn])

	useEffect(() => {
		!lazy && reload()
	}, [lazy, reload])

	return useMemo(
		() => ({
			loading,
			error,
			result,
			reload,
		}),
		[error, loading, reload, result]
	)
}

export type Result<T> =
	| {
			loading: true
			result: undefined
	  }
	| {
			loading: false
			result: T
	  }

export const toResult = <T>(result: T | undefined): Result<T> => {
	if (result === undefined) {
		return { loading: true, result: undefined }
	} else {
		return { loading: false, result }
	}
}

export function useSerializedCallback<Out>(
	callback: () => Promise<Out>,
	deps?: React.DependencyList
): () => Promise<Out> {
	const running = useRef<Promise<Out> | null>(null)

	useEffect(() => {
		running.current = null
	}, deps) // eslint-disable-line react-hooks/exhaustive-deps

	return (): Promise<Out> => {
		if (!running.current) {
			running.current = callback().then(
				res => {
					running.current = null
					return res
				},
				e => {
					running.current = null
					throw e
				}
			)
		}

		return running.current
	}
}

type UseIsMounted = () => () => boolean

export const useIsMounted: UseIsMounted = () => {
	const isMounted = useRef(false)

	useEffect(() => {
		isMounted.current = true

		return () => {
			isMounted.current = false
		}
	}, [])

	return useCallback(() => isMounted.current, [])
}

export const useForceRefresh = (
	refresh: boolean | null | undefined
): boolean => {
	const forceRefresh = useRef(!!refresh)
	useEffect(() => {
		forceRefresh.current = false
	}, [])

	return forceRefresh.current
}

const EMPTY = {} as never
export const useMemoComputed = <T>(
	compute: () => T,
	eq: (a: T, b: T) => boolean
): T => {
	const prev = useRef<T>(EMPTY)
	const update = compute()

	if (Object.is(prev.current, EMPTY) || !eq(prev.current, update)) {
		prev.current = update
	}
	return prev.current
}

export class OnLifecycle extends Component<{
	onUnmount?: () => unknown
	children?: React.ReactNode | null
}> {
	componentWillUnmount(): void {
		this.props.onUnmount?.()
	}

	render(): React.ReactNode {
		return this.props.children || null
	}
}

export const useIsChanged = <T>(obj: T | null, logname?: string): boolean => {
	const prevRef = useRef<T | null>(obj)
	const changed = !Object.is(prevRef.current, obj)
	if (logname && changed) {
		console.log(`CHANGED: $(${logname})`, prevRef.current, obj)
	}

	prevRef.current = obj
	return changed
}

export const useStableId = (): string => {
	return useState(uuidv4)[0]
}

export const useForceRerender = (): (() => void) => {
	const [, set] = useState(0)
	return useCallback(() => set(s => s + 1), [])
}

export function useAsyncCallback<
	R,
	I extends never[],
	F extends (...args: I) => Promise<R> | void
>(fn: F): [F, boolean] {
	const [loading, setLoading] = useState(false)
	const callback = useCallback(
		(...args: I) => {
			const res = fn(...args)
			if (res instanceof Promise) {
				setLoading(true)
				return res.then(
					o => {
						setLoading(false)
						return o
					},
					err => {
						setLoading(false)
						throw err
					}
				)
			} else {
				setLoading(false)
				return res
			}
		},
		[fn]
	)

	return [callback as F, loading]
}

export const useDebounced = (fn: () => void, delay: number): (() => void) => {
	const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
	const fnRef = useRef(fn)
	fnRef.current = fn

	useEffect(() => {
		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current)
				fnRef.current()
			}
		}
	}, [])

	return useCallback(() => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current)
		}
		timeoutRef.current = setTimeout(fnRef.current, delay)
	}, [delay])
}

export const useRefMap = <K, T>(): {
	get: (k: K) => T | undefined
	ref: (k: K) => (v: T | null) => void
} => {
	const map = useRef<Map<K, T>>(new Map())

	const get = useCallback((key: K): T | undefined => map.current.get(key), [])
	const ref = useCallback(
		(key: K) =>
			(value: T | null): void => {
				if (value === null) {
					map.current.delete(key)
				} else {
					map.current.set(key, value)
				}
			},
		[]
	)

	return {
		get,
		ref,
	}
}
