import { InteractionSchemeAddress, RoomAddress } from '../common/types'

export const getQuery = (
	name: string,
	location: Location = window.location
): string | null => {
	const search = location.search
	const results = new RegExp(`[?&]${name}=([^&#]*)`).exec(search)
	if (results) {
		return decodeURI(results[1]) || null
	}

	return null
}

export const checkStatus = (response: Response): Response | Promise<any> => {
	if (response.status >= 200 && response.status < 300) {
		return response
	}

	return response
		.json()
		.catch(() => Promise.reject({ status: response.status }))
		.then(json => Promise.reject({ ...json, status: response.status }))
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseBody = (response: Response): Promise<any> => {
	const contentType = response.headers.get('Content-Type') || ''
	if (/text\/plain/.test(contentType)) {
		return response.text()
	} else if (
		/application\/json/.test(contentType) ||
		/application\/hal\+json/.test(contentType)
	) {
		return response.json()
	} else {
		return Promise.resolve()
	}
}

export const buildRoomUrl = (
	serverUrl: string,
	room: RoomAddress,
	path: string
): string => {
	return `${serverUrl}/api/v0/workflows/${room.workflowId}/interactionschemes/${
		room.interactionSchemeId
	}/rooms/${room.roomId}${path || ''}`
}

export const buildIsUrl = (
	serverUrl: string,
	room: InteractionSchemeAddress,
	path: string
): string => {
	return `${serverUrl}/api/v0/workflows/${room.workflowId}/interactionschemes/${
		room.interactionSchemeId
	}${path || ''}`
}
