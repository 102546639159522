export const StartReview: React.FC = () => (
	<svg
		data-name="Group 25598"
		xmlns="http://www.w3.org/2000/svg"
		width="23.27"
		height="24.35"
		viewBox="0 0 23.27 24.35"
	>
		<defs>
			<clipPath id="7xiwstof8a">
				<path
					data-name="Rectangle 3996"
					style={{ fill: 'none' }}
					d="M0 0h23.27v24.35H0z"
				/>
			</clipPath>
		</defs>
		<g data-name="Group 25598" style={{ clipPath: 'url(#7xiwstof8a)' }}>
			<path
				data-name="Path 11831"
				d="M16.61 0H2.76A2.761 2.761 0 0 0 0 2.77v15.69a2.761 2.761 0 0 0 2.77 2.76h7.9a6.58 6.58 0 0 1-1.76-3.28 6.7 6.7 0 0 1 10.2-7.02 2.343 2.343 0 0 1 .27.2V2.77A2.77 2.77 0 0 0 16.61 0M9.07 9H3.53a.926.926 0 0 1 0-1.85h5.54a.916.916 0 0 1 .88.88.934.934 0 0 1-.88.97m2.76-3.69h-8.3a.935.935 0 0 1-.89-.9.925.925 0 0 1 .89-.95h8.36a.925.925 0 1 1-.06 1.85"
				style={{ fillRule: 'evenodd' }}
			/>
			<path
				data-name="Path 11832"
				d="m22.97 22.6-2.92-2.93.07-.11a5.538 5.538 0 0 0-.74-6.94 5.266 5.266 0 0 0-.9-.72 5.542 5.542 0 0 0-7.41 8.03 5.254 5.254 0 0 0 1.38 1.27 5.556 5.556 0 0 0 6.04 0l.11-.07 2.92 2.92a1.026 1.026 0 0 0 1.45 0 .982.982 0 0 0 .3-.72 1.028 1.028 0 0 0-.3-.73m-7.5-2.58a3.465 3.465 0 1 1 3.47-3.47 3.461 3.461 0 0 1-3.47 3.47"
				style={{ fillRule: 'evenodd' }}
			/>
		</g>
	</svg>
)
