import { FC, ReactElement } from 'react'
import { NavLink } from 'react-router-dom'
import styled from '@emotion/styled'
import colors from '../colors'
import { VerticalFlex } from './layout'
import { css } from '@emotion/react'
import { Training, Drop } from '../icons'

const Title = styled.div`
	font-size: 11px;

	user-select: none;
	white-space: nowrap;
`

const mainLinkClass = css`
	position: relative;
	display: flex;
	width: 25%;
	justify-content: center;
	align-items: center;
	color: ${colors.black};
	cursor: pointer;

	.icon {
		margin-bottom: 6px;
		width: 20px;
		height: 20px;
		color: ${colors.lightGrayishBlue};
	}
	&.active {
		color: ${colors.indigoBlue};
		border-radius: 16px;
		font-weight: 500;
		.icon {
			color: ${colors.indigoBlue};
		}
	}
`

const MenuIconContainer = styled.div`
	width: 36px;
	height: 36px;
	align-items: center;
	justify-content: center;
	display: flex;
	border-radius: 50%;
	background-color: ${colors.darkIndigo};

	.icon {
		height: 20px;
	}

	svg {
		height: 20px;
		width: 20px;
	}
`

const ButtonDropContainer = styled.div`
	position: relative;
	min-width: 68px;
	height: fit-content;
`

const DropContainer = styled.div`
	position: absolute;
	align-items: center;
	justify-content: center;
	margin-left: -41.5px;
	bottom: -28px;
	z-index: -1;

	svg {
		width: 150px;

		path {
			fill: ${colors.white};
		}
	}
`

const CircleButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 68px;
	background-color: ${colors.white};
	box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
	border-radius: 50%;
	bottom: -45px;
	position: absolute;
	width: 68px;
`

const CircleButton = styled(NavLink)`
	border-radius: 50%;
	background-image: linear-gradient(
		129deg,
		${colors.lightishPurple} -5%,
		${colors.indigoBlue} 107%
	);
	display: flex;
	justify-content: center;
	align-items: center;
	height: 52px;
	width: 52px;

	svg {
		color: white;
	}
`

export const MenuItemMobile: FC<{
	icon: ReactElement
	href: string
	exact?: boolean
	onClick?: () => void
	isActive?: boolean
}> = ({ icon, href, exact, children, onClick, isActive }) => (
	<NavLink
		css={mainLinkClass}
		to={href}
		exact={exact}
		onClick={onClick}
		className={isActive ? 'active' : ''}
	>
		<VerticalFlex style={{ flexDirection: 'column' }}>
			<div className="icon">{icon}</div>
			<Title>{children}</Title>
		</VerticalFlex>
	</NavLink>
)

export const ClicableMenuItemMobile: FC<{
	icon: ReactElement
	onClick?: () => void
}> = ({ icon, children, onClick }) => (
	<div css={mainLinkClass} onClick={onClick}>
		<VerticalFlex style={{ flexDirection: 'column' }}>
			<div className="icon">{icon}</div>
			<Title>{children}</Title>
		</VerticalFlex>
	</div>
)

export const MenuIconMobile: FC<{
	icon: ReactElement
	onClick?: () => void
}> = ({ icon, onClick, children }) => (
	<MenuIconContainer onClick={onClick}>
		<div className="icon" style={{ color: `${colors.white}` }}>
			{icon}
		</div>
		<div>{children}</div>
	</MenuIconContainer>
)

export const MenuAddTrainingMobile: FC<{ href: string }> = ({ href }) => (
	<ButtonDropContainer>
		<DropContainer>
			<Drop />
		</DropContainer>
		<CircleButtonContainer>
			<CircleButton to={href}>
				<Training />
			</CircleButton>
		</CircleButtonContainer>
	</ButtonDropContainer>
)
