import styled from '@emotion/styled'
import colors from '../colors'
import responsive from './responsive'

const Container = styled.div`
	background-color: ${colors.white};
	box-shadow: 5px 0 20px 0 rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	bottom: 0;
	column-gap: 16px;
	display: flex;
	margin-top: 10px;
	position: sticky;
	width: 100%;
	z-index: 1;
	padding: 15px 30px 15px 30px;

	${responsive.desktop} {
		padding: 24px 50px 24px 50px;
	}
`

export const StickyWrapper = ({
	children,
	style,
}: {
	children: React.ReactNode
	style?: React.CSSProperties
}): JSX.Element => <Container style={style}>{children}</Container>
