import { isString } from 'lodash'
import { RefObject, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'

export const useDelegatingHistory = (
	elementRef: RefObject<HTMLElement>,
	basePath: string = ''
): string => {
	const history = useHistory()
	const location = useLocation()

	useEffect(() => {
		const element = elementRef.current
		if (element) {
			const routeChangeListener = (customEvent: Event) => {
				const { event, location, amount } = (customEvent as CustomEvent).detail
				let target
				if (location) {
					target = isString(location)
						? basePath + location
						: {
								...location,
								pathname: basePath + location.pathname,
						  }
				}
				if (event === 'go') {
					history.go(amount)
				} else if (event === 'push' && target) {
					history.push(target)
				} else if (event === 'replace' && target) {
					history.replace(target)
				} else {
					console.warn(
						'Unknown delegated router event',
						event,
						location,
						amount
					)
				}
			}

			element.addEventListener('route-change', routeChangeListener)
			return () => {
				element.removeEventListener('route-change', routeChangeListener)
			}
		}
	}, [elementRef, history, basePath])

	const path = location.pathname.substring(basePath.length)

	return path
}
