import { userApi } from '@beelday/common'
import { User } from 'common/types'
import React from 'react'
import { useAssertedWorkflowUser } from 'room/common/use-workflow-user'
import { useProfileFor } from './redux'

export type MyProfile = User & { isTrainer: boolean }
export const useMyProfile = (): MyProfile => {
	const user = useAssertedWorkflowUser()
	const profile = useProfileFor(user.id)

	return React.useMemo(
		() => ({
			...user,
			name: userApi.getDisplayName(profile || user),
			isTrainer: user.role === 'UPPER_ECHELON',
		}),
		[user, profile]
	)
}
