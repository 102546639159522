import { omit } from 'lodash'
import {
	AuthGroups,
	AuthToken,
	AuthenticatedUser,
	Tokens,
	UserRoles,
} from './security-models'

export const saveTokens = (
	tokens: Tokens,
	rememberMe: boolean | undefined
): void => {
	if (rememberMe === undefined) {
		const currentCookies = getCookies()
		const currentTokens = currentCookies['BD_TOKENS']
		rememberMe = !!(currentTokens && JSON.parse(currentTokens).rememberMe)
	}

	const domain = window.location.hostname
	document.cookie = `JWTCOOKIE=${tokens.accessToken}; expires=${
		rememberMe ? new Date(tokens.accessTokenExpiration).toUTCString() : 0
	}; path=/; secure=true; domain=${domain}; sameSite=strict;`

	document.cookie = `BD_TOKENS=${JSON.stringify({
		...omit(tokens, 'accessToken'),
		rememberMe,
	})}; expires=${
		rememberMe ? new Date(tokens.refreshTokenExpiration).toUTCString() : 0
	}; path=/; secure=true; domain=${domain}; sameSite=strict;`
}

export const clearTokens = (): void => {
	const domain = window.location.hostname
	document.cookie = `JWTCOOKIE=null; max-age=-1; path=/; secure=true; domain=.${domain}`
	document.cookie = `BD_TOKENS=null; max-age=-1; path=/; secure=true; domain=.${domain}`
}
export const restoreTokens = (): Tokens | undefined => {
	const cookies = getCookies()
	const tokensCookie = cookies['BD_TOKENS']
	const jwtCookie = cookies['JWTCOOKIE']

	if (!tokensCookie) {
		return undefined
	}
	const parsed = JSON.parse(tokensCookie)
	return {
		accessToken: jwtCookie || '',
		accessTokenExpiration: parsed.accessTokenExpiration,
		refreshToken: parsed.refreshToken,
		refreshTokenExpiration: parsed.refreshTokenExpiration,
		rememberMe: !!parsed.rememberMe,
	}
}

const getCookies = (): Record<string, string> => {
	return document.cookie
		.split(';')
		.map(s => s.trim().split('='))
		.reduce((acc, split) => {
			acc[split[0]] = split[1]
			return acc
		}, {} as Record<string, string>)
}

export const getAuthenticatedUser = (
	accessToken: AuthToken
): AuthenticatedUser => {
	const decoded = JSON.parse(atob(accessToken.split('.')[1]))
	return {
		id: decoded.sub,
		email: decoded.email,
		name: decoded.given_name,
		surname: decoded.family_name,
		_dev: decoded.client_group?.includes('developers'),
		roles: getUserRoles(decoded.client_group),
	}
}

export function getUserRoles(clientGroups: Array<string>): Array<UserRoles> {
	const roles = clientGroups
		.map(cg => mapKeycloakGroupToRole(cg))
		.filter((v): v is Exclude<typeof v, undefined> => v !== undefined)

	return [UserRoles.END_USER, ...roles]
}

export function mapKeycloakGroupToRole(
	clientGroup: string
): UserRoles | undefined {
	if (clientGroup === AuthGroups.ACCOUNT_OWNER) {
		return UserRoles.ACCOUNT_OWNER
	}

	if (clientGroup === AuthGroups.COMPANY_ADMIN) {
		return UserRoles.COMPANY_ADMIN
	}

	if (clientGroup === AuthGroups.UPPER_ECHELON) {
		return UserRoles.UPPER_ECHELON
	}

	return undefined
}
