import { FC } from 'react'
import styled from '@emotion/styled'
import { Colors } from 'common/presentation/colors'
import { PlayerRanking } from '../types'
import Translate from 'intl/translate'
import bg from './images/bg-winner.svg'
import { Avatar } from 'common/types'
import ProfileAvatar from 'users/profile-avatar'
import { ui } from '@beelday/common'

const Container = styled.div`
	background: url(${bg});
	border: 2px solid ${Colors.white};
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 40px 20px 10px 20px;
	position: relative;
	height: 125px;
	width: 190px;

	${ui.responsive.desktop1440} {
		padding: 50px 20px 10px 20px;
		height: 135px;
	}
`

const Name = styled.div`
	color: ${Colors.white};
	font-family: Ubuntu;
	font-size: 12px;
	font-weight: 500;
	max-width: 100%;
	margin-bottom: 10px;
	overflow: hidden;
	text-align: center;
	text-overflow: ellipsis;
	white-space: nowrap;

	&:hover {
		overflow: visible;
		white-space: normal;
		word-break: break-all;
		height: auto;
	}
`

const Congrats = styled.div`
	color: ${Colors.white};
	font-family: Ubuntu;
	font-size: 12px;
	margin-bottom: 10px;
	text-align: center;
	white-space: pre;
`

const Face = styled(ProfileAvatar)`
	position: absolute;
	top: -15px;
	border: 5px solid ${Colors.white};
	border-radius: 35px;
	height: 55px;
	width: 55px;

	${ui.responsive.desktop1440} {
		height: 70px;
		width: 70px;
		top: -30px;
	}
`

type Props = {
	player: PlayerRanking & { avatar?: Avatar; displayName?: string }
}

const PodiumWinner: FC<Props> = ({ player }) => {
	return (
		<Container>
			<Face profile={player} />
			<Name>{player.displayName || player.name}</Name>
			<Congrats>
				<Translate>quiz.congrats</Translate>
			</Congrats>
		</Container>
	)
}

export default PodiumWinner
