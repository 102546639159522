export const WallIcon: React.FC = () => (
	<svg
		version="1.1"
		id="Group_27607"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		width="21.6"
		height="19.3"
		viewBox="0 0 21.6 19.3"
		xmlSpace="preserve"
	>
		<path d="M6.1,4.4l1.6,2.1H2.8L4,4.9l0.9,1L6.1,4.4z" />
		<g>
			<g>
				<g>
					<path
						d="M20.4,0H1.2C0.5,0,0,0.5,0,1.2c0,0,0,0,0,0v6.3c0,0.7,0.5,1.2,1.2,1.2h19.2
				c0.7,0,1.2-0.5,1.2-1.2v0V1.2C21.6,0.5,21.1,0,20.4,0C20.4,0,20.4,0,20.4,0 M8.3,5.9c0,0.9-0.7,1.6-1.6,1.6H3.6
				c-0.9,0-1.6-0.7-1.6-1.6V2.8c0-0.9,0.7-1.6,1.6-1.6h3.1c0.9,0,1.6,0.7,1.6,1.6V5.9z M14.9,5.8h-3.6c-0.5,0-1-0.4-1-1
				c0-0.5,0.4-1,1-1h3.6c0.5,0,1,0.4,1,1S15.4,5.8,14.9,5.8C14.9,5.8,14.9,5.8,14.9,5.8 M17.9,3.1h-6.6c-0.5,0-0.9-0.4-0.9-1
				s0.4-0.9,0.9-0.9h6.6c0.5,0,1,0.4,1,0.9S18.4,3.1,17.9,3.1"
					/>
					<path d="M6.1,14.9L7.6,17H2.8L4,15.4l0.9,1L6.1,14.9z" />
					<path
						d="M20.4,10.5H1.2c-0.7,0-1.2,0.5-1.2,1.2v6.3c0,0.7,0.5,1.2,1.2,1.2c0,0,0,0,0,0h19.2
				c0.7,0,1.2-0.5,1.2-1.2v0v-6.3C21.6,11.1,21.1,10.5,20.4,10.5L20.4,10.5 M8.3,16.5c0,0.9-0.7,1.6-1.6,1.6H3.6
				c-0.9,0-1.6-0.7-1.6-1.6v-3.1c0-0.9,0.7-1.6,1.6-1.6h3.1c0.9,0,1.6,0.7,1.6,1.6V16.5z M14.9,16.3h-3.6c-0.5,0-1-0.4-1-1
				c0-0.5,0.4-1,1-1h3.6c0.5,0,1,0.4,1,1S15.4,16.3,14.9,16.3C14.9,16.3,14.9,16.3,14.9,16.3 M17.9,13.7h-6.6c-0.5,0-0.9-0.4-0.9-1
				c0-0.5,0.4-0.9,0.9-0.9h6.6c0.5,0,1,0.4,1,0.9C18.8,13.2,18.4,13.7,17.9,13.7C17.9,13.7,17.9,13.7,17.9,13.7"
					/>
				</g>
			</g>
		</g>
	</svg>
)
