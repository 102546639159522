import { ActionReducerMapBuilder, AsyncThunk, Draft } from '@reduxjs/toolkit'

export type DataWithLoading<T> = {
	loading?: boolean
	error?: unknown | null
	data: T
}

export type LoadableListState<ID extends string | symbol | number, Type> = {
	initial?: boolean
	loadingAll?: boolean
	errorAll?: unknown | null
	loading: Record<ID, boolean>
	error: Record<ID, unknown>
	data: Record<ID, Type>
}

export const emptyLoadableListState = <
	ID extends string | symbol | number,
	Type
>(): LoadableListState<ID, Type> => ({
	initial: true,
	loading: {} as Record<ID, boolean>,
	error: {} as Record<ID, Error>,
	data: {} as Record<ID, Type>,
})

export const generateFetchingCase = <
	Field extends keyof Draft<State>,
	ID extends string | symbol | number,
	Type extends { id: ID },
	State extends Record<Field, LoadableListState<ID, Type>>
>(
	builder: ActionReducerMapBuilder<State>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	fetcher: AsyncThunk<Type | null, ID, any>,
	field: Field
): void => {
	builder.addCase(fetcher.pending, (state, { meta }) => {
		const id = meta.arg
		if (id) {
			const target = state[field] as LoadableListState<ID, Type>
			target.loading[id] = true
			target.error[id] = null
		}
	})
	builder.addCase(fetcher.rejected, (state, { payload, meta }) => {
		const id = meta.arg
		if (id) {
			const target = state[field] as LoadableListState<ID, Type>
			target.loading[id] = false
			target.error[id] = payload
		}
	})
	builder.addCase(fetcher.fulfilled, (state, { payload, meta }) => {
		const id = meta.arg
		if (id) {
			const target = state[field] as LoadableListState<ID, Type>
			target.loading[id] = false
			target.error[id] = null
			if (payload) {
				target.data[id] = payload
			} else {
				delete target.data[id]
			}
		}
	})
}

export const generateListFetchingCase = <
	Field extends keyof Draft<State>,
	ID extends string | symbol | number,
	Type extends { id: ID },
	State extends Record<Field, LoadableListState<ID, Type>>
>(
	builder: ActionReducerMapBuilder<State>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	fetcher: AsyncThunk<Type[], void, any>,
	field: Field
): void => {
	builder.addCase(fetcher.pending, state => {
		const target = state[field] as LoadableListState<ID, Type>
		target.loadingAll = true
		target.errorAll = null
	})
	builder.addCase(fetcher.rejected, (state, { payload }) => {
		const target = state[field] as LoadableListState<ID, Type>
		target.loadingAll = false
		target.errorAll = payload
	})
	builder.addCase(fetcher.fulfilled, (state, { payload }) => {
		const target = state[field] as LoadableListState<ID, Type>
		target.initial = false
		target.loadingAll = false
		target.errorAll = null
		payload.forEach(obj => {
			target.data[obj.id] = obj
			delete target.loading[obj.id]
			delete target.error[obj.id]
		})
	})
}
