import { dtoToTraining } from 'session-info/api/training-transform'
import { Attachment, Training } from 'session-info/training'
import createRawApi from './training-api'

export type TrainingAPI = {
	uploadTrainingIcon: (file: File) => Promise<Attachment>
	getTrainingIcon: (fileId?: string) => string
	fetchSession: (id: string) => Promise<Training | null>
	fetchSessionByLiveId: (liveSessionId: string) => Promise<Training | null>
}

const createAPI = (): TrainingAPI => {
	const raw = createRawApi()

	return {
		uploadTrainingIcon: raw.uploadTrainingIcon,
		getTrainingIcon: raw.trainingIconURL,
		fetchSession: id =>
			raw.fetchSession(id).then(dto => dto && dtoToTraining(dto)),
		fetchSessionByLiveId: liveSessionId =>
			raw
				.fetchSessionByLiveId(liveSessionId)
				.then(dto => dto && dtoToTraining(dto)),
	}
}

export default createAPI
