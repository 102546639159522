import { css } from '@emotion/react'
import { FC } from 'react'

type Props = {
	percentage: number
	size: number
	color: string
	style?: React.CSSProperties
}
export const CircleProgressSmall: FC<Props> = ({
	style,
	percentage,
	size,
	color,
}) => (
	<div
		css={css({
			//Looks more balanced with the 1px border
			marginTop: '1px',
			padding: '1px',
			border: `1px solid ${color}`,
			borderRadius: '50%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		})}
		style={style}
	>
		<div
			style={{
				background: `conic-gradient(${color} ${percentage}%, white 0)`,
				borderRadius: '50%',
				width: `${size}px`,
				height: `${size}px`,
			}}
		/>
	</div>
)
