import coffee from './images/chat-room-coffee.svg'
import lunch from './images/chat-room-lunch.svg'
import common from './images/chat-room-common.svg'
import talk from './images/chat-room-talk.svg'
import task from './images/chat-room-task.svg'
import emptyTask from './images/empty-task.svg'
import emptyTalk from './images/empty-talk.svg'
import emptyCommon from './images/empty-common.svg'
import emptyCoffee from './images/empty-coffee.svg'
import emptyLunch from './images/empty-lunch.svg'
import { PublicChatRoomType } from './model'

type PublicChatRoomPresentation = {
	name: string
	img: string
	emptyImg: string
}

const rooms: Record<PublicChatRoomType, PublicChatRoomPresentation> = {
	UPPER_ECHELON: {
		name: 'lobby.chat_room.upper_echelon',
		img: task,
		emptyImg: emptyTalk,
	},
	COFFEE: {
		name: 'lobby.chat_room.coffee',
		img: coffee,
		emptyImg: emptyCoffee,
	},
	LUNCH: {
		name: 'lobby.chat_room.lunch',
		img: lunch,
		emptyImg: emptyLunch,
	},
	COMMON_INTERESTS: {
		name: 'lobby.chat_room.common_interests',
		img: common,
		emptyImg: emptyCommon,
	},
	TASK: {
		name: 'lobby.chat_room.task',
		img: task,
		emptyImg: emptyTask,
	},
	LETS_TALK: {
		name: 'lobby.chat_room.lets_talk',
		img: talk,
		emptyImg: emptyTalk,
	},
}

export default rooms
