import { FC, ReactNode } from 'react'
import { UserRoles } from './security-models'
import { useAuthenticatedUser } from './session'

export function isTrainer(roles: Array<UserRoles>): boolean {
	return roles.includes(UserRoles.UPPER_ECHELON)
}

export function isCompanyAdmin(roles: Array<UserRoles>): boolean {
	return roles.includes(UserRoles.COMPANY_ADMIN)
}

export function isAccountOwner(roles: Array<UserRoles>): boolean {
	return roles.includes(UserRoles.ACCOUNT_OWNER)
}

export const ShowIfAuthorized: FC<{ role: UserRoles; children: ReactNode }> = ({
	role,
	children,
}): JSX.Element | null => {
	const authUser = useAuthenticatedUser()

	if (authUser.roles.includes(role)) {
		return <>{children}</>
	}

	return null
}
