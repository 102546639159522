import { Whiteboard } from './whiteboard-models'

type WhiteboardState = {
	whiteboards: Whiteboard[]
	whiteboardDefinition?: Whiteboard
}

const initialState: WhiteboardState = {
	whiteboards: [],
}

type WhiteboardsAction =
	| {
			type: 'SSE_WHITEBOARD_OPENED'
			payload: {
				whiteboardDefinition: Whiteboard
			}
	  }
	| {
			type: 'SSE_WHITEBOARD_CLOSED'
	  }
	| {
			type: 'SSE_WHITEBOARD_COLLABORATION_STATE_CHANGED'
			payload: {
				whiteboardDefinition: Whiteboard
			}
	  }
	| {
			type: 'SSE_WHITEBOARDS_STATE_CHANGED'
			payload: { whiteboards: Whiteboard[] }
	  }

export function reducer(
	state: WhiteboardState = initialState,
	action: WhiteboardsAction
): WhiteboardState {
	switch (action.type) {
		case 'SSE_WHITEBOARD_OPENED': {
			return {
				...state,
				whiteboardDefinition: action.payload.whiteboardDefinition,
			}
		}
		case 'SSE_WHITEBOARD_CLOSED':
			return {
				...state,
				whiteboardDefinition: undefined,
			}
		case 'SSE_WHITEBOARD_COLLABORATION_STATE_CHANGED':
			return {
				...state,
				whiteboardDefinition: action.payload.whiteboardDefinition,
			}
		case 'SSE_WHITEBOARDS_STATE_CHANGED':
			return {
				...state,
				whiteboards: action.payload.whiteboards,
			}
		default:
			return state
	}
}
