export const ActivityFeed: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="20"
		height="20"
		viewBox="0 0 20 20"
	>
		<g id="Group_25819" data-name="Group 25819" transform="translate(-64 -183)">
			<rect
				id="Rectangle_4119"
				data-name="Rectangle 4119"
				width="20"
				height="20"
				transform="translate(64 183)"
				fill="none"
			/>
			<g id="Group_25820" data-name="Group 25820" transform="translate(64 183)">
				<path
					id="Path_12150"
					data-name="Path 12150"
					d="M13.9,6.538,7,10.686a.287.287,0,0,0-.128.336l.9,3.22a.085.085,0,0,0,.167-.02L8.138,12a.622.622,0,0,1,.217-.435l5.63-4.908c.059-.06-.02-.168-.089-.119"
					fill="currentColor"
				/>
				<path
					id="Path_12151"
					data-name="Path 12151"
					d="M16.543,0H3.457A3.46,3.46,0,0,0,0,3.456V16.543A3.461,3.461,0,0,0,3.457,20H16.543A3.461,3.461,0,0,0,20,16.543V3.456A3.467,3.467,0,0,0,16.543,0m.573,4.365L14.588,15.851a.529.529,0,0,1-.85.3L10.775,13.8a.528.528,0,0,0-.731.069L8.523,15.624a.524.524,0,0,1-.9-.178L6.281,11.308a.53.53,0,0,0-.365-.345l-2.657-.7a.528.528,0,0,1-.069-1L16.4,3.763a.533.533,0,0,1,.721.6"
					fill="currentColor"
				/>
			</g>
		</g>
	</svg>
)
