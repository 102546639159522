import { PlayerRanking } from '../types'
import { PlacedScore } from 'connectivity/beelday-client'

import { FC } from 'react'
import styled from '@emotion/styled'
import { Colors } from 'common/presentation/colors'
import Place from './podium-place'
import Winner from './podium-winner'
import gold from './images/gold-medal.svg'
import silver from './images/silver-medal.svg'
import bronze from './images/bronze-medal.svg'
import { ui } from '@beelday/common'
import {
	UserId,
	UserProfile,
	PROFILE_MISSING,
	UserKey,
	Avatar,
} from 'common/types'

const PodiumContainer = styled.div`
	background-color: ${Colors.indigoBlue};
	border-radius: 20px 20px 0 0;
	padding: 20px 55px 10px 55px;
	display: flex;
	align-items: flex-end;
	justify-content: center;

	${ui.responsive.desktop1440} {
		justify-content: space-around;
		padding: 40px 55px 20px 55px;
	}
`

type PlaceContainerProps = {
	type?: 'WINNER'
}

const PlaceContainer = styled.div<PlaceContainerProps>`
	background-color: ${Colors.white};
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin: 0 25px;
	height: ${props => (props.type === 'WINNER' ? '120px' : '100px')};
	width: 135px;
	position: relative;

	${ui.responsive.desktop1440} {
		margin: 0;
		height: ${props => (props.type === 'WINNER' ? '150px' : '130px')};
	}
`

const Medal = styled.img`
	position: absolute;
	right: 10px;
	top: 0px;
	height: 37px;
	width: 25px;
`

const withProfiles = (
	place: PlacedScore,
	profiles: Record<UserId, UserProfile | PROFILE_MISSING>
): {
	score: number
	players: Array<UserKey & { name?: string; avatar?: Avatar }>
} => ({
	...place,
	players: place.players.map(p => {
		const profile = profiles[p.id]
		return {
			...p,
			...profile,
		}
	}),
})

type Props = {
	winner?: PlayerRanking
	first: PlacedScore
	second: PlacedScore
	third: PlacedScore
	profiles: Record<UserId, UserProfile | PROFILE_MISSING>
	total: number
}

const Podium: FC<Props> = ({
	winner,
	first,
	second,
	third,
	profiles,
	total,
}) => {
	const firstPlace = withProfiles(first, profiles)
	const secondPlace = withProfiles(second, profiles)
	const thirdPlace = withProfiles(third, profiles)
	const winnerProfile = winner && profiles[winner.id]
	const winnerWithProfile = winner
		? {
				...winner,
				...winnerProfile,
		  }
		: undefined

	return (
		<PodiumContainer>
			<PlaceContainer>
				<Place place={secondPlace} total={total} />
				<Medal src={silver} />
			</PlaceContainer>
			{winnerWithProfile ? (
				<Winner player={winnerWithProfile} />
			) : (
				<PlaceContainer type="WINNER">
					<Place place={firstPlace} total={total} />
					<Medal src={gold} />
				</PlaceContainer>
			)}
			<PlaceContainer>
				<Place place={thirdPlace} total={total} />
				<Medal src={bronze} />
			</PlaceContainer>
		</PodiumContainer>
	)
}

export default Podium
