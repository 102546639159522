import { Activity } from './script-model'
import { intl } from '@beelday/common'

const ScriptActivityDescription = ({
	activity,
}: {
	activity: Activity
}): JSX.Element => {
	const translate = intl.useTranslate()

	const groupingDescription = (type: string, size: number) => {
		return `${translate(
			`training.script.tool.GROUPING.type.${type}`
		)} • ${translate('training.script.tool.GROUPING.size', { count: size })}`
	}
	return (
		<>
			{activity.type === 'GROUPING' ? (
				groupingDescription(
					activity.state.configuration.groupingType,
					activity.state.configuration.groupSize
				)
			) : activity.type === 'KNOWLEDGE_CHECK' ? (
				<intl.Translate count={activity.state.configuration.questions.length}>
					training.script.tool.KNOWLEDGE_CHECK.questions
				</intl.Translate>
			) : (
				translate(`training.script.tool.${activity.type}.description`)
			)}
		</>
	)
}

export default ScriptActivityDescription
