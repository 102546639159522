import { Children, FC, FunctionComponent, ReactNodeArray, useMemo } from 'react'
import { SceneVideoUserStream } from './scene-video-user-stream'
import styled from '@emotion/styled'
import {
	PresenterGridLayout,
	PresenterStyle,
	Section,
} from './presenter-grid-layout'
import { css, SerializedStyles } from '@emotion/react'
import { Colors } from '../../common/presentation/colors'
import { VideoAspectRatioContainer } from 'room/common/presentation/aspect-ratio-container'
import { GrowthRowContainer } from 'common/presentation/containers'
import EqualVideosContainer from './equal-videos-container'
import { SceneVideoUser } from 'video-conference-media/model/types'

type SceneVideoUsers = {
	sceneVideoUsers: SceneVideoUser[]
}

const containsPresenter = (videoDefinitions: SceneVideoUser[]): boolean => {
	const presenters = videoDefinitions.filter(definition => definition.presenter)
	if (presenters.length > 1) {
		throw new Error(`Too many presenters. Max: 1, actual: ${presenters.length}`)
	}
	return presenters.length === 1
}

export const getVideoSizeAndMargins = (
	numberOfVideos: number
): SerializedStyles => {
	const toSize = (size: number, margin: number) => {
		return css`
			width: ${size}%;
			height: ${size}%;
			margin: ${margin}%;
		`
	}
	if (numberOfVideos <= 4) return toSize(48, 1)
	if (numberOfVideos <= 6) return toSize(31, 1)
	if (numberOfVideos <= 12) return toSize(23, 1)
	if (numberOfVideos <= 20) return toSize(18, 1)
	if (numberOfVideos <= 30) return toSize(14.5, 1)
	throw new Error(`Unsupported number of videos in a grid: ${numberOfVideos}`)
}

const CenteredFlexContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	flex-flow: row wrap;
	position: relative;
`

export const ChildrenEqualGrid: FC<{ children: ReactNodeArray }> = ({
	children,
}) => {
	const videoSizeAndMargins = getVideoSizeAndMargins(children.length)
	return (
		<CenteredFlexContainer>
			{Children.map(children, c => (
				<div css={videoSizeAndMargins}>{c}</div>
			))}
		</CenteredFlexContainer>
	)
}

type LayoutProps = {
	layout: PresenterGridLayout
}

const PresenterGridContainer = styled.div`
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-areas:
		'left   top    right'
		'bottom bottom bottom';
	${(props: LayoutProps) => `
        grid-template-columns: ${props.layout.left.width * 100}% ${
		props.layout.top.width * 100
	}% ${props.layout.right.width * 100}%;
        grid-template-rows: ${props.layout.top.height * 100}% ${
		props.layout.bottom.height * 100
	}%;
    `};
	justify-content: stretch;
	justify-items: stretch;
	align-content: stretch;
	align-items: stretch;
`

const areaFlex = css`
	display: flex;
	justify-content: center;
	justify-items: center;
	align-content: center;
	align-items: center;
`

const horizontalAreaFlex = css`
	${areaFlex};
	flex-flow: row wrap;
`

const verticalAreaFlex = css`
	${areaFlex};
	flex-flow: column wrap;
`

const topAreaFlex = css`
	${horizontalAreaFlex};
	grid-area: top;
`

const bottomAreaFlex = css`
	${horizontalAreaFlex};
	flex-flow: row wrap;
	grid-area: bottom;
`

const leftAreaFlex = css`
	${verticalAreaFlex};
	grid-area: left;
`

const rightAreaFlex = css`
	${verticalAreaFlex};
	grid-area: right;
`

const SizedSceneVideoUserStreams: FunctionComponent<
	Section & PresenterStyleProps
> = ({ sceneVideoUsers, videoSize, presenterStyle }) => {
	return (
		<>
			{sceneVideoUsers.map(sceneVideoUser => (
				<SceneVideoUserStream
					key={sceneVideoUser.userId}
					sceneVideoUser={sceneVideoUser}
					presenterColor={
						presenterStyle === 'spotlight' ? Colors.brightCyan : Colors.fuchsia
					}
					css={css`
						width: ${videoSize.width * 100}%;
						height: ${videoSize.height * 100}%;
					`}
					scale={0.95}
				/>
			))}
		</>
	)
}

const PresenterGrid: FunctionComponent<SceneVideoUsers & PresenterStyleProps> =
	({ sceneVideoUsers, presenterStyle }) => {
		const layout = new PresenterGridLayout(sceneVideoUsers, presenterStyle)
		return (
			<PresenterGridContainer layout={layout}>
				<div css={leftAreaFlex}>
					<SizedSceneVideoUserStreams
						presenterStyle={presenterStyle}
						{...layout.left}
					/>
				</div>
				<div css={topAreaFlex}>
					<SizedSceneVideoUserStreams
						presenterStyle={presenterStyle}
						{...layout.top}
					/>
				</div>
				<div css={rightAreaFlex}>
					<SizedSceneVideoUserStreams
						presenterStyle={presenterStyle}
						{...layout.right}
					/>
				</div>
				<div css={bottomAreaFlex}>
					<SizedSceneVideoUserStreams
						presenterStyle={presenterStyle}
						{...layout.bottom}
					/>
				</div>
			</PresenterGridContainer>
		)
	}

type PresenterStyleProps = {
	presenterStyle: PresenterStyle
}

type VideoGridProps = SceneVideoUsers & PresenterStyleProps

export const VideoGrid: FunctionComponent<VideoGridProps> = ({
	sceneVideoUsers,
	presenterStyle,
}) => {
	const isTherePresenter = useMemo(
		() => containsPresenter(sceneVideoUsers),
		[sceneVideoUsers]
	)
	return (
		<GrowthRowContainer>
			{isTherePresenter ? (
				<VideoAspectRatioContainer>
					<PresenterGrid
						sceneVideoUsers={sceneVideoUsers}
						presenterStyle={presenterStyle}
					/>
				</VideoAspectRatioContainer>
			) : (
				<EqualVideosContainer videos={sceneVideoUsers} />
			)}
		</GrowthRowContainer>
	)
}

export const EqualVideoGrid: FC<{ sceneVideoUsers: SceneVideoUser[] }> = ({
	sceneVideoUsers,
}) => (
	<GrowthRowContainer>
		<EqualVideosContainer videos={sceneVideoUsers} />
	</GrowthRowContainer>
)
