import { colors } from '@beelday/common'
import styled from '@emotion/styled'
import { UUID } from 'common/types'
import { useEffect, useRef, useState } from 'react'
import { ChatIcon } from '../room/common/presentation/icon/chat-icon'

const Notification = styled.div`
	align-items: center;
	background-color: ${colors.pinkRed};
	border-radius: 50%;
	border: 2px solid ${colors.white};
	color: white;
	display: flex;
	font-size: 11px;
	font-weight: 500;
	height: 20px;
	justify-content: center;
	position: absolute;
	right: 10px;
	top: 7px;
	width: 20px;
`

type Props = {
	trainingId: UUID
	open: boolean
}

export const ChatNotifications: React.FC<Props> = ({ trainingId, open }) => {
	const chatNotificationsRef = useRef<HTMLElement>(null)
	const element = chatNotificationsRef.current
	const [notifications, setNotifications] = useState<number>()

	useEffect(() => {
		if (element) {
			const newMessagesListener = (customEvent: Event) => {
				const newMessages = (customEvent as CustomEvent).detail
				setNotifications(newMessages)
			}

			element.addEventListener('new-message', newMessagesListener)
			return () => {
				element.removeEventListener('new-message', newMessagesListener)
			}
		}
	}, [element])

	const openProp = open ? 'true' : 'false'

	return (
		<>
			{notifications && notifications !== 0 ? (
				<Notification>{notifications > 99 ? 99 : notifications}</Notification>
			) : null}
			<ChatIcon />
			<beelday-chat-notifications
				trainingid={trainingId}
				open={openProp}
				ref={chatNotificationsRef}
			/>
		</>
	)
}

export default ChatNotifications
