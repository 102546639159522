import { Config } from 'common/config'
import Logger from 'common/logger'
import {
	awaitConnection,
	getAwaitedPromise,
	Key,
} from 'connectivity/awaited-connections'

const logger = new Logger('RELIABLE_FETCH')

const toKey = (input: RequestInfo, init?: RequestInit): Key => {
	return JSON.stringify(['RELIABLE_FETCH', input, init])
}

const repeatableFetch = (
	input: RequestInfo,
	init?: RequestInit,
	attempt?: number
): Promise<Response> => {
	const attemptNumber = attempt || 1
	const timeout = Config.requestRetryDelay
	return new Promise<Response>(resolve => {
		if (attemptNumber > 1) {
			logger.log(
				`Requesting (attempt number ${attemptNumber} with timeout of ${timeout}ms): ${toKey(
					input,
					init
				)}`
			)
		}
		fetch(input, init)
			.then(resolve)
			.catch(() =>
				setTimeout(
					() => resolve(repeatableFetch(input, init, attemptNumber + 1)),
					timeout
				)
			)
	})
}

export const fetchReliably = (
	input: RequestInfo,
	init?: RequestInit
): Promise<Response> => {
	const key = toKey(input, init)
	const awaitedPromise = getAwaitedPromise(key)
	if (awaitedPromise) {
		logger.warn(
			`Request (${key}) has been blocked because it is already awaiting completion`
		)
		return awaitedPromise.then(response => response.clone())
	}
	return awaitConnection(key, repeatableFetch(input, init))
}
