import { Config } from 'common/config'
import { RoomAddress } from 'common/types'
import { useMemo } from 'react'
import useScriptAPI from './use-script-api'

type UseScript = {
	start: (address: RoomAddress) => Promise<void>
	next: (address: RoomAddress) => Promise<void>
	prev: (address: RoomAddress) => Promise<void>
	confirm: (address: RoomAddress) => Promise<void>
}

const useScript = (): UseScript => {
	const api = useScriptAPI(Config.beeldayBackendUrl)

	return useMemo(
		() => ({
			start: api.start,
			next: api.next,
			prev: api.prev,
			confirm: api.confirm,
		}),
		[api]
	)
}

export default useScript
