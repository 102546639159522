import { FC, MouseEvent } from 'react'
import styled from '@emotion/styled'
import cancel from '../presentation/images/cancel2.svg'
import { Colors } from './colors'

const Button = styled.div`
	width: 32px;
	height: 32px;
	background-color: ${Colors.brightGray};
	border-radius: 16px;
	padding: 0;
	border: none;
	cursor: pointer;

	&:focus {
		outline: none;
	}
`

const Img = styled.img`
	height: 100%;
	width: 100%;
`

type Props = {
	onClick: (event: MouseEvent<HTMLDivElement>) => void
}

const CloseButton: FC<Props> = ({ onClick }) => (
	<Button onClick={onClick}>
		<Img src={cancel} />
	</Button>
)

export default CloseButton
