import styled from '@emotion/styled'
import { CSSProperties, useCallback } from 'react'
import colors from '../colors'
import { useTranslate } from '../intl'
import { Input } from './input'
import search from './search.svg'

const PrimarySearchContainer = styled.div`
	position: relative;
	align-items: center;
	border: 1px solid ${colors.brightGray};
	border-radius: 10px;
	display: flex;
`

const PrimarySearchInput = styled(Input)`
	border: none;
	flex: 1;
	padding-right: 80px;
`

const PrimarySearchBtn = styled.button`
	align-items: center;
	background-color: ${colors.indigoBlue};
	border: none;
	border-radius: 10px;
	box-shadow: 3px 5.2px 10px 0 rgba(79, 23, 190, 0.2);
	cursor: pointer;
	display: flex;
	height: 57px;
	justify-content: center;
	margin: -1px -1px -1px 0;
	width: 70px;
	position: absolute;
	right: 0;
	top: 0;

	&:active {
		opacity: 0.6;
	}
`

const PrimarySearchIcon = styled.img`
	height: 25px;
	width: 25px;
`

type Props = {
	placeholder: string
	style?: CSSProperties
	value: string
	onChange: (val: string) => void
	theme: 'PRIMARY' | 'SECONDARY'
}

export const Search = ({
	placeholder,
	style,
	value,
	onChange,
	theme,
}: Props): JSX.Element => {
	const translate = useTranslate()
	const handleKey = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const { value } = event.currentTarget
			onChange(value)
		},
		[onChange]
	)

	return theme === 'PRIMARY' ? (
		<PrimarySearchContainer style={style}>
			<PrimarySearchInput
				placeholder={translate(placeholder)}
				value={value}
				onChange={handleKey}
			/>
			<PrimarySearchBtn>
				<PrimarySearchIcon src={search} />
			</PrimarySearchBtn>
		</PrimarySearchContainer>
	) : (
		<SecondarySearchContainer style={style}>
			<SecondarySearchInput
				placeholder={translate(placeholder)}
				value={value}
				onChange={handleKey}
			/>
			<SecondarySearchBtn>
				<SecondarySearchIcon src={search} />
			</SecondarySearchBtn>
		</SecondarySearchContainer>
	)
}

const SecondarySearchContainer = styled.div`
	align-items: center;
	background-color: ${colors.white};
	box-shadow: 0 5px 25px 0 rgba(94, 92, 154, 0.1);
	border-radius: 10px;
	display: flex;
	margin-bottom: 24px;
	padding-right: 5px;
`

const SecondarySearchInput = styled(Input)`
	border: none;
	flex: 1;
	height: 55px;
`

const SecondarySearchBtn = styled.button`
	align-items: center;
	background-color: ${colors.greenishCyan};
	border: none;
	border-radius: 10px;
	display: flex;
	height: 45px;
	justify-content: center;
	width: 45px;
`

const SecondarySearchIcon = styled.img`
	height: 20px;
	width: 20px;
`

export default Search
