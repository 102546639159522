import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { Colors } from 'common/presentation/colors'

const fadeInKeyframes = keyframes`
	0% { opacity: 0 }
	100% { opacity: 1 }
`

type Props = {
	backgroundColor: string
}

const SplashContainer = styled.div`
	width: 100vw;
	height: 100vh;
	position: fixed;
	opacity: 1;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-size: 100% 100%;
	background-color: ${(props: Props) => props.backgroundColor};
	animation: ${fadeInKeyframes} 150ms linear 0s 1 normal forwards;
	z-index: 100;
`

export const SplashMessage = styled.div`
	color: ${Colors.white};
	font-family: Ubuntu;
	font-size: 35px;
	font-weight: 500;
	line-height: 2em;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	white-space: pre-line;
`

export default SplashContainer
