import { FC, useEffect, useRef, useState } from 'react'
import { throttle } from 'lodash'
import styled from '@emotion/styled'
import { GroupId, ReviewUser, GroupReview, UserId } from 'common/types'
import MotionStreamVideo from './motion-stream-video'
import { MotionStream } from './motion-stream-video'
import { calculateActive } from './review-common'
import { SceneVideoUser } from 'video-conference-media'

type Props = {
	activeGroup: GroupReview
	users: ReviewUser[]
	videoUsers: Record<UserId, SceneVideoUser>
	groups: Pick<GroupReview, 'members' | 'id' | 'properties'>[]
	onSelect?: (groupId: GroupId | null) => unknown
}

export const StreamsContainer = styled.div`
	position: relative;
	height: 100%;
	display: flex;
	width: 100%;
	margin-top: 10px;
`

export const ReviewMediaMotionGrid: FC<Props> = ({
	activeGroup,
	users,
	videoUsers,
	groups,
	onSelect,
}) => {
	const streamsRef = useRef<HTMLDivElement>(null)
	const [streams, setStreams] = useState<MotionStream[]>([])
	const [hoverGroup, setHoverGroup] = useState<string | null>(null)

	useEffect(() => {
		const onResize = throttle(() => {
			const layoutItems = calculateLayoutItems(
				streamsRef.current?.getBoundingClientRect() || { width: 0, height: 0 },
				groups,
				users
			)
			setStreams(layoutItems.streams)
		}, 500)
		window.addEventListener('resize', onResize)
		onResize()

		return () => window.removeEventListener('resize', onResize)
	}, [groups, users])

	return (
		<StreamsContainer ref={streamsRef}>
			{streams.map(stream => {
				return (
					<MotionStreamVideo
						key={stream.groupMember.id}
						defaultValues={stream.defaultValues}
						activeValues={stream.activeValues}
						user={stream.groupMember}
						isActive={activeGroup.id === stream.groupMember.group?.id}
						videoUsers={videoUsers}
						showOverlay={stream.groupMember.group?.id === hoverGroup}
						onHover={setHoverGroup}
						onSelect={onSelect}
					/>
				)
			})}
		</StreamsContainer>
	)
}

function calculateLayoutItems(
	containerSize: {
		width: number
		height: number
	},
	groups: Pick<GroupReview, 'members' | 'id' | 'properties'>[],
	users: ReviewUser[]
): {
	streams: MotionStream[]
} {
	const streams: MotionStream[] = []
	let groupIndex = 0
	const spacing = 4
	let height = 0
	let width = (height * 16) / 9

	const unGroupUsers = users.filter(user => !user.group)

	const reviewGroups = [
		...groups,
		{
			id: null,
			members: unGroupUsers,
		},
	]

	let helpGroupId = reviewGroups[0]?.id

	const containerInnerSize = {
		height: 0,
		width: 0,
	}

	let offsetLeft = 0
	let countBottomRow = 5
	const offsetFromActive = 10
	const rowsCount = Math.floor(users.length / countBottomRow)

	if (users.length > 10) {
		countBottomRow = 7
	}

	if (containerSize.width > 600) {
		countBottomRow = 10
	}

	width =
		(containerSize.width - spacing * (countBottomRow - 1)) / countBottomRow
	height = (width * 4) / 4

	const bottomRowsHeight = rowsCount ? rowsCount * height : height

	containerInnerSize.height =
		containerSize.height - bottomRowsHeight - offsetFromActive
	containerInnerSize.width = containerSize.width

	reviewGroups.forEach((item, index) => {
		item.members.forEach((member, memberIndex) => {
			const user = users.find(user => user.id === member.id)
			if (!user) return

			if (helpGroupId === user.group?.id) {
				if (memberIndex === 0) {
					groupIndex = 0
				} else {
					groupIndex = groupIndex + 1
				}
			} else {
				helpGroupId = user.group?.id ?? helpGroupId
				groupIndex = 0
			}
			streams.push({
				defaultValues: {
					width: width,
					height: height,
					mediaLeft: 0,
					mediaTop: 0,
				},
				activeValues: {
					width: 0,
					height: 0,
					mediaLeft: 0,
					mediaTop: 0,
				},
				groupIndex: groupIndex,
				groupSize: item.members.length,
				index,
				groupMember: user,
			})
		})
	})

	streams.forEach((member, index) => {
		const rowIndex = Math.floor(index / countBottomRow)

		const { rows, rowSize } = calculateRows(member.groupSize)

		const defaultValues = calculateDefault(
			index,
			width,
			height,
			containerSize,
			spacing,
			rowIndex,
			offsetLeft
		)

		const activeValues = calculateActive(
			rows,
			rowSize,
			member.groupSize,
			width,
			height,
			containerSize,
			containerInnerSize,
			member.groupIndex,
			true
		)

		member.defaultValues = defaultValues
		member.activeValues = activeValues

		if (index + 1 === (rowIndex + 1) * countBottomRow) {
			offsetLeft = 0
		} else {
			offsetLeft++
		}
	})

	return { streams }
}

function calculateDefault(
	index: number,
	width: number,
	height: number,
	containerSize: { width: number; height: number },
	spacing: number,
	rowIndex: number,
	offsetLeft: number
): {
	width: number
	height: number
	mediaLeft: number
	mediaTop: number
} {
	let mediaLeft = 0
	let mediaTop = 0
	const rowOffset = rowIndex * height + rowIndex * spacing

	mediaTop = containerSize.height - rowOffset - height
	mediaLeft = offsetLeft * width + offsetLeft * spacing

	return {
		width,
		height,
		mediaLeft,
		mediaTop,
	}
}

function calculateRows(groupSize: number): {
	rows: number
	rowSize: number
} {
	let rows = 1
	let rowSize = 2

	if (groupSize > 9) {
		rowSize = 3
		rows = 4
	} else if (groupSize > 6) {
		rows = 3
		rowSize = 3
	} else if (groupSize > 4) {
		rows = 3
		rowSize = 2
	} else if (groupSize > 2) {
		rows = 2
	}

	return {
		rows,
		rowSize,
	}
}

export default ReviewMediaMotionGrid
