import { ui } from '@beelday/common'
import styled from '@emotion/styled'
import { Colors } from 'common/presentation/colors'

export const TitleContainer = styled.div`
	position: absolute;
	left: 50%;
	top: 0;
	margin-top: 10px;
	transform: translateX(-50%);
	text-align: center;
`

export const SmallTitle = styled.div`
	color: ${Colors.lightGray};
	font-size: 14px;
	font-weight: 500;
	line-height: 26px;
	text-transform: uppercase;
`

export const Title = styled.div`
	color: ${Colors.darkIndigo};
	font-family: Ubuntu;
	font-weight: 500;
	font-size: 24px;
	line-height: 26px;
	text-transform: uppercase;
`

export const ColumnLayout = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;
	margin-top: 20px;

	${ui.responsive.tablet} {
		flex: 0 0 400px;
		margin-left: 20px;
		margin-top: 0;
	}

	${ui.responsive.desktop1440} {
		margin-left: 30px;
	}

	${ui.responsive.desktop} {
		flex: 0 0 500px;
	}
`

export const VideoContainer = styled.div`
	display: flex;
	flex-grow: 1;
	flex-wrap: wrap;
	align-content: center;
`

export const VideoStreamContainer = styled.div`
	margin: 2px;
	height: 60px;
	width: 92px;

	@media (min-width: 1280px) and (min-height: 900px) {
		margin: 5px;
		height: 75px;
		width: 115px;
	}
`

export const ReviewContainer = styled.div`
	display: flex;
	height: 100%;
`

export const ReviewMediaContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	min-height: 0;
	width: 100%;
	padding: 0 20px;

	${ui.responsive.tablet} {
		flex-wrap: nowrap;
		flex-direction: row;
	}

	${ui.responsive.desktop1440} {
		padding: 0 30px;
	}
`

export function calculateActive(
	rows: number,
	rowSize: number,
	groupSize: number,
	width: number,
	height: number,
	containerSize: { width: number; height: number },
	containerInnerSize: { width: number; height: number },
	groupIndex: number,
	alignTop?: boolean
): {
	mediaLeft: number
	mediaTop: number
	width: number
	height: number
} {
	let mediaHeight = height
	let mediaWidth = width
	let mediaLeft = 0
	let mediaTop = 0

	const leftOffset = (rows * rowSize - groupSize) / rowSize
	mediaWidth = containerInnerSize.width / rowSize
	mediaHeight = (mediaWidth * 9) / 16

	if (rows * mediaHeight > containerInnerSize.height) {
		mediaHeight = containerInnerSize.height / rows
		mediaWidth = (mediaHeight * 16) / 9
	}

	const baseTopOffset =
		(containerInnerSize.height - rows * mediaHeight) / 2 + 70

	const containerOffset = alignTop ? 0 : baseTopOffset > 70 ? baseTopOffset : 70

	const initialColumnOffset = (rowSize * mediaWidth) / 2
	const columnOffset = (groupIndex % rowSize) * mediaWidth
	const rowOffset = Math.floor(groupIndex / rowSize) * mediaHeight
	const currentRowIndex = rows * rowSize - rowSize
	mediaTop = containerOffset + rowOffset

	if (groupIndex >= currentRowIndex) {
		mediaLeft =
			leftOffset * mediaWidth +
			containerSize.width / 2 -
			initialColumnOffset +
			columnOffset
	} else {
		mediaLeft = containerSize.width / 2 - initialColumnOffset + columnOffset
	}

	return {
		width: mediaWidth,
		height: mediaHeight,
		mediaLeft,
		mediaTop,
	}
}
