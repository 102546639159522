export const StartIcon: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		width="21.6"
		height="24"
		viewBox="0 0 21.6 24"
		xmlSpace="preserve"
	>
		<path
			d="M16.8,24c-0.5,0-0.9-0.3-1.1-0.7l-2.4-4.8c-0.1-0.1-0.1-0.3-0.1-0.5H12v3.6
	c0,0.7-0.5,1.2-1.2,1.2l0,0c-0.7,0-1.2-0.5-1.2-1.2l0,0V18H8.4c0,0.2,0,0.4-0.1,0.5l-2.4,4.8C5.7,23.7,5.3,24,4.8,24
	c-0.2,0-0.4,0-0.5-0.1c-0.3-0.1-0.5-0.4-0.6-0.7c-0.1-0.3-0.1-0.6,0.1-0.9L5.9,18H1.2C0.5,18,0,17.5,0,16.8l0,0V3.9h21.6v12.9
	c0,0.7-0.5,1.2-1.2,1.2l0,0h-4.7l2.1,4.3c0.1,0.3,0.2,0.6,0.1,0.9s-0.3,0.6-0.6,0.7C17.2,24,17,24,16.8,24z M9.3,7.1
	C9,7.1,8.6,7.3,8.4,7.5S8.1,8.1,8,8.4v4.3c0,0.2,0.1,0.5,0.2,0.6c0.2,0.3,0.4,0.5,0.8,0.6c0.1,0,0.2,0,0.3,0c0.2,0,0.4-0.1,0.6-0.2
	l3.6-2.1c0.2-0.1,0.3-0.3,0.4-0.4c0.2-0.3,0.2-0.6,0.1-1c-0.1-0.3-0.3-0.6-0.6-0.8L9.9,7.3C9.8,7.2,9.5,7.1,9.3,7.1L9.3,7.1z
	 M21.6,2.9H0V1.2C0,0.5,0.5,0,1.2,0l0,0h19.2c0.7,0,1.2,0.5,1.2,1.2l0,0L21.6,2.9L21.6,2.9z"
		/>
	</svg>
)
