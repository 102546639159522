import { ui, intl } from '@beelday/common'
import styled from '@emotion/styled'
import { useState } from 'react'
import { HeadingP } from './heading'
import { ToolTipP } from './tool-tip'

const TaskNameInput = styled.input`
	border: 3px solid #babbd3;
	border-radius: 20px;
	background-color: white;
	padding: 4% 1% 3.5% 5%;
	width: 100%;
	margin: 8px 0;

	font-family: Ubuntu;
	font-size: 20px;
	font-weight: 500;
	color: #babbd3;
	::placeholder {
		font-family: Ubuntu;
		font-size: 20px;
		font-weight: 500;
		color: #babbd3;
	}

	&:focus {
		outline: none;
	}
`

type Props = {
	onTaskSet(taskName: string): void
}

export const GroupTaskName: React.FC<Props> = ({ onTaskSet }) => {
	const [taskName, setTaskName] = useState<string>('')
	const translate = intl.useTranslate()

	return (
		<ui.FlexColumn style={{ alignItems: 'center' }}>
			<HeadingP>
				<intl.Translate>task-name</intl.Translate>
			</HeadingP>
			<ToolTipP>
				<intl.Translate>set-task-name</intl.Translate>
			</ToolTipP>
			<TaskNameInput
				autoFocus
				type="text"
				placeholder={translate('task-name')}
				onChange={e => setTaskName(e.target.value)}
				onKeyDown={e => e.key === 'Enter' && onTaskSet(taskName)}
				value={taskName}
			/>
			<ui.ButtonPrimary
				onClick={() => onTaskSet(taskName)}
				style={{ marginTop: '23px' }}
			>
				<intl.Translate>confirm-task-name</intl.Translate>
			</ui.ButtonPrimary>
		</ui.FlexColumn>
	)
}

export default GroupTaskName
