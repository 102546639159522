import { assign } from 'lodash'

export const merge = <T>(...objects: T[]): T => {
	if (objects.length === 1) return objects[0]
	return assign({}, ...objects)
}

export type Maybe<T> = T | null

export const isDefined = <T>(value: T | null | undefined): value is T =>
	value != null
