import { FC } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'common/redux'
import ConfiguredKnowledgeCheckTrainee from './configured-knowledge-check-trainee'
import ConfiguredKnowledgeCheckReviewTrainee from './configured-knowledge-check-review-trainee'
import { UUID } from 'common/types'

const ConfiguredKnowledgeCheckViewTrainee: FC<{ knowledgeCheckId: UUID }> = ({
	knowledgeCheckId,
}) => {
	const isReview = useSelector(
		(state: RootState) => state.knowledgeChecks.isReview
	)
	return !isReview ? (
		<ConfiguredKnowledgeCheckTrainee knowledgeCheckId={knowledgeCheckId} />
	) : (
		<ConfiguredKnowledgeCheckReviewTrainee
			knowledgeCheckId={knowledgeCheckId}
		/>
	)
}

export default ConfiguredKnowledgeCheckViewTrainee
