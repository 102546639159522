import { Config } from 'common/config'
import Logger from 'common/logger'

const logger = new Logger('AWAITED_CONNECTIONS')

type AwaitedConnection = {
	promise: Promise<any>
	warningTimeoutId: number
}

export type Key = string

export enum ConnectionQuality {
	Good,
	Bad,
}

export type ConnectionQualityObserver = (quality: ConnectionQuality) => void
const awaitedConnections: Record<Key, AwaitedConnection> = {}
const warnedRequestKeys: Set<Key> = new Set<Key>()
let connectionQualityObserver: ConnectionQualityObserver | undefined = undefined

const notifyConnectionQualityObserver = (quality: ConnectionQuality) => {
	if (!connectionQualityObserver) {
		throw Error('No ConnectionQualityObserver has been registered')
	}
	connectionQualityObserver(quality)
}

const warn = (key: Key) => {
	if (warnedRequestKeys.size === 0) {
		logger.warn('Connection status: Bad')
		notifyConnectionQualityObserver(ConnectionQuality.Bad)
	}
	warnedRequestKeys.add(key)
}

const unwarn = (key: Key) => {
	if (warnedRequestKeys.delete(key) && warnedRequestKeys.size === 0) {
		logger.warn('Connection status: Good')
		notifyConnectionQualityObserver(ConnectionQuality.Good)
	}
}

export const awaitConnection = (
	key: Key,
	promise: Promise<any>
): Promise<any> => {
	awaitedConnections[key] = {
		promise,
		warningTimeoutId: window.setTimeout(
			() => warn(key),
			Config.requestExecutionTimeBeforeWarning
		),
	}
	return promise.then(response => {
		clearAwaitedConnection(key)
		return response
	})
}

export const onConnectionQualityChange = (
	observer: ConnectionQualityObserver
) => {
	if (connectionQualityObserver) {
		throw Error('Do not register multiple ConnectionQualityObservers!')
	}
	connectionQualityObserver = observer
}

export const removeConnectionQualityObserver = (
	observer: ConnectionQualityObserver
) => {
	if (connectionQualityObserver !== observer) {
		throw Error(
			'The quality change observer being removed is different from the one registered!'
		)
	}
	connectionQualityObserver = undefined
}

const clearAwaitedConnection = (key: Key) => {
	window.clearTimeout(awaitedConnections[key].warningTimeoutId)
	unwarn(key)
	delete awaitedConnections[key]
}

export const getAwaitedPromise = (key: Key): Promise<any> | undefined => {
	return awaitedConnections[key]?.promise
}
