import { css } from '@emotion/react'

export const ellipsis = css`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`

export const zoomOnHover = css`
	transition: transform 0.2s ease-in-out;
	&:hover {
		transform: scale(1.05);
	}
`
export const showOnHover = css`
	opacity: 0;
	transition: opacity 0.2s ease-in-out;
	&:hover {
		opacity: 1;
	}
`

export const hideIfFirst = css`
	:first-child {
		display: none;
	}
`

export const flexRow = css`
	display: flex;
	flex-direction: row;
`
export const flexMiddle = css`
	display: flex;
	align-items: center;
`
export const flexCenterAll = css`
	display: flex;
	align-items: center;
	justify-content: center;
`
export const absoluteCenter = css`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`
export const fillSpace = css`
	flex-grow: 1;
`
export const clickable = css`
	cursor: pointer;
`

export const fillWidth = css`
	width: 100%;
`

export const fillHeight = css`
	height: 100%;
`
export const fillWidthHeight = css`
	height: 100%;
	width: 100%;
`

export const fillScreen = css`
	width: 100vw;
	height: 100vh;
`

const RGB_PATTERN = /rgba?\s*\(\s*(\d+\s*,\s*\d+\s*,\s*\d+\s*)/
const opacityToHex = (opacity: number): string => {
	const hexString = Math.ceil(opacity * 255).toString(16)
	return hexString.length === 1 ? '0' + hexString : hexString
}
export const setColorOpacity = (cssColor: string, opacity: number): string => {
	if (!cssColor) {
		return cssColor
	} else if (cssColor.startsWith('#')) {
		return cssColor.substring(0, 7) + opacityToHex(opacity)
	} else if (cssColor.startsWith('rgb')) {
		const match = RGB_PATTERN.exec(cssColor)
		if (match) return `rgba(${match[1]}, ${opacity})`
	}

	throw new Error('Unknown color format: ' + cssColor)
}

export const decodeHexColor = (hexColor: string): [number, number, number] => {
	const hex = hexColor.replace('#', '')
	const r = parseInt(hex.substring(0, 2), 16)
	const g = parseInt(hex.substring(2, 4), 16)
	const b = parseInt(hex.substring(4, 6), 16)
	return [r, g, b]
}

export const global = css`
	@keyframes horizontal-shaking {
		0% {
			transform: translateX(0);
		}
		7% {
			transform: translateX(5px);
		}
		19% {
			transform: translateX(-5px);
		}
		32% {
			transform: translateX(5px);
		}
		40% {
			transform: translateX(0);
		}
	}

	.horizontal-shaking {
		animation: horizontal-shaking 0.7s ease-in-out;
		animation-delay: 0.2s;
		animation-iteration-count: 2;
	}
`

export const inputNoArrows = css`
	-moz-appearance: textfield;
	::-webkit-outer-spin-button,
	::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
`

let scrollbarWidth: number | undefined
export const getScrollbarWidth = (): number => {
	if (scrollbarWidth == null) {
		const inner = document.createElement('p')
		inner.style.width = '100%'
		inner.style.height = '200px'

		const outer = document.createElement('div')
		outer.style.position = 'absolute'
		outer.style.top = '0px'
		outer.style.left = '0px'
		outer.style.visibility = 'hidden'
		outer.style.width = '200px'
		outer.style.height = '150px'
		outer.style.overflow = 'hidden'
		outer.appendChild(inner)

		document.body.appendChild(outer)
		const w1 = inner.offsetWidth
		outer.style.overflow = 'scroll'
		let w2 = inner.offsetWidth
		if (w1 == w2) {
			w2 = outer.clientWidth
		}

		document.body.removeChild(outer)

		scrollbarWidth = w1 - w2
	}
	return scrollbarWidth
}

export const COLORS = [
	'#4ae3a8',
	'#f57655',
	'#bb56d6',
	'#32d4c4',
	'#9570d8',
	'#063d66',
	'#a099a7',
	'#f880aa',
	'#db1864',
	'#4f17be',
	'#fac55b',
	'#33ce92',
	'#31018f',
	'#46c35a',
	'#fc0f6c',
	'#707070',
]

export const colorFromSeed = (seed: string): string => {
	let hash = 0
	for (let i = 0; i < seed.length; i++) {
		hash = seed.charCodeAt(i) + ((hash << 5) - hash)
	}

	const index = Math.abs(hash) % COLORS.length

	const color = COLORS[index]

	return color
}

export default {
	global,
	ellipsis,
	zoomOnHover,
	flexRow,
	fillSpace,
	clickable,
	inputNoArrows,
	getScrollbarWidth,
}
