export const BoardletIcon: React.FC = () => (
	<svg
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		width="21.6"
		height="24"
		viewBox="0 0 21.6 24"
		xmlSpace="preserve"
	>
		<g transform="translate(0 0)">
			<g>
				<g>
					<path
						d="M8.8,4.1H1.2C0.5,4.1,0,3.6,0,2.9V1.2C0,0.5,0.5,0,1.2,0c0,0,0,0,0,0h7.6
				C9.4,0,10,0.5,10,1.2v1.7C10,3.6,9.4,4.1,8.8,4.1"
					/>
					<path
						d="M20.4,4.1h-7.6c-0.7,0-1.2-0.5-1.2-1.2V1.2c0-0.7,0.5-1.2,1.2-1.2h7.6
				c0.7,0,1.2,0.5,1.2,1.2c0,0,0,0,0,0v1.7C21.6,3.6,21.1,4.1,20.4,4.1L20.4,4.1"
					/>
					<path d="M6,9.2l1.8,2.4H2.2l1.4-1.8l1,1.2L6,9.2z" />
					<path
						d="M8.8,5.3H1.2C0.5,5.3,0,5.9,0,6.5v6.4c0,0.7,0.5,1.2,1.2,1.2h7.6c0.7,0,1.2-0.5,1.2-1.2V6.5
				C10,5.9,9.4,5.3,8.8,5.3C8.8,5.3,8.8,5.3,8.8,5.3 M8.5,11c0,1-0.8,1.8-1.8,1.8c0,0,0,0,0,0H3.2c-1,0-1.8-0.8-1.8-1.8V8.4
				c0-1,0.8-1.8,1.8-1.8c0,0,0,0,0,0h3.6c1,0,1.8,0.8,1.8,1.8c0,0,0,0,0,0V11z"
					/>
					<path d="M17.6,19.1l1.8,2.4h-5.6l1.4-1.8l1,1.2L17.6,19.1z" />
					<path
						d="M20.4,15.2h-7.6c-0.7,0-1.2,0.5-1.2,1.2v6.4c0,0.7,0.5,1.2,1.2,1.2c0,0,0,0,0,0h7.6
				c0.7,0,1.2-0.5,1.2-1.2v0v-6.4C21.6,15.8,21.1,15.2,20.4,15.2L20.4,15.2 M20.2,20.9c0,1-0.8,1.8-1.8,1.8c0,0,0,0,0,0h-3.6
				c-1,0-1.8-0.8-1.8-1.8v-2.6c0-1,0.8-1.8,1.8-1.8c0,0,0,0,0,0h3.6c1,0,1.8,0.8,1.8,1.8c0,0,0,0,0,0V20.9z"
					/>
					<path
						d="M8.8,15.3H1.2c-0.7,0-1.2,0.5-1.2,1.2c0,0,0,0,0,0v6.3C0,23.5,0.5,24,1.2,24c0,0,0,0,0,0
				h7.6c0.7,0,1.2-0.5,1.2-1.2v-6.4C10,15.8,9.4,15.3,8.8,15.3 M5.2,21h-3c-0.4,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8h3
				c0.4,0,0.8,0.4,0.8,0.8C6.1,20.7,5.7,21,5.2,21 M7.8,18.8H2.2c-0.4,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8h5.5
				c0.4,0,0.8,0.4,0.8,0.8C8.6,18.4,8.2,18.8,7.8,18.8"
					/>
					<path
						d="M20.4,5.3h-7.6c-0.7,0-1.2,0.5-1.2,1.2v6.3c0,0.7,0.5,1.2,1.2,1.2h7.6
				c0.7,0,1.2-0.5,1.2-1.2c0,0,0,0,0,0V6.5C21.6,5.9,21.1,5.3,20.4,5.3L20.4,5.3 M16.9,11.1h-3c-0.4,0-0.8-0.3-0.8-0.8c0,0,0,0,0,0
				c0-0.4,0.3-0.8,0.8-0.8c0,0,0,0,0,0h3c0.4,0,0.8,0.4,0.8,0.8C17.7,10.7,17.3,11.1,16.9,11.1C16.9,11.1,16.9,11.1,16.9,11.1
				 M19.4,8.9h-5.5c-0.4,0-0.8-0.3-0.8-0.8c0,0,0,0,0,0c0-0.4,0.3-0.8,0.8-0.8c0,0,0,0,0,0h5.5c0.4,0,0.8,0.4,0.8,0.8
				C20.2,8.5,19.8,8.9,19.4,8.9C19.4,8.9,19.4,8.9,19.4,8.9"
					/>
				</g>
			</g>
		</g>
	</svg>
)
