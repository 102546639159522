import styled from '@emotion/styled'
import { Colors } from 'common/presentation/colors'
import { UserProfile } from 'common/types'
import { FC } from 'react'
import { KnowledgeCheckReview } from '../knowledge-check'
import KnowledgeCheckHeader from './knowledge-check-header'
import ThreeColumnsVideoGrid from 'room/training-room/ice-breaker/presentation/three-columns-video-grid'
import KnowledgeCheckReviewQuestion from './knowledge-check-review-question'
import { SceneVideoUser } from 'video-conference-media'

const Title = styled.div`
	color: ${Colors.indigoBlue};
	font-size: 20px;
	font-weight: 700;
	left: 50%;
	line-height: 24px;
	max-width: 600px;
	position: absolute;
	text-align: center;
	top: 15px;
	transform: translateX(-50%);
`

const Container = styled.div`
	background-color: ${Colors.white};
	border-radius: 42px 18px 18px 18px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0 auto;
	max-width: 700px;
	overflow: hidden;
	width: 100%;
`

const ContainerInner = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	position: relative;
	padding: 20px 0;
`

const Content = styled.div`
	display: flex;
	flex-flow: row wrap;
	flex-direction: column;
	position: absolute;
	left: 0;
	top: 20px;
	bottom: 20px;
	right: 0;
`

const ScrollContent = styled.div`
	overflow: auto;
	padding: 0 20px;
`

type Props = {
	title?: string
	sceneVideoUsers: SceneVideoUser[]
	userProfile: UserProfile | undefined | null
	review: KnowledgeCheckReview
}

const KnowledgeCheckReviewGridView: FC<Props> = ({
	title,
	sceneVideoUsers,
	userProfile,
	review,
}) => {
	return (
		<ThreeColumnsVideoGrid sceneVideoUsers={sceneVideoUsers}>
			{title ? <Title>{title}</Title> : null}
			<Container>
				<KnowledgeCheckHeader
					title={review.name}
					userProfile={userProfile}
					progress={{
						currentValue: review.currentQuestionIndex + 1,
						maxValue: review.questions.length,
					}}
				/>
				<ContainerInner>
					<Content>
						<ScrollContent>
							<KnowledgeCheckReviewQuestion
								question={review.questions[review.currentQuestionIndex]}
								number={review.currentQuestionIndex + 1}
								countUsers={review.users.length}
								useCorrectAnswers={review.useCorrectAnswers}
							/>
						</ScrollContent>
					</Content>
				</ContainerInner>
			</Container>
		</ThreeColumnsVideoGrid>
	)
}

export default KnowledgeCheckReviewGridView
