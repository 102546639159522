import { UserId } from '@beelday/common'
import { PermissionMode } from 'common/types'

type RoomPermissionModeChangedSSE = {
	permission: 'SHARE_SCREEN'
	mode: PermissionMode
	allowedUsers: UserId[]
	requestedByUsers: UserId[]
}

export type ScreenShareState = {
	permissionMode: PermissionMode
	allowedUsers: UserId[]
	requestedByUsers: UserId[]
}

const initialState: ScreenShareState = {
	permissionMode: 'NO_ONE',
	allowedUsers: [],
	requestedByUsers: [],
}

type ScreenShareAction = {
	type: 'SSE_PERMISSION_MODE_CHANGED'
	payload: RoomPermissionModeChangedSSE
}

export function reducer(
	state: ScreenShareState = initialState,
	action: ScreenShareAction
): ScreenShareState {
	switch (action.type) {
		case 'SSE_PERMISSION_MODE_CHANGED':
			const { payload } = action
			if (payload.permission === 'SHARE_SCREEN') {
				return {
					...state,
					permissionMode: payload.mode,
					allowedUsers: payload.allowedUsers,
					requestedByUsers: payload.requestedByUsers,
				}
			} else {
				return state
			}

		default:
			return state
	}
}
