import { createAsyncThunk } from '@reduxjs/toolkit'
import { Config } from 'common/config'
import { RootState } from 'common/redux'
import { createSelector } from 'reselect'
import { selectJoinedRoomAddress } from 'room/common/redux'
import { Slide, SlideId } from './slide-models'
import { getSlidesApi } from './slides-api'

type SlidePayload = {
	slideId: SlideId
}

type SlidesState = {
	active: boolean
	available: boolean
	slideId: SlideId | null
	loadingAll: boolean
	slides: Slide[]
}

const initialState: SlidesState = {
	active: false,
	available: false,
	slideId: null,
	loadingAll: false,
	slides: [],
}

const fetchAll = createAsyncThunk<Slide[], void, { state: RootState }>(
	'slides/fetchAll',
	async (_, { getState }) => {
		const roomAddress = selectJoinedRoomAddress(getState())
		if (!roomAddress) return []
		const api = getSlidesApi(Config.beeldayBackendUrl, Config.slidesApiUrl)
		return api.fetchSlides(roomAddress)
	},
	{
		condition: (_, { getState }) => !getState().slides.loadingAll,
	}
)

type SlidesAction =
	| {
			type: 'SSE_SLIDE_STARTED'
			payload: SlidePayload
	  }
	| {
			type: 'SSE_SLIDE_CLOSED'
	  }
	| {
			type: 'SSE_SLIDE_DEFINITIONS_AVAILABILITY'
			payload: { available: boolean }
	  }
	| {
			type: 'slides/fetchAll/fulfilled'
			payload: Slide[]
	  }
export function reducer(
	state: SlidesState = initialState,
	action: SlidesAction
): SlidesState {
	switch (action.type) {
		case 'SSE_SLIDE_STARTED': {
			return {
				...state,
				active: true,
				slideId: action.payload.slideId,
			}
		}
		case 'SSE_SLIDE_CLOSED':
			return {
				...state,
				active: false,
			}
		case 'SSE_SLIDE_DEFINITIONS_AVAILABILITY':
			return {
				...state,
				available: action.payload.available,
			}
		case 'slides/fetchAll/fulfilled':
			return {
				...state,
				slides: action.payload,
				loadingAll: false,
			}
		case fetchAll.rejected.type:
			return {
				...state,
				loadingAll: false,
			}
		default:
			return state
	}
}

export const selectActiveSlideId = (state: RootState): SlideId | null =>
	state.slides.active ? state.slides.slideId : null

export const selectAllSlides = createSelector(
	(state: RootState) => state.slides,
	slides => slides.slides
)
export const selectAreSlidesAvaliable = (state: RootState): boolean =>
	state.slides.available

export const actions = {
	fetchAll,
}
