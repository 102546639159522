import styled from '@emotion/styled'
import { FC, useLayoutEffect, useRef, useState } from 'react'
import { DropzoneRootProps, useDropzone } from 'react-dropzone'
import Cropper from 'react-easy-crop'
import { Area } from 'react-easy-crop/types'
import { FlexCenterAll } from '.'
import colors from '../colors'
import empty from '../images/upload-empty.svg'
import error from '../images/upload-error.svg'
import { Translate } from '../intl'
import styles from '../styles'
import { Button } from './button'
import responsive from './responsive'
import { Slider } from './slider'
import { Spinner } from './spinner'

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: stretch;
	min-height: 300px;
	min-width: 350px;
	background-color: ${colors.white};
	${responsive.smallTablet} {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: stretch;
		min-height: 300px;
		min-width: 350px;
		background-color: ${colors.white};
		padding: 20px;
	}
`

const DropContainer = styled.div<DropzoneRootProps>`
	border-width: 2px;
	border-style: ${props => (props.isDragActive ? 'solid' : 'dashed')};
	border-color: ${props =>
		props.isDragActive ? colors.indigoBlue : colors.brightGray};
	border-radius: 25px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: 1;
	padding: 30px;
	cursor: pointer;
`

const Title = styled.div`
	color: ${colors.black};
	font-family: Ubuntu;
	font-size: 24px;
	font-weight: 500;
	margin-top: 20px;
	margin-bottom: 15px;
	text-align: center;
	${styles.ellipsis};
`

const Subtitle = styled.span`
	color: ${colors.lightGray};
	font-family: Ubuntu;
	font-size: 14px;
	${styles.ellipsis};
`

const Img = styled.img`
	height: 80px;
`

const ButtonsContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 35px;
`

const CropperContainer = styled.div`
	position: relative;
	flex: 1;
	display: flex;
	cursor: move;
`

const ZoomSlider = styled(Slider)`
	position: absolute;
	bottom: 12px;
	left: 20px;
	right: 20px;
`

type State = 'UPLOAD' | 'LOADING' | 'ERROR' | 'CROPPED' | 'CROPPING'

const createImage = (url: string): Promise<HTMLImageElement> =>
	new Promise(resolve => {
		const image = new Image()
		image.addEventListener('load', () => resolve(image))
		image.src = url
	})

const getCroppedImg = async (
	imageSrc2: string,
	pixelCrop: Area,
	outMimeType?: string
) => {
	const image = await createImage(imageSrc2)
	const canvas = document.createElement('canvas')
	const ctx = canvas.getContext('2d')
	canvas.width = pixelCrop.width
	canvas.height = pixelCrop.height
	ctx?.drawImage(image, 0 - pixelCrop.x, 0 - pixelCrop.y)
	return new Promise<Blob>((resolve, reject) => {
		canvas.toBlob(res => {
			if (res) {
				resolve(res)
			} else {
				reject(new Error('Failed to generate blob from canvas'))
			}
		}, outMimeType)
	})
}

const readFile = (file: Blob): Promise<string> =>
	new Promise(resolve => {
		const reader = new FileReader()
		reader.addEventListener('load', () => resolve(String(reader.result)), false)
		reader.readAsDataURL(file)
	})

const MIN_ZOOM = 0.5
const MAX_ZOOM = 3
const CROPPER_PADDING = 40

type Props = {
	withCrop: boolean
	aspectRatio?: {
		width: number
		height: number
	}
	round?: boolean
	onDone: (file: Blob) => unknown
	className?: string
}
export const UploadImage: FC<Props> = ({
	withCrop,
	round,
	onDone,
	className,
	aspectRatio,
}) => {
	const isMobile = responsive.useIsMobile()
	const [state, setState] = useState<State>('UPLOAD')
	const [imageSrc, setImageSrc] = useState('')
	const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>()
	const [zoom, setZoom] = useState(1)
	const [crop, setCrop] = useState({ x: 0, y: 0 })

	const done = async () => {
		setState('LOADING')
		if (croppedAreaPixels === undefined) {
			setState('ERROR')
			return
		}
		const croppedImage = await getCroppedImg(
			imageSrc,
			croppedAreaPixels,
			'image/png'
		)
		onDone(croppedImage)
	}

	const onDrop = async (acceptedFiles: File[]) => {
		setState('LOADING')

		const file = acceptedFiles[0]
		if (withCrop) {
			const imageDataUrl = await readFile(file)
			setImageSrc(imageDataUrl)
			setState('CROPPING')
		} else {
			onDone(file)
		}
	}

	const {
		isDragActive,
		getRootProps,
		getInputProps,
		open: pickFile,
	} = useDropzone({
		onDrop,
		multiple: false,
		accept: 'image/*',
	})

	const [windowSize, setWindowSize] = useState({
		width: 150,
		height: 150,
	})

	const cropperContainerRef = useRef<HTMLDivElement>(null)
	const cropper = cropperContainerRef.current
	useLayoutEffect(() => {
		if (cropper) {
			const { clientWidth, clientHeight } = cropper

			const cropperHeight =
				Math.min(clientWidth, clientHeight) - CROPPER_PADDING * 2

			if (aspectRatio && cropperHeight !== windowSize.height) {
				setWindowSize({
					width: (cropperHeight * aspectRatio.width) / aspectRatio.height,
					height: cropperHeight,
				})
			} else if (cropperHeight !== windowSize.height) {
				setWindowSize({
					width: cropperHeight,
					height: cropperHeight,
				})
			}
		}
	}, [windowSize, cropper, aspectRatio])

	return (
		<Container className={className}>
			{state === 'LOADING' ? (
				<FlexCenterAll style={{ flexDirection: 'column', flex: 1 }}>
					<Title style={{ marginBottom: '20px' }}>
						<Translate>upload.image.uploading</Translate>
					</Title>
					<Spinner size={50} />
				</FlexCenterAll>
			) : state === 'ERROR' ? (
				<>
					<DropContainer {...getRootProps({ isDragActive })}>
						<input {...getInputProps()} />
						<Img src={error} />
						<Title>
							<Translate>upload.image.error</Translate>
						</Title>
						<Subtitle>
							<Translate>upload.image.error_try_again</Translate>
						</Subtitle>
					</DropContainer>
					<ButtonsContainer>
						<Button type="button" onClick={() => setState('UPLOAD')}>
							<Translate>upload.image.try_again</Translate>
						</Button>
					</ButtonsContainer>
				</>
			) : state === 'CROPPING' ? (
				<>
					<CropperContainer ref={cropperContainerRef}>
						<Cropper
							image={imageSrc}
							crop={crop}
							zoom={zoom}
							minZoom={MIN_ZOOM}
							maxZoom={MAX_ZOOM}
							aspect={1}
							restrictPosition={false}
							cropSize={{ width: windowSize.width, height: windowSize.height }}
							cropShape={round ? 'round' : 'rect'}
							onCropChange={setCrop}
							onCropComplete={(_, croppedAreaPixelsComplete) =>
								setCroppedAreaPixels(croppedAreaPixelsComplete)
							}
							onZoomChange={setZoom}
							showGrid={false}
							style={{
								containerStyle: {
									backgroundColor: colors.paleGray,
									clipPath: 'inset(0% 0% 0% 0% round 25px)',
								},
								cropAreaStyle: {
									color: 'rgba(0, 0, 0, 0.7)',
								},
							}}
						/>
						<ZoomSlider
							value={zoom}
							min={0.5}
							max={3}
							onChange={setZoom}
							withButtons={true}
						/>
					</CropperContainer>
					<ButtonsContainer>
						<Button
							style={
								isMobile
									? { width: '100%', backgroundColor: colors.indigoBlue }
									: {}
							}
							type="button"
							onClick={done}
						>
							<Translate>upload.image.confirm</Translate>
						</Button>
					</ButtonsContainer>
				</>
			) : (
				<>
					<DropContainer {...getRootProps({ isDragActive })}>
						<input {...getInputProps()} />
						<Img src={empty} />
						<Title>
							<Translate>upload.image.dnd</Translate>
						</Title>
						<Subtitle>
							<Translate>upload.image.browse</Translate>
						</Subtitle>
					</DropContainer>
					<ButtonsContainer>
						<Button
							style={
								isMobile
									? { width: '100%', backgroundColor: colors.indigoBlue }
									: {}
							}
							type="button"
							onClick={pickFile}
						>
							<Translate>upload.image.select</Translate>
						</Button>
					</ButtonsContainer>
				</>
			)}
		</Container>
	)
}

export default UploadImage
