import styled from '@emotion/styled'
import colors from '../colors'

export const BackgroundCurtain = styled.div`
	cursor: pointer;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 11;
	opacity: 1;
	background-color: ${colors.transparentPaleGray};
`
