export const resizeImage = (
	blob: Blob,
	maxWidth: number,
	maxHeight: number,
	outMimeType?: string
): Promise<Blob> => {
	return new Promise((resolve, reject) => {
		const img = new Image()
		img.src = URL.createObjectURL(blob)
		img.onload = () => {
			const width = img.width
			const height = img.height

			if (width <= maxWidth && height <= maxHeight) {
				resolve(blob)
			} else {
				const canvas = document.createElement('canvas')
				const ctx = canvas.getContext('2d')

				if (!ctx) {
					reject(new Error("Couldn't create canvas context"))
					return
				}

				canvas.width = maxWidth
				canvas.height = maxHeight

				ctx.drawImage(img, 0, 0, maxWidth, maxHeight)

				canvas.toBlob(blob => {
					if (blob) resolve(blob)
					else reject(new Error('Could not generate image, blob is null'))
				}, outMimeType)
			}
		}
	})
}

export const toDataUrl = (blob: Blob): Promise<string> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.onload = () => {
			if (typeof reader.result === 'string') resolve(reader.result)
			else reject(new Error('Could not generate image, blob is null'))
		}
		reader.readAsDataURL(blob)
	})
}
