import { Fragment, FC, useMemo } from 'react'
import * as intl from 'react-intl'
import { getLocale, getMessages } from './actual-locale'
import baseMessages, { Translations } from './messages'

type Props = {
	messages: Translations
}

export const IntlProvider: FC<Props> = ({ children, messages }) => {
	const locale = getLocale()
	const mergedMessages = useMemo(
		() => ({
			...getMessages(baseMessages, locale),
			...getMessages(messages, locale),
		}),
		[messages, locale]
	)

	return (
		<intl.IntlProvider
			textComponent={Fragment}
			locale={locale}
			messages={mergedMessages}
		>
			{children}
		</intl.IntlProvider>
	)
}

export default IntlProvider
