type Props = {
	color?: string
}
export const SendIcon: React.FC<Props> = ({ color }) => (
	<svg
		id="Group_24570"
		data-name="Group 24570"
		xmlns="http://www.w3.org/2000/svg"
		width="16.211"
		height="16.016"
		viewBox="0 0 16.211 16.016"
	>
		<g id="Group_24571" data-name="Group 24571" transform="translate(0.076)">
			<g id="Group_24570-2" data-name="Group 24570">
				<path
					id="Path_11355"
					data-name="Path 11355"
					d="M199.969,184.473h0c-.011-.053.024.049,0,0h0c-.028-.05.043.043,0,0s.051.029,0,0c-.012-.007.012.006,0,0-.051-.025-.946.011-1,0h0c-.058-.011.058,0,0,0h0c-.058.007.055-.022,0,0l-15,6c-.261.107-.014-.282,0,0s-.271.919,0,1l7,2,2,6c.081.271-.282-.013,0,0v1c.269,0,.9-.749,1-1l6-15h0c.022-.054-.007.058,0,0,0-.014,0,.015,0,0C199.973,184.417,199.98,184.529,199.969,184.473Zm-13,6,10-4-6,5Zm6,7-1-5,5-5Z"
					transform="translate(-183.85 -184.456)"
					fill={color}
				/>
			</g>
		</g>
		<g
			id="Group_24573"
			data-name="Group 24573"
			transform="translate(2.999 9.82)"
		>
			<g id="Group_24572" data-name="Group 24572">
				<path
					id="Path_11356"
					data-name="Path 11356"
					d="M189.969,194.473a.736.736,0,0,0-1,0l-2,1a.736.736,0,0,0,0,1c.13.131.829,1,1,1s-.131-.869,0-1l2-1A.736.736,0,0,0,189.969,194.473Z"
					transform="translate(-186.773 -194.277)"
					fill={color}
				/>
			</g>
		</g>
		<g id="Group_24575" data-name="Group 24575" transform="translate(0 9.82)">
			<g id="Group_24574" data-name="Group 24574">
				<path
					id="Path_11357"
					data-name="Path 11357"
					d="M185.969,194.473a.736.736,0,0,0-1,0l-1,1a.739.739,0,0,0,0,1c.131.13.829,0,1,0s-.13.13,0,0l1-1A.736.736,0,0,0,185.969,194.473Z"
					transform="translate(-183.774 -194.277)"
					fill={color}
				/>
			</g>
		</g>
		<g
			id="Group_24577"
			data-name="Group 24577"
			transform="translate(3.999 12.82)"
		>
			<g id="Group_24576" data-name="Group 24576">
				<path
					id="Path_11358"
					data-name="Path 11358"
					d="M189.969,197.473a.736.736,0,0,0-1,0l-1,1a.736.736,0,0,0,0,1c.13.13-.171,1,0,1s.87-.87,1-1l1-1A.736.736,0,0,0,189.969,197.473Z"
					transform="translate(-187.773 -197.277)"
					fill={color}
				/>
			</g>
		</g>
	</svg>
)
