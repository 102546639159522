import styled from '@emotion/styled'
import { map } from 'lodash'
import { ReactNode } from 'react'
import colors from '../colors'
import styles from '../styles'

type Props<T> = {
	options: Array<{ key: T; label: ReactNode }>
	onChange: (value: T) => unknown
	value: T
}

const Container = styled.div`
	background-color: ${colors.inactiveGray};
	border-radius: 20px;
	height: 40px;
	position: relative;
	padding: 2px;
`
const Options = styled.div`
	position: relative;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	font-size: 12px;
	width: 100%;
	height: 100%;
`
const Option = styled.div`
	cursor: pointer;
	flex-basis: 50%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	${styles.ellipsis};
	z-index: 1;
`
const Switcher = styled.div`
	background-color: ${colors.white};
	border-radius: 18px;
	position: absolute;
	left: 0;
	height: 100%;
	width: 50%;
	box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
	transition: transform 0.3s ease-in-out;
`
export function ToggleLarge<T>({
	options,
	onChange,
	value,
}: Props<T>): JSX.Element {
	const idx = options.findIndex(option => option.key === value)
	const optionSize = 100 / options.length
	return (
		<Container>
			<Options>
				<Switcher
					style={{
						transform: `translateX(${100 * idx}%)`,
						width: `${optionSize}%`,
					}}
				/>
				{map(options, option => (
					<Option key={'' + option.key} onClick={() => onChange(option.key)}>
						{option.label}
					</Option>
				))}
			</Options>
		</Container>
	)
}
