import styled from '@emotion/styled'

export const MobileContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`
export const MobileContent = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`
