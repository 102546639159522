import { useEffect, useState } from 'react'

export const useTimer = (
	seconds: number
): { done?: boolean; value: number } => {
	const [value, setValue] = useState(seconds)
	useEffect(() => {
		setValue(seconds)
		let interval: NodeJS.Timeout
		if (seconds) {
			const start = Date.now()
			interval = setInterval(() => {
				const newValue = Math.floor(seconds - (Date.now() - start) / 1000)
				setValue(Math.max(0, newValue))
				if (newValue <= 0) {
					clearInterval(interval)
				}
			}, 250)
		}
		return () => clearInterval(interval)
	}, [seconds])

	return {
		value,
		done: value === 0,
	}
}

export const toTimeString = (seconds: number): string => {
	const date = new Date(seconds * 1000)
	if (seconds < 3600) return date.toISOString().substr(14, 5)
	return date.toISOString().substr(11, 8)
}
