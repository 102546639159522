import {
	Group,
	GroupAttention,
	GroupId,
	HandPosition,
	None,
	RaisedHand,
	TrainerJoining,
	TrainerUnavailableBecauseInOtherGroup,
	TrainerUnavailableBecauseTrainerBeRightBack,
	TrainerUnavailableReason,
} from 'common/types'

export const none: None = { type: 'NONE' }
export const raisedHand: RaisedHand = { type: 'RAISED_HAND' }
export const trainerUnavailableBecauseTrainerBeRightBack: TrainerUnavailableBecauseTrainerBeRightBack =
	{
		reason: TrainerUnavailableReason.BE_RIGHT_BACK,
		type: 'TRAINER_UNAVAILABLE',
	}
export const trainerUnavailableBecauseInOtherGroup: TrainerUnavailableBecauseInOtherGroup =
	{
		reason: TrainerUnavailableReason.IN_OTHER_GROUP,
		type: 'TRAINER_UNAVAILABLE',
	}
export const trainerJoining = (countdown: number): TrainerJoining => ({
	type: 'TRAINER_JOINING',
	countdown,
})

export function trainerBecameUnavailable(reason: TrainerUnavailableReason) {
	switch (reason) {
		case TrainerUnavailableReason.IN_OTHER_GROUP:
			return trainerUnavailableBecauseInOtherGroup
		case TrainerUnavailableReason.BE_RIGHT_BACK:
			return trainerUnavailableBecauseTrainerBeRightBack
	}
}

export const isHandRaised = (group: Pick<Group, 'attention'>): boolean => {
	return group.attention.type !== 'NONE'
}

export const fromHandPositionChanged = (handPosition: HandPosition) =>
	handPosition === HandPosition.UP ? raisedHand : none

export const updateAttention = (
	group: Group,
	newAttention: GroupAttention
): Group => {
	return {
		...group,
		attention: newAttention,
	}
}

export const updateAttentionFor = (
	groups: Record<GroupId, Group>,
	id: GroupId,
	newAttention: GroupAttention
): Record<GroupId, Group> => {
	const updated = updateAttention(groups[id], newAttention)
	return {
		...groups,
		[id]: updated,
	}
}
