import { RootState } from 'common/redux'
import { UserId, PROFILE_MISSING, UserProfile } from 'common/types'
import { difference, keyBy, map } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useUserApi } from 'connectivity/beelday-hooks'
import { addUsers } from 'users/redux'
import { arrays } from '@beelday/common'

const fetchingIds = new Set<UserId>()

const useFetchMissingProfiles = (userIds: UserId[]): void => {
	const client = useUserApi()
	const all = useSelector((state: RootState) => state.users.all)
	const dispatch = useDispatch()

	const missing = userIds
		.filter(userId => all[userId] === undefined)
		.filter(missing => !fetchingIds.has(missing))

	if (missing.length > 0) {
		arrays.addAllToSet(fetchingIds, ...missing)
		client
			.getUserProfiles(missing)
			.then(profiles => {
				const missingProfiles = difference(missing, map(profiles, 'id')).reduce(
					(acc: Record<UserId, UserProfile | PROFILE_MISSING>, id) => {
						acc[id] = null
						return acc
					},
					{}
				)
				const profilesById = {
					...keyBy(profiles, 'id'),
					...missingProfiles,
				}
				dispatch(addUsers(profilesById))
			})
			.finally(() => {
				arrays.removeAllFromSet(fetchingIds, ...missing)
			})
	}
}

export default useFetchMissingProfiles
