import { FC } from 'react'
import styled from '@emotion/styled'
import { RoomContainer } from 'room/common/presentation/room-container'
import HardwareCheck from 'hardware/hardware-check'
import RoomHeader from '../../room/common/presentation/room-header'
import { use100vh } from 'react-div-100vh'
import { ui } from '@beelday/common'
import { useDispatch } from 'app-redux'
import { setIsExpandableCompanyCard } from 'organization/redux'

type Props = {
	onEnter: () => void
}

const Container = styled.div`
	flex: 1;
	display: flex;
	overflow: auto;
`

const PreparationScreen: FC<Props> = ({ onEnter }) => {
	const containerHeight = use100vh()
	const dispatch = useDispatch()

	dispatch(setIsExpandableCompanyCard(false))
	return (
		<RoomContainer
			style={{
				height: `${containerHeight}px`,
			}}
		>
			<RoomHeader />
			<Container>
				<HardwareCheck onEnter={onEnter} />
			</Container>

			<ui.BeeldayLogoAbsoluteBottom />
		</RoomContainer>
	)
}

export default PreparationScreen
