import { http } from '@beelday/common'
import { RoomAddress } from 'common/types'
import { useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'

export type ScriptApi = {
	start: (address: RoomAddress) => Promise<void>
	next: (address: RoomAddress) => Promise<void>
	prev: (address: RoomAddress) => Promise<void>
	confirm: (address: RoomAddress) => Promise<void>
}

export const createScriptAPI = (serverUrl: string): ScriptApi => ({
	start: address =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/features/script/next`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
				body: JSON.stringify({ activityContext: address }),
			}
		).then(http.checkStatus),
	next: address =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/features/script/next`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
				body: JSON.stringify({ activityContext: address }),
			}
		).then(http.checkStatus),
	prev: address =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/features/script/previous`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
				body: JSON.stringify({ activityContext: address }),
			}
		).then(http.checkStatus),
	confirm: address =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/features/script/current/confirm`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
				body: JSON.stringify({ activityContext: address }),
			}
		).then(http.checkStatus),
})

const useScriptAPI = (serverUrl: string): ScriptApi => {
	return useMemo(() => createScriptAPI(serverUrl), [serverUrl])
}

export default useScriptAPI
