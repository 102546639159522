import { isFunction } from 'lodash'

export const valueBy = <V>(
	array: string[],
	mapper: V | ((key: string) => V)
): Record<string, V> => {
	const isFn = isFunction(mapper)

	return array.reduce((acc, key) => {
		acc[key] = isFn ? mapper(key) : mapper
		return acc
	}, {} as Record<string, V>)
}

export const pushAll = <V>(target: V[], source: readonly V[]): V[] => {
	source.forEach(i => target.push(i))
	return target
}

export const mapRight = <I, O>(
	arr: Array<I> | null | undefined,
	mapper: (e: I, i: number) => O
): Array<O> => {
	if (!arr) return []
	const res = []
	for (let i = arr.length - 1; i >= 0; i--) {
		res.push(mapper(arr[i], i))
	}
	return res
}

export const findLastIndex = <T>(
	arr: Array<T>,
	callback: (e: T) => boolean
): number | undefined => {
	const len = arr.length >>> 0
	for (let i = len - 1; i >= 0; i--) {
		if (callback(arr[i])) {
			return i
		}
	}
	return undefined
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const pullFirst = <V>(arr: V[], predicate: (e: V) => any): boolean => {
	for (let i = arr.length - 1; i >= 0; i--) {
		if (predicate(arr[i])) {
			arr.splice(i, 1)
			return true
		}
	}

	return false
}

export const addAllToSet = <T>(set: Set<T>, ...elements: T[]): Set<T> => {
	for (const e of elements) {
		set.add(e)
	}
	return set
}

export const removeAllFromSet = <T>(set: Set<T>, ...elements: T[]): Set<T> => {
	for (const e of elements) {
		set.delete(e)
	}
	return set
}

export const replaceBy = <T>(arr: T[], field: keyof T, value: T): T[] => {
	return arr.map(e => {
		if (e[field] === value[field]) {
			return value
		} else {
			return e
		}
	})
}

export const minBy = <T>(
	arr: IterableIterator<T>,
	fn: (e: T) => number | string
): T | undefined => {
	let min: T | undefined
	let minVal: number | string | undefined
	for (const e of arr) {
		const val = fn(e)
		if (min === undefined || minVal === undefined || minVal > val) {
			min = e
			minVal = val
		}
	}

	return min
}

export const arrEqual = <T>(a1: T[], a2: T[]): boolean => {
	if (Object.is(a1, a2)) return true
	if (a1.length !== a2.length) return false
	const len = a1.length
	for (let i = len - 1; i >= 0; i--) {
		if (a1[i] !== a2[i]) return false
	}
	return true
}

export const notEmpty = <TValue>(
	value: TValue | null | undefined
): value is TValue => {
	return value !== null && value !== undefined
}

export class CircularStack<T> {
	private readonly buffer: Array<T | undefined>
	private head: number
	private start: number
	private capacity: number

	constructor(capacity: number) {
		this.buffer = new Array(capacity || 1)
		this.capacity = capacity
		this.start = 0
		this.head = 0
	}

	public push(value: T): void {
		this.buffer[this.head] = value
		this.head = (this.head + 1) % this.capacity

		if (this.start === this.head) {
			this.start = (this.start + 1) % this.capacity
		}
	}

	public pop(): T | undefined {
		this.head = this.head === 0 ? this.capacity - 1 : this.head - 1
		const item = this.buffer[this.head]
		this.buffer[this.head] = undefined
		return item
	}

	public peek(): T | undefined {
		return this.buffer[this.head === 0 ? this.capacity - 1 : this.head - 1]
	}

	public clear(): void {
		this.head = 0
		this.start = 0
		this.buffer.length = 0
	}
}

export class Peekable<T> {
	private delegate: ReadonlyArray<T>
	private idx: number = 0

	constructor(delegate: ReadonlyArray<T>) {
		this.delegate = delegate
	}

	next(): T {
		return this.delegate[this.idx++]
	}
	peek(): T | undefined {
		return this.delegate[this.idx]
	}
	hasNext(): boolean {
		return this.idx < this.delegate.length
	}
}

export const peekable = <T>(array: ReadonlyArray<T>): Peekable<T> => {
	return new Peekable(array)
}
