import {
	Action,
	createLocation,
	createPath,
	History,
	Location,
	LocationDescriptor,
	LocationDescriptorObject,
	LocationListener,
	UnregisterCallback,
} from 'history'
import { isString, pull } from 'lodash'

const createKey = () => Math.random().toString(36).substr(2, 6)
const cleanupSlashes = (path: string): string => {
	if (!path.startsWith('/')) {
		path = '/' + path
	}
	if (path.length > 1 && path.endsWith('/')) {
		path = path.substring(0, path.length - 2)
	}

	return path
}

type HistoryLocationEvent = {
	location: string | LocationDescriptor
	event: 'push' | 'replace' | 'pop'
}
type HistoryGoEvent = {
	amount: number
	event: 'go'
}

const isLocationChanged = (
	l1: Location<unknown>,
	l2: Location<unknown>
): boolean => {
	if (l1.pathname !== l2.pathname) return true
	if (l1.search !== l2.search) return true
	if (l1.hash !== l2.hash) return true
	return false
}

type HistoryEvent = HistoryLocationEvent | HistoryGoEvent
type HistoryDelegate = (event: HistoryEvent) => unknown
export type DelegatingHistory = History & {
	jumpTo: (path: string) => void
	createHref(location: string | LocationDescriptorObject<unknown>): string
}
export const delegatingHistory = (
	delegate: HistoryDelegate,
	basePath: string = '/'
): DelegatingHistory => {
	const listeners: LocationListener[] = []

	const base = cleanupSlashes(basePath)

	let action: Action = 'POP'
	let location: Location = createLocation(base, undefined, createKey())

	const history: DelegatingHistory = {
		get action() {
			return action
		},
		get length() {
			return 1
		},
		get location() {
			return location
		},
		push(path: string | LocationDescriptor<unknown>, state?: unknown): void {
			const newLocation = createLocation(path, state, createKey(), location)
			if (isLocationChanged(location, newLocation)) {
				delegate({ event: 'push', location: newLocation })
			}
		},

		replace(path: string | LocationDescriptor<unknown>, state?: unknown): void {
			const newLocation = createLocation(path, state, createKey(), location)
			if (isLocationChanged(location, newLocation)) {
				delegate({ event: 'replace', location: newLocation })
			}
		},
		go(amount: number): void {
			delegate({
				event: 'go',
				amount: amount,
			})
		},
		goBack(): void {
			history.go(-1)
		},
		goForward(): void {
			history.go(1)
		},
		block(): UnregisterCallback {
			throw new Error('Block is unsupported in delegating history')
		},
		listen(listener: LocationListener<unknown>): UnregisterCallback {
			listeners.push(listener)
			return () => pull(listeners, listener)
		},
		createHref(location: string | LocationDescriptorObject<unknown>): string {
			if (isString(location)) {
				return base + location
			} else {
				return base + createPath(location)
			}
		},

		jumpTo(path: string): void {
			action = 'PUSH'
			const newLocation = createLocation(path, undefined, createKey())
			if (isLocationChanged(location, newLocation)) {
				location = newLocation
				listeners.forEach(l => l(location, action))
			}
		},
	}

	return history
}
