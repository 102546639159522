import { intl } from '@beelday/common'
import { RootState } from 'common/redux'
import { useAuthenticatedBeeldayClient } from 'connectivity/beelday-hooks'
import { getLobbyAddress } from 'interaction-scheme/model/interaction-scheme'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Menu, MenuItem, MenuItemLine } from 'room/common/presentation/menu'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { Back } from '../common/presentation/icon/back'
import { MicMenuItem } from 'features/microphone'
import MiscPanelMenu from '../misc/misc-panel-menu'
import ResumeTrainingMenuItem from 'room/common/presentation/resume-training-menu-item'
import useIsTrainer from 'room/common/use-is-trainer'
import CamMenuItem from 'features/camera/camera-menu-item'

const PublicChatRoomMenu = (): JSX.Element => {
	const beeldayClient = useAuthenticatedBeeldayClient()
	const address = useAssertedJoinedRoomAddress()
	const latestInteractionSchemeEvent = useSelector(
		(state: RootState) => state.eventSource.latestInteractionSchemeEvent
	)
	const isTrainer = useIsTrainer()

	const backToLobby = useCallback(() => {
		const lobby = getLobbyAddress(latestInteractionSchemeEvent)
		lobby && beeldayClient.transfer(address, lobby)
	}, [beeldayClient, address, latestInteractionSchemeEvent])
	return (
		<Menu>
			<MicMenuItem />
			<CamMenuItem />
			<MenuItem
				icon={<Back />}
				onClick={backToLobby}
				label={<intl.Translate>room_menu.back_to_lobby</intl.Translate>}
			/>
			{isTrainer && <ResumeTrainingMenuItem />}
			<MenuItemLine />
			<MiscPanelMenu />
		</Menu>
	)
}

export default PublicChatRoomMenu
