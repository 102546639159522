import { User } from 'common/types'
import { SceneVideoUser } from 'video-conference-media/model/types'

export function filterSceneVideoUsers(
	users: User[],
	sceneVideoUsers: SceneVideoUser[]
): SceneVideoUser[] {
	return sceneVideoUsers.filter(
		sceneVideoUser =>
			users.find(user => user.id === sceneVideoUser.userId) !== undefined
	)
}
