import { v4 as uuidv4 } from 'uuid'
import { http } from '@beelday/common'
import { RoomAddress, UserId } from 'common/types'
import { useMemo } from 'react'

export type ScreenShareAPI = {
	startSharing: (address: RoomAddress) => Promise<void>
	stopSharing: (address: RoomAddress, userId?: UserId) => Promise<void>
	requestPermission: (address: RoomAddress) => Promise<void>
	acceptPermission: (address: RoomAddress, userId: UserId) => Promise<void>
	disablePermission: (address: RoomAddress, userId: UserId) => Promise<void>
}

export const createScreenShareAPI = (serverUrl: string): ScreenShareAPI => {
	const startSharing = () => (address: RoomAddress) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/screen/shared`,
			{
				method: 'PUT',
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
					'Content-Type': 'text/plain',
				},
			}
		).then(http.checkStatus)
	const stopSharing = () => (address: RoomAddress, userId?: UserId) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/screen/shared/stop`,
			{
				method: 'PUT',
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
					'Content-Type': 'application/json',
				},
				body: userId
					? JSON.stringify({ userIdToStopSharing: userId })
					: JSON.stringify({}),
			}
		).then(http.checkStatus)

	const requestPermission = () => (address: RoomAddress) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/screen/permissions/SHARE_SCREEN/requests`,
			{
				method: 'POST',
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		).then(http.checkStatus)

	const acceptPermission = () => (address: RoomAddress, userId: UserId) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/screen/permissions/SHARE_SCREEN/requests/${userId}/accepted`,
			{
				method: 'PUT',
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		).then(http.checkStatus)

	const disablePermission = () => (address: RoomAddress, userId: UserId) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/screen/permissions/SHARE_SCREEN/requests/${userId}`,
			{
				method: 'DELETE',
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		).then(http.checkStatus)

	return {
		startSharing: startSharing(),
		stopSharing: stopSharing(),
		requestPermission: requestPermission(),
		acceptPermission: acceptPermission(),
		disablePermission: disablePermission(),
	}
}

export const useScreenShareAPI = (serverUrl: string): ScreenShareAPI => {
	return useMemo(() => createScreenShareAPI(serverUrl), [serverUrl])
}

export default useScreenShareAPI
