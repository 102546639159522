import { logger, security } from '@beelday/common'
import { FC, useEffect } from 'react'
import { isFirefox } from 'react-device-detect'
import { useMicrophoneTracking } from './use-microphone-tracking'
import { useTalkDispersionReporting } from './use-talk-dispersion-reporting'
import { useVoiceAnalyzer } from './use-voice-analyzer'

type Props = {
	group?: string
}

const UserEngagementReporterEnabled: FC<Props> = ({ group }) => {
	const user = security.useAuthenticatedUser()
	const reportAudioLevel = useTalkDispersionReporting(
		user.id,
		user.email,
		group
	)
	const reportAudioInput = useVoiceAnalyzer(reportAudioLevel)

	useMicrophoneTracking(reportAudioInput)

	return null
}

export const UserEngagementReporter: FC<Props> = ({ group }) => {
	const browserSupported = !isFirefox

	useEffect(() => {
		if (!browserSupported) {
			logger.warn('User engagement tracking disabled - unsupported browser')
		}
	}, [browserSupported])

	if (browserSupported) {
		return <UserEngagementReporterEnabled group={group} />
	}

	return null
}

export default UserEngagementReporter
