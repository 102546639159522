import { colors, logger, security, ui } from '@beelday/common'
import styled from '@emotion/styled'
import { useSelector } from 'app-redux'
import { FC, useState } from 'react'
import Draggable from 'react-draggable'
import { v4 as uuid } from 'uuid'
import { selectForceBitRate } from 'video-conference-media/redux'
import icon from '../images/devtools.svg'
import DevToolsBackend from './devtools-backend'
import DevToolsFrontend from './devtools-frontend'
import DevToolsJanus from './devtools-janus'
import DevToolsEngagement from './devtools-engagement'

const DevToolsIcon = styled.img`
	width: 100%;
	height: 100%;
	object-fit: contain;
	cursor: pointer;
`

const Container = styled.div`
	position: fixed;
	left: 30px;
	bottom: 30px;
	width: 40px;
	height: 40px;
	z-index: 9999999;
`

const DetailsPopup = styled.div`
	background-color: ${colors.white};
	border-radius: 11px;
	box-shadow: 0 1px 6px 0 rgba(160, 153, 167, 0.5);
	max-height: 80vh;
	max-width: 80vw;
	padding: 10px;
	position: fixed;
	bottom: 100px;
	left: 80px;
	display: flex;
	flex-direction: column;
	font-size: 14px;
	z-index: 999999;
`

const Setting: FC<{ enabled?: boolean; onChange: (on: boolean) => unknown }> =
	({ enabled, onChange, children }) => {
		const id = uuid()
		return (
			<ui.FlexRow style={{ alignItems: 'center', marginRight: '10px' }}>
				<input
					id={id}
					style={{ display: 'block', marginRight: '4px', cursor: 'pointer' }}
					type="checkbox"
					checked={enabled}
					onChange={e => {
						onChange(e.target.checked)
					}}
				/>
				<label htmlFor={id} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
					{children}
				</label>
			</ui.FlexRow>
		)
	}

const DetailsView: FC<{ onClose: () => unknown }> = ({ onClose }) => {
	const [focus, setFocus] = useState(false)
	const [showStreams, setShowStreams] = useState(false)
	const [showBackend, setShowBackend] = useState(false)
	const [showJanus, setShowJanus] = useState(false)
	const [showEngagement, setShowEngagement] = useState(false)

	return (
		<ui.HandleUnfocused
			onClose={() => {
				if (!focus) {
					onClose()
				}
			}}
		>
			<Draggable>
				<DetailsPopup>
					<div
						style={{
							display: 'flex',
							flex: 1,
							minHeight: 0,
							overflowY: 'auto',
						}}
					>
						<div style={{ width: '600px', flex: 1 }}>
							<DevToolsFrontend showStreams={showStreams} />
						</div>
						{showBackend ? (
							<div style={{ width: '400px', flex: 1, marginLeft: '10px' }}>
								<DevToolsBackend pollInterval={2000} />
							</div>
						) : null}
						{showJanus ? (
							<div style={{ width: '400px', flex: 1, marginLeft: '10px' }}>
								<DevToolsJanus pollInterval={2000} />
							</div>
						) : null}
						{showEngagement ? (
							<div style={{ width: '300px', flex: 1, marginLeft: '10px' }}>
								<DevToolsEngagement />
							</div>
						) : null}
					</div>
					<ui.FlexRow style={{ marginTop: '10px', flexShrink: 0 }}>
						<Setting enabled={focus} onChange={setFocus}>
							Keep Focused
						</Setting>
						<Setting enabled={showStreams} onChange={setShowStreams}>
							Streams
						</Setting>
						<Setting enabled={showBackend} onChange={setShowBackend}>
							Backend
						</Setting>
						<Setting enabled={showJanus} onChange={setShowJanus}>
							Janus
						</Setting>
						<Setting enabled={showEngagement} onChange={setShowEngagement}>
							Engagement
						</Setting>
					</ui.FlexRow>
				</DetailsPopup>
			</Draggable>
		</ui.HandleUnfocused>
	)
}

export const DevTools: FC = () => {
	const user = security.useAuthenticatedUser()
	const forcedBitrate = useSelector(selectForceBitRate)

	const warn = !!forcedBitrate

	const [showDetails, setShowDetails] = useState(false)
	if (user?._dev && __DEV__) {
		return (
			<Container>
				<DevToolsIcon
					src={icon}
					alt="devtools"
					onClick={() => setShowDetails(!showDetails)}
					style={{ filter: warn ? 'invert(100%)' : 'none' }}
				/>
				{showDetails ? (
					<DetailsView onClose={() => setShowDetails(false)} />
				) : null}
			</Container>
		)
	}

	return null
}

export default DevTools
