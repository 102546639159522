import { intl, ui } from '@beelday/common'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useUnsavedChanges } from '.'

export const useHandleUnsavedRaw = (hasUnsaved: boolean): void => {
	const confirm = ui.useConfirm()
	const translate = intl.useTranslate()
	const unsavedChanges = useUnsavedChanges()

	useEffect(() => {
		if (hasUnsaved) {
			const listener: WindowEventHandlers['onbeforeunload'] = event => {
				event.preventDefault()
				event.returnValue = translate('unsaved_data.desc')
				return false
			}
			window.addEventListener('beforeunload', listener)
			return () => window.removeEventListener('beforeunload', listener)
		}
	}, [hasUnsaved, translate])

	const history = useHistory()
	useEffect(() => {
		if (hasUnsaved) {
			const unblock = history.block(location => {
				confirm
					.unsaved()
					.then(unblock)
					.then(() => unsavedChanges?.clear())
					.then(() => history.push(location))
				return false
			})
			return unblock
		}
	}, [hasUnsaved, unsavedChanges, confirm, history])
}

export const useHandleUnsaved = (): void => {
	const unsavedChanges = useUnsavedChanges()
	const [hasUnsaved, setHasUnsaved] = useState(
		Boolean(unsavedChanges && !unsavedChanges?.isEmpty())
	)
	useEffect(() => {
		return unsavedChanges?.onChange(setHasUnsaved)
	}, [unsavedChanges])

	useHandleUnsavedRaw(hasUnsaved)
}

export default useHandleUnsaved
