import { FC } from 'react'
import styled from '@emotion/styled'
import { motion, AnimatePresence } from 'framer-motion'
import emptyArc from './images/encouragement-arc-bg.svg'
import peopleArc from './images/encouragement-people-arc.svg'
import { Colors } from 'common/presentation/colors'
import { ui } from '@beelday/common'
import {
	PublicChatRoomEncouragement,
	PublicChatRoomEncouragementCode,
} from './model'
import Translate, { useTranslatedMessage } from 'intl/translate'
import { usePreloaded } from 'connectivity/preload'
import { UserProfile, PROFILE_MISSING } from 'common/types'

const TrainerEngagementContainer = styled.div`
	background-color: ${Colors.indigoBlue};
	border-radius: 25px 0 25px 25px;
	color: ${Colors.white};
	font-family: Ubuntu;
	font-size: 14px;
	font-weight: 500;
	margin-top: 17px;
	padding: 15px 20px;
	position: relative;
	width: 100%;

	${ui.responsive.desktop1536} {
		font-size: 17px;
	}
`

type Props = {
	encouragement?: PublicChatRoomEncouragement | null
	numberOfUsersInRoom: number
}

const contentAnimation = {
	visible: {
		opacity: 1,
		translateY: 0,
		transition: {
			duration: 0.5,
			delay: 0.7,
		},
	},
	initial: {
		opacity: 0,
		translateY: '100%',
	},
	exit: {
		opacity: 0,
		translateY: '100%',
		transition: { duration: 0.3 },
	},
}
const Content: FC<Pick<Props, 'encouragement'>> = ({ encouragement }) => {
	const interestI18n = useTranslatedMessage(
		encouragement?.interest && 'common_interest.' + encouragement.interest
	)
	return (
		<motion.div key="content" variants={contentAnimation}>
			<Translate
				name={
					encouragement?.user?.displayName || encouragement?.user?.name || ''
				}
				interest={interestI18n}
			>
				{'lobby.encouragement.' + encouragement?.code.toLowerCase()}
			</Translate>
		</motion.div>
	)
}

type TrainerContentProps = {
	code: PublicChatRoomEncouragementCode
	upperEchelon: string
}
const TrainerContent: FC<TrainerContentProps> = ({ code, upperEchelon }) => (
	<motion.div key="content" variants={contentAnimation}>
		<Translate name={upperEchelon}>
			{'lobby.encouragement.' + code.toLowerCase()}
		</Translate>
	</motion.div>
)

const NoOneInRoomEngagementContainer = styled.div`
	background-color: ${Colors.indigoBlue};
	border-radius: 25px 0 25px 25px;
	color: ${Colors.white};
	font-family: Ubuntu;
	font-size: 14px;
	font-weight: 500;
	margin-top: 17px;
	padding: 15px 20px;
	position: relative;
	width: 100%;

	transform-origin: top right;

	${ui.responsive.desktop1536} {
		font-size: 17px;
	}
`

const PeopleInRoomEngagementContainer = styled.div`
	background-color: ${Colors.indigoBlue};
	border-radius: 25px;
	color: ${Colors.white};
	font-family: Ubuntu;
	font-size: 14px;
	font-weight: 500;
	margin-top: 17px;
	padding: 15px 20px;
	position: relative;
	width: 100%;

	${ui.responsive.desktop1536} {
		font-size: 17px;
	}
`

const EmptyArc = styled.img`
	position: absolute;
	top: -28px;
	right: 0;
`

const SingleArc = styled.img`
	position: absolute;
	bottom: -28px;
	left: 50%;

	${ui.responsive.desktop1536} {
		transform: translateX(16px);
	}
`
const DoubleArc = styled.img`
	position: absolute;
	bottom: -28px;
	left: 50%;
	transform: translateX(23px);

	${ui.responsive.desktop1536} {
		transform: translateX(48px);
	}
`
const TripleArc = styled.img`
	position: absolute;
	bottom: -28px;
	left: 50%;
	transform: translateX(46px);

	${ui.responsive.desktop1536} {
		transform: translateX(88px);
	}
`

const QuadArc = styled.img`
	position: absolute;
	bottom: -28px;
	left: 50%;
	transform: translateX(72px);

	${ui.responsive.desktop1536} {
		transform: translateX(127px);
	}
`

const ManyArc = styled.img`
	position: absolute;
	bottom: -28px;
	left: 50%;
	transform: translateX(43px);

	${ui.responsive.desktop1536} {
		transform: translateX(85px);
	}
`

type TrainerProps = {
	encouragement?: PublicChatRoomEncouragement | null
	upperEchelon: UserProfile | PROFILE_MISSING
}
const TrainerRoomEngagementBody: FC<TrainerProps> = ({
	encouragement,
	upperEchelon,
}) =>
	encouragement ? (
		<TrainerEngagementContainer>
			<EmptyArc src={emptyArc} />
			<TrainerContent
				code={encouragement.code}
				upperEchelon={upperEchelon?.displayName || upperEchelon?.name || ''}
			/>
		</TrainerEngagementContainer>
	) : null

const EngagementBody: FC<Props> = ({ encouragement, numberOfUsersInRoom }) => {
	const preloaded = usePreloaded([emptyArc, peopleArc])

	return !preloaded ? null : numberOfUsersInRoom === 0 ? (
		<NoOneInRoomEngagementContainer>
			<EmptyArc src={emptyArc} />
			<Content encouragement={encouragement} />
		</NoOneInRoomEngagementContainer>
	) : numberOfUsersInRoom === 1 ? (
		<PeopleInRoomEngagementContainer>
			<SingleArc src={peopleArc} />
			<Content encouragement={encouragement} />
		</PeopleInRoomEngagementContainer>
	) : numberOfUsersInRoom === 2 ? (
		<PeopleInRoomEngagementContainer>
			<DoubleArc src={peopleArc} />
			<Content encouragement={encouragement} />
		</PeopleInRoomEngagementContainer>
	) : numberOfUsersInRoom === 3 ? (
		<PeopleInRoomEngagementContainer>
			<TripleArc src={peopleArc} />
			<Content encouragement={encouragement} />
		</PeopleInRoomEngagementContainer>
	) : numberOfUsersInRoom === 4 ? (
		<PeopleInRoomEngagementContainer>
			<QuadArc src={peopleArc} />
			<Content encouragement={encouragement} />
		</PeopleInRoomEngagementContainer>
	) : numberOfUsersInRoom > 4 ? (
		<PeopleInRoomEngagementContainer>
			<ManyArc src={peopleArc} />
			<Content encouragement={encouragement} />
		</PeopleInRoomEngagementContainer>
	) : null
}

const animation = {
	initial: {
		scale: 0,
	},
	visible: {
		scale: 1,
		transformOrigin: 'right top',
		transition: { duration: 0.5, when: 'beforeChildren' },
	},
	exit: {
		scale: 0,
		transformOrigin: 'right top',
		transition: { duration: 0.2, when: 'afterChildren' },
	},
}

const AnimatedEngagement: React.FC<{ show: boolean }> = ({
	show,
	children,
}) => {
	return (
		<AnimatePresence>
			{show ? (
				<motion.div
					key="body"
					variants={animation}
					initial="initial"
					animate="visible"
					exit="exit"
				>
					{children}
				</motion.div>
			) : null}
		</AnimatePresence>
	)
}

export const Engagement = (props: Props) => (
	<AnimatedEngagement show={!!props.encouragement}>
		<EngagementBody {...props} />
	</AnimatedEngagement>
)

export const TrainerRoomEngagement = (props: TrainerProps) => (
	<AnimatedEngagement show={!!props.encouragement}>
		<TrainerRoomEngagementBody {...props} />
	</AnimatedEngagement>
)
export default Engagement
