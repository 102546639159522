import { intl } from '@beelday/common'
import ConfirmBanner from 'common/presentation/confirm-banner'
import { useAuthenticatedBeeldayClient } from 'connectivity/beelday-hooks'
import { FC, useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
	Menu,
	MenuItem,
	MenuItemLine,
	MenuToggleItem,
} from 'room/common/presentation/menu'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import {
	activateEtherPad,
	activateExcalidraw,
	deactivateEtherPad,
	deactivateExcalidraw,
} from '../redux'
import { Break } from '../../common/presentation/icon/break'
import { Whiteboard } from '../../common/presentation/icon/whiteboard'
import { Document } from '../../common/presentation/icon/document'
import { StartReview } from '../../common/presentation/icon/start-review'
import { Back } from '../../common/presentation/icon/back'
import { GroupId } from '../../../common/types'
import type { Media } from 'common/types'
import { MicMenuItem } from 'features/microphone'
import MiscPanelMenu from '../../misc/misc-panel-menu'
import CamMenuItem from 'features/camera/camera-menu-item'
import ScreenShareGroupMenuItem from 'features/screen-share/screen-share-group-menu-item'
import useScreenShareInGroup from 'features/screen-share/use-screen-share-in-group'

type Props = {
	currentGroupId: GroupId
	openMedia: Media | undefined
	onStartBreak: () => void
}

export const TrainerMenuInGroupView: FC<Props> = props => {
	const dispatch = useDispatch()
	const beeldayClient = useAuthenticatedBeeldayClient()
	const joinedRoomAddress = useAssertedJoinedRoomAddress()
	const [confirmReview, setConfirmReview] = useState(false)
	const { currentGroupId, openMedia } = props
	const groupAddress = useMemo(() => {
		return { ...joinedRoomAddress, groupId: currentGroupId }
	}, [joinedRoomAddress, currentGroupId])

	const { userGroupScreenShareState } = useScreenShareInGroup()

	const startExcalidraw = useCallback(() => {
		if (openMedia === 'Excalidraw') {
			beeldayClient.closeExcalidraw(groupAddress)
		} else {
			beeldayClient.openExcalidraw(groupAddress)
			dispatch(deactivateEtherPad(currentGroupId))
			dispatch(activateExcalidraw(currentGroupId))
		}
	}, [beeldayClient, dispatch, groupAddress, currentGroupId, openMedia])

	const closeExcalidraw = useCallback(() => {
		if (openMedia === 'Excalidraw') {
			beeldayClient.closeExcalidraw(groupAddress)
		}
	}, [beeldayClient, groupAddress, openMedia])

	const startEtherpad = useCallback(() => {
		if (openMedia === 'EtherPad') {
			beeldayClient.closeEtherPad(groupAddress)
		} else {
			beeldayClient.openEtherPad(groupAddress)
			dispatch(deactivateExcalidraw(currentGroupId))
			dispatch(activateEtherPad(currentGroupId))
		}
	}, [beeldayClient, dispatch, groupAddress, currentGroupId, openMedia])

	const closeEtherpad = useCallback(() => {
		if (openMedia === 'EtherPad') {
			beeldayClient.closeEtherPad(groupAddress)
		}
	}, [beeldayClient, groupAddress, openMedia])

	const startReview = () => setConfirmReview(true)

	const backToGroupsView = useCallback(() => {
		beeldayClient.trainerLeavesGroup(groupAddress)
	}, [beeldayClient, groupAddress])

	return (
		<Menu>
			{confirmReview ? (
				<ConfirmBanner
					message="groups.confirm_review"
					onAccept={() => beeldayClient.reviewTask(joinedRoomAddress)}
					onDecline={() => setConfirmReview(false)}
					accept="yes"
					decline="no"
				/>
			) : null}
			<MenuToggleItem
				icon={<Whiteboard />}
				disabled={userGroupScreenShareState?.active}
				active={openMedia === 'Excalidraw'}
				onClick={openMedia === 'Excalidraw' ? closeExcalidraw : startExcalidraw}
				label={<intl.Translate>room_menu.whiteboard</intl.Translate>}
			/>
			<MenuToggleItem
				icon={<Document />}
				disabled={userGroupScreenShareState?.active}
				active={openMedia === 'EtherPad'}
				onClick={openMedia === 'EtherPad' ? closeEtherpad : startEtherpad}
				label={<intl.Translate>room_menu.notepad</intl.Translate>}
			/>
			<ScreenShareGroupMenuItem />
			<MenuToggleItem
				icon={<StartReview />}
				active={confirmReview}
				onClick={startReview}
				label={<intl.Translate>room_menu.group_review</intl.Translate>}
			/>
			<MicMenuItem />
			<CamMenuItem />
			<MenuItem
				icon={<Break />}
				onClick={props.onStartBreak}
				label={<intl.Translate>room_menu.break</intl.Translate>}
			/>
			<MenuItem
				icon={<Back />}
				onClick={backToGroupsView}
				label={<intl.Translate>room_menu.back_to_groups</intl.Translate>}
			/>
			<MenuItemLine />
			<MiscPanelMenu />
		</Menu>
	)
}
