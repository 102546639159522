import { Config } from 'common/config'
import { useMemo } from 'react'
import { checkStatus, parseBody } from 'utils/http'
import { Organization, OrganizationId } from './model'

type OrganizationApi = {
	getLogoUrl: (logoId: string) => string
	getOrganizationById: (organizationId: OrganizationId) => Promise<Organization>
}

const createOrganizationApi = (): OrganizationApi => {
	return {
		getLogoUrl: logoId => {
			if (logoId) {
				return `${Config.USER_API_URL}/files/${logoId}`
			}
			return `${Config.USER_API_URL}/public/avatar.png`
		},

		getOrganizationById: (organizationId: OrganizationId) =>
			fetch(`${Config.USER_API_URL}/organization/${organizationId}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'Application/JSON',
				},
			})
				.then(checkStatus)
				.then(parseBody),
	}
}

export const useOrganizationApi = (): OrganizationApi =>
	useMemo(createOrganizationApi, [])

export default createOrganizationApi
