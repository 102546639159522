/*
 (c) 2017, Vladimir Agafonkin
 Simplify.js, a high-performance JS polyline simplification library
 mourner.github.io/simplify-js
 
 Updated by @marekpiechut to TypeScript and to use [x, y] arrays instead of {x, y} objects for points
*/

// to suit your point format, run search/replace for '.x' and '.y';
// for 3D version, see 3d branch (configurability would draw significant performance overhead)

type Point = [x: number, y: number]
// square distance between 2 points
function getSqDist(p1: Point, p2: Point): number {
	const dx = p1[0] - p2[0],
		dy = p1[1] - p2[1]

	return dx * dx + dy * dy
}

// square distance from a point to a segment
function getSqSegDist(p: Point, p1: Point, p2: Point): number {
	let x = p1[0],
		y = p1[1],
		dx = p2[0] - x,
		dy = p2[1] - y

	if (dx !== 0 || dy !== 0) {
		const t = ((p[0] - x) * dx + (p[1] - y) * dy) / (dx * dx + dy * dy)

		if (t > 1) {
			x = p2[0]
			y = p2[1]
		} else if (t > 0) {
			x += dx * t
			y += dy * t
		}
	}

	dx = p[0] - x
	dy = p[1] - y

	return dx * dx + dy * dy
}
// rest of the code doesn't care about point format

// basic distance-based simplification
function simplifyRadialDist(points: Point[], sqTolerance: number): Point[] {
	let prevPoint = points[0]
	const newPoints = [prevPoint]
	let point

	for (let i = 1, len = points.length; i < len; i++) {
		point = points[i]

		if (getSqDist(point, prevPoint) > sqTolerance) {
			newPoints.push(point)
			prevPoint = point
		}
	}

	if (point && prevPoint !== point) newPoints.push(point)

	return newPoints
}

function simplifyDPStep(
	points: Point[],
	first: number,
	last: number,
	sqTolerance: number,
	simplified: Point[]
) {
	let maxSqDist = sqTolerance,
		index = 0

	for (let i = first + 1; i < last; i++) {
		const sqDist = getSqSegDist(points[i], points[first], points[last])

		if (sqDist > maxSqDist) {
			index = i
			maxSqDist = sqDist
		}
	}

	if (maxSqDist > sqTolerance) {
		if (index - first > 1)
			simplifyDPStep(points, first, index, sqTolerance, simplified)
		simplified.push(points[index])
		if (last - index > 1)
			simplifyDPStep(points, index, last, sqTolerance, simplified)
	}
}

// simplification using Ramer-Douglas-Peucker algorithm
export function simplifyDouglasPeucker(
	points: Point[],
	sqTolerance: number
): Point[] {
	const last = points.length - 1

	const simplified = [points[0]]
	simplifyDPStep(points, 0, last, sqTolerance, simplified)
	simplified.push(points[last])

	return simplified
}

// both algorithms combined for awesome performance
export function simplify(
	points: Point[],
	tolerance: number,
	highestQuality: boolean
): Point[] {
	if (points.length <= 2) return points

	const sqTolerance = tolerance !== undefined ? tolerance * tolerance : 1

	points = highestQuality ? points : simplifyRadialDist(points, sqTolerance)
	points = simplifyDouglasPeucker(points, sqTolerance)

	return points
}
