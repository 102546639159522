const MicrophoneMutedIcon = (): JSX.Element => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
	>
		<defs>
			<clipPath>
				<rect width="24" height="24" />
			</clipPath>
		</defs>
		<g>
			<path d="M14.537,16.354H14.52l1.457,1.457ZM13.166,21.48V18.772c.171-.035.36-.052.531-.1l-1.834-1.818A6.15,6.15,0,0,1,5.76,10.8H3.772v.189a8.271,8.271,0,0,0,7.063,7.783V21.48H8.469v2.366h7.063V21.48Z" />
			<path d="M24,22.183V24H22.166l-6.172-6.188h-.017l-1.44-1.458H14.52l-2.006-2.022H12.5L8.469,10.3v-.017L0,1.817V0H1.817L8.469,6.652V3.686a3.523,3.523,0,1,1,7.045,0v7.131h.017a3.5,3.5,0,0,1-.737,2.16l1.852,1.852a6.134,6.134,0,0,0,1.594-4V10.8h1.989a8.235,8.235,0,0,1-2.195,5.417Z" />
		</g>
	</svg>
)

export default MicrophoneMutedIcon
