export const Polling: React.FC = () => (
	<svg
		data-name="Group 25589"
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
	>
		<defs>
			<clipPath id="xc7l721rda">
				<path
					data-name="Rectangle 3992"
					fill="currentColor"
					d="M0 0h24v24H0z"
				/>
			</clipPath>
		</defs>
		<g data-name="Group 25589" style={{ clipPath: 'url(#xc7l721rda)' }}>
			<path
				data-name="Path 11821"
				d="M21.423 0H2.576A2.587 2.587 0 0 0 0 2.577v18.846A2.587 2.587 0 0 0 2.576 24h18.847A2.588 2.588 0 0 0 24 21.423V2.577A2.587 2.587 0 0 0 21.423 0zM8.084 18.265a.443.443 0 0 1-.43.429H5.381a.443.443 0 0 1-.43-.429V8.918a.443.443 0 0 1 .43-.43h2.273a.443.443 0 0 1 .43.43zm5.482 0a.443.443 0 0 1-.43.429h-2.273a.443.443 0 0 1-.43-.429V5.735a.443.443 0 0 1 .43-.43h2.273a.443.443 0 0 1 .43.43zm5.482 0a.442.442 0 0 1-.429.429h-2.274a.442.442 0 0 1-.429-.429v-6.442a.443.443 0 0 1 .429-.43h2.274a.443.443 0 0 1 .429.43z"
				fill="currentColor"
			/>
		</g>
	</svg>
)
