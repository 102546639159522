import { FC } from 'react'
import styled from '@emotion/styled'
import { Colors } from 'common/presentation/colors'
import { clippingBorderRadius } from 'common/presentation/cross-browser-css'

type Props = {
	value: number
	max: number
}

const Container = styled.div`
	${clippingBorderRadius('100px')}
	width: 100%;
	height: 100%;
	background-color: rgba(149, 112, 216, 0.1);
`

const Bar = styled.div`
	width: ${(props: Props) => (100 * props.value) / props.max}%;
	height: 100%;
	background-color: ${Colors.greenishCyan};
`

const ProgressBar: FC<Props> = props => {
	return (
		<Container>
			<Bar {...props} />
		</Container>
	)
}

export default ProgressBar
