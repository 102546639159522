import { FC } from 'react'
import { SceneVideoUserStream } from 'video-conference-media/presentation/scene-video-user-stream'
import { UserId } from 'common/types'
import {
	Draggable,
	DraggableStateSnapshot,
	DraggingStyle,
	NotDraggingStyle,
} from 'react-beautiful-dnd'
import styled from '@emotion/styled'
import { clippingBorderRadius } from 'common/presentation/cross-browser-css'
import { SceneVideoUser } from 'video-conference-media'

type Props = {
	hideName?: boolean
	draggable: boolean
	id: UserId
	index: number
	stream: SceneVideoUser
	circle?: boolean
}

function getStyle(
	snapshot: DraggableStateSnapshot,
	style?: DraggingStyle | NotDraggingStyle
) {
	if (!snapshot.isDragging) return {}
	if (!snapshot.isDropAnimating) {
		return style
	}

	return {
		...style,
		// cannot be 0, but make it super tiny
		transitionDuration: `0.001s`,
	}
}

type PlaceholderProps = {
	circle: boolean
}

const Placeholder = styled.div`
	height: 100%;
	width: 100%;
	${(props: PlaceholderProps) =>
		clippingBorderRadius(props.circle ? '50%' : '5px')};
`

const UserToPick: FC<Props> = ({
	hideName,
	draggable,
	id,
	index,
	stream,
	circle,
}) => (
	<Draggable
		draggableId={id}
		index={index}
		disableInteractiveElementBlocking
		isDragDisabled={!draggable}
	>
		{(provided, snapshot) => (
			<Placeholder
				circle={!!circle}
				ref={provided.innerRef}
				{...provided.draggableProps}
				{...provided.dragHandleProps}
				style={getStyle(snapshot, provided.draggableProps.style)}
			>
				{stream && (
					<SceneVideoUserStream hideName={hideName} sceneVideoUser={stream} />
				)}
			</Placeholder>
		)}
	</Draggable>
)

export default UserToPick
