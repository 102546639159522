import { FC } from 'react'
import { ConnectionQuality } from 'connectivity/awaited-connections'
import OfflineFriendlyInfoBanner from 'interaction-scheme/presentation/offline-friendly-info-banner'
import { useSelector } from 'react-redux'
import { RootState } from 'common/redux'

const SystemNotifications: FC = () => {
	const connectionQuality = useSelector(
		(state: RootState) => state.connectivity.quality
	)

	if (connectionQuality === ConnectionQuality.Bad) {
		return <OfflineFriendlyInfoBanner message={'bad_connection_warning'} />
	}

	return null
}

export default SystemNotifications
