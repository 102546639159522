import { eventbus } from '@beelday/common'
import { useDispatch } from 'app-redux'
import { useEffect } from 'react'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import useTrainingAPI from './api/hooks'
import { trainingUpdated } from './redux'
import { Training } from './training'

const useInfoEventSource = (): void => {
	const eventBus = eventbus.useEventBus()
	const trainingAPI = useTrainingAPI()
	const joinedRoomAddress = useAssertedJoinedRoomAddress()
	const dispatch = useDispatch()

	useEffect(() => {
		let subscribedSession: Training | null = null

		trainingAPI
			.fetchSessionByLiveId(joinedRoomAddress.workflowId)
			.then(session => {
				if (session) {
					dispatch(trainingUpdated(session))
					eventBus.subscribe('SESSION_UPDATED', session.id)
					subscribedSession = session
				}
			})

		return () => {
			if (subscribedSession) {
				eventBus.unsubscribe('SESSION_UPDATED', subscribedSession.id)
			}
		}
	}, [joinedRoomAddress, trainingAPI, eventBus, dispatch])
}

export default useInfoEventSource
