import { useEffect, useRef, useState } from 'react'
import Draggable, { DraggableData } from 'react-draggable'
import styled from '@emotion/styled'

const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 540px;
	position: absolute;
	bottom: 140px;
	z-index: 1000;
	left: calc(50% - 300px);
`

type Props = {
	header: JSX.Element
	body: JSX.Element
}

const DraggableContent = ({ header, body }: Props): JSX.Element => {
	const refDraggableContent = useRef<HTMLDivElement>(null)

	const [draggablePosition, setDraggablePosition] = useState<{
		x: number
		y: number
	}>({
		x: 0,
		y: 0,
	})

	const trackPos = (data: DraggableData) => {
		setDraggablePosition({ x: data.x, y: data.y })
	}

	const triggerMouseEvent = (
		element: HTMLDivElement | Document | null,
		eventType: string
	) => {
		const mouseEvent = new CustomEvent(eventType, {
			bubbles: true,
			cancelable: true,
		})
		element?.dispatchEvent(mouseEvent)
	}

	useEffect(() => {
		const listener = () => {
			triggerMouseEvent(refDraggableContent.current, 'mouseover')
			triggerMouseEvent(refDraggableContent.current, 'mousedown')
			triggerMouseEvent(document, 'mousemove')
			triggerMouseEvent(refDraggableContent.current, 'mouseup')
			triggerMouseEvent(refDraggableContent.current, 'click')
		}
		addEventListener('resize', listener)
		return () => removeEventListener('resize', listener)
	}, [])

	return (
		<Draggable
			handle=".draggable"
			onDrag={(_e, data) => trackPos(data)}
			bounds="html"
			position={{
				x: draggablePosition?.x ?? 0,
				y: draggablePosition?.y ?? 0,
			}}
		>
			<Container>
				<div className="draggable" ref={refDraggableContent}>
					{header}
				</div>
				{body}
			</Container>
		</Draggable>
	)
}

export default DraggableContent
