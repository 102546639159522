export const Question: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
	>
		<g data-name="Group 25577">
			<g data-name="Group 25578">
				<path
					data-name="Path 11811"
					d="M9.981 4.867H4.713a10.329 10.329 0 0 0 .462-3.327A2.82 2.82 0 0 0 3.881.061c-.181-.092-.369-.092-.462.093L2.4 1.91 1.293 3.666a6.82 6.82 0 0 1-.647 1.109A3.955 3.955 0 0 0 0 5.7v8.318a1.012 1.012 0 0 0 .924 1.016h7.764c.923 0 1.2-1.478 1.571-2.125A32.658 32.658 0 0 0 12.2 8.841c1.2-3.05.185-3.974-2.218-3.974"
					transform="translate(0 -.001)"
				/>
				<path
					data-name="Path 11812"
					d="M23.076 8.967h-7.763c-.925 0-1.2 1.479-1.572 2.126a28.594 28.594 0 0 0-1.94 4.159c-1.017 2.957 0 3.789 2.31 3.789h5.268a10.329 10.329 0 0 0-.462 3.327 3.6 3.6 0 0 0 1.294 1.571c.185.093.37.093.462-.092l2.033-3.512a6.882 6.882 0 0 1 .647-1.109A3.327 3.327 0 0 0 24 18.3V9.984a1.012 1.012 0 0 0-.924-1.017"
					transform="translate(0 -.001)"
				/>
			</g>
		</g>
	</svg>
)
