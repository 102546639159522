export const Grid: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="14"
		height="12"
		viewBox="0 0 14 12"
	>
		<g transform="translate(-1232 -353)">
			<rect
				width="6"
				height="5"
				transform="translate(1232 353)"
				fill="currentColor"
			/>
			<rect
				width="6"
				height="5"
				transform="translate(1240 353)"
				fill="currentColor"
			/>
			<rect
				width="6"
				height="5"
				transform="translate(1240 360)"
				fill="currentColor"
			/>
			<rect
				width="6"
				height="5"
				transform="translate(1232 360)"
				fill="currentColor"
			/>
		</g>
	</svg>
)
