import { colors, ui, intl } from '@beelday/common'
import styled from '@emotion/styled'
import { FC } from 'react'
import { CameraOff } from 'room/common/presentation/icon/camera-off'
import { SafariNotSupportedInfo } from './safari-not-supported-info'
type DevicePopupProps = {
	onClose: () => void
}
export const DevicePopup: FC<DevicePopupProps> = ({ children, onClose }) => {
	const translate = intl.useTranslate()
	return (
		<ui.Modal onClose={onClose}>
			<ui.ModalContainer>
				<ui.ModalHeader
					onClose={onClose}
					title={translate('hardware_check.change_permission.title')}
				/>
				<ui.ModalContent style={{ padding: '50px' }}>
					{children}
				</ui.ModalContent>
			</ui.ModalContainer>
		</ui.Modal>
	)
}

const RequestBox = styled.div`
	position: relative;
	margin-bottom: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	border-radius: 5px;
	background: ${colors.heather};

	&:before {
		display: block;
		content: '';
		width: 100%;
		padding-top: 57%;
	}
`

const RequestBoxIcon = styled.div`
	position: absolute;
	left: 50%;
	top: 42%;
	transform: translate(-50%, -50%);
	color: ${colors.white};

	svg {
		width: 48px;
	}
`

const RequestBoxText = styled.div`
	position: absolute;
	left: 20px;
	bottom: 20px;
	width: calc(100% - 40px);
	background: ${colors.darkBlue};
	border-radius: 5px;
	padding: 10px 20px;
	color: ${colors.white};
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
`

const Text = styled.div`
	margin-bottom: 30px;
	font-size: 18px;
	line-height: 28px;
	margin-top: 20px;

	b {
		font-weight: 500;
	}
`

export const CameraUsedInOtherApp: FC<{
	isSafari?: boolean
	onAskForPermission?: () => unknown
}> = ({ isSafari, onAskForPermission }) => (
	<div style={{ textAlign: 'center' }}>
		<RequestBox>
			<RequestBoxIcon>
				<CameraOff />
			</RequestBoxIcon>
			<RequestBoxText>
				<intl.Translate>hardware_check.camera_used_in_other_app</intl.Translate>
			</RequestBoxText>
		</RequestBox>
		{isSafari && <SafariNotSupportedInfo />}
		<Text>
			<intl.Translate>hardware_check.camera_used_details</intl.Translate>{' '}
			<b>
				<intl.Translate>hardware_check.camera_used_action</intl.Translate>
			</b>
		</Text>
		<ui.ButtonPrimary onClick={onAskForPermission}>
			<intl.Translate>hardware_check.camera_used_retry_button</intl.Translate>
		</ui.ButtonPrimary>
	</div>
)
