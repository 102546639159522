import { FC } from 'react'
import styled from '@emotion/styled'
import { RoomContainer } from 'room/common/presentation/room-container'
import RoomHeader from '../../room/common/presentation/room-header'
import { use100vh } from 'react-div-100vh'
import Translate from 'intl/translate'
import { Link } from 'react-router-dom'
import { ui } from '@beelday/common'

const Content = styled.div`
	margin: auto;
	text-align: center;
	padding: 70px 20px;
`

const Title = styled.div`
	margin-bottom: 8px;
	font-size: 28px;
	line-height: 38px;
	font-weight: 700;
`

const Desc = styled.div`
	margin-bottom: 40px;
	font-size: 16px;
	line-height: 28px;
	max-width: 500px;
`

const Container = styled.div`
	flex: 1;
	display: flex;
	overflow: auto;
`

const EndTraining: FC = () => {
	const containerHeight = use100vh()
	return (
		<RoomContainer
			style={{
				height: `${containerHeight}px`,
			}}
		>
			<RoomHeader />
			<Container>
				<Content>
					<Title>
						<Translate>training.finish.title</Translate>
					</Title>
					<Desc>
						<Translate>training.finish.description</Translate>
					</Desc>
					<Link to="/">
						<ui.ButtonPrimary color="greenishCyan">
							<Translate>error_page.button</Translate>
						</ui.ButtonPrimary>
					</Link>
				</Content>
			</Container>
		</RoomContainer>
	)
}

export default EndTraining
