import { colors, icons, intl, ui } from '@beelday/common'
import styled from '@emotion/styled'
import { useSelector } from 'app-redux'
import { Colors } from 'common/presentation/colors'
import { UserProfile } from 'common/types'
import { keyBy } from 'lodash'
import { FC, ReactElement, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { roomUserIds } from 'room/common/redux'
import { Profiles } from 'users/profiles-in-row'
import { useProfilesFor } from 'users/redux'
import { SceneVideoUser } from 'video-conference-media'
import { KnowledgeCheckStats } from '../knowledge-check'
import {
	highlightGroup,
	removeHighlight,
	useHighlight,
} from '../knowledge-checks-redux'
import KnowledgeCheckHeader from './knowledge-check-header'
import KnowledgeCheckThreeColumnsVideoGrid from './knowledge-check-three-columns-video-grid'

const Container = styled.div`
	background-color: ${Colors.white};
	border-radius: 42px 18px 18px 18px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0 auto;
	max-width: 700px;
	overflow: hidden;
	width: 100%;
`

const Content = styled.div`
	display: flex;
	flex-direction: column;
	padding: 25px 25px 40px 25px;
	height: 100%;
`

const ContentFooter = styled.div`
	display: flex;
	margin-top: auto;
`

const ContentFooterButtons = styled.div`
	margin-left: auto;
`

const CountContainer = styled.div`
	cursor: pointer;
	box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
	border: 1px solid ${Colors.brightGray};
	border-radius: 11px;
	display: flex;
	margin-bottom: 30px;
	position: relative;

	transition: border 0.3s ease-in-out;

	&:hover {
		border: 1px solid ${colors.indigoBlue};
	}
`

const CountContainerText = styled.div`
	display: flex;
	align-items: center;
	flex: 1;
	padding: 25px 20px;
	font-size: 18px;
	line-height: 22px;
	font-weight: 500;
`

const CountContainerCircle = styled.div`
	justify-content: center;
	align-items: center;
	display: flex;
	padding: 15px;
	border-left: 1px solid ${Colors.brightGray};
`

const CountShowMore = styled.button`
	border-radius: 12px;
	background: ${Colors.white};
	box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.08);
	bottom: 0;
	color: ${Colors.indigoBlue};
	cursor: pointer;
	position: absolute;
	padding: 6px 12px;
	left: 50%;
	transform: translate(-50%, 50%);
	transition: background 0.25s, color 0.25s;
	border: 0;
	font-size: 12px;
	font-weight: 500;

	svg {
		margin-left: 5px;
	}

	&:hover {
		background: ${Colors.indigoBlue};
		color: ${Colors.white};
	}
`

const InlineStats = styled.div`
	align-items: center;
	cursor: pointer;
	background-color: ${Colors.heather10};
	border: 1px solid ${colors.heather10};
	border-radius: 7px;
	display: flex;
	padding: 15px;
	margin-bottom: 10px;

	transition: border 0.3s ease-in-out;

	&:hover {
		border: 1px solid ${colors.indigoBlue};
	}
`

const InlineStatsText = styled.div`
	font-size: 16px;
	line-height: 22px;
`

const InlineStatsCount = styled.div`
	padding-left: 20px;
	margin-left: auto;
	font-size: 16px;
	line-height: 22px;
	font-weight: 500;
`

type Props = {
	sceneVideoUsers: SceneVideoUser[]
	userProfile: UserProfile | undefined | null
	title: string
	stats?: KnowledgeCheckStats
	maxDurationInSeconds?: number
	startTime?: Date
	onFinish: () => void
	onShowReview: () => void
}

const KnowledgeCheckTrainerGridView: FC<Props> = ({
	sceneVideoUsers,
	userProfile,
	title,
	stats,
	maxDurationInSeconds,
	startTime,
	onFinish,
	onShowReview,
}): ReactElement => {
	let notStarted = 0
	let started = 0
	let finished = 0

	const userIds = useSelector(roomUserIds)
	const profiles = useProfilesFor(userIds)

	const profilesById = useMemo(() => keyBy(profiles, 'id'), [profiles])

	if (stats) {
		notStarted = stats.notStarted.length
		started = stats.started.length
		finished = stats.finished.length
	}
	const userCount = notStarted + started + finished
	const dispatch = useDispatch()

	const highlight = useHighlight()

	return (
		<KnowledgeCheckThreeColumnsVideoGrid
			sceneVideoUsers={sceneVideoUsers}
			highlight={highlight}
		>
			<Container>
				<KnowledgeCheckHeader
					title={title}
					userProfile={userProfile}
					maxDurationInSeconds={maxDurationInSeconds}
					startTime={startTime}
				/>
				<Content>
					<CountContainer
						onMouseEnter={() => dispatch(highlightGroup('finished'))}
						onMouseLeave={() => dispatch(removeHighlight())}
					>
						<CountContainerText>
							<intl.Translate>knowledge_check.stats.finished</intl.Translate>
						</CountContainerText>
						<CountContainerCircle>
							<ui.CircleProgress value={finished} maxValue={userCount} />
						</CountContainerCircle>
						<CountShowMore onClick={onShowReview}>
							Show more <icons.ChevronDown />
						</CountShowMore>
					</CountContainer>
					<InlineStats
						onMouseEnter={() => dispatch(highlightGroup('started'))}
						onMouseLeave={() => dispatch(removeHighlight())}
					>
						<Profiles
							profiles={stats?.started.map(id => profilesById[id]) ?? []}
							size="36px"
							maxFacesVisible={2}
						/>
						<span style={{ width: '15px' }} />
						<InlineStatsText>
							<intl.Translate>knowledge_check.stats.started</intl.Translate>
						</InlineStatsText>
						<InlineStatsCount>
							{started}/{userCount}
						</InlineStatsCount>
					</InlineStats>
					<InlineStats
						onMouseEnter={() => dispatch(highlightGroup('notStarted'))}
						onMouseLeave={() => dispatch(removeHighlight())}
					>
						<Profiles
							profiles={stats?.notStarted.map(id => profilesById[id]) ?? []}
							size="36px"
							maxFacesVisible={2}
						/>
						<span style={{ width: '15px' }} />
						<InlineStatsText>
							<intl.Translate>knowledge_check.stats.not_started</intl.Translate>
						</InlineStatsText>
						<InlineStatsCount>
							{notStarted}/{userCount}
						</InlineStatsCount>
					</InlineStats>
					<ContentFooter>
						<ContentFooterButtons>
							<ui.ButtonPrimary onClick={onFinish}>
								<intl.Translate>knowledge_check.stats.end</intl.Translate>
							</ui.ButtonPrimary>
						</ContentFooterButtons>
					</ContentFooter>
				</Content>
			</Container>
		</KnowledgeCheckThreeColumnsVideoGrid>
	)
}

export default KnowledgeCheckTrainerGridView
