import { PlayerRanking } from '../types'

import { FC } from 'react'
import styled from '@emotion/styled'
import { Colors } from 'common/presentation/colors'
import Translate from 'intl/translate'
import { Award, Score } from './winners-list-item'

const Container = styled.div`
	background-color: ${Colors.darkIndigo};
	border-radius: 0 0 20px 20px;
	padding: 8px 50px 9px 50px;
`

const Inner = styled.div`
	background-color: ${Colors.white};
	border-radius: 14px;
	display: flex;
	align-items: center;
	padding: 17px 0px 17px 21px;
`

const Label = styled.div`
	color: ${Colors.black};
	font-family: Ubuntu;
	font-size: 14px;
	font-weight: 500;
	flex-grow: 1;
	flex-shrink: 2;
`

type Props = {
	noWinners: boolean
	ranking: PlayerRanking
	total: number
}

const UserRanking: FC<Props> = ({ noWinners, ranking, total }) => (
	<Container>
		<Inner>
			<Label>
				<Translate>quiz.your_score</Translate>
			</Label>
			<Score score={ranking.score} total={total} />
			{noWinners ? null : <Award place={ranking.place} />}
		</Inner>
	</Container>
)

export default UserRanking
