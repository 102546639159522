import { Notepad, NotepadSessionId } from './notepad-models'

type NotepadState = {
	notepads: Notepad[]
	notepadDefinition?: Notepad
	sessionId?: NotepadSessionId
}

const initialState: NotepadState = {
	notepads: [],
}

type NotepadsAction =
	| {
			type: 'SSE_NOTEPAD_OPENED'
			payload: {
				notepadDefinition: Notepad
				sessionId: NotepadSessionId
			}
	  }
	| {
			type: 'SSE_NOTEPAD_CLOSED'
	  }
	| {
			type: 'SSE_NOTEPAD_COLLABORATION_STATE_CHANGED'
			payload: {
				notepadDefinition: Notepad
				sessionId: NotepadSessionId
			}
	  }
	| {
			type: 'SSE_NOTEPADS_STATE_CHANGED'
			payload: { notepads: Notepad[] }
	  }

export function reducer(
	state: NotepadState = initialState,
	action: NotepadsAction
): NotepadState {
	switch (action.type) {
		case 'SSE_NOTEPAD_OPENED': {
			return {
				...state,
				notepadDefinition: action.payload.notepadDefinition,
				sessionId: action.payload.sessionId,
			}
		}
		case 'SSE_NOTEPAD_CLOSED':
			return {
				...state,
				notepadDefinition: undefined,
			}
		case 'SSE_NOTEPAD_COLLABORATION_STATE_CHANGED':
			return {
				...state,
				notepadDefinition: action.payload.notepadDefinition,
				sessionId: action.payload.sessionId,
			}
		case 'SSE_NOTEPADS_STATE_CHANGED':
			return {
				...state,
				notepads: action.payload.notepads,
			}
		default:
			return state
	}
}
