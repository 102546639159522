import styled from '@emotion/styled'
import { PropsOf } from '@emotion/react'
import { useState } from 'react'
import visibleIcon from '../images/visible-password.svg'
import invisibleIcon from '../images/invisible-password.svg'
import { Input as BaseInput } from './input'

const PasswordIcon = styled.img`
	cursor: pointer;
	position: absolute;
	top: 18px;
	right: 14px;
	height: 24px;
	width: 28px;
`

const Container = styled.div`
	position: relative;
`

const Input = styled(BaseInput)`
	width: 100%;
`

const PasswordInput = (props: PropsOf<typeof BaseInput>): JSX.Element => {
	const [visible, setVisible] = useState(false)

	return (
		<Container>
			<Input {...props} type={visible ? 'text' : 'password'} />
			<PasswordIcon
				src={visible ? visibleIcon : invisibleIcon}
				onClick={() => setVisible(!visible)}
				alt="visibility"
			/>
		</Container>
	)
}

export default PasswordInput
