import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { CSSProperties, FC, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import colors from '../colors'
import { ButtonClose } from './button'
import responsive from './responsive'

const TitleContainer = styled.div`
	border-bottom: 1px solid ${colors.brightGray};
	display: flex;
	align-items: center;
	padding: 20px;
	justify-content: space-between;
	align-items: center;
	column-gap: 40px;

	${responsive.desktop1440} {
		padding: 25px 40px;
	}
`

const Title = styled.div`
	color: ${colors.black};
	font-family: Ubuntu;
	font-size: 20px;
	font-weight: 500;
	margin-right: 1em;
`
const Subtitle = styled.div`
	font-size: 12px;
	margin-top: 5px;
`

export const ModalContainer = styled.div`
	background-color: ${colors.white};
	display: flex;
	flex-direction: column;
	border-radius: 18px;
	max-height: 90vh;
	max-width: 90vw;
	overflow: hidden;
`

export const ModalContent = styled.div`
	padding: 25px 20px 25px 20px;
	flex: 1;
	overflow: auto;

	${responsive.desktop1440} {
		padding: 35px 40px 40px 40px;
	}
`

export const ModalButtons = styled.div`
	align-items: center;
	padding: 20px 40px;
	display: flex;
	justify-content: flex-end;
	position: relative;
	width: 100%;
	flex-direction: column;
	row-gap: 12px;
	border-top: 1px solid ${colors.brightGray};

	button {
		width: 100%;
	}

	${responsive.smallTablet} {
		flex-direction: row;
		column-gap: 15px;

		button {
			width: fit-content;
		}
	}
`

export const Screen = styled.div`
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 999999;
`

export const Glass: FC<{
	onClick: () => unknown
	style?: CSSProperties
}> = ({ onClick, style }) => {
	const ref = useRef<HTMLDivElement>(null)
	return (
		<div
			ref={ref}
			style={style}
			css={css`
				position: fixed;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			`}
			onClick={e => {
				if (e.target === ref.current) {
					onClick()
				}
			}}
		></div>
	)
}

export const Window = styled.div`
	background-color: ${colors.blackish};
	position: absolute;
	bottom: 0;
	left: 0;
	opacity: 0.7;
	right: 0;
	top: 0;
`

export const ModalHeader = ({
	title,
	subtitle,
	onClose,
	className,
	style,
}: {
	title: JSX.Element | string
	subtitle?: JSX.Element | string
	onClose?: () => void
	className?: string
	style?: CSSProperties
}): JSX.Element => {
	return (
		<TitleContainer className={className} style={style}>
			<div>
				<Title>{title}</Title>
				{subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
			</div>
			{onClose ? <ButtonClose onClick={onClose} /> : null}
		</TitleContainer>
	)
}

export const HandleUnfocused: React.FC<{
	onClose?: () => unknown
	className?: string
	style?: React.CSSProperties
}> = ({ children, className, onClose, style }) => {
	const containerRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		containerRef.current?.focus()
	}, [])

	return (
		<div
			css={css`
				user-select: none;
				outline: none;
			`}
			className={className}
			style={style}
			onBlurCapture={e => {
				if (onClose && !e.currentTarget.contains(e.relatedTarget)) {
					onClose()
				}
			}}
			onMouseDown={e => {
				e.stopPropagation()
			}}
			tabIndex={-1}
			ref={containerRef}
		>
			{children}
		</div>
	)
}

export const ModalPicker: React.FC<{
	position?: 'left' | 'right'
	onClose?: () => unknown
	className?: string
	fillHeight?: boolean
}> = ({ position, onClose, className, children, fillHeight }) => (
	<HandleUnfocused
		onClose={onClose}
		className={className}
		css={css`
			position: absolute;
			z-index: 2;
		`}
	>
		<div
			css={css`
				display: flex;
				flex-direction: column;
				background-color: ${colors.white};
				border-radius: 11px;
				box-shadow: 0 1px 6px 0 rgba(160, 153, 167, 0.5);
				position: absolute;
				min-width: 100%;
				overflow: hidden;
				top: 0;
				${position === 'left' ? 'right: 0' : 'left: 0'};
			`}
			style={{ minHeight: fillHeight ? '100%' : undefined }}
		>
			{children}
		</div>
	</HandleUnfocused>
)

export const GlobalModalPicker: React.FC<{
	onClose?: () => unknown
	className?: string
	style?: React.CSSProperties
}> = ({ onClose, className, style, children }) => {
	const container = useRef(document.createElement('div'))
	const splashRef = useRef(null)

	useEffect(() => {
		const div = container.current
		div.style.zIndex = '777777'
		div.style.position = 'fixed'
		div.style.width = '100vw'
		div.style.height = '100vh'

		const { body } = window.document
		body.appendChild(div)
		return () => {
			body.removeChild(div)
		}
	}, [])
	return ReactDOM.createPortal(
		<SplashContainer
			ref={splashRef}
			style={{
				backgroundColor: 'transparent',
				animation: 'none',
			}}
			onClick={e => {
				if (e.target === splashRef.current && onClose) {
					onClose()
				}
			}}
		>
			<div
				className={className}
				style={style}
				css={css`
					background-color: ${colors.white};
					border-radius: 11px;
					box-shadow: 0 1px 6px 0 rgba(160, 153, 167, 0.5);
					overflow: hidden;
				`}
			>
				{children}
			</div>
		</SplashContainer>,
		container.current
	)
}

const fadeInKeyframes = keyframes`
	0% { opacity: 0 }
	100% { opacity: 1 }
`
const SplashContainer = styled.div`
	position: fixed;
	opacity: 1;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-size: 100% 100%;
	animation: ${fadeInKeyframes} 0.3s linear 0s 1 normal forwards;
	z-index: 100;
	display: flex;
	justify-content: center;
	align-items: center;
`

export const Modal: React.FC<{
	onClose?: () => unknown
	onEsc?: () => unknown
	backgroundColor?: string
	noAnimation?: boolean
	zIndex?: string
}> = ({ children, onClose, backgroundColor, noAnimation, zIndex, onEsc }) => {
	const container = useRef(document.createElement('div'))
	const splashRef = useRef(null)

	useEffect(() => {
		const div = container.current
		div.style.zIndex = zIndex ? zIndex : '1069'
		div.style.position = 'fixed'
		div.style.width = '100vw'
		div.style.height = '100dvh'

		div.id = 'post-fullscreen-container'

		const { body } = window.document
		body.appendChild(div)
		return () => {
			body.removeChild(div)
		}
	}, [zIndex])

	useEffect(() => {
		if (!onEsc) return

		const closeOnEscape = (e: KeyboardEvent) => {
			if (e.key === 'Escape') onEsc()
		}
		window.addEventListener('keydown', closeOnEscape)

		return () => {
			window.removeEventListener('keydown', closeOnEscape)
		}
	}, [onEsc])

	return ReactDOM.createPortal(
		<SplashContainer
			id="splash-container"
			ref={splashRef}
			style={{
				backgroundColor: backgroundColor || colors.transparentViolet,
				animation: noAnimation ? 'none' : undefined,
			}}
			onClick={e => {
				if (e.target === splashRef.current && onClose) {
					onClose()
				}
			}}
		>
			{children}
		</SplashContainer>,
		container.current
	)
}
