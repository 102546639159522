import { UserId } from '@beelday/common'
import { RootState } from 'common/redux'

type QuestionResultSSE = {
	yesAnswersCount: number
	noAnswersCount: number
	respondents: UserId[]
}

type QuestionResult = {
	no: number
	yes: number
	voted: UserId[]
}

type QuestionsState = {
	active: boolean
	result: QuestionResult | null
}

const initialState: QuestionsState = {
	active: false,
	result: null,
}

type QuestionsAction =
	| {
			type: 'SSE_SIMPLE_QUESTION_FINISHED'
	  }
	| {
			type: 'SSE_SIMPLE_QUESTION_STATE_CHANGED'
			payload: QuestionResultSSE
	  }

export function reducer(
	state: QuestionsState = initialState,
	action: QuestionsAction
): QuestionsState {
	switch (action.type) {
		case 'SSE_SIMPLE_QUESTION_STATE_CHANGED': {
			const { payload } = action
			const result = {
				yes: payload.yesAnswersCount,
				no: payload.noAnswersCount,
				voted: payload.respondents,
			}
			return {
				...state,
				active: true,
				result,
			}
		}
		case 'SSE_SIMPLE_QUESTION_FINISHED':
			return {
				...state,
				active: false,
				result: null,
			}
		default:
			return state
	}
}

export const isActive = (state: RootState) => state.questions.active

export const result = (state: RootState) => state.questions.result
