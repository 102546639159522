import { FC, useCallback, MouseEvent } from 'react'
import styled from '@emotion/styled'
import { User, UserProfile } from 'common/types'
import { Colors } from 'common/presentation/colors'
import { ui } from '@beelday/common'
import { Flex } from 'common/presentation/containers'
import CloseButton from 'common/presentation/close-button-indigo'
import Modal from 'common/presentation/modal'
import Translate, { translate } from 'intl/translate'
import { interestToImage } from 'users'
import { PublicChatRoomType } from './model'
import rooms from './rooms-presentation'
import joinSrc from './images/lobby-join.svg'
import ProfileAvatar from 'users/profile-avatar'

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
`

const PopupContainer = styled.div`
	background-color: ${Colors.white};
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	width: 475px;
`

const Header = styled.div`
	border-bottom: 1px solid ${Colors.heather};
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 21px 40px 20px 40px;
`

const Title = styled.div`
	color: ${Colors.black};
	font-family: Ubuntu;
	font-size: 20px;
	font-weight: 500;
`

const Subtitle = styled.div`
	color: ${Colors.black};
	font-family: Ubuntu;
	font-size: 12px;
	margin-top: 5px;
`

const Body = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px 40px 25px 40px;
`

const JoinBtn = styled.button`
	background-color: ${Colors.indigoBlue};
	border: none;
	border-radius: 22px;
	color: ${Colors.white};
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: Ubuntu;
	font-size: 12px;
	font-weight: 500;
	margin-top: 24px;
	padding: 16px 33px;
`

const JoinImg = styled.img`
	height: 21px;
	margin-right: 11px;
	width: 21px;
`

const CURTAIN = 'CURTAIN'

const UserContainer = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
	width: 100%;

	&:first-child {
		margin-top: 0;
	}
`

const ProfileContainer = styled.div`
	display: flex;
	align-items: center;
`

const Avatar = styled.div`
	height: 47px;
	width: 47px;
`

const NameContainer = styled.div`
	margin-left: 10px;
`

const Name = styled.div`
	color: ${Colors.black};
	font-family: Ubuntu;
	font-size: 13px;
	font-weight: 500;
`

const Participant = styled.div`
	color: ${Colors.black};
	font-family: Ubuntu;
	font-size: 12px;
	font-weight: 300;
	margin-top: 2px;
`

const Trainer = styled.div`
	background-color: ${Colors.heather};
	border-radius: 3px;
	color: ${Colors.white};
	font-family: Ubuntu;
	font-size: 12px;
	font-weight: 300;
	margin-top: 2px;
	padding: 2px;
	width: fit-content;
`

const InterestImg = styled.img`
	margin-left: 5px;
	height: 32px;
	width: 32px;
`

type UserItemProps = {
	user: User & Partial<UserProfile>
}

const UserItem: FC<UserItemProps> = ({ user }) => (
	<UserContainer>
		<ProfileContainer>
			<Avatar>
				<ProfileAvatar profile={user} />
			</Avatar>
			<NameContainer>
				<Name>{user.displayName || user.name}</Name>
				{user.role === 'END_USER' ? (
					<Participant>
						<Translate>participant</Translate>
					</Participant>
				) : (
					<Trainer>
						<Translate>trainer</Translate>
					</Trainer>
				)}
			</NameContainer>
		</ProfileContainer>
		<Flex>
			{user.interests?.map(i => (
				<ui.Tooltip
					placement="top"
					key={i}
					overlay={<Translate>{'common_interest.' + i}</Translate>}
				>
					<InterestImg src={interestToImage[i]} />
				</ui.Tooltip>
			))}
		</Flex>
	</UserContainer>
)

type Props = {
	roomType: PublicChatRoomType
	users: (User & Partial<UserProfile>)[]
	onClose: (event: MouseEvent<HTMLDivElement>) => void
	onJoin: () => void
}

const AllUsersPopup: FC<Props> = ({ roomType, users, onClose, onJoin }) => {
	const close = useCallback(
		(event: MouseEvent<HTMLDivElement>) => {
			if ((event.target as any).id === CURTAIN) {
				onClose(event)
			}
		},
		[onClose]
	)

	return (
		<Modal backgroundColor={Colors.paleGrayTransparent}>
			<Container id={CURTAIN} onClick={close}>
				<PopupContainer>
					<Header>
						<div>
							<Title>
								{translate('lobby.all_users', { count: users.length })}
							</Title>
							<Subtitle>
								<Translate>{rooms[roomType].name}</Translate>
							</Subtitle>
						</div>
						<CloseButton onClick={onClose} />
					</Header>
					<Body>
						{users.map(u => (
							<UserItem key={u.id} user={u} />
						))}
						<JoinBtn onClick={onJoin}>
							<JoinImg src={joinSrc} />
							<Translate>lobby.join_discussion</Translate>
						</JoinBtn>
					</Body>
				</PopupContainer>
			</Container>
		</Modal>
	)
}

export default AllUsersPopup
