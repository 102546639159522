import { FC, useMemo } from 'react'
import { UserProfile } from 'common/types'
import ThreeColumnsVideoGrid from 'room/training-room/ice-breaker/presentation/three-columns-video-grid'
import styled from '@emotion/styled'
import { Colors } from 'common/presentation/colors'
import KnowledgeCheckHeader from './knowledge-check-header'
import KnowledgeCheckQuestionJSX from './knowledge-check-question'
import {
	KnowledgeCheck,
	KnowledgeCheckQuestionID,
	KnowledgeCheckOptionID,
} from '../knowledge-check'
import KnowledgeCheckResult from '../knowledge-check-result'
import { SceneVideoUser } from 'video-conference-media'

const Title = styled.div`
	color: ${Colors.indigoBlue};
	font-size: 20px;
	font-weight: 700;
	left: 50%;
	line-height: 24px;
	max-width: 600px;
	position: absolute;
	text-align: center;
	top: 15px;
	transform: translate X(-50%);
`

const Container = styled.div`
	background-color: ${Colors.white};
	border-radius: 42px 18px 18px 18px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0 auto;
	max-width: 700px;
	overflow: hidden;
	width: 100%;
`

type Props = {
	title?: string
	sceneVideoUsers: SceneVideoUser[]
	userProfile: UserProfile | undefined | null
	knowledgeCheck: KnowledgeCheck
	onAnswer: (
		questionId: KnowledgeCheckQuestionID,
		answers: KnowledgeCheckOptionID[]
	) => void
	onPrev: () => void
	onNext: () => void
	onFinish: (
		questionId: KnowledgeCheckQuestionID,
		answers: KnowledgeCheckOptionID[]
	) => void
}

const KnowledgeCheckGridView: FC<Props> = ({
	title,
	sceneVideoUsers,
	userProfile,
	knowledgeCheck,
	onAnswer,
	onPrev,
	onNext,
	onFinish,
}) => {
	const { name, state, questions, useCorrectAnswers } = knowledgeCheck
	const currentIdx = questions.findIndex(
		question => question.id === state.currentQuestion
	)
	const currentQuestion = questions[currentIdx]
	const progress = useMemo(() => {
		return {
			currentValue: currentIdx + 1,
			maxValue: questions.length,
		}
	}, [currentIdx, questions])

	return (
		<ThreeColumnsVideoGrid sceneVideoUsers={sceneVideoUsers}>
			<Title>{title}</Title>
			<Container>
				<KnowledgeCheckHeader
					title={name}
					userProfile={userProfile}
					progress={state.finished ? undefined : progress}
					maxDurationInSeconds={knowledgeCheck.maxDurationInSeconds}
					startTime={knowledgeCheck.startTime}
				/>
				{state.finished ? (
					<KnowledgeCheckResult
						knowledgeCheckId={knowledgeCheck.id}
						useCorrectAnswers={useCorrectAnswers}
					/>
				) : (
					<KnowledgeCheckQuestionJSX
						key={state.currentQuestion}
						question={currentQuestion}
						isLast={progress.currentValue === progress.maxValue}
						onNext={onNext}
						onPrev={onPrev}
						onAnswer={onAnswer}
						onFinish={onFinish}
						answers={knowledgeCheck.state.answers?.[currentQuestion.id] || []}
					/>
				)}
			</Container>
		</ThreeColumnsVideoGrid>
	)
}

export default KnowledgeCheckGridView
