import { UUID } from '@beelday/common'
import { Answer, PollingListPollingDefinition } from './list-polling-models'
import { RootState } from 'common/redux'

type ListPollingDefinitionsAvailabilitySSE = {
	available: boolean
}

export type ListPollingState = {
	available: boolean
	answers: Record<UUID, Answer[]>
	pollingDefinition?: PollingListPollingDefinition
}

const initialState: ListPollingState = {
	available: false,
	answers: {},
}

type ListPollingAction =
	| {
			type: 'SSE_LIST_POLLING_DEFINITIONS_AVAILABILITY'
			payload: ListPollingDefinitionsAvailabilitySSE
	  }
	| {
			type: 'SSE_LIST_POLLING_STARTED'
			payload: {
				pollingDefinition: PollingListPollingDefinition
			}
	  }
	| {
			type: 'SSE_LIST_POLLING_ANSWERS'
			payload: {
				pollingId: UUID
				questionId: UUID
				answers: Answer[]
			}
	  }
	| {
			type: 'SSE_LIST_POLLING_ALL_ANSWERS_CHANGED'
			payload: {
				pollingId: UUID
				answers: Record<UUID, Answer[]>
			}
	  }

export function reducer(
	state: ListPollingState = initialState,
	action: ListPollingAction
): ListPollingState {
	switch (action.type) {
		case 'SSE_LIST_POLLING_DEFINITIONS_AVAILABILITY': {
			return {
				...state,
				available: action.payload.available,
			}
		}
		case 'SSE_LIST_POLLING_STARTED': {
			return {
				...state,
				pollingDefinition: action.payload.pollingDefinition,
			}
		}
		case 'SSE_LIST_POLLING_ALL_ANSWERS_CHANGED': {
			return {
				...state,
				answers: action.payload.answers,
			}
		}
		case 'SSE_LIST_POLLING_ANSWERS': {
			return {
				...state,
				answers: {
					...state.answers,
					[action.payload.questionId]: [
						...(state.answers[action.payload.questionId] || []),
						...action.payload.answers,
					],
				},
			}
		}

		default:
			return state
	}
}

export const redux = {
	reducer,
}

export const selectIsListPollingAvaliable = (state: RootState): boolean =>
	state.listPolling.available
