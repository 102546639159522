import { ReactChild } from 'react'
import styled from '@emotion/styled'
import colors from '../colors'
import { warning } from '../images'

const Container = styled.div`
	align-items: center;
	background-color: ${colors.white};
	box-shadow: 0 5px 15px 0 rgba(146, 148, 166, 0.16);
	border-bottom: 4px solid ${props => props.color};
	border-radius: 4px;
	color: ${colors.black70};
	display: flex;
	font-size: 14px;
	font-weight: 500;
	height: 56px;
	padding: 0px 12px 0px 0px;
	margin: 24px 0px 24px 0px;
	width: 100%;
`

const Icon = styled.img`
	height: 15px;
	margin: 0px 12px;
	width: 15px;
`

export const Warning = ({ msg }: { msg: string | ReactChild }): JSX.Element => (
	<Container color={colors.dustyOrange}>
		<Icon src={warning} />
		{msg}
	</Container>
)
