type ErrorObj<T> = {
	[K in keyof T]?: string | string[] | null | undefined | ErrorObj<unknown>
}
export function formikError<T>(
	error: ErrorObj<T> | string | string[] | null | undefined,
	key?: keyof T
): string {
	if (!error) return ''
	if (typeof error === 'string') return error
	if (Array.isArray(error)) return error[0]
	if (key) return formikError(error[key])
	return ''
}
