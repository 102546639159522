export const Quiz: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="21.643"
		viewBox="0 0 24 21.643"
	>
		<defs>
			<clipPath id="auarkn4eca">
				<path data-name="Rectangle 3991" d="M0 0h24v21.643H0z" />
			</clipPath>
		</defs>
		<g data-name="Group 25588">
			<g data-name="Group 25587">
				<path
					data-name="Path 11820"
					d="M14.572 5.12c-5.221 0-9.428 3.7-9.428 8.262s4.207 8.262 9.428 8.262a10.387 10.387 0 0 0 5.119-1.318 5.734 5.734 0 0 0 3.6.658.264.264 0 0 0 .1-.481 3.9 3.9 0 0 1-1.5-1.926A7.587 7.587 0 0 0 24 13.382c0-4.562-4.233-8.262-9.428-8.262zm.634 11.607a.39.39 0 0 1-.38.38h-.938a.39.39 0 0 1-.38-.38v-.608a.389.389 0 0 1 .38-.38h.938a.39.39 0 0 1 .38.38zm1.494-3.675a2.578 2.578 0 0 1-.963.837 1.424 1.424 0 0 0-.431.456 1.756 1.756 0 0 0-.1.43.25.25 0 0 1-.254.228h-1.166a.246.246 0 0 1-.253-.278 1.5 1.5 0 0 1 .228-.761 3.365 3.365 0 0 1 .912-.811 1.522 1.522 0 0 0 .532-.532 1.361 1.361 0 0 0 .2-.71 1.016 1.016 0 0 0-.228-.684.771.771 0 0 0-.633-.253.839.839 0 0 0-.558.2.734.734 0 0 0-.2.33.38.38 0 0 1-.38.279l-1.089-.026a.232.232 0 0 1-.228-.253 1.715 1.715 0 0 1 .658-1.318 2.866 2.866 0 0 1 1.8-.532 2.69 2.69 0 0 1 1.875.608 2.052 2.052 0 0 1 .685 1.622 2.1 2.1 0 0 1-.407 1.168zM1.723 11.126A6.235 6.235 0 0 1 0 6.843C0 3.067 3.5 0 7.78 0a8.012 8.012 0 0 1 6.792 3.5c-5.778 0-10.543 4-11.024 9.073a4.531 4.531 0 0 1-2.965.527.2.2 0 0 1-.076-.38 3.514 3.514 0 0 0 1.216-1.594z"
				/>
			</g>
		</g>
	</svg>
)
