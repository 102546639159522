import { colors, ui } from '@beelday/common'
import { CompanyLogo } from '@beelday/common/src/ui'
import styled from '@emotion/styled'
import { Organization } from 'organization/model'
import { useOrganization } from 'organization/use-organization-branding'
import { Config } from 'common/config'
import { Colors } from 'common/presentation/colors'
import { RootState } from 'common/redux'
import React, { FC } from 'react'
import { useSelector } from 'app-redux'
import Exit from './exit-icon'
import { selectIsExpandableCompanyCard } from 'organization/redux'

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	height: 60px;
	position: relative;

	${ui.responsive.desktop1440} {
		height: 80px;
	}
`

const Title = styled.div`
	color: ${Colors.indigoBlue};
	font-family: Ubuntu;
	font-size: 20px;
	font-weight: 500;
	padding-top: 10px;
	padding-left: 10px;
	padding-right: 10px;
	text-align: center;
	min-width: 480px;
	z-index: 4;
	/* display: flex;
	flex-grow: 2;
	justify-content: center; */

	${ui.responsive.desktop1440} {
		padding-top: 20px;
	}
`

const LinksContainer = styled.div`
	align-items: center;
	justify-content: flex-end;
	display: flex;
	height: fit-content;
	padding-top: 15px;
	padding-right: 20px;
	width: 104px;

	${ui.responsive.desktop1440} {
		padding-right: 30px;
		padding-top: 20px;
	}
`

type Props = {
	streamView?: React.ReactNode | null
}

const RoomHeader: FC<Props> = ({ children, streamView }) => {
	const training = useSelector((state: RootState) => state.sessionInfo.training)

	const organization = useOrganization(training?.organizationId)

	return (
		<Header>
			<CompanyCard organization={organization} />

			{children && <Title>{children}</Title>}
			<LinksContainer>
				{streamView}
				<Exit
					color={colors.white}
					backgroundColor={colors.indigoBlue}
					trainerId={training?.upperEchelon.userId}
				/>
			</LinksContainer>
		</Header>
	)
}

export default RoomHeader

const Container = styled(ui.Flex)<{ expandable: boolean }>`
	max-width: 490px;
	height: 60px;
	z-index: 1000;

	.logo {
		z-index: 10;
		padding: ${props =>
			props.expandable ? '0px 16px 0px 14px' : '0px 16px 0px 16px'};
		position: relative;
		cursor: ${props => (props.expandable ? 'pointer' : 'default')};
		border-bottom-right-radius: ${props => (props.expandable ? '10px' : '0px')};
	}

	.name {
		border-bottom-right-radius: 10px;
		height: 60px;
		position: absolute;
		max-width: 390px;
		top: 0px;
		left: 104px;
		width: fit-content;
		padding-right: 16px;
	}

	.expandable {
		transition: transform 0.3s ease-in-out;
		transform: translateX(-100%);
		font-weight: 500;
		z-index: 9;
		left: 102px;
		border-bottom-right-radius: 10px;
	}

	:hover {
		.expandable {
			transform: translateX(0%);
		}

		.logo {
			border-bottom-right-radius: 0px;
		}
	}
`

const CompanyCard = ({
	organization,
}: {
	organization: Organization | null
}) => {
	const expandable = useSelector(selectIsExpandableCompanyCard)
	return (
		organization && (
			<Container expandable={expandable}>
				<div
					className="logo"
					style={{ backgroundColor: organization?.branding.backgroundColor }}
				>
					<CompanyLogo
						size="72px"
						userApiUrl={Config.USER_API_URL}
						logoId={organization?.branding.logoId}
						border={`4px solid ${colors.white}`}
						style={{
							borderRadius: '14px',
							marginTop: '8px',
							boxShadow: `0px 0px 8px 1px ${colors.black10}`,
						}}
					/>
				</div>

				<ui.FlexCenterAll
					className={expandable ? 'name expandable' : 'name'}
					style={{
						color: organization?.branding.fontColor,
						backgroundColor: organization?.branding.backgroundColor,
					}}
				>
					{organization?.name}
				</ui.FlexCenterAll>
			</Container>
		)
	)
}
