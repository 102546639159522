import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'common/redux'
import { workflowUsers } from 'event-source-manager/redux'
import { SplashMessage } from 'common/presentation/splash-container'
import { Colors } from 'common/presentation/colors'
import Translate from 'intl/translate'
import Modal from 'common/presentation/modal'

const RefreshToRejoinWorkflow: FC = () => {
	const [removedFromWorkflow, setRemovedFromWorkflow] = useState(false)
	const currentUser = useSelector((state: RootState) => state.workflow.user)
	const allUsersId = useSelector(workflowUsers)

	useEffect(() => {
		if (currentUser && !allUsersId.find(u => u.id === currentUser.id)) {
			setRemovedFromWorkflow(true)
		}
	}, [currentUser, allUsersId])

	return removedFromWorkflow ? (
		<Modal backgroundColor={Colors.transparentViolet}>
			<SplashMessage>
				<Translate>session.rejoin</Translate>
			</SplashMessage>
		</Modal>
	) : null
}

export default RefreshToRejoinWorkflow
