import { FC, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'

type Props = {
	zIndex?: number
}

type FixedContainerProps = Pick<Props, 'zIndex'>

const FixedContainer = styled.div`
	position: fixed;
	${(props: FixedContainerProps) => props.zIndex && `z-index: ${props.zIndex};`}
`

const CustomTooltip: FC<Props> = ({ zIndex, children }) => {
	const ref = useRef<HTMLDivElement>(null)
	const [parent, setParent] = useState<HTMLElement>()
	const rect = parent?.getBoundingClientRect()
	const [visible, setVisible] = useState(false)

	useEffect(() => {
		if (!parent) {
			return
		}
		const show = () => setVisible(true)
		const hide = () => setVisible(false)
		parent.addEventListener('mouseenter', show)
		parent.addEventListener('mouseleave', hide)
		return () => {
			parent.removeEventListener('mouseenter', show)
			parent.removeEventListener('mouseleave', hide)
		}
	}, [parent])

	useEffect(() => {
		ref.current?.parentElement && setParent(ref.current.parentElement)
	}, [])

	return (
		<div ref={ref}>
			{rect && visible && (
				<FixedContainer
					zIndex={zIndex}
					style={{ top: rect.y + rect.height, left: rect.x }}
				>
					{children}
				</FixedContainer>
			)}
		</div>
	)
}

export default CustomTooltip
