import { FC } from 'react'
import styled from '@emotion/styled'
import { Colors } from 'common/presentation/colors'

type Props = {
	currentValue: number
	maxValue: number
}

const Container = styled.div`
	position: relative;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-end;
	justify-items: flex-end;
	align-content: stretch;
	align-items: stretch;
	margin-top: -10px;
`

const Progress = styled.svg`
	width: 90px;
	height: 65px;
`

const Text = styled.div`
	background: ${Colors.white};
	font-family: Ubuntu;
	font-size: 12px;
	font-weight: 500;
	color: ${Colors.heather};
	text-align: center;
`

const Counter = styled.div`
	position: absolute;
	width: 100%;
	font-family: Ubuntu;
	font-weight: 500;
	font-size: 14px;
	color: ${Colors.heather};
	top: 30px;
	left: 0;
	text-align: center;

	b {
		color: ${Colors.black};
		font-size: 26px;
	}
`

const CountingProgressBar: FC<Props> = ({
	currentValue,
	maxValue,
	children,
}) => {
	const totalPathLength = 151.841
	const part = currentValue < maxValue ? currentValue / maxValue : 1
	const partPathLength = totalPathLength * part
	return (
		<Container>
			<Progress viewBox="0 40 100 55">
				<path
					d="M10,100 C0,30 100,30 90,100"
					fill="none"
					stroke={Colors.brightGray}
					strokeWidth={3}
				/>
				<path
					d="M10,100 C0,30 100,30 90,100"
					strokeDasharray={totalPathLength}
					strokeDashoffset={totalPathLength - partPathLength}
					fill="none"
					stroke={
						part === 1
							? Colors.greenishCyan
							: part > 0.5
							? Colors.dustyOrange
							: Colors.strongPink
					}
					strokeWidth={3}
				/>
			</Progress>
			<Text>{children}</Text>
			<Counter>
				<b>{currentValue}</b> / {maxValue}
			</Counter>
		</Container>
	)
}

export default CountingProgressBar
