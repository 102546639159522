import { ui } from '@beelday/common'
import styled from '@emotion/styled'
import { RootState } from 'common/redux'
import {
	AssignMode,
	GroupPartitionMap,
	GroupsOfSize,
	PartitionMode,
} from 'common/types'
import { useAuthenticatedBeeldayClient } from 'connectivity/beelday-hooks'
import { FC, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { sendGroupsSuggestionAssignment } from 'room/training-room/set-groups/model/set-groups'
import { GroupTaskName } from './group-task-name'
import { GroupSizeMode } from './group-size-mode'
import { GroupPickSize } from './group-pick-size'
import { GroupGroupingMode } from './group-grouping-mode'
import { assertUnreachable } from 'utils/unreachable'
import GroupSelectType from './group-select-type'
import GroupTemplatesPopup from './group-templates-popup'
import { Grouping } from '../../types'
import { selectGroupingDefinitionsAvailability } from 'room/training-room/redux'

const Container = styled.div`
	min-width: 420px;
	max-width: 60vw;
	padding: 20px 20px 40px 20px;
`

enum SettingGroupsParametersState {
	SetGroupingType,
	SetTaskName,
	SetSettingGroupsMode,
	SetGroupsSize,
	SetGroupsAssignmentMode,
}

type SetGroupsParametersPanelProps = {
	groupPartitionMap: GroupPartitionMap
	finishSettingGroupsParameters(
		partitionMode: PartitionMode,
		assignMode: AssignMode,
		taskName: string,
		maxGroupSize?: number
	): void
	onChoseFromTemplate: () => unknown
}

const SetGroupsParametersPanel: FC<SetGroupsParametersPanelProps> = props => {
	const isAvailableGroupingFromTools = useSelector(
		selectGroupingDefinitionsAvailability
	)
	const [settingGroupsParametersState, setSettingGroupsParametersState] =
		useState<SettingGroupsParametersState>(
			isAvailableGroupingFromTools
				? SettingGroupsParametersState.SetGroupingType
				: SettingGroupsParametersState.SetTaskName
		)
	const [partitionMode, setPartitionMode] = useState<PartitionMode>(
		PartitionMode.None
	)
	const [taskName, setTaskName] = useState<string>('task')
	const [maxGroupSize, setMaxGroupSize] = useState<number>()
	const [selectedGroups, setSelectedGroups] = useState<GroupsOfSize[]>()
	const setTask = useCallback(taskName => {
		setTaskName(taskName)
		setSettingGroupsParametersState(
			SettingGroupsParametersState.SetSettingGroupsMode
		)
	}, [])

	const finish = useCallback(
		(assignMode: AssignMode) => {
			props.finishSettingGroupsParameters(
				partitionMode,
				assignMode,
				taskName,
				maxGroupSize
			)
		},
		[maxGroupSize, partitionMode, props, taskName]
	)

	const handlePartitionModeSelected = useCallback(
		(selectedPartitionMode: PartitionMode) => {
			switch (selectedPartitionMode) {
				case PartitionMode.Automatic:
					setPartitionMode(selectedPartitionMode)
					finish(AssignMode.Automatic)
					break
				case PartitionMode.Manual:
					setPartitionMode(selectedPartitionMode)
					setSettingGroupsParametersState(
						SettingGroupsParametersState.SetGroupsSize
					)
					break
				case PartitionMode.None:
					break
				default:
					assertUnreachable('PartitionMode', selectedPartitionMode)
			}
		},
		[finish]
	)

	switch (settingGroupsParametersState) {
		case SettingGroupsParametersState.SetGroupingType:
			return (
				<GroupSelectType
					onManualChose={() =>
						setSettingGroupsParametersState(
							SettingGroupsParametersState.SetTaskName
						)
					}
					onTemplateChose={() => {
						props.onChoseFromTemplate()
					}}
				/>
			)
		case SettingGroupsParametersState.SetTaskName:
			return <GroupTaskName onTaskSet={setTask} />
		case SettingGroupsParametersState.SetSettingGroupsMode:
			return (
				<GroupSizeMode onPartitionModeSelected={handlePartitionModeSelected} />
			)
		case SettingGroupsParametersState.SetGroupsSize:
			return (
				<GroupPickSize
					onFinishSelectingMaxGroupSize={(
						selectedMaxGroupSize,
						selectedGroups
					) => {
						setMaxGroupSize(selectedMaxGroupSize)
						setSelectedGroups(selectedGroups)
						setSettingGroupsParametersState(
							SettingGroupsParametersState.SetGroupsAssignmentMode
						)
					}}
					groupPartitionMap={props.groupPartitionMap}
				/>
			)
		case SettingGroupsParametersState.SetGroupsAssignmentMode:
			return (
				<GroupGroupingMode
					selectedGroups={selectedGroups || []}
					onAssignmentModeSelected={finish}
				/>
			)
	}
}

type Props = {
	onCancel: () => void
	onDone: () => unknown
}

const ConnectedSetGroupsParametersPanel: FC<Props> = ({ onCancel, onDone }) => {
	const beeldayClient = useAuthenticatedBeeldayClient()
	const joinedRoomAddress = useAssertedJoinedRoomAddress()

	const groupPartitionMap = useSelector(
		(state: RootState) => state.trainingRoom.groupPartitionMap
	)

	const [isChoseFromTemplate, setIsChoseFromTemplate] = useState<boolean>(false)

	const finishSettingGroupsParameters = (
		partitionMode: PartitionMode,
		assignMode: AssignMode,
		taskName: string,
		maxGroupSize?: number
	) => {
		sendGroupsSuggestionAssignment({
			...joinedRoomAddress,
			partitionMode,
			assignMode,
			taskName,
			maxGroupSize,
			beeldayClient,
		}).then(onDone)
	}

	return (
		<>
			{!isChoseFromTemplate ? (
				<Container>
					<ui.FlexEnd style={{ paddingBottom: '10px' }}>
						<ui.ButtonClose onClick={onCancel} />
					</ui.FlexEnd>
					<SetGroupsParametersPanel
						groupPartitionMap={groupPartitionMap}
						finishSettingGroupsParameters={finishSettingGroupsParameters}
						onChoseFromTemplate={() => setIsChoseFromTemplate(true)}
					/>
				</Container>
			) : (
				<GroupTemplatesPopup
					address={joinedRoomAddress}
					onClose={() => {
						onCancel()
						setIsChoseFromTemplate(false)
					}}
					onStart={(grouping: Grouping) =>
						beeldayClient.createGroupingFromTemplate(
							joinedRoomAddress,
							grouping
						)
					}
				/>
			)}
		</>
	)
}

export default ConnectedSetGroupsParametersPanel
