import { colors, ui } from '@beelday/common'
import styled, { CSSObject } from '@emotion/styled'
import { FC } from 'react'
import Select from 'react-select'

const SelectWrapper = styled.div`
	position: relative;
	margin-bottom: 10px;
`

const SelectLabel = styled.div`
	position: absolute;
	left: 52px;
	top: 12px;
	z-index: 1;
	pointer-events: none;
	font-size: 12px;
	line-height: 1;
`

const CircleIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${colors.blueGroup};
	border-radius: 50%;
	position: absolute;
	left: 10px;
	top: 50%;
	transform: translateY(-50%);
	width: 32px;
	height: 32px;
	z-index: 1;

	svg {
		max-width: 15px;
		max-height: 16px;
		fill: ${colors.white};
	}
`

type Props = {
	devices: MediaDeviceInfo[]
	selected?: string
	disabled?: boolean
	label: JSX.Element
	icon: JSX.Element
	onChange: (value: string) => unknown
}

const SelectDevice: FC<Props> = ({
	devices,
	selected,
	disabled,
	label,
	icon,
	onChange,
}) => {
	return (
		<SelectWrapper>
			<SelectLabel>{label}</SelectLabel>
			<CircleIcon>{icon}</CircleIcon>
			<Select
				value={devices.find(device => device.deviceId === selected)}
				isClearable={false}
				isSearchable={false}
				isMulti={false}
				options={devices}
				getOptionLabel={option => option.label}
				getOptionValue={option => option.deviceId}
				disabled={disabled}
				styles={SELECT_STYLES}
				onChange={device =>
					device?.deviceId && selected !== device.deviceId
						? onChange(device.deviceId)
						: selected
				}
			/>
		</SelectWrapper>
	)
}

const SELECT_STYLES = {
	...ui.SELECT_STYLES,
	control: (base: CSSObject, state: { menuIsOpen: boolean }): CSSObject => ({
		...base,
		padding: '22px 15px 3px 15px',
		border: `none`,
		borderRadius: state.menuIsOpen ? '11px 11px 0 0' : '11px',
		boxShadow: '0 2px 5px 0 rgba(38, 51, 77, 0.1)',
		cursor: 'pointer',
	}),
	menu: (base: CSSObject): CSSObject => ({
		...base,
		borderRadius: '0 0 11px 11px',
		margin: '-1px 0 0 0',
		boxShadow: '0',
		border: `solid 1px ${colors.brightGray}`,
		zIndex: 2,
	}),
	valueContainer: (base: CSSObject): CSSObject => ({
		...base,
		paddingLeft: '34px',
	}),
	menuList: (base: CSSObject): CSSObject => ({
		...base,
		display: 'flex',
		flexWrap: 'wrap',
		overflow: 'hidden',
		borderRadius: '0 0 11px 11px',
	}),
	option: (
		base: CSSObject,
		state: { isSelected: boolean; isFocused: boolean; data: MediaDeviceInfo }
	): CSSObject => {
		return {
			...base,
			display: 'flex',
			alignItems: 'center',
			fontSize: '14px',
			backgroundColor: state.isSelected ? colors.indigoBlue : undefined,
			color: state.isSelected ? colors.white : undefined,
			cursor: 'pointer',
			transition: 'color 0.25s',

			'&:hover': {
				color: state.isSelected ? colors.white : colors.indigoBlue,
			},

			'&::focus-visible': {
				outline: 'none',
			},
		}
	},
}

export default SelectDevice
