import styled from '@emotion/styled'
import { Translate } from '../intl/translate'
import { FC, createContext, useState, useContext, useMemo } from 'react'
import colors from '../colors'
import { ButtonMinor, ButtonPrimary } from './button'
import responsive from './responsive'
import CloseButton from './close'
import { Modal } from './modal'

export const DialogBody = styled.div`
	background-color: ${colors.white};
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	bottom: 0;
	height: fit-content;
	opacity: 1;
	position: relative;
	padding: 0px 20px 20px 20px;
	position: absolute;
	width: 100%;

	${responsive.smallTablet} {
		align-items: center;
		background: ${colors.white};
		border-radius: 18px;
		display: flex;
		flex-direction: column;
		height: 300px;
		justify-content: space-between;
		position: relative;
		padding: 40px 40px 40px 40px;
		width: 650px;
	}
`

export const MobileSeparator = styled.div`
	border-radius: 2px;
	background-color: ${colors.borderGray50};
	display: flex;
	height: 4px;
	margin: 8px auto 9px auto;
	width: 54px;

	${responsive.smallTablet} {
		display: none;
	}
`

export const CloseBtnContainer = styled.div`
	position: absolute;
	right: 20px;
	top: 20px;

	${responsive.smallTablet} {
		right: 40px;
		top: 36px;
	}
`

export const Title = styled.div`
	align-items: center;
	display: flex;
	font-size: 16px;
	font-weight: 500;
	justify-content: center;
	margin-top: 16px;
	margin-bottom: 24px;
	width: 75%;
	margin-left: auto;
	margin-right: auto;
	text-align: center;

	${responsive.smallTablet} {
		font-size: 20px;
		height: fit-content;
		justify-content: space-between;
		margin-bottom: 30px;
		margin-top: 0px;
		left: -27px;
		width: 100%;
	}
`

export const Separator = styled.div`
	display: none;

	${responsive.smallTablet} {
		display: flex;
		width: 650px;
		height: 1px;
		background-color: ${colors.borderGray50};
		margin-bottom: 34px;
	}
`

const Desc = styled.div`
	display: flex;
	font-family: Ubuntu;
	font-size: 20px;
	font-weight: normal;
	height: fit-content;
	margin-bottom: 33px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	width: 80%;

	${responsive.smallTablet} {
		align-items: start;
		font-size: 14px;
		height: 100%;
		justify-content: flex-start;
		margin-bottom: 0px;
		text-align: start;
		width: 100%;
	}
`

export const Buttons = styled.div`
	align-items: center;
	display: flex;
	justify-content: flex-end;
	position: relative;
	width: 100%;
	flex-direction: column;
	row-gap: 12px;

	button {
		width: 100%;
	}

	${responsive.smallTablet} {
		flex-direction: row;
		column-gap: 15px;

		button {
			width: fit-content;
		}
	}
`

type Props = {
	title: JSX.Element | string
	description: JSX.Element | string
	proceed: () => void
	cancel: () => void
	okButton?: JSX.Element | string
	cancelButton?: JSX.Element | string
	cancelIsPrimary?: boolean
}

const Dialog: FC<Props> = ({
	title,
	description,
	proceed,
	cancel,
	okButton,
	cancelButton,
}) => {
	const isMobile = responsive.useIsMobile()

	return (
		<Modal onClose={cancel} backgroundColor={colors.transparentBlackish}>
			<DialogBody>
				<MobileSeparator />
				<CloseBtnContainer>
					<CloseButton onClick={cancel} />
				</CloseBtnContainer>
				<Title>{title}</Title>
				<Separator />
				<Desc>{description}</Desc>
				{isMobile ? (
					<Buttons>
						<ButtonPrimary onClick={proceed}>
							{okButton || <Translate>ok</Translate>}
						</ButtonPrimary>
						<ButtonMinor onClick={cancel}>
							{cancelButton || <Translate>cancel</Translate>}
						</ButtonMinor>
					</Buttons>
				) : (
					<Buttons>
						<ButtonMinor onClick={cancel}>
							{cancelButton || <Translate>cancel</Translate>}
						</ButtonMinor>
						<ButtonPrimary onClick={proceed}>
							{okButton || <Translate>ok</Translate>}
						</ButtonPrimary>
					</Buttons>
				)}
			</DialogBody>
		</Modal>
	)
}

type Result = 'CONFIRMED' | 'CANCELLED'

type Handlers = {
	delete(
		title?: JSX.Element | string,
		description?: JSX.Element | string
	): Promise<Result>
	yesno(
		title: JSX.Element | string,
		description: JSX.Element | string
	): Promise<Result>
	generic(
		title: JSX.Element | string,
		description: JSX.Element | string,
		okButton: JSX.Element | string | undefined,
		cancelButton: JSX.Element | string | undefined
	): Promise<Result>
	unsaved(): Promise<Result>
	reject(): Promise<Result>
	logout(): Promise<Result>
}
const createHandlers = (
	setDialog: (dialog: JSX.Element | null) => unknown
): Handlers => ({
	delete: (title, description) => {
		return new Promise((resolve, reject) => {
			setDialog(
				<Dialog
					title={title || <Translate>are_you_sure</Translate>}
					description={
						description || <Translate>delete.description.default</Translate>
					}
					cancelButton={<Translate>delete.no</Translate>}
					okButton={<Translate>delete.yes</Translate>}
					proceed={() => {
						setDialog(null)
						resolve('CONFIRMED')
					}}
					cancel={() => {
						setDialog(null)
						reject('CANCELLED')
					}}
				/>
			)
		})
	},
	yesno: (title, description) => {
		return new Promise((resolve, reject) => {
			setDialog(
				<Dialog
					title={title}
					description={description}
					proceed={() => {
						setDialog(null)
						resolve('CONFIRMED')
					}}
					cancel={() => {
						setDialog(null)
						reject('CANCELLED')
					}}
				/>
			)
		})
	},
	generic: (title, description, okButton, cancelButton) => {
		return new Promise((resolve, reject) => {
			setDialog(
				<Dialog
					title={title}
					description={description}
					cancelButton={cancelButton}
					okButton={okButton}
					proceed={() => {
						setDialog(null)
						resolve('CONFIRMED')
					}}
					cancel={() => {
						setDialog(null)
						reject('CANCELLED')
					}}
				/>
			)
		})
	},
	unsaved: () => {
		return new Promise((resolve, reject) => {
			setDialog(
				<Dialog
					title={<Translate>unsaved_data.title</Translate>}
					description={<Translate>unsaved_data.desc</Translate>}
					cancelButton={<Translate>unsaved_data.stay_button</Translate>}
					okButton={<Translate>unsaved_data.discard_button</Translate>}
					cancelIsPrimary={true}
					proceed={() => {
						setDialog(null)
						resolve('CONFIRMED')
					}}
					cancel={() => {
						setDialog(null)
						reject('CANCELLED')
					}}
				/>
			)
		})
	},
	reject: () => {
		return new Promise((resolve, reject) => {
			setDialog(
				<Dialog
					title={<Translate>dashboard.current.reject_popup.header</Translate>}
					description={
						<Translate>dashboard.current.reject_popup.body</Translate>
					}
					cancelButton={
						<Translate>dashboard.current.reject_popup.no</Translate>
					}
					okButton={<Translate>dashboard.current.reject_popup.yes</Translate>}
					proceed={() => {
						setDialog(null)
						resolve('CONFIRMED')
					}}
					cancel={() => {
						setDialog(null)
						reject('CANCELLED')
					}}
				/>
			)
		})
	},
	logout: () => {
		return new Promise((resolve, reject) => {
			setDialog(
				<Dialog
					title={<Translate>menu.logout</Translate>}
					description={<Translate>logout.message</Translate>}
					cancelButton={<Translate>logout.cancel</Translate>}
					okButton={<Translate>logout.confirm</Translate>}
					cancelIsPrimary={false}
					proceed={() => {
						setDialog(null)
						resolve('CONFIRMED')
					}}
					cancel={() => {
						setDialog(null)
						reject('CANCELLED')
					}}
				/>
			)
		})
	},
})

const ConfirmContext = createContext<Handlers | null>(null)
export const useConfirm = (): Handlers => {
	const handlers = useContext(ConfirmContext)
	if (!handlers)
		throw new Error('ConfirmProvider is not mounted in parent tree')

	return handlers
}
export const ConfirmProvider: FC = ({ children }) => {
	const [dialog, setDialog] = useState<JSX.Element | null>(null)
	const handlers = useMemo(() => createHandlers(setDialog), [])
	return (
		<ConfirmContext.Provider value={handlers}>
			{children}
			{dialog}
		</ConfirmContext.Provider>
	)
}
