import styled from '@emotion/styled'
import colors from '../colors'

export const Divider = styled.div`
	display: flex;
	align-items: center;
	text-align: center;
	font-size: 14px;
	color: ${colors.darkGray};

	::before,
	::after {
		content: '';
		flex: 1;
		border-bottom: 1px solid ${colors.darkGray};
	}

	:not(:empty)::before {
		margin-right: 1.25em;
	}

	:not(:empty)::after {
		margin-left: 1.25em;
	}
`
