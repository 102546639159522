import { Answer, PollingWordCloudDefinition } from './world-cloud-models'
import { RootState } from 'common/redux'
import { UUID } from 'common/types'

type WorldCloudDefinitionsAvailabilitySSE = {
	available: boolean
}

export type WordCloudState = {
	available: boolean
	answers: Answer[]
	allUsers: UUID[]
	answerers: UUID[]
	pollingDefinition?: PollingWordCloudDefinition
}

const initialState: WordCloudState = {
	available: false,
	answers: [],
	allUsers: [],
	answerers: [],
}

type WordCloudAction =
	| {
			type: 'SSE_WORD_CLOUD_POLLING_DEFINITIONS_AVAILABILITY'
			payload: WorldCloudDefinitionsAvailabilitySSE
	  }
	| {
			type: 'SSE_WORD_CLOUD_POLLING_STARTED'
			payload: {
				pollingDefinition: PollingWordCloudDefinition
			}
	  }
	| {
			type: 'SSE_WORD_CLOUD_POLLING_ANSWERS'
			payload: {
				answers: Answer[]
			}
	  }
	| {
			type: 'SSE_WORD_CLOUD_POLLING_ANSWERERS_STATS_CHANGED'
			payload: {
				allUsers: UUID[]
				answerers: UUID[]
			}
	  }

export function reducer(
	state: WordCloudState = initialState,
	action: WordCloudAction
): WordCloudState {
	switch (action.type) {
		case 'SSE_WORD_CLOUD_POLLING_DEFINITIONS_AVAILABILITY': {
			return {
				...state,
				available: action.payload.available,
			}
		}
		case 'SSE_WORD_CLOUD_POLLING_STARTED': {
			return {
				...state,
				pollingDefinition: action.payload.pollingDefinition,
			}
		}
		case 'SSE_WORD_CLOUD_POLLING_ANSWERS': {
			return {
				...state,
				answers: action.payload.answers,
			}
		}
		case 'SSE_WORD_CLOUD_POLLING_ANSWERERS_STATS_CHANGED': {
			return {
				...state,
				allUsers: action.payload.allUsers,
				answerers: action.payload.answerers,
			}
		}

		default:
			return state
	}
}

export const redux = {
	reducer,
}

export const selectIsWordCloudAvaliable = (state: RootState): boolean =>
	state.wordCloud.available
