import { useSelector } from 'react-redux'
import {
	MenuToggleItem,
	PopupMenu,
	PopupMenuElement,
} from 'room/common/presentation/menu'
import { isScreenSharingActive } from 'room/common/redux'
import ScreenShareIcon from './screen-share-icon'
import { colors, intl } from '@beelday/common'
import useScreenShare from './use-screen-share'
import { useAssertedWorkflowUser } from '../../room/common/use-workflow-user'
import { RootState } from '../../common/redux'
import { FC, useCallback, useState } from 'react'
import ScreenShareTraineePopup from './screen-share-trainee-popup'
import LoadingScreenShareIcon from './loading-screen-share-icon'

type Props = {
	canStart: boolean
}

const ScreenShareMenuItem: FC<Props> = ({ canStart }) => {
	const translate = intl.useTranslate()
	const [showPopup, setShowPopup] = useState<boolean>(false)
	const { meScreenSharing, start, stop, requestPermission, disablePermission } =
		useScreenShare()
	const active = useSelector(isScreenSharingActive)

	const { allowedUsers, requestedByUsers, permissionMode } = useSelector(
		(state: RootState) => state.screenShare
	)

	const currentUser = useAssertedWorkflowUser()

	const hasPermission = allowedUsers.find(user => user === currentUser.id)
	const isRequested = !!requestedByUsers.find(user => user === currentUser.id)

	const onClick = useCallback(() => {
		if (hasPermission && !active) {
			start()
		} else if (hasPermission) {
			stop()
		} else if (isRequested) {
			disablePermission(currentUser.id).then(() => setShowPopup(false))
		} else {
			requestPermission().then(() => setShowPopup(true))
		}
	}, [
		active,
		currentUser.id,
		disablePermission,
		hasPermission,
		isRequested,
		requestPermission,
		start,
		stop,
	])

	const onStart = useCallback(() => {
		start().then(() => {
			setShowPopup(false)
		})
	}, [start])

	const onRequest = useCallback(() => {
		requestPermission().then(() => setShowPopup(true))
	}, [requestPermission])

	const onCancel = useCallback(() => {
		disablePermission(currentUser.id).then(() => setShowPopup(false))
	}, [currentUser.id, disablePermission])

	if (permissionMode === 'NO_ONE' && !hasPermission) {
		return null
	}

	return (
		<>
			<MenuToggleItem
				active={active || isRequested}
				disabled={!canStart && active && !meScreenSharing}
				icon={isRequested ? <LoadingScreenShareIcon /> : <ScreenShareIcon />}
				onClick={onClick}
				label={<intl.Translate>room_menu.screen_share</intl.Translate>}
			>
				<PopupMenu>
					<>
						{hasPermission ? (
							<>
								{!active && (
									<PopupMenuElement
										text={translate('room_menu.screen_share.popup.start')}
										onClick={onStart}
									/>
								)}

								<PopupMenuElement
									text={translate('room_menu.screen_share.popup.cancel_long')}
									onClick={onCancel}
									style={{ color: colors.pinkRed, fontWeight: 'bolder' }}
								/>
							</>
						) : null}
						{isRequested ? (
							<PopupMenuElement
								text={translate('room_menu.screen_share.popup.cancel_long')}
								onClick={onCancel}
								style={{ color: colors.pinkRed, fontWeight: 'bolder' }}
							/>
						) : null}
						{!isRequested && !hasPermission ? (
							<PopupMenuElement
								text={translate('room_menu.screen_share.menu.request')}
								onClick={onRequest}
							/>
						) : null}
					</>
				</PopupMenu>
			</MenuToggleItem>
			{showPopup && !active ? (
				<ScreenShareTraineePopup
					onClose={() => setShowPopup(false)}
					isPermission={!!hasPermission}
					onCancel={onCancel}
				/>
			) : null}
		</>
	)
}

export default ScreenShareMenuItem
