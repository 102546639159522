import React, { useRef, useEffect } from 'react'
import SplashContainer from './splash-container'
import { Colors } from './colors'
import ReactDOM from 'react-dom'

type Props = {
	backgroundColor?: string
	children: React.ReactNode
}
export const Modal: React.FC<Props> = ({ children, backgroundColor }) => {
	const container = useRef(document.createElement('div'))

	useEffect(() => {
		const div = container.current
		div.style.zIndex = '999999'
		div.style.position = 'fixed'
		div.style.width = '100vw'
		div.style.height = '100vh'

		const { body } = window.document
		body.appendChild(div)
		return () => {
			body.removeChild(div)
		}
	}, [])
	return ReactDOM.createPortal(
		<SplashContainer
			backgroundColor={backgroundColor || Colors.transparentViolet}
		>
			{children}
		</SplashContainer>,
		container.current
	)
}

export default Modal
