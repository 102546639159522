import { useEffect, useState } from 'react'
import { Config } from 'common/config'
import {
	awaitConnection,
	getAwaitedPromise,
} from 'connectivity/awaited-connections'

enum ContentType {
	Image,
}

const contentType = (url: string): ContentType => {
	if (url.endsWith('.svg') || url.endsWith('.png') || url.endsWith('.jpg')) {
		return ContentType.Image
	}
	throw Error(`Unsupported type of content url: '${url}`)
}

const preloadedImage = (url: string) => {
	return new Promise<HTMLImageElement>(resolve => {
		const image = new Image()
		image.src = url
		image.addEventListener('load', () => resolve(image))
		image.addEventListener('error', e =>
			setTimeout(() => resolve(preloadedImage(url)), Config.requestRetryDelay)
		)
	})
}

const toKey = (url: string) => `PRELOAD,${url}`

const preload = (url: string) => {
	const key = toKey(url)
	const currentPromise = getAwaitedPromise(key)
	if (currentPromise) {
		return currentPromise
	}
	switch (contentType(url)) {
		case ContentType.Image: {
			return awaitConnection(key, preloadedImage(url))
		}
		default:
			throw Error(`Unsupported content type of ${url}`)
	}
}

type AssetUrl = string
type Asset = AssetUrl | boolean

const preloadAll = (assetUrls: AssetUrl[]) => {
	return Promise.allSettled(assetUrls.map(preload))
}

const assetUrls = (assets: Asset[]): AssetUrl[] => {
	return assets
		.filter(asset => typeof asset === 'string')
		.map(asset => asset as string)
}

const assetPreloadingState = (assets: Asset[]): boolean[] => {
	return assets
		.filter(asset => typeof asset === 'boolean')
		.map(asset => asset as boolean)
}

export const usePreloaded = (assets: Asset[]) => {
	const [ready, setReady] = useState(false)

	useEffect(() => {
		const urls = assetUrls(assets)
		const preloadingStates = assetPreloadingState(assets)
		const validateReadiness = () => setReady(!preloadingStates.includes(false))
		if (urls.length === 0) {
			validateReadiness()
		}
		preloadAll(urls).then(validateReadiness)
	}, [assets])

	return ready
}
