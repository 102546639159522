import styled from '@emotion/styled'
import { FC } from 'react'
import { ButtonInline } from './button'
import colors from '../colors'
import { plus } from '../images'

const Img = styled.img`
	cursor: pointer;
	margin-right: 10px;
	height: 16px;
	width: 16px;
`

type Props = {
	onClick: () => void
	children: React.ReactElement
}

export const AddNewButton: FC<Props> = ({ onClick, children }) => (
	<ButtonInline type="button" onClick={onClick}>
		<Img src={plus} />
		{children}
	</ButtonInline>
)

export const AddNewButtonSection = styled.div`
	align-items: center;
	column-gap: 10px;
	display: flex;
	justify-content: center;
	position: relative;
	width: 100%;

	button {
		z-index: 1;
	}

	&::before {
		background-color: ${colors.borderLightGray};
		content: '';
		height: 1px;
		position: absolute;
		width: 100%;
	}
`

export default AddNewButton
