export const EyeInvisibleIcon = (): JSX.Element => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="30"
		height="24"
		viewBox="0 0 30 24"
	>
		<g fill="none" fillRule="evenodd">
			<g stroke="currentColor" strokeWidth="2">
				<g>
					<g transform="translate(-1210 -371) translate(1212 371.5) translate(0 5.5)">
						<ellipse cx="13.5" cy="6.5" rx="5.75" ry="5.5" />
						<path d="M0 6.81c9.139-9.08 17.889-9.08 26.25 0 0 0-4.246 6.19-12.541 6.19C8.179 13 3.609 10.937 0 6.81z" />
					</g>
					<path
						strokeLinecap="round"
						d="M1.173 1.111L25.935 21.889"
						transform="translate(-1210 -371) translate(1212 371.5)"
					/>
				</g>
			</g>
		</g>
	</svg>
)
