import styled from '@emotion/styled'

export const ToolTipP = styled.p`
	margin: 8px 0;
	object-fit: contain;
	font-family: Ubuntu;
	font-size: 11px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.56;
	letter-spacing: normal;
	text-align: center;
	color: #272957;
	width: 77%;
	align-self: center;
`
