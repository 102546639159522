import { FC, useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

type BundleValue = string | number | Date | ChunksFormatter
type ChunksFormatter = (chunks: string) => JSX.Element
type Props = Record<string, BundleValue> & {
	children: string
}

type TranslateFn = (id?: string, values?: Record<string, BundleValue>) => string

const FALLBACK_LOCALE = 'en'

export const useTranslate = (): TranslateFn => {
	const intl = useIntl()
	return useCallback(
		(id?: string, values?: Record<string, BundleValue>): string => {
			return intl.formatMessage({ id }, values)?.toString() || ''
		},
		[intl]
	)
}

export const Translate: FC<Props> = ({ children, ...rest }) => (
	<FormattedMessage id={children} values={{ em: Emphasize, ...rest }} />
)

export const Emphasize: ChunksFormatter = chunks => <em>{chunks}</em>
export const ButtonChunk: ChunksFormatter = chunks => (
	<button type="button" role="link">
		{chunks}
	</button>
)

export const translate = (
	key: string,
	messages: {
		[x: string]: Record<string, string>
	},
	locale?: string
): string => {
	if (locale) {
		return messages[locale.substring(0, 2)]
			? messages[locale.substring(0, 2)][key]
			: messages[FALLBACK_LOCALE][key]
	}

	return messages[FALLBACK_LOCALE][key]
}

export default Translate
