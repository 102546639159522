export const BackArrowLeftIcon: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="10.828"
		height="18.828"
		viewBox="0 0 10.828 18.828"
		stroke="currentColor"
	>
		<path
			id="Path_12144"
			data-name="Path 12144"
			d="M-7637.886-7178.627l-8,8,8,8"
			transform="translate(7647.3 7180.042)"
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeWidth="2"
		/>
	</svg>
)
