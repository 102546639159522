import { css } from '@emotion/react'
import { useFormik } from 'formik'
import { isEmpty } from 'lodash'
import colors from '../colors'
import { Translate } from '../intl'
import { ButtonMinor, ButtonPrimary } from './button'
import { FlexEnd } from './containers'
import DetailsPanel from './details-panel'
import { ErrorPlaceholder, Input, InputLabeled } from './input'
import { Modal } from './modal'
import { useEffect, useRef } from 'react'

type Props = {
	title?: string
	subtitle?: string
	name?: string | null
	onDone: (newName: string) => unknown
	onCancel: () => void
}

export const RenamePopup = ({
	name,
	onDone,
	onCancel,
	title,
	subtitle,
}: Props): JSX.Element => {
	const inputRef = useRef<HTMLInputElement>(null)
	const formik = useFormik({
		initialValues: {
			newName: name,
		},
		validate: values => {
			const errors: { newName?: string } = {}

			if (isEmpty(values.newName)) {
				errors.newName = 'field.required'
			}

			return errors
		},
		onSubmit: values => {
			onDone(values.newName || '')
		},
	})

	useEffect(() => {
		inputRef.current?.select()
	}, [])

	return (
		<Modal backgroundColor={colors.transparentPaleGray} onEsc={onCancel}>
			<DetailsPanel
				onClose={onCancel}
				title={title ?? <Translate>templates.change_name.title</Translate>}
				css={css`
					width: 620px;
				`}
			>
				<form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
					<InputLabeled
						label={
							subtitle ?? <Translate>templates.change_name.new_name</Translate>
						}
					>
						<Input
							name="newName"
							onChange={formik.handleChange}
							incorrect={formik.touched.newName && !!formik.errors.newName}
							value={formik.values.newName || ''}
							ref={inputRef}
							autoFocus
						/>
						<ErrorPlaceholder>
							{formik.errors.newName ? (
								<Translate>{formik.errors.newName}</Translate>
							) : null}
						</ErrorPlaceholder>
					</InputLabeled>
					<FlexEnd>
						<ButtonMinor
							style={{ marginRight: '15px' }}
							onClick={onCancel}
							type="button"
						>
							<Translate>cancel</Translate>
						</ButtonMinor>
						<ButtonPrimary
							color="indigoBlue"
							disabled={!!formik.errors.newName}
							type="submit"
						>
							<Translate>ok</Translate>
						</ButtonPrimary>
					</FlexEnd>
				</form>
			</DetailsPanel>
		</Modal>
	)
}
