import { intl, ui } from '@beelday/common'
import { ReactElement } from 'react'
import { KnowledgeCheckReview, KnowledgeCheckStats } from './knowledge-check'
import KnowledgeCheckReviewQuestion from './presentation/knowledge-check-review-question'

type Props = {
	onClose?: () => void
	review: KnowledgeCheckReview
	useCorrectAnswers: boolean
	stats?: KnowledgeCheckStats
}

const KnowledgeCheckReviewPopup = ({
	onClose,
	review,
	useCorrectAnswers,
	stats,
}: Props): ReactElement => {
	return (
		<ui.RightDetailsPanel
			header={
				<ui.FlexCenter>
					{stats ? (
						<div style={{ marginRight: '15px' }}>
							<ui.CircleProgress
								value={stats.finished.length}
								maxValue={
									stats.notStarted.length +
									stats.started.length +
									stats.finished.length
								}
							/>
						</div>
					) : undefined}
					<ui.RightDetailsPanel.SubTitleAndTitle
						title={
							<intl.Translate>
								knowledge_check.review.header_title
							</intl.Translate>
						}
						subTitle={
							<intl.Translate>
								knowledge_check.review.header_desc
							</intl.Translate>
						}
					/>
				</ui.FlexCenter>
			}
			onClose={onClose}
		>
			{review.questions.map((question, index) => (
				<KnowledgeCheckReviewQuestion
					key={question.id}
					question={question}
					number={index + 1}
					countUsers={review.users.length}
					useCorrectAnswers={useCorrectAnswers}
				/>
			))}
		</ui.RightDetailsPanel>
	)
}

export default KnowledgeCheckReviewPopup
