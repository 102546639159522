import { FC, useMemo, useState } from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { translate } from 'intl/translate'
import { colors } from '@beelday/common'
import AnswerStatsButton, {
	AnswerResult,
} from 'room/training-room/ice-breaker/presentation/answer-stats-button'
import ApproachingContainer from 'common/presentation/approaching-container'
import CountingProgressBar from 'room/training-room/ice-breaker/presentation/counting-progress-bar'
import QuestionImage from 'room/training-room/ice-breaker/presentation/question-image'
import ConfirmBanner from 'common/presentation/confirm-banner'
import { GrowthColumnContainer } from 'common/presentation/containers'
import { ui } from '@beelday/common'

type Props = {
	responses?: number
	expectedResponses?: number
	text: string
	imageUrl?: string
	results: AnswerResult[]
	last: boolean
	onCheck: () => void
	onNext: () => void
	onConclude: () => void
	onAbort: () => void
}

const FlexColumn = styled.div`
	align-items: stretch;
	display: flex;
	flex-flow: column nowrap;
	height: 100%;
	width: 100%;
	padding: 0 5px;
	position: relative;
`

const QuestionText = styled.div`
	font-family: Ubuntu;
	font-weight: 500;
	font-size: 18px;
	color: ${colors.black};
	padding: 6px 0 6px 0;

	${ui.responsive.desktop1440} {
		font-size: 20px;
		padding: 10px 0 10px 0;
	}

	${ui.responsive.desktop1680} {
		padding: 10px 0 30px 0;
	}
`

const twoColumn = css`
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 10px;
`

const ActionContainer = styled.div`
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex: 0 0 50px;
	padding-top: 10px;
`

type NestedFlexColumnProps = {
	grow: number
}

const NestedFlexColumn = styled.div`
	display: flex;
	flex-flow: column nowrap;
	flex-grow: ${(props: NestedFlexColumnProps) => props.grow};
`

const NestedFlexRow = styled.div`
	display: flex;
	flex-flow: row nowrap;
`

const QuizContent = styled.div`
	margin-bottom: 62px;
	overflow: auto;

	&::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 7px;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: rgba(0, 0, 0, 0.5);
		box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
		-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
	}
`

type State = 'in-progress' | 'completed'

const LeadableQuizQuestion: FC<Props> = ({
	text,
	imageUrl,
	responses,
	expectedResponses,
	last,
	results,
	onCheck,
	onNext,
	onConclude,
	onAbort,
}) => {
	const state = useMemo((): State => {
		return results.find(result => result.correct !== undefined)
			? 'completed'
			: 'in-progress'
	}, [results])
	const [abortConfirmation, setAbortConfirmation] = useState(false)

	return (
		<>
			{abortConfirmation && (
				<ConfirmBanner
					message="quiz.abort_confirmation"
					onAccept={onAbort}
					onDecline={() => setAbortConfirmation(false)}
				/>
			)}
			<ApproachingContainer>
				<FlexColumn>
					<QuizContent>
						<NestedFlexRow>
							<NestedFlexColumn grow={1}>
								<QuestionText>{text}</QuestionText>
							</NestedFlexColumn>
							{responses !== undefined && expectedResponses !== undefined && (
								<NestedFlexColumn grow={0}>
									<CountingProgressBar
										currentValue={responses}
										maxValue={expectedResponses}
									>
										{translate('quiz.answers')}
									</CountingProgressBar>
								</NestedFlexColumn>
							)}
						</NestedFlexRow>
						<GrowthColumnContainer>
							{imageUrl && <QuestionImage imageUrl={imageUrl} />}
							<div css={imageUrl ? twoColumn : undefined}>
								{results.map(answer => (
									<AnswerStatsButton
										key={answer.text}
										text={answer.text}
										correct={answer.correct}
										playersWhoSelectedThisAnswer={
											answer.playersWhoSelectedThisAnswer
										}
										percentOfAllAnswers={answer.percentOfAllAnswers}
									/>
								))}
							</div>
						</GrowthColumnContainer>
					</QuizContent>
					<ActionContainer>
						<ui.ButtonMinor onClick={() => setAbortConfirmation(true)}>
							{translate('quiz.abort')}
						</ui.ButtonMinor>
						{state === 'completed' && !last ? (
							<ui.ButtonPrimary onClick={onNext}>
								{translate('quiz.next')}
							</ui.ButtonPrimary>
						) : state === 'completed' && last ? (
							<ui.ButtonPrimary onClick={onConclude}>
								{translate('quiz.conclude')}
							</ui.ButtonPrimary>
						) : (
							<ui.ButtonPrimary onClick={onCheck}>
								{translate('quiz.check')}
							</ui.ButtonPrimary>
						)}
					</ActionContainer>
				</FlexColumn>
			</ApproachingContainer>
		</>
	)
}

export default LeadableQuizQuestion
