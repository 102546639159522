import { Colors } from 'common/presentation/colors'
import styled from '@emotion/styled'
import { clippingBorderRadius } from 'common/presentation/cross-browser-css'

const UserDraggingPlaceholder = styled.div`
	${clippingBorderRadius('5px')};
	border: solid 3px ${Colors.greenishCyan};
	width: 100%;
	height: 100%;
`

export default UserDraggingPlaceholder
