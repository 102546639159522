import { colors, intl } from '@beelday/common'
import { useAuthenticatedUser } from '@beelday/common/src/security'
import styled from '@emotion/styled'
import { VideoStreamAvatarOverlay } from 'common/presentation/video-stream-avatar-overlay'
import { FC } from 'react'
import useFetchMissingProfiles from 'users/use-fetch-missing-profiles'

const Preview = styled.div`
	background: ${colors.darkIndigo};
	border-radius: 5px;
	overflow: hidden;
	margin-bottom: 10px;
`

const PreviewText = styled.div`
	font-size: 14px;
	line-height: 20px;
	color: ${colors.white};
	padding: 5px 10px;
`

const VideoWrapper = styled.div`
	position: relative;
	overflow: hidden;

	&:before {
		content: '';
		display: block;
		width: 100%;
		padding-top: 59.57%;
	}

	video {
		position: absolute;
		left: 0;
		top: 0;
		transform: rotateY(180deg);
		width: 100%;
		height: 100%;
		max-height: 100%;
		max-width: 100%;
		object-fit: cover;
	}
`

const AvatarWrapper = styled.div`
	height: 100%;
	width: 100%;
	min-height: 300px;
	position: relative;
`

const PreviewButtons = styled.div`
	position: absolute;
	bottom: 10px;
	left: 50%;
	transform: translateX(-50%);
`

type Props = {
	children?: JSX.Element
	stream?: MediaStream
}

const VideoPreview: FC<Props> = ({ children, stream }) => {
	const user = useAuthenticatedUser()
	useFetchMissingProfiles([user.id])

	return (
		<Preview>
			{stream ? (
				<VideoWrapper>
					<video
						key={stream.id || 'default'}
						className="video"
						ref={v => {
							if (v) {
								v.srcObject = stream
							}
						}}
						autoPlay
					/>
				</VideoWrapper>
			) : (
				<AvatarWrapper>
					<VideoStreamAvatarOverlay userId={user.id} />
				</AvatarWrapper>
			)}

			<PreviewButtons>{children}</PreviewButtons>
			<PreviewText>
				<intl.Translate>hardware_check.private_preview</intl.Translate>
			</PreviewText>
		</Preview>
	)
}

export default VideoPreview
