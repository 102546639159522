import { useSelector } from 'react-redux'
import {
	MenuToggleItem,
	PopupMenu,
	PopupMenuElement,
	PopupNestedMenu,
} from 'room/common/presentation/menu'
import { isScreenSharingActive, screenShareUserId } from 'room/common/redux'
import ScreenShareIcon from './screen-share-icon'
import { colors, intl, MaybeUserProfile, UserId } from '@beelday/common'
import useScreenShare from './use-screen-share'
import { RootState } from '../../common/redux'
import { useProfilesFor } from '../../users/redux'
import { useAssertedWorkflowUser } from '../../room/common/use-workflow-user'
import { getDisplayName } from '@beelday/common/lib/user-api'
import { useCallback, useEffect, useRef, useState } from 'react'
import ScreenShareTrainerPopup from './screen-share-trainer-popup'

type Props = {
	canStart: boolean
	hidden?: boolean
}

const ScreenShareTrainerMenuItem = ({
	canStart,
	hidden,
}: Props): JSX.Element | null => {
	const translate = intl.useTranslate()
	const [popupProfile, setPopupProfile] = useState<MaybeUserProfile>()
	const { start, stop, disablePermission, acceptPermission } = useScreenShare()
	const active = useSelector(isScreenSharingActive)
	const currentScreenShareUserId = useSelector(screenShareUserId)

	const usersRequestedToShare = useRef<MaybeUserProfile[]>([])

	const currentUser = useAssertedWorkflowUser()

	const allowedUsers = useSelector(
		(state: RootState) => state.screenShare.allowedUsers
	)
	const requestedByUsers = useSelector(
		(state: RootState) => state.screenShare.requestedByUsers
	)

	const userIds = [...allowedUsers, ...requestedByUsers]

	const profiles = useProfilesFor(
		userIds.filter(user => currentUser.id !== user) || []
	)

	useEffect(() => {
		if (usersRequestedToShare.current.length < profiles.length) {
			setPopupProfile(profiles[profiles.length - 1])
		}
		usersRequestedToShare.current = profiles
	}, [profiles])

	const onDisable = useCallback(
		(userId: UserId) => {
			disablePermission(userId)
		},
		[disablePermission]
	)

	const onStart = useCallback(() => {
		if (
			currentScreenShareUserId &&
			currentScreenShareUserId !== currentUser.id
		) {
			disablePermission(currentScreenShareUserId)
		}

		start()
	}, [currentScreenShareUserId, currentUser, disablePermission, start])

	const onClick = () => {
		if (active && currentScreenShareUserId === currentUser.id) {
			stop()
		} else if (
			currentScreenShareUserId &&
			currentScreenShareUserId !== currentUser.id
		) {
			onStart()
		} else {
			start()
		}
	}

	if (hidden) return null

	return (
		<>
			<MenuToggleItem
				active={active}
				disabled={!canStart && !active}
				icon={<ScreenShareIcon />}
				onClick={onClick}
				label={<intl.Translate>room_menu.screen_share</intl.Translate>}
				number={requestedByUsers.length}
			>
				<PopupMenu>
					<>
						<PopupMenuElement
							text={translate('room_menu.screen_share.allow', {
								count: profiles.length,
							})}
						>
							{profiles.length ? (
								<PopupNestedMenu>
									<>
										{profiles.map(profile =>
											profile ? (
												<PopupMenuElement
													key={profile.id}
													text={getDisplayName(profile)}
													onClick={() =>
														!!allowedUsers.find(user => user === profile.id)
															? onDisable(profile.id)
															: acceptPermission(profile.id)
													}
													active={
														!!allowedUsers.find(user => user === profile.id)
													}
												/>
											) : null
										)}
									</>
								</PopupNestedMenu>
							) : (
								<></>
							)}
						</PopupMenuElement>
						{currentScreenShareUserId !== currentUser.id ? (
							<PopupMenuElement
								text={translate('room_menu.screen_share.start')}
								onClick={onStart}
							/>
						) : (
							<PopupMenuElement
								text={translate('room_menu.screen_share.stop')}
								onClick={() => stop()}
								style={{ color: colors.pinkRed, fontWeight: 'bolder' }}
							/>
						)}

						{currentScreenShareUserId &&
						currentScreenShareUserId !== currentUser.id ? (
							<PopupMenuElement
								text={'Cancel share permission'}
								style={{ color: colors.pinkRed, fontWeight: 'bolder' }}
								onClick={() => disablePermission(currentScreenShareUserId)}
							/>
						) : null}
					</>
				</PopupMenu>
			</MenuToggleItem>
			{popupProfile &&
			popupProfile.id &&
			requestedByUsers.includes(popupProfile.id) ? (
				<ScreenShareTrainerPopup
					onAccept={() => {
						acceptPermission(popupProfile.id)
						setPopupProfile(undefined)
					}}
					onClose={() => setPopupProfile(undefined)}
					profile={popupProfile}
				/>
			) : null}
		</>
	)
}

export default ScreenShareTrainerMenuItem
