import colors from '../colors'
import styles from '../styles'
import CloseButton from './close'
import {
	CloseBtnContainer,
	DialogBody,
	MobileSeparator,
	Separator,
	Title,
} from './confirm'
import { DetailsPanel } from './details-panel'
import { Modal } from './modal'
import responsive from './responsive'
import UploadImage from './upload-image'

type Props = {
	withCrop: boolean
	title: JSX.Element
	round?: boolean
	onCancel: () => unknown
	onDone: (file: Blob) => unknown
	width?: number
	height?: number
	aspectRatio?: {
		width: number
		height: number
	}
}

type UploadDialogProps = {
	cancel: () => void
	title: JSX.Element | string
}

export const UploadDialog: React.FC<UploadDialogProps> = ({
	cancel,
	title,
	children,
}) => {
	return (
		<Modal onClose={cancel} backgroundColor={colors.transparentBlackish}>
			<DialogBody>
				<MobileSeparator />
				<CloseBtnContainer>
					<CloseButton onClick={cancel} />
				</CloseBtnContainer>
				<Title>{title}</Title>
				<Separator />
				{children}
			</DialogBody>
		</Modal>
	)
}

export const ImagePicker: React.FC<Props> = ({
	withCrop,
	title,
	round,
	onDone,
	onCancel,
	aspectRatio,
	width = 600,
	height = 500,
}) => {
	const isMobile = responsive.useIsMobile()
	return isMobile ? (
		<UploadDialog cancel={onCancel} title="Upload Image">
			<UploadImage
				withCrop={withCrop}
				round={round}
				onDone={onDone}
				css={styles.fillSpace}
			/>
		</UploadDialog>
	) : (
		<Modal backgroundColor={colors.black15} onClose={onCancel}>
			<DetailsPanel
				onClose={onCancel}
				title={title}
				style={{
					display: 'flex',
					width: width + 'px',
					height: height + 'px',
				}}
			>
				<UploadImage
					round={round}
					withCrop={withCrop}
					aspectRatio={aspectRatio}
					onDone={onDone}
					css={styles.fillSpace}
				/>
			</DetailsPanel>
		</Modal>
	)
}

export default ImagePicker
