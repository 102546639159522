export const Training: React.FC = () => (
	<svg
		id="Group_25563"
		data-name="Group 25563"
		xmlns="http://www.w3.org/2000/svg"
		width="19.311"
		height="20"
		viewBox="0 0 19.311 20"
	>
		<clipPath id="clip-path">
			<rect
				id="Rectangle_4041"
				data-name="Rectangle 4041"
				width="19.311"
				height="20"
				fill="none"
			/>
		</clipPath>

		<path
			id="Path_12069"
			data-name="Path 12069"
			d="M10.655,16.093,12.11,19a.69.69,0,0,1-1.234.616L9.654,17.174,8.431,19.618A.69.69,0,0,1,7.2,19l1.455-2.909H4.136A3.218,3.218,0,0,1,.918,12.875v-6.9H18.39v6.9a3.218,3.218,0,0,1-3.218,3.218ZM7.815,9.655h3.679a.46.46,0,0,0,0-.92H7.815a.46.46,0,0,0,0,.92M5.976,12.414h7.356a.46.46,0,1,0,0-.92H5.976a.46.46,0,1,0,0,.92M1.379,0H17.931A1.379,1.379,0,0,1,19.31,1.379V3.218a.458.458,0,0,1-.459.459H.459A.458.458,0,0,1,0,3.218V1.379A1.379,1.379,0,0,1,1.379,0Z"
			fill="currentColor"
		/>
	</svg>
)
