import { colors, styles, ui, UserId, UserProfile } from '@beelday/common'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'app-redux'
import { RootState } from 'common/redux'
import {
	EtherPadState,
	ExcalidrawState,
	FocusedFeatureTypes,
	Group,
	GroupId,
	User,
} from 'common/types'
import { countBy, isEmpty, keyBy, reduce, values } from 'lodash'
import React, { FC, useEffect, useMemo, useRef } from 'react'
import { colorToHex } from 'room/common/presentation/colorsInHex'
import { useWorkflowUser } from 'room/common/use-workflow-user'
import { QuizState, selectActiveQuiz } from 'room/training-room/redux'
import { useProfileFor, useProfilesFor } from 'users/redux'
import useFetchMissingProfiles from 'users/use-fetch-missing-profiles'
import { BeeldayMediaStream, SceneVideoUser } from 'video-conference-media'
import {
	forcePublishBitrate,
	othersStream,
	selectForceBitRate,
	selectLocalScreenShareStream,
	selectLocalStream,
	selectPublishBitRate,
} from 'video-conference-media/redux'

const Link = styled.button`
	all: unset;
	color: ${colors.indigoBlue};
	cursor: pointer;
	text-decoration: underline;
`

const Attribute = styled.div<{
	error?: unknown
	warn?: unknown
	ok?: unknown
	wrap?: boolean
}>`
	margin-bottom: 4px;
	${({ wrap }) => (wrap ? '' : styles.ellipsis)};
	&:before {
		display: inline-block;
		width: 12px;
		height: 12px;
		margin-right: 4px;
		content: ' ';
		border-radius: 50%;
		background-color: ${({ error, warn }) => {
			if (error) return colors.red
			if (warn) return colors.yellowish

			return colors.green
		}};
	}
`

const MediaMarker: FC<{ hasError?: boolean; important?: boolean }> = ({
	hasError,
	important,
	children,
}) => (
	<span
		style={{
			color: hasError ? colors.red : undefined,
			fontWeight: hasError || important ? 'bold' : 'normal',
			marginRight: '4px',
		}}
	>
		{children}
	</span>
)

const VideoStream: FC<{ stream?: MediaStream | null; size: number }> = ({
	stream,
	size,
}) => {
	const videoRef = useRef<HTMLVideoElement>(null)
	const audio = (stream?.getAudioTracks() || []).findIndex(e => e.enabled) >= 0
	const video = (stream?.getVideoTracks() || []).findIndex(e => e.enabled) >= 0

	useEffect(() => {
		if (videoRef.current && stream) {
			videoRef.current.srcObject = stream
		}
	}, [stream])

	return (
		<ui.Relative
			style={{
				height: `${size}px`,
				width: `${(size / 9) * 16}px`,
				borderRadius: '4px',
				border: '1px solid grey',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				overflow: 'hidden',
				flexShrink: 0,
			}}
		>
			{stream ? (
				<video
					autoPlay
					ref={videoRef}
					muted={true}
					style={{
						width: '100%',
						height: '100%',
						objectFit: 'cover',
					}}
				/>
			) : (
				<div>No stream!</div>
			)}
			<div
				style={{
					position: 'absolute',
					fontWeight: 'bold',
					left: '10%',
					top: '10%',
					backgroundColor: 'rgba(0, 0, 0, 0.5)',
					padding: '4px',
					fontSize: `${Math.round(size / 7)}px`,
				}}
			>
				<span style={{ color: video ? colors.white : colors.red }}>
					V({stream?.getVideoTracks().length || 0})
				</span>
				&nbsp;
				<span style={{ color: audio ? colors.white : colors.red }}>
					A({stream?.getAudioTracks().length || 0})
				</span>
			</div>
		</ui.Relative>
	)
}

const LocalUserDetails: FC<{
	user: User
	stream?: MediaStream | null
	screenStream?: MediaStream | null
	profile?: UserProfile | null
	bitrate?: number
	showStream?: boolean
	muted?: boolean
	groups?: Group[]
	forcedBitrate?: number
}> = ({
	user,
	profile,
	stream,
	screenStream,
	bitrate,
	showStream,
	groups,
	muted,
	forcedBitrate,
}) => {
	const dispatch = useDispatch()
	const setBitrate = (kb: number): void => {
		dispatch(forcePublishBitrate(kb * 1000))
	}
	const resetBitrate = (): void => {
		dispatch(forcePublishBitrate(undefined))
	}

	return (
		<ui.FlexMiddle>
			{showStream && <VideoStream stream={stream} size={100} />}
			{showStream && screenStream ? (
				<div style={{ marginLeft: '4px' }}>
					<VideoStream stream={screenStream} size={100} />
				</div>
			) : null}
			<div style={{ marginLeft: '10px', flex: 1, overflow: 'hidden' }}>
				<Attribute error={!user} title={user.id}>
					<b>{user.role}: </b>
					{user.id}
				</Attribute>
				<Attribute error={!profile} title={profile?.name}>
					<b>Name: </b>
					{profile?.name}({profile?.email})
				</Attribute>
				<Attribute error={(groups?.length || 0) > 1}>
					<b>Groups: </b>
					{groups?.map(g => (
						<span
							key={g.groupDescription.id}
							title={g.groupDescription.id}
							style={{
								marginRight: '4px',
								color: colorToHex[g.groupDescription.color],
								fontWeight: 'bold',
							}}
						>
							{g.groupDescription.name || '???'}
						</span>
					))}
				</Attribute>
				<Attribute
					error={!stream}
					warn={!stream?.active}
					title={`${stream?.id} (active: ${stream?.active})`}
				>
					<b>Stream: </b>
					{stream?.id}
				</Attribute>
				<Attribute warn={!bitrate}>
					<b style={{ color: forcedBitrate ? 'red' : undefined }}>Bitrate: </b>
					{(bitrate || 0) / 1000 + 'k'}
					&nbsp;
					<Link onClick={() => setBitrate(128)}>128k</Link>
					&nbsp;
					<Link onClick={() => setBitrate(256)}>256k</Link>
					&nbsp;
					<Link onClick={() => setBitrate(512)}>512k</Link>
					&nbsp;
					<Link onClick={() => setBitrate(768)}>768k</Link>
					&nbsp;
					<Link onClick={() => setBitrate(1024)}>1M</Link>
					&nbsp;
					<Link onClick={() => setBitrate(2048)}>2M</Link>
					&nbsp;
					<Link onClick={() => setBitrate(3072)}>3M</Link>
					&nbsp;
					<Link
						onClick={resetBitrate}
						style={{
							color: forcedBitrate ? 'red' : undefined,
							fontWeight: forcedBitrate ? 'bold' : 'normal',
						}}
					>
						Reset
					</Link>
					&nbsp;
					<input
						type="number"
						style={{ width: '5em' }}
						placeholder={'' + (bitrate || 0) / 1000}
						onKeyDown={e => {
							if (e.key === 'Enter') {
								setBitrate(parseInt(e.currentTarget.value, 10))
							}
						}}
					/>
				</Attribute>
				<Attribute warn={muted}>
					<b>muted: </b>
					{muted ? 'ON' : 'OFF'}
				</Attribute>
			</div>
		</ui.FlexMiddle>
	)
}

const RemoteUserDetails: FC<{
	user: User
	stream?: BeeldayMediaStream | null
	profile?: UserProfile | null
	showStream?: boolean
	muted?: boolean
	isScreenSharing?: boolean
	groups?: Group[]
	requireGroup?: boolean
}> = ({
	user,
	stream,
	profile,
	showStream,
	muted,
	isScreenSharing,
	groups,
	requireGroup,
}) => {
	const [camera, screen] = useMemo(() => {
		if (stream?.screenShareTrackId) {
			return [
				new MediaStream(
					stream.getTracks().filter(t => t.id !== stream.screenShareTrackId)
				),
				new MediaStream(
					stream
						.getVideoTracks()
						.filter(t => t.id === stream.screenShareTrackId)
				),
			]
		} else {
			return [stream, null]
		}
	}, [stream])

	const noAudio = !stream?.getAudioTracks().length
	const noVideo = !stream?.getVideoTracks().length
	const noSharingStream = isScreenSharing && !screen?.getVideoTracks().length

	return (
		<div>
			<ui.FlexRow>
				{showStream && (
					<ui.FlexColumn style={{ justifyContent: 'flex-start' }}>
						<VideoStream stream={camera} size={50} />
						<div style={{ marginTop: '4px' }}>
							{screen ? <VideoStream stream={screen} size={50} /> : null}
						</div>
					</ui.FlexColumn>
				)}
				<div style={{ marginLeft: '10px', flex: 1, overflow: 'hidden' }}>
					<Attribute error={!user} title={user.id}>
						<b>{user.role}: </b>
						{user.id}
					</Attribute>
					<Attribute error={!profile} title={profile?.name}>
						<b>Name: </b>
						{profile?.name}({profile?.email})
					</Attribute>
					<Attribute
						warn={(groups?.length || 0) > 1}
						error={requireGroup && !groups?.length}
					>
						<b>Groups: </b>
						{groups?.map(g => (
							<span
								key={g.groupDescription.id}
								title={g.groupDescription.id}
								style={{
									marginRight: '4px',
									color: colorToHex[g.groupDescription.color],
									fontWeight: 'bold',
								}}
							>
								{g.groupDescription.name || '???'}
							</span>
						))}
					</Attribute>
					<Attribute
						error={!stream}
						warn={!stream?.active}
						title={`${stream?.id} (active: ${stream?.active})`}
					>
						<b>Stream: </b>
						{stream?.id}
					</Attribute>
					<Attribute error={noAudio || noVideo || noSharingStream}>
						<MediaMarker hasError={noVideo}>
							V: {stream?.getVideoTracks().length || 0}
						</MediaMarker>
						<MediaMarker hasError={noAudio}>
							A: {stream?.getAudioTracks().length || 0}
						</MediaMarker>
						<MediaMarker hasError={noSharingStream} important={isScreenSharing}>
							S: {isScreenSharing ? 'ON' : 'OFF'}
						</MediaMarker>
						<MediaMarker important={muted}>
							M: {muted ? 'ON' : 'OFF'}
						</MediaMarker>
					</Attribute>
				</div>
			</ui.FlexRow>
		</div>
	)
}

const CurrentUser = styled.div`
	margin: 10px 0;
`

const OtherUsers = styled.div`
	margin: 10px 0;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px;
	& > * {
		min-width: 0px;
	}
`

const UnknownStreams: FC<{ streams: SceneVideoUser[] }> = ({ streams }) => {
	if (streams.length) {
		return (
			<div>
				<h3>Unknown Streams:</h3>
				<div>
					{streams.map(s => (
						<div key={s.userId + s.stream?.id}>
							<ui.FlexRow>
								<VideoStream stream={s.stream} size={100} />
								<div>
									{s.userName} ({s.userId})
								</div>
							</ui.FlexRow>
						</div>
					))}
				</div>
			</div>
		)
	} else {
		return null
	}
}

const Groups: FC<{
	groups: Group[]
	media: Record<
		GroupId,
		{
			excalidraw?: ExcalidrawState
			etherpad?: EtherPadState
		}
	>
}> = ({ groups, media }) => {
	if (groups.length) {
		return (
			<div>
				<hr />
				<h3>Groups:</h3>
				<ui.FlexRow style={{ flexWrap: 'wrap' }}>
					{groups.map(g => {
						const { etherpad, excalidraw } = media[g.groupDescription.id] || {}
						return (
							<div
								key={g.groupDescription.id}
								title={g.groupDescription.id}
								style={{ marginRight: '20px' }}
							>
								<Attribute
									style={{
										color: colorToHex[g.groupDescription.color],
										fontWeight: 'bold',
									}}
								>
									{g.groupDescription.name || '???'}
								</Attribute>
								<Attribute error={!g.users?.length}>
									<b>Users:</b> {g.users?.length || 0}
								</Attribute>
								<Attribute>
									<b>Attention:</b> {g.attention?.type}
								</Attribute>
								<Attribute>
									Etherpad: {etherpad?.active ? 1 : 0}/
									{etherpad?.loaded ? 1 : 0}
								</Attribute>
								<Attribute>
									Excalidraw: {excalidraw?.active ? 1 : 0}/
									{excalidraw?.loaded ? 1 : 0}
								</Attribute>
							</div>
						)
					})}
				</ui.FlexRow>
			</div>
		)
	} else {
		return null
	}
}

const KnowledgeCheck: FC<{
	knowledgeCheckId?: string | null
	knowledgeCheckUsers?: string[]
	roomUsers?: User[]
}> = ({ knowledgeCheckId, knowledgeCheckUsers, roomUsers }) => {
	if (knowledgeCheckId) {
		const missingUsers = roomUsers?.filter(
			u =>
				knowledgeCheckUsers &&
				!knowledgeCheckUsers.includes(u.id) &&
				u.role !== 'UPPER_ECHELON'
		)
		return (
			<div>
				<hr />
				<h3>Knowledge check:</h3>
				<div>
					<Attribute>
						<b>Id: </b>
						{knowledgeCheckId}
					</Attribute>
					<Attribute>
						<b>Users: </b>
						{knowledgeCheckUsers?.length || 0}/
						{roomUsers?.filter(u => u.role !== 'UPPER_ECHELON')?.length || 0}
					</Attribute>
					{missingUsers?.length ? (
						<Attribute error={true} wrap>
							<b>Missing users:</b>{' '}
							{missingUsers.map(u => u.name || u.id).join(', ')}
						</Attribute>
					) : null}
				</div>
			</div>
		)
	}
	return null
}

const Question: FC<{
	result?: { no: number; yes: number; voted: UserId[] } | null
	roomUsers?: User[]
}> = ({ result, roomUsers }) => {
	const waitingFor = roomUsers?.filter(
		u => result && !result.voted.includes(u.id) && u.role !== 'UPPER_ECHELON'
	)
	return (
		<div>
			<hr />
			<h3>Yes/No</h3>
			<Attribute>
				<b>Stats: </b> Yes: {result?.yes || 0}, No: {result?.no || 0}, Voted:{' '}
				{result?.voted.length || 0}
			</Attribute>
			<Attribute warn={waitingFor?.length} wrap>
				<b>Waiting for: </b>
				{waitingFor?.map(u => u.name || u.id).join(', ')}
			</Attribute>
		</div>
	)
}

const Quiz = React.memo<{
	quiz?: QuizState
	roomUsers?: User[]
}>(({ quiz, roomUsers }) => {
	if (!quiz) return null
	let waitingFor: User[] = []
	let duplicateAnswers: string[] = []
	let total = 0
	let question = 0
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	if (quiz.phase === 'question-answered' && roomUsers) {
		const answered = reduce(
			quiz.state.selectedAnswers,
			(acc, answer) => {
				answer.forEach(a => acc.push(a.id))
				return acc
			},
			[] as UserId[]
		)
		waitingFor = roomUsers?.filter(
			u => !answered.includes(u.id) && u.role !== 'UPPER_ECHELON'
		)
		duplicateAnswers = reduce(
			countBy(answered),
			(acc, count, id) => {
				if (count > 1) {
					const user = roomUsers.find(u => u.id === id)
					acc.push(user?.name || id)
				}

				return acc
			},
			[] as string[]
		)
	}

	if (
		quiz.phase === 'question-asked' ||
		quiz.phase === 'question-answered' ||
		quiz.phase === 'question-completed'
	) {
		total = quiz.state.totalQuestions
		question = quiz.state.number
	}

	return (
		<div>
			<hr />
			<h3>Quiz</h3>
			<Attribute>
				<b>Question: </b>
				{question}/{total}
			</Attribute>
			<Attribute warn={waitingFor?.length} wrap>
				<b>Waiting for: </b>
				{waitingFor?.map(u => u.name || u.id).join(', ')}
			</Attribute>
			<Attribute error={duplicateAnswers?.length} wrap>
				<b>Duplicate answers: </b>
				{duplicateAnswers?.join(', ')}
			</Attribute>
		</div>
	)
})
Quiz.displayName = 'Quiz'

export const DevToolsFrontend: FC<{ showStreams: boolean }> = ({
	showStreams,
}) => {
	const workflowUser = useWorkflowUser()
	const workflowUserProfile = useProfileFor(workflowUser?.id)

	const roomUsers = useSelector((state: RootState) => state.room.users)
	const roomData = useSelector((state: RootState) => state.room)
	const trainingRoomData = useSelector((state: RootState) => state.trainingRoom)
	const isData = useSelector((state: RootState) => state.interactionScheme)

	const userIds = roomUsers?.map(u => u.id) || []
	useFetchMissingProfiles(userIds)
	const profiles = useProfilesFor(userIds)

	const remoteStreams = useSelector(othersStream)
	const localMediaStream = useSelector(selectLocalStream)
	const screenShareStream = useSelector(selectLocalScreenShareStream)
	const bitrate = useSelector(selectPublishBitRate)
	const forceBitrate = useSelector(selectForceBitRate)
	const groupRoom = useSelector((state: RootState) => state.groupRoom)
	const profileById = keyBy(profiles, 'id')
	const streamsById = keyBy(remoteStreams, 'userId')
	const unknownStreams = useMemo(() => {
		if (remoteStreams && roomUsers) {
			return remoteStreams.filter(
				s => roomUsers.findIndex(u => u.id === s.userId) < 0
			)
		}

		return []
	}, [roomUsers, remoteStreams])
	const knowledgeCheck = useSelector(
		(state: RootState) => state.knowledgeChecks
	)
	const question = useSelector((state: RootState) => state.questions)
	const quiz = useSelector(selectActiveQuiz)

	const groupsByUser = useMemo(() => {
		return reduce(
			groupRoom.groups,
			(acc, v) => {
				v.users.forEach(u => {
					const current = acc[u.id]
					if (current) {
						current.groups.push(v)
					} else {
						acc[u.id] = { groups: [v] }
					}
				})
				return acc
			},
			{} as Record<string, { groups: Group[] }>
		)
	}, [groupRoom.groups])

	return (
		<div>
			<CurrentUser>
				{workflowUser ? (
					<LocalUserDetails
						user={workflowUser}
						stream={localMediaStream}
						screenStream={screenShareStream}
						profile={workflowUserProfile}
						bitrate={forceBitrate || bitrate}
						forcedBitrate={forceBitrate}
						showStream={showStreams}
						muted={isData?.usersState[workflowUser.id]?.audioMuted}
						groups={groupsByUser[workflowUser.id]?.groups}
					/>
				) : null}
			</CurrentUser>
			<hr />
			<div>
				Room: {isData?.joinedRoom?.roomId} ({isData?.joinedRoom?.roomType})
			</div>
			<div>Phase: {roomData?.trainingRoomPhase}</div>
			<div>
				Countdown: {isData?.userTransferWithCountdown?.countdown ?? 'OFF'}
			</div>
			<div title={roomData?.screenShare?.user}>
				ScreenShare:{' '}
				{trainingRoomData?.focusedFeatures[0]?.type ===
				FocusedFeatureTypes.ScreenShare
					? `ON (${
							profileById[roomData.screenShare.user || 'NONE']?.name ||
							profileById[roomData.screenShare.user || 'NONE']?.email ||
							roomData.screenShare.user
					  })`
					: 'OFF'}
			</div>
			<UnknownStreams streams={unknownStreams} />
			<hr />
			<OtherUsers>
				{roomUsers
					?.filter(u => u.id !== workflowUser?.id)
					.map(u => (
						<RemoteUserDetails
							key={u.id}
							user={u}
							stream={streamsById[u.id]?.stream}
							profile={profileById[u.id]}
							showStream={showStreams}
							muted={isData?.usersState[u.id]?.audioMuted}
							isScreenSharing={roomData?.screenShare?.user === u.id}
							requireGroup={
								!isEmpty(groupRoom.groups) && u.role !== 'UPPER_ECHELON'
							}
							groups={groupsByUser[u.id]?.groups}
						/>
					))}
			</OtherUsers>
			<KnowledgeCheck
				knowledgeCheckId={knowledgeCheck?.knowledgeCheckID}
				knowledgeCheckUsers={
					knowledgeCheck && [
						...knowledgeCheck.stats.started,
						...knowledgeCheck.stats.notStarted,
						...knowledgeCheck.stats.finished,
					]
				}
				roomUsers={roomUsers}
			/>
			<Groups
				groups={values(groupRoom.groups)}
				media={{
					excalidraw: groupRoom.excalidraw,
					etherpad: groupRoom.etherPadsStates,
				}}
			/>
			{question?.active ? (
				<Question result={question.result} roomUsers={roomUsers} />
			) : null}
			<Quiz quiz={quiz} />
		</div>
	)
}

export default DevToolsFrontend
