import { FC, ReactElement, useContext, useEffect, useState } from 'react'
import { getQuery } from './http'
import logger from './logger'
import React from 'react'

const log = logger.create('FEATURE_TOGGLES')

export type Features = Record<string, boolean>

let featuresCached: Features

if ('production' !== process.env.NODE_ENV) {
	try {
		log.warn('DEV mode - all features are enabled')
		featuresCached = new Proxy({}, { get: () => true })
	} catch (e) {
		log.error('Failed to enable all features in DEV mode', e)
	}
}
const fetchFeatures = (): Promise<Features> => {
	if (featuresCached) {
		return Promise.resolve(featuresCached)
	}

	try {
		const featuresId = getQuery('features') || 'production'
		return fetch(`/features/${featuresId}.json`, { cache: 'no-store' })
			.then(res => res.json())
			.then(features => {
				featuresCached = features
				return features
			})
			.catch(() => ({}))
	} catch (e) {
		return Promise.resolve({})
	}
}

const featuresContext = React.createContext<Features | undefined>({})

export const FeaturesLoader: FC = ({ children }) => {
	const [features, setFeatures] = useState<Features>()

	useEffect(() => {
		fetchFeatures().then(setFeatures)
	}, [])

	return (
		<featuresContext.Provider value={features}>
			{features && children}
		</featuresContext.Provider>
	)
}

type Props = {
	name: string
	children: () => ReactElement | null
}

export const FeatureToggle: FC<Props> = ({ children, name }) => {
	const features = useContext(featuresContext)

	if (features && features[name]) {
		return children()
	} else {
		return null
	}
}

export const useFeatureEnabled = (name: string): boolean => {
	const features = useContext(featuresContext)

	return !!features && features[name]
}
