import styled from '@emotion/styled'
import { Colors } from 'common/presentation/colors'
import { Avatar, UserKey } from 'common/types'
import Translate, { translate } from 'intl/translate'
import { first } from 'lodash'
import { FC, useState } from 'react'
import ProfileAvatar from 'users/profile-avatar'

type FaceProps = { type: 'SMALL' | 'BIG' }
const Face = styled(ProfileAvatar)<FaceProps>`
	height: ${({ type }) => (type === 'SMALL' ? '35px' : '60px')};
	width: ${({ type }) => (type === 'SMALL' ? '35px' : '60px')};
`

const Name = styled.div`
	color: ${Colors.darkViolet};
	font-size: 12px;
	font-family: Ubuntu;
	font-weight: 500;
	margin-top: 10px;
	max-height: 40%;
	max-width: 90%;
	overflow: hidden;
	text-align: center;
	text-overflow: ellipsis;
	white-space: nowrap;

	&:hover {
		overflow: visible;
		white-space: normal;
		word-break: break-all;
		height: auto;
	}
`

const PointsContainer = styled.div`
	color: ${Colors.lightGray};
	font-size: 12px;
	font-family: Ubuntu;
	margin-top: 5px;
`
const Points = ({ score, total }: { score: number; total: number }) => (
	<PointsContainer>
		{`${score} / ${total} `}
		<Translate>quiz.points</Translate>
	</PointsContainer>
)

const Faces = styled.div`
	cursor: pointer;
	position: relative;
	display: flex;
	height: 35px;
	width: 85px;
	justify-content: space-between;
	padding-right: 35px;
`

const AbsoluteFace = styled(ProfileAvatar)`
	position: absolute;
	top: 0;
	left: 0;
	height: 35px;
	width: 35px;
`

const Relative = styled.div`
	position: relative;
`

const Players = ({ players }: { players: Players }) => {
	const [detailsVisible, setDetailsVisible] = useState(false)
	if (players.length > 1) {
		return (
			<>
				<Faces
					onMouseEnter={() => setDetailsVisible(true)}
					onMouseLeave={() => setDetailsVisible(false)}
				>
					{players.map(p => (
						<Relative key={p.id}>
							<AbsoluteFace profile={p} />
						</Relative>
					))}
				</Faces>
				{detailsVisible ? (
					<FacesDetails
						players={players}
						onMouseEnter={() => setDetailsVisible(true)}
						onMouseLeave={() => setDetailsVisible(false)}
					/>
				) : null}
				<Name>{translate('quiz.players', { total: players.length })}</Name>
			</>
		)
	} else {
		const p = first(players)

		return p ? (
			<>
				<Face profile={p} type="BIG" />
				<Name>{p.displayName || p.name}</Name>
			</>
		) : null
	}
}

const DetailsContainer = styled.div`
	background-color: ${Colors.darkBlue};
	border-radius: 5px;
	max-height: 250px;
	position: absolute;
	padding: 15px;
	top: 50px;
	overflow-y: auto;
	z-index: 100;
`

const FlexCenter = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 5px;
`

const FaceName = styled.div`
	color: ${Colors.white};
	font-family: Ubuntu;
	font-size: 12px;
	font-weight: 500;
	margin-left: 10px;
	margin-right: 10px;
	white-space: nowrap;
`

const FacesDetails = ({
	players,
	onMouseEnter,
	onMouseLeave,
}: {
	players: Players
	onMouseEnter: () => void
	onMouseLeave: () => void
}) => (
	<DetailsContainer onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
		{players.map(p => (
			<FlexCenter key={p.id}>
				<Face profile={p} type="SMALL" />
				<FaceName>{p.displayName || p.name}</FaceName>
			</FlexCenter>
		))}
	</DetailsContainer>
)

type Players = (UserKey & {
	avatar?: Avatar
	name?: string
	displayName?: string
})[]

type PodiumPlace = {
	score: number
	players: Players
}

type Props = {
	place: PodiumPlace
	total: number
}

const PodiumPlace: FC<Props> = ({ place, total }) => (
	<>
		<Players players={place.players} />
		<Points score={place.score} total={total} />
	</>
)

export default PodiumPlace
