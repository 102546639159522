export const SelfViewIcon: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="22.753"
		height="15.592"
		viewBox="0 0 22.753 15.592"
	>
		<g transform="translate(-2870.601 -3553.821)">
			<line
				y2="5.657"
				transform="translate(2889.288 3561.882)"
				fill="none"
				stroke="currentColor"
				strokeMiterlimit="10"
				strokeWidth="1.5"
			/>
			<path
				d="M2892.824,3564.71l-3.535,3.536-3.536-3.536"
				fill="none"
				stroke="currentColor"
				strokeMiterlimit="10"
				strokeWidth="1.5"
			/>
			<path
				d="M2884.376,3568.663h-9.836a3.189,3.189,0,0,1-3.189-3.189v-7.714a3.189,3.189,0,0,1,3.189-3.189h11.554a3.189,3.189,0,0,1,3.189,3.189v1.415"
				fill="none"
				stroke="currentColor"
				strokeMiterlimit="10"
				strokeWidth="1.5"
			/>
		</g>
	</svg>
)
