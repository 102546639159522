import { FocusedFeatureTypes } from 'common/types'
import { useAuthenticatedBeeldayClient } from 'connectivity/beelday-hooks'
import ConfiguredKnowledgeCheckViewTrainer from 'features/knowledge-checks/configured-knowledge-check-view-trainer'
import { ConfiguredNotepad } from 'features/notepad'
import ListPolling from 'features/pollings/list-polling/list-polling'
import ConfiguredMultipleChoicePolling from 'features/pollings/multiple-choice-polling/configured-multiple-choice-polling'
import WordCloud from 'features/pollings/word-cloud/word-cloud'
import { ConfiguredScreenShare } from 'features/screen-share'
import { ConfiguredSlideEditor } from 'features/slides'
import { ConfiguredWhiteboard } from 'features/whiteboard'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { ErrorWithStreams } from 'room/common/error-with-streams'
import { useJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { SceneVideoUser } from 'video-conference-media'
import ConfiguredIceBreakerTrainer from './ice-breaker/configured-ice-breaker-trainer'
import { selectFocusedFeature } from './redux'
import { SimpleGroupingFeature } from 'features/simple-grouping'

type Props = {
	streams: SceneVideoUser[]
}
export const TrainerFocusedFeature: React.FC<Props> = ({ streams }) => {
	const focusedFeature = useSelector(selectFocusedFeature)
	const beeldayClient = useAuthenticatedBeeldayClient()
	const roomAddress = useJoinedRoomAddress()

	const closeFocusedFeature = useCallback(() => {
		//TODO: implement other features
		if (!roomAddress) return
		switch (focusedFeature.type) {
			case FocusedFeatureTypes.GROUPING:
				beeldayClient.cancelGroupSuggestion(roomAddress)
				break
		}
	}, [beeldayClient, focusedFeature, roomAddress])

	return (
		<>
			{(() => {
				switch (focusedFeature.type) {
					case FocusedFeatureTypes.ICEBREAKER:
						return <ConfiguredIceBreakerTrainer streams={streams} />
					case FocusedFeatureTypes.KNOWLEDGE_CHECK:
						return (
							<ConfiguredKnowledgeCheckViewTrainer
								streams={streams}
								knowledgeCheckId={focusedFeature.knowledgeCheckId}
							/>
						)
					case FocusedFeatureTypes.ScreenShare:
						return <ConfiguredScreenShare streams={streams} />
					case FocusedFeatureTypes.SLIDE:
						return <ConfiguredSlideEditor streams={streams} />
					case FocusedFeatureTypes.POLLING_OPEN_ENDED_WORD_CLOUD:
						return <WordCloud isTrainer={true} streams={streams} />
					case FocusedFeatureTypes.POLLING_OPEN_ENDED_LIST:
						return <ListPolling isTrainer={true} streams={streams} />
					case FocusedFeatureTypes.POLLING_MULTIPLE_CHOICE:
						return (
							<ConfiguredMultipleChoicePolling
								isTrainer={true}
								streams={streams}
							/>
						)
					case FocusedFeatureTypes.WHITEBOARD:
						return <ConfiguredWhiteboard isTrainer={true} streams={streams} />
					case FocusedFeatureTypes.NOTEPAD:
						return <ConfiguredNotepad isTrainer={true} streams={streams} />
					case FocusedFeatureTypes.SIMPLE_GROUPING:
						return <SimpleGroupingFeature streams={streams} />
					default:
						return <ErrorWithStreams retry={closeFocusedFeature} />
				}
			})()}
		</>
	)
}
