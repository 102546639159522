export const GroupingIcon: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="19.039"
		viewBox="0 0 24 19.039"
	>
		<path
			fill="currentColor"
			d="M13.287 6.727a.261.261 0 0 1-.121-.089.255.255 0 0 1-.049-.141.255.255 0 0 1 .153-.243 3.247 3.247 0 1 0-2.544 0 .254.254 0 0 1 .115.1.261.261 0 0 1 .039.145.256.256 0 0 1-.05.141.252.252 0 0 1-.121.089A4.012 4.012 0 0 0 8 10.522v3.006a.5.5 0 0 0 .5.5h.893a.244.244 0 0 1 .17.066.251.251 0 0 1 .079.164L10 18.58a.5.5 0 0 0 .5.459h3a.5.5 0 0 0 .338-.132.5.5 0 0 0 .162-.327l.36-4.321a.247.247 0 0 1 .08-.164.241.241 0 0 1 .169-.066h.891a.5.5 0 0 0 .5-.5v-3.007a4.012 4.012 0 0 0-2.71-3.8"
		/>
		<path
			fill="currentColor"
			d="M4.688 7.724a.254.254 0 0 1-.166-.224.256.256 0 0 1 .035-.143.261.261 0 0 1 .109-.1 2.748 2.748 0 1 0-2.329 0 .261.261 0 0 1 .109.1.265.265 0 0 1 .036.143.262.262 0 0 1-.049.138.251.251 0 0 1-.117.089A3.514 3.514 0 0 0 0 11.023v1.505a.5.5 0 0 0 .038.191.513.513 0 0 0 .109.163.5.5 0 0 0 .354.146h.829a.251.251 0 0 1 .248.223L2 17.093a.509.509 0 0 0 .165.317.5.5 0 0 0 .332.129h2a.5.5 0 0 0 .333-.128.5.5 0 0 0 .17-.317l.426-3.843a.252.252 0 0 1 .082-.159.248.248 0 0 1 .166-.064H6.5a.506.506 0 0 0 .192-.038.517.517 0 0 0 .162-.108.51.51 0 0 0 .108-.163.5.5 0 0 0 .038-.191v-1.506a3.508 3.508 0 0 0-2.311-3.3"
		/>
		<path
			fill="currentColor"
			d="M21.685 7.724a.257.257 0 0 1-.166-.227.257.257 0 0 1 .036-.143.261.261 0 0 1 .109-.1 2.748 2.748 0 1 0-2.329 0 .261.261 0 0 1 .109.1.265.265 0 0 1 .036.143.262.262 0 0 1-.049.138.251.251 0 0 1-.117.089A3.512 3.512 0 0 0 17 11.023v1.505a.5.5 0 0 0 .038.191.513.513 0 0 0 .109.163.5.5 0 0 0 .162.108.5.5 0 0 0 .191.038h.829a.251.251 0 0 1 .249.223L19 17.093a.509.509 0 0 0 .165.317.5.5 0 0 0 .332.129h2a.494.494 0 0 0 .333-.128.5.5 0 0 0 .17-.317l.426-3.843a.252.252 0 0 1 .082-.159.248.248 0 0 1 .166-.064h.826a.5.5 0 0 0 .191-.038.492.492 0 0 0 .271-.271.486.486 0 0 0 .038-.191v-1.506a3.509 3.509 0 0 0-2.315-3.3"
		/>
		<path
			fill="currentColor"
			d="M13.287 6.727a.261.261 0 0 1-.121-.089.255.255 0 0 1-.049-.141.255.255 0 0 1 .153-.243 3.247 3.247 0 1 0-2.544 0 .254.254 0 0 1 .115.1.261.261 0 0 1 .039.145.256.256 0 0 1-.05.141.252.252 0 0 1-.121.089A4.012 4.012 0 0 0 8 10.522v3.006a.5.5 0 0 0 .5.5h.893a.244.244 0 0 1 .17.066.251.251 0 0 1 .079.164L10 18.58a.5.5 0 0 0 .5.459h3a.5.5 0 0 0 .338-.132.5.5 0 0 0 .162-.327l.36-4.321a.247.247 0 0 1 .08-.164.241.241 0 0 1 .169-.066h.891a.5.5 0 0 0 .5-.5v-3.007a4.012 4.012 0 0 0-2.71-3.8"
		/>
		<path
			fill="currentColor"
			d="M4.688 7.724a.254.254 0 0 1-.166-.224.256.256 0 0 1 .035-.143.261.261 0 0 1 .109-.1 2.748 2.748 0 1 0-2.329 0 .261.261 0 0 1 .109.1.265.265 0 0 1 .036.143.262.262 0 0 1-.049.138.251.251 0 0 1-.117.089A3.514 3.514 0 0 0 0 11.023v1.505a.5.5 0 0 0 .038.191.513.513 0 0 0 .109.163.5.5 0 0 0 .354.146h.829a.251.251 0 0 1 .248.223L2 17.093a.509.509 0 0 0 .165.317.5.5 0 0 0 .332.129h2a.5.5 0 0 0 .333-.128.5.5 0 0 0 .17-.317l.426-3.843a.252.252 0 0 1 .082-.159.248.248 0 0 1 .166-.064H6.5a.506.506 0 0 0 .192-.038.517.517 0 0 0 .162-.108.51.51 0 0 0 .108-.163.5.5 0 0 0 .038-.191v-1.506a3.508 3.508 0 0 0-2.311-3.3"
		/>
		<path
			fill="currentColor"
			d="M21.685 7.724a.257.257 0 0 1-.166-.227.257.257 0 0 1 .036-.143.261.261 0 0 1 .109-.1 2.748 2.748 0 1 0-2.329 0 .261.261 0 0 1 .109.1.265.265 0 0 1 .036.143.262.262 0 0 1-.049.138.251.251 0 0 1-.117.089A3.512 3.512 0 0 0 17 11.023v1.505a.5.5 0 0 0 .038.191.513.513 0 0 0 .109.163.5.5 0 0 0 .162.108.5.5 0 0 0 .191.038h.829a.251.251 0 0 1 .249.223L19 17.093a.509.509 0 0 0 .165.317.5.5 0 0 0 .332.129h2a.494.494 0 0 0 .333-.128.5.5 0 0 0 .17-.317l.426-3.843a.252.252 0 0 1 .082-.159.248.248 0 0 1 .166-.064h.826a.5.5 0 0 0 .191-.038.492.492 0 0 0 .271-.271.486.486 0 0 0 .038-.191v-1.506a3.509 3.509 0 0 0-2.315-3.3"
		/>
	</svg>
)
