import { colors, intl } from '@beelday/common'
import ConfirmBanner from 'common/presentation/confirm-banner'
import { RootState } from 'common/redux'
import { GroupReview } from 'common/types'
import { useAuthenticatedBeeldayClient } from 'connectivity/beelday-hooks'
import CamMenuItem from 'features/camera/camera-menu-item'
import { MicMenuItem } from 'features/microphone'
import ScreenShareTrainerMenuItem from 'features/screen-share/screen-share-trainer-menu-item'
import useScreenShare from 'features/screen-share/use-screen-share'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Document } from 'room/common/presentation/icon/document'
import { FinishReview } from 'room/common/presentation/icon/finish-review'
import { Whiteboard } from 'room/common/presentation/icon/whiteboard'
import {
	Menu,
	MenuItemLine,
	MenuToggleItem,
	PopupMenuElement,
} from 'room/common/presentation/menu'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import MiscPanelMenu from 'room/misc/misc-panel-menu'
import {
	handleReviewEtherPadClosed,
	handleReviewEtherPadOpened,
	handleReviewExcalidrawClosed,
	handleReviewExcalidrawOpened,
} from 'room/training-room/redux'
import { useNotepads } from '../../../features/notepad'
import { useWhiteboards } from '../../../features/whiteboard'
import { NotepadMenu } from '../etherpad/etherpad-menu'
import { ExcalidrawMenu } from '../excalidraw/excalidraw-menu'

type Props = {
	group: GroupReview
	activeMedia?: 'ETHERPAD' | 'EXCALIDRAW' | null
	onComplete: () => void
}

const ReviewMenuTrainer: FC<Props> = ({ group, activeMedia, onComplete }) => {
	const translate = intl.useTranslate()
	const dispatch = useDispatch()
	const beeldayClient = useAuthenticatedBeeldayClient()
	const address = useAssertedJoinedRoomAddress()
	const [completeConfirmation, setCompleteConfirmation] = useState(false)
	const groupAddress = { ...address, groupId: group.id }
	const { closeNotepad } = useNotepads()
	const { closeWhiteboard } = useWhiteboards()
	const whiteboardDefinition = useSelector(
		(state: RootState) => state.whiteboards.whiteboardDefinition
	)

	const notepadDefinition = useSelector(
		(state: RootState) => state.notepads.notepadDefinition
	)

	const { screenShareState } = useScreenShare()

	const toggleExcalidraw = () => {
		if (activeMedia === 'EXCALIDRAW') {
			beeldayClient.closeExcalidrawForReview(groupAddress)
		} else {
			beeldayClient.openExcalidrawForReview(groupAddress)
			dispatch(handleReviewEtherPadClosed(group.id))
			dispatch(handleReviewExcalidrawOpened(group.id))
		}

		if (notepadDefinition) {
			closeNotepad(notepadDefinition.writableNotepadId)
		}

		if (whiteboardDefinition) {
			closeWhiteboard(whiteboardDefinition.whiteboardId)
		}
	}

	const startExcalidraw = () => {
		beeldayClient.openExcalidrawForReview(groupAddress)
		dispatch(handleReviewEtherPadClosed(group.id))
		dispatch(handleReviewExcalidrawOpened(group.id))

		if (whiteboardDefinition) {
			closeWhiteboard(whiteboardDefinition.whiteboardId)
		}

		if (notepadDefinition) {
			closeNotepad(notepadDefinition.writableNotepadId)
		}
	}

	const closeExcalidraw = () => {
		if (activeMedia === 'EXCALIDRAW') {
			beeldayClient.closeExcalidrawForReview(groupAddress)
		}
		if (whiteboardDefinition) {
			closeWhiteboard(whiteboardDefinition.whiteboardId)
		}
	}

	const toggleEtherpad = () => {
		if (activeMedia === 'ETHERPAD') {
			beeldayClient.closeEtherPadForReview(address)
		} else {
			beeldayClient.openEtherPadForReview(address)
			dispatch(handleReviewExcalidrawClosed(group.id))
			dispatch(handleReviewEtherPadOpened(group.id))
		}

		if (whiteboardDefinition) {
			closeWhiteboard(whiteboardDefinition.whiteboardId)
		}

		if (notepadDefinition) {
			closeNotepad(notepadDefinition.writableNotepadId)
		}
	}

	const startEtherpad = () => {
		beeldayClient.openEtherPadForReview(address)
		dispatch(handleReviewExcalidrawClosed(group.id))
		dispatch(handleReviewEtherPadOpened(group.id))

		if (whiteboardDefinition) {
			closeWhiteboard(whiteboardDefinition.whiteboardId)
		}
		if (notepadDefinition) {
			closeNotepad(notepadDefinition.writableNotepadId)
		}
	}

	const closeEtherpad = () => {
		if (activeMedia === 'ETHERPAD') {
			beeldayClient.closeEtherPadForReview(address)
		}

		if (notepadDefinition) {
			closeNotepad(notepadDefinition.writableNotepadId)
		}
	}

	return (
		<Menu>
			{completeConfirmation && (
				<ConfirmBanner
					message="review.stop_confirmation"
					onAccept={onComplete}
					onDecline={() => setCompleteConfirmation(false)}
					accept={'yes'}
					decline={'no'}
				/>
			)}
			<MenuToggleItem
				icon={<Whiteboard />}
				active={activeMedia === 'EXCALIDRAW' || !!whiteboardDefinition}
				disabled={!!screenShareState.user}
				onClick={
					activeMedia === 'EXCALIDRAW' || !!whiteboardDefinition
						? closeExcalidraw
						: toggleExcalidraw
				}
				label={<intl.Translate>room_menu.whiteboard</intl.Translate>}
			>
				<ExcalidrawMenu
					withFocus={false}
					activeWhiteboardId={whiteboardDefinition?.whiteboardId}
					onClose={closeExcalidraw}
					onOpen={closeEtherpad}
					child={
						<>
							{activeMedia === 'EXCALIDRAW' && whiteboardDefinition ? (
								<PopupMenuElement
									style={{ color: colors.pinkRed, fontWeight: 'bolder' }}
									text={'Back to group whiteboard'}
									onClick={startExcalidraw}
								/>
							) : activeMedia === 'EXCALIDRAW' ? (
								<PopupMenuElement
									style={{ color: colors.pinkRed, fontWeight: 'bolder' }}
									text={translate('features.whiteboard.menu.off')}
									onClick={closeExcalidraw}
								/>
							) : undefined}
							{activeMedia !== 'EXCALIDRAW' ? (
								<PopupMenuElement
									text={'Open group whiteboard'}
									onClick={startExcalidraw}
								/>
							) : undefined}
						</>
					}
				/>
			</MenuToggleItem>
			<MenuToggleItem
				icon={<Document />}
				active={activeMedia === 'ETHERPAD' || !!notepadDefinition}
				disabled={!!screenShareState.user}
				onClick={
					activeMedia === 'ETHERPAD' || !!notepadDefinition
						? closeEtherpad
						: toggleEtherpad
				}
				label={<intl.Translate>room_menu.notepad</intl.Translate>}
			>
				<NotepadMenu
					withFocus={false}
					onClose={closeEtherpad}
					onOpen={closeExcalidraw}
					activeNotepadId={notepadDefinition?.readOnlyNotepadId}
					child={
						<>
							{activeMedia === 'ETHERPAD' && notepadDefinition ? (
								<PopupMenuElement
									style={{ color: colors.pinkRed, fontWeight: 'bolder' }}
									text={'Back to group whiteboard'}
									onClick={startEtherpad}
								/>
							) : activeMedia === 'ETHERPAD' ? (
								<PopupMenuElement
									style={{ color: colors.pinkRed, fontWeight: 'bolder' }}
									text={translate('features.notepad.menu.off')}
									onClick={closeEtherpad}
								/>
							) : undefined}
							{activeMedia !== 'ETHERPAD' ? (
								<PopupMenuElement
									text={'Open group notepad'}
									onClick={startEtherpad}
								/>
							) : undefined}
						</>
					}
				/>
			</MenuToggleItem>
			<ScreenShareTrainerMenuItem canStart={!!!screenShareState.user} />
			<MicMenuItem />
			<CamMenuItem />
			<MenuToggleItem
				icon={<FinishReview />}
				active={completeConfirmation}
				onClick={() => setCompleteConfirmation(!completeConfirmation)}
				label={<intl.Translate>room_menu.group_end</intl.Translate>}
			/>

			<MenuItemLine />

			<MiscPanelMenu />
		</Menu>
	)
}

export default ReviewMenuTrainer
