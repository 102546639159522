import { FC } from 'react'
import { css } from '@emotion/react'
import { RowPosition } from 'room/training-room/set-groups/types'

import GroupTopBg1 from './images/group-top-bg-1.svgx'
import GroupTopBg2 from './images/group-top-bg-2.svgx'
import GroupTopBg3 from './images/group-top-bg-3.svgx'
import GroupTopBg4 from './images/group-top-bg-4.svgx'
import GroupBottomBg1 from './images/group-bottom-bg-1.svgx'
import GroupBottomBg2 from './images/group-bottom-bg-2.svgx'
import GroupBottomBg3 from './images/group-bottom-bg-3.svgx'
import GroupBottomBg4 from './images/group-bottom-bg-4.svgx'
import GroupBackgroundImg1col1 from './images/group-bg-1-1.svgx'
import GroupBackgroundImg1col2 from './images/group-bg-1-2.svgx'
import GroupBackgroundImg1col3 from './images/group-bg-1-3.svgx'
import GroupBackgroundImg1col4 from './images/group-bg-1-4.svgx'
import GroupBackgroundImg1col5 from './images/group-bg-1-5.svgx'
import GroupBackgroundImg2col1 from './images/group-bg-2-1.svgx'
import GroupBackgroundImg2col2 from './images/group-bg-2-2.svgx'
import GroupBackgroundImg2col3 from './images/group-bg-2-3.svgx'
import GroupBackgroundImg2col4 from './images/group-bg-2-4.svgx'
import GroupBackgroundImg2col5 from './images/group-bg-2-5.svgx'
import GroupBackgroundImg3col1 from './images/group-bg-3-1.svgx'
import GroupBackgroundImg3col2 from './images/group-bg-3-2.svgx'
import GroupBackgroundImg3col3 from './images/group-bg-3-3.svgx'
import GroupBackgroundImg3col4 from './images/group-bg-3-4.svgx'
import GroupBackgroundImg3col5 from './images/group-bg-3-5.svgx'

type Props = {
	index: number
	row: RowPosition
	colorHex: string
}

const mapper = {
	[RowPosition.TOP]: [
		<GroupTopBg1 key="GroupTopBg1" />,
		<GroupTopBg2 key="GroupTopBg2" />,
		<GroupTopBg3 key="GroupTopBg3" />,
		<GroupTopBg4 key="GroupTopBg4" />,
	],
	[RowPosition.BOTTOM]: [
		<GroupBottomBg1 key="GroupBottomBg1" />,
		<GroupBottomBg2 key="GroupBottomBg2" />,
		<GroupBottomBg3 key="GroupBottomBg3" />,
		<GroupBottomBg4 key="GroupBottomBg4" />,
	],
	[RowPosition.NONE]: [
		<GroupBackgroundImg1col1 key="GroupBackgroundImg1col1" />,
		<GroupBackgroundImg1col2 key="GroupBackgroundImg1col2" />,
		<GroupBackgroundImg1col3 key="GroupBackgroundImg1col3" />,
		<GroupBackgroundImg1col4 key="GroupBackgroundImg1col4" />,
		<GroupBackgroundImg1col5 key="GroupBackgroundImg1col5" />,
		<GroupBackgroundImg2col1 key="GroupBackgroundImg2col1" />,
		<GroupBackgroundImg2col2 key="GroupBackgroundImg2col2" />,
		<GroupBackgroundImg2col3 key="GroupBackgroundImg2col3" />,
		<GroupBackgroundImg2col4 key="GroupBackgroundImg2col4" />,
		<GroupBackgroundImg2col5 key="GroupBackgroundImg2col5" />,
		<GroupBackgroundImg3col1 key="GroupBackgroundImg3col1" />,
		<GroupBackgroundImg3col2 key="GroupBackgroundImg3col2" />,
		<GroupBackgroundImg3col3 key="GroupBackgroundImg3col3" />,
		<GroupBackgroundImg3col4 key="GroupBackgroundImg3col4" />,
		<GroupBackgroundImg3col5 key="GroupBackgroundImg3col5" />,
	],
}

export const GroupBucketBackground: FC<Props> = ({ index, row, colorHex }) => (
	<div
		css={css`
			height: 100%;
			position: absolute;
			width: 100%;

			svg {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;

				.outline {
					fill: #fff;
					stroke-width: 2;
					stroke: ${colorHex};
					transition: fill 0.25s, stroke 0.25s;
				}
			}
		`}
	>
		{mapper[row][index]}
	</div>
)

type ForegroundProps = {
	index: number
	row: RowPosition
	colorHex: string
	onEnter: () => void
}
export const GroupBucketForeground: FC<ForegroundProps> = ({
	index,
	row,
	colorHex,
	onEnter,
}) => (
	<div
		css={css`
			height: 100%;
			position: absolute;
			width: 100%;
			z-index: 5;
			opacity: 10%;
			cursor: pointer;
			svg {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;

				.outline {
					fill: ${colorHex};
				}
			}
		`}
		onClick={onEnter}
	>
		{mapper[row][index]}
	</div>
)

export const GroupBucketBlocker: FC<Props> = ({
	index,
	row,
	colorHex,
	children,
}) => (
	<div
		css={css`
			height: 100%;
			position: absolute;
			width: 100%;
			color: white;
			top: 0;
			left: 0;

			svg {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0.8;
				z-index: 2;

				.outline {
					fill: ${colorHex};
				}
			}
		`}
	>
		<div
			css={css`
				position: absolute;
				justify-content: center;
				display: flex;
				align-items: center;
				text-align: center;
				width: 100%;
				height: 100%;
				font-family: Ubuntu;
				font-weight: 500;
				font-size: 16px;
				z-index: 3;
			`}
		>
			{children}
		</div>
		{mapper[row][index]}
	</div>
)
