import { getLocale } from './intl/actual-locale'

export type LocalTime = {
	hours: number
	minutes: number
}

const HOURS = 24
export const MINUTES = 60

export const inMinutes = (t: LocalTime): number => t.hours * MINUTES + t.minutes

export const isLess = (t1: LocalTime, t2: LocalTime): boolean =>
	inMinutes(t1) < inMinutes(t2)

export const isLessEqual = (t1: LocalTime, t2: LocalTime): boolean =>
	inMinutes(t1) <= inMinutes(t2)

export const isEqual = (t1: LocalTime, t2: LocalTime): boolean =>
	inMinutes(t1) === inMinutes(t2)

const safeInt = (num: number): number => Math.round(num)
const safeZero = (num: number): number => Math.max(num, 0)

const toHours = (positive: number): number => positive % HOURS
const toMinutes = (positive: number): number => positive % MINUTES

export const of = (hours: number, minutes: number): LocalTime => {
	const safeHours = toHours(safeZero(safeInt(hours)))
	const safeMinutes = toMinutes(safeZero(safeInt(minutes)))

	return {
		hours: safeHours,
		minutes: safeMinutes,
	}
}

export const isValid = (time: LocalTime): boolean => {
	return (
		time.hours >= 0 && time.hours < 24 && time.minutes >= 0 && time.minutes < 60
	)
}

const validate = (time: LocalTime): LocalTime => {
	if (!isValid(time)) {
		throw new Error(`Invalid local time: ${format(time)}`)
	}
	return time
}

export const parse = (str: string): LocalTime => {
	const split = str.split(':')
	const parsed = {
		hours: parseInt(split[0]),
		minutes: parseInt(split[1]),
	}
	return validate(parsed)
}

export const serialize = (time: LocalTime): string => {
	return twentyFourHoursFormat(time)
}

export const safeAddMinutes = (time: LocalTime, minutes: number): LocalTime => {
	const minutesInDay = 24 * 60
	const safeMinutes = safeInt(minutes) % minutesInDay
	const totalMinutes =
		(time.hours * MINUTES + time.minutes + safeMinutes + minutesInDay) %
		minutesInDay
	const newHour = toHours(Math.floor(totalMinutes / MINUTES))
	const newMinutes = totalMinutes % MINUTES

	return {
		hours: newHour,
		minutes: newMinutes,
	}
}

export const differenceInMinutes = (t1: LocalTime, t2: LocalTime): number => {
	const t1Minutes = t1.hours * MINUTES + t1.minutes
	const t2Minutes = t2.hours * MINUTES + t2.minutes

	return t1Minutes > t2Minutes ? t1Minutes - t2Minutes : t2Minutes - t1Minutes
}

export const twentyFourHoursFormat = (t: LocalTime): string => {
	const hours = t.hours < 10 ? `0${t.hours}` : t.hours
	const minutes = t.minutes < 10 ? `0${t.minutes}` : t.minutes

	return `${hours}:${minutes}`
}

export const to24HoursFormat = (hour: number, am: boolean): number =>
	(hour % 12) + (am ? 0 : 12)

export const isAnteMeridiem = (time: LocalTime): boolean =>
	inMinutes(time) < 12 * 60

const toLocalTimeTwelveHoursFormat = (time: LocalTime) => {
	const hourTwelveHourFormatDTo = time.hours > 12 ? time.hours - 12 : time.hours
	return {
		hours: hourTwelveHourFormatDTo || 12,
		minutes: time.minutes,
		isAnteMeridiem: isAnteMeridiem(time),
	}
}

export const twelveHoursFormat = (time: LocalTime): string => {
	const t = toLocalTimeTwelveHoursFormat(time)
	const hours = t.hours < 10 ? `0${t.hours}` : t.hours
	const minutes = t.minutes < 10 ? `0${t.minutes}` : t.minutes

	return `${hours}:${minutes} ${t.isAnteMeridiem ? 'AM' : 'PM'}`
}

export const isTwelveHoursFormat = (): boolean =>
	Intl.DateTimeFormat(getLocale(), {
		hour: 'numeric',
	}).resolvedOptions().hour12 === true

type Format = '24hour' | '12hour'
export const localizedTimeFormat = (
	t: LocalTime,
	forceFormat?: Format
): string => {
	if (forceFormat === '12hour' || isTwelveHoursFormat()) {
		return twelveHoursFormat(t)
	} else {
		return twentyFourHoursFormat(t)
	}
}
export const format = localizedTimeFormat

export const compareLocalTimes = (t1: LocalTime, t2: LocalTime): number => {
	if (t1.hours === t2.hours) {
		return t1.minutes - t2.minutes
	}
	return t1.hours - t2.hours
}

export const inBetween = (t1: LocalTime, t2: LocalTime): LocalTime => {
	const diff = differenceInMinutes(t1, t2)
	const half = Math.floor(diff / 2)
	const only5MinutesIntervals = half - (half % 5)

	return isLess(t1, t2)
		? safeAddMinutes(t1, only5MinutesIntervals)
		: safeAddMinutes(t2, only5MinutesIntervals)
}

export const utcToLocalTime = (
	utcHours: number,
	utcMinutes: number,
	offset: number
): LocalTime => {
	const utcTime = of(utcHours, utcMinutes)
	const offsetInMinutes = offset * MINUTES

	return safeAddMinutes(utcTime, offsetInMinutes)
}
