import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { AnimatePresence, motion } from 'framer-motion'
import { BackgroundCurtain } from './BackgroundCurtain'
import colors from '../colors'
import React from 'react'

const Container = styled(motion.div)`
	background-color: ${colors.white};
	bottom: 0px;
	left: 0;
	display: flex;
	flex-shrink: 0;
	padding: 0px 20px 20px 20px;
	flex: 1;
	width: 100%;
	position: fixed;
	flex-direction: column;
	border-radius: 8px 8px 0px 0px;
	box-shadow: 0px -2px 8px ${colors.heather30};

	z-index: 12;
`

const Bar = styled.div`
	border-radius: 2px;
	background-color: ${colors.borderGray50};
	display: flex;
	min-height: 4px;
	margin: 8px auto 16px auto;
	width: 54px;
`

const fadeInKeyframes = keyframes`
	0% { opacity: 0 }
	100% { opacity: 1 }
`

const Curtain = styled(BackgroundCurtain)`
	animation: ${fadeInKeyframes} 0.3s linear 0s 1 normal forwards;
`

type Props = {
	children: React.ReactChild
	height?: string | number
	isOpen: boolean
	withCurtain?: boolean
	style?: React.CSSProperties
	onClose?: () => void
}

export const BottomPanel = ({
	children,
	height,
	isOpen,
	withCurtain = true,
	style,
	onClose,
}: Props): JSX.Element => {
	return (
		<>
			{withCurtain && isOpen && <Curtain onClick={onClose} />}
			<AnimatePresence initial>
				{isOpen && (
					<Container
						style={{ height: height, ...style }}
						initial={{ translateY: '100%' }}
						animate={{ translateY: 0 }}
						exit={{ translateY: '100%' }}
						transition={{ type: 'spring', duration: 0.75 }}
						drag="y"
						dragMomentum={false}
						dragConstraints={{ top: 0, bottom: 0 }}
						dragElastic={0.8}
						onDragEnd={(event, info) => {
							if (info.offset.y > 100) {
								if (onClose) onClose()
							}
						}}
					>
						<Bar />
						<motion.div
							drag="y"
							dragConstraints={{ top: 0, bottom: 0 }}
							dragElastic={0}
							dragMomentum={false}
							style={{
								touchAction: 'pan-y',
								overflowY: 'auto',
								display: 'flex',
								flexDirection: 'column',
								flex: 1,
								WebkitFlex: 1,
								height: 'fit-content',
							}}
						>
							{children}
						</motion.div>
					</Container>
				)}
			</AnimatePresence>
		</>
	)
}
