import styled from '@emotion/styled'
import { MouseEventHandler, useCallback, useEffect, useRef } from 'react'
import { Relative } from '.'
import { hooks } from '..'
import colors from '../colors'
import { MinusIcon, PlusIcon } from '../icons'

const HANDLE_WIDTH = 21
const SLIDER_WIDTH = 3
const SliderContainer = styled.div`
	position: relative;
	padding-right: ${HANDLE_WIDTH / 2}px;
	display: flex;
	align-items: center;
`
const SliderHandle = styled.div`
	background-color: ${colors.white};
	border-radius: 50%;
	border: 1px solid ${colors.brightGray};
	width: ${HANDLE_WIDTH}px;
	height: ${HANDLE_WIDTH}px;
	top: -${HANDLE_WIDTH / 2 - SLIDER_WIDTH / 2}px;
	position: absolute;
	cursor: pointer;
	z-index: 1;
`
const SliderLine = styled.div`
	height: ${SLIDER_WIDTH}px;
	cursor: pointer;
	position: relative;
	flex: 1;

	&:before {
		content: ' ';
		position: absolute;
		background-color: ${colors.white};
		left: ${HANDLE_WIDTH / 2}px;
		right: ${HANDLE_WIDTH / 2}px;
		top: 0;
		bottom: 0;
	}
`
const SliderFill = styled.div`
	position: absolute;
	background-color: ${colors.indigoBlue};
	left: ${HANDLE_WIDTH / 2}px;
	top: 0;
	bottom: 0;
`
const SliderButton = styled.button`
	background: none;
	border: none;
	box-shadow: none;
	color: ${colors.white};
	padding: 0;
	width: 20px;
	height: 20px;
	cursor: pointer;
	transition: transform 0.1s ease-out;
	&:hover {
		transform: scale(1.5);
	}
`

const registerDragHandlers = (
	parent: React.RefObject<HTMLElement>,
	handle: HTMLDivElement | null,
	onDrag: (x: number) => unknown
): (() => void) | undefined => {
	if (handle) {
		let dragging = false
		let lastPosition = handle.getBoundingClientRect().x

		const normalizeValue = (value: number) => {
			const parentRect = parent.current?.getBoundingClientRect()

			const min = parentRect?.left || 0
			const max = parentRect?.right || 0

			return Math.max(Math.min(max, value), min)
		}
		const startDragging = () => {
			dragging = true
		}
		const handleDrag = (e: MouseEvent) => {
			if (dragging) {
				e.preventDefault()
				e.stopPropagation()
				const value = normalizeValue(e.clientX)
				if (lastPosition !== value) {
					lastPosition = value
					onDrag(value)
				}
			}
		}
		const endDragginng = (e: MouseEvent) => {
			handleDrag(e)
			dragging = false
		}

		handle.addEventListener('mousedown', startDragging)
		window.addEventListener('mousemove', handleDrag)
		window.addEventListener('mouseup', endDragginng)

		return () => {
			handle.removeEventListener('mousedown', startDragging)
			window.removeEventListener('mousemove', handleDrag)
			window.removeEventListener('mouseup', endDragginng)
		}
	}
}

const DraggableSliderHandle: React.FC<{
	x: number
	parent: React.RefObject<HTMLElement>
	onDrag: (x: number) => unknown
}> = ({ x, parent, onDrag }) => {
	const handleRef = useRef(null)

	useEffect(() => {
		const handle = handleRef.current
		return registerDragHandlers(parent, handle, onDrag)
	}, [parent, onDrag])
	return <SliderHandle style={{ left: `${x}px` }} ref={handleRef} />
}

export const Slider: React.FC<{
	onChange: (value: number) => unknown
	value?: number
	min?: number
	max?: number
	className?: string
	withButtons?: boolean
	style?: React.CSSProperties
}> = ({
	onChange,
	value = 0,
	min = 0,
	max = 1,
	className,
	withButtons,
	style,
}) => {
	const normalizedValue = Math.max(Math.min(value, max), min)
	const sliderRef = useRef<HTMLDivElement>(null)
	const forceRerender = hooks.useForceRerender()

	let left = 0

	if (sliderRef.current) {
		const sliderWidth = sliderRef.current.getBoundingClientRect().width
		const positionOffset = Math.floor(
			((normalizedValue - min) / (max - min)) * sliderWidth
		)
		left = Math.max(Math.min(positionOffset, sliderWidth) - HANDLE_WIDTH, 0)
	}

	//Make sure we have correct left value after initial mount
	useEffect(forceRerender, [forceRerender])

	const zoomToX = useCallback(
		x => {
			const slider = sliderRef.current
			if (slider) {
				const sliderRect = slider.getBoundingClientRect()
				const pos = x - sliderRect.left
				const percentage = pos / sliderRect.width
				const interpolated = min + (max - min) * percentage

				onChange(Math.max(Math.min(max, interpolated), min))
			}
		},
		[onChange, max, min]
	)

	const zoomOut = () => onChange(Math.max(value - (max - min) / 8, min))
	const zoomIn = () => onChange(Math.min(value + (max - min) / 8, max))
	const zoomToClick: MouseEventHandler = e => {
		if (e.currentTarget === sliderRef.current) {
			zoomToX(e.clientX)
		}
	}

	return (
		<Relative className={className} style={style}>
			<SliderContainer>
				{withButtons && (
					<SliderButton
						style={{ marginRight: `${HANDLE_WIDTH / 2}px` }}
						onClick={zoomOut}
					>
						<MinusIcon />
					</SliderButton>
				)}
				<SliderLine ref={sliderRef} onClick={zoomToClick}>
					<DraggableSliderHandle x={left} parent={sliderRef} onDrag={zoomToX} />
					<SliderFill style={{ width: `${left}px` }} />
				</SliderLine>
				{withButtons && (
					<SliderButton
						style={{ marginLeft: `${HANDLE_WIDTH / 2}px` }}
						onClick={zoomIn}
					>
						<PlusIcon />
					</SliderButton>
				)}
			</SliderContainer>
		</Relative>
	)
}

export default Slider
