export const calculateSize = (fileSize: number, decimals: number): string => {
	if (fileSize <= 0 || NaN || undefined || null) {
		return '0'
	}

	const units = ['B', 'KB', 'MB', 'GB']
	const k = 1024
	const dm = decimals < 0 ? 0 : decimals

	const i = Math.floor(Math.log(fileSize) / Math.log(k))

	return parseFloat((fileSize / Math.pow(k, i)).toFixed(dm)) + ' ' + units[i]
}

export const blobToBase64 = (blob: Blob): Promise<string> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.onloadend = () => {
			resolve(reader.result as string)
		}
		reader.onerror = () => reject(reader.error)
		reader.readAsDataURL(blob)
	})
}
