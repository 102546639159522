export const ThreeDotsIcon = (): JSX.Element => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="4"
		viewBox="0 0 16 4"
	>
		<g
			id="Group_25231"
			data-name="Group 25231"
			transform="translate(-940 -453)"
		>
			<g id="Group_25161" data-name="Group 25161" transform="translate(2 1)">
				<circle
					id="Ellipse_728"
					data-name="Ellipse 728"
					cx="2"
					cy="2"
					r="2"
					transform="translate(938 452)"
					fill="currentColor"
				/>
				<circle
					id="Ellipse_729"
					data-name="Ellipse 729"
					cx="2"
					cy="2"
					r="2"
					transform="translate(944 452)"
					fill="currentColor"
				/>
				<circle
					id="Ellipse_730"
					data-name="Ellipse 730"
					cx="2"
					cy="2"
					r="2"
					transform="translate(950 452)"
					fill="currentColor"
				/>
			</g>
		</g>
	</svg>
)
