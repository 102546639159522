import { FC } from 'react'
import { Config } from 'common/config'
import { Color } from 'common/types'
import styled from '@emotion/styled'
import { Colors } from 'common/presentation/colors'
import { Spinner } from 'common/presentation/spinner'
import MediaContainer from './media-container'
import { colorToHex } from 'room/common/presentation/colorsInHex'

type Props =
	| { color: Color; loaded: true; padId: string; sessionId: string }
	| { color: Color; loaded: false }

const IFrame = styled.iframe`
	width: 100%;
	height: 100%;
	border: none;
`

const EtherPadIframe: FC<Props> = props => {
	return (
		<MediaContainer colorHex={colorToHex[props.color]}>
			{props.loaded ? (
				<IFrame
					title="EtherPadIFrame"
					src={`${Config.etherPadUrl}/p/${props.padId}?sessionID=${props.sessionId}`}
				/>
			) : (
				<Spinner color={Colors.indigoBlue} />
			)}
		</MediaContainer>
	)
}

export default EtherPadIframe
