import styled from '@emotion/styled'
import MediaQuery from 'react-responsive'
import { NavLink } from 'react-router-dom'
import colors from '../colors'
import { Translate } from '../intl'
import responsive from './responsive'

const NavContainer = styled.div`
	display: flex;
	position: relative;
	width: 100%;
`

const NavButton = styled(NavLink)`
	align-items: center;
	border-bottom: solid 2px ${colors.darkGray30};
	color: ${colors.heather};
	display: flex;
	justify-content: center;
	font-size: 14px;
	font-weight: 500;
	text-decoration: none;
	padding: 20px 0px 16px 0px;
	width: 100%;

	&.active {
		color: ${colors.indigoBlue};
		border-bottom: solid 2px ${colors.indigoBlue};
	}
`

export const MobileAuthNavbar = (): JSX.Element => {
	return (
		<MediaQuery maxWidth={responsive.TABLET_WIDTH}>
			<NavContainer>
				<NavButton
					to="/"
					exact={true}
					isActive={(match, location) => {
						return location.pathname === '/' || location.pathname === '/login'
					}}
				>
					<Translate>login.signin</Translate>
				</NavButton>
				<NavButton to="/signup" activeClassName="active">
					<Translate>login.register</Translate>
				</NavButton>
			</NavContainer>
		</MediaQuery>
	)
}
