export const CameraOn: React.FC = () => (
	<svg
		data-name="Group 25547"
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="16.666"
		viewBox="0 0 24 16.666"
	>
		<path
			data-name="Path 11788"
			d="M24 1.336v14L17.995 9.6v1.016a6.069 6.069 0 0 1-6.062 6.048H6.062A6.074 6.074 0 0 1 0 10.618V6.055A6.075 6.075 0 0 1 6.062 0h5.87a6.071 6.071 0 0 1 6.062 6.055v1.016z"
		/>
	</svg>
)
