import styled from '@emotion/styled'
import { BaseUsername } from 'video-conference-media/presentation/username'
import user1url from './icons/user1.svg'
import user3url from './icons/user3.svg'
import user4url from './icons/user4.svg'
import user6url from './icons/user6.svg'

type Props = {
	score: number
	children: React.ReactNode
}

const getScoreUrl = (score: number): string => {
	if (score === 0) {
		return user1url
	}

	if (score < 3) {
		return user3url
	}

	if (score < 8) {
		return user4url
	}

	return user6url
}

const getScoreColor = (score: number): string => {
	switch (score) {
		case 10:
		case 9:
			return '#41881f'
		case 8:
			return '#5ba825'
		case 7:
			return '#74c02b'
		case 6:
			return '#95d930'
		case 5:
			return '#f8b82d'
		case 4:
			return '#eb7b22'
		case 3:
			return '#e55b1d'
		case 2:
			return '#df3f19'
		case 1:
		default:
			return '#c41613'
	}
}

const UsernameWithEmoji = styled.div`
	& > div {
		padding-left: 26px;
		position: absolute;
		left: 10px;
		bottom: 6px;
	}

	.emoji {
		position: absolute;
		bottom: 3px;
		left: 4px;
		height: 27px;
		border-radius: 100%;
		padding: 2px;
	}
`

const UsernameWithEngagement = ({ children, score }: Props): JSX.Element => {
	const baseScore = Math.round(Number(score) * 10)
	const url = getScoreUrl(baseScore)
	const scoreColor = getScoreColor(baseScore)

	return (
		<UsernameWithEmoji>
			<BaseUsername color="white" backgroundColor={scoreColor}>
				{children}
			</BaseUsername>
			<img
				src={url}
				alt={'Engagement'}
				className="emoji"
				style={{ background: scoreColor }}
			/>
		</UsernameWithEmoji>
	)
}

export default UsernameWithEngagement
