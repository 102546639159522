import { colors } from '@beelday/common'
import styled from '@emotion/styled'
import { Colors } from 'common/presentation/colors'
import Modal from 'common/presentation/modal'
import { useAuthenticatedBeeldayClient } from 'connectivity/beelday-hooks'
import Translate from 'intl/translate'
import { FC, useCallback, useState } from 'react'
import { leaveWorkflow } from 'room/common/model/room'
import { useJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { useWorkflowUser } from 'room/common/use-workflow-user'
import { exitToSessionPlanning } from 'workflow/exit-to-session-planning'
import { ExitDoorsIcon } from './images/exit-doors'

const ExitContainer = styled.div`
	width: 40px;
	height: 40px;
	min-width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	object-fit: contain;
	border-radius: 50%;
	box-shadow: 0px 0px 8px 1px ${colors.black10};
	background-color: ${colors.white};

	cursor: pointer;

	transition: transform 0.3s ease-in-out;

	:hover {
		transform: scale(1.1);
	}

	svg {
		width: 24px;
		height: 24px;
	}
`

const Exit: FC<{
	trainerId: string | undefined
	color?: string
	backgroundColor?: string
}> = ({ trainerId, color, backgroundColor }) => {
	const [showLogoutScreen, setShowLogoutScreen] = useState(false)

	return (
		<>
			<ExitContainer
				onClick={() => setShowLogoutScreen(true)}
				style={{ color: color, backgroundColor: backgroundColor }}
			>
				<ExitDoorsIcon />
			</ExitContainer>
			{showLogoutScreen && (
				<LogoutSplashScreen
					trainerId={trainerId}
					onDismiss={() => setShowLogoutScreen(false)}
				/>
			)}
		</>
	)
}

const Container = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`

const Message = styled.div`
	color: ${Colors.white};
	font-family: Ubuntu;
	font-size: 35px;
	font-weight: 500;
	text-align: center;
`

const Buttons = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 40px;
`

type ButtonProps = {
	color: string
	bgColor: string
}

const Button = styled.button`
	border: none;
	border-radius: 22.5px;
	background-color: ${(props: ButtonProps) => props.bgColor};
	color: ${(props: ButtonProps) => props.color};
	cursor: pointer;
	font-family: Ubuntu;
	font-weight: 500;
	font-size: 16px;
	margin-right: 40px;
	width: 180px;
	padding: 15px 0;
	text-align: center;

	&:last-of-type {
		margin-right: 0;
	}

	&:focus {
		outline: none;
	}
`

type LogoutSplashScreenProps = {
	onDismiss: () => void
	trainerId: string | undefined
}
const LogoutSplashScreen: FC<LogoutSplashScreenProps> = ({
	onDismiss,
	trainerId,
}) => {
	const joinedRoomAddress = useJoinedRoomAddress()
	const workflowUser = useWorkflowUser()
	const beeldayClient = useAuthenticatedBeeldayClient()

	const handleExit = useCallback(() => {
		if (workflowUser && joinedRoomAddress) {
			leaveWorkflow(
				beeldayClient,
				joinedRoomAddress.workflowId,
				workflowUser.id
			)
		}
		exitToSessionPlanning({ isTrainer: workflowUser?.id === trainerId })
	}, [beeldayClient, joinedRoomAddress, trainerId, workflowUser])

	return (
		<Modal backgroundColor={Colors.transparentViolet}>
			<Container>
				<Message>
					<Translate>logout.message</Translate>
				</Message>
				<Buttons>
					<Button
						color={Colors.indigoBlue}
						bgColor={Colors.lightGray}
						onClick={onDismiss}
					>
						<Translate>logout.cancel</Translate>
					</Button>
					<Button
						color={Colors.white}
						bgColor={Colors.greenishCyan}
						onClick={handleExit}
					>
						<Translate>logout.confirm</Translate>
					</Button>
				</Buttons>
			</Container>
		</Modal>
	)
}

export default Exit
