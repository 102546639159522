import { colors, intl, ui, icons, images } from '@beelday/common'
import styled from '@emotion/styled'
import { useFormik } from 'formik'
import { v4 as uuidv4 } from 'uuid'
import { set } from 'lodash'
import { useState } from 'react'
import { NewPollingList } from './list-polling-models'
import { removeAtIndex } from 'session-info/utils/arrays'

const Container = styled.div`
	display: flex;
`

const Column = styled.div`
	flex: 0 0 50%;
	max-width: 50%;
`

const FormTitle = styled.div`
	font-size: 18px;
	line-height: 28px;
	font-weight: 500;
	margin-bottom: 20px;
`

const FormRow = styled.div`
	margin-bottom: 25px;
`

const Preview = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${colors.heather10};
	border-radius: 7px;
	padding: 25px;
	margin-left: 30px;
	height: 100%;
`

const PreviewTitle = styled.div`
	font-size: 18px;
	line-height: 28px;
`

const PreviewQuestion = styled.div`
	font-weight: 700;
	margin-bottom: 30px;
`

const PreviewImage = styled.div`
	img {
		max-width: 100%;
	}
`

const DeleteQuestion = styled.button`
	font-size: 14px;
	line-height: 14px;
	display: inline-flex;
	align-items: center;
	color: ${colors.indigoBlue};
	cursor: pointer;
	font-weight: 500;
	background-color: transparent;
	box-shadow: none;
	border: none;
	transition: opacity 0.25s;

	svg {
		width: 16px;
		height: 16px;
		margin-right: 8px;
	}

	&:hover {
		opacity: 0.7;
	}
`

const DeleteQuestionText = styled.span`
	border-bottom: 1px solid ${colors.indigoBlue};
`

type ValidationErrors = {
	name?: 'field.required'
	questions?: ('field.required' | 'field.length')[]
}

type Props = {
	onSubmit: (polling: NewPollingList) => Promise<unknown>
	onClose: () => void
}

const empty: NewPollingList = {
	id: '',
	name: '',
	questions: [''],
	letFacilitatorAnswer: true,
	allowForMultipleAnswer: true,
	type: 'POLLING_OPEN_ENDED_LIST',
}

const PollingListForm = ({ onSubmit, onClose }: Props): JSX.Element => {
	const translate = intl.useTranslate()
	const questionLength = 250
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

	const formik = useFormik({
		validate: values => {
			const errors: ValidationErrors = {}

			if (!values.name) {
				errors.name = 'field.required'
			}

			values.questions.forEach((question, questionIndex) => {
				if (!question) {
					set(errors, `questions[${questionIndex}]`, 'field.required')
				}
				if (question.length > questionLength) {
					set(errors, `questions[${questionIndex}]`, 'field.length')
				}
			})

			return errors
		},
		initialValues: empty,
		onSubmit: values => {
			const newTemplate: NewPollingList = {
				id: values.id ? values.id : uuidv4(),
				name: values.name,
				questions: values.questions,
				letFacilitatorAnswer: values.letFacilitatorAnswer,
				allowForMultipleAnswer: values.allowForMultipleAnswer,
				type: 'POLLING_OPEN_ENDED_LIST',
			}
			onSubmit(newTemplate).finally(() => formik.setSubmitting(false))
		},
	})

	return (
		<>
			<Container>
				<Column>
					<FormTitle>
						<intl.Translate>polling.open_ended.standard_list</intl.Translate>
					</FormTitle>
					<FormRow>
						<ui.InputLabeled
							label={translate('polling.open_ended.standard_list.name.label')}
						>
							<ui.Input
								id="name"
								name="name"
								placeholder={translate(
									'polling.open_ended.standard_list.name.placeholder'
								)}
								value={formik.values.name}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								incorrect={!!formik.errors.name && isSubmitted}
							/>
							{formik.errors.name && isSubmitted && (
								<ui.ErrorPlaceholder>
									<intl.Translate>{formik.errors.name}</intl.Translate>
								</ui.ErrorPlaceholder>
							)}
						</ui.InputLabeled>
					</FormRow>
					{formik.values.questions.map((question, index) => (
						<FormRow key={index}>
							<ui.InputLabeled
								label={
									<ui.FlexSpaceBetween>
										<div>
											<intl.Translate>
												polling.open_ended.standard_list.question.label
											</intl.Translate>
										</div>
										{formik.values.questions.length > 1 ? (
											<DeleteQuestion
												onClick={() =>
													formik.setFieldValue(
														'questions',
														removeAtIndex(formik.values.questions, index)
													)
												}
											>
												<icons.Trash />
												<DeleteQuestionText>Delete</DeleteQuestionText>
											</DeleteQuestion>
										) : null}
									</ui.FlexSpaceBetween>
								}
							>
								<ui.TextAreaLimited
									id={`questions[${index}]`}
									name={`questions[${index}]`}
									placeholder={translate(
										'polling.open_ended.standard_list.question.placeholder'
									)}
									value={formik.values.questions[index]}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									style={{ minHeight: '150px' }}
									limit={questionLength}
									incorrect={!!formik.errors.questions?.[index] && isSubmitted}
								/>
								{formik.errors.questions?.[index] && isSubmitted && (
									<ui.ErrorPlaceholder>
										<intl.Translate>
											{formik.errors.questions?.[index]}
										</intl.Translate>
									</ui.ErrorPlaceholder>
								)}
							</ui.InputLabeled>
						</FormRow>
					))}

					{formik.values.questions.length < 4 ? (
						<ui.AddNewButtonSection
							style={{ marginTop: '15px', marginBottom: '15px' }}
						>
							<ui.AddNewButton
								onClick={() =>
									formik.setFieldValue('questions', [
										...formik.values.questions,
										'',
									])
								}
							>
								<intl.Translate>polling.creator.add_question</intl.Translate>
							</ui.AddNewButton>
						</ui.AddNewButtonSection>
					) : null}

					<FormRow>
						<ui.InputLabeled
							label={translate(
								'polling.open_ended.standard_list.additional_options.label'
							)}
						>
							<ui.Checkbox
								id="letFacilitatorAnswer"
								name="letFacilitatorAnswer"
								checked={formik.values.letFacilitatorAnswer}
								text={translate(
									'polling.open_ended.standard_list.letFacilitatorAnswer.text'
								)}
								onChange={value =>
									formik.setFieldValue('letFacilitatorAnswer', value)
								}
								style={{ marginBottom: '10px' }}
							/>
							<ui.Checkbox
								id="allowForMultipleAnswer"
								name="allowForMultipleAnswer"
								checked={formik.values.allowForMultipleAnswer}
								text={translate(
									'polling.open_ended.standard_list.allowForMultipleAnswer.text'
								)}
								onChange={value =>
									formik.setFieldValue('allowForMultipleAnswer', value)
								}
							/>
						</ui.InputLabeled>
					</FormRow>
				</Column>
				<Column>
					<Preview>
						<PreviewTitle>
							<intl.Translate>
								polling.open_ended.standard_list.preview.title
							</intl.Translate>
						</PreviewTitle>
						<PreviewQuestion
							style={{
								fontSize:
									formik.values.questions[0]?.length < 50 ? '28px' : '18px',
								lineHeight:
									formik.values.questions[0]?.length < 50 ? '34px' : '24px',
							}}
						>
							{formik.values.questions[0]
								? formik.values.questions[0]
								: translate(
										'polling.open_ended.word_cloud.question.placeholder'
								  )}
						</PreviewQuestion>
						<PreviewImage>
							<img src={images.standardListImage} alt="Word Cloud" />
						</PreviewImage>
					</Preview>
				</Column>
			</Container>

			<ui.StickyWrapper
				style={{ padding: '24px 0px', borderRadius: '0px', boxShadow: 'none' }}
			>
				<ui.FlexSpaceBetween style={{ width: '100%' }}>
					<ui.ButtonMinor onClick={onClose}>
						<intl.Translate>cancel</intl.Translate>
					</ui.ButtonMinor>
					<ui.ButtonPrimary
						onClick={() => {
							formik.handleSubmit()
							setIsSubmitted(true)
						}}
						type="button"
						disabled={formik.isSubmitting}
					>
						<intl.Translate>polling.start_polling</intl.Translate>
					</ui.ButtonPrimary>
				</ui.FlexSpaceBetween>
			</ui.StickyWrapper>
		</>
	)
}

export default PollingListForm
