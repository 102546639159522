import styled from '@emotion/styled'

const Container = styled.div`
	text-align: center;
`

const Image = styled.img`
	margin-bottom: 15px;
`

const Title = styled.div`
	font-size: 16px;
	line-height: 21px;
	font-weight: 500;
	margin-bottom: 6px;
`

const Description = styled.div`
	font-size: 14px;
	line-height: 17px;
`

export const EmptyStateIconText = ({
	icon,
	title,
	description,
}: {
	icon: string
	title: string | JSX.Element
	description: string | JSX.Element
}): JSX.Element => {
	return (
		<Container>
			<Image src={icon} />
			<Title>{title}</Title>
			<Description>{description}</Description>
		</Container>
	)
}
