const VERSION = __BEELDAY_VERSION__

export const Config = {
	PROD: process.env.NODE_ENV === 'production',
	vcrManagerUrl:
		process.env.VCR_MANAGER_URL || 'https://vcrmanager.beelday.com:9991',
	beeldayBackendUrl: process.env.BEELDAY_BACKEND_URL || '/live-session',
	assetsUrl:
		process.env.ASSETS_URL ||
		'https://pb-public.eu-central-1.linodeobjects.com',
	sessionPlanningHome: '/session-planning',
	sessionPlanningURL: process.env.SESSION_PLANNING_URL || '/planning/api/v0',
	etherPadUrl:
		process.env.ETHERPAD_URL || 'https://localhost.test:3333/etherpad',
	AUTH_URL: process.env.AUTH_URL || '/auth',
	AUTH_REALM: 'beelday',
	AUTH_CLIENT_ID: 'testapp',
	USER_API_URL: process.env.USER_API_URL || '/u12t/api/v0',
	EXCALIDRAW_SERVER_URL:
		process.env.EXCALIDRAW_SERVER_URL || '/excalidraw.socket',
	EXCALIDRAW_ASSETS_SERVER_URL:
		process.env.EXCALIDRAW_ASSETS_SERVER_URL || '/excalidraw-room',
	heartbeatFrequency: 5000,
	sseTimeout: 30000,
	requestRetryDelay: 1000,
	requestExecutionTimeBeforeWarning: 3000,
	mediaStatsIntervalMs: 1000,
	expireAuthTokensEarlierAboutMs: 30000,
	minTokenRefreshIntervalMs: 5000,
	videoSpotlightDelayMs: 3000,
	vcrConnectionMaxRetries: 15,
	vcrConnectionRetryIntervalMs: 1000,
	moduleMessagingUrl: process.env.MODULE_MESSAGGING || '/messaging',
	moduleUserProfile: process.env.MODULE_USER_PROFILE || '/user-profile',
	moduleSessionPlanningUrl:
		process.env.MODULE_SESSION_PLANNING || '/session-planning',
	moduleSlidesUrl: process.env.MODULE_SLIDES || '/slides',
	userEngagementReportingUrl:
		process.env.USER_ENGAGEMENT_REPORTER_URL ||
		'wss://trainings-dev.dyn.beelday.com/aa-reports',
	userEngagementResultsUrl:
		process.env.USER_ENGAGEMENT_RESULTS_URL ||
		'wss://trainings-dev.dyn.beelday.com/aa-notifications',
	junoUrl: process.env.JUNO_URL || 'https://trainings-dev.dyn.beelday.com/juno',
	junoPollInterval: 10000,
	janusAdminPort: 7889,
	janusTransport: process.env.JANUS_TRANSPORT || 'http',
	vcrManager: process.env.VCR_MANAGER || 'juno',
	remoteControlUrl: process.env.REMOTE_CONTROL_URL || 'ws://localhost:7777/ws',
	slidesServerUrl: process.env.SLIDES_SERVER_URL || 'https://localhost:3015',
	slidesAssetUrl:
		process.env.SLIDES_ASSET_URL || 'https://localhost.test:3333/slide-data',
	slidesApiUrl:
		process.env.SLIDES_ASSET_URL || 'https://localhost.test:3333/slide-data',
	PDF_VIEWER_URL:
		process.env.PDF_VIEWER_URL ||
		'https://localhost.test:3333/pdfjs/web/viewer.html',
	eventSourceCloseTimeout: 30000,
	VERSION,
}
