import { intl } from '@beelday/common'
import { TrainingPhase } from 'common/types'
import { useAuthenticatedBeeldayClient } from 'connectivity/beelday-hooks'
import { useCallback, useEffect, useState } from 'react'
import { MenuItem } from 'room/common/presentation/menu'
import { EndBreak } from './icon/end-break'
import { StartIcon } from './icon/start-icon'
import { useAssertedJoinedRoomAddress } from '../use-joined-room-address'

const ResumeTrainingMenuItem = (): JSX.Element | null => {
	const beeldayClient = useAuthenticatedBeeldayClient()
	const address = useAssertedJoinedRoomAddress()

	const [phase, setPhase] = useState<TrainingPhase>()

	useEffect(() => {
		beeldayClient.getTrainingPhase(address).then(setPhase)
	}, [beeldayClient, address])

	const isTrainingInProgress = phase && phase.previousPhase

	const resumeTraining = useCallback(() => {
		isTrainingInProgress
			? beeldayClient.endBreak(address)
			: beeldayClient.startTraining(address)
	}, [address, beeldayClient, isTrainingInProgress])

	return isTrainingInProgress ? (
		<MenuItem
			icon={<EndBreak />}
			onClick={resumeTraining}
			label={<intl.Translate>lobby.end_break</intl.Translate>}
		/>
	) : (
		<MenuItem
			icon={<StartIcon />}
			onClick={resumeTraining}
			label={<intl.Translate>lobby.start_training</intl.Translate>}
		/>
	)
}

export default ResumeTrainingMenuItem
