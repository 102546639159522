import {
	arrays,
	eventbus,
	hooks,
	MaybeUserProfile,
	userApi,
} from '@beelday/common'
import { Config } from 'common/config'
import type { RootState } from 'common/redux'
import { PROFILE_MISSING, UserId, UserProfile } from 'common/types'
import { identity } from 'lodash'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import type { Middleware } from 'redux'
import { createSelector } from 'reselect'

export type UsersState = {
	all: Record<UserId, UserProfile | PROFILE_MISSING>
}

const usersDefaultState: UsersState = {
	all: {},
}

type UserAction = {
	type: 'ADD_USERS'
	users: Record<UserId, UserProfile | PROFILE_MISSING>
}

export const addUsers = (
	users: Record<UserId, UserProfile | PROFILE_MISSING>
): UserAction => {
	return { type: 'ADD_USERS', users }
}

export function usersReducer(
	state: UsersState = usersDefaultState,
	action: UserAction
): UsersState {
	switch (action.type) {
		case 'ADD_USERS':
			const profiles = action.users

			return {
				...state,
				all: {
					...state.all,
					...profiles,
				},
			}
		default:
			return state
	}
}

//Middleware
export const middleware: Middleware = () => next => action => {
	if (action.type === 'EVENTBUS/USER_PROFILE_CHANGED') {
		const { id } = action.payload
		const api = userApi.createUserAPI(Config.USER_API_URL)
		api.getUserProfiles([action.payload.id]).then(profiles => {
			const profile = profiles.find(p => (p.id = id))
			if (profile) {
				next(addUsers({ [id]: profile }))
			}
		})
	}

	next(action)
}

const profileFor = createSelector(
	(state: RootState) => state.users.all,
	(state: RootState, id: UserId) => id,
	(profiles, id): UserProfile | null | undefined => profiles[id]
)

export const useProfilesFor = (ids: UserId[]): MaybeUserProfile[] => {
	const memoizedIds = hooks.useMemoComputed(() => ids, arrays.arrEqual)
	const allUsers = useSelector((state: RootState) => state.users.all)

	const eventBus = eventbus.useEventBus()
	//Do NOT USE reselect here, it's memoization is broken
	//and you'll get random infinite re-render loops
	const profiles = useMemo(() => {
		return memoizedIds.map(id => allUsers[id]).filter(identity)
	}, [allUsers, memoizedIds])

	useEffect(() => {
		if (memoizedIds.length) {
			memoizedIds.forEach(id => {
				eventBus.subscribe('USER_PROFILE_CHANGED', id)
			})
			return () => {
				memoizedIds.forEach(id => {
					eventBus.unsubscribe('USER_PROFILE_CHANGED', id)
				})
			}
		}
	}, [memoizedIds, eventBus])

	return profiles
}

export const useProfileFor = (
	userId?: UserId
): MaybeUserProfile | undefined => {
	const profile = useSelector((state: RootState) =>
		userId ? profileFor(state, userId) : undefined
	)

	const eventBus = eventbus.useEventBus()
	const id = profile?.id

	useEffect(() => {
		if (id) {
			eventBus.subscribe('USER_PROFILE_CHANGED', id)
			return () => eventBus.unsubscribe('USER_PROFILE_CHANGED', id)
		}
	}, [id, eventBus])
	return profile
}
