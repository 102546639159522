export const JoinTrainingIcon: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="14.138"
		height="14.138"
		viewBox="0 0 14.138 14.138"
	>
		<path
			id="Path_8986"
			data-name="Path 8986"
			d="M8.591,3.043V.5a.5.5,0,0,1,.88-.338l4.544,5.049a.5.5,0,0,1,.016.656L9.486,11.427a.5.5,0,0,1-.9-.319V8.592c-3.989.13-6.478,1.835-7.6,5.2a.5.5,0,0,1-.479.345.489.489,0,0,1-.081-.007.5.5,0,0,1-.424-.5C.007,7.687,3.586,3.319,8.591,3.043Z"
			transform="translate(-0.007 0)"
			fill="currentColor"
		/>
	</svg>
)
