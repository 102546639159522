import { FC } from 'react'
import styled from '@emotion/styled'
import useResize from 'utils/hooks/use-resize'

type CenteredFlexContainerProps = {
	heightPercent: number
	widthPercent: number
}

type Alignment = 'start' | 'center'

const CenteredFlexContainer = styled.div<CenteredFlexContainerProps>`
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	flex-flow: row wrap;
	${props => `
        width: ${props.widthPercent}%;
        height: ${props.heightPercent}%;
    `};
	position: absolute;
`

const GroupVideosBucketPosition = styled.div<{ align: Alignment }>`
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: ${props => props.align};
	position: relative;
	flex-grow: 1;
`

const calculatePercentSize = (
	containerWidth: number,
	containerHeight: number,
	aspect: number
): [number, number] => {
	const invertedVideoAspectRatio = 1 / aspect
	if (invertedVideoAspectRatio * containerWidth <= containerHeight) {
		const scale = 100 / containerHeight
		return [
			scale * containerHeight,
			invertedVideoAspectRatio * containerWidth * scale,
		]
	} else {
		const scale = 100 / containerWidth
		return [aspect * containerHeight * scale, containerWidth * scale]
	}
}

type AspectRatioContainerProps = {
	aspect: number
	align: Alignment
}
const AspectRatioContainer: FC<AspectRatioContainerProps> = ({
	children,
	aspect,
	align,
}) => {
	const {
		ref: containerRef,
		width: containerWidth,
		height: containerHeight,
	} = useResize()
	const [widthPercent, heightPercent] = calculatePercentSize(
		containerWidth,
		containerHeight,
		aspect
	)

	return (
		<GroupVideosBucketPosition align={align} ref={containerRef}>
			<CenteredFlexContainer
				widthPercent={widthPercent}
				heightPercent={heightPercent}
			>
				{children}
			</CenteredFlexContainer>
		</GroupVideosBucketPosition>
	)
}

const VIDEO_ASPECT_RATIO = 640 / 416
export const VideoAspectRatioContainer: FC = ({ children }) => {
	return (
		<AspectRatioContainer aspect={VIDEO_ASPECT_RATIO} align="center">
			{children}
		</AspectRatioContainer>
	)
}

export default AspectRatioContainer
