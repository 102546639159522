import { useMediaQuery } from 'react-responsive'

export const SMALL_TABLET_WIDTH = 768
export const TABLET_WIDTH = 1024
export const DESKTOP_WIDTH = 1280
export const DESKTOP_1440_WIDTH = 1440
export const smallTablet = '@media (min-width: 768px)'
export const tablet = '@media (min-width: 1024px)'
export const desktop = '@media (min-width: 1280px)'
export const desktop1440 = '@media (min-width: 1440px)'
export const desktop1536 = '@media (min-width: 1536px)'
export const desktop1680 = '@media (min-width: 1680px)'
export const desktopFHD = '@media (min-width: 1920px)'

export const desktop1440x768 =
	'@media (min-width: 1440px) and (min-height: 768px)'

export const desktop1440x900 =
	'@media (min-width: 1440px) and (min-height: 900px)'

export const useIsMobile = (): boolean =>
	!useMediaQuery({
		query: `(min-width: ${SMALL_TABLET_WIDTH}px)`,
	})

export const useIsDesktop1024 = (): boolean =>
	useMediaQuery({
		query: `(min-width: ${TABLET_WIDTH}px)`,
	})

export const useIsDesktop1280 = (): boolean =>
	useMediaQuery({
		query: `(min-width: ${DESKTOP_WIDTH}px)`,
	})

export const useIsDesktop1440 = (): boolean =>
	useMediaQuery({
		query: `(min-width: ${DESKTOP_1440_WIDTH}px)`,
	})

export default {
	SMALL_TABLET_WIDTH,
	TABLET_WIDTH,
	smallTablet,
	tablet,
	desktop,
	desktop1440,
	desktop1536,
	desktopFHD,
	useIsMobile,
	useIsDesktop1280,
}
