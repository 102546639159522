import { jsx } from '@emotion/react'
import { Fragment } from 'react'

export { jsx, Fragment }

export const process = {
	env: new Proxy(
		{},
		{
			get: () => '',
		}
	),
}
