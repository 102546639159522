import { icons, ui } from '@beelday/common'
import styled from '@emotion/styled'
import { FC, useState } from 'react'
import DraggableStream from './draggable-stream'
import { SceneVideoUser } from 'video-conference-media'

type Props = {
	sceneVideoUser: SceneVideoUser
	mirrored: boolean
	mutedIcon?: boolean
	startVisible: boolean
}

const DraggableIcon = styled.div`
	margin-right: 5px;
`

const DraggableStreamIcon: FC<Props> = ({
	sceneVideoUser,
	mirrored,
	mutedIcon,
	startVisible,
}) => {
	const [visible, setVisible] = useState<boolean>(startVisible)

	const toggleStream = () => {
		setVisible(!visible ?? false)
	}

	const closeStream = () => {
		setVisible(false)
	}

	return (
		<DraggableIcon>
			<ui.CircleIconButton active={visible} onClick={() => toggleStream()}>
				<icons.SelfViewIcon />
			</ui.CircleIconButton>
			<div
				style={{
					display: visible || visible ? undefined : 'none',
				}}
			>
				<DraggableStream
					sceneVideoUser={sceneVideoUser}
					mirrored={mirrored}
					mutedIcon={mutedIcon}
					closeStream={closeStream}
				/>
			</div>
		</DraggableIcon>
	)
}

export default DraggableStreamIcon
