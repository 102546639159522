import { css } from '@emotion/react'
import styled from '@emotion/styled'
import cls from 'classnames'
import { CSSProperties, FC, PropsWithChildren, useRef, useState } from 'react'
import colors from '../colors'

export const Draggable: FC<{
	grid?: boolean
	gap: number
	data: string
	type: string
	onDrop?: (source: string, target: string, after: boolean) => unknown
	draggable?: boolean
	droppable?: boolean
}> = ({ children, data, type, onDrop, draggable, droppable, grid, gap }) => {
	const [dragging, setDragging] = useState(false)
	const [dropTarget, setDropTarget] = useState<-1 | 1 | null>(null)
	const ref = useRef<HTMLDivElement>(null)
	//Need to keep track of drag over count
	//or we get studdering when dragging over nested elements
	const dragOverCountRef = useRef<number>(0)

	return (
		<div
			ref={ref}
			draggable={draggable}
			className={cls({ dragging })}
			onDragStart={e => {
				if (!draggable) return
				const target = e.currentTarget
				const dragImage = target.children[0] || target
				e.dataTransfer.setData(type, data)
				e.dataTransfer.effectAllowed = 'move'
				e.dataTransfer.setDragImage(dragImage, 10, 10)

				setTimeout(() => {
					//Browser will take a screenshot of the dragged element
					//after this method exits, so we need to postpone hiding of the element
					setDragging(true)
				}, 0)
			}}
			onDragOver={e => {
				if (!droppable) return
				if (ref.current && e.dataTransfer.types.includes(type)) {
					const rect = ref.current.getBoundingClientRect()
					let target: -1 | 1 = -1
					if (grid) {
						const x = e.clientX - rect.x
						if (x > rect.width / 2) {
							target = 1
						}
					} else {
						const y = e.clientY - rect.y
						if (y > rect.height / 2) {
							target = 1
						}
					}

					e.preventDefault()
					if (dropTarget !== target) {
						setDropTarget(target)
					}
				}
			}}
			onDragEnter={() => {
				dragOverCountRef.current++
			}}
			onDragLeave={() => {
				dragOverCountRef.current--
				if (dragOverCountRef.current <= 0) {
					setDropTarget(null)
				}
			}}
			onDragEnd={() => {
				dragOverCountRef.current = 0
				setDragging(false)
			}}
			onDrop={e => {
				dragOverCountRef.current = 0
				if (!droppable) return
				const dropped = e.dataTransfer.getData(type)
				if (dropped) {
					setDropTarget(null)
					if (data !== dropped) {
						onDrop?.(dropped, data, dropTarget === 1)
					}
				}
			}}
			style={{
				borderTop:
					!grid && dropTarget === -1
						? `3px solid ${colors.indigoBlue}`
						: undefined,
				borderBottom:
					!grid && dropTarget === 1
						? `3px solid ${colors.indigoBlue}`
						: undefined,
				borderLeft:
					grid && dropTarget === -1
						? `3px solid ${colors.indigoBlue}`
						: undefined,
				borderRight:
					grid && dropTarget === 1
						? `3px solid ${colors.indigoBlue}`
						: undefined,
				padding: `${gap / 2}px`,
			}}
			css={css`
				transition: all 0.2s ease-in;
				box-sizing: border-box;
				&.dragging {
					opacity: 0;
					transform: scaleY(0);
					transform-origin: top;
				}
			`}
		>
			{children}
		</div>
	)
}

export const DragContainer: FC<
	PropsWithChildren<{ className?: string; style?: CSSProperties }>
> = ({ className, style, children }) => {
	return (
		<div className={className} style={style}>
			{children}
		</div>
	)
}

const DroppableContainer = styled.div`
	transition: all 0.2s ease-in;
	box-sizing: border-box;
	position: relative;

	.dropOverlay {
		display: none;
	}

	&.dropTarget * {
		pointer-events: none;
	}

	&.dropTarget .dropOverlay {
		display: block;
	}
`

export const DroppableInto: FC<{
	disabled?: boolean
	type: string
	data: string
	onDrop?: (source: string, target: string) => unknown
	style?: CSSProperties
	dropOverlay?: React.ReactNode
}> = ({ type, data, onDrop, children, disabled, dropOverlay }) => {
	return (
		<DroppableContainer
			draggable={false}
			onDragEnter={e => {
				console.log('enter')
				if (disabled) return
				if (e.dataTransfer.types.includes(type)) {
					e.currentTarget.classList.add('dropTarget')
				}
			}}
			onDragOver={e => {
				e.preventDefault()
			}}
			onDragLeave={e => {
				if (e.currentTarget === e.target) {
					e.currentTarget.classList.remove('dropTarget')
				}
			}}
			onDrop={e => {
				e.currentTarget.classList.remove('dropTarget')
				if (disabled) return
				const dropped = e.dataTransfer.getData(type)
				if (dropped) {
					e.preventDefault()
					onDrop?.(dropped, data)
				}
			}}
		>
			{children}
			<div className="dropOverlay">{dropOverlay || <DropOverlay />}</div>
		</DroppableContainer>
	)
}

export const DropOverlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: ${colors.white70};
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px dashed ${colors.borderGray};
	border-radius: 4px;
	font-weight: bold;
	font-size: 14px;
`
