import { intl, ui, images, icons } from '@beelday/common'
import { useCallback, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { useWordCloud } from 'features/pollings/word-cloud'
import { PollingWordCloudDefinition } from 'features/pollings/word-cloud/world-cloud-models'
import { useListPolling } from 'features/pollings/list-polling'
import { PollingListPollingDefinition } from 'features/pollings/list-polling/list-polling-models'
import { PollingMultipleChoicePollingDefinition } from 'features/pollings/multiple-choice-polling/multiple-choice-polling-models'
import { useMultipleChoicePolling } from 'features/pollings/multiple-choice-polling'
import PollingListTemplateForm from 'features/pollings/list-polling/polling-list-form'
import PollingWordCloudTemplateForm from 'features/pollings/word-cloud/polling-word-cloud-form'
import PollingMultipleChoiceTemplateForm from 'features/pollings/multiple-choice-polling/polling-multiple-choice-form'
import { useSelector } from 'react-redux'
import { selectEnabledFeatures } from 'room/common/redux'

const Title = styled.div`
	font-size: 16px;
	line-height: 22px;
	font-weight: 500;
	margin-bottom: 15px;
	margin-top: 24px;
`

const PollingRadioListWrapper = styled.div`
	margin-bottom: 30px;
`

type Props = {
	onClose: () => void
}

const StartPollingsPopup = ({ onClose }: Props): JSX.Element => {
	const translate = intl.useTranslate()
	const wordCloudPollings = useWordCloud()
	const listPollings = useListPolling()
	const multipleChoicePollings = useMultipleChoicePolling()
	const enabledFeatures = useSelector(selectEnabledFeatures)

	const [wordCloudPolls, setWordCloudPolls] = useState<
		PollingWordCloudDefinition[]
	>([])
	const [listPollsList, setListPollsList] = useState<
		PollingListPollingDefinition[]
	>([])
	const [multipleChoicePollsList, setMultipleChoicePollsList] = useState<
		PollingMultipleChoicePollingDefinition[]
	>([])
	const [selected, setSelected] = useState<
		| PollingWordCloudDefinition
		| PollingListPollingDefinition
		| PollingMultipleChoicePollingDefinition
	>()

	const [activePollingForm, setActivePollingForm] = useState<string>('')

	const startPoll = useCallback(() => {
		if (selected && selected.type === 'POLLING_OPEN_ENDED_WORD_CLOUD') {
			wordCloudPollings.startPolling(selected.id)
			onClose()
		}

		if (selected && selected.type === 'POLLING_OPEN_ENDED_LIST') {
			listPollings.startPolling(selected.id)
			onClose()
		}

		if (selected && selected.type === 'POLLING_MULTIPLE_CHOICE') {
			multipleChoicePollings.startPolling(selected.id)
			onClose()
		}

		if (selected && selected.type === 'POLLING_MULTIPLE_CHOICE') {
			multipleChoicePollings.startPolling(selected.id)
			onClose()
		}
	}, [
		listPollings,
		multipleChoicePollings,
		onClose,
		selected,
		wordCloudPollings,
	])

	const createNew = (type: string) => setActivePollingForm(type)

	useEffect(() => {
		wordCloudPollings
			.getPollingDefinitions()
			.then(pollings => setWordCloudPolls(pollings))
	}, [wordCloudPollings])

	useEffect(() => {
		listPollings
			.getPollingDefinitions()
			.then(pollings => setListPollsList(pollings))
	}, [listPollings])

	useEffect(() => {
		multipleChoicePollings
			.getPollingDefinitions()
			.then(pollings => setMultipleChoicePollsList(pollings))
	}, [multipleChoicePollings])

	const allowMultipleChoice = enabledFeatures.includes(
		'MultipleChoicePollingFeatureType'
	)
	const allowWordCloud = enabledFeatures.includes('WordCloudPollingFeatureType')
	const allowList = enabledFeatures.includes('ListPollingFeatureType')

	const allPolls = useMemo(
		() => [
			...(allowWordCloud ? wordCloudPolls : []),
			...(allowList ? listPollsList : []),
			...(allowMultipleChoice ? multipleChoicePollsList : []),
		],
		[
			allowList,
			allowMultipleChoice,
			allowWordCloud,
			listPollsList,
			multipleChoicePollsList,
			wordCloudPolls,
		]
	)

	return (
		<>
			<ui.RightDetailsPanel
				header={
					<ui.RightDetailsPanel.SubTitleAndTitle
						title={
							<intl.Translate>room_menu.polling.start_title</intl.Translate>
						}
						subTitle={
							<intl.Translate>
								room_menu.polling.management_sub_title
							</intl.Translate>
						}
					/>
				}
				onClose={onClose}
			>
				<>
					{activePollingForm === 'POLLING_MULTIPLE_CHOICE' && (
						<PollingMultipleChoiceTemplateForm
							onClose={() => setActivePollingForm('')}
							onSubmit={polling => {
								setActivePollingForm('')
								onClose()
								return multipleChoicePollings.createPolling(polling)
							}}
						/>
					)}
					{activePollingForm === 'POLLING_OPEN_ENDED_WORD_CLOUD' && (
						<PollingWordCloudTemplateForm
							onClose={() => setActivePollingForm('')}
							onSubmit={polling => {
								setActivePollingForm('')
								onClose()
								return wordCloudPollings.createPolling(polling)
							}}
						/>
					)}
					{activePollingForm === 'POLLING_OPEN_ENDED_LIST' && (
						<PollingListTemplateForm
							onClose={() => setActivePollingForm('')}
							onSubmit={polling => {
								setActivePollingForm('')
								onClose()
								return listPollings.createPolling(polling)
							}}
						/>
					)}

					{activePollingForm === '' && (
						<>
							<ui.FlexCenter style={{ gap: '24px', flexWrap: 'nowrap' }}>
								{allowMultipleChoice ? (
									<ui.ButtonLarge
										style={{ width: '100%' }}
										icon={<icons.MultipleChoiceIcon />}
										onClick={() => createNew('POLLING_MULTIPLE_CHOICE')}
									>
										<intl.Translate>polling.multiple_choice</intl.Translate>
									</ui.ButtonLarge>
								) : null}
								{allowWordCloud ? (
									<ui.ButtonLarge
										style={{ width: '100%' }}
										icon={<icons.WordCloudIcon />}
										onClick={() => createNew('POLLING_OPEN_ENDED_WORD_CLOUD')}
									>
										<intl.Translate>
											polling.open_ended.word_cloud
										</intl.Translate>
									</ui.ButtonLarge>
								) : null}
								{allowList ? (
									<ui.ButtonLarge
										style={{ width: '100%' }}
										icon={<icons.StandardListIcon />}
										onClick={() => createNew('POLLING_OPEN_ENDED_LIST')}
									>
										<intl.Translate>
											polling.open_ended.standard_list
										</intl.Translate>
									</ui.ButtonLarge>
								) : null}
							</ui.FlexCenter>
							{allPolls.length !== 0 ? (
								<>
									<Title>
										<intl.Translate>room_menu.pollings</intl.Translate>
									</Title>
									<PollingRadioListWrapper>
										<ui.CheckboxList>
											{allPolls.map(option => (
												<ui.CheckboxListItem
													key={option.id}
													type="radio"
													name={
														option.type === 'POLLING_OPEN_ENDED_LIST'
															? option.name
															: option.question
													}
													value={option.id}
													description={translate(
														`training.script.tool.${option.type}.description`
													)}
													checked={selected?.id === option.id}
													imageSrc={images.pollingTemplateIcon}
													onChange={checked => {
														if (checked) {
															setSelected(option)
														}
													}}
												/>
											))}
										</ui.CheckboxList>
									</PollingRadioListWrapper>
									{selected && (
										<ui.ButtonPrimary onClick={startPoll}>
											<intl.Translate>
												room_menu.polling.start_button
											</intl.Translate>
										</ui.ButtonPrimary>
									)}
								</>
							) : (
								<div style={{ marginTop: '24px' }}>
									<ui.EmptyStateIconText
										icon={images.pollingTemplateCircleIcon}
										title={translate('polling.empty.from_tools_title')}
										description={translate(
											'polling.empty.from_tools_description'
										)}
									/>
								</div>
							)}
						</>
					)}
				</>
			</ui.RightDetailsPanel>
		</>
	)
}

export default StartPollingsPopup
