export const Whiteboard: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="18.253"
		height="24"
		viewBox="0 0 18.253 24"
	>
		<g data-name="Group 25582">
			<g data-name="Group 25583">
				<path
					data-name="Path 11814"
					d="M13.676 3.835a1.826 1.826 0 0 0-.58-2.473L11.329.276a1.781 1.781 0 0 0-2.473.581L2.37 11.255l4.8 3z"
				/>
				<path
					data-name="Path 11815"
					d="m1.436 19.457 2.928-1.792a5.777 5.777 0 0 0 1.514-1.413L1.159 13.3a5.075 5.075 0 0 0-.606 1.969L.225 18.7a.794.794 0 0 0 1.211.757z"
				/>
				<path
					data-name="Path 11816"
					d="M17.992 22.132c-2.473-2.953-5.2-1.59-6.663-.833a4.1 4.1 0 0 1-.984.429c-.454 0-.555-.126-.934-.656a2.987 2.987 0 0 0-2.751-1.59c-1.766 0-5.35 1.842-6.06 2.218a1.123 1.123 0 0 0 1.038 2c1.54-.808 4.114-1.969 5.022-1.969.43.051.505.1.909.656A3.155 3.155 0 0 0 10.345 24a4.671 4.671 0 0 0 2.019-.682c1.616-.807 2.675-1.236 3.912.278a1.076 1.076 0 0 0 .858.4 1.057 1.057 0 0 0 .707-.253 1.158 1.158 0 0 0 .151-1.611z"
				/>
			</g>
		</g>
	</svg>
)
