import { http } from '@beelday/common'
import { RoomAddress } from 'common/types'
import { useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'

export type MicApi = {
	start: (address: RoomAddress) => Promise<void>
	finish: (address: RoomAddress) => Promise<void>
}

export const createSimpleGroupingApi = (serverUrl: string): MicApi => ({
	start: (address: RoomAddress) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/simplegrouping`,
			{
				method: 'PUT',
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		).then(http.checkStatus),
	finish: (address: RoomAddress) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/simplegrouping`,
			{
				method: 'DELETE',
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		).then(http.checkStatus),
})

export const useSimpleGroupingApi = (serverUrl: string): MicApi => {
	return useMemo(() => createSimpleGroupingApi(serverUrl), [serverUrl])
}
