import styled from '@emotion/styled'

export const HeadingP = styled.p`
	margin: 0px;
	object-fit: contain;
	font-family: Ubuntu;
	font-size: max(16px, min(2.8vh, 1.95vw));
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #272957;
`
