import { images, intl, ui } from '@beelday/common'
import { useAuthenticatedBeeldayClient } from 'connectivity/beelday-hooks'
import { useCallback, useEffect, useState } from 'react'
import { RoomAddress, UUID } from 'common/types'
import { Grouping } from '../../types'

type Props = {
	address: RoomAddress
	onClose: () => unknown
	onStart: (grouping: Grouping) => unknown
}

const GroupTemplatesPopup = ({ address, onClose, onStart }: Props) => {
	const translate = intl.useTranslate()
	const beeldayClient = useAuthenticatedBeeldayClient()
	const [groupings, setGroupings] = useState<Grouping[]>([])
	const [selected, setSelected] = useState<UUID>()

	useEffect(() => {
		beeldayClient
			.getGroupingDefinitions(address)
			.then(result => setGroupings(result))
	}, [
		address.workflowId,
		address.interactionSchemeId,
		address.roomType,
		address.roomId,
		beeldayClient,
		address,
	])

	const startGrouping = useCallback(() => {
		if (selected) {
			const selectedGrouping = groupings.find(
				grouping => grouping.id === selected
			)
			if (selectedGrouping) {
				onStart(selectedGrouping)
				onClose()
			}
		}
	}, [onClose, groupings, onStart, selected])

	const generateDescription = (type: string, size: number) => {
		return `${translate(`groups.type.${type}`)} • ${translate('groups.size', {
			count: size,
		})}`
	}

	return (
		<ui.RightDetailsPanel
			header={
				<ui.RightDetailsPanel.SubTitleAndTitle
					title={
						<intl.Translate>room_menu.grouping.start_title</intl.Translate>
					}
					subTitle={
						<intl.Translate>
							room_menu.grouping.management_sub_title
						</intl.Translate>
					}
				/>
			}
			onClose={onClose}
		>
			{groupings.length ? (
				<ui.CheckboxList>
					{groupings.map(option => (
						<ui.CheckboxListItem
							type="radio"
							name={option.name}
							value={option.id}
							description={generateDescription(
								option.groupingType,
								option.groupSize
							)}
							checked={selected === option.id}
							imageSrc={images.groupingTemplateIcon}
							onChange={checked => {
								if (checked) setSelected(option.id)
							}}
						/>
					))}
				</ui.CheckboxList>
			) : null}

			{selected && (
				<ui.ButtonPrimary onClick={startGrouping}>
					<intl.Translate>room_menu.grouping.start_button</intl.Translate>
				</ui.ButtonPrimary>
			)}
		</ui.RightDetailsPanel>
	)
}

export default GroupTemplatesPopup
