export const NotificationIcon = (): JSX.Element => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.364 20.364">
		<g id="notification" transform="translate(-0.063 -4.522)">
			<path
				id="Shape"
				d="M5.03,0A2.515,2.515,0,0,1,0,0"
				transform="translate(7.729 21.621)"
				fill="none"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
				strokeWidth="1.5"
			/>
			<path
				id="Shape-2"
				data-name="Shape"
				d="M0,0H18.864"
				transform="translate(0.813 19.106)"
				fill="none"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
				strokeWidth="1.5"
			/>
			<path
				id="Shape-3"
				data-name="Shape"
				d="M16.349,13.834h.629c-1.39,0-3.144-1.126-3.144-2.515V5.659A5.66,5.66,0,0,0,8.174,0h0A5.66,5.66,0,0,0,2.515,5.659v5.659A2.515,2.515,0,0,1,0,13.834"
				transform="translate(2.07 5.272)"
				fill="none"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
				strokeWidth="1.5"
			/>
		</g>
	</svg>
)
