import { Color, GroupId, GroupReview } from 'common/types'

import React, { FC, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { colorToHex } from 'room/common/presentation/colorsInHex'
import { colors, icons, intl, ui } from '@beelday/common'
import Translate from 'intl/translate'
import { css } from '@emotion/react'

const Switcher = styled.div`
	max-width: 100%;
	min-width: 320px;
	position: relative;
	z-index: 1;
`

const Inner = styled.div`
	background: ${colors.white};
	border-radius: 10px;
	box-shadow: 0 2px 5px 0 rgba(38, 51, 77, 0.1);
	display: flex;
	overflow: hidden;
`
const Content = styled.button`
	align-self: center;
	background-color: ${colors.white};
	border: 0;
	display: flex;
	flex: 0 0 calc(100% - 30px);
	max-width: calc(100% - 30px);
	padding: 14px 10px;
	position: relative;
	text-align: left;

	&:hover {
		.contentIcon {
			color: ${colors.indigoBlue};
		}
	}
`

const ContentIcon = styled.div`
	position: absolute;
	bottom: 15px;
	right: 15px;
	color: ${colors.borderGray};
	transition: color 0.25s;

	svg {
		width: 12px;
	}
`

const ContentCircle = styled.div<{ color: Color }>`
	align-items: center;
	background: ${props => colorToHex[props.color]};
	border-radius: 50%;
	color: ${colors.white};
	display: flex;
	height: 32px;
	justify-content: center;
	margin-right: 10px;
	width: 32px;
`
const ContentInner = styled.div``

const ContentTitle = styled.div`
	color: ${colors.blackish};
	font-size: 12px;
	line-height: 13px;
`

const ContentText = styled.div`
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
`

const Buttons = styled.div`
	border-left: 1px solid ${colors.borderGray50};
	display: flex;
	flex: 0 0 30px;
	flex-direction: column;
	max-width: 30px;
`

const Button = styled.button`
	background: ${colors.white};
	border: 0;
	color: ${colors.borderGray};
	cursor: pointer;
	flex: 1;
	transition: color 0.25s;

	&:first-child {
		border-bottom: 1px solid ${colors.borderGray50};
	}

	&:hover {
		color: ${colors.indigoBlue};
	}
`

const Popup = styled.div<{ fullWidth?: boolean }>`
	background: ${colors.white};
	border-radius: 20px;
	box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
	left: 50%;
	position: absolute;
	transform: translateX(-50%);
	transition: bottom 0.25s, top 0.25s, opacity 0.25s;
	width: ${props => (props.fullWidth ? '100%' : '470px')};
	max-width: ${props => (props.fullWidth ? '100%' : '')};
`

const PopupContent = styled.div`
	padding: 25px 40px 15px 40px;
`

const PopupContentSmall = styled.div`
	padding: 25px 20px 15px 20px;
`

const PopupHeader = styled.div<{ small?: boolean }>`
	align-items: center;
	border-bottom: 1px solid ${colors.heather30};
	display: flex;
	padding: ${props =>
		props.small ? '16px 20px 18px 20px' : '16px 40px 18px 40px'};
`

const PopupHeaderContent = styled.div`
	margin-right: auto;
`

const PopupHeaderTitle = styled.div`
	font-size: 20px;
	font-weight: 500;
	line-height: 32px;
`

const PopupHeaderText = styled.div`
	font-size: 12px;
	font-weight: 500;
	line-height: 16px;
`

const Item = styled.div`
	align-items: center;
	display: flex;
	margin-bottom: 20px;
`

const ItemContent = styled.div`
	margin-right: auto;
`

const ItemTitle = styled.div`
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
`

const ItemLength = styled.div`
	font-size: 12px;
	font-weight: 300;
	line-height: 16px;
`

type ActiveGroup = Pick<GroupReview, 'id' | 'properties'>

type Props = {
	active: ActiveGroup
	groups: Pick<GroupReview, 'members' | 'id' | 'properties'>[]
	onSelect?: (groupId: GroupId | null) => unknown
	small?: boolean
	bottomOpen?: boolean
	fullWidth?: boolean
}

const GroupSwitcher: FC<Props> = ({
	active,
	groups,
	onSelect,
	small,
	bottomOpen,
	fullWidth,
}) => {
	const [showPopup, setShowPopup] = useState<boolean>(false)

	const [next, prev] = useMemo(() => {
		const currentIndex = groups.findIndex(group => group.id === active.id)
		let next: GroupId = ''
		let prev: GroupId = ''

		if (currentIndex === 0) {
			prev = groups[groups.length - 1].id
			next = groups[currentIndex + 1].id
		} else if (currentIndex === groups.length - 1) {
			prev = groups[currentIndex - 1].id
			next = groups[0].id
		} else {
			prev = groups[currentIndex - 1].id
			next = groups[currentIndex + 1].id
		}

		return [next, prev]
	}, [active])

	const PopupContentWrapper = small ? PopupContentSmall : PopupContent

	return (
		<Switcher>
			<Inner>
				<Content
					css={css`
						cursor: ${onSelect ? 'pointer' : 'default'};
					`}
					onClick={() => setShowPopup(true)}
				>
					<ContentCircle color={active.properties.color}>
						<icons.GroupIcon />
					</ContentCircle>
					<ContentInner>
						<ContentTitle>
							<Translate>review_task.discussed_group</Translate>
						</ContentTitle>
						<ContentText>{active.properties.name}</ContentText>
					</ContentInner>
					{onSelect && (
						<ContentIcon className={'contentIcon'}>
							<icons.ChevronDown />
						</ContentIcon>
					)}
				</Content>
				{onSelect && (
					<Buttons>
						<Button onClick={() => onSelect(prev)}>
							<icons.ArrowLeft />
						</Button>
						<Button onClick={() => onSelect(next)}>
							<icons.ArrowRight />
						</Button>
					</Buttons>
				)}
			</Inner>
			{onSelect && (
				<Popup
					css={css`
						opacity: ${showPopup ? '1' : '0'};
						${!bottomOpen
							? showPopup
								? 'bottom: -10px;'
								: 'bottom: -30px;'
							: ''}
						${bottomOpen ? (showPopup ? 'top: -10px;' : 'top: -30px;') : ''}
						pointer-events: ${showPopup ? 'all' : 'none'};
					`}
					fullWidth={fullWidth}
				>
					<PopupHeader small={small}>
						<PopupHeaderContent>
							<PopupHeaderTitle>
								<intl.Translate count={groups.length}>
									review_task.switcher.active_group
								</intl.Translate>
							</PopupHeaderTitle>
							<PopupHeaderText>
								<intl.Translate>review_task.switcher.desc</intl.Translate>
							</PopupHeaderText>
						</PopupHeaderContent>
						<ui.ButtonClose onClick={() => setShowPopup(false)} />
					</PopupHeader>
					<PopupContentWrapper>
						{groups.map(group => (
							<GroupSwitcherItem
								active={active}
								group={group}
								onSelect={onSelect}
							/>
						))}
					</PopupContentWrapper>
				</Popup>
			)}
		</Switcher>
	)
}

type ItemProps = {
	active: ActiveGroup
	group: Pick<GroupReview, 'members' | 'id' | 'properties'>
	onSelect?: (groupId: GroupId | null) => unknown
}

const GroupSwitcherItem: FC<ItemProps> = ({ active, group, onSelect }) => {
	return (
		<Item>
			<ContentCircle color={group.properties.color}>
				<icons.GroupIcon />
			</ContentCircle>
			<ItemContent>
				<ItemTitle>
					<intl.Translate group={group.properties.name}>
						review_task.switcher.group
					</intl.Translate>
				</ItemTitle>
				<ItemLength>
					<intl.Translate count={group.members.length}>
						review_task.switcher.persons
					</intl.Translate>
				</ItemLength>
			</ItemContent>
			{active.id === group.id ? (
				<ui.ButtonPrimary
					color="indigoBlue"
					small
					css={css`
						min-width: 120px;
					`}
				>
					<span
						css={css`
							width: 11px;
							display: inline-block;
							margin-right: 5px;
						`}
					>
						<icons.IconChecked />
					</span>
					<intl.Translate>review_task.switcher.selected</intl.Translate>
				</ui.ButtonPrimary>
			) : (
				<ui.ButtonMinor
					css={css`
						min-width: 120px;
					`}
					small
					onClick={onSelect ? () => onSelect(group.id) : undefined}
				>
					<intl.Translate>review_task.switcher.select</intl.Translate>
				</ui.ButtonMinor>
			)}
		</Item>
	)
}

export default GroupSwitcher
