import styled from '@emotion/styled'
import { ReactNode, useState } from 'react'
import { ui } from '..'
import colors from '../colors'
import { contextMenu } from '../images'

const CONTEXT_MENU_DOTS_BUTTON_SIZE = 36

export const TooltipContextMenuIconItem = styled.div`
	cursor: pointer;
	display: flex;
	padding: 7px 14px;
	white-space: nowrap;
	font-size: 14px;
	transition: color 0.25s;
	align-items: center;

	&:first-child {
		margin-top: 7px;
	}

	&:last-child {
		margin-bottom: 7px;
	}

	img {
		height: 16px;
		margin-right: 15px;
		width: 14px;
	}

	span {
		font-size: 14px;
	}

	&:hover {
		color: ${colors.indigoBlue};

		svg,
		a {
			color: ${colors.indigoBlue};
		}
	}
`

export const TooltipContextMenuIcon = styled.div`
	display: flex;
	margin-right: 10px;

	svg {
		color: ${colors.heather};
		height: 16px;
		transition: color 0.25s;
	}
`

export const TooltipContextMenuItem = styled.div`
	padding: 10px 15px;
	cursor: pointer;
	border-bottom: 1px solid ${colors.heather30};
	font-size: 14px;
	line-height: 20px;

	&:last-child {
		border-bottom: 0;
	}

	&:hover {
		color: ${colors.indigoBlue};
	}
`

const Dots = styled.div<{ active: boolean }>`
	align-items: center;
	border-radius: 50%;
	background-color: ${props =>
		props.active ? colors.borderLightGray : 'transparent'};
	cursor: pointer;
	display: flex;
	justify-content: center;
	height: ${CONTEXT_MENU_DOTS_BUTTON_SIZE}px;
	width: ${CONTEXT_MENU_DOTS_BUTTON_SIZE}px;
	flex: 0 0 ${CONTEXT_MENU_DOTS_BUTTON_SIZE}px;
	position: relative;
	z-index: 1;

	img {
		height: 4px;
		width: 16px;
	}

	&:hover {
		background-color: ${colors.borderLightGray};
	}
`

type Props = {
	placement: string
	children: ReactNode
}

export const TooltipContextMenu = ({
	children,
	placement,
}: Props): JSX.Element => {
	const [isVisible, setIsVisible] = useState(false)

	return (
		<ui.Tooltip
			overlayClassName={'tooltipContextMenu'}
			overlay={
				<div
					data-tooltip="tooltip"
					onClick={() => setIsVisible(false)}
					style={{ zIndex: 999999 }}
				>
					{children}
				</div>
			}
			placement={placement}
			trigger={['hover']}
			onVisibleChange={val => {
				setIsVisible(val as boolean)
			}}
			visible={isVisible}
		>
			<Dots active={isVisible} onClick={() => setIsVisible(true)}>
				<img src={contextMenu} alt="menu" />
			</Dots>
		</ui.Tooltip>
	)
}
