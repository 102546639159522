import styled from '@emotion/styled'
import { SceneVideoUser } from 'video-conference-media'
import { SceneAudioUserStream } from './scene-audio-user-stream'
import { FC } from 'react'

const Container = styled.div`
	display: flex;
	flex-grow: 1;
	min-height: 0;
	width: 100%;
	height: 100%;
	padding: 20px;
`

type Props = {
	sceneVideoUsers: SceneVideoUser[]
}

const OnlyAudioGrid: FC<Props> = ({ sceneVideoUsers, children }) => {
	return (
		<Container>
			{sceneVideoUsers.map(video => (
				<SceneAudioUserStream key={video.userId} sceneVideoUser={video} />
			))}
			{children}
		</Container>
	)
}

export default OnlyAudioGrid
