import { colors, ui } from '@beelday/common'
import styled from '@emotion/styled'
import { FC } from 'react'
import { MediaDevicePermissionError } from 'utils/hooks/use-media-devices'

const Wrapper = styled(ui.Flex)<{ mandatory: boolean; error: boolean }>`
	align-items: center;
	column-gap: 16px;
	cursor: pointer;
	position: relative;
	margin-bottom: 10px;
	padding: 10px 10px 10px 10px;
	box-shadow: 0 2px 5px 0 rgba(38, 51, 77, 0.1);
	border-radius: 11px;

	${props => {
		if (props.error) {
			if (props.mandatory) {
				return `
					border: 1px solid ${colors.pinkRed};
					background-color: ${colors.pinkRed15}
				`
			} else {
				return `
					border: 1px solid ${colors.pinkRed};`
			}
		}
	}}
`

const CircleIcon = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${colors.blueGroup};
	border-radius: 50%;
	width: 32px;
	min-width: 32px;
	height: 32px;
	z-index: 1;

	svg {
		max-width: 15px;
		max-height: 16px;
		fill: ${colors.white};
	}
`

const ErrorCircle = styled.div`
	align-items: center;
	background-color: ${colors.dustyOrange};
	border-radius: 50%;
	color: ${colors.white};
	display: flex;
	height: 24px;
	justify-content: center;
	position: absolute;
	width: 24px;
`

type Props = {
	mandatory?: boolean
	permission: PermissionState
	permissionError?: MediaDevicePermissionError
	onClick?: () => void
	icon: JSX.Element
	text: string
}

export const NoDevice: FC<Props> = ({
	mandatory = false,
	permission,
	permissionError,
	icon,
	text,
	onClick,
}) => {
	return (
		<Wrapper
			onClick={onClick}
			mandatory={mandatory}
			error={!!permissionError || permission !== 'granted'}
		>
			<CircleIcon
				style={{
					backgroundColor:
						permissionError || permission !== 'granted'
							? colors.pinkRed
							: colors.blueGroup,
				}}
			>
				{(permissionError || permission !== 'granted') && (
					<div style={{ position: 'absolute', top: '-10px', right: '12px' }}>
						<ErrorCircle>
							<strong>!</strong>
						</ErrorCircle>
					</div>
				)}

				{icon}
			</CircleIcon>
			<div>{text}</div>
		</Wrapper>
	)
}
