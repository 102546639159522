import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/react'
import { RootState } from 'common/redux'
import { Colors } from 'common/presentation/colors'
import { transformedClippingBorderRadius } from 'common/presentation/cross-browser-css'
import { VideoStream } from 'video-conference-media/presentation/video-stream'
import { ellipsis } from '@beelday/common/src/styles'
import { useSelector } from 'react-redux'
import { selectUserEngagementFor } from './redux'
import UsernameWithEngagement from './username-with-engagement'
import { Username } from 'video-conference-media/presentation/username'
import { SceneVideoUser } from 'video-conference-media'
import { VideoStreamAvatarOverlay } from 'common/presentation/video-stream-avatar-overlay'

const DisplayContainer = styled.div`
	bottom: 4px;
	display: flex;
	left: 4px;
	max-width: calc(100% - 8px);
	position: absolute;
`

type Props = {
	sceneVideoUser: SceneVideoUser
}

const intro = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const boxShadow = (props: Props) => {
	if (props.sceneVideoUser.spotlight) {
		return css`
			box-shadow: 0 0 6px 6px ${Colors.brightCyan};
		`
	}
	return undefined
}

const VideoUserStreamWithEngagement = (props: Props): JSX.Element => {
	const { stream, isSelfView, userName, userId, videoMuted } =
		props.sceneVideoUser
	const score = useSelector((state: RootState) =>
		selectUserEngagementFor(state, userId)
	)
	const hasMediaStreams =
		stream?.getVideoTracks().length || stream?.getAudioTracks().length

	return hasMediaStreams ? (
		<div
			css={css`
				${boxShadow(props)};
				${transformedClippingBorderRadius('5px')};
				height: 100%;
				width: 100%;
				transition: box-shadow 100ms ease-in-out;
				background-color: ${Colors.indigoBlue};
				animation: ${intro} 300ms ease-in-out 1;
				position: relative;
			`}
		>
			<VideoStream
				css={css`
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
					${isSelfView ? 'transform: rotateY(180deg);' : ''}
				`}
				userId={userId}
			/>

			{videoMuted && <VideoStreamAvatarOverlay userId={userId} />}

			<DisplayContainer>
				{score !== undefined ? (
					<UsernameWithEngagement score={score}>
						{userName}
					</UsernameWithEngagement>
				) : (
					<Username sceneVideoUser={props.sceneVideoUser} />
				)}
			</DisplayContainer>
		</div>
	) : (
		<div
			css={css`
				${boxShadow(props)};
				${transformedClippingBorderRadius('5px')};
				height: 100%;
				width: 100%;
				transition: box-shadow 100ms ease-in-out;
				display: flex;
				align-items: center;
				justify-content: center;
				overflow: hidden;
				background-color: ${Colors.darkViolet};
				animation: ${intro} 300ms ease-in-out 1;
			`}
		>
			<div
				css={css`
					color: ${Colors.white};
					font-size: 20px;
					font-weight: 500;
					max-width: 90%;
					${ellipsis}
				`}
			>
				{userName}
			</div>
		</div>
	)
}

export default VideoUserStreamWithEngagement
