import Tooltip from 'rc-tooltip'
import { FC, ReactElement, useState } from 'react'
import colors from '../colors'
import Spinner from './spinner'
import { flexCenterAll } from '../styles'

type Props = {
	img?: string | (() => Promise<string | undefined>)
	position?: Position
	children: ReactElement
}
type Position =
	| 'top'
	| 'left'
	| 'right'
	| 'bottom'
	| 'topLeft'
	| 'topRight'
	| 'bottomLeft'
	| 'bottomRight'
	| 'leftTop'
	| 'leftBottom'
	| 'rightTop'
	| 'rightBottom'

export const PreviewTooltip: FC<Props> = ({
	img,
	children,
	position = 'topLeft',
}) => {
	const [hasError, setHasError] = useState(false)
	const [loading, setLoading] = useState(typeof img === 'function')
	const [src, setSrc] = useState<string | undefined>(
		typeof img === 'string' ? img : undefined
	)

	const fetchSrc = () => {
		if (typeof img === 'function') {
			setLoading(true)
			img()
				.then(src => {
					setSrc(src)
					setHasError(false)
				})
				.finally(() => {
					setLoading(false)
				})
		}
	}

	if (!img) return children
	return (
		<Tooltip
			overlayClassName="custom-thumb-tooltip"
			onVisibleChange={visible => {
				if (visible) fetchSrc()
			}}
			overlay={
				hasError || !src ? (
					<div
						css={flexCenterAll}
						style={{
							height: '126px',
							width: '224px',
							backgroundColor: colors.white,
						}}
					>
						{loading ? <Spinner color={colors.gray} size={32} /> : null}
					</div>
				) : (
					<img
						onError={() => setHasError(true)}
						src={src}
						style={{
							display: 'block',
							height: '126px',
							width: '224px',
							backgroundColor: colors.white,
							objectFit: 'cover',
						}}
					/>
				)
			}
			placement={position}
			overlayInnerStyle={{
				padding: 0,
				border: 'none',
			}}
			overlayStyle={
				img
					? {
							boxShadow: `0 5px 15px 0 ${colors.black15}`,
					  }
					: undefined
			}
		>
			{children}
		</Tooltip>
	)
}
