import { MaybeUserProfile } from '@beelday/common'
import { useSelector } from 'app-redux'
import {
	FocusedFeatureTypes,
	GroupId,
	GroupReview,
	ReviewUser,
	RoomAddress,
	UserId,
} from 'common/types'
import { Notepad } from 'features/notepad/notepad-models'
import ConfiguredScreenShareReview from 'features/screen-share/configured-screen-share-review'
import { Whiteboard } from 'features/whiteboard/whiteboard-models'
import Translate from 'intl/translate'
import { FC } from 'react'
import {
	ReviewNotepad,
	ReviewNotepadCollabSwitcher,
} from 'room/group-room/presentation/review-notepad'
import {
	ReviewWhiteboard,
	ReviewWhiteboardCollabSwitcher,
} from 'room/group-room/presentation/review-whiteboard'
import {
	ColumnLayout,
	ReviewContainer,
	ReviewMediaContainer,
	SmallTitle,
	Title,
	TitleContainer,
} from 'room/training-room/review-tasks/presentation/review-common'
import { SceneVideoUser } from 'video-conference-media'
import { selectFocusedFeature } from '../redux'
import MediaReview from './media-review'
import GroupSwitcher from './presentation/group-switcher'
import ReviewMediaMotionGrid from './presentation/review-media-motion-grid'
import ReviewMotionGrid from './presentation/review-motion-grid'

type Props = {
	activeMedia?: 'ETHERPAD' | 'EXCALIDRAW' | null
	activeGroup: GroupReview
	roomAddress: RoomAddress
	users: ReviewUser[]
	videoUsers: Record<UserId, SceneVideoUser>
	groups: GroupReview[]
	userProfile?: MaybeUserProfile
	onNext?: () => void
	onPrev?: () => void
	onSelect?: (groupId: GroupId | null) => unknown
	title?: string
	whiteboardDefinition?: Whiteboard
	isWhiteboardViewMode?: boolean
	notepadDefinition?: Notepad
	notepadSessionId?: string
	isTrainer?: boolean
}

const ReviewTask: FC<Props> = ({
	activeMedia,
	activeGroup,
	roomAddress,
	users,
	videoUsers,
	groups,
	userProfile,
	onSelect,
	title,
	whiteboardDefinition,
	notepadDefinition,
	notepadSessionId,
	isTrainer,
}) => {
	const focusedFeature = useSelector(selectFocusedFeature)

	return (
		<>
			{title ? (
				<TitleContainer>
					<SmallTitle>
						{title}
						<Translate>review_task.review_info</Translate>:
					</SmallTitle>
					<Title>{activeGroup.task.name}</Title>
				</TitleContainer>
			) : null}

			<ReviewContainer>
				{activeMedia ||
				notepadDefinition ||
				whiteboardDefinition ||
				focusedFeature ? (
					<ReviewMediaContainer>
						{focusedFeature?.type === FocusedFeatureTypes.ScreenShare ? (
							<ConfiguredScreenShareReview />
						) : activeMedia && !whiteboardDefinition && !notepadDefinition ? (
							<MediaReview
								address={roomAddress}
								activeMedia={activeMedia}
								group={activeGroup}
							/>
						) : null}

						{whiteboardDefinition ? (
							<ReviewWhiteboard whiteboardDefinition={whiteboardDefinition} />
						) : null}

						{notepadDefinition && notepadSessionId ? (
							<div style={{ width: '100%', height: '100%' }}>
								<ReviewNotepad
									notepadDefinition={notepadDefinition}
									sessionId={notepadSessionId}
									userId={userProfile?.id}
								/>
							</div>
						) : null}

						<ColumnLayout>
							{isTrainer && notepadDefinition ? (
								<ReviewNotepadCollabSwitcher
									notepadDefinition={notepadDefinition}
								/>
							) : null}

							{isTrainer && whiteboardDefinition ? (
								<ReviewWhiteboardCollabSwitcher
									whiteboardDefinition={whiteboardDefinition}
								/>
							) : null}

							<GroupSwitcher
								groups={groups}
								active={activeGroup}
								onSelect={onSelect}
								small
								bottomOpen
								fullWidth
							/>
							<ReviewMediaMotionGrid
								activeGroup={activeGroup}
								users={users}
								videoUsers={videoUsers}
								groups={groups}
								onSelect={onSelect}
							/>
						</ColumnLayout>
					</ReviewMediaContainer>
				) : (
					<ReviewMotionGrid
						activeGroup={activeGroup}
						users={users}
						videoUsers={videoUsers}
						groups={groups}
						onSelect={onSelect}
					/>
				)}
			</ReviewContainer>
		</>
	)
}

export default ReviewTask
