import { RefObject, useEffect, useState } from 'react'

export const useHasUnsavedInWebComponent = (
	componentRef: RefObject<HTMLElement>,
	eventName: string = 'has-unsaved'
): boolean => {
	const [hasUnsaved, setHasUnsaved] = useState(false)
	useEffect(() => {
		const hasUnsavedListener = (event: Event) => {
			setHasUnsaved(!!(event as CustomEvent).detail?.hasUnsaved)
		}

		const current = componentRef.current
		if (current) {
			current.addEventListener(eventName, hasUnsavedListener)
			return () => {
				current.removeEventListener(eventName, hasUnsavedListener)
			}
		}
	}, [componentRef, eventName])

	return hasUnsaved
}
