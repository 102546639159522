import { createSelector } from 'reselect'
import { differenceInMilliseconds, parseISO } from 'date-fns'
import { RootState } from 'common/redux'
import { GroupRoomAction } from 'room/group-room/redux'
import { clearStaleResultsAfter } from './settings'
import { GroupId, UserId } from 'common/types'

type GroupEngagementResult = {
	group: string
	group_score: number
	lower_quantile: number
	upper_quantile: number
	user_scores: Record<UserId, number>
	window_end: string
	window_start: string
}

type GroupEngagementState = Record<GroupId, GroupEngagementResult>

const groupEngagementDefaultState: GroupEngagementState = {}

type GroupEngagementAction =
	| {
			type: 'GROUP_ENGAGEMENT_RESULT'
			payload: GroupEngagementResult
	  }
	| {
			type: 'GROUP_ENGAGEMENT_CLEAR_STALE'
			payload: Date
	  }
	| GroupRoomAction

export const logGroupEngagement = (payload: GroupEngagementResult) => ({
	type: 'GROUP_ENGAGEMENT_RESULT',
	payload,
})

export const clearStaleGroupEngagement = (payload: Date) => ({
	type: 'GROUP_ENGAGEMENT_CLEAR_STALE',
	payload,
})

export function groupEngagementReducer(
	state: GroupEngagementState = groupEngagementDefaultState,
	action: GroupEngagementAction
): GroupEngagementState {
	switch (action.type) {
		case 'GROUP_ENGAGEMENT_RESULT':
			if (action.payload.group) {
				return {
					...state,
					[action.payload.group]: action.payload,
				}
			}
			return state
		case 'GROUP_ENGAGEMENT_CLEAR_STALE':
			const currentTime = action.payload
			return Object.keys(state)
				.filter(groupKey => {
					const lastReportTime = state[groupKey].window_end
					const difference = differenceInMilliseconds(
						currentTime,
						parseISO(lastReportTime)
					)
					return difference < clearStaleResultsAfter
				})
				.reduce(
					(acc, groupKey) => ({ ...acc, [groupKey]: state[groupKey] }),
					{}
				)
		case 'CLEAR_GROUP_ROOM':
			return groupEngagementDefaultState
		default:
			return state
	}
}

export const myId = createSelector(
	(state: RootState) => state.workflow.user,
	user => user?.id
)

export const myStream = createSelector(
	(state: RootState) => state.videoConferenceMedia.sceneVideoUsers,
	myId,
	(streams, id) => (id ? streams.find(s => s.userId === id)?.stream : undefined)
)

export const selectUserEngagementFor = createSelector(
	[
		(state: RootState) => state.groupEngagement,
		(_: RootState, userId: UserId) => userId,
	],
	(engagements, userId) => {
		const engagementsByUser = Object.values(engagements)
			.filter(group => group)
			.reduce(
				(acc, group) => ({ ...acc, ...group.user_scores }),
				{} as GroupEngagementResult['user_scores']
			)

		return engagementsByUser[userId]
	}
)
