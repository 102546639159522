import styled from '@emotion/styled'
import { colors } from '@beelday/common'

const Icon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="14"
		height="14"
		viewBox="0 0 14 14"
	>
		<defs>
			<clipPath id="gnvcwn4boa">
				<path
					data-name="Rectangle 4252"
					transform="translate(-.044 -.043)"
					d="M0 0h14v14H0z"
				/>
			</clipPath>
		</defs>
		<g data-name="Group 26953">
			<g data-name="Group 26952" transform="translate(.044 .043)">
				<path
					data-name="Path 12308"
					d="M13.42 12.183a1.242 1.242 0 0 1-1.238 1.237H9.657a1.242 1.242 0 0 1-1.238-1.241V8.39a1.241 1.241 0 0 1 1.238-1.238h1.282v-.44a4.234 4.234 0 0 0-8.467 0v.445H3.76A1.24 1.24 0 0 1 5 8.39v3.788a1.241 1.241 0 0 1-1.235 1.242H1.241A1.242 1.242 0 0 1 0 12.179V6.712a6.711 6.711 0 0 1 13.422 0z"
					transform="translate(.379 .377)"
					fill="currentColor"
				/>
			</g>
		</g>
	</svg>
)

const Container = styled.div<{ active: boolean }>`
	align-items: center;
	background-color: ${props => (props.active ? colors.indigo : colors.white)};
	border: solid 4px #dcd0f2;
	border-radius: 50%;
	color: ${props => (props.active ? colors.white : colors.indigo)};
	cursor: pointer;
	display: flex;
	height: 40px;
	justify-content: center;
	transition: 0.2s ease-out;
	width: 40px;
	z-index: 10;

	&:hover {
		transform: scale(1.15);
	}
`

type Props = {
	listening: boolean
	onMute: () => unknown
	onUnmute: () => unknown
}

const Eavsdrop = ({ listening, onMute, onUnmute }: Props): JSX.Element => {
	return (
		<Container
			active={listening}
			onClick={event => {
				event.stopPropagation()
				listening ? onMute() : onUnmute()
			}}
		>
			<Icon />
		</Container>
	)
}

export default Eavsdrop
