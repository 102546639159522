import { colors, intl, ui } from '@beelday/common'
import { useToggle } from '@beelday/common/src/hooks'
import styled from '@emotion/styled'
import { useSelector } from 'app-redux'
import { Config } from 'common/config'
import { Colors } from 'common/presentation/colors'
import { RootState } from 'common/redux'
import MicrophoneIcon from 'features/microphone/microphone-icon'
import { useOrganizationBranding } from 'organization/use-organization-branding'
import { FC, useCallback, useEffect, useState } from 'react'
import { CameraOn } from 'room/common/presentation/icon/camera-on'
import {
	DeviceKind,
	MediaDevicePermissionError,
	useMediaDevices,
} from 'utils/hooks/use-media-devices'
import { CameraUsedInOtherApp, DevicePopup } from './hardware-popup'
import { NoDevice } from './no-device'
import {
	SafariNotSupportedInfo,
	useDetectBrowser,
} from './safari-not-supported-info'
import SelectDevice from './select-device'
import VideoPreview from './video-preview'
import whatToDo from './images/what_to_do.png'
import whatToDoEdge from './images/what_to_do_edge.png'

type Props = {
	onEnter: () => void
}

const ContentWrapper = styled.div`
	display: flex;
	width: 100%;
	column-gap: 40px;
`

const Content = styled.div`
	background: ${Colors.white};
	border-radius: 20px;
	box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
	padding: 40px;
	max-width: 540px;
	width: 100%;
	flex-grow: 1;
	margin: auto auto;
`

const JoinButton = styled.div`
	text-align: right;
	margin-top: 40px;
`

type PopupReason = {
	kind: DeviceKind
	permission: PermissionState | undefined
	permissionError: MediaDevicePermissionError | undefined
}

const HardwareCheck: FC<Props> = ({ onEnter }) => {
	const translate = intl.useTranslate()
	const {
		availableCameras,
		availableMicrophones,
		selectCamera,
		selectedMicrophone,
		selectMicrophone,
		stream,
		startStream,
		stopStream,
		selectedCamera,
		permissionsGranted,
		askForPermission,
		error,
		permissionErrors,
		syncPermissions,
	} = useMediaDevices()

	const [popupReason, setPopupReason] = useState<PopupReason>()
	const { value: popupVisible, setOn, setOff } = useToggle()

	const handleAskForPermissionClick = useCallback(
		(kind: DeviceKind) => {
			askForPermission(kind).then(res => {
				setPopupReason({
					kind: kind,
					permission: res.permission,
					permissionError: res.permissionError,
				})
				if (kind === 'CAMERA_DEVICE') {
					if (res.permission !== 'granted') {
						return
					} else if (!res.permissionError) {
						setOff()
					}
				} else {
					if (res.permission !== 'granted') {
						return
					} else if (!res.permissionError) {
						setOff()
					}
				}
			})
		},
		[askForPermission, setOff]
	)

	const handleDeviceClick = useCallback(
		(kind: DeviceKind) => {
			setPopupReason({
				kind: kind,
				permission:
					kind === 'CAMERA_DEVICE'
						? permissionsGranted?.camera
						: permissionsGranted?.microphone,
				permissionError:
					kind === 'CAMERA_DEVICE'
						? permissionErrors?.camera
						: permissionErrors?.microphone,
			})
			setOn()
		},
		[
			permissionErrors?.camera,
			permissionErrors?.microphone,
			permissionsGranted?.camera,
			permissionsGranted?.microphone,
			setOn,
		]
	)

	useEffect(() => {
		syncPermissions().then(() => askForPermission())
	}, [askForPermission, syncPermissions])

	useEffect(() => {
		const defaultCamera = availableCameras.length
			? availableCameras[0].deviceId
			: undefined

		if (!selectedCamera && defaultCamera) {
			selectCamera(defaultCamera)
		}
	}, [availableCameras, selectedCamera, selectCamera])

	useEffect(() => {
		const defaultMicrophone = availableMicrophones.length
			? availableMicrophones[0].deviceId
			: undefined

		if (!selectedMicrophone && defaultMicrophone) {
			selectMicrophone(defaultMicrophone)
		}
	}, [availableMicrophones, selectedMicrophone, selectMicrophone])

	useEffect(() => {
		startStream()

		return () => {
			stopStream()
		}
	}, [selectedCamera, startStream, stopStream, askForPermission])

	const training = useSelector((state: RootState) => state.sessionInfo.training)

	const browser = useDetectBrowser()
	const branding = useOrganizationBranding(training?.organizationId)

	return permissionsGranted ? (
		<ContentWrapper>
			<ui.Flex style={{ width: '50%', margin: 'auto' }}>
				<Content>
					<>
						<VideoPreview stream={stream} />
						{availableCameras.length > 0 ? (
							<SelectDevice
								devices={availableCameras}
								selected={selectedCamera}
								label={
									<intl.Translate>hardware_check.select_camera</intl.Translate>
								}
								icon={<CameraOn />}
								onChange={selectCamera}
							/>
						) : (
							<NoDevice
								permission={permissionsGranted.camera}
								permissionError={permissionErrors?.camera}
								onClick={() => handleDeviceClick('CAMERA_DEVICE')}
								icon={<CameraOn />}
								text={translate(
									permissionsGranted.camera !== 'granted'
										? 'hardware_check.select_camera.no_permission'
										: 'hardware_check.select_camera.none'
								)}
							/>
						)}

						{availableMicrophones.length > 0 ? (
							<SelectDevice
								devices={availableMicrophones}
								selected={selectedMicrophone}
								label={
									<intl.Translate>
										hardware_check.select_microphone
									</intl.Translate>
								}
								icon={<MicrophoneIcon />}
								onChange={selectMicrophone}
							/>
						) : (
							<NoDevice
								mandatory
								permission={permissionsGranted.microphone}
								permissionError={permissionErrors?.microphone}
								onClick={() => handleDeviceClick('MICROPHONE_DEVICE')}
								icon={<MicrophoneIcon />}
								text={translate(
									permissionsGranted.microphone !== 'granted'
										? 'hardware_check.select_microphone.no_permission'
										: 'hardware_check.select_microphone.none'
								)}
							/>
						)}

						{permissionsGranted.microphone !== 'granted' && (
							<span style={{ marginBottom: '10px' }}>
								<intl.Translate>
									hardware_check.select_microphone.obligatory
								</intl.Translate>
							</span>
						)}

						{browser === 'Safari' && <SafariNotSupportedInfo />}

						<JoinButton>
							<ui.ButtonPrimary
								disabled={permissionsGranted.microphone !== 'granted'}
								onClick={onEnter}
								data-testid="session.device.confirm"
							>
								<intl.Translate>hardware_check.join</intl.Translate>
							</ui.ButtonPrimary>
						</JoinButton>
					</>
					{popupVisible && (
						<DevicePopup onClose={setOff}>
							{popupReason?.kind === 'CAMERA_DEVICE' ? (
								error.deviceErrors.camera === 'DEVICE_IS_IN_USE' ? (
									<CameraUsedInOtherApp
										onAskForPermission={() => askForPermission('CAMERA_DEVICE')}
									/>
								) : (
									<ui.FlexColumn style={{ rowGap: '20px' }}>
										{popupReason?.permission === 'denied' ? (
											<ui.FlexColumn
												style={{
													maxWidth: '400px',
													rowGap: '20px',
													alignItems: 'center',
												}}
											>
												<img
													src={browser === 'Edge' ? whatToDoEdge : whatToDo}
													style={{
														width: '360px',
													}}
												/>
												<ui.Flex
													style={{
														padding: '20px',
														borderRadius: '11px',
														backgroundColor: colors.pinkRed15,
														columnGap: '10px',
													}}
												>
													<ui.FlexCenterAll
														style={{
															borderRadius: '50%',
															backgroundColor: colors.pinkRed,
															height: '32px',
															width: '32px',
															minWidth: '32px',
															color: colors.white,
															fontWeight: 'bolder',
														}}
													>
														!
													</ui.FlexCenterAll>
													<intl.Translate>
														hardware_check.select_camera.cant_access
													</intl.Translate>
												</ui.Flex>

												<ui.ButtonPrimary
													onClick={() =>
														handleAskForPermissionClick('CAMERA_DEVICE')
													}
												>
													<intl.Translate>
														hardware_check.device.permission_granted
													</intl.Translate>
												</ui.ButtonPrimary>
											</ui.FlexColumn>
										) : (
											<>
												<div>
													<intl.Translate>
														hardware_check.select_camera.popup.description_1
													</intl.Translate>
												</div>
												<div>
													<intl.Translate>
														hardware_check.select_camera.popup.description_2
													</intl.Translate>
												</div>

												<ui.ButtonPrimary
													onClick={() =>
														handleAskForPermissionClick('CAMERA_DEVICE')
													}
												>
													<intl.Translate>
														hardware_check.select_camera.use
													</intl.Translate>
												</ui.ButtonPrimary>
											</>
										)}
									</ui.FlexColumn>
								)
							) : (
								<ui.FlexColumn style={{ rowGap: '20px' }}>
									{popupReason?.permission === 'denied' ? (
										<ui.FlexColumn
											style={{
												maxWidth: '400px',
												rowGap: '20px',
												alignItems: 'center',
											}}
										>
											<img
												src={browser === 'Edge' ? whatToDoEdge : whatToDo}
												style={{
													width: '360px',
												}}
											/>
											<ui.Flex
												style={{
													padding: '20px',
													borderRadius: '11px',
													backgroundColor: colors.pinkRed15,
													columnGap: '10px',
												}}
											>
												<ui.FlexCenterAll
													style={{
														borderRadius: '50%',
														backgroundColor: colors.pinkRed,
														height: '32px',
														width: '32px',
														minWidth: '32px',
														color: colors.white,
														fontWeight: 'bolder',
													}}
												>
													!
												</ui.FlexCenterAll>
												<intl.Translate>
													hardware_check.select_microphone.cant_access
												</intl.Translate>
											</ui.Flex>
											<ui.ButtonPrimary
												onClick={() =>
													handleAskForPermissionClick('MICROPHONE_DEVICE')
												}
											>
												<intl.Translate>
													hardware_check.device.permission_granted
												</intl.Translate>
											</ui.ButtonPrimary>
										</ui.FlexColumn>
									) : (
										<>
											<div>
												<intl.Translate>
													hardware_check.select_microphone.popup.description_1
												</intl.Translate>
											</div>
											<div>
												<intl.Translate>
													hardware_check.select_microphone.popup.description_2
												</intl.Translate>
											</div>
											<ui.ButtonPrimary
												onClick={() =>
													handleAskForPermissionClick('MICROPHONE_DEVICE')
												}
											>
												<intl.Translate>
													hardware_check.select_microphone.use
												</intl.Translate>
											</ui.ButtonPrimary>
										</>
									)}
								</ui.FlexColumn>
							)}
						</DevicePopup>
					)}
				</Content>
			</ui.Flex>
			{branding?.heroPhotoId && (
				<ui.Flex style={{ width: '50%' }}>
					<ui.CompanyHeroPhoto
						width="100%"
						height="100%"
						userApiUrl={Config.USER_API_URL}
						photoId={branding?.heroPhotoId}
						style={{
							backgroundColor: '#f8f8fb',
						}}
					/>
				</ui.Flex>
			)}
		</ContentWrapper>
	) : null
}

export default HardwareCheck
