import { PropsOf, css } from '@emotion/react'
import styled from '@emotion/styled'
import { map, times } from 'lodash'
import { ChangeEventHandler, FC, useEffect, useMemo, useState } from 'react'
import { HexColorPicker } from 'react-colorful'
import { useLocalStorage } from 'usehooks-ts'
import colors from '../colors'
import { decodeHexColor } from '../styles'
import { GlobalModalPicker, ModalPicker } from './modal'
import { Panel } from './panel'
import { CancelIcon } from '../icons'

const Square = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	min-width: 32px;
	min-height: 32px;
	margin: 3px;
	border-radius: 4px;
	cursor: pointer;
	color: ${colors.heather};
	svg {
		width: 12px;
		height: 12px;
	}
`
const LIGHT_COLOR_THRESHOLD = 200 * 200
const ColorSquare: FC<
	{ color: string | null; selected: boolean } & Omit<
		PropsOf<typeof Square>,
		'color'
	>
> = ({ color, selected, ...rest }) => {
	const isLightColor = useMemo(() => {
		if (!color) return true
		const [r, g, b] = decodeHexColor(color)
		const hsp = 0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)
		return hsp > LIGHT_COLOR_THRESHOLD
	}, [color])

	const borderColor = isLightColor ? colors.heather : color

	return (
		<Square
			{...rest}
			style={{
				backgroundColor: color || 'transparent',
				border: `2px solid ${selected ? colors.indigoBlue : borderColor}`,
				borderWidth: `${selected ? 2 : 1}px`,
			}}
		/>
	)
}

const CustomColorInputWrapper = styled.div`
	display: flex;
	border-radius: 4px;
	border: 2px solid #e8e8e8;
	overflow: hidden;
	height: 32px;
	margin: 3px;
	flex: 1;
	min-width: 6em;
`
const CustomColorInputMarker = styled.div`
	flex: 0;
	background-color: #e8e8e8;
	min-width: 28px;
	min-height: 28px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #bbb;
`
const CustomColorInput = styled.input`
	padding: 6px 10px;
	color: ${colors.black};
	border: none;
	min-width: 5em;
`

const STORE_LAST_COLORS = 10
const VALID_VALUE = /^[0-9a-fA-F]*$/
const DEFAULT_PRESETS: string[] = [
	'#bb56d6',
	'#fc6920',
	'#063d66',
	'#fdb92e',
	'#22d084',
	'#1b93e3',
	'#abb8c3',
	'#eb154c',
	'#f78ca7',
]
type Props = {
	presets?: string[]
	selected?: string | null
	showLast?: number
	onClose: () => unknown
	pointAndClick?: boolean
	storeLastUsed?: boolean
	className?: string
	style?: React.CSSProperties
	global?: boolean
	position?: 'left' | 'right'
	showRemove?: boolean
	onChange: (color: string | null) => unknown
}
export const ColorPicker: FC<Props> & { defaultPresets: string[] } = ({
	presets = DEFAULT_PRESETS,
	selected,
	onClose,
	onChange,
	pointAndClick,
	showLast,
	className,
	style,
	global,
	position,
	showRemove,
}) => {
	const [customColor, setCustomColor] = useState('')
	const [lastColors, updateLastColors] = useLocalStorage(
		'beelday-last-colors',
		[] as string[]
	)

	useEffect(() => {
		if (selected) {
			setCustomColor(selected.substring(1))
		}
	}, [selected])
	const isValid = customColor.length >= 6

	const handleCustomColorChange: ChangeEventHandler<HTMLInputElement> = e => {
		const { value } = e.target
		if (VALID_VALUE.test(value)) {
			setCustomColor(value)
		}
	}

	const handleCustomColor = () => {
		if (isValid) {
			const color = '#' + customColor

			if (!lastColors.includes(color) && !presets.includes(color)) {
				const lastColorsCopy = [...lastColors]
				lastColorsCopy.push(color)
				if (lastColorsCopy.length > STORE_LAST_COLORS) {
					lastColorsCopy.splice(0, lastColorsCopy.length - STORE_LAST_COLORS)
				}
				updateLastColors(lastColorsCopy)
			}
			onChange(color)
			onClose()
		}
	}

	const ModalWrapper = global ? GlobalModalPicker : ModalPicker

	return (
		<ModalWrapper
			onClose={() => {
				if (customColor) {
					handleCustomColor()
				}
				onClose()
			}}
			className={className}
			style={style}
			position={position}
		>
			<Panel
				style={{
					padding: '15px',
					display: 'flex',
					flexWrap: 'wrap',
					width: '296px',
				}}
			>
				{showRemove ? (
					<ColorSquare
						key="remove-color"
						color={null}
						selected={false}
						onClick={() => {
							onChange(null)
							onClose()
						}}
					>
						<CancelIcon />
					</ColorSquare>
				) : null}
				{map(presets, color => (
					<ColorSquare
						key={color}
						color={color}
						selected={color === selected}
						onClick={() => {
							onChange(color)
							onClose()
						}}
					/>
				))}
				{times(showLast || 0, i => {
					const savedColor =
						lastColors[lastColors.length - ((showLast || 0) - i)]
					return (
						<ColorSquare
							key={`${i}${savedColor}`}
							color={savedColor || 'transparent'}
							selected={savedColor != null && savedColor === selected}
							onClick={() => {
								if (savedColor) {
									onChange(savedColor)
									onClose()
								}
							}}
						/>
					)
				})}
				<CustomColorInputWrapper>
					<CustomColorInputMarker>#</CustomColorInputMarker>
					<CustomColorInput
						autoFocus={true}
						maxLength={6}
						minLength={6}
						value={customColor || ''}
						onChange={handleCustomColorChange}
						style={{ color: isValid ? colors.black : colors.red }}
						onKeyDown={e => {
							if (e.key === 'Enter') handleCustomColor()
						}}
					/>
				</CustomColorInputWrapper>
				{pointAndClick ? (
					<PointAndClickPicker
						color={null}
						// color={isValid ? customColor : selected}
						onChange={value => {
							setCustomColor(value)
							onChange(value)
						}}
					/>
				) : null}
			</Panel>
		</ModalWrapper>
	)
}
ColorPicker.defaultPresets = DEFAULT_PRESETS

const PointAndClickPicker = ({
	color,
	onChange,
}: {
	color?: string | null
	onChange: (color: string) => unknown
}) => (
	<div
		css={css`
			margin-top: 11px;
			width: 100%;
			.react-colorful {
				height: 200px;
				width: 100%;
			}
		`}
	>
		<HexColorPicker color={color || undefined} onChange={onChange} />
	</div>
)
