export const AttachmentIcon: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="17.144"
		height="17.5"
		viewBox="0 0 17.144 17.5"
	>
		<g>
			<path
				fill="none"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5px"
				d="M1861.675 760.065l-3.537 3.337a1.867 1.867 0 0 0 0 2.748 2.149 2.149 0 0 0 2.913 0l4.785-4.515a3.733 3.733 0 0 0 0-5.5 4.3 4.3 0 0 0-5.826 0l-5.2 4.909a5.6 5.6 0 0 0 0 8.245 6.445 6.445 0 0 0 8.739 0l4.785-4.515"
				transform="translate(-1852.25 -754.25)"
			/>
		</g>
	</svg>
)
