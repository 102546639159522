import { intl, ui } from '@beelday/common'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { Colors } from 'common/presentation/colors'
import { GroupPartitionMap, GroupsOfSize } from 'common/types'
import { usePreloaded } from 'connectivity/preload'
import { FunctionComponent, useState } from 'react'
import { HeadingP } from './heading'
import GroupProgress from './images/group-progress-2.svg'
import { ToolTipP } from './tool-tip'

const GroupSelectionProgressBackground = styled.div`
	background: url(${GroupProgress});
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 20px;
	margin-top: 22px;
	margin-bottom: 10px;
	width: 100%;
`

const Button = styled.button`
	border-style: solid;
	border-color: ${Colors.indigoBlue};
	border-width: 4px;
	border-radius: 20px;
	background-color: white;
	padding: 0px;
	font-family: Ubuntu;
	font-size: 16px;
	height: 60px;
	width: 60px;
	margin: 0px 10px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	cursor: pointer;
	color: ${Colors.indigoBlue};

	transition-duration: 0.2s;

	&:hover {
		border-color: #e7e7f0;
		background-color: #e7e7f0;
	}

	&:focus {
		border-color: ${Colors.indigoBlue};
		background-color: ${Colors.indigoBlue};
		color: white;
		outline: none;
	}
`

const fadeInAnimation = keyframes`
	0% { opacity: 0; }
	100% { opacity: 1; }
`

const InfoText = styled.p<{ empty?: boolean }>`
	animation-name: ${fadeInAnimation};
	animation-duration: 0.3s;

	border-radius: 10px;
	background-color: ${({ empty }) => (empty ? 'transparent' : '#e7e7f0')};
	border: ${({ empty }) =>
		empty ? 'solid 3px transparent' : 'solid 3px #e7e7f0'};
	min-width: 65%;
	text-align: center;
	padding: 8px;
	margin: 10px 0 0 0;
	font-size: 12px;
	line-height: 1.56;
	color: #5e5e6e;
`

const Info: FunctionComponent<{ hoveredGroups: GroupsOfSize[] | undefined }> =
	({ hoveredGroups }) =>
		hoveredGroups ? (
			<InfoText>
				<intl.Translate>we-will-create</intl.Translate>
				{hoveredGroups.map((group, index) => (
					<span key={index}>
						{index >= 1 ? (
							<>
								{' '}
								<intl.Translate>and</intl.Translate>{' '}
							</>
						) : null}
						<strong>
							<intl.Translate
								groupsCount={group.groupsCount}
								groupsSize={group.groupsSize}
							>
								users-per-group
							</intl.Translate>
						</strong>
					</span>
				))}
				{'. '}
			</InfoText>
		) : (
			<InfoText empty={true}>&nbsp;</InfoText>
		)

type Props = {
	onFinishSelectingMaxGroupSize(
		maxGroupSize: number,
		groups: GroupsOfSize[]
	): void
	groupPartitionMap: GroupPartitionMap
}

export const GroupPickSize: React.FC<Props> = ({
	groupPartitionMap,
	onFinishSelectingMaxGroupSize,
}) => {
	const [hoveredGroups, setHoveredGroups] = useState<GroupsOfSize[]>()
	const preloaded = usePreloaded([GroupProgress])

	if (!preloaded) {
		return null
	}
	return (
		<ui.FlexColumnCenter>
			<HeadingP>
				<intl.Translate>group-people</intl.Translate>
			</HeadingP>
			<GroupSelectionProgressBackground />
			<ToolTipP>
				<intl.Translate>decide-groups-size</intl.Translate>
			</ToolTipP>
			<ui.FlexCenterAll style={{ marginTop: '4px' }}>
				{groupPartitionMap.map(groupPartition => (
					<Button
						key={groupPartition.maxGroupSize}
						onMouseOver={() => setHoveredGroups(groupPartition.groups)}
						onMouseOut={() => setHoveredGroups(undefined)}
						onClick={() =>
							onFinishSelectingMaxGroupSize(
								groupPartition.maxGroupSize,
								groupPartition.groups
							)
						}
					>
						{groupPartition.maxGroupSize}
					</Button>
				))}
			</ui.FlexCenterAll>
			<Info hoveredGroups={hoveredGroups} />
		</ui.FlexColumnCenter>
	)
}

export default GroupPickSize
