import { FC } from 'react'
import { times } from 'lodash'
import { UserProfile } from 'common/types'
import styled from '@emotion/styled'
import { colors, intl } from '@beelday/common'
import ProfileAvatar from 'users/profile-avatar'
import { Progress } from '../knowledge-check'
import KnowledgeCheckTimer from './knowledge-check-timer'

const Container = styled.div`
	align-items: center;
	display: flex;
	padding: 10px 30px 10px 10px;
	position: relative;
`

const ShadowContainer = styled.div`
	align-items: center;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	display: flex;
	padding: 10px 30px 10px 10px;
	position: relative;
`

const GrayPath = styled.div`
	background-color: ${colors.brightGray};
	clip-path: polygon(0 0, 30% 0, 100% 100%, 0% 100%);
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 54px;
`

const AvatarWrapper = styled.div`
	height: 64px;
	position: relative;
	width: 64px;
	z-index: 1;
`

const Content = styled.div`
	padding-left: 12px;
	display: flex;
	justify-content: space-between;
	width: 100%;
`

const ContentSubTitle = styled.div`
	color: ${colors.heather};
	font-size: 14px;
	font-weight: 500;
	line-height: 26px;
	text-transform: uppercase;
`

const ContentTitle = styled.div`
	font-size: 18px;
	font-weight: 700;
	line-height: 26px;
`

const ProgressBar = styled.div`
	display: flex;
	margin: 0 -1px;
`

const ProgressBarItem = styled.div<{ active: boolean }>`
	background-color: ${colors.borderGray50};
	flex: 1;
	height: 6px;
	margin: 0 1px;
	position: relative;

	&:before {
		background-color: ${colors.greenishCyan};
		content: '';
		position: absolute;
		left: 0;
		height: 100%;
		width: ${props => (props.active ? '0' : '100%')};
		transition: width 0.25s;
	}
`

type Props = {
	title: string
	progress?: Progress
	userProfile: UserProfile | undefined | null
	maxDurationInSeconds?: number
	startTime?: Date
}

const KnowledgeCheckHeader: FC<Props> = ({
	title,
	userProfile,
	progress,
	maxDurationInSeconds,
	startTime,
}) => {
	const showTimer = maxDurationInSeconds && startTime

	return (
		<>
			{progress ? (
				<Container>
					<GrayPath />
					<AvatarWrapper>
						<ProfileAvatar profile={userProfile} />
					</AvatarWrapper>
					<Content>
						<div>
							<ContentSubTitle>
								<intl.Translate>knowledge_check_header</intl.Translate>
							</ContentSubTitle>
							<ContentTitle>{title}</ContentTitle>
						</div>
						{showTimer ? (
							<KnowledgeCheckTimer
								startTime={startTime}
								maxDurationInSeconds={maxDurationInSeconds}
							/>
						) : null}
					</Content>
				</Container>
			) : (
				<ShadowContainer>
					<GrayPath />
					<AvatarWrapper>
						<ProfileAvatar profile={userProfile} />
					</AvatarWrapper>
					<Content>
						<div>
							<ContentSubTitle>
								<intl.Translate>knowledge_check_header</intl.Translate>
							</ContentSubTitle>
							<ContentTitle>{title}</ContentTitle>
						</div>
						{showTimer ? (
							<KnowledgeCheckTimer
								startTime={startTime}
								maxDurationInSeconds={maxDurationInSeconds}
							/>
						) : null}
					</Content>
				</ShadowContainer>
			)}

			{progress ? (
				<ProgressBar>
					{times(progress.maxValue, index => (
						<ProgressBarItem
							key={index}
							active={progress.currentValue <= index}
						/>
					))}
				</ProgressBar>
			) : null}
		</>
	)
}

export default KnowledgeCheckHeader
