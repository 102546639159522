import { FC } from 'react'
import styled from '@emotion/styled'
import { Colors } from 'common/presentation/colors'
import { ui } from '@beelday/common'

const verticalStepSize = 14
const horizontalStepSize = 25

const FlexContainer = styled.div`
	width: 100%;
	position: relative;
`

const Nearest = styled.div`
	width: 100%;
	height: 100%;
	background-color: ${Colors.white};
	border-radius: 20px;
	position: absolute;
	padding: 10px 10px 10px 15px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
	-webkit-background-clip: padding-box;
	background-clip: padding-box;

	${ui.responsive.desktop1440} {
		padding: 30px 40px;
		height: calc(100% - ${verticalStepSize * 2}px);
	}
`

const Further = styled.div`
	width: calc(100% - ${horizontalStepSize}px);
	height: calc(100% - ${verticalStepSize}px);
	background-color: ${Colors.white};
	border-radius: 20px;
	position: absolute;
	left: ${horizontalStepSize / 2}px;
	top: 0;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
	opacity: 70%;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
`

const Furthest = styled.div`
	width: calc(100% - ${horizontalStepSize * 2}px);
	height: 100%;
	background-color: ${Colors.white};
	border-radius: 20px;
	position: absolute;
	left: ${horizontalStepSize}px;
	top: 0;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
	opacity: 50%;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
`

const ApproachingContainer: FC = ({ children }) => {
	return (
		<FlexContainer>
			<Furthest />
			<Further />
			<Nearest>{children}</Nearest>
		</FlexContainer>
	)
}

export default ApproachingContainer
