export const ImageIcon: React.FC = () => (
	<svg
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 21 17.2"
		xmlSpace="preserve"
		width="20px"
		height="16px"
	>
		<g transform="translate(0 0)">
			<g>
				<path
					d="M18.1,0H2.9C1.3,0,0,1.3,0,2.9v11.4c0,1.6,1.3,2.9,2.9,2.9h15.2c1.6,0,2.9-1.3,2.9-2.9V2.9C21,1.3,19.7,0,18.1,0z
M19,14.3c0,0.5-0.4,0.9-0.9,0.9H2.9c-0.1,0-0.2,0-0.3,0l5-3.9l1.8,1.6c0.4,0.3,0.9,0.4,1.3,0.1l5.5-4.3l2.9,2.7V14.3z M19,8.6
l-1.5-1.4c-0.7-0.7-1.8-0.7-2.6-0.1l-4.9,3.8L8.9,9.8C8.2,9.2,7.1,9.1,6.4,9.7L2,13.3V2.9c0-0.5,0.4-0.9,0.9-0.9h15.2
c0.2,0,0.5,0.1,0.7,0.3C19,2.4,19,2.6,19,2.9V8.6z"
					fill="currentColor"
				/>
				<path
					d="M7.8,3.7c-0.5-0.5-1.2-0.8-2-0.8c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9c1.6,0,2.9-1.3,2.9-2.9
C8.6,4.9,8.3,4.2,7.8,3.7z M5.7,6.7c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9S6.2,6.7,5.7,6.7z"
					fill="currentColor"
				/>
			</g>
		</g>
	</svg>
)
