import {
	PopupMenu,
	PopupMenuElement,
	PopupNestedMenu,
} from '../../common/presentation/menu'
import { colors, icons, intl, ui, UUID } from '@beelday/common'
import { useNotepads } from 'features/notepad'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'common/redux'
import { ReadOnlyNotepadId } from 'features/notepad/notepad-models'
import { css } from '@emotion/react'
import { useFormik } from 'formik'

type ValidationErrors = {
	name?: 'field.required'
}

type Props = {
	activeNotepadId?: UUID
	withFocus: boolean
	onClose?: () => unknown
	onOpen?: () => unknown
	child?: JSX.Element
}

export const NotepadMenu = ({
	activeNotepadId,
	withFocus,
	onClose,
	onOpen,
	child,
}: Props): JSX.Element => {
	const translate = intl.useTranslate()
	const [showForm, setShowForm] = useState(false)
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

	const { createNotepad, openNotepad, closeNotepad } = useNotepads()

	const notepads = useSelector((state: RootState) => state.notepads.notepads)

	const empty: { name: string } = {
		name: translate('untitled', { count: notepads.length + 1 }),
	}

	const formik = useFormik({
		validate: values => {
			const errors: ValidationErrors = {}

			if (!values.name) {
				errors.name = 'field.required'
			}

			return errors
		},
		initialValues: empty,
		onSubmit: values => {
			createNotepad(values.name, withFocus).finally(() => {
				formik.setSubmitting(false)
				setShowForm(false)
				setIsSubmitted(false)
				formik.setFieldValue('name', '')

				if (onOpen) {
					onOpen()
				}
			})
		},
	})

	const open = useCallback(
		(notepadId: ReadOnlyNotepadId) => {
			openNotepad(notepadId, withFocus).then(() => {
				if (onOpen) {
					onOpen()
				}
			})
		},
		[onOpen, openNotepad, withFocus]
	)

	const close = useCallback(() => {
		if (activeNotepadId) {
			closeNotepad(activeNotepadId).then(() => {
				if (onClose) {
					onClose()
				}
			})
		}
	}, [activeNotepadId, closeNotepad, onClose])

	return (
		<>
			<PopupMenu>
				<>
					{notepads.length ? (
						<PopupMenuElement
							text={translate('features.notepad.menu.select')}
							icon={<icons.Excalidraw />}
						>
							<PopupNestedMenu>
								<>
									{notepads.map(notepad => (
										<PopupMenuElement
											key={notepad.readOnlyNotepadId}
											text={notepad.notepadName}
											onClick={() => open(notepad.readOnlyNotepadId)}
											active={activeNotepadId === notepad.readOnlyNotepadId}
										/>
									))}
								</>
							</PopupNestedMenu>
						</PopupMenuElement>
					) : null}

					<PopupMenuElement
						text={translate('features.notepad.menu.add')}
						onClick={() => setShowForm(true)}
					/>
					{child}

					{activeNotepadId ? (
						<PopupMenuElement
							style={{ color: colors.pinkRed, fontWeight: 'bolder' }}
							text={translate('features.notepad.menu.off')}
							onClick={close}
						/>
					) : null}
				</>
			</PopupMenu>
			{showForm ? (
				<ui.Modal
					backgroundColor={colors.transparentPaleGray}
					onClose={() => setShowForm(false)}
				>
					<ui.ModalContainer
						css={css`
							width: 620px;
						`}
					>
						<ui.ModalHeader
							title={
								<intl.Translate>features.notepad.menu.create</intl.Translate>
							}
							onClose={() => setShowForm(false)}
						/>
						<ui.ModalContent>
							<ui.FlexColumn style={{ width: '100%' }}>
								<ui.InputLabeled
									label={
										<intl.Translate>
											features.notepad.menu.input.label
										</intl.Translate>
									}
								>
									<ui.Input
										id="name"
										name="name"
										placeholder={translate(
											'features.notepad.menu.input.placeholder'
										)}
										value={formik.values.name}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										incorrect={!!formik.errors.name && isSubmitted}
									/>
									{formik.errors.name && isSubmitted && (
										<ui.ErrorPlaceholder>
											<intl.Translate>{formik.errors.name}</intl.Translate>
										</ui.ErrorPlaceholder>
									)}
								</ui.InputLabeled>
								<ui.FlexSpaceBetween
									style={{ width: '100%', marginTop: '50px' }}
								>
									<ui.ButtonMinor onClick={() => setShowForm(false)}>
										<intl.Translate>cancel</intl.Translate>
									</ui.ButtonMinor>
									<ui.ButtonPrimary
										onClick={() => {
											formik.handleSubmit()
											setIsSubmitted(true)
										}}
										type="button"
										disabled={formik.isSubmitting}
									>
										<intl.Translate>
											features.notepad.menu.continue
										</intl.Translate>
									</ui.ButtonPrimary>
								</ui.FlexSpaceBetween>
							</ui.FlexColumn>
						</ui.ModalContent>
					</ui.ModalContainer>
				</ui.Modal>
			) : null}
		</>
	)
}
