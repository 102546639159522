import styled from '@emotion/styled'
import colors from '../colors'
import responsive from './responsive'
import Welcome from './welcome'

export const FormContainer = styled.div`
	display: flex;
	z-index: 5;
	justify-content: center;
	margin-bottom: auto;
	flex: 1;

	${responsive.tablet} {
		flex: 1;
		align-items: center;
		margin: 30px;
	}
`

export const LogoContainer = styled.div`
	padding: 40px;
	margin: auto auto;
	display: flex;
	flex: 0;
	justify-content: center;
	align-items: center;

	${responsive.desktop} {
		padding: 40px;
		margin: auto auto;
		display: flex;
		flex: 1;
		justify-content: center;
		align-items: center;
	}
`

const PageContainer = styled.div`
	min-width: 100vw;
	max-width: 100vw;
	min-height: 100vh;
	min-height: 100dvh;
	max-height: 100vh;
	background: linear-gradient(to top, #f0f1f3, #ffffff);
	display: flex;
	align-items: center;
	flex-direction: column;
	overflow-y: auto;
	overflow-x: hidden;

	@supports (-webkit-touch-callout: none) {
		min-height: -webkit-fill-available;
	}

	${responsive.tablet} {
		flex-direction: row;
		justify-content: space-between;
		align-items: stretch;
		background: ${colors.indigoBlue};
	}
`

export const WelcomeScreen: React.FC<{
	logo?: React.ReactElement
	background: string
}> = ({ logo, background, children }) => {
	return (
		<PageContainer style={{ background: background }}>
			{logo ? <LogoContainer>{logo}</LogoContainer> : <Welcome />}

			<FormContainer>{children}</FormContainer>
		</PageContainer>
	)
}

export default WelcomeScreen
