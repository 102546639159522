export const ResendIcon: React.FC = () => (
	<svg
		id="Group_27042"
		data-name="Group 27042"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		width="16"
		height="16"
		viewBox="0 0 16 16"
	>
		<defs>
			<rect
				id="Rectangle_4269"
				data-name="Rectangle 4269"
				width="16"
				height="16"
				fill="currentColor"
			/>
		</defs>
		<g id="Group_27042-2" data-name="Group 27042">
			<path
				id="Path_12326"
				data-name="Path 12326"
				d="M16,8a8,8,0,1,0-8,8V14.345a6.343,6.343,0,1,1,3.587-1.1V9.931H9.931v5.242a.828.828,0,0,0,.828.827H16V14.345H12.858A7.985,7.985,0,0,0,16,8Z"
				fill="currentColor"
			/>
		</g>
	</svg>
)
