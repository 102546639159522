import { FunctionComponent } from 'react'
import { Group } from 'common/types'
import { colorToHex } from 'room/common/presentation/colorsInHex'
import enterGroup from 'room/group-room/presentation/images/enter-group.svg'
import styled from '@emotion/styled'
import { Colors } from 'common/presentation/colors'
import { styles } from '@beelday/common'
import useScreenShareInGroup from 'features/screen-share/use-screen-share-in-group'

type GroupsListProps = {
	onEnter: (groupId: string) => void
	groups: Group[]
}

type EnterGroupIconProps = {
	backgroundImage: string
}

const EnterGroupIcon = styled.div`
	width: 31px;
	height: 30px;
	margin-right: 5px;
	background-image: url(${(props: EnterGroupIconProps) =>
		props.backgroundImage});
	background-size: 100%;
	background-position: center;
	background-repeat: no-repeat;
	flex-shrink: 0;
`

const GroupName = styled.div`
	font-weight: 500;
	font-family: Ubuntu;
	font-size: 16px;
	color: ${Colors.white};
	margin-left: 12px;
	margin-right: 5px;
	flex-grow: 1;
	${styles.ellipsis};
`

type GroupListItemProps = {
	color: string
}
const GroupListItem = styled.div`
	cursor: pointer;
	height: 40px;
	border-radius: 20px;
	background-color: ${(props: GroupListItemProps) => props.color};
	margin-bottom: 4px;
	margin-top: 4px;
	margin-right: 5px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
`

export const GroupsList: FunctionComponent<GroupsListProps> = props => {
	const { stop } = useScreenShareInGroup()
	return (
		<>
			{props.groups.map(group => {
				return (
					<GroupListItem
						key={group.groupDescription.id}
						color={colorToHex[group.groupDescription.color]}
						onClick={async () => {
							await stop()
							props.onEnter(group.groupDescription.id)
						}}
					>
						<GroupName>{group.groupDescription.name.toUpperCase()}</GroupName>
						<EnterGroupIcon backgroundImage={enterGroup} />
					</GroupListItem>
				)
			})}
		</>
	)
}
