import styled from '@emotion/styled'
import { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import colors from '../colors'
import { error, success } from '../images'
import { Translate } from '../intl'
import { SlideDown } from '../ui'

export type SubmissionState = 'SUCCESS' | 'ERROR' | 'INITIAL'

const Container = styled.div`
	align-items: center;
	background-color: ${colors.paleGray2};
	box-shadow: 0 5px 15px 0 rgba(146, 148, 166, 0.16);
	border-bottom: 2px solid ${props => props.color};
	border-radius: 4px;
	color: ${colors.black};
	display: flex;
	font-size: 14px;
	font-weight: 500;
	height: 42px;
	margin-bottom: 20px;
	padding: 11px 12px 15px 12px;
	width: 100%;
`

const Icon = styled.img`
	height: 15px;
	margin-right: 8px;
	width: 15px;
`

const Success: FC = ({ children }): JSX.Element => (
	<Container color={colors.greenishCyan}>
		<Icon src={success} />
		{children}
	</Container>
)

const Error: FC = ({ children }): JSX.Element => (
	<Container color={colors.strongPink}>
		<Icon src={error} />
		{children}
	</Container>
)

const DEFAULT_DURATION = 3000

type Props = {
	children: ReactNode
	state?: SubmissionState
	autohide?: boolean
	onHide?: () => unknown
	duration?: number
}

const SubmissionFeedback: FC<Props> = ({
	state,
	children,
	autohide,
	onHide,
	duration = DEFAULT_DURATION,
}: Props): JSX.Element => {
	const [visible, setVisible] = useState(false)

	useEffect(() => {
		setVisible(true)
	}, [state])

	useEffect(() => {
		if (autohide && visible) {
			const timeout = setTimeout(() => {
				setVisible(false)
				onHide?.()
			}, duration)
			return () => clearTimeout(timeout)
		}
	}, [autohide, onHide, duration, visible])

	return (
		<SlideDown visible={visible && !!state}>
			{state === 'SUCCESS' ? (
				<Success>{children}</Success>
			) : state === 'ERROR' ? (
				<Error>{children}</Error>
			) : (
				<Container />
			)}
		</SlideDown>
	)
}

const SubmissionFeedbackLegacy = ({
	state,
	successMsg,
	errorMsg,
	onEnd,
	data,
}: {
	errorMsg: string
	state: SubmissionState
	successMsg: string
	onEnd: () => void
	data?: string | number
}): JSX.Element => {
	const visible = state !== 'INITIAL'

	useEffect(() => {
		if (visible) {
			setTimeout(() => onEnd(), DEFAULT_DURATION)
		}
	}, [visible, onEnd])

	return (
		<SlideDown visible={visible}>
			{state === 'SUCCESS' ? (
				<Success>
					{data ? (
						<Translate data={data}>{successMsg}</Translate>
					) : (
						<Translate>{successMsg}</Translate>
					)}
				</Success>
			) : state === 'ERROR' ? (
				<Error>
					{data ? (
						<Translate data={data}>{errorMsg}</Translate>
					) : (
						<Translate>{errorMsg}</Translate>
					)}
				</Error>
			) : (
				<Container />
			)}
		</SlideDown>
	)
}

export type FeedbackRouterState = {
	feedback:
		| {
				status: SubmissionState
				message: ReactNode
		  }
		| undefined
}
export const RouterBasedFeedback: FC<{
	autohide?: boolean
	onHide?: () => unknown
	duration?: number
}> = props => {
	const location = useLocation<FeedbackRouterState>()
	const history = useHistory()

	const feedback = location.state?.feedback
	const clearFeedback = useCallback(() => {
		return setTimeout(() => {
			if (location.state?.feedback) {
				history.replace({
					state: { ...location.state, feedback: undefined },
					search: location.search,
					hash: location.hash,
				})
			}
		}, 100)
	}, [history, location])

	return (
		<SubmissionFeedback
			{...props}
			state={feedback?.status}
			onHide={clearFeedback}
		>
			{feedback?.message}
		</SubmissionFeedback>
	)
}

export default SubmissionFeedbackLegacy
