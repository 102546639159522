import { FeatureToggle, hooks, ui } from '@beelday/common'
import styled from '@emotion/styled'
import { Config } from 'common/config'
import { useRemoteControl } from 'features/remote-control'
import { useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useAssertedWorkflowUser } from 'room/common/use-workflow-user'
import { selectScreenShareStateInGroup } from 'room/group-room/redux'
import {
	sceneVideoUsersById,
	selectLocalScreenShareStream,
} from 'video-conference-media/redux'

const TwoColumnGrid = styled.div`
	display: flex;
	flex: 1;
	height: 100%;
	min-height: 0;
	width: 100%;
`

const ScreenContainer = styled.div<{ remoteControl?: boolean }>`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	video {
		flex-shrink: 1;
		max-width: 100%;
		max-height: 100%;
	}
	border: ${({ remoteControl }) => (remoteControl ? 'solid red 3px' : 'none')};
`

type Props = {
	screen?: MediaStream
	local?: boolean
}

export const OnlyScreenShare = ({ screen, local }: Props): JSX.Element => {
	const videoRef = useRef<HTMLVideoElement>(null)
	const remoteControl = useRemoteControl()
	const { setVideoElement } = remoteControl

	useEffect(() => {
		if (videoRef.current && screen) {
			videoRef.current.srcObject = screen
		}
	}, [screen, videoRef])

	useEffect(() => {
		setVideoElement(videoRef.current || null)
	}, [setVideoElement, videoRef])

	return (
		<TwoColumnGrid style={{ position: 'relative' }}>
			<ScreenContainer remoteControl={remoteControl.state === 'ON'}>
				<video ref={videoRef} autoPlay />
			</ScreenContainer>
			<FeatureToggle name="REMOTE_CONTROL">
				{() => {
					return local ? null : (
						<>
							<hooks.OnLifecycle onUnmount={remoteControl.disconnect} />
							<ui.Button
								style={{ position: 'absolute', cursor: 'pointer', zIndex: 99 }}
								onClick={() => {
									if (remoteControl.state === 'ON') {
										remoteControl.disconnect()
									} else {
										remoteControl.connect(Config.remoteControlUrl, 'the token')
									}
								}}
							>
								Remote Control {remoteControl.state === 'ON' ? '(ON)' : ''}
							</ui.Button>
						</>
					)
				}}
			</FeatureToggle>
		</TwoColumnGrid>
	)
}

const ConfiguredScreenShareGroup = (): JSX.Element => {
	const me = useAssertedWorkflowUser()
	const groupScreenShareState = useSelector(selectScreenShareStateInGroup)

	if (groupScreenShareState?.userId === me.id) {
		return <MeScreenSharing />
	} else {
		return <OtherScreenShare />
	}
}

const MeScreenSharing = (): JSX.Element => {
	const localScreenShareStream = useSelector(selectLocalScreenShareStream)

	return <OnlyScreenShare screen={localScreenShareStream} local={true} />
}

const OtherScreenShare = (): JSX.Element => {
	const videoUsers = useSelector(sceneVideoUsersById)

	const groupScreenShareState = useSelector(selectScreenShareStateInGroup)

	const screenshare = useMemo(
		() =>
			Object.values(videoUsers).find(
				s => s.userId === groupScreenShareState?.userId
			),
		[groupScreenShareState?.userId, videoUsers]
	)

	const stream = screenshare?.stream

	const screen = useMemo(() => {
		if (!stream) return
		return new MediaStream(
			stream.getVideoTracks().filter(t => t.id === stream.screenShareTrackId)
		)
	}, [stream])

	if (screen) {
		return <OnlyScreenShare screen={screen} local={false} />
	} else {
		return <OnlyScreenShare />
	}
}

export default ConfiguredScreenShareGroup
