import { pull } from 'lodash'
import React, { useContext, useMemo } from 'react'

type CommandsListener<C> = (command: C) => void
export class CommandsContext<C> {
	private _listeners: CommandsListener<C>[] = []

	public listen(listener: CommandsListener<C>): () => void {
		this._listeners.push(listener)

		return () => {
			pull(this._listeners, listener)
		}
	}
	public emit(event: C): void {
		this._listeners.forEach(l => l(event))
	}
}

export type CommandsHook<C> = {
	emit: (event: C) => void
	emitter: (event: C) => () => void
	register: (listener: CommandsListener<C>) => () => void
}
export function createCommandsHook<C>(
	ctx: React.Context<CommandsContext<C>>
): () => CommandsHook<C> {
	return () => {
		const context = useContext(ctx)
		const handler = useMemo(() => {
			return {
				register: (listener: CommandsListener<C>) => context.listen(listener),
				emit: (event: C) => context.emit(event),
				emitter: (event: C) => (e?: Event) => {
					e?.preventDefault()
					e?.stopPropagation()
					context.emit(event)
				},
			}
		}, [context])

		return handler
	}
}
