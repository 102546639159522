import { func, http } from '@beelday/common'
import { RoomAddress } from 'common/types'
import { buildRoomUrl } from 'utils/http'
import {
	NewPollingWordCloud,
	PollingWordCloudDefinition,
	PollingWordCloudId,
} from './world-cloud-models'

interface WordCloudApi {
	getPollingDefinitions: (
		room: RoomAddress
	) => Promise<PollingWordCloudDefinition[]>
	createOpenEndedWordCloud: (
		room: RoomAddress,
		polling: NewPollingWordCloud
	) => Promise<PollingWordCloudDefinition>
	sendAnswer: (
		room: RoomAddress,
		pollingId: PollingWordCloudId,
		answerText: string
	) => Promise<void>
	startPolling: (
		room: RoomAddress,
		pollingId: PollingWordCloudId
	) => Promise<void>
	stopPolling: (
		room: RoomAddress,
		pollingId: PollingWordCloudId
	) => Promise<void>
}

const createWordCloudApi = (serverUrl: string): WordCloudApi =>
	<WordCloudApi>{
		getPollingDefinitions: (
			room: RoomAddress
		): Promise<PollingWordCloudDefinition[]> => {
			return http
				.fetch(buildRoomUrl(serverUrl, room, '/features/word-cloud-polling'))
				.then(http.checkStatus)
				.then(http.parseBody)
		},

		createOpenEndedWordCloud: (room, polling) => {
			return http
				.fetch(buildRoomUrl(serverUrl, room, '/features/word-cloud-polling'), {
					method: 'POST',
					body: JSON.stringify(polling),
				})
				.then(http.checkStatus)
				.then(http.parseBody)
		},

		startPolling: (room: RoomAddress, pollingId): Promise<void> => {
			return http
				.fetch(buildRoomUrl(serverUrl, room, '/features/word-cloud-polling'), {
					method: 'PUT',
					body: JSON.stringify({ pollingId }),
				})
				.then(http.checkStatus)
				.then(http.parseBody)
		},
		stopPolling: (room: RoomAddress, pollingId): Promise<void> => {
			return http
				.fetch(buildRoomUrl(serverUrl, room, '/features/word-cloud-polling'), {
					method: 'DELETE',
					body: JSON.stringify({ pollingId }),
				})
				.then(http.checkStatus)
				.then(http.parseBody)
		},
		sendAnswer: (
			room: RoomAddress,
			pollingId,
			answerText: string
		): Promise<void> => {
			return http
				.fetch(
					buildRoomUrl(serverUrl, room, '/features/word-cloud-polling/answer'),
					{
						method: 'POST',
						body: JSON.stringify({ pollingId, answerText }),
					}
				)
				.then(http.checkStatus)
		},
	}

export const getWordCloudApi = func.memoizeSingle(createWordCloudApi)
