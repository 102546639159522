import { FC, useCallback, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import Translate from 'intl/translate'
import { colors, icons } from '@beelday/common'
import { GroupReview } from 'common/types'
import GroupsTaskDescriptionPopup from './groups-task-description-popup'

const Container = styled.div`
	width: 100%;
	height: 100%;

	display: flex;
	flex-flow: column;
	align-items: stretch;
	justify-items: center;
	align-content: stretch;
	justify-content: center;
`

const GroupNameText = styled.p`
	color: ${colors.indigoBlue};
	cursor: pointer;
	margin: 0;
	text-transform: uppercase;
	font-family: Ubuntu;
	font-size: 23px;
	font-weight: 500;
	transition: opacity 0.25s;

	&:hover {
		opacity: 0.7;
	}
`

const TaskNameText = styled.div`
	display: flex;
	color: ${colors.indigoBlue};
	cursor: pointer;
	margin: 5px;
	text-transform: uppercase;
	font-family: Ubuntu;
	font-size: 12px;
	font-weight: 500;
	transition: opacity 0.25s;

	&:hover {
		opacity: 0.7;
	}
`

const GroupNameInput = styled.input`
	border: 3px solid ${colors.greenishCyan};
	border-radius: 20px;
	background-color: white;
	padding: 4% 1% 3.5% 5%;

	font-family: Ubuntu;
	font-size: 20px;
	font-weight: 500;
	color: ${colors.indigoBlue};
	text-transform: uppercase;

	&:focus {
		outline: none;
	}
`

const TaskNameContent = styled.div`
	position: relative;
	color: ${colors.lightishPurple};
	text-decoration: underline;

	svg {
		position: absolute;
		right: -12px;
		top: 50%;
		transform: translateY(-50%);
		height: 10px;
	}
`

type Props = {
	taskDetails: GroupReview | undefined
	groupName: string
	onGroupNameChange: (name: string) => void
}
const MAX_GROUP_NAME_LENGTH = 20

export const GroupPropertiesPanel: FC<Props> = ({
	taskDetails,
	groupName,
	onGroupNameChange,
}) => {
	const [showDescription, setShowDescription] = useState(false)
	const [isFormActive, setFormActive] = useState(false)
	const [newGroupName, setNewGroupName] = useState(groupName)

	useEffect(() => {
		if (!isFormActive) {
			setNewGroupName(groupName)
		}
	}, [isFormActive, groupName])

	const reset = useCallback(() => {
		setNewGroupName(groupName)
		setFormActive(false)
	}, [groupName])

	const confirmAndReset = useCallback(() => {
		if (newGroupName && newGroupName !== '') onGroupNameChange(newGroupName)

		reset()
	}, [newGroupName, onGroupNameChange, reset])

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			confirmAndReset()
		} else if (e.key === 'Esc' || e.key === 'Escape') {
			reset()
		}
	}

	const handleInputChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (e.target.value.length <= MAX_GROUP_NAME_LENGTH) {
				setNewGroupName(e.target.value)
			}
		},
		[]
	)

	const selectAllHandler = useCallback(
		(e: React.FocusEvent<HTMLInputElement>) => e.target.select(),
		[]
	)

	const taskName = taskDetails?.task?.name ?? ''

	return (
		<>
			{isFormActive ? (
				<Container>
					<GroupNameInput
						autoFocus
						onBlur={confirmAndReset}
						onChange={handleInputChange}
						onKeyDown={handleKeyDown}
						onFocus={selectAllHandler}
						value={newGroupName}
					/>
				</Container>
			) : (
				<Container>
					<GroupNameText
						onClick={() => {
							setFormActive(true)
						}}
					>
						<Translate>group</Translate>&nbsp;
						{groupName}
					</GroupNameText>
					<TaskNameText onClick={() => setShowDescription(true)}>
						<Translate>task</Translate>&nbsp;{' '}
						<TaskNameContent>
							{taskName} <icons.Chevron />
						</TaskNameContent>
					</TaskNameText>
				</Container>
			)}
			{taskDetails?.task?.description && showDescription ? (
				<GroupsTaskDescriptionPopup
					taskDetails={taskDetails}
					onClose={() => setShowDescription(false)}
				/>
			) : null}
		</>
	)
}
