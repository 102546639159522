import { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ReactAudioContext } from './audio-context-provider'
import { myStream } from './redux'
import { bufferSize, microphoneInterval } from './settings'
import { VoiceAnalyzerReporter } from './use-voice-analyzer'

type UseMicrophoneTracking = (reportCallback?: VoiceAnalyzerReporter) => void

export const useMicrophoneTracking: UseMicrophoneTracking = report => {
	const { audioContext } = useContext(ReactAudioContext)
	const stream = useSelector(myStream)

	useEffect(() => {
		if (!audioContext || !stream || !report) {
			return undefined
		}

		const source = audioContext.createMediaStreamSource(stream)
		const analyser = new AnalyserNode(audioContext, {
			fftSize: bufferSize,
		})

		source.connect(analyser)

		const interval = setInterval(() => {
			const buffer = new Float32Array(bufferSize)
			analyser.getFloatTimeDomainData(buffer)
			if (report) {
				report(buffer)
			}
		}, microphoneInterval)

		return () => {
			source.disconnect()
			clearInterval(interval)
		}
	}, [stream, report])
}
