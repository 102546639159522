import { InteractionSchemeKey, User } from '../common/types'

const workflowsReducerDefaultState = {
	workflowId: null,
	joinedInteractionScheme: null,
	user: null,
	endTraining: false,
}

export interface WorkflowsState {
	workflowId: string | null
	joinedInteractionScheme: InteractionSchemeKey | null
	user: User | null
	endTraining: boolean
}

type WorkflowAction =
	| { type: 'SET_WORKFLOW_ID'; workflowId: string }
	| { type: 'SET_USER'; user: User }
	| { type: 'SSE_WORKFLOW_CONTEXT_STOPPED' }
	| {
			type: 'SET_JOINED_INTERACTION_SCHEME'
			interactionSchemeKey: InteractionSchemeKey
	  }

export const setWorkflowId = (workflowId: string) => {
	return { type: 'SET_WORKFLOW_ID', workflowId }
}

export const setJoinedInteractionScheme = (
	interactionSchemeKey: InteractionSchemeKey
) => {
	return { type: 'SET_JOINED_INTERACTION_SCHEME', interactionSchemeKey }
}

export const setUser = (user: User) => {
	return { type: 'SET_USER', user }
}

export function workflowReducer(
	state: WorkflowsState = workflowsReducerDefaultState,
	action: WorkflowAction
): WorkflowsState {
	switch (action.type) {
		case 'SET_WORKFLOW_ID':
			return {
				...state,
				workflowId: action.workflowId,
			}
		case 'SET_JOINED_INTERACTION_SCHEME':
			return {
				...state,
				joinedInteractionScheme: action.interactionSchemeKey,
			}
		case 'SET_USER':
			return {
				...state,
				user: action.user,
			}
		case 'SSE_WORKFLOW_CONTEXT_STOPPED':
			return {
				...state,
				endTraining: true,
			}
		default:
			return state
	}
}
