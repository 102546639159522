import {
	forwardRef,
	ReactNode,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react'
import styled from '@emotion/styled'
import { ArrowLeft } from '../../icons'
import colors from '../../colors'
import { desktop1440 } from '../responsive'

export const ListPollingMainContainer = styled.div`
	width: 100%;
	background-color: ${colors.white};
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
	border-radius: 7px;
	height: 100%;
	padding: 10px;
	display: flex;
	gap: 20px;
	overflow: hidden;
`

const ScrollContainerParent = styled.div`
	position: relative;
	overflow: hidden;
	flex: 1;
	height: auto;
`

const ScrollContainer = styled.div`
	position: relative;
	padding: 0 15px;
	overflow: auto;
	height: 100%;
	width: calc(100% - 8px);

	&::-webkit-scrollbar-track {
		-webkit-box-shadow: unset;
		border-radius: 10px;
		background-color: ${colors.darkGray};
	}

	&::-webkit-scrollbar {
		width: 7px;
		height: 7px;
		background-color: ${colors.darkGray};
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		-webkit-box-shadow: unset;
		background-color: rgba(255, 255, 255, 0.4);
	}

	&::-webkit-scrollbar-corner {
		background-color: ${colors.darkGray};
	}
`

const ArrowButton = styled.button`
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
	color: ${colors.black};
	position: absolute;
	left: 50%;
	bottom: 20px;
	width: 40px;
	height: 40px;
	background: ${colors.white};
	border: none;
	border-radius: 50%;
	cursor: pointer;
	transition: background-color 0.25s, color 0.25s;

	svg {
		transform: rotate(-90deg);
	}

	&:hover {
		background: ${colors.indigoBlue};
		color: ${colors.white};
	}
`

export type ListPollingContainerRef = {
	scrollToBottom: () => void
}

type ListPollingScrollContainerProps = {
	children: ReactNode
}

// eslint-disable-next-line react/display-name
export const ListPollingScrollContainer = forwardRef<
	ListPollingContainerRef,
	ListPollingScrollContainerProps
>(({ children }, ref) => {
	const scrollOffset = 150
	const containerRef = useRef<HTMLDivElement>(null)
	const [showArrow, setShowArrow] = useState<boolean>(false)

	useImperativeHandle(ref, () => ({
		scrollToBottom,
	}))

	const clickScrollBottom = () => {
		if (containerRef.current) {
			containerRef.current.scrollTop = containerRef.current.scrollHeight
		}
		setShowArrow(false)
	}

	const scrollToBottom = () => {
		if (!showArrow) {
			setTimeout(() => {
				if (containerRef.current) {
					containerRef.current.scrollTop = containerRef.current.scrollHeight
				}
				setShowArrow(false)
			}, 1)
		}
	}

	useEffect(() => {
		const scrollContainer = containerRef.current
		const scroll = (event: Event) => {
			const target = event.currentTarget as HTMLDivElement
			const scrollOnEnd =
				target.scrollHeight - (target.clientHeight + target.scrollTop) <
				scrollOffset
			setShowArrow(scrollOnEnd)
			if (scrollOnEnd) {
				setShowArrow(false)
			} else {
				setShowArrow(true)
			}
		}
		scrollContainer?.addEventListener('scroll', scroll)
		return () => {
			scrollContainer?.removeEventListener('scroll', scroll)
		}
	}, [])
	return (
		<ScrollContainerParent>
			<ScrollContainer ref={containerRef}>{children}</ScrollContainer>

			{showArrow ? (
				<ArrowButton onClick={() => clickScrollBottom()}>
					<ArrowLeft />
				</ArrowButton>
			) : null}
		</ScrollContainerParent>
	)
})

export const ListPollingHeader = styled.div`
	padding: 15px;
	font-size: 18px;
	line-height: 22px;
	font-weight: 500;
	border-bottom: 1px solid ${colors.heather30};
	margin-bottom: 10px;

	${desktop1440} {
		padding: 25px 15px;
	}
`

export const ListPollingChatContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1;
	background-color: ${colors.dustyWhite};
	border-radius: 7px;
	overflow: hidden;
`

const AnswerItem = styled.div`
	display: flex;
	margin-bottom: 5px;

	&:last-child {
		margin-bottom: 0;
	}
`

const Answer = styled.div`
	display: inline-flex;
	background: ${colors.heather10};
	padding: 10px;
	border-radius: 8px;
`

const AnswerUserAvatar = styled.div`
	flex: 0 0 24px;
	width: 24px;
	height: 24px;
	margin-right: 10px;
`

const AnswerContent = styled.div`
	font-size: 14px;
	line-height: 20px;
	font-weight: 300;

	b,
	strong {
		font-weight: 700;
	}
`

export const ListPollingAnswer = ({
	name,
	text,
	avatar,
}: {
	avatar: JSX.Element
	name?: string
	text: string
}): JSX.Element | null => {
	return (
		<AnswerItem>
			<Answer>
				<AnswerUserAvatar>{avatar}</AnswerUserAvatar>
				<AnswerContent>
					{name ? <strong>{name}:</strong> : null} {text}
				</AnswerContent>
			</Answer>
		</AnswerItem>
	)
}
