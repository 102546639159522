import { Config } from 'common/config'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import useQuestionApi from './question-api'
import { isActive, result } from './questions-redux'

const useQuestion = () => {
	const api = useQuestionApi(Config.beeldayBackendUrl)
	const active = useSelector(isActive)
	const currentResult = useSelector(result)

	return useMemo(
		() => ({
			active,
			finishVoting: api.finishVoting,
			result: currentResult,
			startVoting: api.startVoting,
			voteYes: api.voteYes,
			voteNo: api.voteNo,
		}),
		[api, active, currentResult]
	)
}

export default useQuestion
