import { func, http } from '@beelday/common'
import { RoomAddress, UUID } from 'common/types'
import { buildRoomUrl } from 'utils/http'
import {
	NewPollingList,
	PollingListPollingDefinition,
	PollingListPollingId,
} from './list-polling-models'

interface ListPollingApi {
	getPollingDefinitions: (
		room: RoomAddress
	) => Promise<PollingListPollingDefinition[]>
	createOpenEndedList: (
		room: RoomAddress,
		polling: NewPollingList
	) => Promise<PollingListPollingDefinition>
	sendAnswer: (
		room: RoomAddress,
		pollingId: PollingListPollingId,
		questionId: UUID,
		answerText: string
	) => Promise<void>
	startPolling: (
		room: RoomAddress,
		pollingId: PollingListPollingId
	) => Promise<void>
	stopPolling: (
		room: RoomAddress,
		pollingId: PollingListPollingId
	) => Promise<void>
}

const createListPollingApi = (serverUrl: string): ListPollingApi =>
	<ListPollingApi>{
		getPollingDefinitions: (
			room: RoomAddress
		): Promise<PollingListPollingDefinition[]> => {
			return http
				.fetch(buildRoomUrl(serverUrl, room, '/features/list-polling'))
				.then(http.checkStatus)
				.then(http.parseBody)
		},
		createOpenEndedList: (room, polling) => {
			return http
				.fetch(buildRoomUrl(serverUrl, room, '/features/list-polling'), {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(polling),
				})
				.then(http.checkStatus)
				.then(http.parseBody)
		},
		startPolling: (room: RoomAddress, pollingId): Promise<void> => {
			return http
				.fetch(buildRoomUrl(serverUrl, room, '/features/list-polling'), {
					method: 'PUT',
					body: JSON.stringify({ pollingId }),
				})
				.then(http.checkStatus)
				.then(http.parseBody)
		},
		stopPolling: (room: RoomAddress, pollingId): Promise<void> => {
			return http
				.fetch(buildRoomUrl(serverUrl, room, '/features/list-polling'), {
					method: 'DELETE',
					body: JSON.stringify({ pollingId }),
				})
				.then(http.checkStatus)
				.then(http.parseBody)
		},
		sendAnswer: (
			room: RoomAddress,
			pollingId,
			questionId,
			answerText: string
		): Promise<void> => {
			return http
				.fetch(buildRoomUrl(serverUrl, room, '/features/list-polling/answer'), {
					method: 'POST',
					body: JSON.stringify({ pollingId, questionId, answerText }),
				})
				.then(http.checkStatus)
		},
	}

export const getListPollingApi = func.memoizeSingle(createListPollingApi)
