import { styles } from '@beelday/common'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Colors } from 'common/presentation/colors'
import { ui } from '@beelday/common'
import { Interest, User, UserProfile } from 'common/types'
import { isEmpty, take } from 'lodash'
import { FC, MouseEvent, useCallback, useState } from 'react'
import { interestToImage } from 'users'
import ProfileAvatar from 'users/profile-avatar'
import AllUsersPopup from './chat-room-all-users-popup'
import { PublicChatRoomType } from './model'

const Container = styled.div`
	flex: 1;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	margin-top: 10px;
	margin-left: 10px;

	${ui.responsive.desktop1440} {
		align-items: center;
		margin-bottom: 100px;
	}
`

const Over = styled.div`
	border: 2px solid ${Colors.white};
	border-radius: 50%;
	margin-left: -5px;
	position: relative;
	height: 55px;
	width: 55px;

	${ui.responsive.desktop1536} {
		margin-left: -8px;
		height: 84px;
		width: 84px;
	}
`

const Avatar = styled(ProfileAvatar)`
	height: 100%;
	width: 100%;
`

const Rest = styled.div`
	background-color: ${Colors.azure};
	border-radius: 50%;
	color: ${Colors.white};
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: Ubuntu;
	font-size: 18px;
	font-weight: 500;
	height: 100%;
	width: 100%;
`

const Name = styled.div`
	color: ${Colors.black};
	font-family: Ubuntu;
	font-size: 12px;
	font-weight: 500;
	margin-top: 16px;
	padding: 0 2px;
	text-align: center;

	${styles.ellipsis};
`

const interestCommon = css`
	background-color: ${Colors.white};
	padding: 2px;
	border-radius: 50%;
	position: absolute;
	height: 23px;
	width: 23px;

	${ui.responsive.desktop1536} {
		height: 33px;
		width: 33px;
	}
`

const SingleInterstImg = styled.img`
	${interestCommon}

	bottom: -5px;
	left: -5px;
`

const FirstInterestImg = styled.img`
	${interestCommon}

	bottom: 0;
	left: -6px;
`

const SecondInterestImg = styled.img`
	${interestCommon}

	bottom: -16px;
	left: 10px;

	${ui.responsive.desktop1536} {
		left: 21px;
	}
`

const CommonInterest = ({ interests }: { interests?: Interest[] }) => {
	if (!interests || isEmpty(interests)) {
		return null
	} else if (interests.length === 1) {
		const [single] = interests
		return <SingleInterstImg src={interestToImage[single]} />
	} else {
		const [first, second] = interests
		return (
			<>
				<FirstInterestImg src={interestToImage[first]} />
				<SecondInterestImg src={interestToImage[second]} />
			</>
		)
	}
}

type ChatRoomUser = User & Partial<UserProfile>
type Props = {
	users: ChatRoomUser[]
	roomType: PublicChatRoomType
	onJoin: () => void
}

const MAX_PEOPLE = 4

const ChatRoomUsers: FC<Props> = ({ roomType, users, onJoin }) => {
	const [allUsersPopupVisible, setAllUsersPopupVisible] = useState(false)
	const showPopup = useCallback(
		(event: MouseEvent<HTMLDivElement>) => {
			if (users.length > MAX_PEOPLE) {
				event.stopPropagation()

				setAllUsersPopupVisible(true)
			}
		},
		[users.length]
	)
	const hidePopup = useCallback((event: MouseEvent<HTMLDivElement>) => {
		event.stopPropagation()

		setAllUsersPopupVisible(false)
	}, [])

	const toDisplay = take(users, MAX_PEOPLE)
	const remaining = users.length - toDisplay.length

	return (
		<Container onClick={showPopup}>
			{toDisplay.map(u => (
				<Over key={u.id}>
					<Avatar profile={u} />
					<Name>{u?.displayName || u?.name}</Name>
					<CommonInterest interests={u.interests} />
				</Over>
			))}
			{remaining > 0 ? (
				<Over>
					<Rest>+{remaining}</Rest>
				</Over>
			) : null}
			{allUsersPopupVisible && (
				<AllUsersPopup
					roomType={roomType}
					users={users}
					onClose={hidePopup}
					onJoin={onJoin}
				/>
			)}
		</Container>
	)
}

export default ChatRoomUsers
