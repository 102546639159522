import { func, http } from '@beelday/common'
import { RoomAddress } from 'common/types'
import { buildRoomUrl } from 'utils/http'
import { Slide, SlideId } from './slide-models'

interface SlidesApi {
	openSlide: (
		addr: RoomAddress,
		slideId: SlideId,
		slideName: string
	) => Promise<void>
	closeSlide: (addr: RoomAddress) => Promise<void>
	fetchSlides: (addr: RoomAddress) => Promise<Slide[]>
	getThumbnailUrl: (slide: Slide) => string | undefined
}

type SlideDto = {
	id: SlideId
	name: string
	created: number
	thumb?: string
}
const dtoToSlide = (dto: SlideDto): Slide => ({
	...dto,
	created: new Date(dto.created),
})

const createSlidesApi = (
	serverUrl: string,
	slidesApiUrl: string
): SlidesApi => {
	console.log('Create slides api')
	return {
		openSlide: (
			addr: RoomAddress,
			slideId: SlideId,
			slideName: string
		): Promise<void> => {
			return http
				.fetch(buildRoomUrl(serverUrl, addr, '/features/slides'), {
					method: 'PUT',
					body: JSON.stringify({ slideId, name: slideName }),
				})
				.then(http.checkStatus)
		},
		closeSlide: (addr: RoomAddress): Promise<void> => {
			return http
				.fetch(buildRoomUrl(serverUrl, addr, '/features/slides/cancel'), {
					method: 'PUT',
				})
				.then(http.checkStatus)
		},
		fetchSlides: (addr: RoomAddress): Promise<Slide[]> => {
			return http
				.fetch(buildRoomUrl(serverUrl, addr, '/features/slide-definitions'))
				.then(http.checkStatus)
				.then(http.parseBody)
				.then((body: SlideDto[]) => body.map(dtoToSlide))
		},
		getThumbnailUrl: (slide: Slide): string | undefined => {
			if (!slide.thumb) return undefined
			return `${slidesApiUrl}/boards/files/${slide.id}/${slide.thumb}`
		},
	}
}

export const getSlidesApi = func.memoizeSingle(createSlidesApi)
