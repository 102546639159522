import { Color, GroupMember, User } from 'common/types'

import { FC } from 'react'
import { SceneVideoUserStream } from 'video-conference-media/presentation/scene-video-user-stream'
import { Colors } from 'common/presentation/colors'
import ActiveGroupNumber from 'room/training-room/review-tasks/presentation/active-group-number'
import { isGroupMember } from 'room/training-room/model/review-task'
import { SceneVideoUser } from 'video-conference-media'

type Props = {
	videoUser: SceneVideoUser
	user: User | GroupMember
}

const isIdentifiedByGroupNumber = (user: User | GroupMember): boolean =>
	'identifiedBy' in user && user.identifiedBy === 'GROUP_NUMBER'

//Structure is VERY IMPORTANT TO AVOID STREAM BLINKING
const ReviewSceneVideoUser: FC<Props> = ({ user, videoUser }) => (
	<>
		{isGroupMember(user) && isIdentifiedByGroupNumber(user) ? (
			<ActiveGroupNumber groupNumber={user.group.number} />
		) : null}
		{isGroupMember(user) && isIdentifiedByGroupNumber(user) ? (
			<SceneVideoUserStream
				sceneVideoUser={videoUser}
				fillColor={Color.WHITE}
				fontColor={Colors.indigoBlue}
			/>
		) : isGroupMember(user) ? (
			<SceneVideoUserStream
				sceneVideoUser={videoUser}
				fillColor={user.group.color}
			/>
		) : (
			<SceneVideoUserStream sceneVideoUser={videoUser} />
		)}
	</>
)

export default ReviewSceneVideoUser
