import { isEqual } from 'lodash'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Fn<TArgs extends any[], TResult> = (...args: TArgs) => TResult

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function memoizeSingle<Args extends any[], Res>(
	fn: Fn<Args, Res>,
	eq: Fn<[Args, Args], boolean> = isEqual
): Fn<Args, Res> {
	let lastArgs: Args
	let lastRes: Res

	return (...args: Args): Res => {
		if (!lastArgs || !eq(lastArgs, args)) {
			lastRes = fn(...args)
			lastArgs = args
		}
		return lastRes
	}
}
