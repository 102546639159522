import { FunctionComponent } from 'react'
import { ui } from '@beelday/common'
import { RoomContainer } from 'room/common/presentation/room-container'
import { SelfStreamView } from 'video-conference-media/presentation/top-right-stream-view'
import styled from '@emotion/styled'
import GroupsPreview, { GroupsPreviewProps } from './groups-preview'
import Translate from 'intl/translate'
import { MiscPanel } from 'room/misc'
import RoomHeader from 'room/common/presentation/room-header'
import { GroupId } from 'common/types'
import { TrainerMenuGroupsView } from './trainer-menu-groups-view'
import { use100vh } from 'react-div-100vh'

type Props = GroupsPreviewProps & {
	listenToGroup?: GroupId
	onMuteGroups: () => unknown
	onUnmuteGroup: (id: GroupId) => unknown
	onTrainerBusy: () => void
	onTrainerBackFromBusy: () => void
	isTrainerBusy: boolean
	onStartBreak: () => void
}

const Container = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`

export const RoomTrainerGroupsView: FunctionComponent<Props> = props => {
	const containerHeight = use100vh()

	return (
		<RoomContainer style={{ height: `${containerHeight}px` }}>
			<RoomHeader streamView={<SelfStreamView />}>
				<Translate>groups.overview</Translate>
			</RoomHeader>
			<Container>
				<GroupsPreview {...props} />
			</Container>
			<TrainerMenuGroupsView {...props} />
			<MiscPanel />
			<ui.BeeldayLogoAbsoluteBottom />
		</RoomContainer>
	)
}
