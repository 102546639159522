import styled from '@emotion/styled'
import colors from '../colors'
import { SquarePlus } from './square-plus'

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`

const Text = styled.div`
	color: ${colors.black};
	margin-left: 17px;
	font-size: 14px;
	line-height: 1;
	font-weight: 500;
	transition: color 0.25s;
`

type Props = {
	text: string
}

export const AddIconText = ({ text }: Props): JSX.Element => (
	<Container>
		<SquarePlus />
		<Text className="addIconText">{text}</Text>
	</Container>
)
