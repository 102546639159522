import styled from '@emotion/styled'
import { AnimatePresence, motion } from 'framer-motion'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectActiveSlideId, selectAllSlides } from './slide-redux'
import { SlideSwitcher } from './slide-switcher'
import { useSlides } from './use-slides'

const SwitcherWrapper = styled(motion.div)`
	position: absolute;
	left: 50%;
	transform: translate(-50%);
	width: 700px;
	max-width: 80%;
`
type Props = {
	visible: boolean
	onClose: () => unknown
	offset?: number
}

export const ConfiguredSlideSwitcher: FC<Props> = ({
	visible,
	offset,
	onClose,
}): JSX.Element => {
	const { openSlide } = useSlides()
	const dispatch = useDispatch()
	const allSlides = useSelector(selectAllSlides)
	const slideId = useSelector(selectActiveSlideId)

	useEffect(() => {
		if (visible) dispatch(actions.fetchAll())
	}, [dispatch, visible])

	return (
		<AnimatePresence>
			{visible ? (
				<SwitcherWrapper
					initial={{ opacity: 0, bottom: 0 }}
					animate={{ opacity: 1, bottom: `${offset || 112}px` }}
					exit={{ opacity: 0, bottom: 0 }}
					transition={{ bounce: 0 }}
					style={{ zIndex: 1 }}
				>
					<SlideSwitcher
						slides={allSlides}
						selected={slideId}
						onClose={onClose}
						onSelect={({ id, name }) => openSlide(id, name || '')}
					/>
				</SwitcherWrapper>
			) : null}
		</AnimatePresence>
	)
}
