import { FC } from 'react'
import { UserRole } from 'common/types'
import { assertUnreachable } from 'utils/unreachable'
import { useAssertedWorkflowUser } from 'room/common/use-workflow-user'
import LobbyEndUser from './lobby-end-user'
import LobbyUpperEchelon from './lobby-upper-echelon'
import { useDispatch, useSelector } from 'react-redux'
import { cancelEditProfile, isEditingProfile } from './redux'
import UserProfileEdit from 'user-profile/user-profile-edit'

const Lobby: FC = () => {
	const workflowUser = useAssertedWorkflowUser()
	const editingProfile = useSelector(isEditingProfile)
	const dispatch = useDispatch()

	const closeEditProfile = () => dispatch(cancelEditProfile())

	if (editingProfile) {
		return <UserProfileEdit onClose={closeEditProfile} />
	} else {
		switch (workflowUser.role) {
			case UserRole.END_USER:
				return <LobbyEndUser />
			case UserRole.UPPER_ECHELON:
				return <LobbyUpperEchelon />
			default:
				assertUnreachable('UserRole', workflowUser.role)
		}
	}
}

export default Lobby
