import { ReactNode, FC } from 'react'
import { css } from '@emotion/react'
import { Colors } from './colors'
import { translate } from 'intl/translate'
import bg from './images/confirm-banner.svg'
import { usePreloaded } from 'connectivity/preload'
import { clippingBorderRadius } from 'common/presentation/cross-browser-css'
import styled from '@emotion/styled'
import { ui } from '@beelday/common'

type Props = {
	message: string | ReactNode
	accept?: string
	decline?: string
	onAccept: () => void
	onDecline?: () => void
}

const Container = styled.div`
	background: url(${bg});
	background-size: 100% 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: fixed;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	margin: 0 auto;
	padding: 10px 25px 40px 25px;
	width: 40vw;
	z-index: 10000;

	${ui.responsive.desktop1440} {
		width: 35vw;
	}
`

const ConfirmBanner: FC<Props> = ({
	accept = 'accept',
	decline = 'decline',
	message,
	onAccept,
	onDecline,
}) => {
	const preloaded = usePreloaded([bg])
	if (!preloaded) {
		return null
	}
	return (
		<Container>
			<div
				css={css`
					color: ${Colors.white};
					font-family: Ubuntu;
					font-size: 18px;
					font-weight: 500;
					text-align: center;
				`}
			>
				{typeof message === 'string' ? translate(message) : message}
			</div>
			<div
				css={css`
					${clippingBorderRadius('20px')};
					border: 1px solid ${Colors.white};
					display: flex;
					margin-top: 10px;
					max-width: 440px;
					width: 100%;
				`}
			>
				<button
					css={css`
						background-color: ${Colors.indigoBlue};
						border: none;
						color: ${Colors.white};
						cursor: pointer;
						flex: 1;
						padding: 10px 20px;

						&:focus {
							outline: none;
						}
					`}
					onClick={onAccept}
				>
					{translate(accept)}
				</button>
				{onDecline && (
					<button
						css={css`
							background-color: ${Colors.white};
							border: none;
							color: ${Colors.indigoBlue};
							cursor: pointer;
							flex: 1;
							padding: 10px 20px;

							&:focus {
								outline: none;
							}
						`}
						onClick={onDecline}
					>
						{translate(decline)}
					</button>
				)}
			</div>
		</Container>
	)
}

export default ConfirmBanner
