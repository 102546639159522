import type { PlayerRanking } from '../types'

import { FC } from 'react'
import styled from '@emotion/styled'
import Translate from 'intl/translate'
import { Colors } from 'common/presentation/colors'
import { styles } from '@beelday/common'
import gold from './images/gold-medal.svg'
import silver from './images/silver-medal.svg'
import bronze from './images/bronze-medal.svg'

const ItemContainer = styled.div`
	border-top: solid 1px ${Colors.brightGray};
	display: flex;
	align-items: center;
	padding: 10px;
	height: 50px;
`

const Place = styled.div`
	background-color: ${Colors.paleGray};
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 31px;
	width: 31px;
	margin-right: 20px;
	color: ${Colors.indigoBlue};
	font-family: Ubuntu;
	font-size: 14px;
	font-weight: 500;
`

const Name = styled.div`
	${styles.ellipsis};
	color: ${Colors.indigoBlue};
	font-family: Ubuntu;
	font-size: 14px;
	font-weight: 500;
	flex-grow: 1;
	flex-shrink: 2;
`

const ScoreContainer = styled.div`
	border-right: 1px solid ${Colors.brightGray};
	color: ${Colors.lightGray};
	font-family: Ubuntu;
	font-size: 12px;
	width: 100px;
`

const AwardContainer = styled.div`
	color: ${Colors.lightGray};
	display: flex;
	align-items: center;
	font-family: Ubuntu;
	font-size: 12px;
	margin-left: 15px;
	width: 150px;
`

const Medal = styled.img`
	height: 25px;
	width: 17px;
	margin-left: 10px;
`

type Props = {
	noWinners: boolean
	player: PlayerRanking & { displayName?: string }
	total: number
}

export const Score = ({ score, total }: { score: number; total: number }) => (
	<ScoreContainer>
		{`${score}/${total} `}
		<Translate>quiz.points</Translate>
	</ScoreContainer>
)

export const Award = ({ place }: { place: number }) => {
	switch (place) {
		case 1:
			return (
				<AwardContainer>
					<Translate>quiz.award_badge</Translate>
					<Medal src={gold} />
				</AwardContainer>
			)
		case 2:
			return (
				<AwardContainer>
					<Translate>quiz.award_badge</Translate>
					<Medal src={silver} />
				</AwardContainer>
			)
		case 3:
			return (
				<AwardContainer>
					<Translate>quiz.award_badge</Translate>
					<Medal src={bronze} />
				</AwardContainer>
			)
		default:
			return <AwardContainer></AwardContainer>
	}
}

const WinnersListItem: FC<Props> = ({ noWinners, player, total }) => {
	return noWinners ? (
		<ItemContainer>
			<Name>{player.displayName || player.name}</Name>
			<Score score={player.score} total={total} />
		</ItemContainer>
	) : (
		<ItemContainer>
			<Place>{player.place}</Place>
			<Name>{player.displayName || player.name}</Name>
			<Score score={player.score} total={total} />
			<Award place={player.place} />
		</ItemContainer>
	)
}

export default WinnersListItem
