export const List: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 20 12"
		xmlSpace="preserve"
	>
		<g id="Group_25889" transform="translate(21678.5 -7978.5)">
			<g id="Line_553">
				<rect
					x="-21678.5"
					y="7978.5"
					fill="currentColor"
					width="15"
					height="2"
				/>
			</g>
			<g id="Line_554">
				<rect
					x="-21678.5"
					y="7983.5"
					fill="currentColor"
					width="20"
					height="2"
				/>
			</g>
			<g id="Line_555">
				<rect
					x="-21678.5"
					y="7988.5"
					fill="currentColor"
					width="10"
					height="2"
				/>
			</g>
		</g>
	</svg>
)
