export const DraggableIcon: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="11.684"
		height="11.684"
		viewBox="0 0 11.684 11.684"
	>
		<g transform="translate(-2 -2)">
			<path
				d="M24.223,4.3l-1.9-2.215a.249.249,0,0,0-.351-.027.266.266,0,0,0-.027.027L20.041,4.3a.153.153,0,0,0,.116.252h3.949a.152.152,0,0,0,.116-.252Z"
				transform="translate(-14.289)"
				fill="currentColor"
			/>
			<path
				d="M46.252,20.037a.146.146,0,0,0-.1-.037.164.164,0,0,0-.066.015.149.149,0,0,0-.089.139V24.1a.149.149,0,0,0,.089.139.147.147,0,0,0,.163-.023l2.215-1.9a.25.25,0,0,0,.026-.352.238.238,0,0,0-.026-.026Z"
				transform="translate(-34.87 -14.287)"
				fill="currentColor"
			/>
			<path
				d="M4.3,24.219a.152.152,0,0,0,.252-.116V20.153a.149.149,0,0,0-.089-.139A.164.164,0,0,0,4.4,20a.145.145,0,0,0-.1.038l-2.215,1.9a.25.25,0,0,0-.026.352.238.238,0,0,0,.026.026Z"
				transform="translate(0 -14.286)"
				fill="currentColor"
			/>
			<path
				d="M20.015,46.089a.148.148,0,0,0,.023.163l1.9,2.215a.249.249,0,0,0,.351.027.265.265,0,0,0,.027-.027l1.9-2.215A.152.152,0,0,0,24.1,46H20.154A.149.149,0,0,0,20.015,46.089Z"
				transform="translate(-14.286 -34.871)"
				fill="currentColor"
			/>
		</g>
	</svg>
)
