import Logger from 'common/logger'

const logger = new Logger('EVENT_SOURCE')

export function jsonEventHandler<T>(handler: (data: T) => void): EventListener {
	return ((message: MessageEvent) => {
		let data
		try {
			data = JSON.parse(message.data)
		} catch (error) {
			logger.error('Error parsing SSE event JSON', error, message)
			throw error
		}

		try {
			handler({
				...data,
				timestamp: new Date(data.timestamp),
			})
		} catch (error) {
			logger.error('Error handling SSE event', error, message)
			throw error
		}
	}) as EventListener
}

export default EventSource
