import { User } from 'common/types'
import { SceneVideoUser } from 'video-conference-media'

import { FC } from 'react'
import UserToPick from 'room/training-room/set-groups/presentation/user-to-pick'
import UserToPickPlaceholder from 'room/training-room/set-groups/presentation/user-to-pick-placeholder'
import UserDraggingPlaceholder from 'room/training-room/set-groups/presentation/user-dragging-placeholder'

type Props = {
	index: number
	user: User
	stream: SceneVideoUser
	unassigned: boolean
	dragging: boolean
}

const UserUnassigned: FC<Props> = ({
	index,
	user,
	stream,
	unassigned,
	dragging,
}) => {
	return unassigned ? (
		<UserToPick
			draggable
			key={user.id}
			id={user.id}
			stream={stream}
			index={index}
		/>
	) : dragging ? (
		<UserDraggingPlaceholder key={user.id} />
	) : (
		<UserToPickPlaceholder key={user.id} />
	)
}

export default UserUnassigned
