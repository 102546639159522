export const NewTab: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 16 16"
		xmlSpace="preserve"
	>
		<g>
			<path
				fill="currentColor"
				d="M14.6,7.8V13c0,1.7-1.4,3.1-3.1,3.1H3c-0.8,0-1.6-0.3-2.2-0.9c-0.6-0.6-0.9-1.4-0.9-2.2V4.5c0-0.9,0.3-1.6,0.9-2.2
C1.4,1.7,2.2,1.4,3,1.4h5.2c0.2,0,0.4,0.1,0.6,0.2C8.9,1.8,9,2,9,2.2c0,0.2-0.1,0.4-0.2,0.6C8.6,2.9,8.4,3,8.2,3H3
C2.2,3,1.5,3.7,1.5,4.5V13c0,0.4,0.2,0.8,0.5,1.1c0.3,0.3,0.7,0.4,1.1,0.4h8.4c0.4,0,0.8-0.2,1.1-0.5c0.3-0.3,0.4-0.7,0.4-1.1V7.8
C13,7.4,13.4,7,13.8,7S14.6,7.4,14.6,7.8z"
			/>
		</g>
		<g>
			<path
				fill="currentColor"
				d="M16.1,0.7V5c0,0.4-0.4,0.8-0.8,0.8c-0.2,0-0.4-0.1-0.6-0.2c-0.1-0.2-0.2-0.4-0.2-0.6V2.7L8.2,8.9C8.1,9,7.9,9.1,7.7,9.1
		C7.5,9.1,7.4,9,7.2,8.9C6.9,8.7,6.8,8.2,7.1,7.8l6.3-6.3H11c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8h4.3c0,0,0.1,0,0.1,0
		c0,0,0,0,0,0l0,0l0.1,0c0.1,0,0.1,0,0.2,0.1l0,0c0.1,0.1,0.2,0.1,0.2,0.2c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1
		C16.1,0.6,16.1,0.7,16.1,0.7z"
			/>
		</g>
	</svg>
)
