export const GroupIcon: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="16"
		viewBox="0 0 16 16"
	>
		<path
			d="M16,6.792V8.384A3.554,3.554,0,0,1,12.76,4.8,3.24,3.24,0,0,1,16,1.559v1.6A1.641,1.641,0,0,0,14.361,4.8C14.361,5.7,15.1,6.792,16,6.792ZM8,8a4,4,0,1,1,4-4A4,4,0,0,1,8,8ZM8,1.6A2.4,2.4,0,1,0,10.4,4,2.4,2.4,0,0,0,8,1.6ZM0,8.384V6.792A2.423,2.423,0,0,0,1.992,4.8C1.992,3.9.8,3.159,0,3.159v-1.6c1.6,0,3.592,1.45,3.592,3.239A4.032,4.032,0,0,1,0,8.384ZM5.6,9.6h4.8a4.8,4.8,0,0,1,4.8,4.8V16H13.6V14.4a3.2,3.2,0,0,0-3.2-3.2H5.6a3.2,3.2,0,0,0-3.2,3.2V16H.8V14.4A4.8,4.8,0,0,1,5.6,9.6Z"
			fill="currentColor"
			fillRule="evenodd"
		/>
	</svg>
)
