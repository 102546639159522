import { colors, intl } from '@beelday/common'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
	MenuToggleItem,
	PopupMenu,
	PopupMenuElement,
} from 'room/common/presentation/menu'
import ScreenShareIcon from './screen-share-icon'
import useScreenShareInGroup from './use-screen-share-in-group'
import { selectLocalScreenShareStream } from 'video-conference-media/redux'
import useIsTrainer from 'room/common/use-is-trainer'

const ScreenShareGroupMenuItem = (): JSX.Element => {
	const translate = intl.useTranslate()
	const {
		meScreenSharing,
		userGroupScreenShareState,
		start,
		stop,
		forceStart,
		forceStop,
	} = useScreenShareInGroup()
	const localScreenShareStream = useSelector(selectLocalScreenShareStream)

	const meTrainer = useIsTrainer()

	const onClick = useCallback(() => {
		if (meScreenSharing) {
			stop()
		} else if (localScreenShareStream) {
			stop()
		} else {
			if (meTrainer) {
				forceStart(userGroupScreenShareState?.userId || '')
			} else {
				start()
			}
		}
	}, [
		forceStart,
		localScreenShareStream,
		meScreenSharing,
		meTrainer,
		start,
		stop,
		userGroupScreenShareState?.userId,
	])

	return (
		<>
			<MenuToggleItem
				active={userGroupScreenShareState?.active}
				disabled={
					!meTrainer && userGroupScreenShareState?.active && !meScreenSharing
				}
				icon={<ScreenShareIcon />}
				onClick={onClick}
				label={<intl.Translate>room_menu.screen_share</intl.Translate>}
			>
				{meTrainer && !meScreenSharing && userGroupScreenShareState?.userId && (
					<PopupMenu>
						<PopupMenuElement
							text={translate('room_menu.screen_share.stop')}
							onClick={() => forceStop(userGroupScreenShareState?.userId || '')}
							style={{ color: colors.pinkRed, fontWeight: 'bolder' }}
						/>
					</PopupMenu>
				)}
			</MenuToggleItem>
		</>
	)
}

export default ScreenShareGroupMenuItem
