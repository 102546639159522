import { escapeRegExp, get } from 'lodash'

const BLANK_PATTERN = /^\s+$/
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const isBlank = (s?: any): boolean => {
	if (!s) return true
	return BLANK_PATTERN.test(s.toString())
}

export const isNotNullString = (s: string | undefined | null): s is string =>
	s != null

export const truncateMiddle = (
	s: string,
	max: number,
	omission: string = '...'
): string => {
	if (s.length <= max) {
		return s
	} else {
		const firstLen = Math.ceil((max - omission.length) / 2)
		const secondLen = Math.floor((max - omission.length) / 2)
		return `${s.substring(0, firstLen)}${omission}${s.substring(
			s.length - secondLen - 1,
			s.length
		)}`
	}
}

export const tryGetHostname = (url?: string | null): string => {
	if (url) {
		try {
			return new URL(url).hostname
		} catch (_) {}
	}

	return ''
}

export function searchPatternMatcher<T>(
	pattern: string,
	...fields: Array<keyof T>
): (element: T) => boolean {
	const rg = new RegExp(escapeRegExp(pattern), 'i')
	return (element: T): boolean =>
		fields.some(f => {
			const value = element[f]

			return value && rg.test(String(value))
		})
}

export function searchPatternMatcherPaths<T>(
	pattern: string,
	...paths: string[]
): (element: T) => boolean {
	const rg = new RegExp(escapeRegExp(pattern), 'i')

	return (element: T): boolean =>
		paths.some(p => {
			const value = get(element, p)

			return value && rg.test(String(value))
		})
}
