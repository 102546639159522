import { FC } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'common/redux'
import ConfiguredKnowledgeCheckTrainer from './configured-knowledge-check-trainer'
import ConfiguredKnowledgeCheckReviewTrainer from './configured-knowledge-check-review-trainer'
import { UUID } from 'common/types'
import { SceneVideoUser } from 'video-conference-media'

const ConfiguredKnowledgeCheckViewTrainer: FC<{
	knowledgeCheckId: UUID
	streams: SceneVideoUser[]
}> = ({ knowledgeCheckId, streams }) => {
	const isReview = useSelector(
		(state: RootState) => state.knowledgeChecks.isReview
	)
	return !isReview ? (
		<ConfiguredKnowledgeCheckTrainer
			knowledgeCheckId={knowledgeCheckId}
			streams={streams}
		/>
	) : (
		<ConfiguredKnowledgeCheckReviewTrainer
			knowledgeCheckId={knowledgeCheckId}
			streams={streams}
		/>
	)
}

export default ConfiguredKnowledgeCheckViewTrainer
