import { RootState } from 'common/redux'
import { RoomId, UserId, UserRole } from 'common/types'

export type RosterState = {
	rooms: Record<RoomId, RoomRoster>
}

type RosterUser = {
	id: UserId
	name: string
	role: UserRole
}

type RoomRoster = {
	roomId: RoomId
	roomName: string
	users: RosterUser[]
}

type RosterChangeSSE = {
	rosters: Array<{
		sourceRoomId: RoomId
		sourceRoomName: string
		users: Array<{
			groupingAttributeValue: string
			id: UserId
			name: string
			role: UserRole
		}>
	}>
}

const sseRosterToRooms = (
	rosters: RosterChangeSSE['rosters']
): Record<RoomId, RoomRoster> =>
	rosters.reduce((acc, sseRoom) => {
		acc[sseRoom.sourceRoomId] = {
			roomId: sseRoom.sourceRoomId,
			roomName: sseRoom.sourceRoomName,
			users: sseRoom.users,
		}
		return acc
	}, {} as Record<RoomId, RoomRoster>)

type RosterAction = {
	type: 'SSE_ROSTERS_LIST_STATE_CHANGED'
	payload: RosterChangeSSE
}

export function rosterReducer(
	state: RosterState = { rooms: {} },
	{ type, payload }: RosterAction
): RosterState {
	switch (type) {
		case 'SSE_ROSTERS_LIST_STATE_CHANGED':
			return { ...state, rooms: sseRosterToRooms(payload.rosters) }
		default:
			return state
	}
}

export const selectRoomRosters = (state: RootState) => state.roster.rooms
