import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentType, FC, ReactElement } from 'react'
import colors from '../colors'
import { FlexCenterAll } from './containers'

type Props = {
	color?: string
	size?: number
	className?: string
}

const SpinnerAnimation = styled.div<Props>`
	color: ${props => props.color || '#000000'};
	font-size: ${props => (props.size ? `${props.size / 2}px` : '50px')};
	overflow: hidden;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	position: relative;
	animation: load6 1.7s infinite ease, round 1.7s infinite ease;

	@-webkit-keyframes load6 {
		0% {
			box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
				0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
		}
		5%,
		95% {
			box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
				0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
		}
		10%,
		59% {
			box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
				-0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
				-0.297em -0.775em 0 -0.477em;
		}
		20% {
			box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
				-0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
				-0.749em -0.34em 0 -0.477em;
		}
		38% {
			box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
				-0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
				-0.82em -0.09em 0 -0.477em;
		}
		100% {
			box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
				0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
		}
	}
	@keyframes load6 {
		0% {
			box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
				0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
		}
		5%,
		95% {
			box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
				0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
		}
		10%,
		59% {
			box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
				-0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
				-0.297em -0.775em 0 -0.477em;
		}
		20% {
			box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
				-0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
				-0.749em -0.34em 0 -0.477em;
		}
		38% {
			box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
				-0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
				-0.82em -0.09em 0 -0.477em;
		}
		100% {
			box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
				0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
		}
	}
	@-webkit-keyframes round {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@keyframes round {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
`

export const Spinner: React.FC<Props> = ({ color, size, className }) => (
	<FlexCenterAll
		className={className}
		style={{
			width: size ? `${size}px` : undefined,
			height: size ? `${size}px` : undefined,
		}}
	>
		<SpinnerAnimation color={color} size={size} />
	</FlexCenterAll>
)

export const FullPageSpinner: FC<Props> = ({ color, size, className }) => (
	<FlexCenterAll className={className} style={{ padding: '60px 20px' }}>
		<Spinner size={size || 48} color={color} />
	</FlexCenterAll>
)

export const GlassSpinner: FC<Props> = ({ color, size, className }) => (
	<FlexCenterAll
		style={{
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			zIndex: 999,
			backgroundColor: colors.transparentBlackish,
		}}
		className={className}
	>
		<Spinner size={size || 52} color={color || colors.white} />
	</FlexCenterAll>
)

export const ListSpinner = FullPageSpinner

export const FullScreenSpinner: FC<Props> = props => (
	<FullPageSpinner
		css={css`
			width: 100vw;
			height: 100vh;
		`}
		color={colors.indigoBlue}
		{...props}
	/>
)

type WithLoadingProps = {
	children: () => JSX.Element | null
	loading?: boolean
	spinner?: JSX.Element
}
export const WithLoading: FC<WithLoadingProps> = ({
	children,
	loading,
	spinner,
}) => {
	if (loading) return spinner || <FullPageSpinner />
	else return children()
}

export function withLoading<T>(
	Comp: ComponentType<T>,
	loader?: ReactElement
): ComponentType<T & { loading?: boolean }> {
	const HOC = (props: T & { loading?: boolean }) => {
		if (props.loading) {
			return loader || <Spinner />
		} else {
			return <Comp {...props} />
		}
	}
	HOC.displayName = `withLoading(${Comp.displayName || Comp.name})`
	return HOC
}

export default Spinner
