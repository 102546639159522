import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useScreenShareAPI from './screen-share-api'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { Config } from 'common/config'
import { useJuno } from 'vcr/juno/juno-provider'
import {
	selectLocalScreenShareStream,
	setLocalScreenShareStream,
} from 'video-conference-media/redux'
import { UserId } from '../../common/types'
import { stopShare } from '../../room/common/redux'
import { RootState } from 'common/redux'
import { useAssertedWorkflowUser } from 'room/common/use-workflow-user'

type UseScreenShare = () => {
	screenShareState: { user?: string | undefined; stopShare: boolean }
	meScreenSharing: boolean
	start: () => Promise<void>
	stop: () => Promise<void>
	requestPermission: () => Promise<void>
	disablePermission: (userId: UserId) => Promise<void>
	acceptPermission: (userId: UserId) => Promise<void>
}

const useScreenShare: UseScreenShare = () => {
	const dispatch = useDispatch()
	const localScreenShareStream = useSelector(selectLocalScreenShareStream)
	const address = useAssertedJoinedRoomAddress()
	const isStopShare = useSelector(stopShare)
	const api = useScreenShareAPI(Config.beeldayBackendUrl)
	const { juno } = useJuno()

	const me = useAssertedWorkflowUser()

	const screenShareState = useSelector(
		(state: RootState) => state.room.screenShare
	)

	const meScreenSharing = screenShareState.user === me.id

	const stop = useCallback(async () => {
		await api.stopSharing(address)
		await juno?.stopScreenShare()
		if (localScreenShareStream) {
			localScreenShareStream.getTracks().forEach(track => track.stop())
		}
		dispatch(setLocalScreenShareStream())
	}, [dispatch, api, address, juno, localScreenShareStream])

	const start = useCallback(async () => {
		const stream = await navigator.mediaDevices.getDisplayMedia()
		await api.startSharing(address)
		await juno?.startScreenShare(stream)

		dispatch(setLocalScreenShareStream(stream))
	}, [dispatch, address, api, juno])

	const disablePermission = useCallback(
		async (userId: UserId) => {
			await api.disablePermission(address, userId)
		},
		[address, api]
	)

	const requestPermission = useCallback(async () => {
		await api.requestPermission(address)
	}, [address, api])

	const acceptPermission = useCallback(
		async (userId: UserId) => {
			await api.acceptPermission(address, userId)
		},
		[address, api]
	)

	useEffect(() => {
		if (isStopShare) {
			stop()
		}
	}, [isStopShare])

	useEffect(() => {
		if (localScreenShareStream) {
			const screenSharingTrack = localScreenShareStream.getVideoTracks()[0]
			screenSharingTrack.addEventListener('ended', stop)
			return () => {
				screenSharingTrack.removeEventListener('ended', stop)
			}
		}
	}, [localScreenShareStream, stop])

	useEffect(() => {
		if (localScreenShareStream) {
			window.addEventListener('beforeunload', stop)
			return () => {
				window.removeEventListener('beforeunload', stop)
			}
		}
	}, [localScreenShareStream, stop])

	return {
		screenShareState,
		meScreenSharing,
		start,
		stop,
		requestPermission,
		disablePermission,
		acceptPermission,
	}
}

export default useScreenShare
