export const MultipleChoiceIcon: React.FC = () => (
	<svg
		version="1.1"
		id="Layer_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 32 25"
		enableBackground="new 0 0 32 25;"
		xmlSpace="preserve"
	>
		<path
			id="Path_12689"
			fill="currentColor"
			d="M8,25H0V13.3c0-0.4,0.4-0.8,0.8-0.8h6.4c0.4,0,0.8,0.3,0.8,0.8V25z M20,0.8
	C20,0.3,19.6,0,19.2,0h-6.4C12.4,0,12,0.3,12,0.8V25h8V0.8z M31.2,6.3h-6.4C24.4,6.2,24,6.6,24,7v18h8V7C32,6.6,31.6,6.2,31.2,6.3"
		/>
	</svg>
)
