type Props = {
	color?: string
	rotation?: number
}

export const ExpandArrow: React.FC<Props> = ({
	color = '#293649',
	rotation,
}) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="12.96"
		height="6.552"
		viewBox="0 0 12.96 6.552"
		transform={rotation ? `rotate(${rotation}deg)` : undefined}
	>
		<path
			fill="none"
			stroke={color}
			strokeWidth="1.5px"
			d="M1260.332 26l6 5 6-5"
			transform="translate(-1259.852 -25.424)"
		/>
	</svg>
)

export const ExpandArrowSmall: React.FC<Props> = ({
	color = '#293649',
	rotation,
}) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="5"
		height="9"
		viewBox="0 0 5 9"
		transform={rotation ? `rotate(${rotation})` : undefined}
	>
		<path
			id="Polygon_5"
			data-name="Polygon 5"
			d="M4.5,0,9,5H0Z"
			transform="translate(5) rotate(90)"
			fill={color}
		/>
	</svg>
)
